import { gql } from '@apollo/client';

import { CREATIVES_FRAGMENTS } from '../../../hooks/fragments';

export const GET_CREATIVE_BY_ID = gql`
  query getCreativeByID($id: String!) {
    getCreativeByIDV5: getCreativeByIDV5(creativeId: $id) {
      ...creatives
    }
  }
  ${CREATIVES_FRAGMENTS}
`;
