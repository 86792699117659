import { IconDrag } from '@hulu-react-style-components/icons';
import { colorGray5 } from '@hulu/design-tokens/lib/colors.common';
import { useFormikContext } from 'formik';
import { Draggable } from 'react-beautiful-dnd';
import ReactTooltip from 'react-tooltip';

import { stringToDateTimeString } from '../../Trafficking/TraffickingPage/columns';
import {
  SEQUENCE_ROW_TOOLTIP_DELAY_HIDE,
  SEQUENCE_ROW_TOOLTIP_DELAY_SHOW,
  SEQUENCE_ROW_TOOLTIP_MESSAGE,
  SEQUENCE_ROW_TOOLTIP_PLACEMENT,
} from '../constants';
import type { SequenceAdV5 } from '../types';

type SequenceTableRowProps = {
  row: SequenceAdV5;
  tableIndex: number;
  rowIndex: number;
};

const SequenceTableRow = ({ row, tableIndex, rowIndex }: SequenceTableRowProps): React.JSX.Element => {
  const { isSubmitting } = useFormikContext();

  return (
    <Draggable
      isDragDisabled={!!row.adRotation || isSubmitting}
      draggableId={`${tableIndex}-${rowIndex}`}
      index={rowIndex}
    >
      {(provided, snapshot): React.ReactElement => {
        const trStyle: React.CSSProperties = { ...provided?.draggableProps.style };
        // need to remove the left that react-beautiful-dnd puts on by default
        delete trStyle.left;
        // remove padding that is on there so it sits center of drag and drop
        trStyle.padding = '0px';

        if (snapshot.dropAnimation) {
          const animation = snapshot.dropAnimation;

          // we need to set the transform so that the drop animation does not go off screen
          trStyle.transform = `translate(0px, ${animation.moveTo.y}px)`;
        }

        if (row.adRotation) {
          trStyle.backgroundColor = colorGray5;
        }

        return (
          <>
            <tr
              ref={provided?.innerRef}
              {...provided?.draggableProps}
              {...provided?.dragHandleProps}
              style={trStyle}
              data-for={row.id}
              data-place={SEQUENCE_ROW_TOOLTIP_PLACEMENT}
              data-tip-disable={!row.adRotation}
              data-delay-show={SEQUENCE_ROW_TOOLTIP_DELAY_SHOW}
              data-delay-hide={SEQUENCE_ROW_TOOLTIP_DELAY_HIDE}
              data-tip={SEQUENCE_ROW_TOOLTIP_MESSAGE}
              data-html
            >
              <td>
                <IconDrag className={'icon-drag'} />
              </td>
              <td>{rowIndex + 1}</td>
              <td title={row.name}>{row.name}</td>
              <td>{row.type}</td>
              <td>{stringToDateTimeString({ value: row.startDate })}</td>
              <td>{stringToDateTimeString({ value: row.endDate })}</td>
              <td>{row.trackingId}</td>
              <td title={row.lineItem?.name || ''}>{row.lineItem?.name}</td>
              <td>
                {/** Tooltip is placed there to avoid DOM nesting warning */}
                <ReactTooltip id={row.id} />
              </td>
            </tr>
          </>
        );
      }}
    </Draggable>
  );
};

export default SequenceTableRow;
