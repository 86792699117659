import { useFormikContext } from 'formik';

import type { MultipleEditDrawerFormValues, MultipleEditDrawerLineItemsFormValues } from '../../../../../../types';
import { activeStatuses } from '../constants';

type UseGroupEntitiesByStatus = {
  selectedEntities: Array<MultipleEditDrawerFormValues['entities']>;
  entitiesIdToIndexMap: Record<string, number>;
};

type Props = {
  readonly?: boolean;
};

export const useGroupEntitiesByStatus = ({ readonly }: Props): UseGroupEntitiesByStatus => {
  const { values } = useFormikContext<MultipleEditDrawerFormValues>();

  const isStatusSectionSelected = values.selectedSections.includes('Status');

  const getEntriesForReview = (): MultipleEditDrawerLineItemsFormValues['entities'] => {
    if (!readonly) {
      return values.entities;
    }

    return isStatusSectionSelected ? values.entities : values.initialStatuses;
  };

  const entitiesIdToIndexMap: Record<string, number> = {};
  const selectedEntitiesWithActiveStatuses: Array<MultipleEditDrawerFormValues['entities'][0]> = [];
  const selectedEntitiesWithNotActiveStatuses: Array<MultipleEditDrawerFormValues['entities'][0]> = [];

  getEntriesForReview().forEach((entity, index) => {
    if (!entity.isSelected) return;

    entitiesIdToIndexMap[entity.id] = index;

    const isActiveStatus = activeStatuses.some((entityStatusKey) => entityStatusKey === entity.status?.key);

    if (isActiveStatus) {
      selectedEntitiesWithActiveStatuses.push(entity);
      return;
    }

    selectedEntitiesWithNotActiveStatuses.push(entity);
  });

  return {
    selectedEntities: [selectedEntitiesWithNotActiveStatuses, selectedEntitiesWithActiveStatuses],
    entitiesIdToIndexMap,
  };
};
