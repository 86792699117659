import React from 'react';

import type { AssetTagV5 } from '../../../../apis/graphql';

/**
 * Displays the AssetTags segment
 */
export const AssetTags: React.FC<{ tagList?: AssetTagV5[] }> = (props) => {
  if (!props.tagList?.length) return null;

  const formattedTags = props.tagList?.map((tag) => tag.displayName).join(', ');

  return (
    <>
      {' '}
      | <strong>Tags:</strong> {formattedTags}
    </>
  );
};
