import { getTimezoneOffset } from 'date-fns-tz';

import { UNRECOGNIZED, viewerLocalTimeLabel } from '../../configs';
import type { DropdownOption } from '../Dropdown';

export const getTimezoneWithGMT = (timeZone: string): string => {
  const timezoneOffset = getTimezoneOffset(timeZone);

  if (isNaN(timezoneOffset)) {
    console.error(`${timeZone} timezone is not supported`);

    return UNRECOGNIZED;
  }

  const timezoneGMT = `(GMT${timezoneOffset / 1000 / 60 / 60})`;

  return `${timeZone} ${timezoneGMT}`;
};

export const getSelectedTimezone = (
  timezone: string,
  deliveryInViewerTime: boolean,
  optionsWithViewerLocalTime: DropdownOption<string>[]
): DropdownOption<string> => {
  return deliveryInViewerTime
    ? {
        value: timezone,
        label: viewerLocalTimeLabel,
      }
    : optionsWithViewerLocalTime.find((el) => el.label === getTimezoneWithGMT(timezone)) || {
        value: timezone,
        label: UNRECOGNIZED,
      };
};
