export enum ReportType {
  DELIVERY,
  END_OF_FLIGHT,
  END_OF_CAMPAIGN,
}

export enum ReportEntityType {
  CAMPAIGN,
  FLIGHT,
}
