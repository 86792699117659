import type { RefObject } from 'react';
import { useEffect } from 'react';

import { baseModalContainerClassName } from '../../common/constants';

const useOnClickOutside = (ref: RefObject<HTMLDivElement>, handler: Function): void => {
  useEffect(() => {
    const listener = (event: MouseEvent): void => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler(document.getElementsByClassName(baseModalContainerClassName).length);
    };

    document.addEventListener('mousedown', listener);

    return (): void => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
