import './ColumnControls.scss';

import { SearchInput } from '@hulu/react-style-components';
import { cloneDeep } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import type { ColumnInstance } from 'react-table';

import bem from '../../../utils/bem';
import ColumnToggle from './ColumnToggle';
import { getIsAllColumnUnselected } from './utils';

type CustomColumn = ColumnInstance & { Header: string };
export type ControlledColumn = Pick<
  CustomColumn,
  'Header' | 'id' | 'isVisible' | 'disableHiding' | 'isColumnHiddenByDefault'
>;

type Props = {
  columns: ControlledColumn[];
  toggleHiddenColumns: (columns: ControlledColumn[], value: boolean) => void;
  toggleHiddenColumn: (id: string, value: boolean) => void;
  selectedIds: Record<string, boolean>;
  setColumnOrder: (columnsId: ControlledColumn[]) => void;
  resetToDefaults: () => void;
};

const [block, element] = bem('column-controls');

const ColumnControl: React.FC<Props> = ({
  columns,
  toggleHiddenColumns,
  toggleHiddenColumn,
  selectedIds,
  setColumnOrder,
  resetToDefaults,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleSearch = (value: string): void => setSearchTerm(value);
  const handleClear = (): void => setSearchTerm('');

  const onDragEnd = useCallback(
    ({ source, destination, draggableId }: DropResult): void => {
      if (!destination) return;

      const newColumns = cloneDeep(columns);

      const draggableColumn = columns.find((column) => column.id === draggableId);

      if (!draggableColumn) return;

      newColumns.splice(source.index, 1);
      newColumns.splice(destination.index, 0, draggableColumn);

      setColumnOrder(newColumns);
    },
    [columns, setColumnOrder]
  );

  const columnsToShow = useMemo(() => {
    if (!searchTerm) return columns;
    return columns.filter((column) => (column.Header as string).toLowerCase().includes(searchTerm.toLowerCase()));
  }, [searchTerm, columns]);

  const isAllColumnsUnselected = useMemo(() => getIsAllColumnUnselected(selectedIds, columnsToShow), [
    selectedIds,
    columnsToShow,
  ]);

  const toggleColumns = (): void => toggleHiddenColumns(columnsToShow, isAllColumnsUnselected);

  return (
    <div className={block()} data-testid="column-control">
      <div className={element('header')}>
        <SearchInput onClear={handleClear} value={searchTerm} onInput={handleSearch} placeholder="Search columns" />
        <div className={element('control-buttons')}>
          <span onClick={toggleColumns} className={element('toggle-all')} data-testid="toggle-all">
            {isAllColumnsUnselected ? 'Select All' : 'De-Select All'}
          </span>
          <span onClick={resetToDefaults} className={element('restore')} data-testid="restore">
            Restore Defaults
          </span>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="customize-columns">
          {(provided): JSX.Element => (
            <div {...provided.droppableProps} ref={provided.innerRef} className={element('toggle-block')}>
              {columnsToShow.map((column, index) => (
                <ColumnToggle
                  disableDragging={!!searchTerm}
                  toggleHiddenColumn={toggleHiddenColumn}
                  checked={selectedIds[column.id] ?? false}
                  columnName={column.Header as string}
                  index={index}
                  key={column.id}
                  disabled={column.disableHiding}
                  id={column.id}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {!columnsToShow.length && (
          <div className={element('not-found')} data-testid="not-found">
            No Columns found.
          </div>
        )}
      </DragDropContext>
    </div>
  );
};

export default ColumnControl;
