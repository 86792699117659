import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

export type PacingType = keyof typeof pacingTypeDisplayNames;

const pacingTypeDisplayNames = {
  SOV: 'SOV',
  AFAP: 'AFAP',
  YIELD: 'Yield',
  EVEN: 'Even',
};

export const pacingTypes = makeConfig<PacingType>(pacingTypeDisplayNames);

export function convertPacingType(apiValue: string): DisplayEnum<PacingType> {
  return convertToDisplayEnum(apiValue, pacingTypes);
}

export type PermittedPacingType = keyof typeof pacingTypeDisplayNames;

const permittedPacingTypeDisplayNames = {
  AFAP: 'AFAP',
  EVEN: 'Even',
};

export const permittedPacingTypes = makeConfig<PacingType>(permittedPacingTypeDisplayNames);

export function convertPermittedPacingType(apiValue: string): DisplayEnum<PacingType> {
  return convertToDisplayEnum(apiValue, pacingTypes);
}
