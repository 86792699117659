import './Pill.scss';

import React from 'react';

import { withOptionalClassName } from '../../utils/bem';

export enum PillColor {
  RED = 'red',
  BLUE = 'blue',
  GRAY = 'gray',
  GREEN = 'green',
  YELLOW = 'yellow',
  YELLOW_DARK = 'yellow-dark',
  ORANGE = 'orange',
}

export interface PillProps {
  text: string;
  color: PillColor;
  className?: string;
}

const Pill = ({ text, color, className }: PillProps): JSX.Element => {
  if (text?.length < 1) {
    throw Error('Pill text must be at least one character');
  }

  return (
    <span data-testid="pill-wrapper" className={withOptionalClassName(`pill pill--${color}`, className)}>
      {text}
    </span>
  );
};

export default Pill;
