import './ChangeLog.scss';

import React from 'react';

import DrawerTitle from '../../pages/Trafficking/DrawerTitle';
import { EntityType } from '../../pages/Trafficking/TraffickingPage/hooks/makePageDrawerPlugin';
import bem from '../../utils/bem';
import Loader from '../Loader';
import ChangeLogList from './ChangeLogList';
import { Collapse } from './Collapse/Collapse';
import { ChangesPrefixes } from './constants';
import { DateRangeFilter } from './DateRangeFilter/DateRangeFilter';
import { useChangeLog } from './hooks';
import type { ChangeLogProps } from './types';

const [block] = bem('change-log');

type NestedChangesPrefix = ChangesPrefixes | undefined;

const ChangeLog = ({ id, entityType }: ChangeLogProps): JSX.Element | null => {
  const {
    changeLogEntries,
    targetingEntries,
    toggles,
    toggleAt,
    toggleAll,
    loading,
    loadingFilter,
    setLoadingFilter,
    refetch,
    total,
  } = useChangeLog({
    id,
    entityType,
  });

  const nestedChangesPrefix: NestedChangesPrefix =
    entityType === EntityType.AD ? ChangesPrefixes.AdRotation : undefined;

  const showLoader = loading || loadingFilter;

  // wrapping changelog in a trycatch here to prevent MC crashes.
  try {
    return (
      <>
        <DrawerTitle title="Change Log" />
        <DateRangeFilter setLoadingFilter={setLoadingFilter} refetch={refetch} id={id} toggleAll={toggleAll} />
        {showLoader ? (
          <div className="change-log-loader">
            <Loader text="Loading..." />
          </div>
        ) : changeLogEntries?.length ? (
          <div className={block()}>
            <Collapse toggleAll={toggleAll} />

            <ChangeLogList
              changeLogEntries={changeLogEntries}
              targetingEntries={targetingEntries}
              toggles={toggles}
              toggleAt={toggleAt}
              nestedChangesPrefix={nestedChangesPrefix}
              refetch={refetch}
              total={total}
              loading={loading}
              setLoadingFilter={setLoadingFilter}
            />
          </div>
        ) : (
          <div>No current change log data for this {entityType || 'entity'}.</div>
        )}
      </>
    );
  } catch (error) {
    return <div>Changelog has crashed.</div>;
  }
};

export default ChangeLog;
