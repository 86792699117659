import type { FieldHelperProps, FieldHookConfig, FieldInputProps, FieldMetaProps } from 'formik';
import { useField, useFormikContext } from 'formik';
import update from 'immutability-helper';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import type { ListHelpers } from '../../utils/listHelpers';

export type FieldFastResult<T> = [FieldInputProps<T>, FieldMetaProps<T>, FieldHelperProps<T>];

type FieldArrayHelpers<T> = ListHelpers<T> & {
  setValue: (value: T[], shouldValidate?: boolean) => void;
};

export type FieldArrayResult<T> = [FieldInputProps<T[]>, FieldMetaProps<T[]>, FieldArrayHelpers<T>];

/**
 * Custom hook for providing field props, meta props, and helper props for Formik fields.
 * Substitute for Formik's useField() hook. Minimizes redeclaration of results with every call/render.
 *
 * Code from this comment in Formik's issues:
 * https://github.com/formium/formik/issues/2268#issuecomment-682685788
 */
export function useFieldFast<TValue = string>(
  propsOrFieldName: string | FieldHookConfig<TValue>
): FieldFastResult<TValue> {
  const [field, meta] = useField<TValue>(propsOrFieldName);

  // `setField*` helpers from `useFormikContext` seem to be more "stable" than the ones returned by `useField`
  const { setFieldTouched, setFieldValue, setFieldError } = useFormikContext();

  // so we are going to shim field level helpers using them and `useMemo`:
  const helpers = useMemo<FieldHelperProps<TValue>>(
    () => ({
      setValue: (...args): void => setFieldValue(field.name, ...args),
      setTouched: (...args): void => setFieldTouched(field.name, ...args),
      setError: (...args): void => setFieldError(field.name, ...args),
    }),
    [setFieldTouched, setFieldValue, setFieldError, field.name]
  );

  return [field, meta, helpers];
}

/**
 * Custom hook for providing field props, meta props, and ListHelpers for Formik array fields.
 * Uses useFieldFast + useMemo to minimize redeclaration of results with every call/render.
 *
 * Inspired by this Formik issues thread:
 * https://github.com/formium/formik/issues/1476
 *
 * Code from:
 * https://gist.github.com/joshsalverda/d808d92f46a7085be062b2cbde978ae6
 */
export function useFieldArray<TValue = string>(
  propsOrFieldName: string | FieldHookConfig<TValue[]>
): FieldArrayResult<TValue> {
  const [field, meta, { setValue }] = useFieldFast<TValue[]>(propsOrFieldName);
  const fieldArray = useRef<TValue[]>(field.value);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    fieldArray.current = field.value;
  }, [field.value]);

  const insertAt = useCallback(
    (index: number, value: TValue) => {
      fieldArray.current = update(fieldArray.current, { $splice: [[index, 0, value]] });
      setFieldValue(field.name, fieldArray.current);
    },
    [field.name, setFieldValue]
  );

  const push = useCallback(
    (value: TValue) => {
      fieldArray.current = update(fieldArray.current, { $push: [value] });
      setFieldValue(field.name, fieldArray.current);
    },
    [field.name, setFieldValue]
  );

  const removeAt = useCallback(
    (index: number) => {
      const removedItem = fieldArray.current[index];
      fieldArray.current = update(fieldArray.current, { $splice: [[index, 1]] });
      setFieldValue(field.name, fieldArray.current);
      return removedItem;
    },
    [field.name, setFieldValue]
  );

  const replaceAt = useCallback(
    (index: number, value: TValue) => {
      fieldArray.current = update(fieldArray.current, { $splice: [[index, 1, value]] });
      setFieldValue(field.name, fieldArray.current);
    },
    [field.name, setFieldValue]
  );

  const removeAll = useCallback(() => {
    fieldArray.current = [];
    setFieldValue(field.name, fieldArray.current);
  }, [field.name, setFieldValue]);

  const helpers = useMemo<FieldArrayHelpers<TValue>>(
    () => ({ insertAt, push, removeAt, replaceAt, setValue, removeAll }),
    [insertAt, push, removeAt, replaceAt, setValue, removeAll]
  );

  return [field, meta, helpers];
}
