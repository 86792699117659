import { getTimezoneWithGMT } from '../../../../common/SelectTimeZone/utils';
import { formatDollarAmount, formatStringDate } from '../../../../utils/formatting';
import { DELIVERY_FORENSICS_LINK } from '../../constants';
import { LineItemColumn } from '../../constants/lineItems';
import { cellContentsByPacingStatus } from '../../TraffickingPage/cells/PacingStatusCell/constants';
import {
  boolToYes,
  formatUTCToDefaultTimezone,
  getAccountRelatedColumnAccessor,
  getLineItemThreshold,
  getMetricsRelatedColumnAccessor,
  getPercentageValueForExport,
} from '../../TraffickingPage/columns';
import type { LineItemModelForExport } from '../convertors/lineItem';
import type { LineItemModelValueAccessor } from '../types';
import {
  getAlertMessage,
  getCreatedAt,
  getDisplayPublisherTarget,
  getFrequencyCapList,
  getTrackingId,
  getUpdatedAt,
} from './commonAccessors';
import { getDollarValueToShow, getPercentageValueToShow } from './utils';

export const lineItemModelColumnAccessors: LineItemModelValueAccessor = {
  [LineItemColumn.Alert]: (lineItem: LineItemModelForExport): string => {
    return getAlertMessage({
      hasZeroDeliveryRisk: !!lineItem.hasZeroDeliveryRisk,
      hasMisalignedSchedule: !!lineItem.hasMisalignedSchedule,
      hasLineItemExclusiveBlock: !!lineItem?.inventoryBlockId,
    });
  },
  [LineItemColumn.Name]: (lineItem: LineItemModelForExport): string => lineItem.name,
  [LineItemColumn.Country]: (lineItem: LineItemModelForExport): string => lineItem.country || '',
  [LineItemColumn.DeliverableLineItemPackageName]: (lineItem: LineItemModelForExport): string =>
    lineItem.orderLineItemDto?.packageName || '',
  [LineItemColumn.TrackingId]: getTrackingId,
  [LineItemColumn.ParentOmsLinkId]: (lineItem: LineItemModelForExport): string => lineItem.parentLineOmsLink?.id || '',
  [LineItemColumn.Status]: (lineItem: LineItemModelForExport): string =>
    lineItem.subStatus ? lineItem.subStatus.displayName : lineItem.status.displayName,
  [LineItemColumn.Review]: (lineItem: LineItemModelForExport): string => lineItem.review.displayName,
  [LineItemColumn.ScheduleStart]: (lineItem: LineItemModelForExport): string => formatStringDate(lineItem.startDate),
  [LineItemColumn.ActualStartDate]: (lineItem: LineItemModelForExport): string =>
    formatUTCToDefaultTimezone({ value: lineItem.metadata?.actualStartDate || null }) || '',
  [LineItemColumn.ScheduleEnd]: (lineItem: LineItemModelForExport): string => formatStringDate(lineItem.endDate),
  [LineItemColumn.DisplayPublisherTarget]: getDisplayPublisherTarget,
  [LineItemColumn.ScheduleTimezoneOffset]: (lineItem: LineItemModelForExport): string =>
    lineItem.schedule?.timezone ? getTimezoneWithGMT(lineItem.schedule?.timezone) : '',
  [LineItemColumn.GeneralPacingStatus]: (lineItem: LineItemModelForExport): string =>
    lineItem.pacingMetrics?.generalPacingStatus
      ? cellContentsByPacingStatus[lineItem.pacingMetrics.generalPacingStatus]?.title || ''
      : '',
  [LineItemColumn.Goal]: (lineItem: LineItemModelForExport): string =>
    lineItem.goal !== null ? lineItem.goal.toLocaleString() : '',
  [LineItemColumn.MetricsCompletions]: (lineItem: LineItemModelForExport): string =>
    getMetricsRelatedColumnAccessor(lineItem.metrics?.completions),
  [LineItemColumn.MetricsPercentPacing]: (lineItem: LineItemModelForExport): string =>
    getPercentageValueForExport(lineItem.metrics?.percentPacing),
  [LineItemColumn.MetricsPercentExpected]: (lineItem: LineItemModelForExport): string =>
    getPercentageValueForExport(lineItem.metrics?.percentExpected),
  [LineItemColumn.MetricsPercentCompletions]: (lineItem: LineItemModelForExport): string =>
    getPercentageValueForExport(lineItem.metrics?.percentCompletions),
  [LineItemColumn.PacingType]: (lineItem: LineItemModelForExport): string => lineItem.pacingType.displayName,
  [LineItemColumn.Tier]: (lineItem: LineItemModelForExport): string => lineItem.tier.name.displayName,
  [LineItemColumn.YodaThreshold]: (lineItem: LineItemModelForExport): string =>
    getLineItemThreshold(lineItem.pacingMetrics?.yodaThreshold ?? null) || '',
  [LineItemColumn.YodaPacingStatus]: (lineItem: LineItemModelForExport): string =>
    lineItem.pacingMetrics?.yodaPacingStatus || '',
  [LineItemColumn.PriorityName]: (lineItem: LineItemModelForExport): string => lineItem.priority.name,
  [LineItemColumn.PriorityValue]: (lineItem: LineItemModelForExport): string =>
    lineItem.priorityValue?.toString() || '',
  [LineItemColumn.AdProduct]: (lineItem: LineItemModelForExport): string => lineItem.adProduct.displayName,
  [LineItemColumn.FrequencyCapList]: getFrequencyCapList,
  [LineItemColumn.DeliveryBufferPercent]: (lineItem: LineItemModelForExport): string =>
    getPercentageValueToShow(lineItem.deliveryBufferPercent),
  [LineItemColumn.FrontLoadPercent]: (lineItem: LineItemModelForExport): string =>
    getPercentageValueToShow(lineItem.frontLoadPercent),
  [LineItemColumn.DeliveryForensics]: (lineItem: LineItemModelForExport): string =>
    `${DELIVERY_FORENSICS_LINK}?line-item-id=${lineItem.id}`,
  [LineItemColumn.AccessorId]: (lineItem: LineItemModelForExport): string => lineItem.id,
  [LineItemColumn.MetricsRemainingExpectedCompletionsAcc]: (lineItem: LineItemModelForExport): string =>
    getMetricsRelatedColumnAccessor(lineItem.metrics?.remainingExpectedCompletionsAcc),
  [LineItemColumn.MetricsCompletionsAtRisk]: (lineItem: LineItemModelForExport): string =>
    getMetricsRelatedColumnAccessor(lineItem.metrics?.completionsAtRisk),
  [LineItemColumn.Revenue]: (lineItem: LineItemModelForExport): string => getDollarValueToShow(lineItem.revenue),
  [LineItemColumn.MetricsRevenueAtRisk]: (lineItem: LineItemModelForExport): string =>
    getDollarValueToShow(lineItem.metrics?.revenueAtRisk),
  [LineItemColumn.BillableThirdParty]: (lineItem: LineItemModelForExport): string =>
    lineItem.billableThirdParty?.displayName || '',
  [LineItemColumn.UnitCost]: (lineItem: LineItemModelForExport): string =>
    formatDollarAmount(lineItem.unitCostDto.micros / 1000000),
  [LineItemColumn.MetricsUnderPacingRevenue]: (lineItem: LineItemModelForExport): string =>
    getDollarValueToShow(lineItem.metrics?.underPacingRevenue),
  [LineItemColumn.LineOmsLinkId]: (lineItem: LineItemModelForExport): string => lineItem.lineOmsLink?.id || '',
  [LineItemColumn.CreatedAt]: getCreatedAt,
  [LineItemColumn.UpdatedAt]: getUpdatedAt,
  [LineItemColumn.CreativeMaxSeconds]: (lineItem: LineItemModelForExport): string =>
    lineItem.creativeMaxSeconds?.toString() || '',
  [LineItemColumn.CampaignName]: (lineItem: LineItemModelForExport): string => lineItem.campaign?.name || '',
  [LineItemColumn.DeliveryGoal]: (lineItem: LineItemModelForExport): string =>
    lineItem.deliveryGoal?.toLocaleString() || '',
  [LineItemColumn.IsCoppaOrCaru]: (lineItem: LineItemModelForExport): string =>
    boolToYes({ value: lineItem.isCoppaOrCaru }) || '',
  [LineItemColumn.IsAddedValue]: (lineItem: LineItemModelForExport): string =>
    boolToYes({ value: lineItem.isAddedValue }) || '',
  [LineItemColumn.IsMakegood]: (lineItem: LineItemModelForExport): string =>
    boolToYes({ value: lineItem.isMakegood }) || '',
  [LineItemColumn.IsFiller]: (lineItem: LineItemModelForExport): string =>
    boolToYes({ value: lineItem.isFiller }) || '',
  [LineItemColumn.DeliverableLineItemBuyerName]: (lineItem: LineItemModelForExport): string =>
    lineItem.orderLineItemDto?.buyerName || '',
  [LineItemColumn.DeliverableLineItemBuyerId]: (lineItem: LineItemModelForExport): string =>
    lineItem.orderLineItemDto?.buyerId || '',
  [LineItemColumn.MagniteDealDspName]: (lineItem: LineItemModelForExport): string =>
    lineItem.magniteDealDto?.dspName || '',
  [LineItemColumn.MagniteDealDealName]: (lineItem: LineItemModelForExport): string =>
    lineItem.magniteDealDto?.dealName || '',
  [LineItemColumn.CampaignTraffickerEmail]: (lineItem: LineItemModelForExport): string =>
    getAccountRelatedColumnAccessor(lineItem.campaign?.traffickerEmail),
  [LineItemColumn.CampaignOrderSalespersonEmail]: (lineItem: LineItemModelForExport): string =>
    getAccountRelatedColumnAccessor(lineItem.campaign?.order?.salespersonEmail),
  [LineItemColumn.Currency]: (lineItem: LineItemModelForExport): string => lineItem.unitCostDto.currency,
  [LineItemColumn.Sequence]: (lineItem: LineItemModelForExport): string =>
    boolToYes({ value: !!lineItem.lineItemSequenceId, isNoShown: true }) || '',
};
