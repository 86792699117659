import type { TargetingTermValue } from '../../../../../apis/graphql/types';
import type { TargetingTermValuesFragment } from '../../../../../apis/graphql/types';

export const reduceTargetingTermsToCountryStrings = (
  termList: (TargetingTermValue | TargetingTermValuesFragment)[]
): string[] => {
  const initialValues: string[] = [];
  return termList.reduce((accCountryCodes, term) => {
    if (term?.dimension?.id === 'country' && term?.value?.id) {
      accCountryCodes.push(term?.value?.id);
    }
    return accCountryCodes;
  }, initialValues);
};
