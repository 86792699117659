import type { ApolloError } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';

import type {
  Rotation,
  UpdateRotationNameMutation,
  UpdateRotationNameMutationVariables,
} from '../../../../apis/graphql';
import { AlertType } from '../../../../common/Alert';
import {
  getUpdatedRotations,
  updateAdRotationCache,
  updateRotationsCache,
} from '../../../../common/SequenceWeight/utils';
import { openToastAlert } from '../../../../common/ToastAlert/toastAlert';
import { getGraphQLErrorExtensionsMessage } from '../../../../common/utils';
import { generalErrorDescription, generalErrorMessage } from '../../../../constants';
import { UPDATE_ROTATION_NAME } from './mutations/rotations';
import useCellVariant from './useCellVariant';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useUpdateRotationName = (lineItemId?: string) => {
  const [graphQLErrors, setGraphQLErrors] = useState<string[]>([]);

  const [updateRotationName, mutationResult] = useMutation<
    UpdateRotationNameMutation,
    UpdateRotationNameMutationVariables
  >(UPDATE_ROTATION_NAME);

  useEffect(() => {
    const message = mutationResult?.error?.message || '';
    if (message.length > 0) {
      setGraphQLErrors((prev) => [...prev, message]);
    }
  }, [mutationResult.error]);

  const cellVariant = useCellVariant(mutationResult);

  const updateRotationNameFields = (rotationId: string, name: string): void => {
    if (!lineItemId) {
      openToastAlert({
        alertType: AlertType.ERROR,
        message: 'Missing Line Item',
        description: 'Cannot update rotation because Ad does not have a Line Item',
        restrictToPage: false,
      });
      return;
    }

    updateRotationName({
      variables: {
        rotationId,
        name,
      },
      update(_, { data }) {
        const rotations = data?.updateRotationName || null;

        if (rotations?.length) {
          const updatedRotations = getUpdatedRotations(rotations[0], lineItemId, rotationId);

          if (updatedRotations) updateRotationsCache(updatedRotations, lineItemId);
          updateAdRotationCache(rotations as Rotation[]);
        }
      },
    }).catch((error: ApolloError) => {
      const message = getGraphQLErrorExtensionsMessage(error);

      openToastAlert({
        alertType: AlertType.ERROR,
        message: generalErrorMessage,
        description: generalErrorDescription,
        restrictToPage: false,
      });

      if (message) {
        setGraphQLErrors((prev) => [...prev, message]);
      }
    });
  };

  return {
    cellVariant,
    updateRotationNameFields,
    errors: graphQLErrors,
  };
};

export default useUpdateRotationName;
