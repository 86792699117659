import './AdSideNavScheduling.scss';

import { Formik } from 'formik';
import { User } from 'mission-control-common-components';
import React, { useCallback, useMemo, useState } from 'react';

import type { PermissionsNames } from '../../constants';
import { getAdSchedule } from '../AdForm/hooks/modelConverters';
import { useUpdateAdSchedule } from '../hooks/mutations/useUpdateAdSchedule';
import type { AdFormErrorType } from '../utils';
import { showFailure, showSuccess } from '../utils';
import AdSchedule from './AdSchedule';
import ModeControlBlock from './ModeControl';
import type { AdSideNavSchedulingInitialValues, AdSideNavSchedulingProps } from './types';

function AdSideNavScheduling({ adId, adSchedule, lineItemSchedule }: AdSideNavSchedulingProps): React.JSX.Element {
  const permissions = User.getPermissions<PermissionsNames>();

  const [editMode, setEditMode] = useState(false);
  const [disableSubmitting, setDisableSubmitting] = useState(false);

  const initialValues: AdSideNavSchedulingInitialValues = {
    schedule: useMemo(() => getAdSchedule(adSchedule, lineItemSchedule), [adSchedule, lineItemSchedule]),
    isLastDateRangeDisabled: false,
  };

  const handleCloseEditMode = useCallback(() => setEditMode(false), []);

  const onSuccessUpdate = useCallback(() => {
    showSuccess();
    handleCloseEditMode();
  }, [handleCloseEditMode]);

  const onFailureUpdate = useCallback((error: AdFormErrorType) => {
    showFailure(error);
  }, []);

  const { loading, updateAdSchedule } = useUpdateAdSchedule({
    adId,
    onSuccessUpdate,
    onFailureUpdate,
  });

  const onUpdateAdSchedule = useCallback(
    async (values: AdSideNavSchedulingInitialValues) => {
      await updateAdSchedule({ schedule: values.schedule, lineItemSchedule });
    },
    [lineItemSchedule, updateAdSchedule]
  );

  return (
    <div data-testid="side-nav-scheduling" className="ad-side-nav-scheduling">
      <Formik initialValues={initialValues} onSubmit={onUpdateAdSchedule} enableReinitialize={true}>
        {({ submitForm }): JSX.Element => (
          <div>
            <ModeControlBlock
              editMode={editMode}
              setEditMode={setEditMode}
              loading={loading}
              disableSubmitting={disableSubmitting}
              onSubmit={submitForm}
              onCancel={handleCloseEditMode}
              canEdit={permissions?.updateAd}
            />
            <AdSchedule
              setDisableSubmitting={setDisableSubmitting}
              adSchedule={adSchedule}
              lineItemSchedule={lineItemSchedule}
              editMode={editMode}
            />
          </div>
        )}
      </Formik>
    </div>
  );
}

export default AdSideNavScheduling;
