import { format, isAfter, isBefore } from 'date-fns';
import type { Dispatch, SetStateAction } from 'react';

import type { IDateRange } from '../DateFilter';
import { DEFAULT_DATE_FORMAT } from './constants';
import { getMaxDate, getMinDate } from './helpers';
import type { Errors } from './Modal';

export const minDate = getMinDate();
export const maxDate = getMaxDate();

export const ErrorVariants = {
  INCORRECT_DATE: 'Incorrect date',
  INCORRECT_END_DATE: 'Incorrect end date',
  INCORRECT_START_DATE: 'Incorrect start date',
  START_DATE_AFTER_END_DATE: 'Start date cannot before end date',
  START_DATE_AFTER_MAX_DATE: `Start date cannot after ${format(maxDate, DEFAULT_DATE_FORMAT)}`,
  START_DATE_BEFORE_MIN_DATE: `Start date cannot be before ${format(minDate, DEFAULT_DATE_FORMAT)}`,
  END_DATE_AFTER_MAX_DATE: `End date cannot be after ${format(maxDate, DEFAULT_DATE_FORMAT)}`,
};

export const checkValidation = (
  setErrors: Dispatch<SetStateAction<Errors | null | undefined>>,
  dateRange: IDateRange
): void => {
  if (!dateRange.startDate) {
    setErrors({ startDate: ErrorVariants.INCORRECT_START_DATE });

    return;
  }

  if (dateRange.startDate) {
    if (isAfter(dateRange.startDate, maxDate)) {
      setErrors({ startDate: ErrorVariants.START_DATE_AFTER_MAX_DATE });

      return;
    }

    if (isBefore(dateRange.startDate, minDate)) {
      setErrors({ startDate: ErrorVariants.START_DATE_BEFORE_MIN_DATE });

      return;
    }

    if (dateRange.endDate) {
      if (isAfter(dateRange.startDate, dateRange.endDate)) {
        setErrors({ startDate: ErrorVariants.START_DATE_AFTER_END_DATE });

        return;
      }

      if (isAfter(dateRange.endDate, maxDate)) {
        setErrors({ endDate: ErrorVariants.END_DATE_AFTER_MAX_DATE });

        return;
      }
    }
  }

  setErrors(null);
};
