import './MultipleEditDrawerTitle.scss';

import React from 'react';

import bem from '../../../../../utils/bem';
import type { EntityTypeArray } from '../../../TraffickingPage/hooks/makePageDrawerPlugin';
import { Steps } from '../constants';
import { isActiveStep } from '../utils';
import MultipleEditFirstStepTitle from './MultipleEditFirstStepTitle';
import MultipleEditNotFirstStepTitle from './MultipleEditNotFirstStepTitle';

const [block] = bem('multiple-edit-drawer-title');

type MultipleEditDrawerTitleProps = {
  activeStep: Steps;
  entityType: EntityTypeArray;
  onPrevStep: () => void;
};

export default function MultipleEditDrawerTitle({
  activeStep,
  entityType,
  onPrevStep,
}: MultipleEditDrawerTitleProps): JSX.Element {
  const isFirstStepActive = isActiveStep(activeStep, Steps.SELECT);

  return isFirstStepActive ? (
    <MultipleEditFirstStepTitle className={block()} />
  ) : (
    <MultipleEditNotFirstStepTitle className={block()} onPrevStep={onPrevStep} entityType={entityType} />
  );
}
