import './LineItemScheduling.scss';

import React from 'react';

import type { Maybe, ScheduleV5 } from '../../../apis/graphql';
import DrawerTitle from '../DrawerTitle';
import DaypartSection from './DaypartSection';
import SecondaryPacing from './SecondaryPacing';

interface LineItemSchedulingProps {
  schedule?: Maybe<Pick<ScheduleV5, 'timezone' | 'dateRangeList' | 'dayPartList'>>;
}

const LineItemScheduling = ({ schedule }: LineItemSchedulingProps): React.JSX.Element => {
  return (
    <>
      <DrawerTitle title="Line Item Scheduling" />
      <SecondaryPacing schedule={schedule} />
      <DaypartSection dayPartList={schedule?.dayPartList} />
    </>
  );
};

export default LineItemScheduling;
