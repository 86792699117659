export const TranscodeStatus = Object.freeze({
  PENDING: 'PENDING',
  PRE_PROCESSING: 'PRE_PROCESSING',
  PRE_PROCESSING_COMPLETE: 'PRE_PROCESSING_COMPLETE',
  PRE_PROCESSING_ERROR: 'PRE_PROCESSING_ERROR',
  PRE_PROCESSING_ABORT: 'PRE_PROCESSING_ABORT',
  PUBLISHING: 'PUBLISHING',
  PUBLISHING_COMPLETE: 'PUBLISHING_COMPLETE',
  PUBLISHING_ERROR: 'PUBLISHING_ERROR',
  PUBLISHING_ABORT: 'PUBLISHING_ABORT',
  STREAMABLE: 'STREAMABLE',
  STREAMABLE_CHECKING: 'STREAMABLE_CHECKING',
  STREAMABLE_CHECKING_ERROR: 'STREAMABLE_CHECKING_ERROR',
  STREAMABLE_CHECKING_ABORT: 'STREAMABLE_CHECKING_ABORT',
  STREAMABLE_ERROR: 'STREAMABLE_ERROR',
});

export const TranscodeStatusMap: Record<keyof typeof TranscodeStatus, string> = Object.freeze({
  PENDING: 'PENDING',
  PRE_PROCESSING: 'PRE-PROCESSING',
  PRE_PROCESSING_COMPLETE: 'PRE-PROCESSING COMPLETE',
  PRE_PROCESSING_ERROR: 'PRE-PROCESSING ERROR',
  PRE_PROCESSING_ABORT: 'PRE-PROCESSING ABORT',
  PUBLISHING: 'PUBLISHING',
  PUBLISHING_COMPLETE: 'PUBLISHING COMPLETE',
  PUBLISHING_ERROR: 'PUBLISHING ERROR',
  PUBLISHING_ABORT: 'PUBLISHING ABORT',
  STREAMABLE: 'STREAMABLE',
  STREAMABLE_CHECKING: 'STREAMABLE CHECKING',
  STREAMABLE_CHECKING_ERROR: 'STREAMABLE CHECKING ERROR',
  STREAMABLE_CHECKING_ABORT: 'STREAMABLE CHECKING ABORT',
  STREAMABLE_ERROR: 'STREAMABLE ERROR',
});
