import type { Config } from '../../configs';
import { UNRECOGNIZED } from '../../configs';
import type { DropdownOption, SingleSelectValue } from './Dropdown';

export const configToDropdownOption = <T extends string>({ key, displayName }: Config<T>): DropdownOption<T> => ({
  label: displayName,
  value: key,
});

export function isRecognizedOption<T extends string>(
  option: SingleSelectValue<T | typeof UNRECOGNIZED>
): option is DropdownOption<T> {
  return !!option && option.value !== UNRECOGNIZED;
}
