import gql from 'graphql-tag';

export const SEND_CAMPAIGNS_ON_REVIEW = gql`
  mutation SendCampaignsOnReview($ids: [ID!]!) {
    sendCampaignsOnReviewV5(input: $ids) {
      id
      review
    }
  }
`;
