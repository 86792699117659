import type { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';

import type { Scalars } from '../../../apis/graphql';
import bem from '../../../utils/bem';

export interface ChangelogTitleProps {
  id: string;
  changesPlural: string;
  formattedDate: string;
  modifiedBy: Maybe<Scalars['String']>;
}
const [block, element] = bem('change-log-title');

const ChangeLogTittle = ({ id, changesPlural, formattedDate, modifiedBy }: ChangelogTitleProps): JSX.Element => (
  <div key={id} className={block()} data-testid="change-log-title">
    {changesPlural}{' '}
    {modifiedBy && (
      <span>
        by <span className={element('bold-title')}>{modifiedBy}</span>
      </span>
    )}{' '}
    on <span className={element('bold-title')}>{formattedDate}</span>
  </div>
);

export default ChangeLogTittle;
