import { gql } from '@apollo/client';

export const GET_BRANDS = gql`
  query GET_BRANDS($limit: Int, $offset: Int, $searchTerm: String) {
    brands(paginationOptions: { limit: $limit, offset: $offset }, filterOptions: { name: $searchTerm }) {
      edges {
        node {
          id
          name
          url
          type
          industry
          industryId
          sourceSystemId
          adomains
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
