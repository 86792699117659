import './BasicSelectButton.scss';

import { IconChevronDown } from '@hulu-react-style-components/icons';
import type { FC } from 'react';
import React from 'react';

import bem from '../../../../utils/bem';

const [block, element] = bem('basic-select-button');

type Props = {
  toggleOpen: () => void;
  isOpen: boolean;
  isActive?: boolean;
  text: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
};

const BasicSelectButton: FC<Props> = ({ text, leftIcon, rightIcon, isActive, isOpen, toggleOpen }) => {
  return (
    <div
      data-testid="basic-select-button"
      className={`${block()} ${isActive ? element('active') : ''} ${isOpen ? element('open') : ''}`}
      onClick={toggleOpen}
    >
      {leftIcon}
      <span>{text}</span>
      {rightIcon ? (
        rightIcon
      ) : (
        <IconChevronDown width={16} height={16} className={`${element('chevron-icon', isOpen ? 'open' : 'closed')}`} />
      )}
    </div>
  );
};

export default BasicSelectButton;
