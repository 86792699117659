import type { SingleValueChange } from '../../../../apis/graphql';
import type { Elements } from './removeEmptyArrayElements';

type PayloadElement = object | number | Record<string, string> | null;
export type Payload = SingleValueChange | Elements | null;

const isAllObjectFieldsNull = (obj: object | null | number | string): boolean => {
  if (typeof obj === 'object' && !Array.isArray(obj) && obj !== null) {
    return Object.values(obj).every((value) => value === null || undefined || '');
  }
  return false;
};

export const setPayloadToNull = (payload: Payload): Payload => {
  if (Array.isArray(payload)) {
    if (payload.every((value) => value === null || undefined || '')) payload = null;
    if (payload && payload.every((element: PayloadElement) => isAllObjectFieldsNull(element))) payload = null;
  }
  if (isAllObjectFieldsNull(payload)) payload = null;

  return payload;
};
