import type { Get_Campaigns_For_ExportQuery } from '../../../../apis/graphql';
import type { Campaign } from '../../../../models';
import type { CampaignModel } from '../../TraffickingPage/modelConverters';
import { campaignNodeToModel } from '../../TraffickingPage/modelConverters';

export type CampaignNodeForExport = NonNullable<Get_Campaigns_For_ExportQuery['campaigns']['edges'][number]>['node'];

export type CampaignModelForExport = Omit<CampaignModel, 'lineItemList'> & Pick<Campaign, 'campaignLineItems'>;

export const campaignNodeToModelForExport = (node: CampaignNodeForExport): CampaignModelForExport => {
  return {
    ...campaignNodeToModel({ ...node, lineItemList: null }),
    campaignLineItems: node.campaignLineItems,
  };
};
