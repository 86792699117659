import './DisplayCreativeBuilder.scss';

import { useFormikContext } from 'formik';
import React from 'react';

import type { DisplayCreativeInputV5 } from '../../../../apis/graphql';
import { TextField } from '../../../../common/Form';
import AssetImporterField from '../../../../common/Form/AssetImporterField';
import type { AdFormValues } from '../../adFormik';
import { AdFormCreativeFields } from '../../adFormik/fields';
import { isCreativeAssetRemoveForbidden } from '../../utils/creative';
import CreativeBuilder from '../CreativeBuilder';
import type { CreativeBuilderProps } from '../CreativeBuilder/CreativeBuilder';
import type { CreativesBuilderValues } from '../types';
import { CreativeEditMode } from '../types';

export const constructDisplayCreativeInput = ({
  name,
  image,
  altText,
}: CreativesBuilderValues): DisplayCreativeInputV5 => {
  const input: DisplayCreativeInputV5 = { name, type: 'DISPLAY', altText: altText! };
  if (image?.id !== '') input.imageId = image?.id;

  return input;
};

function DisplayCreativeBuilder(
  aspectRatio: number
): ({ isPreview, adTypeDimensions, editMode }: CreativeBuilderProps) => React.JSX.Element {
  return function DisplayCreativeBuilderWithAspectRatio({
    isPreview,
    adTypeDimensions,
    editMode,
  }: CreativeBuilderProps): JSX.Element {
    const {
      values: { creative },
    } = useFormikContext<AdFormValues>();
    const secondaryLabel = isPreview ? '' : ' (Required)';

    const assetReadonly = isCreativeAssetRemoveForbidden(editMode, creative);

    return (
      <CreativeBuilder classNameModifier="display" isPreview={isPreview} editMode={editMode}>
        <div className="grid-container">
          <AssetImporterField
            adTypeDimensions={adTypeDimensions?.display}
            aspectRatio={aspectRatio}
            assetType="DISPLAY"
            formFieldName={AdFormCreativeFields.Image}
            id="display-image-asset"
            isPreview={isPreview}
            label="Display Image Asset"
            secondaryLabel={secondaryLabel}
            readonly={assetReadonly}
          />
          <TextField
            className="image-alt-text"
            formFieldName={AdFormCreativeFields.AltText}
            id="image-alt-text"
            label="Image Alt Text"
            secondaryLabel={secondaryLabel}
            readOnly={editMode === CreativeEditMode.readonly}
            required
          />
        </div>
      </CreativeBuilder>
    );
  };
}

export default DisplayCreativeBuilder;
