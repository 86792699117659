import { gql } from '@apollo/client/core';

export const UPDATE_LINE_ITEM_PACING_TYPE = gql`
  mutation UpdatePacingType($id: ID!, $pacingType: String!) {
    updateLineItemPacingType: updateLineItemPacingTypeV5(input: { id: $id, pacingType: $pacingType }) {
      id
      pacingType
    }
  }
`;
