import React from 'react';

import { element } from '../../../components/Row/Row';
import type { GetLineItemsRowsParams } from '../getLineItemsRows';

const PriorityRow = ({
  tier,
  priority,
  priorityValue,
  isShowPriorityValues,
}: GetLineItemsRowsParams['values']['priority'] & { isShowPriorityValues: boolean }): React.JSX.Element => {
  return (
    <>
      {isShowPriorityValues && tier?.name && (
        <div>
          <div>
            <span className={element('value-title')}>TN:</span> {tier.name.displayName}
          </div>
          <div>
            <span className={element('value-title')}>TV:</span> {priority.name}
          </div>
          <div>
            <span className={element('value-title')}>PV:</span> {priorityValue}
          </div>
        </div>
      )}
    </>
  );
};

export default PriorityRow;
