import type { SequenceAdV5 } from '../../types';
import type { SequenceState } from '../state';

export const setSequenceAdsOrder = (state: SequenceState, ads: SequenceAdV5[]): SequenceState => {
  const sequencedAdsOrder: Map<string, number> = new Map();

  ads.forEach(({ id }, index) => {
    sequencedAdsOrder.set(id, index + 1);
  });

  return {
    ...state,
    sequencedAdsOrder,
  };
};
