import './TextInput.scss';

import { TextField } from '@hulu/react-style-components';
import React from 'react';

import { genericMemo } from '../../utils/genericMemo';

export type InputType = 'text' | 'email' | 'button' | 'password';

type Props<T> = {
  name: string;
  value: T;
  type?: InputType;
  placeholder?: string;
  errMsg?: string | React.ReactElement;
  className?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const TextInput = <T,>({
  className,
  disabled,
  name,
  value,
  errMsg,
  type,
  placeholder,
  onChange,
}: Props<T>): JSX.Element => {
  return (
    <div data-testid="form-input">
      <TextField
        id={value}
        formFieldName={name}
        type={type}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        errMsg={errMsg}
        className={className}
        onChange={onChange}
      />
    </div>
  );
};

export default genericMemo(TextInput);
