import type { TargetingTermValue } from '../../../models';
import { disableByDimension } from '../../../pages/Trafficking/DrawerTargetingTable/utils/utils';
import type { TargetingValueOption } from '../../AdForm/adFormik';
import type { DropdownOption } from '../../Dropdown';
import type { Category } from './useTargetingDropdowns';

export const createDropdownOptionFromTargeting = ({
  value: { id, displayName: label, name: value, description },
  dimension: { id: dimension },
}: Pick<TargetingTermValue, 'value' | 'dimension'>): TargetingValueOption => ({
  id,
  label,
  value,
  dimension,
  description,
  ...(description && {
    tooltip: {
      id: `${id}-tooltip`,
      message: description,
    },
  }),
});

export const formatForDropdown = (
  categoryValueType: Pick<Category, 'id' | 'displayName' | 'name' | 'description'>[]
): DropdownOption[] => {
  return categoryValueType.map(({ id, displayName: label, name: value, description = null }) => ({
    id,
    label,
    value,
    ...(description && {
      description,
      tooltip: {
        id: `${id}-tooltip`,
        message: description,
      },
    }),
    isDisabled: disableByDimension(id),
  }));
};
