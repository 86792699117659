import styled from 'styled-components';

import { theme } from '../../../../styles/styled';

export const MetaDataTable = styled.table`
  margin: 0.25em;
  word-break: break-word;
  th,
  td {
    font-size: 0.75em;
    padding: 0.2em 0;
  }
  th {
    width: 5.25em;
    font-weight: ${theme.constant.pageTitle.fontWeight};
    text-align: left;
  }
`;
