import type { CampaignClientErrors } from '../../../../../apis/graphql/graphqlAPI';

export const handleGraphQLErrors = (error: Error): string => {
  const errorMap: CampaignClientErrors = { [error.name]: [error?.message] };
  let errStr: string = '';

  Object.keys(errorMap).forEach((status) => {
    errorMap[status].forEach((msg) => (errStr = `${errStr}\n${msg}`));
  });

  return errStr;
};
