export enum AdFieldName {
  FrequencyCapList = 'frequency-cap-list',
  Tier = 'tier',
  Priority = 'priority',
  TargetingRule = 'targeting-rule',
  AdTagList = 'ad-tag-list',
  NonBillableAdTrackingList = 'non-billable-ad-tracking-list',
  ClickThrough = 'click-through',
  BillableAdTracking = 'billable-ad-tracking',
}
