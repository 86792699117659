import './AdTagsTable.scss';

import { some as _some } from 'lodash';
import React, { useEffect, useState } from 'react';

import type { AdTagType, UNRECOGNIZED } from '../../configs';
import { adTagTypes } from '../../configs';
import type { AdTag } from '../../models';
import bem from '../../utils/bem';
import type { ListHelpers } from '../../utils/listHelpers';
import { configToDropdownOption } from '../Dropdown';
import type { DropdownOption, DropdownProps } from '../Dropdown/Dropdown';
import { validateAndPerformActionOnURL } from '../utils';
import AdTagsTableRows from './AdTagsTableRows';
import NewAdTagRow from './NewAdTagRow';

export interface AdTagsTableProps {
  adTagList: AdTag[];
  listHelpers: Pick<ListHelpers<AdTag>, 'replaceAt' | 'push' | 'removeAt'>;
  isAdInitiallyWithBillableTag: boolean;
  unDeletableAd: boolean;
  readonly?: boolean;
}

const [block, element] = bem('ad-tags-table');

// Disable Billable or Click option if an Ad already has a tag of that type
export function getValidAdTagTypeDropdownOptions(
  adTagList: AdTag[],
  unDeletableAd: boolean
): DropdownOption<AdTagType>[] {
  return Object.values(adTagTypes)
    .map(configToDropdownOption)
    .map((option) => {
      const adHasBillableTag = _some(adTagList, { type: adTagTypes.BILLABLE });
      const adHasClickTag = _some(adTagList, { type: adTagTypes.CLICK });

      if (
        (adHasBillableTag && option.value === 'BILLABLE') ||
        (adHasClickTag && option.value === 'CLICK') ||
        (option.value === 'BILLABLE' && unDeletableAd)
      ) {
        return {
          ...option,
          isDisabled: true,
        };
      }

      return option;
    });
}

function AdTagsTable({
  adTagList,
  listHelpers,
  isAdInitiallyWithBillableTag,
  unDeletableAd,
  readonly,
}: AdTagsTableProps): JSX.Element {
  const addNewTag = (newAdTag: AdTag): void => {
    validateAndPerformActionOnURL(newAdTag.url, () => {
      listHelpers.push(newAdTag);
    });
  };

  const [commonTagTypeDropdownProps, setCommonTagTypeDropdownProps] = useState<
    Pick<DropdownProps<AdTagType | typeof UNRECOGNIZED>, 'classNameModifier' | 'isSearchable' | 'options'>
  >({
    classNameModifier: 'ad-tag-type',
    isSearchable: false,
    options: getValidAdTagTypeDropdownOptions(adTagList, unDeletableAd),
  });

  useEffect(() => {
    setCommonTagTypeDropdownProps((prevProps) => ({
      ...prevProps,
      options: getValidAdTagTypeDropdownOptions(adTagList, unDeletableAd),
    }));
  }, [adTagList, unDeletableAd]);

  return (
    <table className={block()}>
      <thead>
        <tr>
          <th className={element('type-head')}>Type</th>
          <th className={element('tag-head')}>Tag</th>
          <th className={element('event-head')}>Position</th>
          <th className={element('verified-head')}>Verified</th>
          <th className={element('remove-head')} />
        </tr>
      </thead>

      <tbody>
        <AdTagsTableRows
          adTagList={adTagList}
          listHelpers={listHelpers}
          commonTagTypeDropdownProps={commonTagTypeDropdownProps}
          isAdInitiallyWithBillableTag={isAdInitiallyWithBillableTag}
          isAdUnDeletable={unDeletableAd}
          disabled={readonly}
        />
        <NewAdTagRow
          addNewTag={addNewTag}
          commonTagTypeDropdownProps={commonTagTypeDropdownProps}
          readonly={readonly}
        />
      </tbody>
    </table>
  );
}

export default AdTagsTable;
