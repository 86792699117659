import type { MultipleEditDrawerAdsFormValues, MultipleEditDrawerLineItemsFormValues } from '../../../../../../types';
import type { LineItemStatusError } from '../../../MultipleEditDrawerContentThirdStep';
import type { BufferConflictIdsResult } from '../checkBufferConflicts/checkBufferConflicts';
import { checkBufferConflicts } from '../checkBufferConflicts/checkBufferConflicts';
import { checkPriorityConflicts } from '../checkPriorityConflicts/checkPriorityConflicts';
import { checkSchedulingConflicts } from '../checkSchedulingConflicts/checkSchedulingConflicts';
import { useCheckStatusConflicts } from '../checkStatusConflicts/useCheckStatusConflicts';
import { checkTagsConflicts } from '../checkTagsConflicts/checkTagsConflicts';
import type { TargetingConflictResult } from '../useCheckTargetingConflicts/useCheckTargetingConflicts';
import { useCheckTargetingConflicts } from '../useCheckTargetingConflicts/useCheckTargetingConflicts';
import { getLineItemConflictMessages } from './utils';

export type GetLineItemConflictMessagesResult = {
  errorUnapproved?: JSX.Element | null;
  errorInactiveAd?: JSX.Element | null;
};

type Props = {
  isLineItem: boolean;
  values: MultipleEditDrawerLineItemsFormValues | MultipleEditDrawerAdsFormValues;
};

export type UseGetAllConflictsResult = {
  targetingConflicts?: TargetingConflictResult;
  bufferConflicts?: BufferConflictIdsResult | null;
  priorityConflicts?: string[] | null;
  lineItemConflicts?: GetLineItemConflictMessagesResult;
  lineItemStatusErrors?: LineItemStatusError;
  adsStatusErrors?: Record<string, string[]> | null;
  schedulingConflicts?: Record<string, string[]> | null;
  tagsConflicts?: Record<string, string[]> | null;
  isTargetingErrorsLoading?: boolean;
  isLineItemStatusErrorLoading?: boolean;
};

export const useGetAllConflicts = ({ values, isLineItem }: Props): UseGetAllConflictsResult => {
  const statusError = useCheckStatusConflicts({ values: values as MultipleEditDrawerLineItemsFormValues, isLineItem });
  const targetingConflicts = useCheckTargetingConflicts({ values, isLineItem });

  if (isLineItem) {
    return {
      targetingConflicts: targetingConflicts.targetingConflicts,
      bufferConflicts: checkBufferConflicts({ values: values as MultipleEditDrawerLineItemsFormValues }),
      lineItemConflicts:
        statusError.lineItemStatusConflicts &&
        getLineItemConflictMessages({
          statusErrors: statusError.lineItemStatusConflicts,
        }),
      priorityConflicts: checkPriorityConflicts({ values: values as MultipleEditDrawerLineItemsFormValues }),
      lineItemStatusErrors: statusError.lineItemStatusConflicts,
      isLineItemStatusErrorLoading: statusError.lineItemStatusErrorLoading,
      isTargetingErrorsLoading: targetingConflicts.loading,
    };
  }

  return {
    targetingConflicts: targetingConflicts.targetingConflicts,
    adsStatusErrors: statusError.adsStatusConflicts,
    schedulingConflicts: checkSchedulingConflicts(values as MultipleEditDrawerAdsFormValues),
    isTargetingErrorsLoading: targetingConflicts.loading,
    tagsConflicts: checkTagsConflicts(values as MultipleEditDrawerAdsFormValues),
  };
};
