import type { FC, SyntheticEvent } from 'react';
import React, { memo, useMemo } from 'react';
import type { CellPropGetter, TableCellProps } from 'react-table';

import type { EntityModel } from '../../pages/Trafficking/TraffickingPage/modelConverters';
import { FOCUSABLE_ELEMENT_SELECTOR, SELECT_ROW_TOOLTIP } from '../constants';

type Props = {
  selectedColIndex: number;
  selectedRowIndex: number;
  colIndex: number;
  isEditable?: boolean;
  rowIndex: number;
  className?: string;
  maxWidth?: number;
  getCellProps: (propGetter?: CellPropGetter<EntityModel>) => TableCellProps;
  render: (type: string) => React.ReactNode;
  isPossibleToSelectRow?: boolean;
};

const CellElement: FC<Props> = ({
  selectedColIndex,
  selectedRowIndex,
  colIndex,
  isEditable,
  rowIndex,
  className = '',
  maxWidth,
  getCellProps,
  render,
  isPossibleToSelectRow,
}) => {
  const additionalCellProps = useMemo(() => ({ style: { maxWidth: maxWidth } }), [maxWidth]);
  const hasEditableClass = isEditable || false;
  const isCellSelected = colIndex === selectedColIndex && rowIndex === selectedRowIndex;

  const onTableDataClick = (e: SyntheticEvent): void => {
    if (!isPossibleToSelectRow) {
      e.stopPropagation();
    }
  };

  return (
    <td
      {...getCellProps(additionalCellProps)}
      className={`${hasEditableClass ? 'editable' : 'not-editable'} ${isCellSelected ? 'selected' : ''} ${className}`}
      // https://developer.mozilla.org/en-US/docs/Web/Accessibility/Keyboard-navigable_JavaScript_widgets#managing_focus_inside_groups
      // we're using the "roving tabindex" practice of programmatically managing focused elements via keyboard arrow keys,
      // and treating the table as a single tab-able element with no tab accessible children
      //
      // NOTE: in order for this to work, all internal elements must be outside the tabIndex and have a tabIndex={-1}
      // reference for tab-able elements: https://allyjs.io/data-tables/focusable.html
      tabIndex={isCellSelected ? 0 : -1}
      data-row={rowIndex}
      data-col={colIndex}
      onFocus={(e): void => {
        const focusable = e.target.querySelector(FOCUSABLE_ELEMENT_SELECTOR) as HTMLElement;
        focusable?.focus();
      }}
      onClick={onTableDataClick}
      title={isPossibleToSelectRow ? SELECT_ROW_TOOLTIP : ''}
    >
      {render('Cell')}
    </td>
  );
};

export default memo(CellElement);
