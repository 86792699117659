import './ViewabilityVendorCell.scss';

import { Modal } from 'antd';
import { Formik } from 'formik';
import { bem, User } from 'mission-control-common-components';
import React from 'react';
import type { Cell } from 'react-table';

import { getInitialIASCampaignLineItemId } from '../../../../../common/AdForm/adFormik';
import EditableCell from '../../../../../common/EditableTableCells/EditableCell';
import type { PermissionsNames } from '../../../../../constants';
import type { AdModel } from '../../modelConverters';
import { useViewabilityVendorModal, useViewabilityVendorUpdate } from './hooks';
import ViewabilityVendorForm from './ViewabilityVendorForm';

const [block, element] = bem('viewability-vendor-cell');

function ViewabilityVendorCell(cell: Cell<AdModel, AdModel['viewabilityVendorList']>): JSX.Element {
  const permissions = User.getPermissions<PermissionsNames>();
  const { value: viewabilityVendorList, row } = cell;

  const { isModalOpened, onModalClose, onModalOpen, onSuccess } = useViewabilityVendorModal();
  const { loading, handleSubmit } = useViewabilityVendorUpdate({ onSuccess, cell });

  const viewabilityVendorLabel = viewabilityVendorList.map(({ displayName }) => displayName).join(', ');

  const initialValues = {
    viewabilityVendorList,
    iasCampaignLineItemId: getInitialIASCampaignLineItemId(row.original.adTagList),
    adTagList: row.original.adTagList,
    newAdTag: '',
  };

  const isReadonly = !permissions?.updateAd || loading;

  return (
    <EditableCell onClick={onModalOpen} variant={'base'} tabIndex={0} usePencil={true}>
      <div className={block()}>
        <div className={element('label')}>{viewabilityVendorLabel}</div>
        {isModalOpened && (
          <Modal
            open={isModalOpened}
            footer={null}
            onCancel={onModalClose}
            width={800}
            data-testid="viewability-vendor-modal"
            className={element('modal')}
          >
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({ submitForm }): React.JSX.Element => (
                <ViewabilityVendorForm
                  readonly={isReadonly}
                  onSubmit={submitForm}
                  onCancel={onModalClose}
                  loading={loading}
                />
              )}
            </Formik>
          </Modal>
        )}
      </div>
    </EditableCell>
  );
}

export default ViewabilityVendorCell;
