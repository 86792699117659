export const getInitialRowsValue = (values: boolean[][]): boolean[] =>
  values.map((row) => (row.some((item) => item === false) ? false : true));

export const getInitialColumnsValue = (values: boolean[][]): boolean[] => {
  let result: boolean[] = new Array(24).fill(true);
  values.forEach((hours, i) => {
    hours.forEach((_, j) => {
      if (!values[i][j]) {
        result[j] = false;
      }
    });
  });

  return result;
};
