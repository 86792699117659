import './Targeting.scss';

import React, { useMemo } from 'react';

import { useFieldArray } from '../../../common/Form/hooks';
import TargetingDropdowns from '../../../common/TargetingDropdowns';
import type { TargetingTableRowGroup } from '../../../common/TargetingTable/TargetingTable';
import TargetingTable from '../../../common/TargetingTable/TargetingTable';
import type { TargetingTermValue } from '../../../models';
import {
  addCountryCode,
  filterOutCountryTarget,
  getCountryTargetLineItem,
  getPublisherTargetLineItem,
} from '../../../pages/Trafficking/DrawerTargetingTable/utils/utils';
import bem from '../../../utils/bem';

const [block, element] = bem('targeting');

export type TargetingProps = {
  lineItemTargetingTermValues: TargetingTermValue[];
  publisher: string;
  readonly?: boolean;
  countryCode?: string;
  publisherIds?: string[];
};

function Targeting({
  lineItemTargetingTermValues,
  publisher,
  readonly,
  countryCode,
  publisherIds,
}: TargetingProps): JSX.Element {
  const [
    ,
    { value: adTargetingTermValues, error },
    { insertAt, removeAt, replaceAt, removeAll },
  ] = useFieldArray<TargetingTermValue>('targetingTermValues');
  const data: TargetingTableRowGroup[] = useMemo(
    (): TargetingTableRowGroup[] => [
      {
        groupType: 'Ad Targeting',
        targetingTermValues: adTargetingTermValues,
      },
      {
        groupType: 'Line Item Targeting',
        targetingTermValues: filterOutCountryTarget({ value: lineItemTargetingTermValues }) ?? [],
      },
    ],
    [adTargetingTermValues, lineItemTargetingTermValues]
  );
  let countryLineItem = getCountryTargetLineItem({ value: lineItemTargetingTermValues });
  countryLineItem = addCountryCode(countryLineItem, countryCode);

  return (
    <div className={block()}>
      <p className={element('country')}>Country: {countryLineItem?.value.name}</p>
      <p className={element('publisher')}>Publisher: {publisher}</p>
      <TargetingDropdowns
        listHelpers={{ insertAt, replaceAt, removeAt }}
        targetings={adTargetingTermValues}
        readonly={readonly}
        validationError={error}
        countryOption={countryLineItem || undefined}
        publisherOption={getPublisherTargetLineItem(publisherIds || []) || undefined}
      />
      <TargetingTable
        data={data}
        listHelpers={{ removeAt, replaceAt, removeAll }}
        editMode={!readonly}
        isAd
        isReadOnly
        isTableToggleable
      />
    </div>
  );
}

export default Targeting;
