import './ChangeLogTable.scss';

import React, { useMemo } from 'react';
import type { Cell as CellType, CellProps, Column } from 'react-table';
import { useTable } from 'react-table';

import type {
  MultiValueChange as MultiValueChangeGql,
  Scalars,
  SimpleChangeValue,
  SingleValueChange,
} from '../../../apis/graphql';
import { AdFieldName } from '../../../configs/adFieldNames';
import type { CustomColumnConfig } from '../../../pages/Trafficking/TraffickingPage/columns';
import bem from '../../../utils/bem';
import { ToggleSection } from '../../ToggleSection';
import ChangeLogTablesList from '../ChangeLogTablesList/ChangeLogTablesList';
import ChangeLogTitle from '../ChangeLogTitle/ChangeLogTitle';
import { ROWS_TO_IGNORE, ROWS_TO_IGNORE_DUPLICATE } from '../constants';
import type { ChangeLogRecord, NewChangeLogTablePropsWithCreatives } from '../types';
import { hasDuplicates } from '../utils';
import { Cell } from './Cell';
import { getClassName } from './utils/changeLogTableHelpers';
import { mapTermList } from './utils/mapTermList';

const [block, element] = bem('change-log-table');

export type MultiValueChange = Omit<MultiValueChangeGql, 'oldValues' | 'newValues'> & {
  oldValue: MultiValueChangeGql['oldValues'];
  newValue: MultiValueChangeGql['newValues'];
};
type CellValue = CellType<ChangeLogRecord> & { column: { className?: string } };

const fieldNameDisplayName: Record<Scalars['String'], string> = {
  'actual-start': 'Actual Start Date',
  'ad-tag-list': 'Ad Tags',
  'creative-max-seconds': 'Max Duration',
  definition: 'Targeting',
  [AdFieldName.FrequencyCapList]: 'Freq Caps',
  [`${AdFieldName.FrequencyCapList}.limit`]: 'Freq Caps Limit',
  [`${AdFieldName.FrequencyCapList}.duration`]: 'Freq Caps Count',
  [`${AdFieldName.FrequencyCapList}.duration-unit`]: 'Freq Caps Unit',
  'line-item-list': 'Line Items',
  'viewability-vendor-list': 'Viewability Vendor',
  'ad-rotation.rotation-name': 'Name',
  'ad-rotation.rotation-id': 'Id',
  'ad-rotation.weight': 'Weight',
  //  Ad list fields
  'ad-list.id': 'Id',
  'ad-list.name': 'Name',
};

function FormatFieldCell({ value }: CellProps<SingleValueChange>): string {
  // Replace dashes with spaces, capitalize first letter of word
  const fallbackValue = value
    .split('type-properties.')
    .pop()
    .replace(/([A-Z])/g, ' $1')
    .replace(/(-|^)([^-]?)/g, function (_: string, toReplace: string, firstLetter: string) {
      return (toReplace && ' ') + firstLetter.toUpperCase();
    });
  return fieldNameDisplayName[value] ? fieldNameDisplayName[value] : fallbackValue;
}

function ChangeLogTable({
  changes,
  subHeaderTitle,
  isLineThrough,
  targetingEntry,
  creativeTablesProps,
  creativeDateFormat,
  creativeModifiedBy,
  parentIndex,
  toggles,
  toggleAt,
}: NewChangeLogTablePropsWithCreatives): JSX.Element {
  const originColumns: CustomColumnConfig<ChangeLogRecord>[] = useMemo(
    () => [
      { Header: 'Field', accessor: 'fieldName', Cell: FormatFieldCell },
      {
        Header: 'Previous',
        accessor: 'oldValue',
        Cell,
        className: isLineThrough ? 'line-through' : '',
      },
      { Header: 'New', accessor: 'newValue', Cell },
    ],
    [isLineThrough]
  );
  const columns: Column<ChangeLogRecord>[] = useMemo(
    (): Column<ChangeLogRecord>[] =>
      subHeaderTitle
        ? [
            {
              Header: subHeaderTitle,
              columns: originColumns,
              id: 'headerTitle',
            },
          ]
        : originColumns,
    [subHeaderTitle, originColumns]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<ChangeLogRecord>({
    columns,
    data: changes,
  });

  const handleToggle = (): void => {
    toggleAt?.(parentIndex!, [!(toggles?.[parentIndex!] as boolean[])[0], (toggles?.[parentIndex!] as boolean[])[1]]);
  };

  return (
    <>
      <table {...getTableProps()} className={block()} cellSpacing={0} cellPadding={0}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th tabIndex={-1} {...column.getHeaderProps()} className={i === 0 ? element('field') : ''}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            const isRowIgnored = !ROWS_TO_IGNORE.includes(row.original.fieldName);

            const hasRowDuplicates = !hasDuplicates(
              row.values.newValue as SimpleChangeValue,
              row.values.oldValue as SimpleChangeValue
            );

            const ignoreRowDuplicate = ROWS_TO_IGNORE_DUPLICATE.includes(row.original.fieldName);

            return (
              isRowIgnored &&
              (ignoreRowDuplicate || hasRowDuplicates) && (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: CellValue, i) => {
                    const className = getClassName(cell.column.className, ignoreRowDuplicate);

                    if (cell.row.values.oldValue.payload?.definition?.['term-list'] && targetingEntry) {
                      cell.row.values.oldValue.payload.definition['term-list'] = mapTermList(
                        targetingEntry?.old?.termList || []
                      );
                      cell.row.values.newValue.payload.definition['term-list'] = mapTermList(
                        targetingEntry?.new?.termList || []
                      );
                    }

                    return (
                      <td
                        {...cell.getCellProps([{ className: i === 0 ? element('field') + className : className }])}
                        tabIndex={0}
                        key={`change-log-cell-${i}`}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              )
            );
          })}
        </tbody>
      </table>
      {creativeTablesProps?.changesLength! > 0 && (
        <>
          <ToggleSection
            isExpanded={(toggles?.[parentIndex!] as boolean[])[1]}
            title={
              <ChangeLogTitle
                id="test"
                changesPlural={creativeTablesProps?.changesPlural || ''}
                formattedDate={creativeDateFormat || ''}
                modifiedBy={creativeModifiedBy}
              />
            }
            handleToggle={handleToggle}
          >
            <ChangeLogTablesList tablesProps={creativeTablesProps?.tablesProps} />
          </ToggleSection>
        </>
      )}
    </>
  );
}

export default ChangeLogTable;
