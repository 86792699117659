import { css } from 'styled-components';

// Hulu design tokens
export const design = {
  black1: 'rgba(39, 44, 53, 0.9)',
  black2: 'rgba(39, 44, 53, 0.8)',
  black3: 'rgba(39, 44, 53, 0.7)',
  black4: 'rgba(39, 44, 53, 0.6)',
  black5: 'rgba(39, 44, 53, 0.5)',
  black6: 'rgba(39, 44, 53, 0.4)',
  black7: 'rgba(39, 44, 53, 0.3)',
  black8: 'rgba(39, 44, 53, 0.2)',
  black9: 'rgba(39, 44, 53, 0.1)',
  fauxBlack: '#040405',

  blue1: '#3482ea',
  blue2: '#5998ee',
  blue3: '#7caef1',
  blue4: '#a0c5f5',
  blue5: '#c2daf9',
  blue6: '#e7f1fd',
  blueBase: '#176ee1',

  gray1: '#272c35',
  gray2: '#646f85',
  gray3: '#b9bfcb',
  gray4: '#dde0e6',
  gray5: '#f0f1f5',
  gray6: '#f7f7f9',

  green1: '#4cc7ac',
  green2: '#6cd1ba',
  green3: '#8bdbc9',
  green4: '#abe5d8',
  green5: '#caefe7',
  green6: '#eaf8f5',
  greenBase: '#2dbd9d',

  purple1: '#9245bd',
  purple2: '#a566c9',
  purple3: '#b887d4',
  purple4: '#cba7e0',
  purple5: '#dec8eb',
  purple6: '#f2e9f7',
  purpleBase: '#7f25b2',

  red1: '#e33d66',
  red2: '#e85f81',
  red3: '#ed829c',
  red4: '#f2a4b7',
  red5: '#f7c6d2',
  red6: '#fce8ed',
  redBase: '#de1b4b',

  white1: 'rgba(255, 255, 255, 0.9)',
  white2: 'rgba(255, 255, 255, 0.8)',
  white3: 'rgba(255, 255, 255, 0.7)',
  white4: 'rgba(255, 255, 255, 0.6)',
  white5: 'rgba(255, 255, 255, 0.5)',
  white6: 'rgba(255, 255, 255, 0.4)',
  white7: 'rgba(255, 255, 255, 0.3)',
  white8: 'rgba(255, 255, 255, 0.2)',
  white9: 'rgba(255, 255, 255, 0.1)',
  white: '#ffffff',

  yellow1: '#fab834',
  yellow2: '#fbc558',
  yellow3: '#fcd17c',
  yellow4: '#fddd9f',
  yellow5: '#fdeac3',
  yellow6: '#fff7e7',
  yellowBase: '#faac11',
};

// Page
export const page = {
  pageBackground: design.gray6,
  whiteBackground: design.white,
};

// Text
export const text = {
  black: design.fauxBlack,
  dark: design.gray1,
  light: design.gray3,
};

// Borders
export const borders = {
  dark: design.gray1,
  light: design.gray3,
  header: design.gray4,
};

// Shadow
export const boxShadow = {
  dark: design.black5,
  light: design.black9,
};

// Links
export const links = {
  blue: design.blue1,
};

// Rows
export const rows = {
  onHover: design.gray5,
};

// Custom Colors
export const custom = {
  redDark: '#b91740',
  redInputError: '#de1b4b',
  blueDark: '#135dbf',
  blueDark1: '#1352a6',
  greenDark: '#0a764f',
  greyDark: '#212329',
  titleCray: '#292c33',
  brandPrimary: '#26d997',
  black: '#000000',
};

// Filters (for use with CSS rules for SVG img elements)
export const mixins = {
  filters: {
    white: css`
      filter: brightness(255) saturate(100%);
    `,
    gray1: css`
      filter: invert(15%) sepia(10%) saturate(1013%) hue-rotate(180deg) brightness(93%) contrast(91%);
    `,
    gray2: css`
      filter: brightness(0) saturate(100%) invert(44%) sepia(11%) saturate(747%) hue-rotate(182deg) brightness(95%)
        contrast(92%);
    `,
    gray3: css`
      filter: brightness(0) saturate(100%) invert(87%) sepia(14%) saturate(183%) hue-rotate(182deg) brightness(87%)
        contrast(89%);
    `,
    fauxBlack: css`
      filter: brightness(0) saturate(100%) invert(3%) sepia(5%) saturate(726%) hue-rotate(201deg) brightness(91%)
        contrast(102%);
    `,
    redBase: css`
      filter: invert(19%) sepia(65%) saturate(3626%) hue-rotate(330deg) brightness(98%) contrast(100%);
    `,
    red2: css`
      filter: invert(49%) sepia(66%) saturate(947%) hue-rotate(308deg) brightness(95%) contrast(91%);
    `,
    blue1: css`
      filter: invert(48%) sepia(50%) saturate(5129%) hue-rotate(200deg) brightness(98%) contrast(87%);
    `,
    blue2: css`
      filter: invert(41%) sepia(93%) saturate(5892%) hue-rotate(207deg) brightness(99%) contrast(82%);
    `,
  },
};
