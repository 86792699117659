import './EditableCellModal.scss';

import { IconCheck, IconClose } from '@hulu-react-style-components/icons';
import { colorGray1 } from '@hulu/design-tokens/lib/colors.common';
import { Alert as TrekAlert, AlertHeader as TrekAlertHeader } from '@hulu/react-style-components';
import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { useClickAway } from 'react-use';

import useFocusTrap from '../../../common/hooks/useFocusTrap';
import bem from '../../../utils/bem';

export interface EditableCellModalProps {
  children: React.ReactNode;
  errorMessage?: string;
  onCancel: () => void;
  onSubmit: () => void;
  onClickAway?: () => void;
  hasValue?: boolean;
}

const [block, element] = bem('editable-cell-modal');

const EditableCellModal = ({
  children,
  errorMessage,
  onCancel,
  onSubmit,
  onClickAway,
  hasValue,
  ...props
}: EditableCellModalProps): JSX.Element => {
  const modalRef = useRef<HTMLDivElement>(null);
  const focusRef = useFocusTrap<HTMLDivElement>(['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']);

  useClickAway(modalRef, (e: MouseEvent) => {
    e.stopPropagation();
    // allows customization of dropdown close behavior
    onClickAway ? onClickAway() : onCancel();
  });

  const handleCancel = (e: React.MouseEvent): void => {
    e.stopPropagation();
    onCancel();
  };

  const handleSubmit = (e: React.MouseEvent): void => {
    e.stopPropagation();
    onSubmit();
  };

  return (
    <div ref={modalRef} className={block()} {...props}>
      <div ref={focusRef} className={element('wrapper')}>
        <div className={element('content')}>{children}</div>
        <div className={element('buttons')}>
          {errorMessage && (
            <TrekAlert variant="error" className={element('error-message')}>
              <TrekAlertHeader cta={errorMessage} />
            </TrekAlert>
          )}
          <button className={element('cancel-btn')} onClick={handleCancel} aria-label="cancel button">
            <IconClose title="Cancel Changes" />
          </button>
          <button
            className={element('submit-btn')}
            onClick={handleSubmit}
            aria-label="submit button"
            data-for="submit button"
            data-tip="Fill out the required field"
            data-place="bottom"
          >
            <IconCheck title="Submit Changes" />
          </button>
          {!hasValue ? (
            <ReactTooltip
              id="submit button"
              className={element('tooltip')}
              backgroundColor={colorGray1}
              effect="solid"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EditableCellModal;
