import './DrawerTargetingHeader.scss';

import React from 'react';

import ModeControlButton from '../../../../common/AdSideNavScheduling/ModeControlButton';
import type { Props as ModeControlButtonsProps } from '../../../../common/AdSideNavScheduling/ModeControlButton/ModeControlButton';
import bem from '../../../../utils/bem';
import DrawerTitle from '../../DrawerTitle';

const [block] = bem('drawer-targeting-table-header');

function DrawerTargetingHeader(props: ModeControlButtonsProps): JSX.Element {
  return (
    <div className={block()}>
      <DrawerTitle title="Targeting" />
      <ModeControlButton {...props} />
    </div>
  );
}

export default DrawerTargetingHeader;
