import { useMutation } from '@apollo/client';

import type {
  CampaignInputV5,
  UpdateCampaignMutation,
  UpdateCampaignMutationVariables,
} from '../../../../apis/graphql';
import { campaignClient } from '../../../../apis/graphql';
import { CAMPAIGN_TRAFFICKING_COLUMNS } from './fragments';
import { UPDATE_TRAFFICKING_PAGE_CAMPAIGN } from './mutations';
import useCellVariant from './useCellVariant';

export type UseUpdateCampaign = {
  cellVariant: ReturnType<typeof useCellVariant>;
  updateCampaignField: (newValue: CampaignInputV5[keyof CampaignInputV5]) => Promise<void>;
};

const useUpdateCampaign = (campaignId: string, inputFieldName: keyof CampaignInputV5): UseUpdateCampaign => {
  const [updateCampaign, mutationResult] = useMutation<UpdateCampaignMutation, UpdateCampaignMutationVariables>(
    UPDATE_TRAFFICKING_PAGE_CAMPAIGN
  );
  const cellVariant = useCellVariant(mutationResult);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const updateCampaignField = async (newValue: CampaignInputV5[typeof inputFieldName]) => {
    await updateCampaign({
      variables: {
        id: campaignId,
        campaignInput: {
          [inputFieldName]: newValue,
        },
      },
      update: (cache, { data }) => {
        const normalizedId = cache.identify({ id: campaignId, __typename: 'CampaignV5' });
        const oldCacheData = campaignClient.readFragment({
          fragment: CAMPAIGN_TRAFFICKING_COLUMNS,
          id: normalizedId,
        });

        const updatedData = {
          ...oldCacheData,
          ...data?.updateCampaign,
          __typename: 'CampaignV5',
        };

        campaignClient.writeFragment({
          fragment: CAMPAIGN_TRAFFICKING_COLUMNS,
          data: updatedData,
          id: normalizedId,
        });
      },
    }).catch((error) => console.log(error));
  };

  return {
    cellVariant,
    updateCampaignField,
  };
};

export default useUpdateCampaign;
