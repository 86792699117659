import type { DeselectOptionActionMeta, SelectOptionActionMeta } from 'react-select';

import type { Nullable, TargetingTermValue } from '../../models';
import type { TargetingValueOption } from '../AdForm/adFormik';
import type { DropdownOption } from '../Dropdown';
import { ActionTypes, isClearAllOption } from '../MultiSelectDropdown';
import type { MultiSelectTargetingValue } from './types';

export const getEmptyTextMessage = (loading: boolean, requiredEntry: Nullable<DropdownOption>): string => {
  if (!requiredEntry) return 'Select a category and type first';

  return loading ? 'Loading...' : 'Select';
};

export const formatOptionsToDropdownOptions = (options: MultiSelectTargetingValue): DropdownOption[] =>
  options.map(({ id, value, label, tooltip, description }) => ({
    id,
    value,
    label,
    ...(tooltip && { tooltip }),
    ...(description && { description }),
  }));

export const formatOptionsToTargetingValueOptions = (
  options: MultiSelectTargetingValue,
  selectedDimension: DropdownOption,
  selectedValues: TargetingValueOption[]
): TargetingValueOption[] =>
  selectedDimension.id
    ? options.map(
        ({ id, value, label, tooltip, description }) =>
          ({
            id,
            value,
            label,
            dimension: selectedDimension.id,
            ...(tooltip && { tooltip }),
            ...(description && { description }),
          } as TargetingValueOption)
      )
    : selectedValues;

export const getUnselectedValues = (
  targeting: Pick<TargetingTermValue, 'dimension' | 'value'>[],
  selectedDimension: Nullable<DropdownOption>,
  selectedValues: TargetingValueOption[]
): string[] =>
  targeting.reduce<string[]>((unselected, { dimension, value }) => {
    const isDimensionsEqual = dimension.id === selectedDimension?.id;
    const isValueSelected = selectedValues.find(
      (selected) => selected.value === value?.name && selected.id === value?.id
    );

    if (isDimensionsEqual && !isValueSelected) return [...unselected, value.id];

    return unselected;
  }, []);

export const getCurrentSelectedValues = (
  currentSelected: DropdownOption[],
  metas: Array<SelectOptionActionMeta<DropdownOption> | DeselectOptionActionMeta<DropdownOption>>
): DropdownOption[] => {
  const newlyCurrVals = [...currentSelected];

  metas.forEach((meta) => {
    if (meta.action === ActionTypes.select) {
      newlyCurrVals.push(meta.option!);
    } else {
      let idx = -1;

      newlyCurrVals.forEach((val, key) => {
        if (val.id === meta.option?.id) {
          idx = key;
        }
      });

      if (idx > -1) {
        newlyCurrVals.splice(idx, 1);
      }
    }
  });

  return newlyCurrVals;
};

export const getSelectedValues = (
  selectedValues: TargetingValueOption[],
  meta: SelectOptionActionMeta<DropdownOption> | DeselectOptionActionMeta<DropdownOption>,
  selectedDimension: Nullable<DropdownOption>
): TargetingValueOption[] => {
  if (isClearAllOption(meta)) return selectedValues.filter(({ dimension }) => dimension !== selectedDimension?.id);

  if (meta.action === ActionTypes.select) {
    if (!selectedDimension?.id) return selectedValues;

    return [...selectedValues, { ...meta.option!, dimension: selectedDimension.id }];
  }

  return selectedValues.filter(({ id }) => id !== meta.option?.id);
};

export const hasValuesChanged = (newValues: TargetingValueOption[], selectedValues: TargetingValueOption[]): boolean =>
  newValues.length !== selectedValues.length || !newValues.every((v, i) => v.value === selectedValues[i].value);

export const getNoOptionsMessage = (searching: boolean, supportEmptySearch: boolean, searchTerm: string): string => {
  if (searching) return 'Loading';

  return supportEmptySearch || searchTerm ? 'No Options' : 'Start typing to find options';
};
