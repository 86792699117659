import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

export type Review = keyof typeof reviewDisplayNames;

// Review Statuses for Ads and Line Items
const reviewDisplayNames = {
  DRAFT: enumToTitleCase,
  UNDER_REVIEW: enumToTitleCase,
  APPROVED: enumToTitleCase,
};

export const reviews = makeConfig<Review>(reviewDisplayNames);

export function convertReview(apiValue: string): DisplayEnum<Review> {
  return convertToDisplayEnum(apiValue, reviews);
}
