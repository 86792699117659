import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';

import type {
  TargetingPreset,
  TargetingPresetEdge,
  TargetingPresetFilterOptions,
  TargetingPresetPage,
  TargetingPresetSortOptions,
} from '../../../../apis/graphql';
import type { Nullable } from '../../../../models';
import { GET_TARGETING_PRESETS, GET_TARGETING_PRESETS_WITH_TARGETING_TERMS } from './queries/getTargetingPresets';

type PresetListQuery = {
  targetingPresets: TargetingPreset[];
  total?: number;
  loading: boolean;
  error?: ApolloError;
};

export const DEFAULT_LIMIT = 75;

export const nodeToPresetList = (edges: TargetingPresetEdge[]): TargetingPreset[] | [] =>
  edges && edges.length ? edges.map(({ node }: TargetingPresetEdge) => node) : [];

const useFetchTargetingPresets = ({
  offset,
  archived = false,
  idList,
  includeTargetingTerms = false,
  skip = false,
}: {
  offset?: number;
  archived: Nullable<boolean>;
  idList?: string[];
  sortOrder?: string;
  includeTargetingTerms?: boolean;
  skip?: boolean;
}): PresetListQuery => {
  const paginationOptions = {
    limit: DEFAULT_LIMIT,
    offset: offset || 0,
  };
  const filterOptions: TargetingPresetFilterOptions = {
    archived: archived,
    idList: idList || [],
  };

  const sortOptions: TargetingPresetSortOptions = {
    sortBy: 'updatedAt',
    sortOrder: 'ASC',
  };

  const { data, loading, error } = useQuery<{ getTargetingPresets: TargetingPresetPage }>(
    includeTargetingTerms ? GET_TARGETING_PRESETS_WITH_TARGETING_TERMS : GET_TARGETING_PRESETS,
    {
      variables: { paginationOptions, filterOptions, sortOptions },
      skip,
      notifyOnNetworkStatusChange: true,
    }
  );

  const targetingPresets = nodeToPresetList(data?.getTargetingPresets.edges as TargetingPresetEdge[]);
  const total = data?.getTargetingPresets?.total;

  return { targetingPresets, total, loading, error };
};

export default useFetchTargetingPresets;
