import './TargetingTable.scss';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import type { CellProps, Column } from 'react-table';
import { useFlexLayout, useSortBy, useTable } from 'react-table';

import type { TargetingTermValue } from '../../models';
import bem from '../../utils/bem';
import type { ListHelpers } from '../../utils/listHelpers';
import { workWithLocalStorage } from '../../utils/storage/localStorage';
import SortArrow from '../SortArrow';
import { SortState } from '../SortArrow/constants';
import { DIMENSION_INCLUDE_EXCLUDE_LIST } from '../TargetingDropdowns/constants';
import { ToggleSection } from '../ToggleSection';
import { LOCAL_STORAGE_EXPANDABLE_ROWS_STATE, MIN_COLLAPSIBLE_ROW_GROUP_SIZE } from './constants';
import TargetingTableActionCell from './TargetingTableActionCell';
import { assignIdFromUnsortedData, changeSortState, sortTargetingTableRowGroups } from './utils';
import { buildRowGroups, generateRow, generateToggleRow, getExpandCollapseText } from './utils/helpers';

export type TargetingTableRowGroup = {
  groupType?: string;
  entityName?: string;
  targetingTermValues: TargetingTermValue[];
};

type ListHelpersPartial = Pick<ListHelpers<TargetingTermValue>, 'removeAt' | 'replaceAt' | 'removeAll'>;

export interface TargetingTableProps {
  data: TargetingTableRowGroup[];
  listHelpers?: ListHelpersPartial;
  editMode?: boolean;
  isAd?: boolean;
  isReadOnly?: boolean;
  isTableToggleable?: boolean;
}

const [block, element] = bem('targeting-table');

function TargetingValueCell({ value: cellValue, ...rest }: CellProps<TargetingTermValue>): JSX.Element {
  if (!cellValue || !cellValue?.displayName) return <>No Value found</>;
  return cellValue.displayName;
}

function getAgeMinMax(values: string[]): string {
  const valFilter: number[] = values.filter((val) => !Number.isNaN(val)).map((val) => parseInt(val, 10));
  const lower: number = Math.min(...valFilter);
  const upper: number | undefined = values.length > 1 ? Math.max(...valFilter) : undefined;

  return `${lower}${upper ? ' - ' + upper : ''}`;
}

function TargetingTable({
  data,
  listHelpers,
  editMode = true,
  isAd,
  isReadOnly = true,
  isTableToggleable = true,
}: TargetingTableProps): JSX.Element {
  const [expandedRows, setExpandedRows] = useState<boolean[]>(new Array(data.length).fill(true));
  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>({});
  const [tableSort, setTableSort] = useState(SortState.UNSORTED);

  const handleExpandCollapseClick = (): void => {
    setExpandedGroups((prevExpandedGroups) => {
      const allExpanded = Object.values(prevExpandedGroups).every((isExpanded) => isExpanded === true);

      const newExpandedGroups = Object.keys(prevExpandedGroups).reduce<Record<string, boolean>>((acc, group) => {
        acc[group] = !allExpanded;
        return acc;
      }, {});

      return newExpandedGroups;
    });
  };

  const columns: Column<TargetingTermValue>[] = useMemo(
    (): Column<TargetingTermValue>[] => {
      const actionColumn: Column<TargetingTermValue> = {
        Header: (): React.JSX.Element => {
          return (
            <button className={element('expand-collapse-text')} onClick={handleExpandCollapseClick}>
              {getExpandCollapseText(expandedGroups)}
            </button>
          );
        },
        accessor: 'include',
        Cell: (props): JSX.Element => {
          return (
            <TargetingTableActionCell
              editMode={editMode}
              listHelpers={listHelpers as ListHelpersPartial}
              isLineItemTargetingTermValue={props.isLineItemTargetingTermValue}
              changeToggleByDimension={changeToggleByDimension}
              {...props}
            />
          );
        },
        disableSortBy: true,
        maxWidth: 100,
      };

      return [
        {
          Header: 'Category',
          accessor: (a): string => a?.category?.displayName || 'No Category found',
          maxWidth: 100,
        },
        {
          Header: 'Type',
          accessor: (a): string => a?.dimension?.displayName || 'No Dimension found',
          disableSortBy: true,
          maxWidth: 80,
        },
        {
          Header: 'Value',
          accessor: 'value',
          Cell: TargetingValueCell,
          disableSortBy: true,
          maxWidth: 140,
        },
        ...(isReadOnly && listHelpers ? [actionColumn] : []),
      ];
    },
    // eslint-disable-next-line
    [editMode, listHelpers, expandedGroups]
  );

  const sortedData = useMemo(() => sortTargetingTableRowGroups(data, tableSort), [data, tableSort]);
  const targetingTermValuesData = useMemo(
    () =>
      sortedData.reduce(
        (allTermValues: TargetingTermValue[], { targetingTermValues }) => [...allTermValues, ...targetingTermValues],
        []
      ),
    [sortedData]
  );

  const tableInstance = useTable<TargetingTermValue>(
    {
      columns,
      data: targetingTermValuesData,
    },
    useSortBy,
    useFlexLayout
  );

  useEffect(() => {
    setTableSort(SortState.UNSORTED);
  }, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
  const changeToggleByDimension = (value: boolean, row: TargetingTermValue, subGroupType: string): void => {
    const type: string = row.dimension.name;
    const listGroup = sortedData.find((group) => group.groupType === subGroupType);

    if (!DIMENSION_INCLUDE_EXCLUDE_LIST.includes(row.dimension.id)) {
      // update all include values of the same dimensions
      listGroup?.targetingTermValues.forEach((rowToUpdate, idx) => {
        const currType: string = rowToUpdate.dimension.name;
        if (currType === type) {
          listHelpers?.replaceAt(idx, { ...rowToUpdate, include: value });
        }
      });
    } else {
      listHelpers?.replaceAt(listGroup?.targetingTermValues?.indexOf(row) ?? 0, { ...row, include: value });
    }
  };

  const handleClearSelections = (e: React.MouseEvent): void => {
    e.preventDefault();
    listHelpers?.removeAll();
  };

  let completeGroupedData: Record<string, boolean> = {};
  const hasInitializedRef = useRef(false);

  useEffect(() => {
    if ((!hasInitializedRef.current && data.length > 0) || editMode) {
      setExpandedGroups(completeGroupedData);
      hasInitializedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  let nThRowForGeneratingKey = 0;

  return (
    <table {...getTableProps()} cellSpacing={0} cellPadding={0} className={block()}>
      <thead>
        <tr {...headerGroups[0].getHeaderGroupProps()}>
          {headerGroups[0].headers.map((column, headerIndex) => (
            <th
              tabIndex={-1}
              {...column.getHeaderProps(column.getSortByToggleProps())}
              key={`table-head-${headerIndex}`}
            >
              <div className={element('header-cell')}>
                {column.render('Header')}
                {column.canSort && (
                  <SortArrow
                    isSorted={tableSort !== SortState.UNSORTED}
                    isDescending={tableSort === SortState.SORTED_DESC}
                    onClick={(): void => setTableSort(changeSortState(tableSort))}
                  />
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody {...getTableBodyProps()} key="targeting-table-body">
        {sortedData.map(({ targetingTermValues, groupType, entityName }, rowGroupIndex) => {
          const handleToggle = (): void => {
            setExpandedRows((prevExpandedRows) => {
              const updatedExpandedRows = [...prevExpandedRows];
              updatedExpandedRows[rowGroupIndex] = !prevExpandedRows[rowGroupIndex];
              return updatedExpandedRows;
            });
          };
          const ageValArr: string[] = targetingTermValues
            .filter((entry) => entry.dimension.id === 'age')
            .map((entry) => entry.value.id);
          const sliceRowIndex = rowGroupIndex ? sortedData[rowGroupIndex - 1].targetingTermValues.length : 0;

          const isShowAction =
            targetingTermValues.length > 0 &&
            editMode &&
            ((!isAd && groupType === 'Line Item Targeting') || (isAd && groupType === 'Ad Targeting'));

          const TableToggleWrapper = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => (
            <td className={element('toggle-cell')}>
              <ToggleSection
                title={
                  <div className={element('section-title')}>
                    <div>
                      {groupType}
                      <span>
                        {entityName ? ` - ${entityName}` : ' '} ({targetingTermValues.length})
                      </span>
                    </div>
                  </div>
                }
                isExpanded={expandedRows[rowGroupIndex]}
                handleToggle={handleToggle}
                key={`toggle-section-${rowGroupIndex}`}
                toggleClassName={`${element('toggle')}`}
                action={
                  isShowAction && (
                    <button className={element('clear')} title="Clear Selections" onClick={handleClearSelections}>
                      Clear Selections
                    </button>
                  )
                }
              >
                {children}
              </ToggleSection>
            </td>
          );

          const targetingRows = rows.slice(sliceRowIndex, sliceRowIndex + targetingTermValues.length);
          const groupedRowData = buildRowGroups(targetingRows);
          let targetingTermsTable: React.JSX.Element[] = [];
          for (let group in groupedRowData) {
            let rowsToAdd: React.JSX.Element | React.JSX.Element[] = [];
            if (groupedRowData[group].items.length < MIN_COLLAPSIBLE_ROW_GROUP_SIZE) {
              rowsToAdd = groupedRowData[group].items.map((termValueRow, rowIndexWithinGroup) => {
                termValueRow.index = assignIdFromUnsortedData(data, groupType!, termValueRow);
                prepareRow(termValueRow);
                return generateRow(termValueRow, nThRowForGeneratingKey++, rowIndexWithinGroup, groupType, element);
              });
            } else {
              const adOrLineItemGroup = group + rowGroupIndex;
              const handleToggleGroup = (): void => {
                setExpandedGroups((prevExpandedGroups) => {
                  const updatedExpandedGroups = {
                    ...prevExpandedGroups,
                    [adOrLineItemGroup]: !prevExpandedGroups[adOrLineItemGroup],
                  };
                  return updatedExpandedGroups;
                });
              };

              let rowGroup: React.JSX.Element[] = [];
              groupedRowData[group].items.forEach((termValueRow, rowIndexWithinGroup) => {
                termValueRow.index = assignIdFromUnsortedData(data, groupType!, termValueRow);
                prepareRow(termValueRow);
                const rowInGroup = generateRow(
                  termValueRow,
                  nThRowForGeneratingKey++,
                  rowIndexWithinGroup,
                  groupType,
                  element,
                  groupedRowData[group].items.length - 1,
                  expandedGroups[adOrLineItemGroup],
                  handleToggleGroup
                );
                rowGroup.push(rowInGroup);
              });
              rowsToAdd = generateToggleRow(rowGroup, expandedGroups[adOrLineItemGroup], element, handleToggleGroup);
              completeGroupedData[adOrLineItemGroup] =
                expandedGroups[adOrLineItemGroup] ||
                (workWithLocalStorage.getData(LOCAL_STORAGE_EXPANDABLE_ROWS_STATE) as boolean);
            }
            targetingTermsTable = targetingTermsTable.concat(rowsToAdd);
          }

          if (targetingTermsTable.length === 0) {
            targetingTermsTable = targetingTermsTable.concat(
              <span className={element('no-targeting')}>No targeting added yet</span>
            );
          }

          const tdElem = isTableToggleable ? (
            <TableToggleWrapper>{targetingTermsTable}</TableToggleWrapper>
          ) : (
            targetingTermsTable
          );

          return (
            <tr key={`toggle-section-row-${rowGroupIndex}`}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {ageValArr.length ? (
                  <div>{`NOTE: You selected Age values '${ageValArr.join(', ')}' your value will be '${getAgeMinMax(
                    ageValArr
                  )}'`}</div>
                ) : null}
                {tdElem}
              </div>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default TargetingTable;
