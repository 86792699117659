import { useEffect, useState } from 'react';

import { PathName } from '../../../../constants';
import { useFiltersContext } from '../../../../contexts/FilterContext';
import type { ReviewTableName } from '../types';

interface ReturnType {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  reviewPath: string;
  disabledButton: boolean;
}

export const useCreateReviewButton = (tableName: ReviewTableName): ReturnType => {
  const [showModal, setShowModal] = useState(false);
  const [idsList, setIdsList] = useState(['']);
  const [reviewPath, setReviewPath] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);

  const { filters } = useFiltersContext();

  useEffect(() => {
    const selectedRows = filters[tableName]?.selectedRowIds || [];
    setIdsList(selectedRows);
    setDisabledButton(selectedRows.length < 1);
  }, [filters, tableName]);

  useEffect(() => {
    setReviewPath(`${PathName.campaignReview}?${tableName}Ids=${idsList}`);
  }, [idsList, tableName]);

  return {
    showModal,
    disabledButton,
    setShowModal,
    reviewPath,
  };
};

export default useCreateReviewButton;
