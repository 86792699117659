import type { AuditLineItemV5, ChangeLogUnionObject, DayPartV5 } from '../../../apis/graphql';
import { formatTimeDetail } from '../../../utils/formatting';
import type { ChangesPrefixes } from '../constants';
import type { ChangeLogEntryNode, TargetingEntry } from '../hooks/useChangeLog';
import type { ChangeLogTableProps } from '../types';
import { getNormalizedChanges } from './utils';

interface Props {
  changeLogEntries: ChangeLogEntryNode[];
  nestedChangesPrefix?: ChangesPrefixes;
  targetingEntries?: TargetingEntry[];
  entityType?: string;
}

export interface FilteredLogs {
  audits: {
    tablesProps: ChangeLogTableProps[];
    changesPlural: string;
    changesLength: number;
  };
  creativeAudits: {
    tablesProps: ChangeLogTableProps[];
    changesPlural: string;
    changesLength: number;
  };
}

export interface UseChangeLogList extends FilteredLogs {
  dateFormat: string;
  creativeDateFormat: string;
  creativeModifiedBy: string | null;
  modifiedBy: string | null;
  originalIndex: number;
  targetingEntry: TargetingEntry;
}

interface UseChangeLogListResponse {
  filteredChangeLogList: UseChangeLogList[];
}

export function countTargetingChanges(targetingEntry: TargetingEntry): number {
  if (!targetingEntry) {
    console.error('countTargetingChanges was called without a targeting entry.');
    return 1;
  }

  let count = (targetingEntry?.old?.termList?.length || 0) + (targetingEntry?.new?.termList?.length || 0);
  if (targetingEntry?.old?.termList?.length && targetingEntry?.new?.termList?.length) {
    targetingEntry?.old?.termList.forEach((oldTerm) => {
      if (targetingEntry?.new?.termList?.find((newTerm) => newTerm?.dimension === oldTerm?.dimension)) {
        // key exists on both sides and counts as one change, not two.
        count--;
      }
    });
  }
  return count;
}

export const useChangeLogList = ({
  changeLogEntries,
  nestedChangesPrefix,
  targetingEntries,
  entityType,
}: Props): UseChangeLogListResponse => {
  let previousDayPartList: DayPartV5[] | null;
  let previousObject: ChangeLogUnionObject | null;

  const filteredChangeLogList = changeLogEntries.map(({ node }: ChangeLogEntryNode, index: number) => {
    const targetingEntry = targetingEntries?.length ? targetingEntries[index] : null;

    if (changeLogEntries[index + 1]) {
      const previousDayPart = (changeLogEntries[index + 1].node.audits.object as AuditLineItemV5)?.schedule
        ?.dayPartList;
      previousObject = changeLogEntries[index + 1].node.audits.object;

      // we need to check for null, because this means that all values in the schedule have been deleted
      if (previousDayPart || previousDayPart === null) {
        previousDayPartList = previousDayPart || [];
      }
    } else {
      previousDayPartList = [];
      previousObject = null;
    }

    const {
      audits: { modifiedBy, modifiedDate },
      creativeAudits: creativeAuditsFromNode,
    } = node;

    const { creativeAudits, audits } = getNormalizedChanges({
      previousObject,
      nestedChangesPrefix,
      previousDayPartList,
      node,
      entityType,
      targetingEntry,
    });

    const dateFormat = formatTimeDetail(modifiedDate);
    const creativeDateFormat = formatTimeDetail(
      creativeAuditsFromNode.length > 0 ? creativeAuditsFromNode[0].modifiedDate : null
    );
    const creativeModifiedBy = creativeAuditsFromNode.length > 0 ? creativeAuditsFromNode[0].modifiedBy : '';

    return {
      creativeAudits,
      audits,
      dateFormat,
      creativeDateFormat,
      creativeModifiedBy,
      modifiedBy,
      originalIndex: index,
      targetingEntry,
    };
  });

  const filteredChangeLogListWithChanges = filteredChangeLogList.filter((log) => log.audits.changesLength);

  return { filteredChangeLogList: filteredChangeLogListWithChanges };
};
