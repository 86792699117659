import { format } from 'date-fns';
import { upperFirst } from 'lodash';
import type { ColumnInstance, TableInstance } from 'react-table';

import { TraffickingTableName } from '../../../../constants';
import type { Nullable } from '../../../../models';
import { formatDollarAmount, formatPercentage } from '../../../../utils/formatting';
import type { AdModel, CampaignModel, EntityModel, LineItemModel } from '../../TraffickingPage/modelConverters';
import { HEADER_COLUMNS_TO_IGNORE, NOT_SELECTED_ENTITY_MARK, SELECTED_ENTITY_MARK } from '../constants';
import type { AdModelForExport } from '../convertors/ad';
import type { CampaignModelForExport } from '../convertors/campaign';
import type { LineItemModelForExport } from '../convertors/lineItem';
import type { EntityModelForExport, HeaderColumn, ModelValueAccessor } from '../types';
import { adModelColumnAccessors } from './ads';
import { campaignModelColumnAccessors } from './campaigns';
import { lineItemModelColumnAccessors } from './lineItems';

const getHeaderColumns = (columns: ColumnInstance<EntityModel>[]): HeaderColumn[] => {
  return columns.reduce(
    (acc, column) => {
      if (!column.isVisible || typeof column.Header !== 'string' || HEADER_COLUMNS_TO_IGNORE.includes(column.id)) {
        return acc;
      }

      const headerCol: HeaderColumn = {
        name: column.Header,
        id: column.id,
      };

      return [...acc, headerCol];
    },
    [
      {
        name: 'Selection',
        id: 'selection',
      },
    ]
  );
};

const isAdModels = (models: EntityModel[], tableName: TraffickingTableName): models is AdModel[] => {
  return tableName === TraffickingTableName.ads;
};

const isAdModelsForExport = (
  models: EntityModelForExport[],
  tableName: TraffickingTableName
): models is AdModelForExport[] => {
  return tableName === TraffickingTableName.ads;
};

const isLineItemModels = (models: EntityModel[], tableName: TraffickingTableName): models is LineItemModel[] => {
  return tableName === TraffickingTableName.lineItems;
};

const isLineItemModelsForExport = (
  models: EntityModelForExport[],
  tableName: TraffickingTableName
): models is LineItemModelForExport[] => {
  return tableName === TraffickingTableName.lineItems;
};

const isCampaignModels = (models: EntityModel[], tableName: TraffickingTableName): models is CampaignModel[] => {
  return tableName === TraffickingTableName.campaigns;
};

const isCampaignModelsForExport = (
  models: EntityModelForExport[],
  tableName: TraffickingTableName
): models is CampaignModelForExport[] => {
  return tableName === TraffickingTableName.campaigns;
};

type GetModelDataToExportProps = {
  tableName: TraffickingTableName;
  tableInstance: TableInstance<EntityModel>;
  entities: EntityModelForExport[];
  selectedItems: string[];
};

export const getTableDataToExport = ({
  tableName,
  tableInstance,
  selectedItems,
}: Omit<GetModelDataToExportProps, 'entities'>): string[][] => {
  const entities: EntityModel[] = tableInstance.rows.map((row) => row.original);
  let entitiesForExport: EntityModelForExport[] = [];

  if (isCampaignModels(entities, tableName)) {
    entitiesForExport = entities.map((entity) => ({
      ...entity,
      campaignLineItems: entity.lineItemList,
    }));
  } else if (isLineItemModels(entities, tableName) || isAdModels(entities, tableName)) {
    entitiesForExport = entities;
  }

  return getModelDataToExport({
    tableName,
    tableInstance,
    entities: entitiesForExport,
    selectedItems,
  });
};

export const getHeaderRowToExport = (tableInstance: TableInstance<EntityModel>): string[] => {
  const headerColumns = getHeaderColumns(tableInstance.allColumns);

  return headerColumns.map((headerColumn) => headerColumn.name);
};

export const getDataRowsToExport = ({
  tableName,
  tableInstance,
  entities,
  selectedItems,
}: GetModelDataToExportProps): string[][] => {
  const headerColumns = getHeaderColumns(tableInstance.allColumns);
  let rowsValues: string[][] = [];

  if (isAdModelsForExport(entities, tableName)) {
    rowsValues = getModelRowsValues({
      entities,
      headerColumns,
      modelAccessors: adModelColumnAccessors,
      selectedColumnIds: selectedItems,
    });
  }

  if (isLineItemModelsForExport(entities, tableName)) {
    rowsValues = getModelRowsValues({
      entities,
      headerColumns,
      modelAccessors: lineItemModelColumnAccessors,
      selectedColumnIds: selectedItems,
    });
  }

  if (isCampaignModelsForExport(entities, tableName)) {
    rowsValues = getModelRowsValues({
      entities,
      headerColumns,
      modelAccessors: campaignModelColumnAccessors,
      selectedColumnIds: selectedItems,
    });
  }

  return rowsValues;
};

export const getModelDataToExport = ({
  tableName,
  tableInstance,
  entities,
  selectedItems,
}: GetModelDataToExportProps): string[][] => {
  const columnNames = getHeaderRowToExport(tableInstance);
  let rowsValues: string[][] = getDataRowsToExport({
    tableName,
    tableInstance,
    entities,
    selectedItems,
  });

  return [columnNames, ...rowsValues];
};

type GetModelRowsValuesProps<T extends EntityModelForExport> = {
  entities: T[];
  headerColumns: HeaderColumn[];
  modelAccessors: ModelValueAccessor<T>;
  selectedColumnIds: string[];
};

const getModelRowsValues = <T extends EntityModelForExport>({
  entities,
  headerColumns,
  modelAccessors,
  selectedColumnIds,
}: GetModelRowsValuesProps<T>): string[][] => {
  const resultedData: string[][] = [];

  entities.forEach((dataItem: T) => {
    const csvCells: string[] = [];

    csvCells.push(selectedColumnIds.includes(dataItem.id) ? SELECTED_ENTITY_MARK : NOT_SELECTED_ENTITY_MARK);

    for (let i = 1; i < headerColumns.length; i++) {
      const accessor = modelAccessors[headerColumns[i].id];
      const value = accessor ? accessor(dataItem) : '';
      csvCells.push(value);
    }

    resultedData.push(csvCells);
  });

  return resultedData;
};

export const getPercentageValueToShow = (value: Nullable<number>): string => {
  return value !== null ? formatPercentage(value) : '';
};

export const getDollarValueToShow = (value?: Nullable<number>): string => {
  return value ? formatDollarAmount(value) : '';
};

const convertCurrentDateToExportFileNameDate = (date: Date): string => {
  return format(date, "MMddyy'_'HHmma");
};

export const convertExportFileNameToCorrectFormat = (tableName: TraffickingTableName, date: Date): string => {
  return `${upperFirst(tableName).replace(/ /g, '')}_${convertCurrentDateToExportFileNameDate(date)}`;
};

export const getReversedEntities = (dataToExport: string[][]): string[][] => {
  let lastSelectedItemsIndex = -1;
  for (let exportRowIndex = 0; exportRowIndex < dataToExport.length; exportRowIndex++) {
    const columnSelected = dataToExport[exportRowIndex][0] && dataToExport[exportRowIndex][0] === SELECTED_ENTITY_MARK;
    if (columnSelected) {
      lastSelectedItemsIndex = exportRowIndex;
    } else {
      break;
    }
  }

  const selectedItems: string[][] =
    lastSelectedItemsIndex > -1 ? dataToExport.slice(0, lastSelectedItemsIndex + 1) : [];
  const notSelectedItems: string[][] = dataToExport.slice(lastSelectedItemsIndex + 1);

  return [...selectedItems.reverse(), ...notSelectedItems.reverse()];
};
