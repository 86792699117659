import './Tags.scss';

import React from 'react';

import type { AdTag, Nullable } from '../../../models';
import bem from '../../../utils/bem';
import type { ListHelpers } from '../../../utils/listHelpers';
import AdTagsTable from '../../AdTagsTable';
import type { NielsenTagWarning } from '../utils';

const [block, element] = bem('tags');

export type TagsProps = {
  isAdInitiallyWithBillableTag: boolean;
  unDeletableAd: boolean;
  readonly?: boolean;
  adTagList: AdTag[];
  listHelpers: Pick<ListHelpers<AdTag>, 'removeAt' | 'replaceAt' | 'push'>;
  nielsenTagWarning?: Nullable<NielsenTagWarning>;
};

function Tags({
  isAdInitiallyWithBillableTag,
  unDeletableAd,
  readonly,
  adTagList,
  listHelpers,
  nielsenTagWarning,
}: TagsProps): JSX.Element {
  const warningType = nielsenTagWarning?.isError ? 'error' : 'warning';

  return (
    <div className={block()}>
      <AdTagsTable
        adTagList={adTagList}
        listHelpers={listHelpers}
        isAdInitiallyWithBillableTag={isAdInitiallyWithBillableTag}
        unDeletableAd={unDeletableAd}
        readonly={readonly}
      />
      {nielsenTagWarning && (
        <div data-testid="nielsen-warning" className={element(`nielsen-${warningType}`)}>
          {nielsenTagWarning.message}
        </div>
      )}
    </div>
  );
}

export default Tags;
