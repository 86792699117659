import 'rc-tooltip/assets/bootstrap.css';

import Tooltip from 'rc-tooltip';
import type { FC, RefObject } from 'react';
import React, { useRef } from 'react';

import { useOnClickOutside } from '../../hooks';
import type { TooltipProps } from './types';

export type Props = {
  tooltipProps?: TooltipProps;
  tooltipContent: JSX.Element | string;
  children: JSX.Element;
  onClickOutside: Function;
  baseContainerId?: string;
};

const RcTooltip: FC<Props> = ({
  baseContainerId = 'app',
  children,
  tooltipContent,
  tooltipProps = {},
  onClickOutside,
}) => {
  const ref: RefObject<HTMLDivElement> = useRef(null);
  useOnClickOutside(ref, onClickOutside);

  const getTooltipContainer = (): HTMLElement => {
    return document.getElementById(baseContainerId) || document.getElementsByTagName('body')[0];
  };

  return (
    <Tooltip
      {...tooltipProps}
      getTooltipContainer={getTooltipContainer}
      overlay={<div ref={ref}>{tooltipContent}</div>}
    >
      {children}
    </Tooltip>
  );
};

export default RcTooltip;
