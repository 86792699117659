import './SequenceContent.scss';

import { Button } from '@hulu/react-style-components';
import { bem } from 'mission-control-common-components';
import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import { NEW_SEQUENCE_TITLE, UNSEQUENCED_TABLE_ID } from '../constants';
import { useSequenceContent, useSequenceForm } from '../hooks';
import SequenceTable from '../SequenceTable';
import type { SequenceAdV5, TransformedSequence } from '../types';

const [block, element] = bem('sequence-content');

const SequenceContent = (): React.JSX.Element => {
  const { values, addSequence } = useSequenceForm();
  const { handleDragEnd, filteredUnsequencedAds } = useSequenceContent();

  const getMappedSequenceAds = (sequence: TransformedSequence): SequenceAdV5[] => {
    return sequence?.ads && sequence?.ads.length ? sequence?.ads : [];
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className={block()}>
        {values.sequences.map((sequence, index) => (
          <SequenceTable sequence={sequence} rows={getMappedSequenceAds(sequence)} tableIndex={index} key={index} />
        ))}
        <div>
          <Button
            variant="outlined"
            icon="IconAdd"
            aria-label="add new sequence"
            className={element('add-sequence')}
            onClick={addSequence}
          >
            {NEW_SEQUENCE_TITLE}
          </Button>
        </div>
        <SequenceTable rows={filteredUnsequencedAds} tableIndex={Number(UNSEQUENCED_TABLE_ID)} />
      </div>
    </DragDropContext>
  );
};

export default SequenceContent;
