import './ToggleSwitch.scss';
import 'antd/lib/switch/style/index.css';

import { Switch } from 'antd';
import type { SwitchChangeEventHandler } from 'antd/lib/switch';
import React from 'react';

import bem, { withOptionalClassName } from '../../utils/bem';

const [block, element] = bem('toggle-switch');

export interface ToggleSwitchProps {
  isOn: boolean;
  disabled?: boolean;
  onChange?: SwitchChangeEventHandler;
  className?: string;
  label?: string;
  checkedIcon?: JSX.Element;
  uncheckedIcon?: JSX.Element;
  isHorizontalPositioning?: boolean;
  toggleTooltip?: string;
}

function ToggleSwitch({
  isOn,
  onChange,
  className,
  disabled,
  label,
  checkedIcon,
  uncheckedIcon,
  isHorizontalPositioning,
  toggleTooltip = '',
}: ToggleSwitchProps): JSX.Element {
  const toggleState = [isOn ? 'on' : 'off', disabled ? 'disabled' : ''];
  return (
    <div className={withOptionalClassName(block(), className)}>
      {!isHorizontalPositioning && label && (
        <div className={element('label')} data-testid="toggle-switch-label">
          {label}
        </div>
      )}
      <div className={element('toggler', toggleState)}>
        <div className={element('toggle-switch-wrapper')} title={toggleTooltip}>
          <Switch
            data-testid="toggle-switch-toggler"
            disabled={disabled}
            onChange={onChange}
            checked={isOn}
            unCheckedChildren={uncheckedIcon}
            checkedChildren={checkedIcon}
          />
        </div>
        {isHorizontalPositioning && label && (
          <span className={element('horizontal-label')} data-testid="horizontal-toggle-switch-label">
            {label}
          </span>
        )}
      </div>
    </div>
  );
}

export default ToggleSwitch;
