import './CreativeModeSelector.scss';

import { useFormikContext } from 'formik';
import { User } from 'mission-control-common-components';
import React from 'react';

import type { PermissionsNames } from '../../../constants';
import bem from '../../../utils/bem';
import Button from '../../Button';
import type { AdFormValues } from '../adFormik';
import { CreativeEditMode } from '../CreativeBuilders/types';

const [block, element] = bem('creative-mode-selector');

type CreativeModeSelectorProps = {
  editMode: CreativeEditMode;
};

function CreativeModeSelector({ editMode }: CreativeModeSelectorProps): React.JSX.Element {
  const permissions = User.getPermissions<PermissionsNames>();
  const { setFieldValue } = useFormikContext<AdFormValues>();

  const createDisabled = !permissions?.createCreative || editMode === CreativeEditMode.readonly;
  const updateDisabled = !permissions?.updateCreative || editMode === CreativeEditMode.readonly;

  return (
    <div className={block()}>
      <Button
        className={element('build-btn')}
        variant="outlined"
        size="medium"
        onClick={(): void => setFieldValue('creative.mode', 'search')}
        disabled={updateDisabled}
      >
        Search Creative Library
      </Button>
      <span>OR</span>
      <Button
        className={element('search-btn')}
        variant="outlined"
        size="medium"
        onClick={(): void => setFieldValue('creative.mode', 'build')}
        disabled={createDisabled}
      >
        Build New Creative
      </Button>
    </div>
  );
}

export default CreativeModeSelector;
