import { isEqual } from 'lodash';

import { ChangeAction } from '../../../../../apis/graphql';
import { orderedDaysOfWeek } from '../../../../../utils/formatting';
import type { ChangedDayPartRange, NullableDayPart } from '../../../types';
import { getWeekDaysRanges } from './getWeekDaysRanges';

export const compareDayValues = (
  dayPartListBefore: NullableDayPart[],
  dayPartListAfter: NullableDayPart[],
  action: ChangeAction
): ChangedDayPartRange[] => {
  const daysOld = getWeekDaysRanges(dayPartListBefore);
  const daysNew = getWeekDaysRanges(dayPartListAfter);

  return orderedDaysOfWeek.reduce<ChangedDayPartRange[]>((changed, day) => {
    if (!isEqual(daysOld[day], daysNew[day])) {
      changed.push({ day, oldRange: daysOld[day], newRange: daysNew[day] });
    } else if (action !== ChangeAction.Update) {
      changed.push({ day, oldRange: [], newRange: [] });
    }

    return changed;
  }, []);
};
