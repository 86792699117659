import './Rating.scss';

import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';

import type { Get_Ad_RatingsQuery } from '../../../../../../../../../apis/graphql';
import type { DropdownOption } from '../../../../../../../../../common/Dropdown';
import Dropdown from '../../../../../../../../../common/Dropdown';
import { DropdownOptions } from '../../../../../../../../../common/hooks/config';
import { useFetchDropdownOptions } from '../../../../../../../../../common/hooks/queries/useFetchDropdownOptions';
import bem from '../../../../../../../../../utils/bem';
import type { MultipleEditDrawerAdsFormValues } from '../../../../../types';
import { RATINGS_SECTION_HEADER } from './const';
import { convertAdRatingToDropdownOption } from './utils';

const [block, element] = bem('multi-edit-rating');

export default function Rating(): JSX.Element {
  const { options: adRatingOptions } = useFetchDropdownOptions<Get_Ad_RatingsQuery>(DropdownOptions.AdRating);
  const {
    values: { rating },
    setFieldValue: setAdRating,
  } = useFormikContext<MultipleEditDrawerAdsFormValues>();

  const selectedAdRating = useMemo(() => convertAdRatingToDropdownOption(rating), [rating]);

  const onAdRatingChange = (newAdRating: DropdownOption | null): void => {
    setAdRating('rating', newAdRating?.value);
  };

  return (
    <section className={block()}>
      <h3 className={element('header')}>{RATINGS_SECTION_HEADER}</h3>
      <div className={element('dropdown-wrapper')}>
        <Dropdown
          id="rating"
          value={selectedAdRating}
          label="Ad Rating"
          name="rating"
          options={adRatingOptions}
          isSearchable={false}
          onChange={onAdRatingChange}
          dataTestId="ad-rating"
        />
      </div>
    </section>
  );
}
