import { IconClockOutlined } from '@hulu-react-style-components/icons';
import React from 'react';

import type { DayPartV5, Maybe } from '../../../../apis/graphql';
import IconWithTitle from '../../../../common/IconWithTitle';
import ScheduleOptions from '../../../../common/ScheduleOptions';
import TimesList from '../../../../common/TimesList';
import { filterDaysList } from './utils';

type DaypartSectionProps = {
  dayPartList?: Maybe<DayPartV5[]>;
};

function DaypartSection({ dayPartList }: DaypartSectionProps): JSX.Element {
  const dayList = dayPartList && filterDaysList(dayPartList);

  const dayPartsExists = !!(dayList && dayList.length > 0);

  return (
    <div className="scheduling-section" data-testid="daypart-section">
      <IconWithTitle icon={<IconClockOutlined />} title="Dayparts" />
      <ScheduleOptions firstText="All Times" secondText="Specific Times" isFirstActive={!dayPartsExists} />
      {dayList && dayPartsExists && <TimesList dayParts={dayList} />}
    </div>
  );
}

export default DaypartSection;
