import type { DocumentNode } from '@apollo/client';
import { useMutation } from '@apollo/client';
import type { FormikHelpers } from 'formik';

import type {
  TargetingRuleDefinitionV4,
  TargetingTermValue,
  UpdateAdTargetingValuesMutation,
  UpdateLineItemTargetingValuesMutation,
} from '../../../../apis/graphql';
import { getErrorMessageFromGraphQlErrors, removeTypeNamesFromObject } from '../../../../apis/graphql';
import { showFailure, showSuccess } from '../../../../common/utils';
import { EntityType } from '../../TraffickingPage/hooks/makePageDrawerPlugin';
import { convertValuesForMutation, filterOutCountryTarget } from '../utils/utils';
import { UPDATE_AD_TARGETING_TERM_VALUES } from './queries/updateAdTargetingTermValues';
import { UPDATE_LINE_ITEM_TARGETING_TERM_VALUES } from './queries/updateLineItemTargetingTermValues';
import type { DrawerTargetingTableResult, TargetingTermValuesInput } from './types';
import type { DrawerTargetingFormValues } from './types/formValues';

export type HookProps = {
  entityType: EntityType.AD | EntityType.LINE_ITEM;
  id?: string;
  onSubmitSuccessful: () => void;
};

type TargetingData = {
  updateAdV5?: {
    targetingTermValues: TargetingTermValue[];
  };
  updateLineItem?: {
    targetingTermValues: TargetingTermValue[];
  };
};

const getTypename = (isAd: boolean): string => {
  return isAd ? 'AdV5' : 'LineItemV5';
};

const getMutation = (isAd: boolean): DocumentNode => {
  return isAd ? UPDATE_AD_TARGETING_TERM_VALUES : UPDATE_LINE_ITEM_TARGETING_TERM_VALUES;
};

const getTargetingTerms = <T extends TargetingData>(isAd: boolean, data: T): TargetingTermValue[] => {
  return isAd ? data?.updateAdV5?.targetingTermValues ?? [] : data?.updateLineItem?.targetingTermValues ?? [];
};

export const useUpdateTargeting = ({
  entityType,
  onSubmitSuccessful,
  id,
}: HookProps): Pick<DrawerTargetingTableResult, 'submitForm' | 'isUpdating'> => {
  const isAd = entityType === EntityType.AD;
  const updateTargetingTermValues = getMutation(isAd);

  const [updateTargetingValues, { loading }] = useMutation<
    UpdateAdTargetingValuesMutation | UpdateLineItemTargetingValuesMutation
  >(updateTargetingTermValues, {
    update(cache, { data, errors }) {
      if (!errors) {
        const updatedTargetingTermValues = getTargetingTerms(isAd, data as TargetingData);

        const typename = getTypename(isAd);

        const cacheKey = cache.identify({ __typename: typename, id });

        cache.modify({
          id: cacheKey,
          fields: {
            targetingTermValues: () => updatedTargetingTermValues,
          },
        });
      }
    },
  });

  const submitForm = async (
    newValue: TargetingTermValuesInput,
    helpers: FormikHelpers<DrawerTargetingFormValues>,
    rule: TargetingRuleDefinitionV4 | null
  ): Promise<void> => {
    const targetingValueKey = isAd ? 'adTargetingTermValues' : 'lineItemTargetingTermValues';
    const targetingTermValues = newValue[targetingValueKey];

    const { errors } = await updateTargetingValues({
      variables: {
        id,
        newTargetingValues: convertValuesForMutation(targetingTermValues),
        targetingRule: rule ? removeTypeNamesFromObject(rule) : null,
      },
    });

    if (errors) {
      showFailure({
        message: `${isAd ? 'Ad' : 'Line Item'} Save Failed`,
        description: getErrorMessageFromGraphQlErrors(errors),
      });
      const lineItemsNoCountry = filterOutCountryTarget({ value: newValue.lineItemTargetingTermValues });
      newValue.lineItemTargetingTermValues = lineItemsNoCountry;
      helpers.setValues(newValue);
      helpers.setSubmitting(false);
    } else {
      showSuccess(isAd);
      onSubmitSuccessful();
    }
  };

  return { submitForm, isUpdating: loading };
};
