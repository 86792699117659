import { gql } from '@apollo/client/core';

export const ROTATIONS_FRAGMENT = gql`
  fragment rotations on Rotation {
    rotationId
    name
    order
    adRotationList {
      adId
      weight
      order
      rotationId
      rotationName
    }
  }
`;
