import { isValid } from 'date-fns';
import { detect } from 'detect-browser';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useState } from 'react';

import type { DateRangeV5 as Range } from '../../../apis/graphql';
import { getDefaultRangeEndDate } from '../../../pages/Trafficking/TraffickingPageDrawer/MultipleEditDrawer/utils';
import { useFieldArray } from '../../Form/hooks';

type ReturnValues = {
  validationError: string | null;
  setValidationError: Dispatch<SetStateAction<string | null>>;
  handleDateChange: (field: 'start' | 'end', value: string, index: number) => void;
  removeAt: (index: number) => void;
  push: (item: Range) => void;
  dateRangeList: Range[];
  addDateRange: () => void;
  handleToggleDisableLastEndDate: (isDisabled: boolean, index: number) => void;
};

export default function useDatesInfo(dateRangeListField: string): ReturnValues {
  const [, { value: dateRangeList }, { replaceAt: replaceAtDateRange, removeAt, push }] = useFieldArray<Range>(
    dateRangeListField
  );

  const [validationError, setValidationError] = useState<string | null>(null);

  const handleDateChange = (field: 'start' | 'end', value: string, index: number): void => {
    if (!dateRangeList.length) return;

    const dateValue = new Date(value);
    const isValidDate = isValid(dateValue);

    if (isValidDate || field === 'end') {
      replaceAtDateRange(index, {
        ...dateRangeList[index],
        [`${field}Date`]: isValidDate ? value : null,
      });
    }
  };

  const handleToggleDisableLastEndDate = (isDisabled: boolean, index: number): void => {
    if (isDisabled) {
      const { endDate } = getDefaultRangeEndDate();

      replaceAtDateRange(index, {
        ...dateRangeList[index],
        endDate,
      });
    } else {
      replaceAtDateRange(index, {
        ...dateRangeList[index],
        endDate: null,
      });
    }
  };

  const addDateRange = useCallback((): void => {
    const lastDateRangeIndex = dateRangeList.length - 1;
    const browser = detect();
    const isSafari = browser?.name === 'safari';

    if (dateRangeList[lastDateRangeIndex].endDate === null && isSafari) {
      const dateRangeWithNullableEndDate = dateRangeList[lastDateRangeIndex];

      replaceAtDateRange(lastDateRangeIndex, dateRangeList[0]);
      push(dateRangeWithNullableEndDate);
    } else {
      push(dateRangeList[0]);
    }
  }, [push, dateRangeList, replaceAtDateRange]);

  return {
    validationError,
    setValidationError,
    handleDateChange,
    removeAt,
    push,
    dateRangeList,
    addDateRange,
    handleToggleDisableLastEndDate,
  };
}
