import { useQuery } from '@apollo/client';
import React, { useEffect, useMemo } from 'react';

import type {
  GetLineItemsWithTargetingsQuery,
  GetLineItemsWithTargetingsQueryVariables,
} from '../../../../../../apis/graphql';
import { getDataFromNodes } from '../../../../../../apis/graphql';
import { AlertType } from '../../../../../../common/Alert';
import { openToastAlert } from '../../../../../../common/ToastAlert/toastAlert';
import type { MultipleEditDrawerLineItemData } from '../../../../TraffickingPage/hooks/makePageDrawerPlugin';
import { GET_LINE_ITEMS_WITH_TARGETINGS } from '../../../../TraffickingPage/hooks/queries/getLineItemsWithTargetings';
import type { LineItemModel } from '../../../../TraffickingPage/modelConverters';
import type {
  LineItemWithTargetingsModel,
  LineItemWithTargetingsNode,
} from '../../../../TraffickingPage/modelConverters/lineItemWithTargetingsConvertor';
import { lineItemWithTargetingsNodeToModel } from '../../../../TraffickingPage/modelConverters/lineItemWithTargetingsConvertor';
import type { MultipleEditDrawerProps } from '../../MultipleEditDrawer';
import MultipleEditDrawer from '../../MultipleEditDrawer';
import { getMultipleEditDrawerLineItemsInitialValues } from '../../utils';
import SharedMultipleEditLoader from '../SharedMultipleEditLoader/SharedMultipleEditLoader';

export type Props = MultipleEditDrawerProps & {
  data: MultipleEditDrawerLineItemData;
};

const MultipleLineItemDrawer = ({
  data,
  isExpanded,
  isOpen,
  className,
  onClose,
  onToggleExpansion,
}: Props): JSX.Element => {
  const ids = data.entities.map((entity: LineItemModel) => entity.id);

  const { data: lineItemsData, loading, error } = useQuery<
    GetLineItemsWithTargetingsQuery,
    GetLineItemsWithTargetingsQueryVariables
  >(GET_LINE_ITEMS_WITH_TARGETINGS, {
    variables: {
      ids,
    },
    fetchPolicy: 'no-cache',
  });

  const lineItemsWithTargetings = useMemo(() => {
    return getDataFromNodes<LineItemWithTargetingsNode, LineItemWithTargetingsModel>(
      lineItemsData,
      'lineItems',
      lineItemWithTargetingsNodeToModel
    )();
  }, [lineItemsData]);
  const initialValues = useMemo(() => getMultipleEditDrawerLineItemsInitialValues(lineItemsWithTargetings), [
    lineItemsWithTargetings,
  ]);

  useEffect(() => {
    if (error) {
      openToastAlert({
        alertType: AlertType.ERROR,
        message: 'Something went wrong. Please try later.',
      });
    }
  }, [error]);

  if (loading) {
    return (
      <SharedMultipleEditLoader
        isOpen={isOpen}
        isExpanded={isExpanded}
        className={className}
        data={data}
        onClose={onClose}
        onToggleExpansion={onToggleExpansion}
      />
    );
  }

  return (
    <MultipleEditDrawer
      isOpen={isOpen}
      isExpanded={isExpanded}
      className={className}
      data={{
        ...data,
        entities: lineItemsWithTargetings,
      }}
      onClose={onClose}
      onToggleExpansion={onToggleExpansion}
      initialValues={initialValues}
    />
  );
};

export default MultipleLineItemDrawer;
