import './DrawerTitle.scss';

import React from 'react';

import bem from '../../../utils/bem';

const [block] = bem('trafficking-drawer-title');

const DrawerTitle = (props: { title: string }): JSX.Element => <div className={block()}>{props.title}</div>;

export default DrawerTitle;
