import { gql } from '@apollo/client';

export const TARGETING_RULE_DEFINITION = gql`
  fragment targetingRuleDefinition on TargetingRule {
    definition {
      not
      subClass
      termList {
        not
        dimension
        subClass
        valueSet
        termList {
          not
          dimension
          subClass
          valueSet
          termList {
            not
            dimension
            subClass
            valueSet
            termList {
              not
              dimension
              subClass
              valueSet
              termList {
                not
                dimension
                subClass
                valueSet
                termList {
                  not
                  dimension
                  subClass
                  valueSet
                  termList {
                    not
                    dimension
                    subClass
                    valueSet
                    termList {
                      not
                      dimension
                      subClass
                      valueSet
                      termList {
                        not
                        dimension
                        subClass
                        valueSet
                        termList {
                          not
                          dimension
                          subClass
                          valueSet
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
