import React from 'react';

import type { CardsContentWithLink } from '../types';
import BufferAndFrontload from './BufferAndFrontload';
import Frequency from './Frequency';
import Priority from './Priority';
import Status from './Status';
import Targeting from './Targeting';

export const lineItemsCardsContent: Array<CardsContentWithLink> = [
  {
    sideNavLink: 'Status',
    cardContent: (isExpanded, entityType): JSX.Element => <Status isExpanded={isExpanded} entityType={entityType} />,
  },
  {
    sideNavLink: 'Targeting',
    cardContent: (_, entityType): JSX.Element => <Targeting entityType={entityType} />,
  },
  {
    sideNavLink: 'Buffer & Frontload',
    cardContent: (): JSX.Element => <BufferAndFrontload />,
  },
  {
    sideNavLink: 'Priority',
    cardContent: (): JSX.Element => <Priority />,
  },
  {
    sideNavLink: 'Frequency',
    cardContent: (): JSX.Element => <Frequency />,
  },
];
