import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import type { DropdownOption, DropdownOptionGroup } from '../Dropdown';

const CustomOptionWithTooltip = <T extends string>({
  ...props
}: OptionProps<DropdownOption<T>, false, DropdownOptionGroup<T>>): React.JSX.Element => {
  const tooltip = props.data.tooltip;

  const option = props.data?.customOption ? (
    <components.Option {...props}>{props.data.customOption}</components.Option>
  ) : (
    <components.Option {...props}></components.Option>
  );

  return tooltip ? (
    <div
      data-for={tooltip.id || ''}
      data-place={tooltip.place || 'bottom'}
      data-tip-disable={tooltip.disable || false}
      data-delay-show={tooltip.delayShow || 100}
      data-delay-hide={tooltip.delayHide || 100}
      data-tip={tooltip.message}
      data-html
      data-testid="option-drag-wrapper"
    >
      {option}
    </div>
  ) : (
    <>{option}</>
  );
};

export default CustomOptionWithTooltip;
