import type { RadioProps as TrekRadioProps } from '@hulu/react-style-components';
import { Label, Radio as TrekRadio } from '@hulu/react-style-components';
import { useFormikContext } from 'formik';
import React from 'react';

import bem, { withOptionalClassName } from '../../../utils/bem';
import { useFieldFast } from '../hooks';
import { makeLabelChild } from '../utils';

// Expose all Trek RadioProps except for those that will be controlled
export interface RadioProps extends Omit<TrekRadioProps, 'onChange' | 'isChecked'> {
  formValue: string; // Form value for selection
}

export interface RadioGroupProps {
  id: string;
  formFieldName: string;
  options: RadioProps[]; // Options for each Radio
  label?: string; // Label text displayed above the RadioGroup
  secondaryLabel?: string;
  disabled?: boolean;
  className?: string;
}

const [block, element] = bem('radio-group');

// A Formik-connected group of Trek Radio components
export function RadioGroup({
  id,
  formFieldName,
  options,
  label,
  secondaryLabel,
  disabled,
  className,
}: RadioGroupProps): JSX.Element {
  const [, { value }, { setValue }] = useFieldFast(formFieldName);
  const { isSubmitting } = useFormikContext();

  return (
    <div className={withOptionalClassName(block(), className)} id={id}>
      {(label || secondaryLabel) && <Label className={element('label')}>{makeLabelChild(label, secondaryLabel)}</Label>}
      <div className={element('options')}>
        {options.map(({ formValue, ...trekRadioProps }: RadioProps) => (
          <TrekRadio
            {...trekRadioProps}
            id={element(`option-${formValue}`)}
            key={formValue}
            isChecked={value === formValue}
            onChange={(): void => setValue(formValue)}
            disabled={trekRadioProps.disabled || disabled || isSubmitting}
            isErr={trekRadioProps.isErr}
          />
        ))}
      </div>
    </div>
  );
}

export default RadioGroup;
