import type { DropdownOption } from '../common/Dropdown';
import type { Config, DisplayEnum, TransformableConfigMap } from '.';
import { UNRECOGNIZED } from '.';

export type ConfigMap<T extends string> = Record<T, Config<T>>;

// Function to add a display value for enums that don't have a special one listed
export function makeConfig<ValidKeys extends string>(transformableEnum: TransformableConfigMap): ConfigMap<ValidKeys> {
  const reducer = (accumulator: ConfigMap<ValidKeys>, configKey: string): ConfigMap<ValidKeys> => {
    // The value can be either a string or a function to generate one
    const stringOrFunction = transformableEnum[configKey];

    // Return the accumulating object with a new key that either preserves or backfills a display value
    return {
      ...accumulator,
      [configKey]: {
        key: configKey,
        displayName: typeof stringOrFunction === 'string' ? stringOrFunction : stringOrFunction(configKey),
      },
    };
  };

  return Object.keys(transformableEnum).reduce<ConfigMap<ValidKeys>>(reducer, {} as ConfigMap<ValidKeys>);
}

export const convertConfigToDropDownOptions = <T extends string>(config: ConfigMap<T>): DropdownOption<T>[] =>
  Object.values<Config<T>>(config).map((value) => {
    return {
      value: value.key,
      label: value.displayName,
    };
  });

export const convertConfigToDropdownOption = <T extends string>(
  config: Config<T | typeof UNRECOGNIZED>
): DropdownOption<T | typeof UNRECOGNIZED> => ({
  value: config.key,
  label: config.displayName,
});

// Function to check if a string value from the API is recognized by MC
function isRecognizedEnum<T extends string>(apiValue: string, validValues: string[]): apiValue is T {
  return validValues.indexOf(apiValue) !== -1;
}

// Function to convert an API value to a displayable enum
export function convertToDisplayEnum<ValidKeys extends string>(
  apiValue: string,
  configMap: ConfigMap<ValidKeys>
): DisplayEnum<ValidKeys> {
  // If the enum is recognized, return a configuration entry from our list of recognized configs
  if (isRecognizedEnum<ValidKeys>(apiValue, Object.keys(configMap))) {
    return configMap[apiValue];
  }

  return {
    key: UNRECOGNIZED,
    displayName: apiValue,
  };
}

export function isRecognizedConfig<T extends string>(
  possiblyUnrecognized: DisplayEnum<T>
): possiblyUnrecognized is Config<T> {
  return possiblyUnrecognized.key !== UNRECOGNIZED;
}

export const descriptionMessage = (description: string): string =>
  `Campaign Review is required for ${description} to be activated.`;

export const STATUS_ERROR: Record<'ad' | 'lineItem', { message: string; description: string }> = {
  ad: {
    message: 'ACTIVATION FAILED',
    description: descriptionMessage('ads'),
  },
  lineItem: {
    message: 'ACTIVATION FAILED',
    description: descriptionMessage('line items'),
  },
};
