import React, { useMemo } from 'react';

import { useFieldArray } from '../../../../common/Form/hooks';
import TargetingDropdowns from '../../../../common/TargetingDropdowns';
import TargetingTable from '../../../../common/TargetingTable';
import type { TargetingTableRowGroup } from '../../../../common/TargetingTable/TargetingTable';
import type { TargetingTermValue, TargetingValue } from '../../../../models';
import { EntityType } from '../../TraffickingPage/hooks/makePageDrawerPlugin';
import { generateTargetingTableData } from '../utils/utils';

interface Props {
  editMode: boolean;
  entityType?: string;
  countryLineItem?: TargetingTermValue;
  publisherLineItem?: TargetingValue[];
}

function DrawerTargetingBody({ editMode, entityType, countryLineItem, publisherLineItem }: Props): JSX.Element {
  const isAd = entityType === EntityType.AD;

  const [
    ,
    { value: adTargetingTermValues, error: adTargetingError },
    { insertAt: insertAtAd, removeAt: removeAtAd, replaceAt: replaceAtAd, removeAll: removeAllAds },
  ] = useFieldArray<TargetingTermValue>('adTargetingTermValues');
  const [
    ,
    { value: lineItemTermValues, error: lineItemTargetingError },
    {
      insertAt: insertAtLineItem,
      removeAt: removeAtLineItem,
      replaceAt: replaceAtLineItem,
      removeAll: removeAllLineItems,
    },
  ] = useFieldArray<TargetingTermValue>('lineItemTargetingTermValues');

  const tableData: TargetingTableRowGroup[] = useMemo(
    (): TargetingTableRowGroup[] => generateTargetingTableData(adTargetingTermValues, lineItemTermValues),
    [adTargetingTermValues, lineItemTermValues]
  );

  return (
    <div>
      {editMode && (
        <TargetingDropdowns
          targetings={isAd ? adTargetingTermValues : lineItemTermValues}
          listHelpers={{
            insertAt: isAd ? insertAtAd : insertAtLineItem,
            replaceAt: isAd ? replaceAtAd : replaceAtLineItem,
            removeAt: isAd ? removeAtAd : removeAtLineItem,
          }}
          validationError={isAd ? adTargetingError : lineItemTargetingError}
          countryOption={countryLineItem}
          publisherOption={publisherLineItem}
        />
      )}
      {tableData.length ? (
        <TargetingTable
          editMode={editMode}
          data={tableData}
          listHelpers={{
            removeAt: isAd ? removeAtAd : removeAtLineItem,
            replaceAt: isAd ? replaceAtAd : replaceAtLineItem,
            removeAll: isAd ? removeAllAds : removeAllLineItems,
          }}
          isAd={isAd}
          isReadOnly
          isTableToggleable
        />
      ) : (
        <div>No current targeting data for this {entityType}.</div>
      )}
    </div>
  );
}

export default DrawerTargetingBody;
