import { gql } from '@apollo/client';

import { ADS_PAGE_AD } from '../../../../../../common/AdForm/hooks/fragments';

export const GET_ADS_PAGE_DATA_BY_IDS = gql`
  query getAdsPageAdByID($adId: ID!, $lineItemId: String!) {
    ads: adsV5(filterOptions: { idList: [$adId] }) {
      edges {
        node {
          ...adsPageAd
        }
      }
    }
    getTargetingTermValuesByLineItemIDV5(lineItemId: $lineItemId) {
      category {
        name
        displayName
      }
      dimension {
        name
        displayName
      }
      value {
        name
        displayName
      }
      include
    }
  }
  ${ADS_PAGE_AD}
`;
