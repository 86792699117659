import './CreateReviewLinkModal.scss';

import { IconShare } from '@hulu-react-style-components/icons';
import { useNavigate } from '@hulu/react-router';
import { Button as TrekLink } from '@hulu/react-style-components';
import { Button, Modal } from 'antd';
import React, { useEffect, useMemo } from 'react';

import { openToastAlert } from '../../../../common/ToastAlert/toastAlert';
import { MISSION_CONTROL_SUPPORT_EMAIL } from '../../../../constants';
import bem from '../../../../utils/bem';
import type { EntityModel } from '../../TraffickingPage/modelConverters';
import useSendOnReview from '../hooks/useSendOnReview';
import ReviewContent from '../ReviewContent/ReviewContent';
import type { FlatRowType, ReviewTableName } from '../types';
import { generateGQLErrorMessage } from './utils';

const [block, element] = bem('create-review-modal');

type Props = {
  reviewPath: string;
  closeModal: () => void;
  tableName: ReviewTableName;
  selectedFlatRows: FlatRowType<EntityModel>;
};

const CreateReviewLinkModal = ({ reviewPath, closeModal, tableName, selectedFlatRows }: Props): JSX.Element => {
  const { sendOnReview, loading, error } = useSendOnReview();
  const navigate = useNavigate();

  const errorMessage = useMemo(() => generateGQLErrorMessage(selectedFlatRows, error), [selectedFlatRows, error]);

  useEffect(() => {
    sendOnReview(selectedFlatRows, tableName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows, tableName]);

  const handleReviewLinkClick = (): void => {
    navigate(reviewPath);
  };

  useEffect(() => {
    if (errorMessage) {
      openToastAlert({
        alertType: 'error',
        restrictToPage: false,
        message: 'Create Review Link Failed',
        description: (
          <span data-testid="create-review-link-failed">
            {errorMessage}
            <br />
            <a href={`mailto:${MISSION_CONTROL_SUPPORT_EMAIL}`}>{MISSION_CONTROL_SUPPORT_EMAIL}</a>
          </span>
        ),
        className: element('notification'),
      });
    }
  }, [errorMessage]);

  const footerContent = [
    <Button key="cancel-button" onClick={closeModal} prefixCls={`ant-btn ${element('cancel-button')}`}>
      CANCEL
    </Button>,
    <TrekLink
      key="go-to-link-button"
      data-testid="go-to-link-button"
      onClick={handleReviewLinkClick}
      className={element('submit-button')}
      aria-label="submit create review"
      disabled={false}
    >
      go to link <IconShare />
    </TrekLink>,
  ];

  return (
    <Modal className={block()} onCancel={closeModal} visible closable={true} footer={footerContent}>
      <ReviewContent
        reviewPath={reviewPath}
        contentName={tableName}
        selectedRowsCount={selectedFlatRows?.length}
        loading={loading}
      />
    </Modal>
  );
};

export default CreateReviewLinkModal;
