import './Scheduling.scss';

import { IconCalendar } from '@hulu-react-style-components/icons';
import type { Dispatch, SetStateAction } from 'react';
import React from 'react';

import type { DayPartV5, ScheduleV5 } from '../../../apis/graphql';
import { Globe } from '../../../common/assets';
import { defaultSupportedTimezones } from '../../../common/commonMocks/timezones';
import DatesInfo from '../../../common/DatesInfo';
import type { Nullable } from '../../../models';
import bem from '../../../utils/bem';
import { getTimezoneWithGMT } from '../../SelectTimeZone/utils';
import DaypartTabs from './DaypartTabs';

const [block, element] = bem('scheduling');

type SchedulingProps = {
  stashedDayPartList: DayPartV5[];
  setStashedDayPartList: Dispatch<SetStateAction<DayPartV5[]>>;
  lineItemSchedule?: Nullable<ScheduleV5>;
  readonly?: boolean;
};
const Scheduling = ({
  stashedDayPartList,
  setStashedDayPartList,
  lineItemSchedule,
  readonly,
}: SchedulingProps): JSX.Element => {
  const lineItemTimezone = lineItemSchedule?.timezone || defaultSupportedTimezones.ET;

  return (
    <div className={block()}>
      <div className={element('card-outer-wrapper')}>
        <div className={element('location-wrapper')}>
          <img src={Globe} alt="globe-icon" className={'globe-icon'} />
          <div className={element('location-text')} data-testid={element('location-text')}>
            {getTimezoneWithGMT(lineItemTimezone)}
          </div>
        </div>
      </div>
      <div className={element('card-inner-wrapper')}>
        <div className={element('date-title')}>
          <IconCalendar />
          <h5>Dates</h5>
        </div>

        <DatesInfo lineItemSchedule={lineItemSchedule} readonly={readonly} />
        <DaypartTabs
          setStashedDayPartList={setStashedDayPartList}
          stashedDayPartList={stashedDayPartList}
          readonly={readonly}
        />
      </div>
    </div>
  );
};

export default Scheduling;
