import styled from 'styled-components';

export const IconAnchor = styled.a`
  svg {
    width: 1em;
    height: 1em;

    path {
      fill: currentColor;
    }
  }
`;
