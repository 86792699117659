import type { ApolloError } from '@apollo/client';

import { generateMisalignmentGQLErrorMessage, getErrorMessageFromGraphQlErrors } from '../../../../apis/graphql';
import type { EntityModel } from '../../TraffickingPage/modelConverters';
import type { FlatRowType } from '../types';

export const generateGQLErrorMessage = (selectedFlatRows: FlatRowType<EntityModel>, error?: ApolloError): string => {
  const hasMisalignedSchedule = selectedFlatRows.some((el) => el.original.hasMisalignedSchedule === true);

  const generalGQLErrorMessage = getErrorMessageFromGraphQlErrors(error?.graphQLErrors ?? []);
  const misalignmentScheduleGQLErrorMessage = generateMisalignmentGQLErrorMessage(error?.graphQLErrors ?? []);

  return hasMisalignedSchedule ? misalignmentScheduleGQLErrorMessage : generalGQLErrorMessage;
};
