import React from 'react';

import { adStatuses, UNRECOGNIZED } from '../../../../configs';
import { ComponentWithRouter, withMockedProvider } from '../../../../utils/testing';
import type { AdFormValues } from '../../../../common/AdForm/adFormik';
import { getInitialValues } from '../../../../common/AdForm/adFormik';
import { makeAdListElement, makeStatusPills } from '../utils/utils';
import type { AdListItemProps } from './AdListItem';
import AdListItem from './AdListItem';
import { PathName } from '../../../../constants';
import { Query } from '../../../../apis/graphql';
import { GET_ASSETS_BY_IDS } from '../../hooks/queries';
import { getMockAsset, mockAssetId } from '../../../../common/AdForm/CreativeBuilders/VideoCreativeBuilder/mocks';
import { ClearableFrequencyCap } from '../../../../common/FrequencyCaps/FrequencyCaps';
import { AdsPageAd } from '../../../../common/AdForm/hooks/types';
import { AdListElement } from '../types';
import { mockAd } from '../mocks';
import { filterTermValues } from '../../../../common/utils';

export const mockFormValues: AdFormValues = getInitialValues({ type: 'SLATE' });
mockFormValues.name = 'Testing';

// TODO: Should be removed from this file and used directly from useAdList ttps://jira.disneystreaming.com/browse/SPEAR-90#
function getFormValuesFromAdListAd(initialValues: AdFormValues, ad: AdsPageAd): AdFormValues {
  const formValues: AdFormValues = {
    ...initialValues,
    id: ad.id,
    name: ad.name,
    deliveryStatus: ad.status === adStatuses.ON,
    frequencyCapList: ad.frequencyCapList.map(
      ({ limit, duration, enabled, type, durationUnit }): ClearableFrequencyCap => {
        return { limit, duration, enabled, type, durationUnit };
      }
    ),
    viewabilityVendorList: ad.viewabilityVendorList,
    adTagList: ad.adTagList,
    targetingTermValues: filterTermValues(ad.targetingTermValues),
    actualStart: ad.actualStart,
    schedule: ad.schedule!,
  };

  if (ad.type.key !== UNRECOGNIZED) {
    formValues.type = ad.type.key;
  }

  if (ad.rating.key !== UNRECOGNIZED) {
    formValues.rating = ad.rating.key;
  }

  if (ad.creative) {
    formValues.creative = ad.creative;
  }

  return {
    ...formValues,
  };
}

// adPropMap makes it easier for our story consumers to select between the two scenarios:
// when an actual AdListAd is passed for the `ad` prop value, or when AdListAd is `null`
// If we need additional mock ad data we can add them here.
const adPropMap = {
  'use mocked ad data': makeAdListElement(mockFormValues, mockAd),
  'use mocked "new" ad data': makeAdListElement(mockFormValues),
};

export default {
  title: 'Ads/AdListItem',
  component: AdListItem,
  excludeStories: ['mockAd', 'mockFormValues'],
  argTypes: {
    adListElement: {
      type: {
        name: 'object',
        required: true,
      },
      control: {
        type: 'select',
        options: adPropMap,
      },
      defaultValue: adPropMap['use mocked "new" ad data'],
    },
    adIndex: { control: { disable: true } },
    onClick: {
      action: 'cicked with ad index',
    },
    onClickDelete: {
      action: 'clicked delete with ad index',
    },
  },
};

type AdListItemStoryArgs = Partial<AdListItemProps> & {
  adListElement: AdListElement;
};

const defaultArgs = {
  adListElement: adPropMap['use mocked "new" ad data'],
  adIndex: 0,
  isSelected: false,
  onClick: (): void => {},
  onClickDelete: (): void => {},
  isSequence: false,
};

const location = {
  pathname: PathName.adsEdit,
  search: '',
  hash: '',
  state: { values: { id: 123 } },
  key: '',
};

const makeAssetIdsMock = (assetIdList: string[], result: Query['getAssetsByIDsV5']) => ({
  request: {
    query: GET_ASSETS_BY_IDS,
    variables: {
      assetIdList,
      paginationOptions: { limit: 1, offset: 0 },
      filterOptions: { creativeIdList: ['123'] },
    },
  },
  result: {
    data: {
      getAssetsByIDsV5: result,
    },
    error: null,
  },
});

// Base is the base AdListItem component + any args it may receive from story consumers.
// The only real variations would be whether or not an AdListAd is provided for the `ad` prop.
export const Base = (args?: AdListItemStoryArgs): JSX.Element => {
  const props = args ? { ...defaultArgs, ...args } : defaultArgs;

  // get the mapped `ad` prop data if the key provided in `args` exists
  const { adListElement } = props;

  return (
    <ComponentWithRouter
      componentWithRedirection={(): JSX.Element =>
        withMockedProvider(
          <AdListItem
            {...props}
            ad={adListElement.ad}
            weight={args?.weight ?? 10}
            pills={makeStatusPills(adListElement)}
            isSaved
          />,
          [makeAssetIdsMock([mockAssetId], [getMockAsset()])],
          false
        )
      }
      startingUrl={location.pathname}
      initialEntries={[location]}
      redirectUrl={location.pathname}
    />
  );
};

export const WithMockAd = (ad: Partial<AdsPageAd>, args?: Partial<AdListItemProps>): JSX.Element => {
  const adListElement = makeAdListElement(mockFormValues, { ...mockAd, ...ad });

  return Base({ adListElement, ...args });
};
