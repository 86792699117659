import { v4 as uuidv4 } from 'uuid';

import { NEW_SEQUENCE_ID, NEW_SEQUENCE_LABEL } from '../constants';
import type { TransformedSequence } from '../types';

export const getInitialSequence = (sequenceIndex: number): TransformedSequence => {
  return {
    id: NEW_SEQUENCE_ID + uuidv4(),
    name: NEW_SEQUENCE_LABEL + sequenceIndex,
    ads: [],
  };
};
