import React from 'react';

/**
 * Displays the Publisher segment
 */
export const AssetPublisher: React.FC<{ publisher?: string }> = (props) => {
  if (!props.publisher) return null;

  // Convert a publisher term such as "DISNEY_PLUS" into "Disney Plus"
  const formattedPublisher = props.publisher
    ?.split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

  return (
    <span>
      {' '}
      | <strong>Publisher:</strong> {formattedPublisher}
    </span>
  );
};
