import type { FormikErrors } from 'formik';

import type { AdFormValues } from '../../adFormik';

export const isTabComplete = (id?: string | null): boolean => !!id;

export const isTabFailed = (errors: FormikErrors<AdFormValues>, fields: string[]): boolean => {
  return fields.some((field) => {
    return !!errors[field as keyof AdFormValues];
  });
};
