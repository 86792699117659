import React from 'react';
import type { Column } from 'react-table';

import Pill, { PillColor } from '../../../../../../../../../../common/Pill';
import Tooltip from '../../../../../../../../../../common/Tooltip';
import type { StatusTableData } from '../types';
import { colorPills } from './utils';

/** props minWidth/width don't work with columns settings for unknown reason */
export const getColumns = (className: string): Column<StatusTableData>[] => [
  {
    accessor: 'id',
    Cell: ({ cell: { value } }): JSX.Element => (
      <Tooltip id="edit-multiple-status-table-id" message={value}>
        <div className={className}>{value}</div>
      </Tooltip>
    ),
    Header: 'ID',
  },
  {
    accessor: 'name',
    disableSortBy: true,
    Cell: ({ cell: { value } }): JSX.Element => (
      <Tooltip id="edit-multiple-status-table-name" message={value}>
        <div className={className}>{value}</div>
      </Tooltip>
    ),
    Header: 'Name',
  },
  {
    accessor: 'status',
    disableSortBy: true,
    Cell: ({ cell: { value } }): JSX.Element => {
      return (
        <Pill
          text={value?.displayName || ''}
          color={value?.displayName ? colorPills[value?.displayName] : PillColor.YELLOW_DARK}
        />
      );
    },
    Header: 'Current Status',
  },
];
