import './EditablePercentInput.scss';

import { TextField as TrekTextField } from '@hulu/react-style-components';
import React, { memo, useCallback, useState } from 'react';
import { useToggle } from 'react-use';

import bem from '../../../utils/bem';
import { formatPercentage } from '../../../utils/formatting';
import EditableCell from '../EditableCell';
import type { EditableInputVariant } from '../EditableCell/EditableCell';
import EditableCellModal from '../EditableCellModal';
export interface EditablePercentInputProps {
  onSubmit: (value: number) => void;
  variant: EditableInputVariant;
  initialValue?: number | null;
  upperLimit: number;
  lowerLimit: number;
  isDisabled?: boolean;
  withBorder?: boolean;
  labelText?: string;
}

const [block, element] = bem('editable-percent-input');

function EditablePercentInput({
  onSubmit,
  initialValue,
  variant,
  lowerLimit,
  upperLimit,
  isDisabled,
  withBorder,
  labelText,
}: EditablePercentInputProps): JSX.Element {
  // Manage "isActive" state to provide to EditableCell wrapper
  // The isActive state does not control the active state of the input
  // It controls the style of the containing EditableCell div
  const [active, toggleActive] = useToggle(false);

  // we use value as a string in order to allow the field to take any character input
  // then manually validate that it is numerical, and call onSubmit as a number
  const [value, setValue] = useState(
    initialValue !== null && initialValue !== undefined ? initialValue.toString() : ''
  );
  const [error, setError] = useState(false);

  const handleActivate = (): void => {
    if (isDisabled) {
      return;
    }
    if (variant !== 'saving') toggleActive(true);
  };

  const handleCancel = useCallback((): void => {
    setError(false);
    toggleActive(false);
    setValue(initialValue !== null && initialValue !== undefined ? initialValue.toString() : '');
  }, [initialValue, toggleActive]);

  const handleSubmit = useCallback((): void => {
    // clean up trailing "%" if included
    const trimmedValue: number = parseFloat(value.replace(/%$/, ''));

    if (trimmedValue < lowerLimit || trimmedValue > upperLimit) {
      setError(true);
    } else {
      setError(false);
      onSubmit(trimmedValue);
      toggleActive(false);
    }
  }, [lowerLimit, onSubmit, toggleActive, upperLimit, value]);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const targetValue = e.target.value || '';
    setValue(targetValue);
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      handleSubmit();
    }
    if (e.key === 'Escape') {
      e.stopPropagation();
      handleCancel();
    }
  };

  const errorMessage = `Enter number between ${lowerLimit} and ${upperLimit}`;

  return (
    <div className={block(withBorder ? 'with-border' : '')}>
      {labelText && <div className={element('labelText')}>{labelText}</div>}

      <EditableCell usePencil={true} onClick={handleActivate} variant={active ? 'active' : variant} tabIndex={-1}>
        <div>
          {active ? (
            <EditableCellModal
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              hasValue={Boolean(value)}
              errorMessage={error ? errorMessage : undefined}
            >
              <TrekTextField
                value={value}
                onChange={onChangeHandler}
                onKeyDown={onKeyDownHandler}
                autoFocus
                type="number"
                placeholder={`${lowerLimit} - ${upperLimit}`}
                isErr={error}
              />
            </EditableCellModal>
          ) : (
            <div className={element('display')}>
              <span className={element('rendered-value')}>
                {initialValue !== null && initialValue !== undefined ? formatPercentage(initialValue) : ''}
              </span>
            </div>
          )}
        </div>
      </EditableCell>
    </div>
  );
}

export default memo(EditablePercentInput);
