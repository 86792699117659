import type { TableInstance } from 'react-table';

import type { Maybe } from '../../../../apis/graphql';
import { getDataFromNodes } from '../../../../apis/graphql';
import type { TraffickingTableName } from '../../../../constants';
import type { GlobalFilters } from '../../../../contexts/FilterContext/hooks';
import type { ResultKeys } from '../../constants';
import type { QueryVariables } from '../../TraffickingPage/hooks';
import type { EntityModel } from '../../TraffickingPage/modelConverters';
import type { TraffickerTableData } from '../../types';
import { CHUNK_SHIFT, COUNT_PARALLEL_REQUESTS_PER_CHUNK, COUNT_RETRIES, EXPORT_LIMIT_PER_PAGE } from '../constants';
import type { FetchDataResponse } from '../hooks/useTraffickerExportTable';
import type { GetQueryVariables, LoadDataPerTable } from '../types';
import { getDataRowsToExport } from './utils';

type ProcessEntitesData = {
  data: TraffickerTableData;
  loadTableDataOptions: LoadDataPerTable;
  exportedIds: string[];
  tableName: TraffickingTableName;
  tableInstance: TableInstance<EntityModel>;
};

type ProcessEntitiesDataResponse = {
  newEntityIds: string[];
  newDataToExport: string[][];
};

export const processEntitiesDataToExport = ({
  data,
  loadTableDataOptions,
  exportedIds,
  tableName,
  tableInstance,
}: ProcessEntitesData): ProcessEntitiesDataResponse => {
  let entities = getDataFromNodes(data, loadTableDataOptions.resultKey, loadTableDataOptions.transformFn)();

  const uniqueEntitiesToExport = entities.filter((entity) => !exportedIds.includes(entity.id));

  const newEntityIds = uniqueEntitiesToExport.map((entity) => entity.id);

  const newDataToExport = getDataRowsToExport({
    tableName,
    tableInstance,
    entities: uniqueEntitiesToExport,
    selectedItems: loadTableDataOptions.selectedItems,
  });

  return {
    newEntityIds,
    newDataToExport,
  };
};

export const getNewCurrentTotal = (
  fetchEntitiesResponseData: TraffickerTableData[],
  resultKey: ResultKeys,
  currentTotal: number
): number => {
  if (!fetchEntitiesResponseData.length) {
    return currentTotal;
  }

  return fetchEntitiesResponseData[fetchEntitiesResponseData.length - 1]
    ? fetchEntitiesResponseData[fetchEntitiesResponseData.length - 1][resultKey]?.total || currentTotal
    : currentTotal;
};

type FetchChunkData = {
  currentOffset: number;
  total: number;
  queryFilters: GlobalFilters;
  fetchEntities: (queryVars: QueryVariables) => Promise<FetchDataResponse>;
  getQueryVariables: GetQueryVariables;
};

type FetchChunkDataResponse = {
  newOffset: number;
  loadChunkFailed: boolean;
  fetchEntitiesResponseData: Maybe<TraffickerTableData[]>;
};

type RefetchRequestData = Pick<FetchChunkData, 'queryFilters' | 'fetchEntities' | 'getQueryVariables'> & {
  offset: number;
};

const refetchRequestData = async ({
  queryFilters,
  fetchEntities,
  getQueryVariables,
  offset,
}: RefetchRequestData): Promise<FetchDataResponse | null> => {
  let dataResponse: FetchDataResponse | null = null;
  let countRetries = COUNT_RETRIES;
  while (countRetries > 0) {
    const queryVariables = getQueryVariables(queryFilters, offset);
    const fetchDataResponse = await fetchEntities(queryVariables);

    if (fetchDataResponse.data && !(fetchDataResponse.errors && fetchDataResponse.errors.length)) {
      dataResponse = fetchDataResponse;
      break;
    }
    countRetries -= 1;
  }

  return dataResponse;
};

export const fetchChunkData = async ({
  currentOffset,
  total,
  queryFilters,
  fetchEntities,
  getQueryVariables,
}: FetchChunkData): Promise<FetchChunkDataResponse> => {
  let loadChunkFailed = false;
  const requestOffsets: number[] = [];
  const fetchEntitiesRequests: Promise<FetchDataResponse>[] = [];
  let offset = currentOffset;

  try {
    for (let i = 0; i < COUNT_PARALLEL_REQUESTS_PER_CHUNK; i++) {
      if (offset > total) break;

      const queryVariables = getQueryVariables(queryFilters, offset);
      requestOffsets.push(offset);
      fetchEntitiesRequests.push(fetchEntities(queryVariables));

      /*
        There may be a case when we could miss some items.
        Suppose the first and second request have been fetched, and the third request is currently being fetched.
        And at the same time an item from the already-fetched first or second request might be removed.
        This could result in missing items from the final export.
        To mitigate this issue, we implement an offset shift after each request.
        So, we re-fetch the last 100 items from the previous request to reduce the amount of missed items.
       */
      offset += EXPORT_LIMIT_PER_PAGE - CHUNK_SHIFT;
    }

    const fetchEntitiesResponseData = await Promise.all(fetchEntitiesRequests);

    for (let i = 0; i < fetchEntitiesResponseData.length; i++) {
      const { data, errors } = fetchEntitiesResponseData[i];

      if (data && !(errors && errors.length)) {
        continue;
      }

      const fetchDataResponse = await refetchRequestData({
        queryFilters,
        fetchEntities,
        getQueryVariables,
        offset: requestOffsets[i],
      });

      if (fetchDataResponse) {
        fetchEntitiesResponseData[i] = fetchDataResponse;
      } else {
        loadChunkFailed = true;
        break;
      }
    }

    return {
      loadChunkFailed,
      newOffset: offset,
      fetchEntitiesResponseData: loadChunkFailed
        ? null
        : fetchEntitiesResponseData.map((fetchEntitiesResponse) => fetchEntitiesResponse.data),
    };
  } catch {
    return {
      loadChunkFailed: true,
      newOffset: offset,
      fetchEntitiesResponseData: null,
    };
  }
};
