import { CreativeV5Type } from '../../apis/graphql';

export const RESTRICTED_CREATIVE_TYPES: CreativeV5Type[] = [
  CreativeV5Type.AdSelectorSlate,
  CreativeV5Type.AdSelectorVideo,
  CreativeV5Type.Brightline,
  CreativeV5Type.GatewayGo,
  CreativeV5Type.Innovid,
  CreativeV5Type.Pause,
  CreativeV5Type.Slate,
  CreativeV5Type.VastVideo,
  CreativeV5Type.Video,
];

export enum CreativeStatus {
  Pending = 'PENDING',
  Preview = 'PREVIEW',
  Review = 'REVIEW',
  Approved = 'APPROVED',
  Published = 'PUBLISHED',
  Rejected = 'REJECTED',
}

export const CREATIVE_STATUSES_ASSET_IS_NOT_REMOVABLE: string[] = [CreativeStatus.Approved, CreativeStatus.Published];
