import { gql } from '@apollo/client';

/**
 * Begin the asset ingestion process for a VAST creative
 */

export const START_ASSET_INGESTION = gql`
  mutation StartAssetIngestion($creativeId: ID!, $vastUrl: String!) {
    startAssetIngestion(creativeId: $creativeId, vastUrl: $vastUrl) {
      tracingId
      duplicate
    }
  }
`;
