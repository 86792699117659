import './CreativeSectionWrapper.scss';

import { bem } from 'mission-control-common-components';
import React from 'react';

const [block, element] = bem('creative-section-wrapper');

type Props = {
  icon?: React.JSX.Element;
  title: string;
  children?: React.ReactNode;
};

const CreativeSectionWrapper = ({ children, icon, title }: Props): React.JSX.Element => {
  return (
    <div className={block()}>
      <div className={element('title-wrapper')}>
        <span className={element('icon')}>{icon}</span>
        <span className={element('title')}>{title}</span>
      </div>
      <div className={element('children-wrapper')}>{children}</div>
    </div>
  );
};

export default CreativeSectionWrapper;
