import { Modal } from 'antd';
import React, { useMemo } from 'react';

import type { TargetingPreset } from '../../apis/graphql';
import type { Nullable } from '../../models';
import Loader from '../Loader';
import TargetingPresetDetails from './TargetingPresetDetails';
import useFetchTargetingPresets from './TargetingPresetDetails/hooks/useFetchTargetingPresets';

type TargetingPresetModalProps = {
  targetingPresetId: Nullable<string>;
  visible: boolean;
  onClose: () => void;
};

const TargetingPresetModal = ({
  targetingPresetId,
  onClose,
  visible = false,
}: TargetingPresetModalProps): JSX.Element => {
  const { targetingPresets, loading } = useFetchTargetingPresets({
    offset: 0,
    archived: null,
    includeTargetingTerms: true,
    ...(targetingPresetId && { idList: [targetingPresetId] }),
    skip: !visible || !targetingPresetId,
  });

  // only one value in array since we send single ID to GQL
  const targetingPreset: Nullable<TargetingPreset> = useMemo(() => {
    return targetingPresets?.length ? targetingPresets[0] : null;
  }, [targetingPresets]);

  return (
    <Modal open={visible} onCancel={onClose} footer={null} width="90%">
      {loading ? (
        <Loader text="Loading targeting preset data" />
      ) : (
        <TargetingPresetDetails targetingPreset={targetingPreset} />
      )}
    </Modal>
  );
};

export default TargetingPresetModal;
