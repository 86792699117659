import './EditMultipleButton.scss';

import { IconEdit } from '@hulu-react-style-components/icons';
import React from 'react';

import Button from '../../../common/Button';
import Tooltip from '../../../common/Tooltip';
import type { TraffickingTableName } from '../../../constants';
import bem from '../../../utils/bem';
import { tooltipMessage } from './utils';

const [block, element] = bem('edit-multiple-button');

interface EditMultipleButtonProps {
  selectedObjectsLength: number;
  tableName: TraffickingTableName;
  onClick: () => void;
  hasPermission?: boolean;
}

const EditMultipleButton = ({
  selectedObjectsLength,
  tableName,
  onClick,
  hasPermission = true,
}: EditMultipleButtonProps): JSX.Element => {
  return (
    <Tooltip
      id="edit-multiple"
      className={element('tooltip')}
      message={tooltipMessage(tableName)}
      disable={!!selectedObjectsLength}
    >
      <Button
        size="medium"
        icon={<IconEdit />}
        variant="outlined"
        className={block()}
        onClick={onClick}
        disabled={!selectedObjectsLength || !hasPermission}
        aria-label="Edit Multiple"
        data-testid="edit-multiple-button"
      >
        Edit Multiple
      </Button>
    </Tooltip>
  );
};

export default EditMultipleButton;
