import React from 'react';

import ErrorMessage from '../../../common/ErrorMessage';
import MSTRReport from '../MSTRReport';
import type { ReportEntityType, ReportType } from '../types';
import { useToken } from './hooks';

type ReportsPageContainerProps = {
  reportType: ReportType;
  entityType: ReportEntityType;
  entityId: string;
};

const ReportsPageContainer = (reportsPageProps: ReportsPageContainerProps): React.ReactElement => {
  const { loading, error, payload } = useToken();

  if (loading) {
    return <h1>Loading</h1>;
  }

  if (error || payload == null) {
    return <ErrorMessage message={error || 'Unable to fetch MSTR Token.'} showEmailAddress />;
  }

  return <MSTRReport {...reportsPageProps} mstrToken={payload} />;
};

export default ReportsPageContainer;
