import React from 'react';

import { ToggleSection } from '../../ToggleSection';
import ChangeLogTablesList from '../ChangeLogTablesList';
import ChangeLogTitle from '../ChangeLogTitle';
import type { TargetingEntry, Toggles } from '../hooks/useChangeLog';
import type { ChangeLogTableProps } from '../types';

export interface DefaultAudit {
  tablesProps: ChangeLogTableProps[];
  changesPlural: string;
  changesLength: number;
}

interface Props {
  audits: DefaultAudit;
  creativeAudits: DefaultAudit;
  toggles: Toggles[];
  toggleAt: (index: number, value: boolean | boolean[]) => void;
  originalIndex: number;
  dateFormat: string;
  modifiedBy: string | null;
  creativeModifiedBy: string | null;
  creativeDateFormat: string;
  targetingEntry?: TargetingEntry;
}

const ChangeLogListEntry = ({
  audits,
  creativeAudits,
  toggles,
  toggleAt,
  originalIndex,
  dateFormat,
  creativeDateFormat,
  modifiedBy,
  creativeModifiedBy,
  targetingEntry,
}: Props): JSX.Element => {
  const isExpanded = Array.isArray(toggles[originalIndex])
    ? (toggles[originalIndex] as boolean[])[0]
    : (toggles[originalIndex] as boolean);

  const title = (
    <ChangeLogTitle
      id={`toggle-title-${originalIndex}`}
      changesPlural={audits.changesPlural}
      formattedDate={dateFormat}
      modifiedBy={modifiedBy}
    />
  );

  return (
    <ToggleSection
      title={title}
      isExpanded={isExpanded}
      handleToggle={(): void => {
        toggleAt(originalIndex, toggles[originalIndex]);
      }}
      key={`toggle-${originalIndex}`}
    >
      <ChangeLogTablesList
        tablesProps={audits.tablesProps}
        creativeTablesProps={creativeAudits}
        targetingEntry={targetingEntry}
        creativeDateFormat={creativeDateFormat}
        creativeModifiedBy={creativeModifiedBy || ''}
        toggles={toggles}
        index={originalIndex}
        toggleAt={toggleAt}
      />
    </ToggleSection>
  );
};

export default ChangeLogListEntry;
