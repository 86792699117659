import './VastContent.scss';

import React from 'react';

import { AssetSlider } from '../../../../_ForCommonLibrary/components/organisms/AssetSlider';
import type { AssetExtended } from '../../../../_ForCommonLibrary/types/extended';
import type { IngestionTrial } from '../../../../../apis/graphql';
import Alert, { AlertType } from '../../../../Alert';

export const VastContent: React.FC<{
  creativeName: string;
  assets?: AssetExtended[] | null;
  creativeId: string;
  trialsWithoutAssets?: IngestionTrial[];
}> = ({ creativeName, assets, creativeId, trialsWithoutAssets }) => {
  // No assets and no ingestion errors present
  if (!assets?.length && !trialsWithoutAssets?.length) {
    return (
      <Alert type={AlertType.INFORMATION} bodyText={`Assets not available. Click below to begin asset ingestion.`} />
    );
  }

  return (
    <>
      {!!assets?.length && <AssetSlider title={creativeName} assets={assets} creativeId={creativeId} />}
      {!!trialsWithoutAssets?.length && (
        <Alert
          className="vast-content-alert"
          type={assets?.length ? AlertType.WARNING : AlertType.ERROR}
          headerText={`${assets?.length ? 'Additional ' : ''}Ingestion Errors`}
          bodyText={
            <ul>
              {trialsWithoutAssets.map((trial, i) => {
                const { __typename, ...trialData } = trial;
                return <li key={i}>{JSON.stringify(trialData, null, 1)}</li>;
              })}
            </ul>
          }
        />
      )}
    </>
  );
};
