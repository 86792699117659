import { gql } from '@apollo/client';

export const ASSET_IMPORTER_ASSET = gql`
  fragment assetImporterAssetV5 on BaseAssetV5 {
    assetTagList {
      id
      description
      displayName
      value
    }
    comments
    countryCodes
    createdAt
    filename
    fileSize
    id
    md5
    name
    publisher
    rejectionReasons
    review
    s3Url
    type
    updatedAt

    ... on DisplayAssetV5 {
      height
      kinkoKey
      width
    }
    ... on VideoAssetV5 {
      countryCodes
      duration
      rating
    }
  }
`;

// Asset data to populate CreativeBuilder in AdsPage
export const CREATIVE_BUILDER_ASSET = gql`
  fragment creativeBuilderAssetV5 on CreativeV5Asset {
    __typename
    asset {
      ...assetImporterAssetV5
    }
  }
  ${ASSET_IMPORTER_ASSET}
`;
