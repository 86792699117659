import { gql } from '@apollo/client';

export const GET_LINE_ITEM_PRIORITIES = gql`
  query LineItemPriorities {
    lineItemPrioritiesV5 {
      name
      rank
    }
  }
`;
