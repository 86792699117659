import { IconChevronDown, IconChevronRight } from '@hulu-react-style-components/icons';
import { Checkbox, Label, Radio } from '@hulu/react-style-components';
import React from 'react';

import type { DropdownOption } from '../../../../../../common/Dropdown';
import Loader from '../../../../../../common/Loader';
import type { MultiSelectDropdownProps } from '../../../../../../common/MultiSelectDropdown/MultiSelectDropdown';
import type { SearchParamsValuesType } from '../../../../../../constants';
import bem from '../../../../../../utils/bem';
import MultiDropdownFilter from '../../../MultiDropdownFilter';
import type { AdditionalFilterDropdown } from '../../constants';
import { AdditionalFilterType } from '../../constants';

export type FilterDropdownProps = {
  filter: AdditionalFilterDropdown;
  isExpanded: boolean;
  selectedValues: (string | DropdownOption<string>)[];
  handleApplyFilter: (selectedSearchParam: SearchParamsValuesType, value: string[]) => void;
  handleRemoveFilter: (selectedSearchParam: SearchParamsValuesType) => void;
  onToggle: (itemLabel: string) => void;
  setSelectedFilter(): void;
} & Omit<MultiSelectDropdownProps, 'value' | 'options' | 'onChange' | 'handleRemove'>;

// same styles as single-select dropdown
const [block, element] = bem('filter-dropdown');

const FilterDropdown = ({
  isExpanded,
  filter,
  selectedValues,
  handleApplyFilter,
  handleRemoveFilter,
  onToggle,
  setSelectedFilter,
}: FilterDropdownProps): JSX.Element => {
  const { label, searchParam, type, multiDropdownPlaceholder, useOptions } = filter;
  const { options, loading } = useOptions();

  const handleChange = (value: string): void => {
    if (type === AdditionalFilterType.RADIOBUTTON) {
      handleApplyFilter(searchParam, [value]);
    } else {
      if (selectedValues.includes(value)) {
        const updatedValuesList = selectedValues.filter((selectedValue) => selectedValue !== value);
        handleApplyFilter(searchParam, [...updatedValuesList] as string[]);
      } else {
        handleApplyFilter(searchParam, [...selectedValues, value] as string[]);
      }
    }
    setSelectedFilter();
  };

  const handleToggle = (e: React.MouseEvent): void => {
    e.stopPropagation();
    onToggle(label);
  };

  const handleClear = (e: React.MouseEvent): void => {
    e.stopPropagation();
    handleRemoveFilter(searchParam);
  };

  return (
    <div className={block()} data-testid="filter-dropdown">
      <div className={element('header')} onClick={handleToggle} aria-labelledby="dropdown-button-label">
        <div className={element('chevron-icon')}>
          {isExpanded ? <IconChevronDown width={14} height={14} /> : <IconChevronRight width={14} height={14} />}
        </div>
        <Label className={element('label')} id="dropdown-button-label">
          {label} {!!selectedValues.length && `(${selectedValues.length})`}
        </Label>
        {!!selectedValues.length && (
          <div onClick={handleClear} className={element('clear')}>
            CLEAR
          </div>
        )}
      </div>
      {isExpanded && !loading && (
        <div>
          {type === AdditionalFilterType.DROPDOWN && (
            <MultiDropdownFilter
              searchParam={searchParam}
              multiDropdownPlaceholder={multiDropdownPlaceholder}
              selectedValues={selectedValues as DropdownOption[]}
              onSetSelectedFilter={setSelectedFilter}
              onRemoveFilter={handleRemoveFilter}
              useOptions={useOptions}
            />
          )}
          {type !== AdditionalFilterType.DROPDOWN &&
            options.map((option) => {
              return (
                <div
                  className={element('checkbox-container')}
                  data-testid="checkbox-container"
                  key={option.label}
                  onClick={(): void => handleChange(option.value)}
                >
                  {type === AdditionalFilterType.CHECKBOX && (
                    <Checkbox
                      id={option.value}
                      isChecked={selectedValues?.includes(option.value)}
                      label={option.label}
                      onChange={(): void => {}}
                      onKeyDown={(): void => {}}
                    />
                  )}
                  {type === AdditionalFilterType.RADIOBUTTON && (
                    <Radio
                      id={option.value}
                      isChecked={selectedValues?.includes(option.value)}
                      isErr={false}
                      label={option.label}
                      onChange={(): void => {}}
                      onKeyDown={(): void => {}}
                    />
                  )}
                </div>
              );
            })}
        </div>
      )}
      {isExpanded && loading && <Loader text="Loading..." />}
      {isExpanded && !loading && options.length === 0 && !AdditionalFilterType.DROPDOWN && (
        <div className={element('not-found')}>{`No results found for "${label}"`}</div>
      )}
    </div>
  );
};

export default FilterDropdown;
