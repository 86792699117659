import type { ApolloError } from '@apollo/client';
import { useMutation } from '@apollo/client';
import type { Row } from 'react-table';

import type {
  SendCampaignsOnReviewMutation,
  SendCampaignsOnReviewMutationVariables,
  SendLineItemsOnReviewMutation,
  SendLineItemsOnReviewMutationVariables,
} from '../../../../apis/graphql';
import type { EntityModel } from '../../TraffickingPage/modelConverters';
import type { FlatRowType, ReviewTableName } from '../types';
import { SEND_CAMPAIGNS_ON_REVIEW } from './mutations/sendCampaignsOnReview';
import { SEND_LINE_ITEMS_ON_REVIEW } from './mutations/sendLineItemsOnReview';

export interface UseSendOnReviewReturnType {
  loading: boolean;
  sendOnReview: (selectedFlatRows: FlatRowType<EntityModel>, tableName: ReviewTableName) => void;
  error?: ApolloError;
  data?: SendCampaignsOnReviewMutation | SendLineItemsOnReviewMutation | null;
}

const useSendOnReview = (): UseSendOnReviewReturnType => {
  const [
    sendLineItemsOnReview,
    { data: sendLineItemsOnReviewData, loading: sendLineItemsOnReviewLoading, error: sendLineItemsOnReviewError },
  ] = useMutation<SendLineItemsOnReviewMutation, SendLineItemsOnReviewMutationVariables>(SEND_LINE_ITEMS_ON_REVIEW);

  const [
    sendCampaignsOnReview,
    { data: sendCampaignsOnReviewData, loading: sendCampaignsOnReviewLoading, error: sendCampaignsOnReviewError },
  ] = useMutation<SendCampaignsOnReviewMutation, SendCampaignsOnReviewMutationVariables>(SEND_CAMPAIGNS_ON_REVIEW);

  const sendOnReview = (selectedFlatRows: FlatRowType<EntityModel>, tableName: ReviewTableName): void => {
    const idsList = selectedFlatRows.map((el: Row<EntityModel>) => el.id);

    if (!idsList.length) {
      return;
    }

    const options = {
      variables: {
        ids: idsList,
      },
    };
    if (tableName === 'lineItems') {
      sendLineItemsOnReview(options);
    } else if (tableName === 'campaigns') {
      sendCampaignsOnReview(options);
    }
  };

  return {
    loading: sendLineItemsOnReviewLoading || sendCampaignsOnReviewLoading,
    sendOnReview,
    error: sendCampaignsOnReviewError || sendLineItemsOnReviewError,
    data: sendLineItemsOnReviewData || sendCampaignsOnReviewData,
  };
};

export default useSendOnReview;
