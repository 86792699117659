import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from './types';
import { convertToDisplayEnum, makeConfig } from './utils';

export type AssetRejectionReasons = keyof typeof assetRejectionReasonDisplayNames;

const assetRejectionReasonDisplayNames = {
  AD_POLICIES_VIOLATION: enumToTitleCase,
  AUDIO_EXTRA_CHANNELS: enumToTitleCase,
  AUDIO_MISSING: enumToTitleCase,
  AUDIO_WRONG_BIT_RATE: enumToTitleCase,
  AUDIO_WRONG_CODEC: enumToTitleCase,
  AV_DURATION_MISMATCH: 'AV Duration Mismatch',
  AV_SYNC: 'AV Sync',
  BRAND_MISMATCH: enumToTitleCase,
  COMPETITOR_CALLOUT: enumToTitleCase,
  FILE_CORRUPTED: enumToTitleCase,
  FILE_WRONG_FORMAT: enumToTitleCase,
  FILE_WRONG_SIZE: enumToTitleCase,
  FRAMES_BLENDED: enumToTitleCase,
  FRAMES_DUPLICATE: enumToTitleCase,
  FRAMES_INTERLACED: enumToTitleCase,
  FRAMES_WRONG_RATE: enumToTitleCase,
  GLITCHES_OR_ARTIFACTS: enumToTitleCase,
  IMPLIED_INTERACTIVITY: enumToTitleCase,
  OTHER: enumToTitleCase,
  SLATE_PRESENT: enumToTitleCase,
  VIDEO_EXTRA_TRACKS: enumToTitleCase,
  VIDEO_WRONG_BIT_RATE: enumToTitleCase,
  VIDEO_WRONG_CODEC: enumToTitleCase,
  WRONG_ASPECT_RATIO: enumToTitleCase,
  WRONG_LENGTH: enumToTitleCase,
  WRONG_SAMPLING_RATE: enumToTitleCase,
  WRONG_SCAN_TYPE: enumToTitleCase,
};

export const assetRejectionReasons = makeConfig<AssetRejectionReasons>(assetRejectionReasonDisplayNames);

export function convertAssetRejectionReason(apiValue: string): DisplayEnum<AssetRejectionReasons> {
  return convertToDisplayEnum(apiValue, assetRejectionReasons);
}

export interface AssetRejectionDisplayValues {
  title: string;
  description: string;
}

export const assetRejectionReasonMap: Record<AssetRejectionReasons, AssetRejectionDisplayValues> = {
  AUDIO_EXTRA_CHANNELS: {
    title: 'Extra Audio Channels',
    description: 'Please reduce the number of audio channels and map to stereo.',
  },
  AUDIO_MISSING: {
    title: 'Missing Audio',
    description: 'We did not detect any audio. All Ads must have audio.',
  },
  AV_SYNC: {
    title: 'Out of Sync Audio/Video',
    description: 'The audio and video are out of sync.',
  },
  BRAND_MISMATCH: { title: 'Brand Mismatch', description: 'Ad asset brand does not match Line Item brand' },
  COMPETITOR_CALLOUT: {
    title: 'Competitor Callout',
    description: 'Callout to competitor service. Please remove the logo and/or call to action.',
  },
  FRAMES_BLENDED: { title: 'Blended Frames', description: 'Please deinterlace the Ad without using blended frames.' },
  FILE_CORRUPTED: {
    title: 'Corrupted File',
    description: 'We were unable to read and transcode your Ad. Please reencode and resubmit it.',
  },
  FRAMES_DUPLICATE: {
    title: 'Duplicate Frames',
    description: 'Please resubmit in native frame rate to remove stutter during playback.',
  },
  GLITCHES_OR_ARTIFACTS: {
    title: 'Glitches or Artifacts',
    description: 'There are glitches and/or artifacts. Please reencode and resubmit.',
  },
  SLATE_PRESENT: { title: 'Slate Present', description: 'Please remove the slate.' },
  IMPLIED_INTERACTIVITY: {
    title: 'Implied Interactivity',
    description: 'Please remove buttons, overlays, animations or other elements that imply interactivity.',
  },
  WRONG_ASPECT_RATIO: { title: 'Aspect Ratio', description: 'Please resubmit your Ad in a widescreen (16:9) format.' },
  VIDEO_WRONG_CODEC: {
    title: 'Video Codec',
    description: 'Please resubmit your Ad in Apple ProRes 422 or H.264 codecs.',
  },
  FRAMES_WRONG_RATE: {
    title: 'Frame Rate',
    description: 'Ad frame rate must be 23.976, 23.98, 25, or 29.97 frames per second.',
  },
  FRAMES_INTERLACED: {
    title: 'Interlaced Frames',
    description: 'The video is interlaced. Please resubmit with progressive scan.',
  },
  WRONG_SCAN_TYPE: { title: 'Scan Type', description: 'Video assets must be exported using Progressive Scan.' },
  AUDIO_WRONG_BIT_RATE: { title: 'Audio Bit Rate', description: 'The audio bit rate must be at least 192 kb/s.' },
  WRONG_SAMPLING_RATE: { title: 'Sampling Rate', description: 'The audio sampling rate must be 48.0 kHz.' },
  VIDEO_EXTRA_TRACKS: {
    title: 'Video Extra Tracks',
    description: 'Video assets must have only one video track. Try using Constant Bitrate (CBR).',
  },
  VIDEO_WRONG_BIT_RATE: { title: 'Video Bit Rate', description: 'Ad video bitrate must be a minimum of 15 Mbps.' },
  AD_POLICIES_VIOLATION: {
    title: 'Ad Policies Violation',
    description:
      "The Ad does not adhere to Hulu's Technical Specifications and Advertising Policies. Please review the guidelines and re-submit with a different Ad.",
  },
  AV_DURATION_MISMATCH: { title: 'Audio/Video Mismatch', description: 'Both tracks must match the video in duration.' },
  FILE_WRONG_FORMAT: { title: 'File Format', description: 'The video file format must be MOV or MP4.' },
  FILE_WRONG_SIZE: { title: 'File Size', description: 'The file size cannot exceed TBD 3 GB.' },
  WRONG_LENGTH: { title: 'Video Length', description: 'The video duration is too long.' },
  AUDIO_WRONG_CODEC: { title: 'Audio Codec', description: 'The audio codec must be PCM or AAC.' },
  OTHER: { title: 'Other', description: 'Refer to asset details.' },
};
