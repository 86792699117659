import './AdSchedule.scss';

import { IconCalendar } from '@hulu-react-style-components/icons';
import React, { useEffect } from 'react';
import { Element } from 'react-scroll';

import type { DayPartV5 } from '../../../apis/graphql';
import ScheduleCalendar from '../../../pages/Trafficking/ScheduleCalendar';
import bem from '../../../utils/bem';
import { getAdSchedule } from '../../AdForm/hooks/modelConverters';
import { Globe } from '../../assets';
import Card from '../../Card';
import { defaultSupportedTimezones } from '../../commonMocks/timezones';
import DatesInfo from '../../DatesInfo';
import { useFieldArray } from '../../Form/hooks';
import { useFormStateManager } from '../../hooks/useFormStateManager';
import { useTrackFormFieldChanges } from '../../hooks/useTrackFormFieldChanges';
import type { AdScheduleProps } from './../types';

const [block, element] = bem('ad-schedule');

function AdSchedule({
  editMode = false,
  adSchedule,
  lineItemSchedule,
  setDisableSubmitting,
}: AdScheduleProps): React.JSX.Element {
  const [, { value: dayPartList }] = useFieldArray<DayPartV5>('schedule.dayPartList');

  const { initialValues, setFormState } = useFormStateManager();
  const { areFormFieldsDirty } = useTrackFormFieldChanges();

  useEffect(() => {
    setDisableSubmitting(!areFormFieldsDirty);
  }, [setDisableSubmitting, areFormFieldsDirty]);

  useEffect(() => {
    setFormState({
      initialValues,
      values: {
        ...initialValues,
        schedule: getAdSchedule(adSchedule, lineItemSchedule),
      },
    });
  }, [initialValues, setFormState, adSchedule, lineItemSchedule]);

  return (
    <div className={block()} data-testid="ad-nav-schedule">
      <Element name="Scheduling">
        <Card>
          <div className={element('card-outer-wrapper')}>
            <div className={element('location-wrapper')}>
              <img src={Globe} alt="globe-icon" className={'globe-icon'} />
              <div className={element('location-text')} data-testid={element('location-text')}>
                {lineItemSchedule?.timezone || defaultSupportedTimezones.ET}
              </div>
            </div>
          </div>
          <div className={element('card-inner-wrapper')}>
            <div className={element('date-title')}>
              <IconCalendar />
              <h5>Dates</h5>
            </div>
            <DatesInfo lineItemSchedule={lineItemSchedule} editMode={editMode} readonly={!editMode} column />
            <ScheduleCalendar editMode={editMode} dayPartList={dayPartList} />
          </div>
        </Card>
      </Element>
    </div>
  );
}

export default AdSchedule;
