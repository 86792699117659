import './Footer.scss';

import { get as _get } from 'lodash';
import React from 'react';

type FooterProps = {};

// window.ENV_CONFIG is set when app is served via /app/serve/src/serve.ts
// Until we get the dynamic solution working, we'll use a hardcoded pre-alpha tag which makes sense to stakeholders
const versionNumber: string = _get(window, 'ENV_CONFIG.REACT_APP_VERSION', 'pre-alpha 0.0.1');

const Footer = (props: FooterProps): React.ReactElement => {
  return (
    <div className="footer">
      <span>{`Version: ${versionNumber}`}</span>
    </div>
  );
};

export default Footer;
