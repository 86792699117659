import './AdListItems.scss';

import React, { useEffect, useRef, useState } from 'react';

import ErrorMessage from '../../../../common/ErrorMessage';
import ListToggle from '../../../../common/ListToggle';
import Loader from '../../../../common/Loader';
import type { AdListData, AdRotation, Rotation } from '../../../../common/SequenceWeight/types';
import bem, { withOptionalClassName } from '../../../../utils/bem';
import AdListItem from '../AdListItem';
import AdListItemInteractive from '../AdListItemInteractive/AdListItemInteractive';
import type { AdListOptionalFormState } from '../types';
import { makeStatusPills } from '../utils/utils';

const [block, element] = bem('ad-list-items');

type AdListElementWithIndex = {
  adListElement: AdListOptionalFormState;
  adIndex: number;
};
export interface AdListItemsProps {
  adList: AdListOptionalFormState[];
  error?: string;
  loading?: boolean;
  selectedAdIndex?: number;
  adListData: AdListData;
  notInteractive?: boolean;
  className?: string;
  onClickDelete?: (adIndex: number) => void;
  onClickAd?: (adIndex: number, adId?: string) => void;
}

function AdListItems({
  adList,
  error,
  loading,
  selectedAdIndex,
  onClickAd,
  onClickDelete,
  adListData,
  notInteractive,
  className,
}: AdListItemsProps): JSX.Element {
  const [adListElementsWithIndex, setAdListElementsWithIndex] = useState<AdListElementWithIndex[]>([]);

  useEffect(() => {
    const adListElementsModified: AdListElementWithIndex[] = adList.map(
      (adListElement: AdListOptionalFormState, index: number) => {
        return { adListElement, adIndex: index };
      }
    );

    setAdListElementsWithIndex(adListElementsModified);
  }, [adList]);

  const selectedFocusRef = useRef<HTMLLIElement[]>([]);

  const handleClickDelete = (adIndex: number): void => {
    if (!notInteractive && onClickDelete) {
      onClickDelete(adIndex);
    }

    const selectedAdFocused = selectedFocusRef.current[adIndex - 1];

    if (selectedAdFocused) {
      selectedAdFocused.focus();
    }
  };

  if (loading) return <Loader />;
  // display error if there's no ad, or only a default ad
  if (error && (adList.length === 0 || adList[0].ad.id === '')) return <ErrorMessage message={error} />;

  if (adList.length === 0 && notInteractive) return <div className={element('no-ads-message')}>No ads.</div>;

  if (adList.length === 0) return <ErrorMessage message="No ads. Please create an ad!" />;

  const getAdListItem = (
    adListElement: AdListOptionalFormState,
    adIndex: number,
    weight: number,
    isSequence: boolean
  ): JSX.Element => {
    const pills = makeStatusPills(adListElement);

    if (notInteractive) {
      return (
        <AdListItem
          ad={adListElement.ad}
          isSaved={true}
          isSelected={false}
          pills={pills}
          weight={weight}
          key={adIndex}
          isSequence={isSequence}
        />
      );
    } else {
      return (
        <AdListItemInteractive
          selectedFocusRef={(el: HTMLLIElement): HTMLLIElement => (selectedFocusRef.current[adIndex] = el)}
          key={adIndex}
          ad={adListElement.ad}
          adIndex={adIndex}
          isSelected={selectedAdIndex === adIndex}
          onClick={onClickAd as (adIndex: number, adId?: string) => void}
          onClickDelete={(): void => handleClickDelete(adIndex)}
          weight={weight}
          pills={pills}
          isAdListWithOneItem={adList.length === 1}
          isSequence={isSequence}
        />
      );
    }
  };

  const getListItemsForAdRotations = (adRotations: AdRotation[], isSequence = false): JSX.Element => (
    <>
      {adRotations.map((adRotation: AdRotation) => {
        const neededElement = adListElementsWithIndex.find(
          (adListElementWithIndex: AdListElementWithIndex) =>
            adListElementWithIndex.adListElement.ad.id === adRotation.adId
        );

        if (!neededElement) return null;

        return getAdListItem(neededElement.adListElement, neededElement.adIndex, adRotation.weight, isSequence);
      })}
    </>
  );

  return (
    <>
      <ul className={withOptionalClassName(block(), className)} data-testid="ad-list-items">
        {adList.map((adListElement: AdListOptionalFormState, adIndex: number) =>
          !adListElement.ad.id ? getAdListItem(adListElement, adIndex, 0, false) : null
        )}
        {[...adListData.rotations].map((rotation: Rotation) => {
          return (
            <div className={element('toggle-container')} key={rotation.id}>
              <ListToggle
                ignoreInitialExpanded
                header={
                  <div>
                    <span className={element('list-name')}>{rotation.name}</span>
                    <div className={element('list-type')}>Rotation</div>
                  </div>
                }
              >
                {getListItemsForAdRotations(rotation.adsList, false)}
              </ListToggle>
            </div>
          );
        })}
        {[...adListData.sequences].map((sequence) => {
          return (
            <div className={element('toggle-container')} key={sequence.id}>
              <ListToggle
                ignoreInitialExpanded
                header={
                  <div>
                    <span className={element('list-name')}>{sequence.name}</span>
                    <div className={element('list-type')}>Sequence</div>
                  </div>
                }
              >
                {getListItemsForAdRotations(sequence.adsList, true)}
              </ListToggle>
            </div>
          );
        })}
        {getListItemsForAdRotations(adListData.adsWithoutWeightOrSequence, true)}
      </ul>
      {/* {error && !adList && <ErrorMessage message={error} />} */}
    </>
  );
}

export default AdListItems;
