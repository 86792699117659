import './LineItemStatusCell.scss';

import { User } from 'mission-control-common-components';
import React, { memo, useEffect } from 'react';
import type { Cell } from 'react-table';

import EditableDropdown from '../../../../../common/EditableTableCells/EditableDropdown';
import Pill from '../../../../../common/Pill';
import { openToastAlert } from '../../../../../common/ToastAlert/toastAlert';
import { findValueInArray } from '../../../../../common/utils';
import type { DisplayEnum, LineItemStatus } from '../../../../../configs';
import {
  convertConfigToDropdownOption,
  convertConfigToDropDownOptions,
  dropdownLineItemStatuses,
  lineItemDerivedState,
  STATUS_ERROR,
} from '../../../../../configs';
import type { PermissionsNames } from '../../../../../constants';
import { ERROR_PATTERNS } from '../../../../../constants';
import bem from '../../../../../utils/bem';
import { getIdFromTableCell } from '../../columns';
import { useUpdateLineItem } from '../../hooks';
import type { LineItemModel } from '../../modelConverters';
import { getLineItemStaticState, getPillColor, getSubStatusSelectedValue } from './utils';

type Props = Cell<LineItemModel, DisplayEnum<LineItemStatus>>;
const [, element] = bem('line_item_status_cell');

function LineItemStatusCell(props: Props): JSX.Element {
  const permissions = User.getPermissions<PermissionsNames>();
  const id = getIdFromTableCell(props);
  const subStatus = props.row.original.subStatus;
  const { value } = props;

  const { cellVariant, updateLineItemField, errors } = useUpdateLineItem(id, 'status');

  const lineItemStaticState = getLineItemStaticState({
    lineItemStatus: value,
    subStatus,
  });
  const subStatusSelectedValue = getSubStatusSelectedValue({ status: value, subStatus });
  const highlightSelectedDropdownValue = getPillColor({ subStatus: subStatus, status: value });

  const subStatusesWithEdit =
    subStatus?.key === lineItemDerivedState.LATE.key ||
    subStatus?.key === lineItemDerivedState.READY.key ||
    subStatus?.key === lineItemDerivedState.LIVE.key;

  useEffect(() => {
    if (findValueInArray(ERROR_PATTERNS.STATUS_ACTIVATION_ERROR, errors)) {
      openToastAlert({
        alertType: 'error',
        message: STATUS_ERROR.lineItem.message,
        description: STATUS_ERROR.lineItem.description,
        duration: 4,
      });
    }
  }, [errors]);

  if (lineItemStaticState && !subStatusesWithEdit) {
    return (
      <div className={element('wrapper')}>
        {lineItemStaticState.pillColor ? (
          <Pill text={lineItemStaticState.displayName} color={lineItemStaticState.pillColor} />
        ) : (
          <span data-testid="not-editable-text">{value.displayName}</span>
        )}
      </div>
    );
  }

  return (
    <EditableDropdown<LineItemStatus>
      classNameModifier="status-cell"
      selectedValue={subStatus ? subStatusSelectedValue : convertConfigToDropdownOption(value)}
      onSubmit={updateLineItemField}
      options={convertConfigToDropDownOptions(dropdownLineItemStatuses)}
      variant={cellVariant}
      highlightSelectedValue={highlightSelectedDropdownValue}
      isDisabled={!permissions?.updateLineItem}
      isShowSelectedOption={false}
    />
  );
}

export const comparison = (prev: Props, next: Props): boolean => {
  const prevId = getIdFromTableCell(prev);
  const nextId = getIdFromTableCell(next);

  return (
    prevId === nextId &&
    prev.row.original.subStatus === next.row.original.subStatus &&
    prev.value.key === next.value.key
  );
};

export default memo(LineItemStatusCell, comparison);
