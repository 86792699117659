import './DuplicateAdForm.scss';

import type { ApolloError } from '@apollo/client';
import React, { memo, useCallback, useMemo } from 'react';

import type { CreateBatchAdsMutation } from '../../../../apis/graphql';
import FormikStepper from '../../../../common/FormikStepper';
import { openToastAlert } from '../../../../common/ToastAlert/toastAlert';
import type { AdModel } from '../../TraffickingPage/modelConverters';
import type { LineItemModel } from '../../TraffickingPage/modelConverters';
import { DEFAULT_SELECTED_SECTIONS, DUPLICATE_LINE_ITEM_HEADER } from '../constants';
import DuplicateAdFormFirstStep from './DuplicateAdFormFirstStep';
import DuplicateLineItemsPicker from './DuplicateAdFormFirstStep/DuplicateLineItemsPicker';
import { validationSchema as firstStepValidationSchema } from './DuplicateAdFormFirstStep/validation';
import DuplicateAdFormSecondStep from './DuplicateAdFormSecondStep';
import { DUPLICATED_AD_FORM_SECOND_STEP_FORM_VALIDATION } from './DuplicateAdFormSecondStep/validation';
import DuplicateAdFormThirdStep from './DuplicateAdFormThirdStep';
import DuplicatedAdDetails from './DuplicatedAdDetails/DuplicatedAdDetails';
import { FieldName } from './enums';
import type { DuplicateLineItem } from './hooks';
import { useBatchCreateAds, useDuplicateLineItemsState } from './hooks';
import type { DuplicateAdFormValues } from './types';
import { getDuplicateAdInitialValues, parseCreateBatchAdsError } from './utils';

export type DuplicateAdFormProps = {
  ad: AdModel;
  selectedLineItems: LineItemModel[];
  onClose: () => void;
};

const onComplete = (onClose: () => void) => (data: CreateBatchAdsMutation): void => {
  const adsCreated = data.createAdsBatchV5.length;

  openToastAlert({
    alertType: 'success',
    message: `Ads (${adsCreated}) successfully created. Please refresh page to see ads`,
  });
  onClose();
};

const onError = (savedItems: DuplicateLineItem[], e: ApolloError): void => {
  const errors = parseCreateBatchAdsError(savedItems, e);

  errors.forEach((error) => {
    openToastAlert(error);
  });
};

const DuplicateAdForm = ({ ad, selectedLineItems, onClose }: DuplicateAdFormProps): JSX.Element => {
  const { savedItems, handleAddSavedItem, handleRemoveSavedItem } = useDuplicateLineItemsState(selectedLineItems);
  const { submitDuplicateAdForm } = useBatchCreateAds(onComplete(onClose), onError);

  const initialValues: DuplicateAdFormValues = useMemo(
    () => ({
      ...getDuplicateAdInitialValues(ad),
      [FieldName.lineItems]: undefined,
      [FieldName.selectedSections]: DEFAULT_SELECTED_SECTIONS,
    }),
    [ad]
  );

  const handleSubmit = useCallback(
    async (newValues: DuplicateAdFormValues): Promise<void> => {
      await submitDuplicateAdForm(newValues);
    },
    [submitDuplicateAdForm]
  );

  return (
    <FormikStepper<DuplicateAdFormValues> initialValues={initialValues} onSubmit={handleSubmit} onCancel={onClose}>
      <DuplicateAdFormFirstStep
        validationSchema={firstStepValidationSchema}
        leftSide={
          <DuplicatedAdDetails
            campaignName={ad.lineItem?.campaign.name || ''}
            lineItemName={ad.lineItem?.name || ''}
            adType={ad.type.displayName}
            adId={ad.id}
            adName={ad.name}
          />
        }
        rightSide={
          <>
            <h3>{DUPLICATE_LINE_ITEM_HEADER}</h3>
            <DuplicateLineItemsPicker
              adLineItemId={ad.lineItem?.id || ''}
              adName={ad.name}
              adType={ad.type}
              savedItems={savedItems}
              handleAddSavedItem={handleAddSavedItem}
              handleRemoveSavedItem={handleRemoveSavedItem}
            />
          </>
        }
      />
      <DuplicateAdFormSecondStep ad={ad} validationSchema={DUPLICATED_AD_FORM_SECOND_STEP_FORM_VALIDATION} />
      <DuplicateAdFormThirdStep adId={ad.id} lineItem={ad.lineItem} />
    </FormikStepper>
  );
};

export default memo(DuplicateAdForm);
