import { gql } from '@apollo/client';

export const GET_TRANSCODING_FAILURE = gql`
  query getTranscodingFailure($assetId: String!) {
    getTranscodingFailure(assetId: $assetId) {
      message
      id
      name
      status
    }
  }
`;
