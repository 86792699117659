import type { DisplayEnum } from './types';
import { convertToDisplayEnum, makeConfig } from './utils';

export type EventType = keyof typeof adTagEventDisplayNames;

const adTagEventDisplayNames = {
  START: 'Start',
  FIRSTQUARTILE: 'First Quartile',
  MIDPOINT: 'Mid Point',
  THIRDQUARTILE: 'Third Quartile',
  COMPLETE: 'Complete',
  CLICK: '-',
};

export const adTagEvents = makeConfig<EventType>(adTagEventDisplayNames);

export function convertAdTagEvent(apiValue: string): DisplayEnum<EventType> {
  return convertToDisplayEnum(apiValue, adTagEvents);
}
