import './ColorPicker.scss';

import { TextField as TrekTextField } from '@hulu/react-style-components';
import { Label } from '@hulu/react-style-components';
import { useFormikContext } from 'formik';
import type { InputHTMLAttributes } from 'react';
import React, { useState } from 'react';

import bem, { withOptionalClassName } from '../../../utils/bem';
import { useFieldFast } from '../hooks';
import PreviewWrapper from '../PreviewWrapper';
import { makeLabelChild } from '../utils';

export interface ColorPickerProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  formFieldName: string;
  label?: string;
  secondaryLabel?: string;
  isPreview?: boolean;
}

interface RgbObject {
  r?: number;
  g?: number;
  b?: number;
}

const [block, element] = bem('color-picker');

const isValidRGB = (value: string): boolean => {
  if (typeof value !== 'string') return false;

  // rgbRegex matches comma-separated rgb values (eg "123,45,255", "123, 45, 0")
  // ([0-1]?\d?\d|2[0-4]\d|25[0-5]) matches values 0-255
  const rgbRegex = /^([0-1]?\d?\d|2[0-4]\d|25[0-5]),\s*([0-1]?\d?\d|2[0-4]\d|25[0-5]),\s*([0-1]?\d?\d|2[0-4]\d|25[0-5])$/;
  const match = value.match(rgbRegex);
  return !!match && value.length === match[0].length;
};

const parseRgbStringToObject = (rgbString: string): RgbObject | undefined => {
  const rgb = rgbString.match(/\d{1,3}/g);
  if (!isValidRGB(rgbString) || !rgb) return undefined;
  const [r, g, b] = rgb.map((v) => parseInt(v));
  return { r, g, b };
};

const parseRgbObjectToString = (rgbObject?: RgbObject): string => {
  if (!rgbObject) return '';
  const { r, g, b } = rgbObject;
  return `${r},${g},${b}`;
};

export const ColorPicker = ({
  formFieldName,
  label,
  secondaryLabel,
  disabled,
  className,
  isPreview,
  ...props
}: ColorPickerProps): JSX.Element => {
  const [, { value }, { setValue }] = useFieldFast<RgbObject | undefined>(formFieldName);
  const [textFieldValue, setTextFieldValue] = useState(value ? parseRgbObjectToString(value) : '');
  const { isSubmitting, errors } = useFormikContext<Record<string, unknown>>();

  const onChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const targetValue = e.target.value || '';
    setTextFieldValue(targetValue);
    setValue(parseRgbStringToObject(targetValue));
  };
  const rgbStringValue = isValidRGB(textFieldValue) ? textFieldValue : '255,255,255';
  const error = errors[formFieldName];

  return (
    <div className={withOptionalClassName(block(), className)}>
      {(label || secondaryLabel) && (
        <Label htmlFor={props.id} isErr={!!error}>
          {makeLabelChild(label, secondaryLabel)}
        </Label>
      )}
      <div className={element('container')}>
        <div className={element('preview-container')}>
          <div
            role="img"
            className={element('preview')}
            style={{
              backgroundColor: `rgb(${rgbStringValue})`,
            }}
          />
        </div>
        <div className={element('color-format')}>RGB</div>
        <PreviewWrapper isPreview={isPreview} value={textFieldValue}>
          <TrekTextField
            value={textFieldValue}
            onChange={onChangeHandler}
            disabled={disabled || isSubmitting}
            errMsg={error}
            {...props}
            className={element('color-input')}
          />
        </PreviewWrapper>
      </div>
    </div>
  );
};

export default ColorPicker;
