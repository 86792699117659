import { gql } from '@apollo/client';

import { AD_TRAFFICKING_COLUMN } from '../fragments';

export const UPDATE_TRAFFICKING_PAGE_AD = gql`
  mutation UpdateAd($id: ID!, $updateAdInput: UpdateAdInputV5!) {
    updateAd: updateAdV5(id: $id, input: $updateAdInput) {
      ...adTraffickingColumn
    }
  }
  ${AD_TRAFFICKING_COLUMN}
`;

export const DELETE_TRAFFICKING_PAGE_ADS = gql`
  mutation DeleteAds($ids: [ID!]!) {
    deleteAds: deleteAdsV5(input: $ids)
  }
`;
