import 'antd/lib/notification/style/index.css';
import './ToastAlert.scss';

import {
  IconAlertErrorRed,
  IconAlertSuccessGreen,
  IconAlertWarningYellow,
  IconClose,
} from '@hulu-react-style-components/icons';
import {
  colorGreen6,
  colorGreenBase,
  colorRed6,
  colorRedBase,
  colorYellow6,
  colorYellowBase,
} from '@hulu/design-tokens/lib/colors.common';
import { Typography } from '@hulu/react-style-components';
import { notification } from 'antd';
import type { ArgsProps as AntNotificationArgsProps } from 'antd/lib/notification';
import React from 'react';

import { generalErrorDescription, generalErrorMessage } from '../../constants';

/**
 * A wrapper around Ant-design's Notification component that applies common Trek styles
 */

type CommonProps = 'placement' | 'duration' | 'closeIcon';
type VariantSpecificProps = Omit<AntNotificationArgsProps, CommonProps | 'message' | 'description'>;

const defaultNotificationProps: Pick<AntNotificationArgsProps, CommonProps> = {
  placement: 'bottomRight',
  duration: 10,
  closeIcon: <IconClose />,
};

const successNotificationProps: VariantSpecificProps = {
  icon: <IconAlertSuccessGreen />,
  style: {
    backgroundColor: colorGreen6,
    border: `1px solid ${colorGreenBase}`,
  },
};

const errorNotificationProps: VariantSpecificProps = {
  icon: <IconAlertErrorRed />,
  style: {
    backgroundColor: colorRed6,
    border: `1px solid ${colorRedBase}`,
  },
};

const warningNotificationProps: VariantSpecificProps = {
  icon: <IconAlertWarningYellow />,
  style: {
    backgroundColor: colorYellow6,
    border: `1px solid ${colorYellowBase}`,
  },
};

type AlertType = 'success' | 'error' | 'warning';

const variantMap: Record<AlertType, VariantSpecificProps> = {
  success: successNotificationProps,
  error: errorNotificationProps,
  warning: warningNotificationProps,
};

export interface ToastAlertProps extends AntNotificationArgsProps {
  alertType: AlertType;
  restrictToPage?: boolean; // Restrict the scope of the toast alert to the current MC 'page' (any component with the 'page' className)
}

export function openToastAlert({ alertType, description, restrictToPage = true, ...restProps }: ToastAlertProps): void {
  const notificationProps: AntNotificationArgsProps = {
    ...defaultNotificationProps,
    ...variantMap[alertType],
    description: <Typography variant="body1">{description}</Typography>,
    ...restProps,
  };

  // By default, scope toast alert to a particular page
  if (restrictToPage) {
    notificationProps.getContainer = (): HTMLElement => document.getElementsByClassName('page')[0] as HTMLElement;
  }

  notification.open(notificationProps);
}

export const openErrorToastAlert = (
  description: string | JSX.Element = generalErrorDescription,
  message: string = generalErrorMessage
): void => {
  openToastAlert({
    alertType: 'error',
    message,
    description,
  });
};
