import React from 'react';

import { StyledLabel } from './styled';

export type LabelTextProps = {
  /**Text for show */
  text?: string;
};

/**
 * This is a LabelText Component to show label for inputs
 */
export const LabelTextComponent = ({ text }: LabelTextProps): JSX.Element | null => {
  return text ? <StyledLabel>{text}</StyledLabel> : null;
};
