type ClassNameState = {
  isLoading?: boolean;
  isDisabled?: boolean;
  hasCustomInputComponent?: boolean;
  isInputSubmitted?: boolean;
};

export const getClassNameByState = ({
  isLoading,
  isDisabled,
  hasCustomInputComponent,
  isInputSubmitted,
}: ClassNameState): string => {
  if (isLoading) {
    return 'isLoading';
  }

  if (isDisabled) {
    return 'inputDisabled';
  }

  if (hasCustomInputComponent) {
    return 'customClearButtonPosition';
  }

  if (isInputSubmitted) {
    return 'inputSubmitted';
  }

  return '';
};
