import type React from 'react';
import { useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import useFocusTrap from '../../hooks/useFocusTrap';

interface UseDropdownParams {
  onClose?: () => void;
}

interface UseDropdownResult {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  focusRef: React.RefObject<HTMLDivElement>;
  dropdownRef: React.RefObject<HTMLDivElement>;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export default function useDropdown({ onClose }: UseDropdownParams = {}): UseDropdownResult {
  const [active, setActive] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const focusRef = useFocusTrap<HTMLDivElement>();

  useClickAway(dropdownRef, (e: MouseEvent) => {
    e.stopPropagation();
    if (onClose && active) onClose();
    setActive(false);
  });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Escape') {
      if (onClose) onClose();
      setActive(false);
    }
  };

  return {
    active,
    setActive,
    focusRef,
    dropdownRef,
    handleKeyDown,
  };
}
