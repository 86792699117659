import { Button, TextField, Typography } from '@hulu/react-style-components';
import styled, { css } from 'styled-components';

import { theme } from '../../../styles/styled';
import type { CalendarButtonVariant } from './DatePicker';

export const DatePickerContainer = styled.div`
  position: relative;
  width: 320px;
  margin: auto;
  margin-top: 10px;

  .react-datepicker-popper {
    z-index: 10;
    padding-top: 0;
  }

  .react-datepicker {
    background-color: ${theme.color.design.white};
    line-height: normal;
    padding: 12px;
    position: relative;
    box-shadow: 0px 1px 3px ${theme.color.design.gray3};

    &-popper {
      transform: translate3d(0px, 42px, 0px);
    }
  }
  .react-datepicker__header {
    background-color: ${theme.color.design.white};
    border: none;
  }
  .react-datepicker__month {
    margin: 4px;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    margin: 0.3rem;

    &--in-selecting-range {
      background-color: ${theme.color.design.gray3};
    }

    &--in-range {
      color: ${theme.color.design.white};
      background-color: ${theme.color.design.fauxBlack};
    }

    &--outside-month {
      visibility: hidden;
    }

    &--disabled {
      color: ${theme.color.design.gray3};
    }

    &--keyboard-selected {
      color: ${theme.color.design.white};
      background-color: ${theme.color.design.fauxBlack};
    }

    &--selected {
      background-color: ${theme.color.design.fauxBlack};

      &:hover {
        background-color: ${theme.color.design.gray3} !important;
        color: ${theme.color.design.white};
      }
    }
  }
  .react-datepicker__day-name {
    &:hover {
      background-color: ${theme.color.design.white};
      color: ${theme.color.design.fauxBlack};
    }
  }
  .react-datepicker__current-month {
    background-color: ${theme.color.design.white};
  }
  .react-datepicker__navigation {
    border: 1px solid ${theme.color.design.gray3};
    border-radius: 50%;
    position: absolute;
    top: 40%;
    width: 20px;
    height: 20px;
    background-color: ${theme.color.design.white};

    &--previous {
      left: -12px;
    }
    &--next {
      right: -12px;
    }
    &-icon {
      &:before {
        border-color: ${theme.color.design.fauxBlack};
        border-style: solid;
        border-width: 1px 1px 0 0;
        width: 6px;
        height: 6px;
        top: 9px;
      }
      &--next {
        &:before {
          left: -2px;
        }
      }
      &--previous {
        &:before {
          right: -2px;
        }
      }
    }
  }
  .react-datepicker__close-icon::after {
    display: none;
  }
`;

export const ButtonContainerStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const StyledMCButton = styled(Button).attrs({ size: 'small' })<{ type: CalendarButtonVariant }>(({ type }) => {
  return css`
    color: ${type === 'submit' ? theme.color.design.white : theme.color.design.gray3};
    background-color: ${type === 'submit' ? theme.color.design.fauxBlack : 'transparent'};
    &:hover {
      color: ${type === 'submit' ? theme.color.design.white : theme.color.design.gray3};
    }
  `;
});

export const StyledDateInput = styled(TextField)<{ errMsg?: string }>(({ errMsg = undefined }) => {
  return css(() => {
    return `
    cursor: pointer;
    width: 200px;
    padding: 9.5px 12px 10.5px 12px;
    border-radius: 4px;
    font-size: 12px;
    height: 32px;
    border: 1px solid ${errMsg ? theme.color.design.redBase : theme.color.design.gray3};
    background-color: ${theme.color.design.white};
    &:hover {
      border: 2px solid ${theme.color.design.gray2};
    }
    `;
  });
});

export const StyledLabel = styled(Typography).attrs({ variant: 'eyebrow' })`
  letter-spacing: 1px;
  font-size: 12px;
  padding-bottom: 6px;
  display: inline-block;
  color: ${theme.color.design.fauxBlack};
`;
