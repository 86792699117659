import { format, isValid, parseISO } from 'date-fns';

import type { DefaultRangeType } from './DateRange';

const DATETIME_WITHOUT_SECONDS_FORMAT = "yyyy-MM-dd'T'HH:mm";

export const getDateRangeModifier = (dateRange: DefaultRangeType, editMode: boolean): string => {
  if (!dateRange.end && !editMode) return 'invisible';
  if (!editMode) return 'readonly';

  return '';
};

export const formatDateTimeWithoutSecond = (dateString: string): string => {
  const parsedDate = parseISO(dateString);

  return isValid(parsedDate) ? format(parsedDate, DATETIME_WITHOUT_SECONDS_FORMAT) : '';
};
