import './OverrideReviewButton.scss';

import React, { useCallback } from 'react';

import Button from '../../../common/Button';
import Tooltip from '../../../common/Tooltip';
import { TraffickingTableName } from '../../../constants';
import bem from '../../../utils/bem';
import { singularTitleCase } from '../../../utils/formatting';
import type { FlatRowType } from '../CreateReviewButton/types';
import type { EntityModel } from '../TraffickingPage/modelConverters';
import useOverrideReviewButton from './hooks';
import { ReactComponent as IconOverride } from './IconOverride.svg';
import OverrideReviewModal from './OverrideReviewModal/OverrideReviewModal';

export interface OverrideReviewButtonProps {
  selectedFlatRows: FlatRowType<EntityModel>;
  tableName: TraffickingTableName;
}

const [block, element] = bem('override-review-button');

function OverrideReviewButton({ tableName, selectedFlatRows }: OverrideReviewButtonProps): JSX.Element {
  const { isButtonDisabled, showModal, setShowModal, hasOverrideReviewPermission } = useOverrideReviewButton({
    selectedFlatRows,
    tableName,
  });

  const openModal = useCallback((): void => {
    setShowModal(true);
  }, [setShowModal]);

  const closeModal = useCallback((): void => {
    setShowModal(false);
  }, [setShowModal]);

  const isVisible =
    (tableName === TraffickingTableName.lineItems || tableName === TraffickingTableName.ads) &&
    hasOverrideReviewPermission;

  return (
    <>
      {isVisible && (
        <>
          <Tooltip
            id={`override-${tableName}-review`}
            className={element('tooltip')}
            message={`Select ${singularTitleCase(tableName).toLowerCase()}s to override campaign review requirement.`}
            disable={!isButtonDisabled}
          >
            <Button
              size="medium"
              variant="outlined"
              className={`${block()} table-header-link`}
              onClick={openModal}
              disabled={isButtonDisabled}
              aria-label="Override Campaign Review"
              data-testid="override-review-button"
              icon={<IconOverride />}
            >
              Override Campaign Review
            </Button>
          </Tooltip>

          <OverrideReviewModal
            visible={showModal}
            closeModal={closeModal}
            selectedFlatRows={selectedFlatRows}
            tableName={tableName}
          />
        </>
      )}
    </>
  );
}

export default OverrideReviewButton;
