import './EditableTextField.scss';

import { TextField as TrekTextField } from '@hulu/react-style-components';
import { bem } from 'mission-control-common-components';
import React, { memo, useEffect, useState } from 'react';

import type { EditableInputVariant } from '../EditableCell/EditableCell';
import EditableCell from '../EditableCell/EditableCell';
import EditableCellModal from '../EditableCellModal';

const [block, element] = bem('editable-rotation-name');

type Props = {
  updateField: (name: string) => void;
  variant: EditableInputVariant;
  initialValue?: string;
  isDisabled?: boolean;
};

const EditableTextField = ({ updateField, initialValue = '', variant, isDisabled, ...props }: Props): JSX.Element => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string>('');

  useEffect(() => {
    setTextValue(initialValue);
  }, [initialValue]);

  const handleActivate = (): void => {
    if (isDisabled) {
      return;
    }
    if (variant !== 'saving') {
      setIsActive(true);
    }
  };

  const handleEscape = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Escape') {
      setIsActive(false);
    }
  };

  const handleCancel = (): void => {
    setIsActive(false);
    setTextValue(initialValue);
  };

  const handleClickAway = (): void => handleCancel();

  const handleSubmit = (): void => {
    if (textValue) {
      setIsActive(false);
      updateField(textValue);
    }
  };

  return (
    <EditableCell
      usePencil={true}
      onClick={handleActivate}
      onKeyDown={handleEscape}
      variant={isActive ? 'active' : variant}
      tabIndex={-1}
      allowSpaceTyping={true}
      {...props}
    >
      <div className={block()}>
        {isActive ? (
          <EditableCellModal
            onCancel={handleCancel}
            onClickAway={handleClickAway}
            onSubmit={handleSubmit}
            hasValue={!!textValue}
          >
            <TrekTextField
              value={textValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setTextValue(e.currentTarget.value)}
            />
          </EditableCellModal>
        ) : (
          <div className={element('display')} data-testid="render-value">
            <span className={element('rendered-value')}>{textValue}</span>
          </div>
        )}
      </div>
    </EditableCell>
  );
};

export default memo(EditableTextField);
