import { useFormikContext } from 'formik';
import type { Dispatch, SetStateAction } from 'react';
import React from 'react';

import ModeControlButton from '../ModeControlButton';
import type { AdScheduleItem } from '../types';

interface Props {
  onSubmit: () => void;
  onCancel: () => void;
  disableSubmitting?: boolean;
  loading?: boolean;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  canEdit?: boolean;
}

function ModeControlBlock({ onCancel, canEdit, ...rest }: Props): JSX.Element {
  const { resetForm } = useFormikContext<AdScheduleItem>();

  const handleCancel = (): void => {
    resetForm();
    onCancel();
  };

  return <ModeControlButton onCancel={handleCancel} {...rest} canEdit={canEdit} />;
}

export default ModeControlBlock;
