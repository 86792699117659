import { useQuery } from '@apollo/client';

import type {
  BaseAssetV5,
  GetCreativeDataQuery,
  IngestionJobStatus,
  IngestionStatus,
  IngestionTrial,
  Query,
} from '../../../../apis/graphql';
import type { AssetExtended } from '../../../../common/_ForCommonLibrary/types/extended';
import { getAssetIds } from '../../utils';
import type { CreativesBuilderValues } from '../types';
import { GET_CREATIVE_DATA } from './queries';
import { getTrialsWithoutAssets } from './util/getTrialsWithoutAssets';

/** Finds any trials for the given asset and merges the values if available */
function mapDataToAsset(
  asset: BaseAssetV5,
  trials?: IngestionTrial[],
  reviewTasks?: GetCreativeDataQuery['getReviewTasksV5']
): AssetExtended {
  const matchingTrial = trials?.length ? trials.find((trial) => trial.assetId === asset.id) : null;
  const reviewTasksNodes = reviewTasks?.edges.map((edge) => edge.node);

  const matchingReviewTask = reviewTasksNodes?.length
    ? reviewTasksNodes.find((task) => task.assetId === asset.id)
    : null;

  return {
    ...asset,
    ingestionTrial: matchingTrial || null,
    reviewTask: matchingReviewTask || null,
  };
}

/** Checks whether the given status is a valid success value. If no status is present, we'll assume success. */
function hasSuccessfulIngest(status?: IngestionStatus | null): boolean {
  return !status || status === 'INGESTING' || status === 'INGESTION_COMPLETE';
}

/** Returns the percentage (0 through 1) of successful ingestions based on the total available */
function getSuccessPercent(assetsCount: number = 0, successCount: number = 0, withoutAssetsCount: number = 0): number {
  // Prevent a divide by 0 error; if there are no assets or trials without assets, assume 100% success rate
  if (!assetsCount && !withoutAssetsCount) return 1;

  return successCount / (assetsCount + withoutAssetsCount);
}

type CreativeData = {
  assets: AssetExtended[];
  successPercent: number;
  trialsWithoutAssets: IngestionTrial[];
  ingestionJobStatus: IngestionJobStatus;
};

export function useCreativeData(creative?: CreativesBuilderValues | null): { data: CreativeData; loading: boolean } {
  const assetIdList = creative ? getAssetIds(creative) : [];

  const { data, loading } = useQuery<Query>(GET_CREATIVE_DATA, {
    variables: {
      assetIdList,
      creativeId: creative?.id,
      filterOptions: { creativeIdList: [creative?.id] },
    },
    skip: !creative?.id || !assetIdList?.length,
  });

  const baseAssets = data?.getAssetsByIDsV5;
  const ingestionTrials = data?.getAssetLatestIngestionStatus.assetStatus;
  const ingestionJobStatus = data?.getAssetLatestIngestionStatus.jobStatus || 'NO_JOBS';
  const reviewTasks = data?.getReviewTasksV5;

  // Merge any ingestion trial data into the assets
  const assets = baseAssets?.map((asset) => mapDataToAsset(asset, ingestionTrials, reviewTasks)) || [];

  // Find any remaining trials that could not be mapped to an asset
  const trialsWithoutAssets = getTrialsWithoutAssets(baseAssets, ingestionTrials);

  // Determine how many successful ingestions are present
  const successfulAssets = assets?.filter((asset) => hasSuccessfulIngest(asset?.ingestionTrial?.trialStatus)) || [];
  const successPercent = getSuccessPercent(assets.length, successfulAssets.length, trialsWithoutAssets.length);

  return { data: { assets, ingestionJobStatus, successPercent, trialsWithoutAssets }, loading };
}
