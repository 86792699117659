import './DuplicatedAdStepLayout.scss';

import React from 'react';

import bem from '../../../../../utils/bem';

type Props = {
  leftSide?: JSX.Element;
  rightSide?: JSX.Element;
  isWrapped?: boolean;
};

const [block, element] = bem('layout');

const DuplicatedAdStepLayout = ({ leftSide, rightSide, isWrapped }: Props): JSX.Element => {
  return (
    <section className={block(isWrapped ? 'wrapped' : '')}>
      <div className={element('left-side')}>{leftSide}</div>
      <div className={element('right-side')}>{rightSide}</div>
    </section>
  );
};

export default DuplicatedAdStepLayout;
