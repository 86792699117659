export const LIVING_ROOM_CHARACTERS_RESTRICTION = 65;
export const PUSH_TITLE_CHARACTERS_RESTRICTION = 115;
export const PUSH_BODY_CHARACTERS_RESTRICTION = 145;
export const EMAIL_SUBJECT_CHARACTERS_RESTRICTION = 120;
export const EMAIL_BODY_CHARACTERS_RESTRICTION = 250;

export const IMAGE_ASPECT_RATIO = 16 / 9;

export const LIVING_ROOM_COPY_LABEL = 'Living room copy';
export const LIVING_ROOM_COPY_PLACEHOLDER = `${LIVING_ROOM_CHARACTERS_RESTRICTION} characters max.`;

export const DESTINATION_URL_LABEL = 'Destination URL';
export const DESTINATION_URL_PLACEHOLDER = 'https://';

export const PUSH_NOTIFICATION_LABEL = 'Push Notification';

export const PUSH_TITLE_LABEL = 'Push Title';
export const PUSH_TITLE_HELP_TEXT = 'Do not edit the push title without product approval.';
export const PUSH_TITLE_DEFAULT_VALUE = 'Here’s the info you requested while watching.';

export const PUSH_BODY_LABEL = 'Push Body';
export const PUSH_BODY_PLACEHOLDER = `${PUSH_BODY_CHARACTERS_RESTRICTION} characters max.`;

export const EMAIL_LABEL = 'Email';

export const EMAIL_SUBJECT_LABEL = 'Email Subject';
export const EMAIL_SUBJECT_PLACEHOLDER = `${EMAIL_SUBJECT_CHARACTERS_RESTRICTION} characters max.`;

export const EMAIL_BODY_LABEL = 'Email Body';
export const EMAIL_BODY_PLACEHOLDER = `${EMAIL_BODY_CHARACTERS_RESTRICTION} characters max.`;

export const IMAGE_TITLE = 'Image';
export const VIDEO_TITLE = 'Video';

export const IMAGE_ASSET_LABEL = 'Image Asset';

export const VIDEO_ASSET_LABEL = 'Video Asset';
