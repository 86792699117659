import { ChangeAction } from '../../../../../apis/graphql';
import type { NullableDayPart } from '../../../types';

export const getChangeActionType = (
  dayPartListBefore: NullableDayPart[],
  dayPartListAfter: NullableDayPart[]
): ChangeAction => {
  const emptyBefore = !dayPartListBefore.length;
  const emptyAfter = !dayPartListAfter.length;

  if (emptyBefore && !emptyAfter) return ChangeAction.Addition;
  if (!emptyBefore && emptyAfter) return ChangeAction.Removal;

  return ChangeAction.Update;
};
