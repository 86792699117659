import { useRef } from 'react';

type ValueContainer<T> = { value: T };

// useConstant sets a value `once` and always returns that value whenever it is called again
// source: https://github.com/Andarist/use-constant/blob/master/src/index.ts
export function useConstant<T>(value: T): T {
  const ref = useRef<ValueContainer<T> | null>(null);

  if (!ref.current) {
    ref.current = { value };
  }

  return ref.current.value;
}
