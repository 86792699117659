import { enumToTitleCase, titleCase } from '../utils/formatting';
import { makeConfig } from './utils';

const assetReviewStatusDisplayNames = {
  PENDING: enumToTitleCase,
  PREVIEW: enumToTitleCase,
  REVIEW: enumToTitleCase,
  APPROVED: enumToTitleCase,
  PUBLISHED: enumToTitleCase,
  REJECTED: enumToTitleCase,
};

export const assetReviewStatuses = makeConfig<string>(assetReviewStatusDisplayNames);

export function convertAssetReviewStatus(apiValue: string): string {
  return titleCase(apiValue);
}
