import { IconCalendar } from '@hulu-react-style-components/icons';
import React from 'react';

import type { Maybe, ScheduleV5 } from '../../../../apis/graphql';
import IconWithTitle from '../../../../common/IconWithTitle';
import ScheduleOptions from '../../../../common/ScheduleOptions';
import TimezoneWithGMT from '../../../../common/TimezoneWithGMT';
import DatePacing from './DatePacing';

interface SecondaryPacingProps {
  schedule?: Maybe<Pick<ScheduleV5, 'dateRangeList' | 'timezone'>>;
}

function SecondaryPacing({ schedule }: SecondaryPacingProps): JSX.Element {
  return (
    <div className="scheduling-section" data-testid="secondary-pacing">
      <IconWithTitle icon={<IconCalendar />} title="Secondary Pacing" />
      <ScheduleOptions firstText="Even Pacing" secondText="Scheduled Pacing" isFirstActive={!schedule} />
      {schedule && (
        <div>
          <TimezoneWithGMT timezone={schedule.timezone} />
          <DatePacing dateRangeList={schedule.dateRangeList} />
        </div>
      )}
    </div>
  );
}

export default SecondaryPacing;
