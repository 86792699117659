import gql from 'graphql-tag';

export const APPROVE_LINE_ITEMS_AND_CORRELATED_ADS = gql`
  mutation ApproveLineItemsAndCorrelatedAds($ids: [ID!]!) {
    approveLineItemsAndCorrelatedAdsV5(input: $ids) {
      ads {
        id
        review
        status
      }
      lineItems {
        id
        review
        status
      }
      errors {
        id
        message
      }
    }
  }
`;
