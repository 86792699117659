import type { MutationFunction } from '@apollo/client';

import type {
  AssetInputV5,
  BatchReviewAssetInputV5,
  CountryCode,
  TargetingRuleInput,
  UpdateAdsPageAdMutation,
  UpdateAdsPageAdMutationVariables,
  UpdateAdsPageWithAssetMutation,
  UpdateAdsPageWithAssetMutationVariables,
  UpdateAdWithBatchAssetsMutation,
  UpdateAdWithBatchAssetsMutationVariables,
  UpdateAdWithGatewayAssetsMutation,
  UpdateAdWithGatewayAssetsMutationVariables,
} from '../../../../../apis/graphql';
import type { AdFormValues } from '../../../../../common/AdForm/adFormik';
import type { GatewayGoAssetInput } from '../../util/buildGatewayAssetInput';
import { constructUpdateAdInput } from './constructUpdateAdInput';
import { getAssetId } from './getAssetId';

type Mutations = {
  updateAd: MutationFunction<UpdateAdsPageAdMutation>;
  updateAdWithAsset: MutationFunction<UpdateAdsPageWithAssetMutation>;
  updateAdWithBatchAssets: MutationFunction<UpdateAdWithBatchAssetsMutation>;
  updateAdWithGatewayAssets: MutationFunction<UpdateAdWithGatewayAssetsMutation>;
};

type WithoutAsset = { mutation: Mutations['updateAd']; variables: UpdateAdsPageAdMutationVariables };
type WithAsset = { mutation: Mutations['updateAdWithAsset']; variables: UpdateAdsPageWithAssetMutationVariables };
type WithBatchAssets = {
  mutation: Mutations['updateAdWithBatchAssets'];
  variables: UpdateAdWithBatchAssetsMutationVariables;
};
type WithGatewayAssets = {
  mutation: Mutations['updateAdWithGatewayAssets'];
  variables: UpdateAdWithGatewayAssetsMutationVariables;
};

export function getUpdateMutation(
  mutations: Mutations,
  adFormValues: AdFormValues,
  assetInput?: AssetInputV5 | GatewayGoAssetInput,
  targetingRule?: TargetingRuleInput | null
): WithoutAsset | WithAsset | WithBatchAssets | WithGatewayAssets {
  const assetId = getAssetId(adFormValues.creative.type, adFormValues);

  // The Ad has a GatewayGo Asset
  if ((assetInput as GatewayGoAssetInput)?.display && (assetInput as GatewayGoAssetInput)?.video) {
    const withGatewayAssets: WithGatewayAssets = {
      mutation: mutations.updateAdWithGatewayAssets,
      variables: {
        adId: adFormValues.id || '',
        updateAdInput: constructUpdateAdInput(adFormValues),
        targetingRule: targetingRule,
        displayAssetId: adFormValues.creative.image?.id || '',
        displayAssetInput: (assetInput as GatewayGoAssetInput).display as BatchReviewAssetInputV5,
        videoAssetId: adFormValues.creative.video?.id || '',
        videoAssetInput: (assetInput as GatewayGoAssetInput).video as BatchReviewAssetInputV5,
      },
    };
    return withGatewayAssets;
  }

  // The Ad has one or more standard Assets for mutation
  if (assetId && assetInput) {
    // The Ad has a single Asset
    if (typeof assetId === 'string') {
      const withAsset: WithAsset = {
        mutation: mutations.updateAdWithAsset,
        variables: {
          adId: adFormValues.id || '',
          updateAdInput: constructUpdateAdInput(adFormValues),
          assetId: assetId || '',
          assetInput: assetInput as AssetInputV5,
          targetingRule: targetingRule,
        },
      };
      return withAsset;
    }

    // The Ad has a batch list of Assets
    if (Array.isArray(assetId)) {
      // Destructure to remove 'name' and keep the rest of the object to match the typing of batch mutation
      const { name, ...rest } = assetInput as AssetInputV5;

      const withBatchAssets: WithBatchAssets = {
        mutation: mutations.updateAdWithBatchAssets,
        variables: {
          adId: adFormValues.id || '',
          updateAdInput: constructUpdateAdInput(adFormValues),
          assetIdList: assetId || '',
          assetInput: { ...rest, countryCodes: (assetInput as AssetInputV5).countryCodes as CountryCode[] },
          targetingRule: targetingRule,
        },
      };
      return withBatchAssets;
    }
  }

  // No Asset condition has been met; we're updating the Ad only
  const withoutAsset: WithoutAsset = {
    mutation: mutations.updateAd,
    variables: {
      adId: adFormValues.id || '',
      updateAdInput: constructUpdateAdInput(adFormValues),
      targetingRule: targetingRule,
    },
  };
  return withoutAsset;
}
