import { useFormikContext } from 'formik';
import type { SetStateAction } from 'react';
import { useEffect } from 'react';

import type { SequenceAdV5, SequenceFormValues } from '../types';
import { getInitialSequence } from '../utils/initialValues';
import { getRotatedSequences, getRotatedUnsequencedAds, getSortedUnsequencedAds } from './utils';

export const MIN_NAME_LENGTH_ERROR = "Sequence's name should be at least 1 character long!";

export type UseSequenceForm = {
  isControlButtonsDisabled: boolean;
  handleReset: () => void;
  values: SequenceFormValues;
  deleteSequence: (sequenceIndex: number) => void;
  updateSequenceName: (newValue: string, sequenceIndex: number) => string | null;
  changeAdSequenceOrder: (props: ChangeOrderProps) => void;
  addSequence: () => void;
  setValues: (values: SetStateAction<SequenceFormValues>, shouldValidate?: boolean | undefined) => void;
  status?: Record<string, Set<string>> | null;
};

export type ChangeOrderProps = {
  destination: {
    droppableId: string;
    index: number;
  };
  source: {
    droppableId: string;
    index: number;
  };
  draggedAd: SequenceAdV5;
};

const useSequenceForm = (): UseSequenceForm => {
  const { values, dirty, setValues, handleReset, status, setStatus } = useFormikContext<SequenceFormValues>();

  const deleteSequence = (sequenceIndex: number): void => {
    setValues((prev) => {
      // get ads from sequence that will be added to unsequenced table
      const deletedSequencedAds = prev.sequences[sequenceIndex]?.ads || [];
      const updatedUnsequencedAds = [...prev.unsequencedAds, ...deletedSequencedAds];

      const sequences = [...prev.sequences];

      sequences.splice(sequenceIndex, 1);

      return {
        ...prev,
        sequences,
        unsequencedAds: getSortedUnsequencedAds(updatedUnsequencedAds),
      };
    });
  };

  const updateSequenceName = (newValue: string, sequenceIndex: number): string | null => {
    // validation on name length
    const error = newValue.length ? null : MIN_NAME_LENGTH_ERROR;

    if (!error) {
      setValues((prev) => {
        const sequences = [...prev.sequences];

        sequences.splice(sequenceIndex, 1, { ...sequences[sequenceIndex], name: newValue });

        return {
          ...prev,
          sequences,
        };
      });
    }

    return error;
  };

  const changeAdSequenceOrder = (props: ChangeOrderProps): void => {
    const { unsequencedAds, sequences } = values;

    // rotate ad sequences in tables and save
    setValues((prev) => {
      const updatedUnsequencedAds = getRotatedUnsequencedAds({
        unsequencedAds,
        ...props,
      });

      const updatedSequences = getRotatedSequences({
        sequences,
        ...props,
      });

      return {
        ...prev,
        sequences: [...updatedSequences],
        unsequencedAds: [...updatedUnsequencedAds],
      };
    });
  };

  const addSequence = (): void => {
    setValues((prev) => {
      const sequences = [...prev.sequences, getInitialSequence(prev.sequences.length + 1)];

      return {
        ...prev,
        sequences,
      };
    });
  };

  // reset status errors, when form is not dirty
  useEffect(() => {
    if (!dirty) {
      setStatus(null);
    }
  }, [dirty, setStatus]);

  return {
    isControlButtonsDisabled: !dirty,
    handleReset,
    values,
    status,
    setValues,
    deleteSequence,
    updateSequenceName,
    changeAdSequenceOrder,
    addSequence,
  };
};

export default useSequenceForm;
