import type { AssetInputV5 } from '../../../../apis/graphql';
import type { AssetExtended } from '../../../../common/_ForCommonLibrary/types/extended';
import type { AdsPageLineItem } from '../../../../common/AdForm/types';
import { getCountryCodesFromAsset } from './getCountryCodesFromAsset';
import { getCountryCodesFromLineItem } from './getCountryCodesFromLineItem';

export type GatewayGoAssetInput = {
  display: Partial<AssetInputV5> | null;
  video: Partial<AssetInputV5> | null;
};

/**
 * Generates Display and Video input objects for GatewayGo creatives
 * @param lineItem Line Item for the Ad
 * @param assets List of Assets attached to the Ad's Creative
 * @returns Display and Video asset input objects, or null if no assets are provided
 */
export function buildGatewayAssetInput(lineItem?: AdsPageLineItem, assets?: AssetExtended[]): GatewayGoAssetInput {
  if (!assets?.length) return { display: null, video: null };

  const displayAsset = assets?.find((asset) => asset.type === 'DISPLAY');
  const videoAsset = assets?.find((asset) => asset.type === 'VIDEO');

  if (!displayAsset && !videoAsset) return { display: null, video: null };

  const displayCountryCodes = getCountryCodesFromAsset(displayAsset);
  const videoCountryCodes = getCountryCodesFromAsset(videoAsset);
  const lineItemCountryCodes = getCountryCodesFromLineItem(lineItem);

  return {
    display: {
      comments: displayAsset?.comments,
      countryCodes: [...new Set([...displayCountryCodes, ...lineItemCountryCodes])],
      rating: displayAsset?.rating,
      rejectionReasons: displayAsset?.rejectionReasons,
      type: 'DISPLAY',
    },
    video: {
      comments: videoAsset?.comments,
      countryCodes: [...new Set([...videoCountryCodes, ...lineItemCountryCodes])],
      rating: videoAsset?.rating,
      rejectionReasons: videoAsset?.rejectionReasons,
      type: 'VIDEO',
    },
  };
}
