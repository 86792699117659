import { useApolloClient, useMutation } from '@apollo/client';
import type { FormikErrors, FormikHelpers } from 'formik';
import { useCallback } from 'react';
import type { Cell } from 'react-table';

import type { AdTagInput, UpdateAdMutation, UpdateAdMutationVariables } from '../../../../../../apis/graphql';
import type { FormFormikErrors } from '../../../../../../common/AdForm/adFormik';
import { convertAdTagToAdTagInput } from '../../../../../../common/AdForm/hooks/modelConverters/adsPageAdModelToNode';
import type { ValidationError } from '../../../../../../common/AdForm/types';
import { validateMetadata } from '../../../../../../common/AdForm/validation/metadata';
import { showFailure, updateTraffickingColumnCache } from '../../../../../../common/utils';
import type { DisplayEnum, ViewabilityVendor } from '../../../../../../configs';
import type { AdTag } from '../../../../../../models';
import { getIdFromTableCell } from '../../../columns';
import { AD_TRAFFICKING_COLUMN } from '../../../hooks/fragments';
import { UPDATE_TRAFFICKING_PAGE_AD } from '../../../hooks/mutations';
import type { AdModel } from '../../../modelConverters';
import { getViewabilityVendorInput } from './utils';

export type ViewabilityVendorCellFormValues = {
  viewabilityVendorList: DisplayEnum<ViewabilityVendor>[];
  adTagList: AdTag[];
  newAdTag: string;
  iasCampaignLineItemId: string;
};

type UseViewabilityVendorUpdateProps = {
  onSuccess: () => void;
  cell: Cell<AdModel, DisplayEnum<'IAS' | 'IAB' | 'MOAT'>[]>;
};

type UseViewabilityVendorUpdate = {
  loading: boolean;
  handleSubmit: (
    values: ViewabilityVendorCellFormValues,
    helpers: FormikHelpers<ViewabilityVendorCellFormValues>
  ) => Promise<void>;
};

export const useViewabilityVendorUpdate = ({
  cell,
  onSuccess,
}: UseViewabilityVendorUpdateProps): UseViewabilityVendorUpdate => {
  const id = getIdFromTableCell(cell);
  const [updateAd, mutationResult] = useMutation<UpdateAdMutation, UpdateAdMutationVariables>(
    UPDATE_TRAFFICKING_PAGE_AD
  );

  const client = useApolloClient();
  const oldCacheData = client.readFragment({
    id: `AdV5:${id}`,
    fragment: AD_TRAFFICKING_COLUMN,
  });

  const handleValidationError = useCallback(
    (error: ValidationError, setErrors: (errors: FormikErrors<ViewabilityVendorCellFormValues>) => void): void => {
      const formErrors: FormFormikErrors = {};
      error.fields.forEach((field) => (formErrors[field] = ' '));

      showFailure({ message: error.message, description: error.description });

      setErrors(formErrors as FormikErrors<ViewabilityVendorCellFormValues>);
    },
    []
  );

  const handleUpdate = useCallback(
    async (newAdTagList: AdTagInput[], newViewabilityVendors: ViewabilityVendor[]) => {
      try {
        await updateAd({
          variables: {
            id,
            updateAdInput: {
              adTagList: newAdTagList,
              viewabilityVendorList: newViewabilityVendors,
            },
          },
          update(_, { data }) {
            updateTraffickingColumnCache({
              adId: id,
              data,
              displayPublisherTarget: oldCacheData.displayPublisherTarget,
            });
          },
        });

        onSuccess();
      } catch (e) {
        showFailure({ message: 'Ad Save Failed', description: (e as Error).message });
      }
    },
    [id, oldCacheData, onSuccess, updateAd]
  );

  const handleSubmit = useCallback(
    async (
      values: ViewabilityVendorCellFormValues,
      helpers: FormikHelpers<ViewabilityVendorCellFormValues>
    ): Promise<void> => {
      const { adTagList, viewabilityVendorList, iasCampaignLineItemId } = values;
      const { setSubmitting, setErrors } = helpers;

      const error = validateMetadata(viewabilityVendorList, iasCampaignLineItemId);

      if (error) {
        handleValidationError(error, setErrors);
      } else {
        const newAdTagList: AdTagInput[] = adTagList.map((tag) => convertAdTagToAdTagInput(tag));
        const newViewabilityVendors: ViewabilityVendor[] = getViewabilityVendorInput(viewabilityVendorList);

        await handleUpdate(newAdTagList, newViewabilityVendors);
      }

      setSubmitting(false);
    },
    [handleUpdate, handleValidationError]
  );

  return {
    loading: mutationResult.loading,
    handleSubmit,
  };
};
