import './CustomizeColumns.scss';

import { IconSettings } from '@hulu-react-style-components/icons';
import React, { useCallback, useRef, useState } from 'react';
import type { ColumnInstance } from 'react-table';

import { hiddenData, orderData, visibleInitialHiddenColumns } from '../../../common/TraffickingTable/utils';
import type { TraffickingTableName } from '../../../constants';
import { useOnClickOutside } from '../../../hooks';
import bem from '../../../utils/bem';
import type { ControlledColumn } from '../ColumnControl';
import ColumnControl from '../ColumnControl';
import { SELECTION_COLUMN_ID } from '../TraffickingPage/hooks/constants';
import type { EntityModel } from '../TraffickingPage/modelConverters';
import {
  getControlledColumns,
  getHiddenColumnsFromSelectedIds,
  getSelectedColumns,
  setColumnsSelectValue,
} from './utils';

const [block, element] = bem('customize-columns');

type Props = {
  columns: ColumnInstance<EntityModel>[];
  originalColumns: ColumnInstance<EntityModel>[];
  setHiddenColumns: (param: string[]) => void;
  tableName: TraffickingTableName;
  setColumnOrder: (columnsId: string[]) => void;
};

const CustomizeColumns = ({
  originalColumns,
  columns,
  setHiddenColumns,
  tableName,
  setColumnOrder,
}: Props): React.ReactElement<Props> => {
  const ref = useRef(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<Record<string, boolean>>(() => {
    return getSelectedColumns(columns);
  });
  const [controlledColumns, setControlledColumns] = useState<ControlledColumn[]>(() => {
    return getControlledColumns(columns);
  });

  const toggleHiddenColumn = useCallback(
    (id: string, value: boolean): void => {
      setSelectedColumns((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    },
    [setSelectedColumns]
  );

  const updateColumnsOrder = (): void => {
    const columnsIds = [SELECTION_COLUMN_ID, ...controlledColumns.map((column) => column.id)];
    setColumnOrder(columnsIds);
    orderData.setStorageData(tableName, columnsIds);
  };

  const updateColumnsVisibility = (): void => {
    const selectedHiddenColumns = getHiddenColumnsFromSelectedIds(selectedColumns);

    const visibleInitiallyHiddenColumns: string[] = [];
    const hiddenColumns: string[] = [];

    controlledColumns.forEach((column) => {
      const isHidden = selectedHiddenColumns.includes(column.id);

      if (column.isColumnHiddenByDefault && !isHidden) {
        visibleInitiallyHiddenColumns.push(column.id);
      } else if (isHidden) {
        hiddenColumns.push(column.id);
      }
    });

    setHiddenColumns(hiddenColumns);
    hiddenData.setStorageData(tableName, hiddenColumns);
    visibleInitialHiddenColumns.setStorageData(tableName, visibleInitiallyHiddenColumns);
  };

  const toggleHiddenColumns = (controlledColumns: ControlledColumn[], value: boolean): void => {
    const newSelectedColumns = setColumnsSelectValue(controlledColumns, value);
    setSelectedColumns((selectedColumns) => ({ ...selectedColumns, ...newSelectedColumns }));
  };

  const resetToDefaults = (): void => {
    setControlledColumns(getControlledColumns(originalColumns));
    toggleHiddenColumns(controlledColumns, true);
  };

  const saveSettings = (): void => {
    updateColumnsVisibility();
    updateColumnsOrder();
  };

  const clickOutside = (): void => {
    if (visible) {
      saveSettings();
      setVisible(false);
    }
  };

  const onTooltipToggle = (e: React.MouseEvent): void => {
    e.stopPropagation();
    if (visible) saveSettings();
    setVisible(!visible);
  };

  useOnClickOutside(ref, clickOutside);

  return (
    <div ref={ref} className={block(visible ? 'active' : '')} data-testid="customize-columns">
      <div className={element('toggle-block')} onClick={onTooltipToggle}>
        <IconSettings className={element('settings')} data-testid="icon-settings" />
        <span className={element('text')}>Customize Columns</span>
      </div>

      {visible && (
        <div className={element('tooltip')} data-testid="tooltip">
          <ColumnControl
            setColumnOrder={setControlledColumns}
            toggleHiddenColumns={toggleHiddenColumns}
            toggleHiddenColumn={toggleHiddenColumn}
            columns={controlledColumns}
            selectedIds={selectedColumns}
            resetToDefaults={resetToDefaults}
          />
        </div>
      )}
    </div>
  );
};

export default CustomizeColumns;
