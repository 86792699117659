import './EntityDetailsDrawerContent.scss';

import { User } from 'mission-control-common-components';
import React from 'react';

import AdSideNavScheduling from '../../../../../common/AdSideNavScheduling';
import { TargetingIcon_Dark, TargetingIcon_Light } from '../../../../../common/assets';
import Badge from '../../../../../common/Badge';
import ChangeLog from '../../../../../common/ChangeLog';
import IconButton from '../../../../../common/IconButton';
import type { PermissionsNames } from '../../../../../constants';
import bem from '../../../../../utils/bem';
import AdListTab from '../../../../Ads/AdListTab/AdListTab';
import DrawerTargetingTable from '../../../DrawerTargetingTable';
import LineItemScheduling from '../../../LineItemScheduling';
import type { EntityDetailsDrawerData } from '../../../TraffickingPage/hooks/makePageDrawerPlugin';
import { EntityType } from '../../../TraffickingPage/hooks/makePageDrawerPlugin';

const [, drawerElement] = bem('trafficking-drawer-content');
const [menuItemBlock] = bem(drawerElement('menu-button')); // trafficking-drawer-content__menu-button

type Tabs = {
  tabTitle: string;
  navElement: JSX.Element;
  content: JSX.Element;
};

export const generateTraffickingTabs = (data: EntityDetailsDrawerData, isExpanded: boolean): Tabs[] => {
  const permissions = User.getPermissions<PermissionsNames>();

  const hasAccessToReadAdList = permissions?.readRotation && permissions.readTraffickingAds;
  const hasAccessToReadChangeLog = permissions?.readTraffickingChangeLog;

  const tabs: Tabs[] = [];

  if (data?.dataType === EntityType.LINE_ITEM || data?.dataType === EntityType.AD) {
    tabs.push({
      tabTitle: 'Targeting',
      navElement: (
        <div role="listitem">
          <img src={TargetingIcon_Light} className={drawerElement('img-icon', 'selected')} alt="Targeting" />
          <img src={TargetingIcon_Dark} className={drawerElement('img-icon', 'unselected')} alt="Targeting" />
          {isExpanded && <span>Targeting</span>}
        </div>
      ),
      content: <DrawerTargetingTable id={data.id} entityType={data.dataType} />,
    });
  }

  if (data?.dataType === EntityType.LINE_ITEM) {
    tabs.push({
      tabTitle: 'Line Item Scheduling',
      navElement: (
        <div role="listitem">
          <IconButton icon="IconCalendar" className={menuItemBlock('with-icon')} />
          {isExpanded && <span>Scheduling</span>}
        </div>
      ),
      content: <LineItemScheduling schedule={data.schedule} />,
    });
  }

  if (data?.dataType === EntityType.AD) {
    tabs.push({
      tabTitle: 'Ad Scheduling',
      navElement: (
        <div role="listitem">
          <IconButton icon="IconCalendar" className={menuItemBlock('with-icon')} />
          {isExpanded && <span>Scheduling</span>}
        </div>
      ),
      content: data.id ? (
        <AdSideNavScheduling
          adId={data.id}
          adSchedule={data?.schedule ?? null}
          lineItemSchedule={data?.lineItem?.schedule || null}
        />
      ) : (
        <div>No current schedule data for this {data.dataType}.</div>
      ),
    });
  }

  if (hasAccessToReadAdList && (data?.dataType === EntityType.LINE_ITEM || data?.dataType === EntityType.AD)) {
    const lineItemId = data?.dataType === EntityType.LINE_ITEM ? data.id : data.lineItem?.id;

    tabs.push({
      tabTitle: 'Ad List',
      navElement: (
        <div role="listitem">
          <Badge text={EntityType.AD} as="button" className={menuItemBlock()} />
          {isExpanded && <span>Ad List</span>}
        </div>
      ),
      content: lineItemId ? (
        <AdListTab lineItemId={lineItemId} />
      ) : (
        <div>No current ad list data for this {data.dataType}.</div>
      ),
    });
  }

  if (hasAccessToReadChangeLog) {
    tabs.push({
      tabTitle: 'Change Log',
      navElement: (
        <div role="listitem">
          <IconButton icon="IconHistory" className={menuItemBlock('with-icon')} />
          {isExpanded && <span>Change log</span>}
        </div>
      ),
      content: <ChangeLog id={data?.id} entityType={data?.dataType} />,
    });
  }

  return tabs;
};
