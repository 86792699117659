import { campaignClient } from '../../../../apis/graphql';
import * as Toast from '../../../../common/ToastAlert/toastAlert';
import { AlertType } from '../../../Alert';
import { DELETE_CREATIVES_ASSET_BY_ID_V5 } from './mutations/deleteCreativesAssetByIDV5';

export function showToast(success: boolean, description?: string): void {
  Toast.openToastAlert({
    alertType: success ? AlertType.SUCCESS : AlertType.ERROR,
    message: `Creative Asset ${success ? 'Removed' : 'Was Not Removed'}`,
    description,
  });
}

export async function deleteCreativeAssetByIds(creativeId: string, assetId: string): Promise<void> {
  if (!creativeId || !assetId) return;

  try {
    const { data, errors } = await campaignClient.mutate({
      mutation: DELETE_CREATIVES_ASSET_BY_ID_V5,
      variables: { creativeId, assetId },
    });

    if (!data || errors?.length) throw new Error();

    showToast(true, 'Creative Asset Successfully Removed');
    return;
  } catch (err) {
    showToast(false, 'Creative Asset may not exist or Creative ID or Asset ID is invalid');
  }
}
