import '../CellItems.scss';

import React from 'react';

import type { Priority } from '../../../../../../configs';
import bem from '../../../../../../utils/bem';
import { GreenPlus } from '../../../../../assets';
import type { CellItemsProps } from '../index';

const [block, element] = bem('multiple-change-log-cell');

function PriorityCell({ changes, action }: CellItemsProps): JSX.Element {
  const removalClass = action.removal ? 'removal' : '';
  if (changes && Array.isArray(changes)) {
    return (
      <div className={block(removalClass)}>
        {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
        {changes.map(({ payload: { name, rank } }, index) => {
          return (
            <ul key={index} className={element('priority')}>
              <li>Name: {name}</li>
              {rank && <li>Rank: {rank}</li>}
            </ul>
          );
        })}
      </div>
    );
  }

  if (changes.payload && Array.isArray(changes.payload)) {
    return (
      <div className={block(removalClass)}>
        {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
        {changes.payload.map((payload: Priority, index: number) => {
          return payload ? (
            <ul key={index} className={element('priority')}>
              <li>Name: {payload.name || '-'}</li>
              <li>Rank: {payload.rank || '-'}</li>
            </ul>
          ) : (
            <span>-</span>
          );
        })}
      </div>
    );
  }

  return changes.payload ? (
    <div className={block(removalClass)}>
      {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
      <ul className={element('priority')}>
        <li>Name: {changes.payload.name}</li>
        {changes.payload.rank && <li>Rank: {changes.payload.rank}</li>}
      </ul>
    </div>
  ) : (
    <span>-</span>
  );
}

export default PriorityCell;
