import './OverrideReviewModal.scss';

import type { ModalProps } from 'antd';
import React, { useCallback } from 'react';
import type { Row } from 'react-table';

import Loader from '../../../../common/Loader';
import ModalPopup from '../../../../common/ModalPopup';
import { TraffickingTableName } from '../../../../constants';
import bem from '../../../../utils/bem';
import useOverrideReview from '../hooks/useOverrideReview';

export interface OverrideReviewModalProps {
  closeModal: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedFlatRows: Row<any>[];
  tableName: TraffickingTableName;
  visible: boolean;
}

const overrideModalProps: ModalProps = {
  cancelText: 'Cancel',
  okText: 'Confirm',
  title: 'Override Campaign Review',
};

export const overrideItemType: { [key: string]: string } = {
  [TraffickingTableName.lineItems]: 'Line(s)',
  [TraffickingTableName.ads]: 'Ad(s)',
};

const [block] = bem('override-review-modal');

function OverrideReviewModal({
  closeModal,
  selectedFlatRows,
  tableName,
  visible,
}: OverrideReviewModalProps): JSX.Element {
  const { overrideReview, loading } = useOverrideReview();

  const handleSubmit = async (): Promise<void> => {
    await overrideReview({ selectedFlatRows, tableName });

    closeModal();
  };

  const getModalTitle = (): string => {
    return loading
      ? 'Override Request Submitted'
      : `Campaign Review Will be Overridden for Selected ${overrideItemType[tableName] || ''}`;
  };

  const ModalMessage = useCallback((): JSX.Element => {
    return (
      <>
        {loading ? (
          <div className={block()}>
            <Loader />
          </div>
        ) : (
          <ul>
            {selectedFlatRows.map(({ original }) => (
              <li key={original.id}>{original.name}</li>
            ))}
          </ul>
        )}
      </>
    );
  }, [loading, selectedFlatRows]);

  return (
    <ModalPopup
      visible={visible}
      onOk={handleSubmit}
      onCancel={closeModal}
      modalMessage={<ModalMessage />}
      modalProps={{
        ...overrideModalProps,
        title: getModalTitle(),
        cancelButtonProps: {
          disabled: loading,
        },
        okButtonProps: {
          disabled: loading,
        },
      }}
      wrapWithParagraphTag={false}
    />
  );
}

export default OverrideReviewModal;
