import type { TargetingTableRowGroup } from '../../../../../../../../../common/TargetingTable/TargetingTable';
import type { TargetingTermValue } from '../../../../../../../../../models';
import { EntityTypeArray } from '../../../../../../../TraffickingPage/hooks/makePageDrawerPlugin';

export const generateTargetingTableData = (
  targetingTermValues: TargetingTermValue[],
  isLineItem: boolean
): TargetingTableRowGroup[] => [
  {
    groupType: 'Ad Targeting',
    targetingTermValues: isLineItem ? [] : targetingTermValues,
  },
  {
    groupType: 'Line Item Targeting',
    targetingTermValues: isLineItem ? targetingTermValues : [],
  },
];

export const getHeaderText = (entityType: EntityTypeArray): string => {
  const isLineItem = entityType === EntityTypeArray.LINE_ITEM_ARRAY;

  return `Choose a targeting value to apply to the selected ${
    isLineItem ? 'line items' : 'ads'
  }. This setting will be in addition to other targeting previously set for the ${isLineItem ? 'line items' : 'ads'}.`;
};
