import { User } from 'mission-control-common-components';
import React, { memo } from 'react';
import type { Cell } from 'react-table';

import type { Get_Ad_Permitted_TimezonesQuery, ScheduleV5 } from '../../../../../apis/graphql';
import type { DropdownOption } from '../../../../../common/Dropdown';
import EditableDropdown from '../../../../../common/EditableTableCells/EditableDropdown';
import { DropdownOptions } from '../../../../../common/hooks/config';
import { useFetchDropdownOptions } from '../../../../../common/hooks/queries/useFetchDropdownOptions';
import { viewerLocalTimeLabel } from '../../../../../configs';
import type { PermissionsNames } from '../../../../../constants';
import type { Nullable } from '../../../../../models';
import { getIdFromTableCell } from '../../columns';
import { useUpdateAd } from '../../hooks';
import type { AdModel } from '../../modelConverters';

export const getTimezoneOption = (
  value: Pick<ScheduleV5, 'timezone' | 'deliveryInViewerTime'>,
  options: DropdownOption[]
): Nullable<DropdownOption> => {
  if (value.deliveryInViewerTime) return { value: viewerLocalTimeLabel, label: viewerLocalTimeLabel };

  const option = options.find((opt) => opt.value === value.timezone);

  if (option) return { value: option.value, label: option.label };

  return null;
};

function TimeZoneCell(cell: Cell<AdModel, Pick<ScheduleV5, 'timezone' | 'deliveryInViewerTime'>>): React.JSX.Element {
  const permissions = User.getPermissions<PermissionsNames>();
  const id = getIdFromTableCell(cell);

  const { cellVariant, updateAdFields } = useUpdateAd<'timezone' | 'deliveryInViewerTime'>(id);

  const { options } = useFetchDropdownOptions<Get_Ad_Permitted_TimezonesQuery>(DropdownOptions.AdPermittedTimezones);
  const optionsWithViewerLocalTime = [{ label: viewerLocalTimeLabel, value: viewerLocalTimeLabel }, ...options];

  const selectedValue = getTimezoneOption(cell.value, options);

  const updateValue = (newValue: string): void => {
    const adFields = {
      timezone: newValue === viewerLocalTimeLabel ? cell.value.timezone : newValue,
      deliveryInViewerTime: newValue === viewerLocalTimeLabel,
    };

    updateAdFields(adFields);
  };

  if (!selectedValue) return <></>;

  return (
    <EditableDropdown<string>
      classNameModifier="time-zone-cell"
      selectedValue={selectedValue}
      onSubmit={updateValue}
      options={optionsWithViewerLocalTime}
      variant={cellVariant}
      dataTestId="time-zone-cell"
      isDisabled={!permissions?.updateAd}
    />
  );
}

export const comparison = (
  prev: Cell<AdModel, Pick<ScheduleV5, 'timezone' | 'deliveryInViewerTime'>>,
  next: Cell<AdModel, Pick<ScheduleV5, 'timezone' | 'deliveryInViewerTime'>>
): boolean => {
  return (
    prev.row.original.id === next.row.original.id &&
    prev.value.deliveryInViewerTime === next.value.deliveryInViewerTime &&
    prev.value.timezone === next.value.timezone
  );
};

export default memo(TimeZoneCell, comparison);
