import './MenuList.scss';

import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import type { MenuListComponentProps } from 'react-select';

import type { DropdownOption, DropdownOptionGroup } from '../../../../common/Dropdown';
import Loader from '../../../../common/Loader';
import bem from '../../../../utils/bem';

type MenuListProps<T extends boolean = true> = MenuListComponentProps<DropdownOption, T, DropdownOptionGroup>;

const [, element] = bem('dropdown-list');

function MenuList<T extends boolean>({
  options,
  selectProps,
  children,
  innerRef,
  isMulti,
}: MenuListProps<T>): JSX.Element {
  return (
    <div
      id="scrollable-options"
      data-testid="scrollable-options"
      className={element('menu-list', isMulti ? 'is-multi' : '')}
      ref={innerRef}
    >
      <InfiniteScroll
        scrollableTarget="scrollable-options"
        dataLength={options.length}
        loader={<Loader />}
        next={selectProps.onNext}
        hasMore={selectProps.hasMore}
      >
        {children}
      </InfiniteScroll>
    </div>
  );
}

export default MenuList;
