import React from 'react';

import type { BrightlineCreativeInputV5 } from '../../../../apis/graphql';
import { AdTypeV5 } from '../../../../apis/graphql';
import { brightlineFeatures } from '../../../../configs';
import { creativeTypes } from '../../../../configs';
import { getAdPermittedTemplateTypes } from '../../../../configs/adPermittedTemplateTypes';
import { useFieldFast } from '../../../Form/hooks';
import { getInitialCreativeBuilderValues } from '../../adFormik';
import { AdFormFields } from '../../adFormik/fields';
import type { CreativeBuilderProps } from '../CreativeBuilder/CreativeBuilder';
import { DEFAULT_TAG_URL } from '../hooks/useTagUpload';
import { InteractiveCreativeBuilder } from '../index';
import type { CreativesBuilderValues } from '../types';

export const constructBrightlineCreativeInput = (
  { name, template, url }: CreativesBuilderValues = getInitialCreativeBuilderValues('build', AdTypeV5.Brightline)
): BrightlineCreativeInputV5 => {
  const input: BrightlineCreativeInputV5 = {
    name,
    type: 'BRIGHTLINE',
    url: url || DEFAULT_TAG_URL,
    template: template!,

    // @deprecated should be removed in demand migration UML updates
    vpaid: false,
    // @deprecated should be removed in demand migration UML updates
    featureList: [{ type: brightlineFeatures.IN_STREAM_SCROLLABLE.key }],
  };

  return input;
};

export function BrightlineCreativeBuilder({ editMode, isPreview = false }: CreativeBuilderProps): React.JSX.Element {
  const [, { value: adType }] = useFieldFast<AdTypeV5>(AdFormFields.Type);
  const templateOptions = getAdPermittedTemplateTypes(adType);

  return (
    <InteractiveCreativeBuilder
      creativeType={creativeTypes.BRIGHTLINE.key}
      templateOptions={templateOptions}
      isPreview={isPreview}
      editMode={editMode}
    />
  );
}

export default BrightlineCreativeBuilder;
