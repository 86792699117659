import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

// Templates
export type InnovidTemplate = keyof typeof innovidTemplateDisplayNames;

const innovidTemplateDisplayNames = {
  CANVAS: enumToTitleCase,
  ENDCARD: enumToTitleCase,
  EXPANDABLE: enumToTitleCase,
  OVERLAY: enumToTitleCase,
  TEST_INNOVID: enumToTitleCase,
};

export const innovidTemplates = makeConfig<InnovidTemplate>(innovidTemplateDisplayNames);
export const innovidTemplateKeys = Object.keys(innovidTemplates) as InnovidTemplate[];

export function convertInnovidTemplate(apiValue: string): DisplayEnum<InnovidTemplate> {
  return convertToDisplayEnum(apiValue, innovidTemplates);
}

// Feature List
export type InnovidFeature = keyof typeof innovidFeatureDisplayNames;

const innovidFeatureDisplayNames = {
  IN_STREAM_SCROLLABLE: enumToTitleCase,
  IN_STREAM_STORE_LOCATOR: enumToTitleCase,
  IN_STREAM_DIAGNOSTIC: enumToTitleCase,
  IN_STREAM_WEATHER: enumToTitleCase,
  CTFS_GALLERY_IMAGES: 'CTFS Gallery Images',
  CTFS_GALLERY_VIDEOS: 'CTFS Gallery Videos',
  CTFS_QUIZ: 'CTFS Quiz',
  REQUEST_FOR_INFO: enumToTitleCase,
};

export const innovidFeatures = makeConfig<InnovidFeature>(innovidFeatureDisplayNames);
export const innovidFeatureKeys = Object.keys(innovidFeatures) as InnovidFeature[];

export function convertInnovidFeature(apiValue: string): DisplayEnum<InnovidFeature> {
  return convertToDisplayEnum(apiValue, innovidFeatures);
}
