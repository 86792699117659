import './SequenceWeightCell.scss';

import { memo, useMemo, useState } from 'react';

import EditableCell from '../../../../../common/EditableTableCells/EditableCell';
import SequenceWeight from '../../../../../common/SequenceWeight';
import type { AdListData } from '../../../../../common/SequenceWeight/types';
import { convertAdStartAndEndDateToRightFormat } from '../../../../../common/utils';
import type { Nullable } from '../../../../../models';
import bem from '../../../../../utils/bem';
import { getAdListData } from '../../../../Ads/AdList/utils/utils';
import { weightToPercentage } from '../../columns';
import { useLazyFetchAdRotations } from '../../hooks/useLazyFetchAdRotations';
import useLazyFetchRotations from '../../hooks/useLazyFetchRotations';

const [block] = bem('sequence-weight-cell');

type Props = { weight?: Nullable<number>; lineItemId: string };

const SequenceWeightCell = ({ weight, lineItemId }: Props): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const { rotations, loading: rotationsLoading, loadRotations } = useLazyFetchRotations(lineItemId);
  const { adsRotations, loading: adRotationsLoading, loadAdRotations } = useLazyFetchAdRotations(lineItemId);

  const loading = rotationsLoading || adRotationsLoading;

  const displayedWeight = weightToPercentage({ value: weight || null });

  const adListData: AdListData = useMemo(() => {
    if (adsRotations && rotations) {
      const adsRotationWithTransformedStartAndEndDate = convertAdStartAndEndDateToRightFormat(adsRotations);

      return getAdListData(adsRotationWithTransformedStartAndEndDate, rotations);
    }

    return { rotations: [], adsWithoutWeightOrSequence: [], sequences: [] };
  }, [rotations, adsRotations]);

  const handleShowModal = (): void => {
    loadAdRotations();
    loadRotations();
    setIsModalVisible(true);
  };

  const handleCloseModal = (): void => {
    setIsModalVisible(false);
  };

  return (
    <>
      <EditableCell onClick={handleShowModal} variant={'base'} tabIndex={-1} usePencil={true}>
        <div className={block()}>{displayedWeight}</div>
      </EditableCell>
      {isModalVisible && (
        <SequenceWeight
          lineItemId={lineItemId}
          loading={loading}
          adListData={adListData}
          onCancel={handleCloseModal}
          onOk={handleCloseModal}
        />
      )}
    </>
  );
};

export default memo(SequenceWeightCell);
