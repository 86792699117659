import type { AdTag, Nullable } from '../../../models';
import { TAG_VALIDATION_MESSAGE, URL_REGEX } from '../../constants';
import type { ValidationError } from '../types';
import * as validationErrors from './const';

export const validateTagList = (adTagList: AdTag[]): Nullable<Pick<ValidationError, 'message' | 'description'>> => {
  const hasInvalidURL = adTagList.every((tag) => URL_REGEX.test(tag.url));

  if (!hasInvalidURL) {
    const error = {
      message: validationErrors.TAG_LIST_ERROR_MESSAGE,
      description: TAG_VALIDATION_MESSAGE,
    };

    return error;
  }

  return null;
};
