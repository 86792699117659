import { useQuery } from '@apollo/client';

import type { CreativeMetadata, Query } from '../../../../apis/graphql';
import { GET_CREATIVE_METADATA } from '../../../../pages/Ads/hooks/queries/getCreativeMetadata';

export const useCreativeMetadataByCreativeId = (
  creativeId: string,
  disableCache = false
): { metadataLoading: boolean; creativeMetadata: CreativeMetadata | null } => {
  const { loading, data } = useQuery<Query>(GET_CREATIVE_METADATA, {
    variables: { filterOptions: { creativeIdList: [creativeId] } },
    skip: !creativeId,
    fetchPolicy: disableCache ? 'no-cache' : undefined,
  });

  return { metadataLoading: loading, creativeMetadata: data?.creativeMetadata?.edges[0]?.node ?? null };
};
