import React from 'react';

import type { AdsPageAd } from '../../../../common/AdForm/hooks/types';
import type { PillProps } from '../../../../common/Pill';
import AdListItem from '../AdListItem/AdListItem';
import { useAdListItem } from '../AdListItem/hooks';
import { isSavedAd } from '../utils/utils';

export interface AdListItemInteractiveProps {
  weight: number;
  ad: AdsPageAd;
  adIndex: number;
  isSelected: boolean;
  selectedFocusRef?: (el: HTMLLIElement) => HTMLLIElement | null;
  pills: PillProps[];
  onClick: (adIndex: number, adId?: string) => void;
  onClickDelete: (adIndex: number) => void;
  isAdListWithOneItem: boolean;
  isSequence: boolean;
}

function AdListItemInteractive({
  adIndex,
  ad,
  onClick,
  onClickDelete,
  selectedFocusRef,
  isSelected,
  weight,
  pills,
  isAdListWithOneItem,
  isSequence,
}: AdListItemInteractiveProps): JSX.Element {
  const { handleDeleteClick, handleItemClick, handleRemoveAd, handleSelectAd } = useAdListItem({
    adIndex,
    ad,
    onClick,
    onClickDelete,
  });

  return (
    <AdListItem
      ad={ad}
      selectedFocusRef={selectedFocusRef}
      isSaved={isSavedAd(ad)}
      isSelected={isSelected}
      handleDeleteClick={handleDeleteClick}
      handleRemoveAd={handleRemoveAd}
      pills={pills}
      handleItemClick={handleItemClick}
      weight={weight}
      handleSelectAd={handleSelectAd}
      isAdListWithOneItem={isAdListWithOneItem}
      isSequence={isSequence}
    />
  );
}

export default AdListItemInteractive;
