import { gql } from '@apollo/client';

// Data for Targeting Dropdowns
export const GET_TARGETING_VALUE_BY_CATEGORY_AND_DIMENSION_ID = gql`
  query getTargetingValuesByCategoryAndDimensionID(
    $categoryGuid: String!
    $dimensionGuid: String!
    $filterOptions: TargetingValueFilterOptions
    $paginationOptions: PaginationOptions
    $countryOption: CountryFilterOption
    $publisherOption: PublisherFilterOption
  ) {
    getTargetingValuesByCategoryAndDimensionID(
      categoryGuid: $categoryGuid
      dimensionGuid: $dimensionGuid
      filterOptions: $filterOptions
      paginationOptions: $paginationOptions
      countryOption: $countryOption
      publisherOption: $publisherOption
    ) {
      edges {
        node {
          id
          name
          displayName
          description
          definition {
            category {
              id
              name
              displayName
            }
            value {
              id
              name
              displayName
              description
            }
            dimension {
              id
              name
              displayName
            }
          }
          primarySource {
            id
            name
            source
            system
          }
          additionalSource {
            id
            name
            source
            system
          }
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
