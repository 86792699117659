// Prevent users from selecting BILLABLE or CLICK on more than one tag
import type { TextAreaProps } from 'antd/lib/input';
import { some as _some } from 'lodash';

import type { AdTagType, Config, DisplayEnum, EventType } from '../../configs';
import { adTagTypes, getPermittedAdTagEvents, isRecognizedConfig, UNRECOGNIZED } from '../../configs';
import type { AdTag } from '../../models';
import type { DropdownOption } from '../Dropdown';
import { configToDropdownOption } from '../Dropdown';
import type { DropdownProps } from '../Dropdown/Dropdown';

export const commonTagEventDropdownProps: Pick<
  DropdownProps<EventType | typeof UNRECOGNIZED>,
  'classNameModifier' | 'isSearchable'
> = {
  classNameModifier: 'ad-tag-event',
  isSearchable: false,
};

export const URL_PLACEHOLDER_TEXT = 'https://';
export const commonUrlTextAreaProps: TextAreaProps = {
  autoSize: true,
  placeholder: URL_PLACEHOLDER_TEXT,
};

export function getAdTagEventDropdownOptionsByType(adTagType: DisplayEnum<AdTagType>): DropdownOption<EventType>[] {
  return isRecognizedConfig(adTagType) ? getPermittedAdTagEvents(adTagType.key).map(configToDropdownOption) : [];
}

// Updates to the tag type may trigger an update to the tag event
export function getUpdatedTagByType(
  prevTag: Pick<AdTag, 'id' | 'event' | 'url' | 'isVerified' | 'unDeletable' | 'isIas'>,
  newType: DropdownOption<AdTagType>
): AdTag {
  const newTag: AdTag = {
    ...prevTag,
    type: adTagTypes[newType.value],
  };

  // If the new AdTagType does not permit the currently-selected EventType, reset the EventType to a permitted value
  const currentEvent: DisplayEnum<EventType> = prevTag.event;
  const permittedEventsForNewAdTagType: Config<EventType>[] = getPermittedAdTagEvents(newType.value);

  if (!currentEvent || currentEvent.key === UNRECOGNIZED || !_some(permittedEventsForNewAdTagType, currentEvent)) {
    newTag.event = permittedEventsForNewAdTagType[0];
  }

  return newTag;
}
