import { gql } from '@apollo/client';

export const GET_ADS_BY_PARENT_LINE_OMS_LINK_ID_LIST = gql`
  query GET_ADS_BY_PARENT_LINE_OMS_LINK_ID_LIST($lineItemParentLineOmsLinkList: [ID!]!, $offset: Int!, $limit: Int!) {
    ads: adsV5(
      filterOptions: { lineItemParentLineOmsLinkList: $lineItemParentLineOmsLinkList }
      paginationOptions: { offset: $offset, limit: $limit }
    ) {
      edges {
        node {
          id
          name
          startDate
          endDate
          trackingId
          type
          lineItem {
            id
            name
            parentLineOmsLink {
              id
            }
          }
          adRotation {
            rotationId
          }
          adSequence {
            adId
            name
            order
            sequenceId
            updatedAt
          }
        }
      }
      total
    }
  }
`;
