import { gql } from '@apollo/client';

import { TARGETING_RULE_DEFINITION, TARGETING_TERM_VALUES } from '../../../../../apis/graphql';

export const GET_DRAWER_TARGETING_AD = gql`
  query getDrawerTargetingAd($ids: [ID!]!) {
    adsV5(filterOptions: { idList: $ids }) {
      edges {
        node {
          id
          lineItem {
            id
            targetingTermValues {
              ...targetingTermValues
            }
          }
          targetingTermValues {
            ...targetingTermValues
          }
          displayPublisherTarget
          targetingRule {
            ...targetingRuleDefinition
          }
        }
      }
    }
  }
  ${TARGETING_TERM_VALUES}
  ${TARGETING_RULE_DEFINITION}
`;
