import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import type { ScheduleV5, UpdateAdMutation, UpdateAdMutationVariables } from '../../../apis/graphql';
import type { Nullable } from '../../../models';
import { useCellVariant } from '../../../pages/Trafficking/TraffickingPage/hooks';
import { AD_TRAFFICKING_COLUMN } from '../../../pages/Trafficking/TraffickingPage/hooks/fragments';
import { UPDATE_TRAFFICKING_PAGE_AD } from '../../../pages/Trafficking/TraffickingPage/hooks/mutations';
import { validateAdSchedule } from '../../AdForm/validation/schedule';
import type { EditableInputVariant } from '../../EditableTableCells/EditableCell/EditableCell';
import type { AdFormErrorType } from '../../utils';
import { convertToDateRangeInput, convertToDayPartInput, updateTraffickingColumnCache } from '../../utils';

export type UpdateAdSchedule = {
  schedule: ScheduleV5;
  lineItemSchedule: Nullable<ScheduleV5>;
};

export type UseUpdateAdScheduleProps = {
  adId: string;
  onSuccessUpdate: () => void;
  onFailureUpdate: (error: AdFormErrorType) => void;
};

export type UseUpdateAdScheduleReturnedType = {
  cellVariant: EditableInputVariant;
  loading: boolean;
  updateAdSchedule: ({ schedule, lineItemSchedule }: UpdateAdSchedule) => Promise<void>;
};

export const useUpdateAdSchedule = ({
  adId,
  onSuccessUpdate,
  onFailureUpdate,
}: UseUpdateAdScheduleProps): UseUpdateAdScheduleReturnedType => {
  const [updateAd, mutationResult] = useMutation<UpdateAdMutation, UpdateAdMutationVariables>(
    UPDATE_TRAFFICKING_PAGE_AD
  );

  const cellVariant = useCellVariant(mutationResult);
  const client = useApolloClient();
  // we need this data because after update we can't receive some fields from campaign-manager
  // according to bug AX-3785
  const oldCacheData = client.readFragment({
    id: `AdV5:${adId}`,
    fragment: AD_TRAFFICKING_COLUMN,
  });

  const updateAdSchedule = useCallback(
    async ({ schedule, lineItemSchedule }: UpdateAdSchedule): Promise<void> => {
      const error = validateAdSchedule(schedule, lineItemSchedule);

      if (error) {
        return onFailureUpdate({ description: error.description, message: error.message });
      }

      try {
        await updateAd({
          variables: {
            id: adId,
            updateAdInput: {
              timezone: schedule?.timezone,
              dateRangeList: convertToDateRangeInput(schedule?.dateRangeList || []),
              dayPartList: convertToDayPartInput(schedule?.dayPartList || []),
              deliveryInViewerTime: schedule?.deliveryInViewerTime,
            },
          },
          update(_, { data }) {
            updateTraffickingColumnCache({ adId, data, displayPublisherTarget: oldCacheData.displayPublisherTarget });
          },
        });
        onSuccessUpdate();
      } catch (e) {
        onFailureUpdate({ message: 'Ad Save Failed', description: (e as Error).message });
      }
    },
    [adId, oldCacheData, onFailureUpdate, onSuccessUpdate, updateAd]
  );

  return {
    cellVariant,
    updateAdSchedule,
    loading: mutationResult.loading,
  };
};
