import React from 'react';

import type { ElementBuilder } from '../../../utils/bem';

interface NavIndicatorProps {
  linksLength: number;
  activeLink: number;
  element: ElementBuilder;
}

export default function NavIndicator({ linksLength, activeLink, element }: NavIndicatorProps): JSX.Element {
  return (
    <div className={element('nav-indicator')}>
      {new Array(linksLength).fill(0).map(
        (__: number, index: number): JSX.Element => {
          const isActiveIndicator = index === activeLink ? 'active' : '';
          return <div key={index} className={element(`indicator`, isActiveIndicator)} />;
        }
      )}
    </div>
  );
}
