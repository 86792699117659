import gql from 'graphql-tag';

export const SEND_LINE_ITEMS_ON_REVIEW = gql`
  mutation SendLineItemsOnReview($ids: [ID!]!) {
    sendLineItemsOnReviewV5(input: $ids) {
      id
      review
    }
  }
`;
