import { gql } from '@apollo/client';

export const TARGETING_TERM_VALUES = gql`
  fragment targetingTermValues on TargetingTermValue {
    category {
      id
      name
      displayName
      primarySource {
        id
        name
        system
      }
      additionalSource {
        id
        name
        system
      }
    }
    dimension {
      id
      name
      displayName
      primarySource {
        id
        name
        system
      }
      additionalSource {
        id
        name
        system
      }
    }
    value {
      id
      name
      displayName
      description
      definition {
        category {
          id
          name
          displayName
        }
        dimension {
          id
          name
          displayName
        }
        value {
          id
          name
          displayName
          description
        }
      }
      primarySource {
        id
        name
        system
      }
      additionalSource {
        id
        name
        system
      }
    }
    include
  }
`;
