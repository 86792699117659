import { PillColor } from '../../Pill';

const assetReviewStatusPillColors: { [key: string]: PillColor } = {
  REVIEW: PillColor.BLUE,
  APPROVED: PillColor.GREEN,
  PUBLISHED: PillColor.GREEN,
  PENDING: PillColor.GRAY,
  PREVIEW: PillColor.GRAY,
  REJECTED: PillColor.RED,
  UNRECOGNIZED: PillColor.GRAY,
  LOCKED: PillColor.ORANGE,
  QC_PENDING: PillColor.GRAY,
  QC_PREVIEW: PillColor.GRAY,
  QC_REVIEW: PillColor.GRAY,
};

export function getColor(review: string | undefined): PillColor {
  return review ? assetReviewStatusPillColors[review] : assetReviewStatusPillColors.UNRECOGNIZED;
}
