import { colorGray1 } from '@hulu/design-tokens/lib/colors.common';
import type { MenuProps } from 'react-select';
import { components } from 'react-select';
import ReactTooltip from 'react-tooltip';

import type { DropdownOption, DropdownOptionGroup } from '../Dropdown';

const CustomMenuWithTooltip = <T extends string>(
  props: MenuProps<DropdownOption<T>, false, DropdownOptionGroup<T>>
): React.JSX.Element => {
  return (
    <>
      <components.Menu {...props} />
      <ReactTooltip {...props.selectProps.tooltip} backgroundColor={colorGray1} />
    </>
  );
};

export default CustomMenuWithTooltip;
