import './EntityDetailsDrawerContent.scss';

import { Tab, TabNavigation } from '@hulu/react-style-components';
import React from 'react';

import bem from '../../../../../utils/bem';
import type { EntityDetailsDrawerData } from '../../../TraffickingPage/hooks/makePageDrawerPlugin';
import { generateTraffickingTabs } from './utils';

const [block, element] = bem('entity-details-drawer-content');

type Props = Partial<{
  data: EntityDetailsDrawerData;
  isExpanded: boolean;
}>;

export default function EntityDetailsDrawerContent({ data, isExpanded = false }: Props): JSX.Element {
  const expandedClass = isExpanded ? `expanded` : '';
  const tabs = data ? generateTraffickingTabs(data, isExpanded) : null;

  return (
    <TabNavigation className={block(expandedClass)}>
      {tabs?.map(({ tabTitle, content, navElement }) => (
        <Tab key={tabTitle} title={tabTitle} element={navElement}>
          <div className={element('selected-content')}>{content}</div>
        </Tab>
      ))}
    </TabNavigation>
  );
}
