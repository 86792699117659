export enum GeoDimension {
  Country = 'country',
  PostalCode = 'postal-code',
  City = 'city',
  DmaCode = 'dma-code',
  State = 'state',
}

enum ContentDimension {
  Movie = 'movie',
  Series = 'series',
  Extra = 'extra',
  Episode = 'episode',
  Season = 'season',
  Genre = 'genre',
}

export enum CustomDimension {
  Pod = 'pod',
  Publisher = 'publisher',
  Slot = 'slot',
  Age = 'age',
  TargetingPreset = 'PRESET',
}

export enum DemoDimension {
  Demo = 'demo',
  Audience = 'audience',
}

export enum ContentPartnerDimension {
  ContentPartner = 'content-partner',
}

export const DimensionsToDisable = [DemoDimension.Audience];

// add dimensions here that should have mandatory filtering before loading dimension values
export const DIMENSIONS_DOES_NOT_SUPPORT_EMPTY_SEARCH = [];

export const DIMENSION_INCLUDE_EXCLUDE_LIST: string[] = [
  DemoDimension.Audience,
  ContentDimension.Genre,
  CustomDimension.TargetingPreset,
  GeoDimension.PostalCode,
  GeoDimension.City,
  GeoDimension.State,
  GeoDimension.DmaCode,
];
