import './MultiSelectContainer.scss';

import { bem } from 'mission-control-common-components';
import React from 'react';
import Select from 'react-select';
import type { SelectComponents } from 'react-select/src/components';
import type { NamedProps as SelectProps } from 'react-select/src/Select';

import type { DropdownOption, DropdownOptionGroup } from '../../Dropdown/Dropdown';
import { commonCustomComponents, commonSelectProps } from '../../Dropdown/Dropdown';
import MultiControl from '../MultiControl';
import MultiOption from '../MultiOption';
import type { MultiSelectDropdownProps, OptionTypeWithExtras } from '../MultiSelectDropdown';
import useMultiSelectContainer from './hooks/useMultiSelectContainer';

const [block] = bem('multi-select-container');

export type MultiSelectContainerProps<T = string> = Omit<
  MultiSelectDropdownProps<OptionTypeWithExtras<T>>,
  'label' | 'onChange'
> &
  Pick<SelectProps<DropdownOption<OptionTypeWithExtras<T>>, true>, 'onChange'>;

function MultiSelectContainer<T = string>({
  components = commonCustomComponents,
  ...props
}: MultiSelectContainerProps<T>): JSX.Element {
  const { groupedOptions } = useMultiSelectContainer<T>(props);

  const multiCustomComponents: Partial<
    SelectComponents<DropdownOption<OptionTypeWithExtras<T>>, true, DropdownOptionGroup<OptionTypeWithExtras<T>>>
  > = {
    ...components,
    Control: MultiControl,
    Option: MultiOption,
  };

  return (
    <Select<DropdownOption<OptionTypeWithExtras<T>>, true, DropdownOptionGroup<OptionTypeWithExtras<T>>>
      className={block(props.multipleSelectControls ? ['multiple-select-controls'] : [])}
      {...commonSelectProps}
      isMulti
      components={multiCustomComponents}
      {...props}
      options={groupedOptions}
    />
  );
}

export default MultiSelectContainer;
