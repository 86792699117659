import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

export type LineItemStatus = keyof typeof lineItemStatusDisplayNames;
export type AdStatus = keyof typeof adStatusDisplayNames;
export type LineItemDerivedState = keyof typeof lineItemStatusDerivedState;

// Line Items for Status
const lineItemStatusDisplayNames = {
  OFF: enumToTitleCase,
  ON: enumToTitleCase,
  PAUSED: enumToTitleCase,
  CANCELLED: enumToTitleCase,
};

// Line Items for Status Dropdown
const dropdownLineItemStatusDisplayNames = {
  ON: enumToTitleCase,
  PAUSED: enumToTitleCase,
};

// Derived State
export const lineItemStatusDerivedState = {
  READY: enumToTitleCase,
  LIVE: enumToTitleCase,
  LATE: enumToTitleCase,
  ENDED: enumToTitleCase,
  DID_NOT_RUN: enumToTitleCase,
};

export const lineItemStatuses = makeConfig<LineItemStatus>(lineItemStatusDisplayNames);
export const dropdownLineItemStatuses = makeConfig<LineItemStatus>(dropdownLineItemStatusDisplayNames);
export const lineItemDerivedState = makeConfig<LineItemDerivedState>(lineItemStatusDerivedState);

export function convertLineItemStatus(apiValue: string): DisplayEnum<LineItemStatus> {
  return convertToDisplayEnum(apiValue, lineItemStatuses);
}

export function convertLineItemDerivedStatus(apiValue: string): DisplayEnum<LineItemDerivedState> {
  return convertToDisplayEnum(apiValue, lineItemDerivedState);
}

// Ads
const adStatusDisplayNames = {
  ON: enumToTitleCase,
  OFF: enumToTitleCase,
};

export const adStatuses = makeConfig<AdStatus>(adStatusDisplayNames);

export function convertAdStatus(apiValue: string): DisplayEnum<AdStatus> {
  return convertToDisplayEnum(apiValue, adStatuses);
}
