import { gql } from '@apollo/client';

export const DELETE_SEQUENCE_BY_ID = gql`
  mutation DeleteSequence($id: String!) {
    deleteSequenceV5(sequenceId: $id)
  }
`;

export const UPDATE_SEQUENCE = gql`
  mutation UpdateSequence($input: SequenceInput!) {
    updateSequenceV5(input: $input) {
      id
      type
      adSequence {
        order
        sequenceId
        adId
      }
    }
  }
`;

export const CREATE_SEQUENCE = gql`
  mutation CreateSequence($input: SequenceInput!) {
    createSequenceV5(input: $input) {
      id
      type
      adSequence {
        order
        sequenceId
        adId
      }
    }
  }
`;
