import React from 'react';

import SideNav from '../../SideNav';

export interface AdSideNavProps {
  className?: string;
  containerId?: string;
  links?: string[];
  error?: string;
}

export enum AdFormSection {
  DETAILS = 'Details',
  CREATIVE = 'Creative',
  TAGS = 'Tags',
  TARGETING = 'Targeting',
  SCHEDULING = 'Scheduling',
  METADATA = 'Metadata',
}

function AdSideNav({ className, containerId = '', links = Object.values(AdFormSection) }: AdSideNavProps): JSX.Element {
  return <SideNav links={links} containerId={containerId} testid="ad-side-nav" className={className} />;
}

export default AdSideNav;
