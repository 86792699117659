import './PacingStatusCell.scss';

import { IconAlertWarningSolid } from '@hulu-react-style-components/icons';
import React, { memo } from 'react';
import type { Cell } from 'react-table';

import type { GeneralPacingStatus } from '../../../../../apis/graphql';
import Tooltip from '../../../../../common/Tooltip';
import bem from '../../../../../utils/bem';
import type { LineItemModel } from '../../modelConverters';
import { cellContentsByPacingStatus } from './constants';

export type Props = {
  cell: Cell<LineItemModel>;
};

const [block, element] = bem('pacing-status-cell');

function renderPacingTooltip(messageTitle: string | null, message: string | null, id: string): JSX.Element | null {
  if (!messageTitle) return null;

  return (
    <Tooltip id={id} message={`<div style="text-align: left;"><strong>${messageTitle}</strong></div>${message}`}>
      <IconAlertWarningSolid aria-label={messageTitle} />
    </Tooltip>
  );
}

const PacingStatusCell = (cell: Cell<LineItemModel>): JSX.Element | null => {
  const {
    row: {
      id,
      values: { GeneralPacingStatus },
    },
  } = cell;

  if (!GeneralPacingStatus) return null;

  const { title, message, messageTitle, pacingMeter, classModifier } = cellContentsByPacingStatus[
    GeneralPacingStatus as GeneralPacingStatus
  ];

  const showStatus = title === 'Cancelled' || title === 'Did Not Run';
  const isNotEven = title === 'Not Even';

  return !showStatus ? (
    <div className={block(classModifier)} data-testid="pacing-status-cell">
      {pacingMeter}
      <div className={element('title')}>{isNotEven ? 'N/A' : title}</div>
      {renderPacingTooltip(messageTitle, message, id)}
    </div>
  ) : null;
};

const comparison = (prevProps: Readonly<Cell<LineItemModel>>, nextProps: Readonly<Cell<LineItemModel>>): boolean =>
  prevProps.row.id === nextProps.row.id &&
  prevProps.row.values.GeneralPacingStatus === nextProps.row.values.GeneralPacingStatus;

export default memo(PacingStatusCell, comparison);
