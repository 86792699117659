import './WeightSumInfo.scss';

import React from 'react';

import bem from '../../../utils/bem';

const [block, element] = bem('weight-sum-info');

type Props = {
  sum: number;
};

function WeightSumInfo({ sum }: Props): JSX.Element {
  return (
    <div className={block()}>
      <div className={element('line')} />
      <div data-testid="weight-sum-info-sum" className={element('info')}>
        = {sum}%
      </div>
    </div>
  );
}

export default WeightSumInfo;
