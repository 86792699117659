import { gql } from '@apollo/client';

export const AUDIT_TARGETING_TERM_VALUES = gql`
  fragment auditTargetingTermValues on TargetingTermValue {
    category {
      id
      name
      displayName
    }
    dimension {
      id
      name
      displayName
    }
    value {
      id
      name
      displayName
    }
    include
  }
`;

export const AUDIT_SCHEDULE_V5 = gql`
  fragment auditScheduleV5 on ScheduleV5 {
    dayPartList {
      activeDayList
      firstActiveHour
      lastActiveHour
    }
    dateRangeList {
      startDate
      endDate
      pacingShare
    }
    timezone
    deliveryInViewerTime
  }
`;

export const AUDIT_LINE_ITEM_V5 = gql`
  fragment auditLineItemV5 on AuditLineItemV5 {
    ... on AuditLineItemV5 {
      schedule {
        ...auditScheduleV5
      }
      adList {
        id
        name
      }
      lineItemTargetingTermValues: targetingTermValues {
        ...auditTargetingTermValues
      }
    }
  }
  ${AUDIT_TARGETING_TERM_VALUES}
  ${AUDIT_SCHEDULE_V5}
`;

export const AUDIT_AD_V5 = gql`
  fragment auditAdV5 on AuditAdV5 {
    ... on AuditAdV5 {
      schedule {
        ...auditScheduleV5
      }
      adTargetingTermValues: targetingTermValues {
        ...auditTargetingTermValues
      }
    }
  }
  ${AUDIT_TARGETING_TERM_VALUES}
`;

export const SINGLE_VALUE_CHANGE = gql`
  fragment singleValueChange on BaseChange {
    __typename
    ... on SingleValueChange {
      __typename
      oldValue {
        action
        payload
      }
      newValue {
        action
        payload
      }
    }
  }
`;

export const GET_CHANGE_LOG = gql`
  query getChangeLogByAdObjectID(
    $id: ID!
    $entityType: EntityType
    $paginationOptions: PaginationOptions
    $filterOptions: GetChangelogFilterOptions
  ) {
    getChangeLogByAdObjectID(
      id: $id
      entityType: $entityType
      paginationOptions: $paginationOptions
      filterOptions: $filterOptions
    ) {
      total
      edges {
        node {
          audits {
            modifiedDate
            modifiedBy
            changeList {
              fieldName
              ...singleValueChange
            }
            object {
              ...auditLineItemV5
              ...auditAdV5
            }
          }
          creativeAudits {
            modifiedDate
            modifiedBy
            changeList {
              fieldName
              ...singleValueChange
            }
            object {
              ...auditLineItemV5
              ...auditAdV5
            }
          }
          targetingChanges {
            old {
              termList {
                not
                subClass
                operation
                dimension
                valueSet
                rangeList {
                  lower
                  upper
                }
              }
            }
            new {
              termList {
                not
                subClass
                operation
                dimension
                valueSet
                rangeList {
                  lower
                  upper
                }
              }
            }
          }
        }
      }
    }
  }
  ${SINGLE_VALUE_CHANGE}
  ${AUDIT_LINE_ITEM_V5}
  ${AUDIT_AD_V5}
`;
