import { gql } from '@apollo/client';

import { TARGETING_RULE_DEFINITION } from '../../../../apis/graphql';
import { ADS_PAGE_AD } from '../../../../common/AdForm/hooks/fragments';

export const CREATE_ADS_PAGE_AD = gql`
  mutation CreateAdsPageAd($adInput: AdInputV5!) {
    createAd: createAdV5(input: $adInput) {
      ...adsPageAd
    }
  }
  ${ADS_PAGE_AD}
`;

export const CREATE_ADS_PAGE_AD_WITH_ASSET = gql`
  mutation CreateAdsPageAdWithAsset($adInput: AdInputV5!, $assetId: ID!, $assetInput: AssetInputV5!) {
    createAd: createAdV5(input: $adInput) {
      ...adsPageAd
    }
    updateReviewAssetV5(assetId: $assetId, input: $assetInput) {
      id
    }
  }
  ${ADS_PAGE_AD}
`;

export const CREATE_AD_WITH_BATCH_ASSETS = gql`
  mutation CreateAdWithBatchAssets($adInput: AdInputV5!, $idList: [String!]!, $assetInput: BatchReviewAssetInputV5!) {
    createAd: createAdV5(input: $adInput) {
      ...adsPageAd
    }

    updateBatchReviewAssets(idList: $idList, asset: $assetInput, isExternal: false) {
      successful {
        id
      }
      failed {
        id
        message
      }
    }
  }
  ${ADS_PAGE_AD}
  ${TARGETING_RULE_DEFINITION}
`;

export const CREATE_AD_WITH_GATEWAY_ASSETS = gql`
  mutation CreateAdWithGatewayAssets(
    $adInput: AdInputV5!
    $displayAssetId: String!
    $displayAssetInput: BatchReviewAssetInputV5!
    $videoAssetId: String!
    $videoAssetInput: BatchReviewAssetInputV5!
  ) {
    createAd: createAdV5(input: $adInput) {
      ...adsPageAd
    }

    updateDisplayAsset: updateBatchReviewAssets(
      idList: [$displayAssetId]
      asset: $displayAssetInput
      isExternal: false
    ) {
      successful {
        id
      }
      failed {
        id
        message
      }
    }
    updateVideoAsset: updateBatchReviewAssets(idList: [$videoAssetId], asset: $videoAssetInput, isExternal: false) {
      successful {
        id
      }
      failed {
        id
        message
      }
    }
  }
  ${ADS_PAGE_AD}
`;

export const UPDATE_ADS_PAGE_AD = gql`
  mutation UpdateAdsPageAd($adId: ID!, $updateAdInput: UpdateAdInputV5!, $targetingRule: TargetingRuleInput) {
    updateAd: updateAdV5(id: $adId, input: $updateAdInput, targetingRule: $targetingRule) {
      ...adsPageAd
      targetingRule {
        ...targetingRuleDefinition
      }
    }
  }
  ${ADS_PAGE_AD}
  ${TARGETING_RULE_DEFINITION}
`;

export const UPDATE_ADS_PAGE_AD_WITH_ASSET = gql`
  mutation UpdateAdsPageWithAsset(
    $adId: ID!
    $updateAdInput: UpdateAdInputV5!
    $assetId: ID!
    $assetInput: AssetInputV5!
    $targetingRule: TargetingRuleInput
  ) {
    updateAd: updateAdV5(id: $adId, input: $updateAdInput, targetingRule: $targetingRule) {
      ...adsPageAd
      targetingRule {
        ...targetingRuleDefinition
      }
    }
    updateReviewAssetV5(assetId: $assetId, input: $assetInput) {
      id
    }
  }
  ${ADS_PAGE_AD}
  ${TARGETING_RULE_DEFINITION}
`;

export const UPDATE_AD_WITH_BATCH_ASSETS = gql`
  mutation UpdateAdWithBatchAssets(
    $adId: ID!
    $updateAdInput: UpdateAdInputV5!
    $assetIdList: [String!]!
    $assetInput: BatchReviewAssetInputV5!
    $targetingRule: TargetingRuleInput
  ) {
    updateAd: updateAdV5(id: $adId, input: $updateAdInput, targetingRule: $targetingRule) {
      ...adsPageAd
      targetingRule {
        ...targetingRuleDefinition
      }
    }

    updateBatchReviewAssets(idList: $assetIdList, asset: $assetInput, isExternal: false) {
      successful {
        id
      }
      failed {
        id
        message
      }
    }
  }
  ${ADS_PAGE_AD}
  ${TARGETING_RULE_DEFINITION}
`;

export const UPDATE_AD_WITH_GATEWAY_ASSETS = gql`
  mutation UpdateAdWithGatewayAssets(
    $adId: ID!
    $updateAdInput: UpdateAdInputV5!
    $targetingRule: TargetingRuleInput
    $displayAssetId: String!
    $displayAssetInput: BatchReviewAssetInputV5!
    $videoAssetId: String!
    $videoAssetInput: BatchReviewAssetInputV5!
  ) {
    updateAd: updateAdV5(id: $adId, input: $updateAdInput, targetingRule: $targetingRule) {
      ...adsPageAd
      targetingRule {
        ...targetingRuleDefinition
      }
    }

    updateDisplayAsset: updateBatchReviewAssets(
      idList: [$displayAssetId]
      asset: $displayAssetInput
      isExternal: false
    ) {
      successful {
        id
      }
      failed {
        id
        message
      }
    }
    updateVideoAsset: updateBatchReviewAssets(idList: [$videoAssetId], asset: $videoAssetInput, isExternal: false) {
      successful {
        id
      }
      failed {
        id
        message
      }
    }
  }
  ${ADS_PAGE_AD}
  ${TARGETING_RULE_DEFINITION}
`;
