import gql from 'graphql-tag';

export const GET_ADS_IDS = gql`
  query GET_ADS_IDS($campaignsIds: [ID!], $lineItemsIds: [ID!], $limit: Int!) {
    ads: adsV5(
      filterOptions: { campaignIdList: $campaignsIds, lineItemIdList: $lineItemsIds }
      paginationOptions: { limit: $limit }
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`;
