import React, { useMemo } from 'react';

import { adTagTypes } from '../../../../../../../../../../configs';
import type { AdTag } from '../../../../../../../../../../models';
import { element } from '../../../components/Row/Row';

type Props = {
  adTagList: AdTag[];
  isShown?: boolean;
};

const TagsRow = ({ adTagList, isShown }: Props): React.JSX.Element => {
  const billableTags = useMemo(() => adTagList.filter((tag) => tag.type.key === adTagTypes.BILLABLE.key), [adTagList]);
  const nonBillableTags = useMemo(() => adTagList.filter((tag) => tag.type.key === adTagTypes.NON_BILLABLE.key), [
    adTagList,
  ]);
  const clickableTags = useMemo(() => adTagList.filter((tag) => tag.type.key === adTagTypes.CLICK.key), [adTagList]);

  if (!isShown || (!billableTags.length && !nonBillableTags.length && !clickableTags.length)) {
    return <div>-</div>;
  }

  return (
    <div>
      {billableTags.length ? (
        <div>
          <div className={element('value-title')} data-testid="billable-title">
            {adTagTypes.BILLABLE.displayName}:{' '}
          </div>
          {billableTags.map((tag) => (
            <div key={`billable-${tag.url}`}>{tag.url}</div>
          ))}
        </div>
      ) : null}
      {nonBillableTags.length ? (
        <div>
          <div className={element('value-title')} data-testid="non-billable-title">
            {adTagTypes.NON_BILLABLE.displayName}:{' '}
          </div>
          {nonBillableTags.map((tag) => (
            <div key={`non-billable-${tag.url}`}>{tag.url}</div>
          ))}
        </div>
      ) : null}
      {clickableTags.length ? (
        <div>
          <div className={element('value-title')} data-testid="click-title">
            {adTagTypes.CLICK.displayName}:{' '}
          </div>
          {clickableTags.map((tag) => (
            <div key={`click-${tag.url}`}>{tag.url}</div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default TagsRow;
