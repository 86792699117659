import type { DisplayEnum } from './types';
import { convertToDisplayEnum, makeConfig } from './utils';

export type ActiveDayType = keyof typeof ActiveDayTypeDisplayNames;

const ActiveDayTypeDisplayNames = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
};

export const activeDayTypes = makeConfig<ActiveDayType>(ActiveDayTypeDisplayNames);

export function convertActiveDayType(apiValue: string): DisplayEnum<ActiveDayType> {
  return convertToDisplayEnum(apiValue, activeDayTypes);
}
