import { AdTypeV5 } from '../../../../apis/graphql';
import type { CreativesBuilderValues } from '../../CreativeBuilders/types';

export const getTransformedSearchedCreative = (
  adType: AdTypeV5,
  fetchedCreative: CreativesBuilderValues
): CreativesBuilderValues => {
  const updatedCreative = { ...fetchedCreative, isSubmitted: !!fetchedCreative?.url };

  if (adType === AdTypeV5.AdSelectorSlate) {
    updatedCreative.optionList =
      updatedCreative?.optionList?.map((option) => ({
        ...option,
        adSelectorVideoCreativeId: '',
      })) ?? [];
  }

  return updatedCreative;
};
