import './ExportDropdown.scss';

import { IconDownload } from '@hulu-react-style-components/icons';
import React, { memo, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import type Link from 'react-csv/components/Link';
import type { TableInstance } from 'react-table';

import Loader from '../../../common/Loader';
import { openToastAlert } from '../../../common/ToastAlert/toastAlert';
import type { TraffickingTableName } from '../../../constants';
import { useOnClickOutside } from '../../../hooks';
import bem from '../../../utils/bem';
import type { EntityModel } from '../TraffickingPage/modelConverters';
import { EXPORT_MAX_LIMIT } from './constants';
import useTraffickerExportState from './hooks/useTraffickerExportState';
import { convertExportFileNameToCorrectFormat } from './utils/utils';

const [block, element] = bem('export-dropdown');

interface CSVLinkRef extends HTMLAnchorElement, Link {
  link: HTMLAnchorElement;
}

export interface ExportDropdownProps {
  tableInstance: TableInstance<EntityModel>;
  tableName: TraffickingTableName;
  total: number;
}

const ExportDropdown = ({ tableInstance, tableName, total }: ExportDropdownProps): JSX.Element => {
  const [visible, setVisible] = useState<boolean>(false);
  const [needExport, setNeedExport] = useState<boolean>(false);
  const ref = useRef(null);
  const csvFullExportLink = useRef<CSVLinkRef>(null);
  const { loading, loadAllData, loadTableData, dataToExport } = useTraffickerExportState(
    tableName,
    tableInstance,
    total
  );
  const exportFileName = convertExportFileNameToCorrectFormat(tableName, new Date(Date.now()));

  const onExportAll = (): void => {
    if (total <= EXPORT_MAX_LIMIT) {
      setVisible(false);
      setNeedExport(true);
      loadAllData();
    } else {
      openToastAlert({
        alertType: 'error',
        message: `Too many entities`,
        description: `We are unable to load more than ${EXPORT_MAX_LIMIT} rows. Please add some more filters.`,
      });
    }
  };

  const onExportInView = (): void => {
    setVisible(false);
    setNeedExport(true);
    loadTableData();
  };

  useOnClickOutside(ref, (): void => setVisible(false));

  useEffect(() => {
    if (needExport && !loading && dataToExport.length > 1) {
      setNeedExport(false);
      setTimeout(() => {
        csvFullExportLink.current?.link.click();
      });
    }
  }, [needExport, dataToExport, loading]);

  return (
    <div ref={ref} className={block()}>
      {loading ? (
        <Loader text="Loading data" className={element('loader')} />
      ) : (
        <div className={element('button')} onClick={(): void => setVisible(true)}>
          <IconDownload className={element('download-icon')} />
          Export
        </div>
      )}
      {visible && (
        <ul className={element('tooltip')}>
          <li onClick={onExportAll}>Export All</li>
          <li onClick={onExportInView}>Export Current View</li>
        </ul>
      )}
      {dataToExport.length > 1 && (
        <CSVLink
          role="link"
          filename={`${exportFileName}.csv`}
          data={dataToExport}
          className={element('csv-link')}
          ref={csvFullExportLink}
        />
      )}
    </div>
  );
};

export default memo(ExportDropdown);
