import React, { createContext, useContext, useMemo } from 'react';

import type { TraffickingFilters } from '../../apis/graphql';
import type { TraffickingTableName } from '../../constants';
import type { Nullable } from '../../models';
import type { GlobalFilters } from './hooks';
import { INITIAL_FILTERS, useFilters } from './hooks';

export type FilterContextValues = {
  filters: GlobalFilters;
  applyFilter: <T extends keyof TraffickingFilters>(filter: T, value: TraffickingFilters[T]) => void;
  applyTableFilter: <
    TableName extends keyof typeof TraffickingTableName,
    TableFilter extends keyof NonNullable<TraffickingFilters[TableName]>
  >(
    tableName: TableName,
    tableFilter: TableFilter,
    values: NonNullable<TraffickingFilters[TableName]>[TableFilter]
  ) => void;
  clearTableFilters: () => void;
  loading: boolean;
  shareableId: Nullable<string>;
};

export const defaultValue = {
  filters: INITIAL_FILTERS as GlobalFilters,
  applyFilter: (): void => {},
  applyTableFilter: (): void => {},
  clearTableFilters: (): void => {},
  loading: true,
  shareableId: null,
};

export const FilterContext = createContext<FilterContextValues>(defaultValue);

type Props = {
  children: React.ReactNode;
};

export const useFiltersContext = (): FilterContextValues => useContext(FilterContext);

const FilterProvider = ({ children }: Props): JSX.Element => {
  const { filters, applyFilter, applyTableFilter, clearTableFilters, loading, shareableId } = useFilters();

  const memoizedContextValue = useMemo(
    () => ({
      filters,
      applyFilter,
      applyTableFilter,
      clearTableFilters,
      loading,
      shareableId,
    }),
    [filters, applyFilter, applyTableFilter, clearTableFilters, loading, shareableId]
  );

  return <FilterContext.Provider value={memoizedContextValue}>{children}</FilterContext.Provider>;
};

export default FilterProvider;
