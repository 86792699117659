import './Collapse.scss';

import React from 'react';

import bem from '../../../utils/bem';

type Props = {
  toggleAll: (isOpen: boolean) => void;
};

const [block, element] = bem('change-log-expand-collapse-wrapper');

export const Collapse = ({ toggleAll }: Props): JSX.Element => {
  return (
    <div className={block()}>
      <button className={element('expand')} onClick={(): void => toggleAll(true)} aria-label="Expand All">
        Expand All
      </button>
      &nbsp;/&nbsp;
      <button className={element('collapse')} onClick={(): void => toggleAll(false)} aria-label="Collapse All">
        Collapse All
      </button>
    </div>
  );
};
