/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type { ModalProps } from 'antd';
import { User } from 'mission-control-common-components';
import React, { useCallback, useState } from 'react';
import type { CellProps } from 'react-table';

import IconButton from '../../../../../common/IconButton';
import ModalPopup from '../../../../../common/ModalPopup';
import type { PermissionsNames } from '../../../../../constants';
import type { DeleteItemsCallback } from '../../hooks/makeDeleteItemsPlugin';
import useDeleteAds from '../../hooks/useDeleteAds';
import type { AdModel } from '../../modelConverters';

export interface DeleteAdCellProps extends CellProps<AdModel> {
  deleteItemsCallback: DeleteItemsCallback;
}

function DeleteAdCell({ row, deleteItemsCallback, toggleRowSelected }: DeleteAdCellProps): JSX.Element {
  const permissions = User.getPermissions<PermissionsNames>();
  const [confirmationModalVisible, setConfirmationModalVisible] = useState<boolean>(false);
  const { deleteAds, loading } = useDeleteAds();
  const { id } = row.original;

  const handleOpenConfirmationModal = () => {
    if (!permissions?.deleteAd) {
      return;
    }

    setConfirmationModalVisible(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };

  const handleDeleteAd = useCallback(async () => {
    const response = await deleteAds([id]);

    if (!response?.errors?.length) deleteItemsCallback([id], () => toggleRowSelected(id, false));

    handleCloseConfirmationModal();
  }, [deleteAds, deleteItemsCallback, id, toggleRowSelected]);

  const okButtonProps = {
    loading,
  };

  const cancelButtonProps = {
    disabled: loading,
  };

  const confirmationModalProps: ModalProps = {
    title: 'Delete Ad',
    okText: 'Yes',
    cancelText: 'No',
    className: 'free-btn',
    cancelButtonProps,
    okButtonProps,
  };

  return (
    <>
      <span className="delete-cell" data-testid="delete-cell">
        <IconButton
          className={'delete-cell__button'}
          icon="IconDelete"
          variant="outlined"
          aria-label="delete ad"
          onClick={handleOpenConfirmationModal}
          disabled={!permissions?.deleteAd || row.original.unDeletable}
        />
      </span>
      {confirmationModalVisible && (
        <ModalPopup
          visible={confirmationModalVisible}
          onOk={handleDeleteAd}
          modalProps={confirmationModalProps}
          modalMessage="Are you sure you want to delete ad?"
          onCancel={handleCloseConfirmationModal}
        />
      )}
    </>
  );
}

export default DeleteAdCell;
