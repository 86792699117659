import { capitalize as _capitalize, toUpper as _toUpper } from 'lodash';

import type { DropdownOption } from '../../../common/Dropdown';
import type { SearchParamsValuesType } from '../../../constants';
import { TRAFFICKING_SEARCH_PARAMS } from '../../../constants';

const splitStringBySeparators = (value: string): string[] => value.split(/[_,-]/);

const formatWord = (word: string, index: number, words: string[], formatter?: (word: string) => string): string => {
  formatter = formatter ?? _capitalize;

  const startsWithNumberRegex = /^\d/;
  const shouldKeepWordLowerCase = startsWithNumberRegex.test(word) && index < words.length - 1;

  return shouldKeepWordLowerCase ? word : formatter(word.toLowerCase());
};

export const formatFilterName = (value: string, formatter?: (word: string) => string): string =>
  splitStringBySeparators(value)
    .map((...params) => formatWord(...params, formatter))
    .join(' ');

export const convertSelectedFilterValueToRightFormat = (
  selectedFilterValues: (string | DropdownOption)[],
  searchParam: SearchParamsValuesType
): (string | DropdownOption)[] => {
  const isAdStatusOrOrderTypeFilter =
    searchParam === TRAFFICKING_SEARCH_PARAMS.ORDER_TYPE_LIST ||
    searchParam === TRAFFICKING_SEARCH_PARAMS.AD_STATUS_LIST;

  const isCountryFilter = searchParam === TRAFFICKING_SEARCH_PARAMS.COUNTRY_LIST;

  return selectedFilterValues.map((value) => {
    const isValueDropdownOption = value instanceof Object && 'label' in value && 'value' in value;

    if (isValueDropdownOption) {
      return (value as DropdownOption).label;
    }

    if (isCountryFilter) {
      return formatFilterName(value as string, _toUpper);
    }

    if (!isAdStatusOrOrderTypeFilter) {
      return formatFilterName(value as string);
    }

    return value;
  });
};
