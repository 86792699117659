import React, { memo, useEffect, useMemo } from 'react';
import type { CellProps, Column, HeaderProps } from 'react-table';
import { useFlexLayout, useRowSelect, useTable } from 'react-table';

import { useFieldFast } from '../../../../../../../common/Form/hooks';
import type { AdType, DisplayEnum } from '../../../../../../../configs';
import { useFiltersContext } from '../../../../../../../contexts/FilterContext';
import { EMPTY_LINE_ITEM_SELECTION_MESSAGE } from '../../../../constants';
import { FieldName } from '../../../enums';
import type { DuplicateLineItem } from '../../../hooks/useDuplicateLineItemsState';
import type { UseDuplicateLineItemsState } from '../../../hooks/useDuplicateLineItemsState/useDuplicateLineItemsState';
import { sharedTableColumns } from '../constants';
import DuplicateLineItemsTable from '../DuplicateLineItemsTable';
import { getInitiallySelectedIds, transformSelectedRowsToFormValue } from '../utils';
import CheckboxLineItemBasicCell from './CheckboxLineItemBasicCell';
import CheckboxLineItemHeaderCell from './CheckboxLineItemHeaderCell';
import DeleteSavedLineItemCell from './DeleteSavedLineItemCell';

export type Props = {
  adName: string;
  adLineItemId: string;
  adType: DisplayEnum<AdType>;
} & Pick<UseDuplicateLineItemsState, 'savedItems' | 'handleRemoveSavedItem'>;

const DuplicateLineItemsSavedTable = ({
  savedItems,
  adLineItemId,
  adName,
  adType,
  handleRemoveSavedItem,
}: Props): JSX.Element => {
  const {
    filters: { lineItems },
  } = useFiltersContext();

  const [, { value: duplicateLineItemsFormValue }, { setValue: setLineItemsFormValue }] = useFieldFast<
    DuplicateLineItem[]
  >(FieldName.lineItems);

  const selectedLineItems = useMemo(
    () => lineItems?.selectedRowIds?.map((id) => savedItems.filter((item) => item.id === id)).flat(),
    [savedItems, lineItems?.selectedRowIds]
  );

  const initiallySelectedIds = useMemo(
    () => getInitiallySelectedIds(adType, duplicateLineItemsFormValue, selectedLineItems, adLineItemId),
    [adLineItemId, duplicateLineItemsFormValue, selectedLineItems, adType]
  );

  const columns: Column<DuplicateLineItem>[] = useMemo(
    () => [
      {
        id: 'Delete',
        Header: 'Delete',
        width: 70,
        Cell: ({
          row: {
            original: { id },
            toggleRowSelected,
          },
        }: CellProps<DuplicateLineItem>): JSX.Element => (
          <DeleteSavedLineItemCell
            id={id}
            handleRemoveSavedItem={handleRemoveSavedItem}
            toggleRowSelected={toggleRowSelected}
          />
        ),
      },
      ...sharedTableColumns,
    ],
    [handleRemoveSavedItem]
  );

  const {
    rows,
    headerGroups,
    prepareRow,
    getTableBodyProps,
    getTableProps,
    selectedFlatRows,
  } = useTable<DuplicateLineItem>(
    {
      columns,
      data: savedItems,
      getRowId: (originalRow) => originalRow.id,
      autoResetSelectedRows: false,
      initialState: {
        selectedRowIds: initiallySelectedIds,
      },
    },
    useRowSelect,
    useFlexLayout,
    (hooks) => {
      hooks.visibleColumns.push((tableColumns) => [
        {
          id: 'selection',
          Header: (props: HeaderProps<DuplicateLineItem>): JSX.Element => (
            <CheckboxLineItemHeaderCell adType={adType} {...props} />
          ),
          Cell: (props: CellProps<DuplicateLineItem>): JSX.Element => (
            <CheckboxLineItemBasicCell adType={adType} {...props} />
          ),
          width: 30,
        },
        ...tableColumns,
      ]);
    }
  );

  const stringifiedSelectedFlatRows = JSON.stringify(selectedFlatRows);

  useEffect(() => {
    const newLineItemsFormValue = transformSelectedRowsToFormValue(
      selectedFlatRows,
      adName,
      duplicateLineItemsFormValue
    );
    setLineItemsFormValue(newLineItemsFormValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedSelectedFlatRows, adName, setLineItemsFormValue]);

  return (
    <DuplicateLineItemsTable
      tableProps={getTableProps()}
      headerGroups={headerGroups}
      tableBodyProps={getTableBodyProps()}
      rows={rows}
      prepareRow={prepareRow}
      emptyMessage={EMPTY_LINE_ITEM_SELECTION_MESSAGE}
    />
  );
};

export default memo(DuplicateLineItemsSavedTable);
