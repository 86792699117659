import './MultipleEditDrawerContentHeader.scss';

import React from 'react';

import bem from '../../../../../../utils/bem';

type Props = {
  children: React.ReactNode;
};

const [block] = bem('multiple-edit-drawer-content-header');

export default function MultipleEditDrawerContentHeader({ children }: Props): JSX.Element {
  return <div className={block()}>{children}</div>;
}
