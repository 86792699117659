import type { MultiEditAdScheduleInputV5, MultiEditScheduleValueV5 } from '../../../../../../../../../../apis/graphql';
import type { PrepareAdsInputProps } from '../../../types';

type Result = {
  schedule?: Array<MultiEditAdScheduleInputV5>;
};

export const getPreparedInputForScheduling = ({ values, conflictIds }: PrepareAdsInputProps): Result => {
  const { entities, schedule, selectedSections } = values;
  if (!schedule || !entities.length || !selectedSections.includes('Scheduling')) {
    return {};
  }

  const { timezone, dateRangeList, dayPartList, deliveryInViewerTime } = schedule;

  const resultSchedule: MultiEditScheduleValueV5 = {
    timezone,
    deliveryInViewerTime,
    dayPartList,
  };

  if (dateRangeList.length && dateRangeList[0].startDate !== null) {
    resultSchedule.dateRangeList = dateRangeList;
  }

  return {
    schedule: entities
      .filter(({ id }) => !conflictIds?.includes(id))
      .map(({ id }) => ({
        id,
        value: resultSchedule,
      })),
  };
};
