import { gql } from '@apollo/client';

export const GET_AGENCIES = gql`
  query GET_AGENCIES($limit: Int, $offset: Int, $searchTerm: String, $crmLinkTypeList: [CRMLinkTypeV5!]) {
    getAgencies(
      paginationOptions: { limit: $limit, offset: $offset }
      filterOptions: { name: $searchTerm, crmLinkTypeList: $crmLinkTypeList }
    ) {
      edges {
        node {
          id
          url
          type
          name
          indexedAt
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
