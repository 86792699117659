import type { DisplayEnum, EventType } from '../../../configs';
import { adTagEvents, adTagTypes } from '../../../configs';
import type { AdTag } from '../../../models/adTag';

const IAS_CAMPAIGN_ID_TEXT = '{IAS_CAMPAIGN_ID}';
const IAS_LINE_ITEM_ID_TEXT = '{IAS_PLACEMENT_ID}';

/* eslint-disable no-template-curly-in-string */
export const IAS_NON_BILLABLE_START_URL =
  'https://video.adsafeprotected.com/vevent/impression/{IAS_CAMPAIGN_ID}/{IAS_PLACEMENT_ID}?xsId={random_UUID}&ias_xts=${upid}&ias_xdip={DEVICE_IP}&ias_xdua={DEVICE_USER_AGENT}&campId={DAS_CAMPAIGN_ID}&placementId={DAS_PLACEMENT_ID}&ias_xmtp=v&ias_xmapp=0&ias_xdebug=true&ias_xappb={PLATFORM}&us_privacy=${us_privacy}';
export const IAS_NON_BILLABLE_FIRST_QUARTILE_URL =
  'https://video.adsafeprotected.com/vevent/firstQuartile/{IAS_CAMPAIGN_ID}/{IAS_PLACEMENT_ID}?xsId={random_UUID}&ias_xts=${upid}&ias_xdebug=true&us_privacy=${us_privacy}';
export const IAS_NON_BILLABLE_MIDPOINT_URL =
  'https://video.adsafeprotected.com/vevent/midpoint/{IAS_CAMPAIGN_ID}/{IAS_PLACEMENT_ID}?xsId={random_UUID}&ias_xts=${upid}&ias_xdebug=true&us_privacy=${us_privacy}';
export const IAS_NON_BILLABLE_THIRD_QUARTILE_URL =
  'https://video.adsafeprotected.com/vevent/thirdQuartile/{IAS_CAMPAIGN_ID}/{IAS_PLACEMENT_ID}?xsId={random_UUID}&ias_xts=${upid}&ias_xdebug=true&us_privacy=${us_privacy}';
export const IAS_NON_BILLABLE_COMPLETE_URL =
  'https://video.adsafeprotected.com/vevent/complete/{IAS_CAMPAIGN_ID}/{IAS_PLACEMENT_ID}?xsId={random_UUID}&ias_xts=${upid}&ias_xdebug=true&us_privacy=${us_privacy}';

export const getURLWithIASIds = (url: string, campaignId?: string, lineItemId?: string): string => {
  const parsedUrl = url.split('/');
  const params = parsedUrl[6].split('?')[1];
  const paramsString = !!params ? `?${params}` : '';

  let campaignIdValue = '';
  let lineItemValue = '';

  if (!campaignId && !lineItemId) {
    campaignIdValue = IAS_CAMPAIGN_ID_TEXT;
    lineItemValue = IAS_LINE_ITEM_ID_TEXT;
  } else {
    campaignIdValue = campaignId ?? '';
    lineItemValue = lineItemId ?? '';
  }

  parsedUrl[5] = campaignIdValue;
  parsedUrl[6] = `${lineItemValue}${paramsString}`;

  return parsedUrl.join('/');
};

export const getIASCampaignLineItemIdFromIASUrl = (url: string): { campaignId: string; lineItemId: string } => {
  const parsedUrl = url.replace('{IAS_CAMPAIGN_ID}', '').replace('{IAS_PLACEMENT_ID}', '').split('/');
  const lineItemIdWithParams = parsedUrl[6];
  let lineItemId = '';

  if (lineItemIdWithParams) {
    lineItemId = lineItemIdWithParams.split('?')[0];
  }

  return { campaignId: parsedUrl[5] || '', lineItemId: lineItemId || '' };
};

export const getDefaultIASTag = (url: string, event: DisplayEnum<EventType>): AdTag => {
  return {
    id: '',
    type: adTagTypes.NON_BILLABLE,
    event,
    url,
    isVerified: true,
    unDeletable: false,
    isIas: true,
  };
};

export const getDefaultIASTags = (campaignId?: string, lineItemId?: string): AdTag[] => [
  getDefaultIASTag(getURLWithIASIds(IAS_NON_BILLABLE_START_URL, campaignId, lineItemId), adTagEvents.START),
  getDefaultIASTag(
    getURLWithIASIds(IAS_NON_BILLABLE_FIRST_QUARTILE_URL, campaignId, lineItemId),
    adTagEvents.FIRSTQUARTILE
  ),
  getDefaultIASTag(getURLWithIASIds(IAS_NON_BILLABLE_MIDPOINT_URL, campaignId, lineItemId), adTagEvents.MIDPOINT),
  getDefaultIASTag(
    getURLWithIASIds(IAS_NON_BILLABLE_THIRD_QUARTILE_URL, campaignId, lineItemId),
    adTagEvents.THIRDQUARTILE
  ),
  getDefaultIASTag(getURLWithIASIds(IAS_NON_BILLABLE_COMPLETE_URL, campaignId, lineItemId), adTagEvents.COMPLETE),
];
