import { gql } from '@apollo/client';

import { CAMPAIGN_TRAFFICKING_COLUMNS } from '../fragments';

export const GET_CAMPAIGNS = gql`
  query GET_CAMPAIGNS(
    $ids: [ID!]
    $adProductList: [String!]
    $pacingRiskList: [String!]
    $generalPacingStatusList: [String!]
    $hasMisalignedSchedule: Boolean
    $reviewStatusList: [String!]
    $adStatusList: [String!]
    $traffickerEmailList: [String!]
    $accountExecutiveList: [String!]
    $orderTypeList: [String!]
    $lineItemStatusList: [String!]
    $lineItemSubStatusList: [String!]
    $tierNameList: [String!]
    $priorityValueList: [String!]
    $billableThirdPartyList: [String!]
    $publisherList: [String!]
    $adRatingList: [String!]
    $adTypeList: [String!]
    $creativeTypeList: [String!]
    $creativeReviewList: [String!]
    $isCoppaOrCaru: Boolean
    $isMakegood: Boolean
    $isAddedValue: Boolean
    $brandIdList: [ID!]
    $agencyIdList: [ID!]
    $industryIdList: [ID!]
    $currencyList: [String!]
    $isRotation: Boolean
    $isSequence: Boolean
    $countryList: [String!]
    $schedule: ScheduleFilterInputV5
    $sort: CampaignSortOptionV5
    $order: OrderOption
    $limit: Int
    $offset: Int
    $searchTerm: String
  ) {
    campaigns: campaignsV5(
      filterOptions: {
        selectedIdList: $ids
        traffickerEmailList: $traffickerEmailList
        accountExecutiveList: $accountExecutiveList
        hasMisalignedSchedule: $hasMisalignedSchedule
        schedule: $schedule
        searchTerm: $searchTerm
        adProductList: $adProductList
        pacingRiskList: $pacingRiskList
        generalPacingStatusList: $generalPacingStatusList
        reviewStatusList: $reviewStatusList
        adStatusList: $adStatusList
        orderTypeList: $orderTypeList
        lineItemStatusList: $lineItemStatusList
        lineItemSubStatusList: $lineItemSubStatusList
        tierNameList: $tierNameList
        priorityValueList: $priorityValueList
        billableThirdPartyList: $billableThirdPartyList
        publisherList: $publisherList
        adRatingList: $adRatingList
        adTypeList: $adTypeList
        creativeTypeList: $creativeTypeList
        creativeReviewList: $creativeReviewList
        isCoppaOrCaru: $isCoppaOrCaru
        isMakegood: $isMakegood
        isAddedValue: $isAddedValue
        brandIdList: $brandIdList
        agencyIdList: $agencyIdList
        industryIdList: $industryIdList
        currencyList: $currencyList
        isRotation: $isRotation
        isSequence: $isSequence
        countryList: $countryList
      }
      sortOptions: { sort: $sort, order: $order }
      paginationOptions: { limit: $limit, offset: $offset }
    ) {
      edges {
        node {
          ...campaignTraffickingColumns
        }
      }
      total
    }
  }
  ${CAMPAIGN_TRAFFICKING_COLUMNS}
`;
