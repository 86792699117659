import './ClearAllButton.scss';

import { IconClose } from '@hulu-react-style-components/icons';
import React from 'react';

import bem, { withOptionalClassName } from '../../../utils/bem';

export type ClearAllButtonProps = {
  label: string;
  className?: string;
  onClick: () => void;
};

const [block, element] = bem('clear-all-btn');

const ClearAllButton = ({ label, className, onClick }: ClearAllButtonProps): React.JSX.Element => {
  return (
    <button data-testid="clear-all-btn" className={withOptionalClassName(block(), className)} onClick={onClick}>
      {label} <IconClose className={element('close-icon')} />
    </button>
  );
};

export default ClearAllButton;
