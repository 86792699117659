import { gql } from '@apollo/client';

import { ASSET_IMPORTER_ASSET } from './assetFragments';

export const CREATIVE_ASSET_FRAGMENT = gql`
  fragment creativeAsset on BaseAssetV5 {
    id
    review
    rejectionReasons
    name
    type
    s3Url
    assetTagList {
      value
    }
    createdAt
    updatedAt
    md5
    fileSize
    transcodeStatus
    transcodeFailureDetails {
      name
      message
    }
    ...assetImporterAssetV5
    ... on VideoAssetV5 {
      duration
      mediaMetadata {
        format
      }
    }
  }
  ${ASSET_IMPORTER_ASSET}
`;
