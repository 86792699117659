import type { DateRangeV5 } from '../../../../apis/graphql';

export enum Steps {
  'SELECT',
  'EDIT',
  'CONFIRM',
}

export const nullableDefaultRangeDate: DateRangeV5 = {
  startDate: null,
  endDate: null,
  pacingShare: null,
};
