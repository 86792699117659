export const SPReviewStatus = Object.freeze({
  UNLOCKED: 'UNLOCKED',
  LOCKED: 'LOCKED',
  DENIED: 'DENIED',
});

export const SPReviewStatusMap: Record<keyof typeof SPReviewStatus, string> = Object.freeze({
  UNLOCKED: 'UNLOCKED',
  LOCKED: 'UNDER S&P REVIEW',
  DENIED: 'DENIED',
});
