import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

export type OrderType = keyof typeof orderTypeDisplayNames;

const orderTypeDisplayNames = {
  ATV_PG: enumToTitleCase,
  ATV_PG_XP: enumToTitleCase,
  ATV_IOA: enumToTitleCase,
  ATV_IOA_XP: enumToTitleCase,
  ATV_PMP: enumToTitleCase,
  ATV_PMP_XP: enumToTitleCase,
  BARTER: enumToTitleCase,
  SELF_SERVE: enumToTitleCase,
  STANDARD: enumToTitleCase,
  STANDARD_XP: enumToTitleCase,
  TEST: enumToTitleCase,
};

export const orderTypes = makeConfig<OrderType>(orderTypeDisplayNames);

export function convertOrderType(apiValue: string): DisplayEnum<OrderType> {
  return convertToDisplayEnum(apiValue, orderTypes);
}
