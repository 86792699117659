import { User } from 'mission-control-common-components';
import React, { memo } from 'react';

import EditablePercentInput from '../../../../../common/EditableTableCells/EditablePercentInput';
import { onLineItemFailureUpdate } from '../../../../../common/utils';
import type { PermissionsNames } from '../../../../../constants';
import { useUpdateLineItem } from '../../hooks';

export type PercentCellProps = {
  name: 'deliveryBufferPercent' | 'frontLoadPercent';
  lowerLimit: number;
  upperLimit: number;
  value: number | null;
  showEmptyCell?: boolean;
  id: string;
};

function PercentCell({ value, id, showEmptyCell, name, lowerLimit, upperLimit }: PercentCellProps): React.JSX.Element {
  const permissions = User.getPermissions<PermissionsNames>();
  const { cellVariant, updateLineItemField } = useUpdateLineItem(id, name, onLineItemFailureUpdate);

  if (!!showEmptyCell) {
    return <div data-testid="percent-empty-cell"></div>;
  }

  return (
    <EditablePercentInput
      initialValue={value || 0}
      onSubmit={updateLineItemField}
      variant={cellVariant}
      lowerLimit={lowerLimit}
      upperLimit={upperLimit}
      isDisabled={!permissions?.updateLineItem}
    />
  );
}

export default memo(PercentCell);
