export type ActionButtonConfig = {
  id: string;
  isShown: boolean;
  tooltipLabel: string;
  tooltipClassName?: string;
  component: React.JSX.Element;
};

export enum CellType {
  create = 'create',
  edit = 'edit',
}
