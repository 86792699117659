import * as color from './colors';

export function gridPx(multiple = 1): string {
  return `${multiple * 8}px`;
}

export const defaultPadding = gridPx(2);
export const fontSize = {
  standard: '14px',
  small: '12px',
};

export const headerHeight = gridPx(8);
export const footerHeight = '50px';

// Page Title
export const pageTitle = {
  height: '62px',
  padding: `${gridPx(4)} 0 0 ${gridPx(4)}`,
  fontSize: '24px',
  fontWeight: 500,
};

// Page Header
export const pageHeader = {
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '24px',
};

// Height remaining when you account for header and footer
export const contentHeight = `calc(100vh - ${pageTitle.height} - ${footerHeight})`;

// Borders
export const border = {
  radiusStandard: '4px',
  light: '0.5px solid rgba(0, 0, 0, 0.04',
  darkBorder: `1px solid ${color.borders.dark}`,
  lightBorder: `1px solid ${color.borders.light}`,
};

// Tables
export const tables = {
  cellHeight: gridPx(6),
  background: color.design.white,
  border: color.design.gray3,
};

// Shadows
export const shadows = {
  shadowHeightStandard: '2px',
  raisedShadowStandard: '0px 2px 4px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14) 0 1px 10px rgba(0, 0, 0, 0.12)',
  raisedShadowClipPath: 'inset(0 -5px -5px -5px)',
  tooltipShadow: '0 4px 5px rgba(0, 0, 0, 0.2), 0 7px 10px rgba(0, 0, 0, 0.14), 0 2px 16px rgba(0, 0, 0, 0.12)',
  dragShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
  stickyFilterDropShadow: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.1))',
  boxShadowDark: `0 2px 4px 0 ${color.boxShadow.dark}`,
  boxShadowLight: `0 2px 4px 0 ${color.boxShadow.light}`,
};
