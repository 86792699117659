import './Tooltip.scss';

import { colorGray1 } from '@hulu/design-tokens/lib/colors.common';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import type { TooltipProps as ReactTooltipProps } from 'react-tooltip';
import ReactTooltip from 'react-tooltip';

import bem, { withOptionalClassName } from '../../utils/bem';

export interface TooltipProps
  extends Pick<ReactTooltipProps, 'place' | 'delayShow' | 'delayHide' | 'disable' | 'effect'> {
  // provide a unique ID for each tooltip
  id: string;
  className?: string;
  children: JSX.Element;
  // data-tip only accepts a string, data-html supports HTML, both are setup
  message: string;
  /**  Determine if the tooltip should be placed in a React Portal or not.
   * Currently, some tooltips are misplaced or not being shown because they are put inside the React Portal
   */
  shouldRenderInPortal?: boolean;
}

const [block, element] = bem('tooltip');

// Wrapper component to portal react-tooltips
function BodyPortal({ children }: { children: React.ReactNode }): React.JSX.Element {
  // Create root level element for react-tooltips
  const domNode = document.createElement('div');
  // Add a unique ID to the root level element for testing purposes
  domNode.setAttribute('data-testid', 'body-portal');

  useEffect(() => {
    document.body.appendChild(domNode);

    return (): void => {
      domNode.remove();
    };
  }, [domNode]);

  return ReactDOM.createPortal(children, domNode);
}

// tooltip wrapper to ensure all tooltips get rendered into the portal
function Tooltip({
  id,
  className,
  children,
  message,
  place,
  delayShow,
  delayHide,
  disable,
  effect = 'solid',
  shouldRenderInPortal = true,
}: TooltipProps): JSX.Element {
  const reactTooltip = (
    <ReactTooltip
      id={id}
      className={withOptionalClassName(block(), className)}
      backgroundColor={colorGray1}
      effect={effect}
    />
  );

  return (
    <>
      <div data-testid="tooltip-wrapper" className={element('wrapper')}>
        {!shouldRenderInPortal ? reactTooltip : null}
        <div
          data-for={id}
          className={element('container')}
          data-place={place || 'bottom'}
          data-tip-disable={disable || false}
          data-delay-show={delayShow || 100}
          data-delay-hide={delayHide || 100}
          data-tip={message}
          data-html
        >
          {children}
        </div>
      </div>
      {shouldRenderInPortal ? <BodyPortal>{reactTooltip}</BodyPortal> : null}
    </>
  );
}

export default Tooltip;
