import './DateInput.scss';

import type { InputHTMLAttributes } from 'react';
import React from 'react';

import bem from '../../utils/bem';
import { MAX_ALLOWED_DATE } from './const';

export interface DateTimeInputProps {
  value: string | null;
  onChange?: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  nullable?: boolean;
  isReadOnly?: boolean;
  isInvisible?: boolean;
  type?: 'date' | 'datetime-local';
}

const [block, element] = bem('datetime-input');

const DateInput = ({
  value,
  onChange,
  onKeyDown,
  disabled = false,
  className = '',
  nullable = false,
  isReadOnly = false,
  isInvisible = false,
  type = 'date',
  ...props
}: DateTimeInputProps & Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>): JSX.Element => {
  let prevValue = '';
  const modifiers = [
    isReadOnly ? 'readonly' : null,
    isInvisible ? 'invisible' : null,
    disabled ? 'disabled' : null,
    type === 'date' ? 'without-time' : 'with-time',
  ];

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!nullable && isNaN(Date.parse(e.currentTarget.value) || 0)) {
      e.currentTarget.value = prevValue;
      return;
    }
    if (onChange) onChange(e.currentTarget.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    prevValue = e.currentTarget.value;
    if (onKeyDown) onKeyDown(e);
  };

  return (
    <div className={`${block(modifiers)} ${className}`} data-testid="date-input-container">
      <input
        aria-label="date picker"
        className={element(type === 'date' ? 'date-input' : 'datetime-input')}
        value={value || ''}
        onChange={handleDateChange}
        onClick={(e): void => e.preventDefault()}
        onKeyDown={handleKeyDown}
        type={type}
        // bring focus to the first input
        disabled={disabled}
        required
        max={MAX_ALLOWED_DATE}
        {...props}
      />
    </div>
  );
};

export default DateInput;
