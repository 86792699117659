import './CreativeFooterStyling.scss';

import { bem } from 'mission-control-common-components';
import React from 'react';

const [block] = bem('creative-footer');

export const CreativeFooterStyling: React.FC<{ children: React.ReactNode }> = (props) => {
  return <div className={block()}>{props.children}</div>;
};
