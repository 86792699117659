import './FilterButton.scss';

import { IconChevronDown, IconFilterFunnel } from '@hulu-react-style-components/icons';
import type { FC } from 'react';
import React from 'react';

import bem from '../../../../../../utils/bem';

const [block, element] = bem('filter-button');

type Props = {
  quantity: number;
  toggleFilterOpen: () => void;
};

const FilterButton: FC<Props> = ({ toggleFilterOpen, quantity }) => {
  return (
    <div data-testid="filterButton" className={block()} onClick={toggleFilterOpen}>
      <IconFilterFunnel width={16} height={16} className={element('funnel-icon')} />
      <span>Filters {quantity > 0 && `(${quantity})`}</span>
      <IconChevronDown width={16} height={16} className={element('chevron-icon')} />
    </div>
  );
};

export default FilterButton;
