import type { ApolloQueryResult, DocumentNode } from '@apollo/client';

import { campaignClient } from '../../../../apis/graphql';
import type { QueryVariables } from '../../TraffickingPage/hooks/useTraffickerState';
import type { TraffickerTableData } from '../../types';

export type FetchDataResponse = Pick<ApolloQueryResult<TraffickerTableData>, 'data' | 'errors'>;

type LazyTraffickerTableState = {
  fetchData: (queryVars: QueryVariables) => Promise<FetchDataResponse>;
};

const useTraffickerExportTable = (query: DocumentNode): LazyTraffickerTableState => {
  const fetchData = async (queryVars: QueryVariables): Promise<FetchDataResponse> => {
    const { data, errors } = await campaignClient.query({
      query,
      variables: queryVars,
      fetchPolicy: 'no-cache',
    });

    return {
      data,
      errors,
    };
  };

  return {
    fetchData,
  };
};

export default useTraffickerExportTable;
