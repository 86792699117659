import { useEffect, useState } from 'react';

import { fetchMSTRToken } from '../../../apis/mstrAPI';
import type { Nullable } from '../../../models';

interface UseTokenResult {
  loading: boolean;
  error: Nullable<string>;
  payload: Nullable<string>;
}

const fetchTokenAndUpdateState = async (setToken: Function): Promise<void> => {
  const { payload, error } = await fetchMSTRToken();
  setToken({ loading: false, payload, error });
};

function useToken(): UseTokenResult {
  const [token, setToken] = useState({
    loading: true,
    error: null,
    payload: null,
  });

  // Exactly once, on page load, fetch a unique MSTR token from the backend and set the app state
  // Empty dependency array means this will only run one time, after the initial render
  useEffect(() => {
    fetchTokenAndUpdateState(setToken);
  }, []);

  return token;
}

export { useToken };
