import '../CellItems.scss';

import type { JSX } from 'react';
import React from 'react';

import bem from '../../../../../../utils/bem';
import { GreenPlus } from '../../../../../assets';
import type { CellItemsProps } from '../index';

const [block, element] = bem('multiple-change-log-cell');

export type Tag = { type?: string; event?: string; url?: string; isVerified?: boolean; 'is-verified'?: boolean };

const PlusIcon: React.FC<{ addition?: boolean }> = (props) => (
  <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />
);

type TagEntryProps = { tag: Tag; removal?: boolean };

const TagEntry = ({ tag, removal }: TagEntryProps): JSX.Element => {
  let isTagVerified = null;

  if (tag.isVerified !== undefined) isTagVerified = tag.isVerified;
  if (tag['is-verified'] !== undefined) isTagVerified = tag['is-verified'];

  return (
    <ul className={element('tags', removal ? 'removal' : '')}>
      {tag.type?.length ? <li>Type: {tag.type}</li> : null}
      {tag.event?.length ? <li>Event: {tag.event}</li> : null}
      {tag.url?.length ? <li>Url: {tag.url}</li> : null}
      {typeof isTagVerified === 'boolean' ? <li>Verified: {isTagVerified ? 'Yes' : 'No'}</li> : null}
    </ul>
  );
};

const TagsCell = ({ changes, action }: CellItemsProps): JSX.Element => {
  const { addition, removal } = action;

  if (changes && Array.isArray(changes)) {
    return (
      <div className={block()}>
        {addition && <PlusIcon />}
        {changes.map(({ payload }, i) => (
          <TagEntry removal={removal} tag={payload as Tag} key={i} />
        ))}
      </div>
    );
  }

  if (changes.payload.length) {
    return changes.payload.map((tag: Tag, i: number) => {
      if (!tag) return <span>-</span>;

      return (
        <div className={block()} key={i}>
          {addition && <PlusIcon />}
          <TagEntry removal={removal} tag={tag} />
        </div>
      );
    });
  }

  if (changes.payload && Object.keys(changes.payload).length) {
    return (
      <div className={block()}>
        {addition && <PlusIcon />}
        <TagEntry removal={removal} tag={changes.payload} />
      </div>
    );
  }

  return <span>-</span>;
};

export default TagsCell;
