import '../CellItems.scss';

import React from 'react';

import bem from '../../../../../../utils/bem';
import { GreenPlus } from '../../../../../assets';
import type { Action } from '../../CellItem';
import type { CellItemsProps } from '../index';

const [block, element] = bem('multiple-change-log-cell');

type secondaryPaceObject = {
  until: string;
  percent: number;
};

interface TypePropertiesListProps {
  index: number;
  action: Action;
  goal: string;
  preemptible: boolean;
  frontLoadPercent: number;
  secondaryPaceList: secondaryPaceObject[];
  deliveryBufferPercent: number;
  url?: string;
  video?: { id?: string };
}

function TypePropertiesList({
  index,
  action,
  goal,
  preemptible,
  frontLoadPercent,
  secondaryPaceList,
  deliveryBufferPercent,
  url,
  video,
}: TypePropertiesListProps): JSX.Element {
  const removalClass = action.removal ? 'removal' : '';
  const hasItems = goal || preemptible || frontLoadPercent || secondaryPaceList || deliveryBufferPercent;
  if (url || video?.id) {
    return (
      <div className={block()}>
        {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
        {url ? `URL: ${url}` : ''}
        {video?.id ? `Video Id: ${video?.id}` : ''}
      </div>
    );
  }
  return (
    <div className={block()}>
      {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
      {hasItems ? (
        <ul key={index} className={element('type-properties', removalClass)}>
          {goal && <li>Goal: {goal}</li>}
          {preemptible?.toString() && <li>Preemptible: {preemptible.toString()}</li>}
          {frontLoadPercent && <li>Front Load Percent: {frontLoadPercent}</li>}
          {secondaryPaceList && secondaryPaceList.length > 0
            ? secondaryPaceList.map((secondaryPace: secondaryPaceObject, index: number): JSX.Element | '' =>
                secondaryPace && secondaryPace.percent && secondaryPace.until ? (
                  <li key={`secondary-pace-object-${index}`}>
                    {secondaryPace.percent}% until {secondaryPace.until}
                  </li>
                ) : (
                  <li>-</li>
                )
              )
            : null}
          {deliveryBufferPercent && <li>Delivery Buffer Percent: {deliveryBufferPercent}</li>}
        </ul>
      ) : (
        <span>-</span>
      )}
    </div>
  );
}

function TypePropertiesCell({ changes, action }: CellItemsProps): JSX.Element {
  if (changes && Array.isArray(changes)) {
    return (
      <>
        {changes.map(
          (
            {
              payload: {
                goal,
                preemptible,
                frontLoadPercent,
                secondaryPaceList,
                deliveryBufferPercent,
                url = null,
                video = null,
              },
            },
            index
          ) => {
            return (
              <TypePropertiesList
                key={`type-properties-${index}`}
                index={index}
                action={action}
                goal={goal}
                preemptible={preemptible}
                frontLoadPercent={frontLoadPercent}
                secondaryPaceList={secondaryPaceList}
                deliveryBufferPercent={deliveryBufferPercent}
                url={url}
                video={video?.id}
              />
            );
          }
        )}
      </>
    );
  }
  if (changes.payload && Array.isArray(changes.payload)) {
    return (
      <>
        {changes.payload.map((item: TypePropertiesListProps, index: number) => {
          return (
            <TypePropertiesList
              key={`order-oms-link-${index}`}
              index={index}
              action={action}
              goal={item.goal}
              preemptible={item.preemptible}
              frontLoadPercent={item.frontLoadPercent}
              secondaryPaceList={item.secondaryPaceList}
              deliveryBufferPercent={item.deliveryBufferPercent}
              url={item?.url}
              video={{ id: item?.video?.id }}
            />
          );
        })}
      </>
    );
  }

  return (
    <TypePropertiesList
      index={0}
      action={action}
      goal={changes.payload.goal}
      preemptible={changes.payload.preemptible}
      frontLoadPercent={
        changes.payload.hasOwnProperty('front-load-percent')
          ? `${changes.payload['front-load-percent']}`
          : changes.payload.frontLoadPercent
      }
      secondaryPaceList={changes.payload['secondary-pace-list']}
      deliveryBufferPercent={
        changes.payload.hasOwnProperty('delivery-buffer-percent')
          ? `${changes.payload['delivery-buffer-percent']}`
          : changes.payload.deliveryBufferPercent
      }
      url={changes.payload?.url || null}
      video={changes.payload?.video || null}
    />
  );
}

export default TypePropertiesCell;
