import './Metadata.scss';

import { IconRepeat } from '@hulu-react-style-components/icons';
import { TabNavigation } from '@hulu/react-style-components';
import type { Dispatch, SetStateAction } from 'react';
import React from 'react';

import type { FrequencyCapTypeV5 } from '../../../apis/graphql';
import type { ClearableFrequencyCap } from '../../../common/FrequencyCaps/FrequencyCaps';
import bem from '../../../utils/bem';
import { useFrequencyCapList } from './hooks';
import ViewabilityVendorsSection from './ViewabilityVendorsSection/ViewabilityVendorsSection';

const [block] = bem('metadata');
const [freqCapBlock, freqCapElement] = bem('frequency-cap');

export type MetadataProps = {
  stashedFrequencyCapList: ClearableFrequencyCap[];
  setStashedFrequencyCapList: Dispatch<SetStateAction<ClearableFrequencyCap[]>>;
  readonly?: boolean;
  withViewabilityVendor?: boolean;
  frequencyCapType?: FrequencyCapTypeV5;
  withUnlimitedTab?: boolean;
  isCoppaOrCaru?: boolean;
};

function Metadata({
  stashedFrequencyCapList,
  setStashedFrequencyCapList,
  readonly,
  withViewabilityVendor = true,
  withUnlimitedTab = true,
  frequencyCapType = 'AD',
  isCoppaOrCaru,
}: MetadataProps): JSX.Element {
  const { memorizedTabs, handleTabClick, frequencyCapList } = useFrequencyCapList({
    setStashedFrequencyCapList,
    stashedFrequencyCapList,
    withUnlimitedTab,
    frequencyCapType,
    readonly,
  });

  return (
    <div className={block()}>
      {withViewabilityVendor && <ViewabilityVendorsSection readonly={readonly} isCoppaOrCaru={isCoppaOrCaru} />}
      <div className={freqCapBlock()}>
        {withViewabilityVendor && (
          <span className={freqCapElement('header')}>
            <IconRepeat aria-label="repeat icon" />
            <h5>Frequency Cap</h5>
          </span>
        )}

        <TabNavigation
          className={freqCapElement('segmented-control')}
          selectedTab={frequencyCapList.length ? 'Capped' : 'Unlimited'}
          onTabClick={handleTabClick}
        >
          {memorizedTabs.map((tab) => tab)}
        </TabNavigation>
      </div>
    </div>
  );
}

export default Metadata;
