import './SelectableAdFormSectionWrapper.scss';

import { useField } from 'formik';
import { ControlledCheckbox } from 'mission-control-common-components';
import React from 'react';
import { Element } from 'react-scroll';

import Card from '../../../../../../../common/Card';
import type { Nullable } from '../../../../../../../models';
import bem from '../../../../../../../utils/bem';

const [block, element] = bem('selectable-ad-form-section-wrapper');

export type SelectableAdFormSectionWrapperProps = {
  title: string;
  sectionName: string;
  customTitle?: JSX.Element;
  children: JSX.Element;
  disabled?: boolean;
  error?: Nullable<string>;
};

const SelectableAdFormSectionWrapper = ({
  title,
  sectionName,
  customTitle,
  children,
  disabled,
  error,
}: SelectableAdFormSectionWrapperProps): JSX.Element => {
  const [, { value: selectedSections }, { setValue }] = useField<string[]>('selectedSections');

  const onToggleCheck = (): void => {
    if (selectedSections.includes(sectionName)) {
      setValue(selectedSections.filter((section) => section !== sectionName));
    } else {
      setValue([...selectedSections, sectionName]);
    }
  };

  return (
    <Element name={sectionName} className={block()} data-testid="form-section">
      <div className={element('checkbox-container')} onClick={onToggleCheck}>
        <ControlledCheckbox
          disabled={disabled}
          checked={selectedSections.includes(sectionName)}
          indeterminate={false}
          onChange={onToggleCheck}
        />
        {customTitle || <span className={element('title')}>{title}</span>}
      </div>
      {error && (
        <div data-testid="error-message" className={element('error')}>
          {error}
        </div>
      )}
      <Card>{children}</Card>
    </Element>
  );
};

export default SelectableAdFormSectionWrapper;
