import './TimezoneWithGMT.scss';

import React from 'react';

import { viewerLocalTimeLabel } from '../../configs';
import { getTimezoneWithGMT } from '../SelectTimeZone/utils';

type Props = {
  timezone: string;
  deliveryInViewerTime?: boolean;
};

function TimezoneWithGMT({ timezone, deliveryInViewerTime = false }: Props): JSX.Element {
  return (
    <span className="timezone" data-testid="timezone">
      {deliveryInViewerTime ? viewerLocalTimeLabel : `Timezone: ${getTimezoneWithGMT(timezone)}`}
    </span>
  );
}

export default TimezoneWithGMT;
