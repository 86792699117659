import './DrawerTargetingTable.scss';

import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { User } from 'mission-control-common-components';
import React, { useMemo, useState } from 'react';

import Loader from '../../../common/Loader';
import type { PermissionsNames } from '../../../constants';
import bem from '../../../utils/bem';
import { getPublisherTargetingList } from '../TraffickingPage/columns';
import type { EntityType } from '../TraffickingPage/hooks/makePageDrawerPlugin';
import DrawerTargetingBody from './Body';
import DrawerTargetingHeader from './Header/DrawerTargetingHeader';
import { useFetchTargeting, useUpdateTargeting } from './hooks';
import type { DrawerTargetingFormValues } from './hooks/types/formValues';
import {
  addCountryTargetLineItem,
  filterOutCountryTarget,
  getCountryTargetLineItem,
  getCountryTargetName,
  getPublisherTargetLineItem,
} from './utils/utils';

const [block, element] = bem('drawer-targeting-table');

interface Props {
  id?: string;
  entityType: EntityType.AD | EntityType.LINE_ITEM;
}

function DrawerTargetingTable({ id, entityType }: Props): JSX.Element {
  const permissions = User.getPermissions<PermissionsNames>();
  const [editMode, setEditMode] = useState(false);

  const { adTermValues, displayPublisherTarget, lineItemTermValues, isFetching, targetingRule } = useFetchTargeting({
    entityType,
    id,
  });

  const { isUpdating, submitForm } = useUpdateTargeting({
    entityType,
    id,
    onSubmitSuccessful: (): void => setEditMode(false),
  });

  const lineItemTermValuesNoCountry = filterOutCountryTarget({ value: lineItemTermValues });
  const countryLineItem = getCountryTargetLineItem({ value: lineItemTermValues });

  const formikInitialValues: DrawerTargetingFormValues = useMemo(
    () => ({ adTargetingTermValues: adTermValues, lineItemTargetingTermValues: lineItemTermValuesNoCountry }),
    [adTermValues, lineItemTermValuesNoCountry]
  );

  const hasAccessToEdit = entityType === 'Ad' ? permissions?.updateAd : permissions?.updateLineItem;

  if (isFetching) return <Loader text="Loading..." />;

  function submitFormWithCountry(
    values: DrawerTargetingFormValues,
    actions: FormikHelpers<DrawerTargetingFormValues>
  ): void {
    const valuesWithCountry = addCountryTargetLineItem(values, countryLineItem);
    submitForm(valuesWithCountry, actions, targetingRule);
  }

  return (
    <div className={block()}>
      <Formik initialValues={formikInitialValues} onSubmit={submitFormWithCountry} enableReinitialize>
        {({ submitForm, resetForm, isSubmitting }): JSX.Element => (
          <div>
            <DrawerTargetingHeader
              editMode={editMode}
              disableSubmitting={isSubmitting || isUpdating}
              canEdit={hasAccessToEdit}
              setEditMode={setEditMode}
              onSubmit={submitForm}
              onCancel={resetForm}
              loading={isUpdating}
            />
            <div className={element('country')}>Country: {getCountryTargetName({ value: lineItemTermValues })}</div>
            <div className={element('publisher')}>
              Publisher: {getPublisherTargetingList({ value: displayPublisherTarget })}
            </div>
            <DrawerTargetingBody
              editMode={editMode}
              entityType={entityType}
              countryLineItem={countryLineItem || undefined}
              publisherLineItem={getPublisherTargetLineItem(displayPublisherTarget) || undefined}
            />
          </div>
        )}
      </Formik>
    </div>
  );
}

export default DrawerTargetingTable;
