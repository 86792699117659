import './VastPreview.scss';

import { Label } from '@hulu/react-style-components';
import React from 'react';

import { TagInput } from '../../common/Form';
import bem from '../../utils/bem';
import type { TagInputProps } from '../Form/TagInput';

const [block, element] = bem('vast-preview');

interface VastPreviewProps extends TagInputProps {}

const VastPreview = (props: VastPreviewProps): JSX.Element => {
  return (
    <div className={block()}>
      <TagInput {...props} />
      <Label>VAST/VPAID Creative</Label>
      <div className={element('creative-card')}>
        <p className={element('save-prompt')}>Save the ad to generate a preview of this VAST log.</p>
      </div>
    </div>
  );
};

export default VastPreview;
