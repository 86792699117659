import React from 'react';
import type { ColumnInstance, HeaderGroup } from 'react-table';

import SortArrow from '../SortArrow';

export type CommonTableHeader<RawObject extends {} = {}> = {
  /** An array of react-table HeaderGroups.  */
  headers: HeaderGroup<RawObject>[];
  /** A boolean that keeps the checkbox to the left. */
  checkboxSticky: boolean;
};

const headerToElement = <RawObject extends {} = {}>(
  { getHeaderProps, render, canSort, isSorted, isSortedDesc, getSortByToggleProps }: ColumnInstance<RawObject>,
  checkboxSticky: boolean
): JSX.Element => {
  const overrideObj: object & {
    className?: string;
  } = {};
  const headerKey = getHeaderProps().key.toString();

  if (checkboxSticky && headerKey.includes('checkbox')) {
    overrideObj.className = 'sticky';
  }

  return (
    <th {...{ ...getHeaderProps(getSortByToggleProps ? getSortByToggleProps() : undefined), ...overrideObj }}>
      <div className={isSorted ? 'header-cell-sorted' : 'header-cell'}>
        {render('Header')}
        {canSort && <SortArrow isSorted={isSorted} isDescending={isSortedDesc} />}
      </div>
    </th>
  );
};

const headerGroupToElement = <RawObject extends {} = {}>(
  { getHeaderGroupProps, headers }: HeaderGroup<RawObject>,
  checkboxSticky: boolean
): JSX.Element => {
  return (
    <tr {...getHeaderGroupProps()}>
      {headers.map((headerContext) => headerToElement({ ...headerContext }, checkboxSticky))}
    </tr>
  );
};

const CommonHeader = <RawObject extends {} = {}>({
  headers,
  checkboxSticky,
}: CommonTableHeader<RawObject>): JSX.Element => {
  return <thead>{headers.map((headerGroup) => headerGroupToElement({ ...headerGroup }, checkboxSticky))}</thead>;
};

export default CommonHeader;
