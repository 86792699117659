import { gql } from '@apollo/client';

export const ADS_ROTATIONS_FRAGMENT = gql`
  fragment adsRotations on AdV5 {
    id
    name
    weight
    startDate
    endDate
    adRotation {
      weight
      rotationName
      adId
      rotationId
    }
    targetingTermValues {
      category {
        id
        name
        displayName
      }
      dimension {
        id
        name
        displayName
      }
      value {
        id
        name
        displayName
      }
      include
    }
    schedule {
      timezone
      dateRangeList {
        startDate
        endDate
        pacingShare
      }
      dayPartList {
        lastActiveHour
        firstActiveHour
        activeDayList
      }
      deliveryInViewerTime
    }
    lineItem {
      id
      schedule {
        timezone
        dateRangeList {
          startDate
          endDate
          pacingShare
        }
        dayPartList {
          firstActiveHour
          lastActiveHour
          activeDayList
        }
        deliveryInViewerTime
      }
    }
  }
`;
