import type { ScheduleV5 } from '../../apis/graphql';
import type { DateRange, Nullable } from '../../models';
import { formatStringDate, isValidDate } from '../../utils/formatting';

const getFormattedDate = (date: Nullable<string>): string =>
  date && isValidDate(new Date(date)) ? formatStringDate(date, true) : '';

const getSingleDateRangeInfo = (range: DateRange): string => {
  const start = getFormattedDate(range.startDate);
  const end = getFormattedDate(range.endDate);

  if (range.endDate) return `This line item runs between ${start} and ${end}.`;

  return `This line item runs from ${start}.`;
};

export const formatMultiplyDateRanges = (range: DateRange, separator: string = ' - '): string => {
  const start = getFormattedDate(range.startDate);
  const end = getFormattedDate(range.endDate) || '∞';

  return `${start}${separator}${end}`;
};

/**
 * Generate text displaying the Line Item's date range
 */
export const getLineItemIntervalMessage = (dateRangeList: DateRange[] = []): string => {
  if (dateRangeList.length === 0) return '';

  if (dateRangeList.length === 1) return getSingleDateRangeInfo(dateRangeList[0]);

  return `This line item runs in ranges ${dateRangeList
    .map((dateRange) => formatMultiplyDateRanges(dateRange))
    .join(', ')}.`;
};

export const lineItemHasNoEndDate = (lineItemSchedule?: Nullable<ScheduleV5>): boolean => {
  return !!lineItemSchedule?.dateRangeList.find((range) => range.endDate === null);
};
