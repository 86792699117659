import * as color from './colors';
import * as constant from './constants';
import * as font from './fonts';
// TODO: Placeholder until mixins are converted
// import * as mixins from './mixins'
import * as util from './util';
import * as zIndex from './zIndices';

export const theme = {
  color,
  constant,
  font,
  // mixins,
  util,
  zIndex,
};
