import { useEffect } from 'react';

import type { AdTypeV5 } from '../../../../apis/graphql';
import { isCreativeTemplatePermitted } from '../../../../configs';
import { openToastAlert } from '../../../ToastAlert/toastAlert';
import type { SetFieldValueFunction } from '../../adFormik';
import { getInitialCreativeBuilderValues } from '../../adFormik';
import { AdFormFields } from '../../adFormik/fields';
import { getTemplateMismatchAlertBody } from '../../validation/utils';
import { useGetCreativeById } from './useGetCreativeById';
import { getTransformedSearchedCreative } from './utils';

/**
 * This hook creates a lazy getCreativeById query which can be executed to fetch a creative.
 * It also creates a side effect to update the creative object in the AdForm when the query resolves
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useUpdateCreativeForm = (adType: AdTypeV5, setFieldValue: SetFieldValueFunction) => {
  const { getCreativeByID, results: fetchedCreative, error } = useGetCreativeById();

  // Update the CreativeBuilder values in AdForm if getCreativeById is called
  useEffect(() => {
    if (fetchedCreative) {
      if (isCreativeTemplatePermitted(adType, fetchedCreative.template)) {
        setFieldValue(AdFormFields.Creative, getTransformedSearchedCreative(adType, fetchedCreative));
      } else {
        openToastAlert(getTemplateMismatchAlertBody(adType, fetchedCreative.template!));
      }
    }

    if (error) {
      setFieldValue('creative', { ...getInitialCreativeBuilderValues('search') });
    }
  }, [adType, error, fetchedCreative, setFieldValue]);

  return { getCreativeByID, error };
};
