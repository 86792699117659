import './AlertCell.scss';

import { HuluIcons } from '@hulu/react-style-components';
import type { FC } from 'react';
import React, { memo } from 'react';

import Tooltip from '../../../../../common/Tooltip';
import bem from '../../../../../utils/bem';

const { IconFlagSolid } = HuluIcons;

export enum AlertFiled {
  ZeroDelivery = 'zero-delivery',
  MisalignedSchedule = 'misaligned-schedule',
  InventoryBlock = 'inventory-block',
  LineItem = 'line-item',
}

export type AlertFlagProps = {
  id: string;
  field: AlertFiled;
  icon?: React.JSX.Element;
  hoverMessage?: string;
  isShown: boolean;
  className?: string;
};

type Props = {
  flags: AlertFlagProps[];
};

const AlertCell: FC<Props> = ({ flags }) => {
  const [block, element] = bem('alert-cell');

  return (
    <div className={block()}>
      {flags
        .filter((flag) => flag.isShown)
        .map(({ id, field, hoverMessage, icon, className }) => {
          return (
            <div className={className} key={`alert-${id}-${field}`}>
              <Tooltip
                delayShow={300}
                delayHide={200}
                id={`alert-tooltip-${id}-${field}`}
                message={hoverMessage || ''}
                disable={!hoverMessage}
              >
                {icon ? (
                  icon
                ) : (
                  <IconFlagSolid
                    data-testid={`iconFlag-${id}-${field}`}
                    width={18}
                    height={18}
                    className={element('default-flag')}
                  />
                )}
              </Tooltip>
            </div>
          );
        })}
    </div>
  );
};

const comparison = (prev: Props, next: Props): boolean =>
  prev.flags.filter((item) => item.isShown).length === next.flags.filter((item) => item.isShown).length;

export default memo(AlertCell, comparison);
