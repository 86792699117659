import { datadogRum } from '@datadog/browser-rum';
import envConfig from '@hulu/env-config';

datadogRum.init({
  applicationId: envConfig.REACT_APP_DATADOG_APPLICATION_ID || '',
  clientToken: envConfig.REACT_APP_DATADOG_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: 'mission-control',
  env: envConfig.REACT_APP_DATADOG_ENV || '',
  version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
  allowedTracingUrls: [
    ...(envConfig.REACT_APP_CAMPAIGN_GRAPHQL_ORIGIN ? [envConfig.REACT_APP_CAMPAIGN_GRAPHQL_ORIGIN] : []),
    ...(envConfig.REACT_APP_CAMPAIGN_MANAGER_ORIGIN ? [envConfig.REACT_APP_CAMPAIGN_MANAGER_ORIGIN] : []),
    ...(envConfig.REACT_APP_CAMPAIGN_SEARCHER_ORIGIN ? [envConfig.REACT_APP_CAMPAIGN_SEARCHER_ORIGIN] : []),
    ...(envConfig.REACT_APP_ASSET_MANAGER_ORIGIN ? [envConfig.REACT_APP_ASSET_MANAGER_ORIGIN] : []),
    ...(envConfig.REACT_APP_CREATIVE_MANAGER_ORIGIN ? [envConfig.REACT_APP_CREATIVE_MANAGER_ORIGIN] : []),
    ...(envConfig.REACT_APP_INVENTORY_GRAPHQL_API ? [envConfig.REACT_APP_INVENTORY_GRAPHQL_API] : []),
  ],
});

datadogRum.startSessionReplayRecording();
