import './PercentageInput.scss';

import type { ChangeEvent } from 'react';
import React, { memo } from 'react';

import bem from '../../utils/bem';

type PercentageInputProps = {
  value: number;
  onChange: (newValue: number) => void;
};

const [block, element] = bem('percentage-input');

function PercentageInput({ value, onChange }: PercentageInputProps): JSX.Element {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();

    let numberValue = e.target?.value ? parseInt(e.target?.value) : 0;
    numberValue = numberValue < 100 ? numberValue : 100;
    onChange(numberValue);
  };

  return (
    <div className={block()} data-testid="percentage-input-block">
      <input
        data-testid="percentage-input-field"
        value={value}
        onChange={onChangeHandler}
        className={element('input')}
        type="text"
        pattern="[0-9]"
      />
      <span className={element('percentage_mark')}>%</span>
    </div>
  );
}

export default memo(PercentageInput);
