import axios from 'axios';

interface MSTRTokenSuccessResponse {
  payload: string;
  error: null;
}

interface MSTRTokenErrorResponse {
  payload: null;
  error: string;
}

export const fetchMSTRToken = async (): Promise<MSTRTokenSuccessResponse | MSTRTokenErrorResponse> => {
  try {
    // Make a GET request to localhost:80/mstr_token
    const response = await axios.get('/mstr_token');
    const { status, data } = response;

    // If the request worked, extract the session token and return it
    if (status === 200 && data) {
      return {
        payload: data.token,
        error: null,
      };
    }

    // If the request returned successfully, but was not a 200 (OK), return with an error
    return {
      payload: null,
      error: data.error,
    };
    // If there was an error and we cannot gather error information from the response (e.g. network error), return the error message
  } catch (error) {
    return {
      payload: null,
      error: 'Failed to fetch MSTR Token',
    };
  }
};
