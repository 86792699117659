import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';

import type {
  PopulateTargetingValuesInput,
  PopulateTargetingValuesQuery,
  PopulateTargetingValuesQueryVariables,
} from '../../../../apis/graphql';
import { POPULATE_TARGETING_VALUES } from './queries/populateTargetingValues';

export type UsePopulateTargetingValuesResponse = {
  loading: boolean;
  error?: ApolloError;
  termsWithValues?: PopulateTargetingValuesQuery['populateTargetingValues'];
};

const usePopulateTargetingValues = (termList: PopulateTargetingValuesInput[]): UsePopulateTargetingValuesResponse => {
  const { loading, data, error } = useQuery<PopulateTargetingValuesQuery, PopulateTargetingValuesQueryVariables>(
    POPULATE_TARGETING_VALUES,
    {
      variables: {
        termList,
      },
      skip: !termList.length,
    }
  );

  return {
    loading,
    error,
    termsWithValues: data?.populateTargetingValues,
  };
};

export default usePopulateTargetingValues;
