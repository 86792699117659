import { gql } from '@apollo/client';

export const CREATIVE_METADATA = gql`
  fragment creativeMetadata on CreativeMetadata {
    creativeId
    createdAt
    updatedAt
    countryCodes
    deleted
    isLegalApproved
  }
`;
