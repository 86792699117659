import { compact as _compact, flatten as _flatten, groupBy as _groupBy } from 'lodash';

import type { AdsPageAdFragment, ScheduleV5, TargetingRule } from '../../../../apis/graphql';
import {
  convertAdRating,
  convertAdStatus,
  convertAdTagEvent,
  convertAdTagType,
  convertAdType,
  convertFrequencyCapUnitType,
  convertReview,
  convertViewabilityVendor,
} from '../../../../configs';
import type { AdTag, FrequencyCap, Nullable, TargetingTermValue } from '../../../../models';
import { defaultSupportedTimezones } from '../../../commonMocks/timezones';
import type { AdsPageAd } from '../types';
import { creativeFragmentToBuilderValues } from './creativeBuilderCreativeConverter';

export type NonBillableTagsGrouping = {
  iasTags: AdTag[];
  nonIasTags: AdTag[];
};

export const DEFAULT_SCHEDULE_OBJECT: ScheduleV5 = {
  timezone: defaultSupportedTimezones.ET,
  dateRangeList: [],
  dayPartList: [],
  deliveryInViewerTime: false,
};

export const convertAdTag = (tag: AdsPageAdFragment['adTagList'][number]): AdTag => {
  return {
    id: tag.id,
    url: tag.url,
    type: convertAdTagType(tag.type),
    event: convertAdTagEvent(tag.event),
    isVerified: tag.isVerified,
    unDeletable: tag.unDeletable,
    isIas: tag.isIas,
  };
};

export const getNonBillableTagDictionaryKey = (event: string, isIas: boolean | null): string => {
  const isIasSuffix = isIas ? 'IAS' : 'NON_IAS';

  return event + '_' + isIasSuffix;
};

/**
 * On page load, Ad Tags should be displayed in the following order:
 * BILLABLE Tag - an Ad should have one at most
 * IAS tags
 * NON_BILLABLE Tags with START event
 * NON_BILLABLE Tags with FIRSTQUARTILE event
 * NON_BILLABLE Tags with MIDPOINT event
 * NON_BILLABLE Tags with THIRDQUARTILE event
 * NON_BILLABLE Tags with COMPLETE event
 * NON_BILLABLE Tags with an unrecognized event
 * CLICK Tag - an Ad should have one at most
 * Tags with an unrecognized type
 */
export function getSortedAdTagList(adTagList: AdTag[]): AdTag[] {
  // Group tags by type
  const {
    BILLABLE: billableTags,
    NON_BILLABLE: nonBillableTags,
    CLICK: clickTags,
    UNRECOGNIZED: tagsWithUnrecognizedType,
  } = _groupBy(adTagList, (adTag) => adTag.type.key);

  // Group Non-Billable tags by event
  const nonBillableTagsByEvent = _groupBy(nonBillableTags, (adTag) =>
    getNonBillableTagDictionaryKey(adTag.event.key, adTag.isIas)
  );

  // Place tags in order, flatten the group list, and remove any undefined groups
  return _compact(
    _flatten([
      billableTags,
      nonBillableTagsByEvent.START_IAS,
      nonBillableTagsByEvent.FIRSTQUARTILE_IAS,
      nonBillableTagsByEvent.MIDPOINT_IAS,
      nonBillableTagsByEvent.THIRDQUARTILE_IAS,
      nonBillableTagsByEvent.COMPLETE_IAS,
      nonBillableTagsByEvent.UNRECOGNIZED_IAS,
      nonBillableTagsByEvent.START_NON_IAS,
      nonBillableTagsByEvent.FIRSTQUARTILE_NON_IAS,
      nonBillableTagsByEvent.MIDPOINT_NON_IAS,
      nonBillableTagsByEvent.THIRDQUARTILE_NON_IAS,
      nonBillableTagsByEvent.COMPLETE_NON_IAS,
      nonBillableTagsByEvent.UNRECOGNIZED_NON_IAS,
      clickTags,
      tagsWithUnrecognizedType,
    ])
  );
}

export const getSortedAdTagsFromFragment = (adTagList: AdsPageAdFragment['adTagList']): AdTag[] => {
  const adTagModels = adTagList.map(convertAdTag);

  return getSortedAdTagList(adTagModels);
};

export const getAdSchedule = (
  adSchedule: Nullable<ScheduleV5>,
  lineItemSchedule?: Nullable<ScheduleV5>
): ScheduleV5 => {
  if (adSchedule) return adSchedule;
  if (lineItemSchedule)
    return {
      ...lineItemSchedule,
      dateRangeList: lineItemSchedule.dateRangeList.map((range) => ({
        ...range,
        pacingShare: null,
      })),
    };

  return DEFAULT_SCHEDULE_OBJECT;
};

const convertFrequencyCap = (fc: AdsPageAdFragment['frequencyCapList'][number]): FrequencyCap => {
  const { type, limit, enabled, duration, durationUnit } = fc;
  return {
    type,
    limit,
    enabled: !!enabled,
    duration: duration || 0,
    durationUnit: convertFrequencyCapUnitType(durationUnit),
  };
};

export function adsPageAdNodeToModel(node: AdsPageAdFragment): AdsPageAd {
  const {
    type,
    status,
    review,
    rating,
    creative,
    frequencyCapList,
    adTagList,
    targetingTermValues,
    targetingRule,
    viewabilityVendorList,
    schedule,
    lineItem,
    adRotation,
  } = node;

  return {
    ...node,
    lineItem: {
      id: lineItem?.id || '',
      schedule: lineItem?.schedule || null,
      parentLineOmsLink: lineItem?.parentLineOmsLink || null,
    },
    type: convertAdType(type),
    status: convertAdStatus(status),
    rating: convertAdRating(rating),
    review: convertReview(review),
    creative: creative ? creativeFragmentToBuilderValues(creative) : null,
    dirty: false,
    frequencyCapList: frequencyCapList.map(convertFrequencyCap),
    adTagList: getSortedAdTagsFromFragment(adTagList),
    targetingTermValues: targetingTermValues as TargetingTermValue[],
    targetingRule: targetingRule as TargetingRule,
    viewabilityVendorList: viewabilityVendorList.map(convertViewabilityVendor),
    schedule: getAdSchedule(schedule, lineItem?.schedule),
    adRotation: adRotation || null,
  };
}
