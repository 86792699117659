import type { MultipleEditDrawerAdsFormValues } from '../../../../../../types';

type Props = {
  values: MultipleEditDrawerAdsFormValues;
  statusErrors?: string[];
};

type Result = {
  status?: {
    id: string;
    value?: string;
  }[];
};

export const getPreparedInputForAdStatuses = ({ values, statusErrors }: Props): Result => {
  if (!values.selectedSections.includes('Status')) {
    return {};
  }

  const entitiesToBeChangedStatus = values.entities.filter((entity) => {
    const initialStatus = values.initialStatuses.find((initialEntity) => initialEntity.id === entity.id)?.status?.key;

    return initialStatus !== entity.status?.key;
  });

  const entitiesWithoutConflict = statusErrors
    ? entitiesToBeChangedStatus.filter((entity) => !statusErrors.includes(entity.id))
    : entitiesToBeChangedStatus;

  return entitiesWithoutConflict.length
    ? {
        status: entitiesWithoutConflict.map((entity) => ({
          id: entity.id,
          value: entity.status?.key,
        })),
      }
    : {};
};
