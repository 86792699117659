import React from 'react';

type Props = {
  title: string;
  children: React.ReactNode;
};

const DuplicatedAdStepWrapper = ({ title, children }: Props): JSX.Element => {
  return (
    <>
      <h5 className="duplicate-ad-form__title" data-testid="title">
        {title}
      </h5>
      {children}
    </>
  );
};

export default DuplicatedAdStepWrapper;
