import type { TraffickingTableName } from '../../constants';
import bem from '../../utils/bem';
import { workWithLocalStorage } from '../../utils/storage/localStorage';
import { StorageEntity } from '../../utils/storage/storageEntity';
import type { ResizingStorageType, ResizingValueType } from './types';

export const [stickyBlock] = bem('sticky-cell');

export const [resizeSeparator] = bem('resize-separator');

enum StorageKeys {
  resizing = 'LOCAL_STORAGE_RESIZING_DATA_KEY',
  hiddenColumns = 'LOCAL_STORAGE_HIDDEN_COLUMNS_DATA_KEY',
  columnsOrder = 'LOCAL_STORAGE_COLUMNS_ORDER_DATA_KEY',
  sequence = 'LOCAL_STORAGE_SEQUENCE_DATA_KEY',
  visibleInitiallyHiddenColumns = 'LOCAL_STORAGE_VISIBLE_INITIAL_HIDDEN_COLUMNS_DATA_KEY',
}

class LocalStorageTableResizing extends StorageEntity<ResizingStorageType> {
  getData(defaultWidth: number, tableName: TraffickingTableName, columnId: string): ResizingValueType {
    const storageData = super.getStorageData(tableName);

    return storageData?.[columnId] ?? defaultWidth;
  }

  setData(tableName: TraffickingTableName, columnId: string, width: ResizingValueType): void {
    const storageData = super.getStorageData(tableName);
    const data = { ...storageData, [`${columnId}`]: width };
    super.setStorageData(tableName, data);
  }
}

export const resizingData = new LocalStorageTableResizing(StorageKeys.resizing, workWithLocalStorage);
export const hiddenData = new StorageEntity<Record<TraffickingTableName, string[]>>(
  StorageKeys.hiddenColumns,
  workWithLocalStorage
);
export const orderData = new StorageEntity<Record<TraffickingTableName, string[]>>(
  StorageKeys.columnsOrder,
  workWithLocalStorage
);
export const sequenceData = new StorageEntity<Record<TraffickingTableName, string[]>>(
  StorageKeys.sequence,
  workWithLocalStorage
);
export const visibleInitialHiddenColumns = new StorageEntity<Record<TraffickingTableName, string[]>>(
  StorageKeys.visibleInitiallyHiddenColumns,
  workWithLocalStorage
);
