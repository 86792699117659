import { useField } from 'formik';
import { useCallback, useMemo, useState } from 'react';

import type { DisplayEnum, UNRECOGNIZED, ViewabilityVendor } from '../../../../configs';
import { viewabilityVendors } from '../../../../configs';
import type { AdTag } from '../../../../models';
import { useFieldArray } from '../../../Form/hooks';
import type { MultiSelectValue } from '../../../MultiSelectDropdown/MultiSelectDropdown';
import { getAdTagsWithIASTags, getViewabilityVendorValuesWithoutIAS } from '../utils';

type UseViewabilityVendors = {
  isIASSet: boolean;
  iasCampaignLineItemIdValue: string;
  handleChangeViewabilityVendor: (newValue: MultiSelectValue<ViewabilityVendor | typeof UNRECOGNIZED>) => void;
  handleChangeIASCampaignLineItemIdValue: (value: string) => void;
};

export const AD_TAG_LIST_FIELD = 'adTagList';
export const VIEWABILITY_VENDORS_LIST_FIELD_NAME = 'viewabilityVendorList';
export const IAS_CAMPAIGN_LINE_ITEM_ID_FIELD_NAME = 'iasCampaignLineItemId';

export const useViewabilityVendors = (): UseViewabilityVendors => {
  const [, { value: viewabilityVendorsValue }, { setValue: setViewabilityVendorsValue }] = useField<
    DisplayEnum<ViewabilityVendor>[]
  >(VIEWABILITY_VENDORS_LIST_FIELD_NAME);
  const [, { value: adTagList }, { setValue: setAdTagList }] = useFieldArray<AdTag>(AD_TAG_LIST_FIELD);
  const [stashedIASTags, setStashedIASTags] = useState<AdTag[]>([]);
  const [, { value: iasCampaignLineItemIdValue }, { setValue: setIASCampaignLineItemIdValue }] = useField<string>(
    IAS_CAMPAIGN_LINE_ITEM_ID_FIELD_NAME
  );

  const handleChangeIASCampaignLineItemIdValue = (value: string): void => {
    setIASCampaignLineItemIdValue(value);
    setAdTagList(getAdTagsWithIASTags(adTagList, value));
  };

  const isIASSet = useMemo(() => !!viewabilityVendorsValue.find((value) => value.key === viewabilityVendors.IAS.key), [
    viewabilityVendorsValue,
  ]);

  const setAdTagsWithIASStash = useCallback((): void => {
    const tagsWithoutIAS: AdTag[] = [];
    const IAStags: AdTag[] = [];

    adTagList.forEach((tag) => {
      if (tag.isIas) {
        IAStags.push(tag);
      } else {
        tagsWithoutIAS.push(tag);
      }
    });

    setAdTagList(tagsWithoutIAS);
    setStashedIASTags(IAStags);
  }, [setAdTagList, setStashedIASTags, adTagList]);

  const handleChangeViewabilityVendor = useCallback(
    (newValue: MultiSelectValue<ViewabilityVendor | typeof UNRECOGNIZED>): void => {
      const isIASSelected = newValue.find(({ value }) => value === viewabilityVendors.IAS.key) && !isIASSet;

      if (isIASSelected) {
        setViewabilityVendorsValue([viewabilityVendors.IAS]);

        setAdTagList(getAdTagsWithIASTags([...adTagList, ...stashedIASTags], iasCampaignLineItemIdValue));
      } else {
        setViewabilityVendorsValue(getViewabilityVendorValuesWithoutIAS(newValue));

        setAdTagsWithIASStash();
      }
    },
    [
      isIASSet,
      setAdTagList,
      setViewabilityVendorsValue,
      adTagList,
      stashedIASTags,
      iasCampaignLineItemIdValue,
      setAdTagsWithIASStash,
    ]
  );

  return {
    isIASSet,
    handleChangeViewabilityVendor,
    handleChangeIASCampaignLineItemIdValue,
    iasCampaignLineItemIdValue,
  };
};
