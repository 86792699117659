import { format } from 'date-fns';
import { detect } from 'detect-browser';

import type { DateRangeV5 } from '../../../../apis/graphql';
import { defaultSupportedTimezones } from '../../../../common/commonMocks/timezones';
import { getDefaultFrequencyCap } from '../../../../common/FrequencyCaps/FrequencyCaps';
import { adTagTypes } from '../../../../configs';
import type { AdWithTargetingsModel } from '../../TraffickingPage/modelConverters/adWithTargetingsConvertor';
import type { LineItemWithTargetingsModel } from '../../TraffickingPage/modelConverters/lineItemWithTargetingsConvertor';
import type { Steps } from './constants';
import { nullableDefaultRangeDate } from './constants';
import type { MultipleEditDrawerAdsFormValues, MultipleEditDrawerLineItemsFormValues } from './types';

export const isActiveStep = (activeStep: Steps, step: Steps): boolean => activeStep === step;

export const getMultipleEditDrawerLineItemsInitialValues = (
  lineItems: Array<LineItemWithTargetingsModel>
): MultipleEditDrawerLineItemsFormValues => ({
  entities: lineItems.map(({ id, name, status, subStatus, review, displayPublisherTarget, targetingTermValues }) => ({
    id,
    name,
    status: subStatus ?? status,
    isSelected: true,
    review,
    displayPublisherTarget,
    targetingTermValues,
  })),
  initialStatuses: lineItems.map(
    ({ id, name, status, subStatus, deliveryBufferPercent, frontLoadPercent, pacingType }) => ({
      id,
      name,
      status: subStatus ?? status,
      isSelected: true,
      buffer: {
        deliveryBufferPercent,
        frontLoadPercent,
      },
      pacingType,
    })
  ),
  targetingTermValues: [],
  buffer: {
    deliveryBufferPercent: null,
    frontLoadPercent: null,
  },
  priority: {
    priority: {
      name: '',
      rank: 0,
    },
    priorityValue: null,
    tier: null,
  },
  frequencyCapList: [getDefaultFrequencyCap('LINE_ITEM')],
  selectedSections: [],
});

export const getDefaultRangeEndDate = (): DateRangeV5 => {
  const dateValue = new Date();
  const formattedDateValue = format(dateValue, 'yyyy-MM-dd');
  const formattedStartDateTime = `${formattedDateValue}T00:00:00`;
  const formattedEndDateTime = `${formattedDateValue}T23:59:59`;
  const defaultRangeEndDate = {
    startDate: formattedStartDateTime,
    endDate: formattedEndDateTime,
    pacingShare: null,
  };

  return defaultRangeEndDate;
};

export const getDefaultDateRangeItem = (): DateRangeV5 => {
  const browser = detect();

  if (browser?.name !== 'safari') {
    return nullableDefaultRangeDate;
  }

  return getDefaultRangeEndDate();
};

export const getMultipleEditDrawerAdsInitialValues = (
  ads: Array<AdWithTargetingsModel>
): MultipleEditDrawerAdsFormValues => {
  const defaultDateRangeItem = getDefaultDateRangeItem();

  return {
    entities: ads.map(({ id, name, status, adTagList, review, lineItem }) => ({
      id,
      name,
      status,
      review,
      isSelected: true,
      adTagList,
      hasBillableTag: adTagList.some(({ type }) => type.key === adTagTypes.BILLABLE.key),
      hasClickTag: adTagList.some(({ type }) => type.key === adTagTypes.CLICK.key),
      lineItem: lineItem?.schedule
        ? {
            ...lineItem?.schedule,
            displayPublisherTarget: lineItem?.displayPublisherTarget,
            targetingTermValues: lineItem?.targetingTermValues,
          }
        : {
            start: null,
            end: null,
            dayPartList: null,
            dateRangeList: [],
            timezone: defaultSupportedTimezones.ET,
            deliveryInViewerTime: false,
            displayPublisherTarget: lineItem?.displayPublisherTarget,
            targetingTermValues: lineItem?.targetingTermValues,
          },
    })),
    initialStatuses: ads.map(({ id, name, status }) => ({
      id,
      name,
      status,
      isSelected: true,
    })),
    targetingTermValues: [],
    schedule: {
      dateRangeList: [defaultDateRangeItem],
      dayPartList: [],
      deliveryInViewerTime: false,
      timezone: defaultSupportedTimezones.ET,
    },
    adTagList: [],
    newAdTag: {
      url: '',
    },
    rating: '',
    selectedSections: [],
    isLastDateRangeDisabled: false,
  };
};
