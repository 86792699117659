import type { ApolloError, QueryLazyOptions } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';
import { useMemo } from 'react';

import type {
  AdsRotationsFragment,
  GetAdsRotationsQuery,
  GetAdsRotationsQueryVariables,
} from '../../../../apis/graphql';
import { getDataFromNodes } from '../../../../apis/graphql';
import { getAdSchedule } from '../../../../common/AdForm/hooks/modelConverters';
import type { TargetingTermValue } from '../../../../models/targetingTermValue';
import type { AdRotationInput } from '../../../Ads/AdList/utils/utils';
import { GET_ADS_ROTATIONS } from './queries/getAdsRotations';

type LazyFetchRotationsResponse = {
  error?: ApolloError;
  loading: boolean;
  loadAdRotations: (options?: QueryLazyOptions<GetAdsRotationsQueryVariables> | undefined) => void;
  adsRotations: AdRotationInput[];
};

function nodeToModel(node: AdsRotationsFragment): AdRotationInput {
  const { schedule, adRotation, lineItem, targetingTermValues } = node;

  return {
    ...node,
    schedule: getAdSchedule(schedule, lineItem?.schedule),
    adRotation: adRotation || null,
    adSequence: null, // ads in a rotation do not have a sequence value
    targetingTermValues: targetingTermValues as TargetingTermValue[],
  };
}

export const useLazyFetchAdRotations = (lineItemId: string): LazyFetchRotationsResponse => {
  const [loadAdRotations, { loading, error, data }] = useLazyQuery<GetAdsRotationsQuery, GetAdsRotationsQueryVariables>(
    GET_ADS_ROTATIONS,
    {
      variables: {
        lineItemIds: [lineItemId],
      },
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const adsRotations = useMemo<AdRotationInput[]>(getDataFromNodes(data, 'ads', nodeToModel), [data]);

  return {
    loadAdRotations,
    loading,
    error,
    adsRotations,
  };
};
