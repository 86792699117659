import { gql } from '@apollo/client';

export const GET_INDUSTRIES_V5 = gql`
  query GET_INDUSTRIES_V5($limit: Int, $offset: Int, $searchTerm: String) {
    getIndustriesV5(paginationOptions: { limit: $limit, offset: $offset }, filterOptions: { name: $searchTerm }) {
      edges {
        node {
          id
          name
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
