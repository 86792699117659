import type { MultipleEditDrawerLineItemsFormValues } from '../../../../../../types';
import type { LineItemStatusError } from '../../../MultipleEditDrawerContentThirdStep';

type Props = {
  values: MultipleEditDrawerLineItemsFormValues;
  statusErrors?: LineItemStatusError;
};

type Result = {
  status?: {
    id: string;
    value?: string;
  }[];
};

export const getPreparedInputForLineItemStatuses = ({ values, statusErrors }: Props): Result => {
  if (!values.selectedSections.includes('Status')) {
    return {};
  }

  const errorsLineItemsWithoutActiveAd = statusErrors?.lineItemsWithoutActiveAd ?? [];
  const errorsLineItemsWithoutApproved = statusErrors?.lineItemsWithoutApproved ?? [];

  const allConflictedIdsByStatus = [...errorsLineItemsWithoutActiveAd, ...errorsLineItemsWithoutApproved];

  const entitiesToBeChangedStatus = values.entities.filter((entity) => {
    const initialStatus = values.initialStatuses.find((initialEntity) => initialEntity.id === entity.id)?.status?.key;

    return initialStatus !== entity.status?.key;
  });
  const entitiesWithoutConflict = entitiesToBeChangedStatus.filter(
    (entity) => !allConflictedIdsByStatus.includes(entity.id)
  );

  return entitiesWithoutConflict.length
    ? {
        status: entitiesWithoutConflict.map((entity) => ({
          id: entity.id,
          value: entity.status?.key,
        })),
      }
    : {};
};
