import './DefaultPageHeader.scss';

import { IconArrowLeft } from '@hulu-react-style-components/icons';
import { useNavigate } from '@hulu/react-router-dom';
import { bem } from 'mission-control-common-components';
import React from 'react';

export interface AdsHeaderProps {
  children?: React.JSX.Element;
}

const [block, element] = bem('default-page-header');

const DefaultPageHeader = ({ children }: AdsHeaderProps): React.JSX.Element => {
  const navigate = useNavigate();

  return (
    <div className={block()}>
      <button className={element('back-icon')} onClick={(): void => navigate(-1)} aria-label="go back">
        <IconArrowLeft className={element('icon')} />
      </button>
      <header>{children}</header>
    </div>
  );
};

export default DefaultPageHeader;
