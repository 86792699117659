import { orderedDaysOfWeek } from '../../../../../utils/formatting';
import type { DayPartTimeRange, WeekDayRanges } from '../../../../TimesList/types';
import type { NullableDayPart } from '../../../types';

export const getWeekDaysRanges = (dayParts: NullableDayPart[]): WeekDayRanges =>
  orderedDaysOfWeek.reduce<WeekDayRanges>((daysObject, day) => {
    const dayRanges = [] as DayPartTimeRange[];

    dayParts.forEach((dayPart: NullableDayPart) => {
      if (dayPart?.activeDayList?.includes(day)) {
        dayRanges.push([dayPart.firstActiveHour || 0, dayPart.lastActiveHour || 0]);
      }
    });

    daysObject[day] = dayRanges;

    return daysObject;
  }, {});
