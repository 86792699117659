import type { IngestionTrial, LatestIngestionStatus } from '../../../../apis/graphql';
import { AdTypeV5, CountryCode } from '../../../../apis/graphql';
import type { AssetExtended } from '../../../../common/_ForCommonLibrary/types/extended';
import type { AdFormValues } from '../../adFormik';
import { getInitialValues } from '../../adFormik';
import type { CreativesBuilderValues } from '../types';

export const mockAssetId = 'a5cc6782c1011976bba0f028fdb5536e';

/** Returns a mock creative; the creative's values can be overridden */
export const getMockCreative = (creative?: Partial<CreativesBuilderValues>): CreativesBuilderValues => ({
  id: '123-abc',
  name: 'Mock Creative',
  type: 'VAST_VIDEO',
  mode: 'build',
  videoAssetList: [{ id: mockAssetId }],
  url: 'https://www.mock.com',
  review: 'APPROVED',
  ...creative,
});

/** Returns initial form values with an attached creative; the creative's values can be overridden */
export const getMockFormValues = (creative?: Partial<CreativesBuilderValues>): AdFormValues => ({
  ...getInitialValues({ type: AdTypeV5.Video }),
  creative: getMockCreative(creative),
});

/** Returns a mock asset; the asset's values can be overridden */
export const getMockAsset = (asset?: Partial<AssetExtended>): AssetExtended => ({
  assetBrandList: [],
  assetTagList: [{ id: 'id', value: 'NONE', description: 'description', displayName: 'displayName' }],
  comments: null,
  countryCodes: [CountryCode.Us],
  createdAt: '2022-09-27T19:09:55Z',
  creativeLibraryId: 'abc123',
  deleted: false,
  duration: 1516000,
  externalAssetDto: '',
  filename: 'ST-video.mp4',
  fileSize: 48390229,
  id: mockAssetId,
  language: 'ENGLISH',
  mediaMetadata: null,
  md5: 'a5cc6782c1011976bba0f028fdb5536e',
  name: 'ST_asset_1',
  outletKey: '12345',
  publisher: 'DISNEY_PLUS',
  rating: 'MATURE',
  rejectionReasons: null,
  review: 'APPROVED',
  s3Url: 'https://hulu-adsassetmanager-dev-ads-asset-manager.s3.us-west-2.amazonaws.com/ST-video-1664305794.mp4',
  transcodeFailureDetails: null,
  transcodeStatus: 'PRE_PROCESSING_COMPLETE',
  type: 'VIDEO',
  updatedAt: '2022-10-13T14:07:43Z',
  ...asset,
});

/** Returns a mock IngestionTrial; the trial requires an AD, and its other values can be overridden */
export const getMockIngestionTrial = (overrides?: Partial<IngestionTrial>): IngestionTrial => ({
  assetKey: 'a5cc6782c1011976bba0f028fdb5536e',
  assetId: mockAssetId,
  mediaFileUrl: 'https://hulu-adsassetmanager-dev-ads-asset-manager.s3.us-west-2.amazonaws.com/ST-video-1664305794.mp4',
  s3Url: 'https://ads-asset-manager-qa-ods.aor.staging.hulu.com/v4/assets/download/7cd264a67fcab20e64d2118dcb432b5c',
  trialStatus: 'INGESTION_COMPLETE',
  detail: '',
  updatedAt: '2001-01-01T01:01:01Z',
  ...overrides,
});

/** Returns a mock LatestIngestionStatus; its values can be overridden */
export const getMockLatestIngestionStatus = (overrides?: Partial<LatestIngestionStatus>): LatestIngestionStatus => ({
  jobStatus: 'COMPLETE',
  assetStatus: [],
  ...overrides,
});
