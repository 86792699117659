import type { ApolloError, MutationResult } from '@apollo/client';
import type { GraphQLError } from 'graphql';
import { difference, invert as _invert } from 'lodash';
import type { Dispatch, SetStateAction } from 'react';
import type { IdType, Row, SortingRule } from 'react-table';

import type { TraffickingFilters } from '../../../../apis/graphql';
import { getErrorMessageFromGraphQlErrors, OrderOption } from '../../../../apis/graphql';
import type { EditableInputVariant } from '../../../../common/EditableTableCells/EditableCell/EditableCell';
import { getGraphQLErrorExtensionsMessage } from '../../../../common/utils';
import { TraffickingTableName } from '../../../../constants';
import type { NonNullableFields, Nullable } from '../../../../models';
import { NEW_SEQUENCE_LABEL } from '../../../Sequence/constants';
import type { CustomColumnConfig } from '../columns';
import type { AdModel, CampaignModel, EntityModel, LineItemModel } from '../modelConverters';
import { LINE_ITEMS_STATUSES } from './constants';
import type { GroupedData, GroupedRows, UseGroupedTraffickerDataResult } from './useGroupedTraffickerData';
import type { SelectedRowIds } from './useTraffickerState';
import { sortOptionMap } from './useTraffickerState';
import type { TraffickerTableState } from './useTraffickerTable';

export const PARENT_LINE_OMS_LINK_ID_TITLE = 'Parent Line Oms Link Id';
export const STANDALONE_LINE_ITEMS_TITLE = 'Standalone Line Items';
export const SEQUENCE_NAME_TITLE = 'Sequence Name';
export const UNSEQUENCED_ADS_TITLE = 'Unsequenced Ads';

export const DEFAULT_UNGROUPED_ID = 'UNGROUPED_';

export const getSelectedIdsMatchedSearchTerm = <Model extends EntityModel>(
  selectedFlatRows: Row<Model>[],
  searchTerm: string
): SelectedRowIds => {
  const matchedFlatRows = selectedFlatRows.filter(({ original }) => {
    const { id, name, trackingId } = original;

    return name.toLowerCase().includes(searchTerm.toLowerCase()) || id === searchTerm || trackingId === searchTerm;
  });

  const matchedSelectedIds: SelectedRowIds = {};

  matchedFlatRows.length &&
    matchedFlatRows.forEach(({ original }) => {
      matchedSelectedIds[original.id as keyof typeof matchedSelectedIds] = true;
    });

  return matchedSelectedIds;
};

export type SortOption<T extends TraffickingTableName> = NonNullable<TraffickingFilters[T]>['sortBy'];

export const isValidSortOption = <T extends TraffickingTableName>(
  tableName: T,
  sortOption?: Nullable<SortOption<T>>
): sortOption is NonNullableFields<NonNullable<SortOption<TraffickingTableName>>> => {
  if (!sortOption || !sortOption.sort || !sortOption.order) return false;

  return Object.values(sortOptionMap[tableName]).includes(sortOption.sort);
};

export const prepareSortOption = <T extends TraffickingTableName>(
  tableName: T,
  sortOption?: SortingRule<SortOption<T>>
): SortOption<T> => {
  if (!sortOption || !sortOptionMap[tableName][sortOption.id]) return null;

  const order = sortOption.desc ? OrderOption.Desc : OrderOption.Asc;
  const sort = sortOptionMap[tableName][sortOption.id];

  return {
    order,
    sort,
  } as SortOption<T>;
};

export const prepareSortOptionsForTable = <T extends TraffickingTableName>(
  tableName: T,
  sortOption?: SortOption<T>
): SortingRule<string>[] => {
  if (!sortOption) return [];

  const sortKeysMap = _invert(sortOptionMap[tableName]);

  return [{ id: sortKeysMap[sortOption.sort as string], desc: sortOption.order === OrderOption.Desc }];
};

export const sortLineItemStatusesAndSubStatuses = (
  lineItemStatusListFilter: string[]
): {
  filteredStatuses: string[];
  filteredSubStatuses: string[];
} => {
  const lineItemStatuses = Object.values(LINE_ITEMS_STATUSES);
  const filteredStatuses: string[] = [];
  const filteredSubStatuses: string[] = [];

  lineItemStatusListFilter.forEach((filteredStatus) => {
    if (lineItemStatuses.some((status) => status === filteredStatus)) {
      filteredStatuses.push(filteredStatus);
    } else {
      filteredSubStatuses.push(filteredStatus);
    }
  });

  return {
    filteredStatuses,
    filteredSubStatuses,
  };
};

export const isRowQuantityBigger = (prevSelectedRowsQuantity: number, newSelectedRowsQuantity: number): boolean =>
  prevSelectedRowsQuantity > newSelectedRowsQuantity;

export const getUnselectedIds = (prevSelectedItems: string[], newSelectedItems: string[]): string[] =>
  difference(prevSelectedItems, newSelectedItems);

export const getDifferenceFromSelectedEntities = (
  selectedEntitiesIds: string[],
  associatedEntitiesIds: string[]
): string[] =>
  selectedEntitiesIds.filter((id) => associatedEntitiesIds.findIndex((entityId) => entityId === id) === -1);

export const getRepresentedInSelectedEntities = (
  selectedEntitiesIds: string[],
  associatedEntitiesIds: string[]
): string[] => selectedEntitiesIds.filter((entityId) => associatedEntitiesIds.includes(entityId));

type UpdatedEntity = {
  previous: string[];
  current: string[];
};

export const getDeselectedEntities = (
  campaignsEntity: UpdatedEntity,
  lineItemsEntity: UpdatedEntity
): Nullable<UpdatedEntity & { tableName: TraffickingTableName }> => {
  if (isRowQuantityBigger(campaignsEntity.previous.length, campaignsEntity.current.length)) {
    return { ...campaignsEntity, tableName: TraffickingTableName.campaigns };
  }

  if (isRowQuantityBigger(lineItemsEntity.previous.length, lineItemsEntity.current.length)) {
    return { ...lineItemsEntity, tableName: TraffickingTableName.lineItems };
  }

  return null;
};

export const getSelectedEntities = (
  campaignsEntity: UpdatedEntity,
  lineItemsEntity: UpdatedEntity
): Nullable<UpdatedEntity & { tableName: TraffickingTableName }> => {
  if (isRowQuantityBigger(campaignsEntity.current.length, campaignsEntity.previous.length)) {
    return { ...campaignsEntity, tableName: TraffickingTableName.campaigns };
  }

  if (isRowQuantityBigger(lineItemsEntity.current.length, lineItemsEntity.previous.length)) {
    return { ...lineItemsEntity, tableName: TraffickingTableName.lineItems };
  }

  return null;
};

type TraffickerTable = {
  ads: TraffickerTableState<AdModel>;
  campaigns: TraffickerTableState<CampaignModel>;
  lineItems: TraffickerTableState<LineItemModel>;
};

type ClearAllBadgeVisibilityParams = {
  adSearchTerm: string;
  campaignSearchTerm: string;
  lineItemSearchTerm: string;
  traffickerTables: TraffickerTable;
};

export const determineClearAllBadgeVisibility = ({
  adSearchTerm,
  campaignSearchTerm,
  lineItemSearchTerm,
  traffickerTables,
}: ClearAllBadgeVisibilityParams): boolean => {
  const hasSearchResult = [adSearchTerm, campaignSearchTerm, lineItemSearchTerm].some(Boolean);

  const selectedRows = [
    traffickerTables.ads.tableInstance.state.selectedRowIds,
    traffickerTables.campaigns.tableInstance.state.selectedRowIds,
    traffickerTables.lineItems.tableInstance.state.selectedRowIds,
  ];

  const hasSelection = selectedRows.some((rowIds) => !!Object.keys(rowIds).length);

  return hasSearchResult || hasSelection;
};

export const updateOffsetsBasedOnSelectedTab = (
  selectedTab: string | null,
  traffickerTables: TraffickerTable
): void => {
  if (selectedTab === TraffickingTableName.campaigns) {
    traffickerTables.ads.setOffset(0);
    traffickerTables.lineItems.setOffset(0);
  }

  if (selectedTab === TraffickingTableName.lineItems) {
    traffickerTables.ads.setOffset(0);
  }
};

export const clearTableSearchResults = (traffickerTables: TraffickerTable): void => {
  traffickerTables.ads.setTableSearchTerm('');
  traffickerTables.campaigns.setTableSearchTerm('');
  traffickerTables.lineItems.setTableSearchTerm('');
};

export const clearTablesSelections = (traffickerTables: TraffickerTable): void => {
  traffickerTables.ads.tableInstance.state.selectedRowIds = {};
  traffickerTables.campaigns.tableInstance.state.selectedRowIds = {};
  traffickerTables.lineItems.tableInstance.state.selectedRowIds = {};
};

export const hasSelectedRowIds = (tableName: TraffickingTableName, traffickerTables: TraffickerTable): boolean =>
  !!Object.keys(traffickerTables[tableName].tableInstance.state.selectedRowIds).length;

export const getVariantFromMutationResult = ({ loading, error, data }: MutationResult): EditableInputVariant => {
  const conditionMappings: { isConditionMet: boolean; variant: EditableInputVariant }[] = [
    { isConditionMet: loading, variant: 'saving' },
    { isConditionMet: data && data?.updateLineItem, variant: 'saveSuccessful' },
    { isConditionMet: !!error, variant: 'saveFailed' },
  ];

  const defaultVariant = 'base';
  const matchedCondition = conditionMappings.find((mapping) => mapping.isConditionMet);

  return matchedCondition?.variant ?? defaultVariant;
};

export const onHandleLineItemUpdateErrors = ({
  message,
  errors = [],
  error = {} as ApolloError,
  onSetGraphQLErrors,
  onFailureUpdate,
}: {
  message: string;
  errors?: ReadonlyArray<GraphQLError>;
  error?: ApolloError;
  onSetGraphQLErrors: Dispatch<SetStateAction<string[]>>;
  onFailureUpdate?: ({ message, description }: Record<string, string>) => void;
}): void => {
  const gqlErrorMessage = getErrorMessageFromGraphQlErrors(errors);
  const networkErrorMessage = getGraphQLErrorExtensionsMessage(error);

  const errorMessage = gqlErrorMessage || networkErrorMessage;

  if (errorMessage) {
    onSetGraphQLErrors((prev) => [...prev, errorMessage]);
    onFailureUpdate?.({ message, description: errorMessage });
  }
};

export const getGroupedAdsBySequences = (
  rows: Row<AdModel>[]
): Pick<UseGroupedTraffickerDataResult, 'groupedData' | 'ungroupedData'> => {
  const groupedData: GroupedData[] = [
    {
      title: SEQUENCE_NAME_TITLE,
      data: [],
    },
  ];

  const ungroupedData: GroupedRows = {
    title: UNSEQUENCED_ADS_TITLE,
    id: DEFAULT_UNGROUPED_ID + TraffickingTableName.ads,
    rows: [],
  };

  rows.forEach((row) => {
    const groupingIndex = groupedData[0].data.findIndex(({ id }) => id === row.original.adSequence?.sequenceId);

    if (groupingIndex > -1) {
      const newIndex = groupedData[0].data[groupingIndex].rows.length;

      groupedData[0].data[groupingIndex].rows.push({ ...row, index: newIndex });
    } else if (row.original.adSequence?.sequenceId) {
      const newIndex = 0;
      const newTitle = row.original.adSequence?.name || NEW_SEQUENCE_LABEL + (groupedData[0].data.length + 1);

      const newGroupedDataValue = {
        id: row.original.adSequence.sequenceId,
        title: newTitle,
        rows: [{ ...row, index: newIndex }] as Row<AdModel>[] & Row<LineItemModel>[],
      };

      groupedData[0].data.push(newGroupedDataValue);
    } else {
      const newIndex = ungroupedData.rows.length;

      ungroupedData.rows.push({ ...row, index: newIndex } as Row<AdModel> & Row<LineItemModel>);
    }
  });

  return {
    groupedData,
    ungroupedData,
  };
};

export const getGroupedLineItemsBySequences = (
  rows: Row<LineItemModel>[]
): Pick<UseGroupedTraffickerDataResult, 'groupedData' | 'ungroupedData'> => {
  const groupedData: GroupedData[] = [
    {
      title: PARENT_LINE_OMS_LINK_ID_TITLE,
      data: [],
    },
  ];

  const ungroupedData: GroupedRows = {
    title: STANDALONE_LINE_ITEMS_TITLE,
    id: DEFAULT_UNGROUPED_ID + TraffickingTableName.lineItems,
    rows: [],
  };

  rows.forEach((row) => {
    const groupingIndex = groupedData[0].data.findIndex(({ id }) => id === row.original.parentLineOmsLink?.id);

    if (groupingIndex > -1) {
      const newIndex = groupedData[0].data[groupingIndex].rows.length;

      groupedData[0].data[groupingIndex].rows.push({ ...row, index: newIndex });
    } else if (row.original.parentLineOmsLink?.id) {
      const newIndex = 0;
      const newGroupedDataValue = {
        title: row.original.parentLineOmsLink.id,
        id: row.original.parentLineOmsLink.id,
        rows: [{ ...row, index: newIndex }] as Row<AdModel>[] & Row<LineItemModel>[],
      };

      groupedData[0].data.push(newGroupedDataValue);
    } else {
      const newIndex = ungroupedData.rows.length;

      ungroupedData.rows.push({ ...row, index: newIndex } as Row<AdModel> & Row<LineItemModel>);
    }
  });

  return {
    groupedData,
    ungroupedData,
  };
};

export const getInitiallyHiddenColumnIds = <Model extends EntityModel>(
  columns: CustomColumnConfig<Model>[]
): string[] => {
  return columns.reduce<IdType<Model>[]>((acc, column) => {
    if (column.isColumnHiddenByDefault && !!column.id) {
      acc.push(column.id);
    }

    return acc;
  }, []);
};

export const getInitialHiddenColumnsWithoutVisibleColumnsIds = (
  initialHiddenColumns: string[],
  visibleInitialHiddenColumns: string[]
): string[] => initialHiddenColumns.filter((columnId) => !visibleInitialHiddenColumns.includes(columnId));
