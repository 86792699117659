import React from 'react';

import { element } from '../../../components/Row/Row';
import type { GetLineItemsRowsParams } from '../getLineItemsRows';

const TargetingRow = ({
  targeting,
  isShowTargetingValues,
}: Pick<GetLineItemsRowsParams, 'targeting'> & { isShowTargetingValues?: boolean }): React.JSX.Element => {
  return (
    <>
      <div>
        <span className={element('value-title')}>Inclusions: </span>
        {targeting.include.length > 0 ? isShowTargetingValues && targeting.include.join(', ') : '-'}
      </div>

      <div>
        <span className={element('value-title')}>Exclusions: </span>
        {targeting.exclude.length > 0 ? isShowTargetingValues && targeting.exclude.join(', ') : '-'}
      </div>
    </>
  );
};

export default TargetingRow;
