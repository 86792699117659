import { User } from 'mission-control-common-components';
import React, { memo } from 'react';
import type { Cell } from 'react-table';

import EditableTextField from '../../../../../common/EditableTableCells/EditableTextField';
import type { PermissionsNames } from '../../../../../constants';
import { cellPropsComparison } from '../../../../../utils/common';
import useUpdateRotationName from '../../hooks/useUpdateRotationName';
import type { AdModel } from '../../modelConverters';

type Props = Cell<AdModel, AdModel['adRotation']>;

const RotationGroupNameCell = (cell: Props): JSX.Element => {
  const permissions = User.getPermissions<PermissionsNames>();
  const { value: adRotation } = cell;
  const rotationId = adRotation?.rotationId;
  const rotationName = adRotation?.rotationName;

  const { cellVariant, updateRotationNameFields } = useUpdateRotationName(cell.row.original.lineItem?.id);

  return (
    <>
      {rotationId && rotationName ? (
        <EditableTextField
          initialValue={rotationName}
          updateField={(rotationName: string): void => updateRotationNameFields(rotationId, rotationName)}
          variant={cellVariant}
          isDisabled={!permissions?.updateRotation}
        />
      ) : (
        <div data-testid="empty-block"></div>
      )}
    </>
  );
};

export default memo(RotationGroupNameCell, cellPropsComparison);
