import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import bem from '../../../utils/bem';
import DragDropItem from '../DragDropItem/DragDropItem';
import type { DraggedElement } from '../types';

const [block] = bem('draggable');

type Props = DraggedElement & {
  index: number;
};

const DragDropEntity: React.FC<Props> = ({ id, element, index }) => (
  <Draggable draggableId={id} index={index}>
    {(provided, snapshot): JSX.Element => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        data-testid={id}
        className={block(snapshot.isDragging ? 'dragging' : '')}
      >
        <DragDropItem element={element} />
      </div>
    )}
  </Draggable>
);

export default DragDropEntity;
