import './Card.scss';

import type { FunctionComponent } from 'react';
import React from 'react';

import bem, { withOptionalClassName } from '../../utils/bem';

type CardProps = {
  title?: string | React.ReactNode;
  children: React.ReactNode;
  className?: string;
};
const [block, element] = bem('card');

const Card: FunctionComponent<CardProps> = ({ title, children, className }: CardProps) => {
  return (
    <div className={withOptionalClassName(block(), className)}>
      {title && <div className={element('title')}>{title}</div>}
      <div className={element('content')}>{children}</div>
    </div>
  );
};

export default Card;
