import { gql } from '@apollo/client';

export const GET_DUPLICATE_LINE_ITEMS = gql`
  query GetLineItemsForAdDuplicate(
    $searchTerm: String
    $adAccountIdList: [ID!]
    $accountExecutiveList: [String!]
    $schedule: ScheduleFilterInputV5
    $adProductList: [String!]
    $adPotentialTypeList: [String!]
    $generalPacingStatusList: [String!]
    $reviewStatusList: [String!]
    $pacingRiskList: [String!]
    $hasMisalignedSchedule: Boolean
    $adStatusList: [String!]
    $traffickerEmailList: [String!]
    $orderTypeList: [String!]
    $statusList: [String!]
    $subStatusList: [String!]
    $tierNameList: [String!]
    $priorityValueList: [String!]
    $billableThirdPartyList: [String!]
    $publisherList: [String!]
    $adRatingList: [String!]
    $adTypeList: [String!]
    $creativeTypeList: [String!]
    $creativeReviewList: [String!]
    $isCoppaOrCaru: Boolean
    $isMakegood: Boolean
    $isAddedValue: Boolean
    $brandIdList: [ID!]
    $agencyIdList: [ID!]
    $industryIdList: [ID!]
    $isRotation: Boolean
    $isSequence: Boolean
    $ids: [ID!]
    $campaignIds: [ID!]!
    $paginationOptions: PaginationOptions!
  ) {
    lineItemsV5(
      paginationOptions: $paginationOptions
      filterOptions: {
        searchTerm: $searchTerm
        adAccountIdList: $adAccountIdList
        accountExecutiveList: $accountExecutiveList
        schedule: $schedule
        adProductList: $adProductList
        adPotentialTypeList: $adPotentialTypeList
        generalPacingStatusList: $generalPacingStatusList
        pacingRiskList: $pacingRiskList
        hasMisalignedSchedule: $hasMisalignedSchedule
        reviewStatusList: $reviewStatusList
        adStatusList: $adStatusList
        traffickerEmailList: $traffickerEmailList
        orderTypeList: $orderTypeList
        statusList: $statusList
        subStatusList: $subStatusList
        tierNameList: $tierNameList
        priorityValueList: $priorityValueList
        billableThirdPartyList: $billableThirdPartyList
        publisherList: $publisherList
        adRatingList: $adRatingList
        adTypeList: $adTypeList
        creativeTypeList: $creativeTypeList
        creativeReviewList: $creativeReviewList
        isCoppaOrCaru: $isCoppaOrCaru
        isMakegood: $isMakegood
        isAddedValue: $isAddedValue
        brandIdList: $brandIdList
        agencyIdList: $agencyIdList
        industryIdList: $industryIdList
        isRotation: $isRotation
        isSequence: $isSequence
        idList: $ids
        campaignIdList: $campaignIds
      }
    ) {
      edges {
        node {
          id
          name
          startDate
          endDate
          billableThirdParty
          isCoppaOrCaru
          schedule {
            timezone
            deliveryInViewerTime
            dateRangeList {
              startDate
              endDate
              pacingShare
            }
            dayPartList {
              activeDayList
              firstActiveHour
              lastActiveHour
            }
          }
          campaign {
            id
            campaignTagList {
              id
              type
              url
              isVerified
            }
          }
          adProduct
        }
      }
      total
    }
  }
`;
