import 'react-datepicker/dist/react-datepicker.css';

import React, { useCallback, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import { LabelTextComponent } from '../../atoms/LabelText';
import { ButtonContainerStyled, DatePickerContainer, StyledDateInput, StyledMCButton } from './styled';

export type CalendarDate = Date | undefined | null;
type DatePickerArgs = CalendarDate | CalendarDate[];

export type IDatepicker = {
  /**Is component disabled */
  disabled?: boolean;
  /**Callback for change date or date range */
  onChange?: (date: CalendarDate | CalendarDate[]) => void;
  /**Text in placeholder when date isn't selected */
  placeholderText?: string;
  /**Prop selected date, will work if selectsRange prop isn't true */
  date?: Date;
  /**Array with start and end dates. Will work if selectsRange prop is true */
  dateRange?: CalendarDate[];
  /**Min date that restrict choice of range */
  minDate?: Date;
  /**Max date that restrict choice of range */
  maxDate?: Date;
  /**Prop define what mode will be used - date picker or date range picker */
  selectsRange?: boolean;
  /**Error message in placeholder and red-styled component */
  errMsg?: string;
  /**Label for DatePicker */
  label?: string;

  inline?: boolean;
  disabledKeyboardNavigation?: boolean;
  openToDate?: Date;
  onOk?: () => void;
};

export enum CalendarButtonVariant {
  SUBMIT = 'submit',
  CLEAR = 'clear',
}

/**
 * Component Datepicker allow user to pick date or date range (as array from 2 dates)
 */
export function DatePicker({
  onChange,
  placeholderText,
  date,
  dateRange,
  disabled = false,
  minDate,
  maxDate,
  selectsRange,
  errMsg,
  label,
  inline,
  disabledKeyboardNavigation,
  openToDate,
  onOk,
}: IDatepicker): JSX.Element {
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const [myRef, setMyRef] = useState<ReactDatePicker>();
  const [isErr, setIsErr] = useState<string>();

  useEffect(() => {
    if (selectsRange && dateRange) {
      setStartDate(dateRange[0]);
      setEndDate(dateRange[1]);
    }

    if (!selectsRange) setStartDate(date);
    if (errMsg) setIsErr(errMsg);
  }, [date, dateRange, selectsRange, errMsg]);

  const closeCalendar = (): void => {
    myRef?.setOpen(false);
    if (onChange) {
      onChange(selectsRange ? [startDate, endDate] : startDate);
    }
  };

  const setDateValues = useCallback((args: DatePickerArgs): CalendarDate | CalendarDate[] => {
    if (!Array.isArray(args)) {
      setStartDate(args);

      return args;
    }

    const [startDateValue, endDateValue] = args;
    setStartDate(startDateValue);
    if (args.length === 2) {
      setEndDate(endDateValue);
    }

    return [startDateValue, endDateValue];
  }, []);

  const handleOnChange = (args: DatePickerArgs): void => {
    setIsErr(undefined);
    const data = setDateValues(args);
    if (onChange) {
      onChange(data);
    }
  };

  const Input: JSX.Element = <StyledDateInput errMsg={isErr} />;
  return (
    <DatePickerContainer>
      <LabelTextComponent text={label} />
      <ReactDatePicker
        data-testid="datepicker-component"
        ref={(r: ReactDatePicker): void => {
          setMyRef(r);
        }}
        selectsRange={selectsRange}
        placeholderText={placeholderText}
        disabled={disabled}
        onChange={handleOnChange}
        startDate={startDate}
        selected={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        showPopperArrow={false}
        shouldCloseOnSelect={false}
        customInput={Input}
        inline={inline}
        disabledKeyboardNavigation={disabledKeyboardNavigation}
        openToDate={openToDate}
      >
        <ButtonContainerStyled>
          <StyledMCButton type={CalendarButtonVariant.SUBMIT} onClick={onOk || closeCalendar} disabled={disabled}>
            OK
          </StyledMCButton>
        </ButtonContainerStyled>
      </ReactDatePicker>
    </DatePickerContainer>
  );
}
