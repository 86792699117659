import './SchedulingForm.scss';

import { Form } from 'formik';
import React, { memo, useEffect } from 'react';

import { Scheduling } from '../../../../../../common/AdForm';
import { getAdSchedule } from '../../../../../../common/AdForm/hooks/modelConverters';
import Button from '../../../../../../common/Button';
import useFocusTrap from '../../../../../../common/hooks/useFocusTrap';
import { useFormStateManager } from '../../../../../../common/hooks/useFormStateManager';
import { useTrackFormFieldChanges } from '../../../../../../common/hooks/useTrackFormFieldChanges';
import bem from '../../../../../../utils/bem';
import type { SchedulingFormProps } from '../types';

const [block, element] = bem('scheduling-form');

const SchedulingForm = ({
  schedule,
  lineItemSchedule,
  loading,
  onCancel,
  onSubmit,
  stashedDayPartList,
  onSetStashedDayPartList,
}: SchedulingFormProps): JSX.Element => {
  const { areFormFieldsDirty } = useTrackFormFieldChanges();
  const { initialValues, setFormState } = useFormStateManager();

  const focusRef = useFocusTrap<HTMLFormElement>(['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']);

  useEffect(() => {
    setFormState({
      initialValues,
      values: {
        ...initialValues,
        schedule: getAdSchedule(schedule, lineItemSchedule),
      },
    });
  }, [initialValues, setFormState, lineItemSchedule, schedule]);

  return (
    <Form autoComplete="off" className={block()} ref={focusRef} aria-label="form">
      <h2>Scheduling</h2>
      <Scheduling
        stashedDayPartList={stashedDayPartList}
        setStashedDayPartList={onSetStashedDayPartList}
        lineItemSchedule={lineItemSchedule}
      />
      <div className={element('buttons')}>
        <Button variant="outlined" className={element('cancel-button')} data-testid="cancel-btn" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="button"
          loading={loading}
          disabled={!areFormFieldsDirty}
          className={element('confirm-button')}
          onClick={onSubmit}
          data-testid="confirm-btn"
        >
          Confirm
        </Button>
      </div>
    </Form>
  );
};

export default memo(SchedulingForm);
