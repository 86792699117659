import './DetailsTable.scss';

import React from 'react';

interface DetailsTableProps {
  details: [string | null, React.ReactNode][];
}
/**
 * The DetailsTable renders an immutable, non-interactive set of "details" about an object
 * laid out as a 2-column table. It is useful for displaying an arbitrary amount of key, value pairs
 */
function DetailsTable({ details }: DetailsTableProps): JSX.Element {
  return (
    <table className="details-table">
      <tbody>
        {details.map(([label, value]) => (
          <tr key={label}>
            {label && (
              <td>
                <strong>{label}</strong>
              </td>
            )}
            <td>{value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default DetailsTable;
