import './AdTagsTableRows.scss';

import { IconAlertErrorRed, IconAlertSuccessGreen } from '@hulu-react-style-components/icons';
import TextAreaAnt from 'antd/lib/input/TextArea';
import React from 'react';

import IconButton from '../../../common/IconButton';
import type { AdTagType, DisplayEnum, EventType, UNRECOGNIZED } from '../../../configs';
import { adTagEvents, adTagTypes } from '../../../configs';
import type { AdTag, Nullable } from '../../../models';
import bem from '../../../utils/bem';
import type { ListHelpers } from '../../../utils/listHelpers';
import { isNielsenTag } from '../../AdForm/utils';
import Dropdown, { configToDropdownOption } from '../../Dropdown';
import type { DropdownOption, DropdownProps, SingleSelectValue } from '../../Dropdown/Dropdown';
import { isRecognizedOption } from '../../Dropdown/utils';
import Tooltip from '../../Tooltip';
import {
  commonTagEventDropdownProps,
  commonUrlTextAreaProps,
  getAdTagEventDropdownOptionsByType,
  getUpdatedTagByType,
} from '../utils';

export const IAS_LABEL = 'IAS Viewability';

export interface AdTagsTableRowsProps {
  adTagList: AdTag[];
  listHelpers: Pick<ListHelpers<AdTag>, 'removeAt' | 'replaceAt'>;
  commonTagTypeDropdownProps: Pick<
    DropdownProps<AdTagType | typeof UNRECOGNIZED>,
    'classNameModifier' | 'isSearchable' | 'options'
  >;
  isAdInitiallyWithBillableTag: boolean;
  disabled?: boolean;
  isAdUnDeletable: boolean;
}

const [, element] = bem('ad-tags-table-rows');

const unverifiedTooltipMessageMap: Record<AdTagType | typeof UNRECOGNIZED, Nullable<string>> = {
  BILLABLE: 'Tag requires review before delivering',
  NON_BILLABLE: 'Tag requires review but may still deliver',
  CLICK: null,
  UNRECOGNIZED: null,
};

function AdTagsTableRows({
  adTagList,
  commonTagTypeDropdownProps,
  listHelpers: { removeAt, replaceAt },
  isAdInitiallyWithBillableTag,
  disabled = false,
  isAdUnDeletable,
}: AdTagsTableRowsProps): JSX.Element {
  function handleTagTypeChange(newType: SingleSelectValue<AdTagType | typeof UNRECOGNIZED>, index: number): void {
    if (isRecognizedOption(newType)) {
      replaceAt(index, {
        ...getUpdatedTagByType(adTagList[index], newType),
        isVerified: false,
      });
    }
  }

  function handleTagEventChange(newEvent: SingleSelectValue<EventType | typeof UNRECOGNIZED>, index: number): void {
    if (isRecognizedOption(newEvent)) {
      replaceAt(index, {
        ...adTagList[index],
        event: adTagEvents[newEvent.value],
        isVerified: false,
      });
    }
  }

  function handleTagUrlChange(e: React.ChangeEvent<HTMLTextAreaElement>, index: number): void {
    replaceAt(index, {
      ...adTagList[index],
      url: e.target.value,
      isVerified: false,
    });
  }

  const getAdTagDropdownValue = (
    type: DisplayEnum<'BILLABLE' | 'NON_BILLABLE' | 'CLICK'>,
    isIas: boolean
  ): DropdownOption<'UNRECOGNIZED' | 'CLICK' | 'BILLABLE' | 'NON_BILLABLE'> => {
    const dropdownValue = configToDropdownOption(type);

    if (type.key === adTagTypes.NON_BILLABLE.key && isIas) {
      dropdownValue.label = IAS_LABEL;
    }

    return dropdownValue;
  };

  return (
    <>
      {!adTagList.length && (
        <tr className={element('row')}>
          <td colSpan={5} className={element('empty-msg')}>
            No tags added yet
          </td>
        </tr>
      )}
      {adTagList.map(({ type, event, url, isVerified, unDeletable, isIas }, index) => {
        const nielsenTag = isNielsenTag(url);
        const eventDropdownOptions = getAdTagEventDropdownOptionsByType(type);

        function onAdTagTypeChange(value: SingleSelectValue<AdTagType | typeof UNRECOGNIZED>): void {
          handleTagTypeChange(value, index);
        }

        function onUrlChange(e: React.ChangeEvent<HTMLTextAreaElement>): void {
          handleTagUrlChange(e, index);
        }

        function onAdTagEventChange(value: SingleSelectValue<EventType | typeof UNRECOGNIZED>): void {
          handleTagEventChange(value, index);
        }

        function onRemoveTag(): void {
          removeAt(index);
        }

        const shouldShowUnverifiedTooltip = !isVerified && !!unverifiedTooltipMessageMap[type.key];
        const shouldDisableBillableTag = type.key === 'BILLABLE' && isAdInitiallyWithBillableTag && isAdUnDeletable;
        const isActionDisabled = shouldDisableBillableTag || unDeletable || disabled || !!isIas;

        let disabledTagTooltipMessage = 'Deleting disabled';
        if (unDeletable) {
          disabledTagTooltipMessage = 'Billable tag with active ad status cannot be deleted';
        } else if (shouldDisableBillableTag) {
          disabledTagTooltipMessage = `Billable tags with delivered <br /> impressions cannot be deleted.`;
        }

        if (nielsenTag) {
          disabledTagTooltipMessage = 'Nielsen tag cannot be deleted';
        }

        if (isIas) {
          disabledTagTooltipMessage =
            'De-select IAS as Viewability Vendor in <br /> Metadata section below to remove tags.';
        }

        return (
          <tr className={element('row')} key={index}>
            <td>
              <Dropdown<AdTagType | typeof UNRECOGNIZED>
                {...commonTagTypeDropdownProps}
                value={getAdTagDropdownValue(type, !!isIas)}
                onChange={onAdTagTypeChange}
                isDisabled={isActionDisabled}
              />
            </td>
            <td>
              {/*Using Ant-Design component for its autoSize feature */}
              <TextAreaAnt {...commonUrlTextAreaProps} value={url} onChange={onUrlChange} disabled={isActionDisabled} />
            </td>
            <td>
              <Dropdown<EventType | typeof UNRECOGNIZED>
                {...commonTagEventDropdownProps}
                value={configToDropdownOption(event)}
                options={eventDropdownOptions}
                isDisabled={eventDropdownOptions.length <= 1 || nielsenTag || disabled || !!isIas}
                onChange={onAdTagEventChange}
              />
            </td>
            <td className={element('cell-verified')}>
              {isVerified ? (
                <div>
                  <IconAlertSuccessGreen
                    className={`${element('verified-icon')} ${element('verified-icon-success')}`}
                    aria-label="verified"
                  />
                </div>
              ) : (
                <Tooltip
                  id={`${index}-unverified`}
                  message={unverifiedTooltipMessageMap[type.key] || ''}
                  disable={!shouldShowUnverifiedTooltip}
                >
                  <div>
                    <IconAlertErrorRed className={element('verified-icon')} aria-label="not verified" />
                  </div>
                </Tooltip>
              )}
            </td>
            <td className={element('cell-remove')}>
              <Tooltip
                id={`${index}-disable-tag-removal`}
                message={disabledTagTooltipMessage}
                disable={(!shouldDisableBillableTag && !unDeletable && !isIas) || disabled}
                className={element('remove-disabled-tooltip')}
              >
                <IconButton
                  className={element('remove-btn')}
                  icon="IconDelete"
                  variant="outlined"
                  aria-label="remove tag"
                  onClick={onRemoveTag}
                  disabled={isActionDisabled}
                />
              </Tooltip>
            </td>
          </tr>
        );
      })}
    </>
  );
}

export default AdTagsTableRows;
