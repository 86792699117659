import { User } from 'mission-control-common-components';
import { useState } from 'react';

import type { PermissionsNames } from '../../../../constants';
import { TraffickingTableName } from '../../../../constants';
import type { FlatRowType } from '../../CreateReviewButton/types';
import type { EntityModel } from '../../TraffickingPage/modelConverters';

interface UseOverrideReviewButtonReturnType {
  hasOverrideReviewPermission: boolean;
  isButtonDisabled: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
}

export const useOverrideReviewButton = ({
  selectedFlatRows,
  tableName,
}: {
  selectedFlatRows: FlatRowType<EntityModel>;
  tableName: TraffickingTableName;
}): UseOverrideReviewButtonReturnType => {
  const permissions = User.getPermissions<PermissionsNames>();
  const hasOverrideReviewPermission =
    (tableName === TraffickingTableName.lineItems && permissions?.overrideLineItemReview) ||
    (tableName === TraffickingTableName.ads && permissions?.overrideAdReview);

  const [showModal, setShowModal] = useState(false);
  const isButtonDisabled: boolean = !selectedFlatRows?.length;

  return {
    hasOverrideReviewPermission: !!hasOverrideReviewPermission,
    isButtonDisabled,
    setShowModal,
    showModal,
  };
};

export default useOverrideReviewButton;
