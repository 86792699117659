import type { FormikConfig, FormikValues } from 'formik';
import React from 'react';

import type { AdModel } from '../../../TraffickingPage/modelConverters';
import DuplicatedAdDetails from '../DuplicatedAdDetails';
import DuplicatedAdStepLayout from '../DuplicatedAdStepLayout';
import DuplicatedAdStepWrapper from '../DuplicatedAdStepWrapper';
import DuplicateAdAssigningToLineItem from './DuplicateAdAssigningToLineItem/DuplicateAdAssigningToLineItem';

type Props = Pick<FormikConfig<FormikValues>, 'validationSchema'> & {
  ad: AdModel;
};

const DuplicateAdFormSecondStep: React.FC<Props> = ({ ad }) => {
  const { id, type, name, lineItem } = ad;
  const lineItemName = lineItem?.name || '';
  const campaignName = lineItem?.campaign.name || '';

  return (
    <DuplicatedAdStepWrapper title="Duplicate ad">
      <DuplicatedAdStepLayout
        leftSide={
          <DuplicatedAdDetails
            campaignName={campaignName}
            lineItemName={lineItemName}
            adType={type.displayName}
            adId={id}
            adName={name}
          />
        }
        rightSide={<DuplicateAdAssigningToLineItem />}
      />
    </DuplicatedAdStepWrapper>
  );
};

export default DuplicateAdFormSecondStep;
