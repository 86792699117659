import { sortBy as _sortBy } from 'lodash';
import { useCallback, useState } from 'react';

import type { LineItemModel } from '../../../../TraffickingPage/modelConverters';
import type { DuplicateLineItem, HandleAddSavedItem, HandleRemoveSavedItem } from './types';

export type UseDuplicateLineItemsState = {
  savedItems: DuplicateLineItem[];
  handleAddSavedItem: HandleAddSavedItem;
  handleRemoveSavedItem: HandleRemoveSavedItem;
};

const useDuplicateLineItemsState = (lineItems: LineItemModel[]): UseDuplicateLineItemsState => {
  const sortedLineItemsByName = _sortBy(lineItems, ['name']);

  const [savedItems, setSavedItems] = useState<DuplicateLineItem[]>(
    sortedLineItemsByName.map((lineItemModel) => {
      return {
        id: lineItemModel.id,
        name: lineItemModel.name,
        schedule: lineItemModel.schedule,
        startDate: lineItemModel.startDate,
        endDate: lineItemModel.endDate,
        adProduct: lineItemModel.adProduct,
        billableThirdParty: lineItemModel.billableThirdParty,
        isCoppaOrCaru: lineItemModel.isCoppaOrCaru,
        campaign: lineItemModel.campaign,
      };
    })
  );

  const handleAddSavedItem: HandleAddSavedItem = useCallback((newSaveItem) => {
    setSavedItems((prevState) => [...(prevState || []), newSaveItem]);
  }, []);

  const handleRemoveSavedItem: HandleRemoveSavedItem = useCallback((id) => {
    setSavedItems((prevState) => prevState?.filter((approvedItem) => approvedItem.id !== id));
  }, []);

  return {
    savedItems,
    handleAddSavedItem,
    handleRemoveSavedItem,
  };
};

export default useDuplicateLineItemsState;
