import '../Dropdown/Dropdown.scss';

import React from 'react';
import type {
  DeselectOptionActionMeta,
  NamedProps as SelectProps,
  SelectOptionActionMeta,
  ValueType,
} from 'react-select';

import bem from '../../utils/bem';
import type { DropdownOption, DropdownOptionGroup } from '../Dropdown';
import type { DropdownTypes } from '../Dropdown/Dropdown';
import DropdownButton from '../Dropdown/DropdownButton';
import { useDropdown } from '../Dropdown/hooks';
import { useMultiSelectDropdown } from './hooks';
import MultiSelectContainer from './MultiSelectContainer';
import type {
  CLEAR_SELECTED_ITEMS_VALUE,
  SELECT_ALL_OPTIONS_VALUE,
} from './MultiSelectContainer/hooks/useMultiSelectContainer';

export type OptionTypeWithExtras<T = string> = T | typeof CLEAR_SELECTED_ITEMS_VALUE | typeof SELECT_ALL_OPTIONS_VALUE;

export type MultiSelectValue<T = string> = ValueType<DropdownOption<T>, true>;

export type MultiSelectOptions<T = string> = ReadonlyArray<DropdownOption<T> | DropdownOptionGroup<T>>;

type MultiSelectProps<T = string> = SelectProps<DropdownOption<T>, true, DropdownOptionGroup<T>>;

export interface MultiSelectDropdownProps<T = string>
  extends Omit<MultiSelectProps<OptionTypeWithExtras<T>>, 'onChange'> {
  label?: string;
  emptyDisplayText?: string;
  multiOptionLabel?: string; // select-all option text - if omitted select all will not be an option in the dropdown.
  multipleSelectControls?: boolean;
  handleSelectAll?: () => void;
  handleClearAll?: () => void;
  value: MultiSelectValue<T>;
  loading?: boolean;
  error?: string;
  isRemovable?: boolean;
  onChange: (
    value: MultiSelectValue<T>,
    meta: SelectOptionActionMeta<DropdownOption> | DeselectOptionActionMeta<DropdownOption>
  ) => void;
  options: MultiSelectOptions<T>;
  errMsg?: string;
  dataTestId?: string;
  classNameModifier?: string;
  onClose?: () => void;
  handleRemove?: () => void;
  type?: DropdownTypes;
  onNext?: () => Promise<void>;
  hasMore?: boolean;
  setIsMultiSelectOpen?: (isOpen: boolean) => void;
}

// same styles as single-select dropdown
const [block, element] = bem('dropdown');

function MultiSelectDropdown<T = string>({
  label,
  classNameModifier,
  type,
  onClose,
  loading,
  isRemovable,
  errMsg,
  dataTestId,
  handleRemove,
  setIsMultiSelectOpen,
  ...props
}: MultiSelectDropdownProps<T>): JSX.Element {
  const { active, setActive, focusRef, dropdownRef, handleKeyDown } = useDropdown({ onClose });
  const { isOptionSelected, handleChange, getDisplayValue } = useMultiSelectDropdown<T>(props);

  const modifiers = [
    ...(classNameModifier ? [classNameModifier] : []),
    ...(type ? [type] : []),
    ...(active ? ['active'] : []),
    ...(errMsg ? ['val-error'] : []),
  ];

  React.useEffect(() => {
    setIsMultiSelectOpen?.(active);
  }, [active, setIsMultiSelectOpen]);

  return (
    <div className={block(modifiers)} ref={dropdownRef} data-testid="multi-dropdown-button">
      <DropdownButton
        label={label}
        emptyDisplayText={props.emptyDisplayText}
        displayValue={getDisplayValue(props.value)}
        active={active}
        isRemovable={isRemovable}
        setActive={setActive}
        isDisabled={props.isDisabled}
        dataTestId={dataTestId || 'dropdown-button'}
        onClose={onClose}
        handleRemove={handleRemove}
        errMsg={errMsg}
      />
      <div
        className={element('container', props.isSearchable === false ? 'not-searchable' : null)}
        ref={focusRef}
        onKeyDown={handleKeyDown}
      >
        {active && !loading && !props.error && (
          <MultiSelectContainer {...props} isOptionSelected={isOptionSelected} onChange={handleChange} />
        )}
        {active && loading && <div className={element('loading')}>Loading...</div>}
        {active && props.error && (
          <div className={element('error')}>Something went wrong. Refresh the page or try again later.</div>
        )}
        {active && !loading && props.options.length === 0 && (
          <div className={element('not-found')}>{`No results found for "${props.inputValue}"`}</div>
        )}
      </div>
    </div>
  );
}

export default MultiSelectDropdown;
