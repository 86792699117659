import { gql } from '@apollo/client';

import { ADS_ROTATIONS_FRAGMENT } from '../fragments/adsRotations';

export const GET_ADS_ROTATIONS = gql`
  query getAdsRotations($lineItemIds: [ID!]!) {
    ads: adsV5(filterOptions: { lineItemIdList: $lineItemIds }) {
      edges {
        node {
          ...adsRotations
        }
      }
    }
  }
  ${ADS_ROTATIONS_FRAGMENT}
`;
