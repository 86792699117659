import type { FetchResult } from '@apollo/client';
import { useMutation } from '@apollo/client';

import type { DeleteAdsMutation } from '../../../../apis/graphql';
import { getErrorMessageFromGraphQlErrors } from '../../../../apis/graphql';
import { AlertType } from '../../../../common/Alert';
import { openToastAlert } from '../../../../common/ToastAlert/toastAlert';
import { MISSION_CONTROL_SUPPORT_EMAIL } from '../../../../constants';
import { DELETE_TRAFFICKING_PAGE_ADS } from './mutations';

export type UseDeleteAdsResponse = {
  deleteAds: (ids: string[]) => Promise<FetchResult<DeleteAdsMutation> | undefined>;
  loading: boolean;
};

const getDeleteAdAlertErrorMessage = (idsLength: number): string => `${idsLength > 1 ? 'Ads' : 'Ad'} Deletion Failed`;

const useDeleteAds = (): UseDeleteAdsResponse => {
  const [deleteAdsMutation, { loading }] = useMutation<DeleteAdsMutation>(DELETE_TRAFFICKING_PAGE_ADS);

  const deleteAds = async (ids: string[]): Promise<FetchResult<DeleteAdsMutation> | undefined> => {
    try {
      const response = await deleteAdsMutation({
        variables: {
          ids,
        },
      });

      if (response?.errors && response.errors.length) {
        openToastAlert({
          alertType: AlertType.ERROR,
          message: getDeleteAdAlertErrorMessage(ids.length),
          description: getErrorMessageFromGraphQlErrors(response.errors),
        });
      } else {
        openToastAlert({
          alertType: AlertType.SUCCESS,
          message: `${ids.length > 1 ? 'Ads' : 'Ad'} Deleted Successfully`,
        });
      }

      return response;
    } catch (error) {
      openToastAlert({
        alertType: AlertType.ERROR,
        message: getDeleteAdAlertErrorMessage(ids.length),
        description: (
          <span>
            Something went wrong when deleting {ids.length > 1 ? 'ads' : 'ad'}. Please try again in a few minutes or
            email us at
            <br />
            <a href={`mailto:${MISSION_CONTROL_SUPPORT_EMAIL}`}>{MISSION_CONTROL_SUPPORT_EMAIL}</a>
          </span>
        ),
      });
    }
  };

  return {
    deleteAds,
    loading,
  };
};

export default useDeleteAds;
