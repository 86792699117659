import { useFormikContext } from 'formik';
import { bem } from 'mission-control-common-components';
import React from 'react';
import { useEffectOnce } from 'react-use';

import type { AdSelectorSlateCreativeInputV5 as CreativeInput } from '../../../../apis/graphql';
import { CreativeV5Type } from '../../../../apis/graphql';
import type { CreativeReviewStatus } from '../../../../configs';
import DraggableTabNavigation from '../../../DraggableTabNavigation';
import { DraggableTab } from '../../../DraggableTabNavigation/DraggableTabNavigation';
import { Switch, TextField } from '../../../Form';
import Loader from '../../../Loader';
import type { AdFormValues } from '../../adFormik';
import { AdFormCreativeFields } from '../../adFormik/fields';
import { isCreativeAssetRemoveForbidden } from '../../utils/creative';
import { SiteServedFooter } from '../components';
import type { CreativeBuilderProps } from '../CreativeBuilder';
import CreativeBuilder from '../CreativeBuilder';
import type { CreativesBuilderValues } from '../types';
import { CreativeEditMode } from '../types';
import {
  HEADLINE_DEFAULT_VALUE,
  HEADLINE_LABEL,
  RANDOM_SEQUENCE_INFO_FALSY,
  RANDOM_SEQUENCE_INFO_TRUTHY,
  RANDOM_SEQUENCE_LABEL,
  VOICEOVER_URL_DEFAULT_VALUE,
  VOICEOVER_URL_LABEL,
} from './constants';
import { isOptionAssetFilled, useAdSelectorSlateAssets, useAssetTabs } from './hooks';
import OptionHeader from './OptionHeader';

export type DraggableTabComponent = {
  id: string;
  label: string;
  isComplete?: boolean;
  isFailed?: boolean;
  isDragDisabled?: boolean;
  tooltipMessage?: string;
  component: React.JSX.Element;
};

const CLASS_NAME_MODIFIER = 'ad-selector-slate';

const [navigationBlock] = bem('task-tab-navigation');

export const constructAdSelectorSlateCreativeInput = ({
  name,
  logo,
  headline,
  voiceoverUrl,
  randomOrder,
  optionList,
}: CreativesBuilderValues): CreativeInput => {
  return {
    name,
    type: CreativeV5Type.AdSelectorSlate,
    logoId: logo?.id,
    headline,
    voiceoverUrl,
    randomOrder,
    optionList:
      optionList?.filter(isOptionAssetFilled).map(({ caption, thumbnail, adSelectorVideoCreativeId, index }) => ({
        caption,
        thumbnailId: thumbnail?.id || '',
        adSelectorVideoCreativeId,
        index,
      })) ?? [],
  };
};

const AdSelectorSlateCreativeBuilder = ({
  editMode,
  adTypeDimensions,
  isPreview,
}: CreativeBuilderProps): React.JSX.Element => {
  const {
    values: { creative },
    setFieldValue,
  } = useFormikContext<AdFormValues>();

  const isReadonly = editMode === CreativeEditMode.readonly;
  const isAssetReadonly = isCreativeAssetRemoveForbidden(editMode, creative);

  const { onDragEnd, handleTabChange, selectedTabIndex, tabs } = useAssetTabs({
    isPreview: !!isPreview,
    isReadonly: isAssetReadonly,
    adTypeDimensions,
  });

  const { assets, selectedAsset, loadingCreativeData } = useAdSelectorSlateAssets({ selectedTabIndex });

  // set default values for fields
  useEffectOnce(() => {
    if (!creative.headline) {
      setFieldValue(AdFormCreativeFields.Headline, HEADLINE_DEFAULT_VALUE);
    }

    if (!creative.voiceoverUrl) {
      setFieldValue(AdFormCreativeFields.VoiceoverUrl, VOICEOVER_URL_DEFAULT_VALUE);
    }
  });

  if (loadingCreativeData) return <Loader />;

  return (
    <CreativeBuilder classNameModifier={CLASS_NAME_MODIFIER} editMode={editMode}>
      <TextField
        id="headline"
        formFieldName={AdFormCreativeFields.Headline}
        label={HEADLINE_LABEL}
        disabled={isReadonly}
      />
      <Switch
        id="randomOrder"
        formFieldName={AdFormCreativeFields.RandomOrder}
        colorWhenOn={'green'}
        disabled={isReadonly}
        label={RANDOM_SEQUENCE_LABEL}
        info={(value: boolean): string => (value ? RANDOM_SEQUENCE_INFO_TRUTHY : RANDOM_SEQUENCE_INFO_FALSY)}
      />
      <TextField
        id="voiceoverUrl"
        formFieldName={AdFormCreativeFields.VoiceoverUrl}
        label={VOICEOVER_URL_LABEL}
        disabled={isReadonly}
      />
      <div className={navigationBlock()}>
        <DraggableTabNavigation
          selectedIndex={selectedTabIndex}
          onTabClick={handleTabChange}
          onDragEnd={onDragEnd}
          droppableId={'ad-selector-options'}
        >
          {tabs.map(({ id, label, isDragDisabled, component, tooltipMessage, isComplete, isFailed }) => {
            return (
              <DraggableTab
                title={label}
                key={id}
                element={
                  <OptionHeader
                    tooltipId={id}
                    tooltipMessage={tooltipMessage}
                    label={label}
                    isDragDisabled={isDragDisabled}
                    isComplete={isComplete}
                    isFailed={isFailed}
                  />
                }
                isDragDisabled={isDragDisabled}
              >
                {component}
              </DraggableTab>
            );
          })}
        </DraggableTabNavigation>
      </div>
      <SiteServedFooter
        creativeReview={creative.review as CreativeReviewStatus}
        assets={assets}
        selectedAsset={selectedAsset}
        isLoading={loadingCreativeData}
        creativeStatus={creative?.status}
      />
    </CreativeBuilder>
  );
};

export default AdSelectorSlateCreativeBuilder;
