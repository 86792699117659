import './AdditionalFilters.scss';

import type { Dispatch, FC } from 'react';
import React, { memo, useCallback, useEffect, useMemo } from 'react';

import type { TraffickingFilters } from '../../../../apis/graphql';
import type { SearchParamsValuesType } from '../../../../constants';
import { filtersWithDynamicOrderConfig } from './config';
import type { AdditionalFilterDropdown, SelectedFiltersValues } from './constants';
import FiltersDropdown from './FiltersDropdown';
import { getSortedFilters } from './utils';

export type AdditionalFiltersProps = {
  selectedFiltersValues: SelectedFiltersValues;
  selectedDropdownFilters: AdditionalFilterDropdown[];
  onSelectedDropdownFilters: Dispatch<React.SetStateAction<AdditionalFilterDropdown[]>>;
  onRemoveSingleFilter: (selectedSearchParam: SearchParamsValuesType) => void;
  applyFilter: <T extends keyof TraffickingFilters>(filterName: T, filterValue: TraffickingFilters[T]) => void;
};

const AdditionalFilters: FC<AdditionalFiltersProps> = ({
  selectedFiltersValues,
  selectedDropdownFilters,
  applyFilter,
  onSelectedDropdownFilters,
  onRemoveSingleFilter,
}) => {
  const filters = useMemo(() => getSortedFilters(filtersWithDynamicOrderConfig), []);

  const handleSetAdditionalFilters = useCallback(
    (selectedFilter: AdditionalFilterDropdown): void => {
      onSelectedDropdownFilters((prevSelectedFiltersDropdowns) => {
        const isFilterDropdownSelected = prevSelectedFiltersDropdowns.some(
          (filterDr) => filterDr.searchParam === selectedFilter.searchParam
        );

        if (!isFilterDropdownSelected) {
          return [...prevSelectedFiltersDropdowns, selectedFilter];
        }

        return prevSelectedFiltersDropdowns;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [applyFilter, selectedFiltersValues, onSelectedDropdownFilters]
  );

  const handleApplyFilterValue = useCallback(
    (selectedSearchParam: SearchParamsValuesType, value: string[]): void => {
      applyFilter(selectedSearchParam, value);
    },
    [applyFilter]
  );

  useEffect(() => {
    onSelectedDropdownFilters(filters.filter((filter) => selectedFiltersValues[filter.searchParam]?.length));
  }, [selectedFiltersValues, filters, onSelectedDropdownFilters]);

  return (
    <FiltersDropdown
      filters={filters}
      selectedDropdownsLength={selectedDropdownFilters.length}
      selectedFiltersValues={selectedFiltersValues}
      handleApplyFilter={handleApplyFilterValue}
      handleRemoveFilter={onRemoveSingleFilter}
      setAdditionalFilter={handleSetAdditionalFilters}
    />
  );
};

export default memo(AdditionalFilters);
