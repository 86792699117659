import type { UpdateAdInputV5 } from '../../../../../apis/graphql';
import { AdStatusV5, removeTypeNamesFromObject } from '../../../../../apis/graphql';
import type { AdFormValues } from '../../../../../common/AdForm/adFormik';
import {
  getAdTagInput,
  getFrequencyCapInput,
  getTargetingTermValuesInput,
  getViewabilityVendorEnumsFromFormValue,
} from '../../../../../common/AdForm/hooks/modelConverters/adsPageAdModelToNode';

export function constructUpdateAdInput(formValues: AdFormValues): UpdateAdInputV5 {
  return removeTypeNamesFromObject({
    name: formValues.name,
    status: formValues.deliveryStatus ? AdStatusV5.On : AdStatusV5.Off,
    rating: formValues.rating,
    creativeId: formValues.creative.id || null,
    frequencyCapList: getFrequencyCapInput(formValues.frequencyCapList),
    viewabilityVendorList: getViewabilityVendorEnumsFromFormValue(formValues.viewabilityVendorList),
    adTagList: getAdTagInput(formValues.adTagList),
    targetingTermValues: getTargetingTermValuesInput(formValues.targetingTermValues),
    dateRangeList: formValues.schedule.dateRangeList,
    dayPartList: formValues.schedule.dayPartList,
    timezone: formValues.schedule.timezone,
    deliveryInViewerTime: formValues.schedule.deliveryInViewerTime,
  });
}
