import { addTimeToStringDateIfNeeded } from '../../utils/formatting';

export type DateValues = {
  year?: number;
  month?: number;
  day?: number;
};

export type TimeValues = {
  hours?: number;
  minutes?: number;
  seconds?: number;
};

export type DateTimeValues = DateValues & TimeValues;

export const getDateTimeWithPredefinedValues = (
  dateTime: string,
  { hours, minutes, seconds, year, month, day }: DateTimeValues,
  timeSeparator: string = ':',
  dateSeparator: string = '-'
): string => {
  const splitDateTimeString = addTimeToStringDateIfNeeded(dateTime).split('T');

  return dateTime
    ? `${getDateWithPredefinedValues(
        splitDateTimeString[0],
        {
          year,
          month,
          day,
        },
        dateSeparator
      )}T${getTimeWithPredefinedValues(splitDateTimeString[1], { hours, minutes, seconds }, timeSeparator)}`
    : '';
};

export const getDateWithPredefinedValues = (
  date: string,
  { year, month, day }: DateValues,
  separator: string = '-'
): string => {
  const splitDate = date.split(separator);

  return date
    ? `${
        (year || splitDate[0]) +
        separator +
        compareDateTimeValues(month || Number(splitDate[1]), 12, 1) +
        separator +
        compareDateTimeValues(day || Number(splitDate[2]), 31, 1)
      }`
    : '';
};

export const getTimeWithPredefinedValues = (
  time: string,
  { hours, minutes, seconds }: TimeValues,
  separator: string = ':'
): string => {
  const splitTime = time.split(separator);

  const hoursValue = hours !== undefined ? hours : Number(splitTime[0]);
  const minutesValue = minutes !== undefined ? minutes : Number(splitTime[1]);
  const secondsValue = seconds !== undefined ? seconds : Number(splitTime[2] || 0);

  return time
    ? `${
        compareDateTimeValues(hoursValue, 23) +
        separator +
        compareDateTimeValues(minutesValue, 59) +
        separator +
        compareDateTimeValues(secondsValue, 59)
      }`
    : '';
};

export const compareDateTimeValues = (value: number, max: number, min: number = 0): string => {
  const comparedValue = Math.max(Math.min(value, max), min);

  return comparedValue < 10 ? `0${comparedValue}` : comparedValue.toString();
};
