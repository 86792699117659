import { AdReviewV5, AdStatusV5, Review } from '../apis/graphql';
import type { AdsPageAd } from '../common/AdForm/hooks/types';
import { RESTRICTED_CREATIVE_TYPES } from '../common/constants/creative';
import type { AdModel } from '../pages/Trafficking/TraffickingPage/modelConverters';

export const isSwitchToOnForbidden = (ad: AdModel | AdsPageAd): boolean => {
  if (!ad.creative) return false;

  return (
    ad.review.key === AdReviewV5.Approved &&
    ad.status.key !== AdStatusV5.On &&
    ad.creative.review === Review.Rejected &&
    RESTRICTED_CREATIVE_TYPES.includes(ad.creative.type)
  );
};
