import React from 'react';
import type { CellProps } from 'react-table';

import type { SimpleChangeValue, SingleValueChange } from '../../../../apis/graphql';
import type { ChangeLogRecord } from '../../types';
import { CellItem } from './CellItem';
import type { CellItemsProps } from './CellItems';
import {
  DateRangeCell,
  DaypartCell,
  DefaultCellItems,
  FrequencyCapCell,
  OrderOMSLinkCell,
  PriorityCell,
  SecondaryPaceCell,
  TagsCell,
  TargetingCell,
  TargetingRulesCell,
  TierCell,
  TypePropertiesCell,
} from './CellItems';
import type { Term } from './CellItems/TargetingCell/TargetingCell';

const fieldNameCellItems: Record<string, (change: CellItemsProps) => JSX.Element | null> = {
  'ad-tag-list': TagsCell,
  'child-line-oms-link': OrderOMSLinkCell,
  dates: DateRangeCell,
  daypart: DaypartCell,
  definition: TargetingCell,
  'order-oms-link': OrderOMSLinkCell,
  'parent-line-oms-link': OrderOMSLinkCell,
  priority: PriorityCell,
  'frequency-cap-list': FrequencyCapCell,
  'targeting-rules': TargetingRulesCell,
  'targeting-rule': TargetingCell,
  targeting: TargetingCell,
  tier: TierCell,
  'type-properties': TypePropertiesCell,
  'type-properties.secondarypacelist': SecondaryPaceCell,
  'publisher-target': TargetingCell,
  'non-billable-ad-tracking-list': TagsCell,
  'click-through': TagsCell,
  'billable-ad-tracking': TagsCell,
};

// FieldNames that should never have an old value
const noInitialOldValue = ['actual-start'];

const siblingPayloadNullCurrentPayloadTruthy = (
  otherPayload: Pick<SingleValueChange, 'oldValue'>,
  currentPayload: Pick<SingleValueChange, 'newValue'>
): boolean => {
  const otherValue = Array.isArray(otherPayload)
    ? otherPayload.every(
        (item: SimpleChangeValue) =>
          item && Object.values(item).every((x) => (x && Array.isArray(x) ? x[0] === null : x === null || x === ''))
      )
    : otherPayload === null;
  return otherValue && currentPayload !== null;
};

function Cell({ row, value, column }: CellProps<ChangeLogRecord>): JSX.Element | null {
  // If old was null and new is present, it’s an addition, if new is null and old is present, it’s a removal
  const { oldValue, newValue, fieldName } = row.values;

  const action = {
    addition: siblingPayloadNullCurrentPayloadTruthy(oldValue.payload, newValue.payload) && column.id !== 'oldValue',
    removal: siblingPayloadNullCurrentPayloadTruthy(newValue.payload, oldValue.payload) && column.id !== 'newValue',
  };
  const fieldNameAsKey = fieldName.toLowerCase();
  const valueHasMultipleItems = Array.isArray(value) || Array.isArray(value.payload) || value.payload instanceof Object;
  const terms = value?.payload?.definition?.termList || value?.payload?.definition?.['term-list'];
  const areTermsValid =
    (value?.payload?.definition?.termList && value?.payload?.definition?.termList[0]?.dimension) ||
    (value?.payload?.definition?.['term-list'] && value?.payload?.definition?.['term-list'][0]?.dimension);
  const isOnlyOneTerm = value?.payload?.definition?.dimension;

  if (valueHasMultipleItems) {
    // If Items have specific formatting, use formatter, else, use default
    return fieldNameCellItems[fieldNameAsKey] ? (
      React.createElement(fieldNameCellItems[fieldNameAsKey], {
        changes: areTermsValid
          ? terms.map((term: Term) => ({ payload: term }))
          : isOnlyOneTerm
          ? { payload: value?.payload?.definition }
          : value,
        action,
      })
    ) : (
      <DefaultCellItems changes={value} action={action} />
    );
  }

  return column.id === 'oldValue' && noInitialOldValue.includes(fieldNameAsKey) ? (
    <span>-</span>
  ) : (
    <CellItem value={value} fieldNameAsKey={fieldNameAsKey} action={action} />
  );
}

export default Cell;
