import './NewAdTagRow.scss';

import TextAreaAnt from 'antd/lib/input/TextArea';
import { findIndex as _findIndex } from 'lodash';
import React from 'react';

import type { AdTagType, EventType, UNRECOGNIZED } from '../../../configs';
import { adTagEvents } from '../../../configs';
import type { AdTag, Nullable } from '../../../models';
import bem from '../../../utils/bem';
import Button from '../../Button';
import { configToDropdownOption } from '../../Dropdown';
import type { DropdownOption, DropdownProps, SingleSelectValue } from '../../Dropdown/Dropdown';
import Dropdown from '../../Dropdown/Dropdown';
import { isRecognizedOption } from '../../Dropdown/utils';
import { useFieldFast } from '../../Form/hooks';
import {
  commonTagEventDropdownProps,
  commonUrlTextAreaProps,
  getAdTagEventDropdownOptionsByType,
  getUpdatedTagByType,
} from '../utils';

export interface NewAdTagRowProps {
  addNewTag: (newTag: AdTag) => void;
  commonTagTypeDropdownProps: Pick<
    DropdownProps<AdTagType | typeof UNRECOGNIZED>,
    'classNameModifier' | 'isSearchable' | 'options'
  >;
  readonly?: boolean;
}

const [block, element] = bem('new-ad-tag-row');

export type NewTag = Pick<AdTag, 'id' | 'url' | 'isVerified' | 'event' | 'unDeletable' | 'isIas'> & {
  type: Nullable<AdTag['type']>; // An empty tag has a null tag type
};

const emptyNewTag: NewTag = {
  id: '',
  type: null,
  url: '',
  event: adTagEvents.START, // event/position dropdown is not rendered until a tag type is selected
  isVerified: false,
  unDeletable: false,
  isIas: null,
};

function isCompleteAdTag(newTag: NewTag): newTag is AdTag {
  return !!newTag.type && !!newTag.event && !!newTag.url;
}

function NewAdTagRow({ addNewTag, commonTagTypeDropdownProps, readonly }: NewAdTagRowProps): JSX.Element {
  const [, { value: newAdTag }, { setValue: setNewAdTag }] = useFieldFast<NewTag>('newAdTag');

  // if chosen DropdownOption is updated to isDisabled=true, reset selected DropdownOption
  const { options } = commonTagTypeDropdownProps;
  if (options) {
    const indexValue = _findIndex(options, ['value', newAdTag.type?.key]);

    if (indexValue !== -1 && (options[indexValue] as DropdownOption<AdTagType>).isDisabled) {
      setNewAdTag({ ...newAdTag, type: emptyNewTag.type });
    }
  }

  function handleTagTypeChange(newType: SingleSelectValue<AdTagType | typeof UNRECOGNIZED>): void {
    if (isRecognizedOption(newType)) setNewAdTag(getUpdatedTagByType(newAdTag, newType));
  }

  function handleTagEventChange(newEvent: SingleSelectValue<EventType | typeof UNRECOGNIZED>): void {
    if (isRecognizedOption(newEvent)) setNewAdTag({ ...newAdTag, event: adTagEvents[newEvent.value] });
  }

  function handleTagUrlChange(e: React.ChangeEvent<HTMLTextAreaElement>): void {
    setNewAdTag({ ...newAdTag, url: e.target.value });
  }

  function addTag(): void {
    if (isCompleteAdTag(newAdTag)) {
      addNewTag({ ...newAdTag, isVerified: newAdTag.isVerified || emptyNewTag.isVerified, url: newAdTag.url.trim() });
      setNewAdTag(emptyNewTag);
    }
  }

  const eventDropdownOptions = newAdTag.type ? getAdTagEventDropdownOptionsByType(newAdTag.type) : [];

  const shouldShowEventDropdown = eventDropdownOptions.length > 1;

  return (
    <tr className={block()}>
      <td>
        <Dropdown<AdTagType | typeof UNRECOGNIZED>
          {...commonTagTypeDropdownProps}
          value={newAdTag.type ? configToDropdownOption(newAdTag.type) : null}
          onChange={handleTagTypeChange}
          isDisabled={readonly}
        />
      </td>
      <td colSpan={shouldShowEventDropdown ? 1 : 2}>
        <TextAreaAnt
          {...commonUrlTextAreaProps}
          value={newAdTag.url}
          onChange={handleTagUrlChange}
          className={element('url-textarea', !shouldShowEventDropdown ? 'expanded' : [])}
          disabled={readonly}
        />
      </td>

      {shouldShowEventDropdown && (
        <td>
          <Dropdown<EventType | typeof UNRECOGNIZED>
            {...commonTagEventDropdownProps}
            value={newAdTag.event ? configToDropdownOption(newAdTag.event) : null}
            options={eventDropdownOptions}
            onChange={handleTagEventChange}
            isDisabled={readonly}
          />
        </td>
      )}

      <td colSpan={2}>
        <Button
          icon="IconCirclePlus"
          aria-label="add tag"
          className={element('add-btn')}
          onClick={addTag}
          disabled={!isCompleteAdTag(newAdTag) || readonly}
        >
          Add
        </Button>
      </td>
    </tr>
  );
}

export default NewAdTagRow;
