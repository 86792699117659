import { gql } from '@apollo/client';

import { TARGETING_RULE_DEFINITION, TARGETING_TERM_VALUES } from '../../../../../apis/graphql';

export const UPDATE_AD_TARGETING_TERM_VALUES = gql`
  mutation UpdateAdTargetingValues(
    $id: ID!
    $newTargetingValues: UpdateAdInputV5!
    $targetingRule: TargetingRuleInput
  ) {
    updateAdV5(id: $id, input: $newTargetingValues, targetingRule: $targetingRule) {
      targetingTermValues {
        ...targetingTermValues
      }
      targetingRule {
        ...targetingRuleDefinition
      }
    }
  }
  ${TARGETING_TERM_VALUES}
  ${TARGETING_RULE_DEFINITION}
`;
