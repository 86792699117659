import React from 'react';

import type { TargetingPreset } from '../../../apis/graphql';
import type { Nullable, TargetingTermValue } from '../../../models';
import TargetingTable from '../../TargetingTable';

type TargetingPresetDetailsProps = {
  targetingPreset: Nullable<TargetingPreset>;
};

const TargetingPresetDetails = ({ targetingPreset }: TargetingPresetDetailsProps): JSX.Element => {
  const targetingTermValues = (targetingPreset?.definition || []) as TargetingTermValue[];

  return (
    <div>
      <h1>{targetingPreset?.name || ''}</h1>
      <p>{targetingPreset?.description || ''}</p>
      <TargetingTable
        isTableToggleable={false}
        isReadOnly={false}
        data={[
          {
            targetingTermValues,
          },
        ]}
      />
    </div>
  );
};

export default TargetingPresetDetails;
