import './CreativeReviewBadge.scss';

import type { ReactNode } from 'react';
import React from 'react';

import type { BaseAssetV5 } from '../../../apis/graphql';
import Alert from '../../../common/Alert';
import type { AlertProps } from '../../../common/Alert/Alert';
import Loader from '../../../common/Loader';
import { ReviewStatusModal } from '../../../common/StatusModal';
import type { Nullable } from '../../../models';
import bem from '../../../utils/bem';

interface CreativeReviewBadgeProps {
  alertProps?: Nullable<AlertProps>;
  asset?: BaseAssetV5 | null;
  loading?: boolean;
  creativeStatus?: string;
  creativeReview?: string;
}

const [block] = bem('creative-review-badge');

function withWrapper(component: ReactNode): JSX.Element {
  return <div className={block()}>{component}</div>;
}

const CreativeReviewBadge: React.FC<CreativeReviewBadgeProps> = ({ alertProps, asset, creativeReview, loading }) => {
  if (loading) return withWrapper(<Loader />);

  if (!asset && !creativeReview) return withWrapper(null);

  if (asset) {
    const isRejected = asset.rejectionReasons && asset.review === 'REJECTED';

    if (isRejected) return withWrapper(<ReviewStatusModal asset={asset} />);
  }

  return withWrapper(alertProps ? <Alert {...alertProps} /> : null);
};

export default CreativeReviewBadge;
