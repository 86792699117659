import React from 'react';
import type { Column } from 'react-table';

import Tooltip from '../../../../../../../common/Tooltip';
import type { MultipleEditDrawerContentFirstStepTableData } from './types';

export const getColumns = (className: string): Column<MultipleEditDrawerContentFirstStepTableData>[] => [
  {
    accessor: 'id',
    Cell: ({ cell: { value } }): JSX.Element => (
      <Tooltip id="edit-multiple-table-id" message={value}>
        <div className={className}>{value}</div>
      </Tooltip>
    ),
    Header: 'ID',
  },
  {
    accessor: 'name',
    disableSortBy: true,
    Cell: ({ cell: { value } }): JSX.Element => (
      <Tooltip id="edit-multiple-table-name" message={value}>
        <div className={className}>{value}</div>
      </Tooltip>
    ),
    Header: 'Name',
  },
];
