import './DefaultFormContainer.scss';

import { bem } from 'mission-control-common-components';
import React from 'react';

import Button from '../Button';

const [block, element] = bem('default-form-container');

export type DefaultFormContainerProps = {
  title?: string;
  children?: React.JSX.Element;
  handleReset: (event: unknown) => void;
  submitForm: (event: Event) => void;
  handleResetTitle: string;
  submitTitle: string | React.JSX.Element;
  isHandleResetDisabled?: boolean;
  isSubmitFormDisabled?: boolean;
  handleResetAriaLabel: string;
  submitFormAriaLabel: string;
};

const DefaultFormContainer = ({
  title,
  children,
  handleReset,
  submitForm,
  handleResetTitle,
  submitTitle,
  isHandleResetDisabled,
  isSubmitFormDisabled,
  handleResetAriaLabel,
  submitFormAriaLabel,
}: DefaultFormContainerProps): React.JSX.Element => {
  return (
    <div className={block()}>
      <header className={element('header')}>
        <h1>{title}</h1>

        <div className={element('header-actions')}>
          <Button
            size="medium"
            className={element('discard-changes-btn')}
            onClick={handleReset}
            disabled={isHandleResetDisabled}
            aria-label={handleResetAriaLabel}
          >
            {handleResetTitle}
          </Button>
          <Button
            size="medium"
            className={element('save-btn')}
            onClick={submitForm}
            disabled={isSubmitFormDisabled}
            aria-label={submitFormAriaLabel}
          >
            {submitTitle}
          </Button>
        </div>
      </header>
      <div className={element('body-container')}>{children}</div>
    </div>
  );
};

export default DefaultFormContainer;
