import type {
  AdsPageAdFragment,
  CreateAdsPageAdMutation,
  GetSavedAdsQuery,
  GetSavedAdsQueryVariables,
  TargetingTermValue,
} from '../../../../../apis/graphql';
import { campaignClient } from '../../../../../apis/graphql';
import type { AdFormValues } from '../../../../../common/AdForm/adFormik';
import { getEarliestDateRange, getLatestDateRange } from '../../../../../common/utils';
import { GET_SAVED_ADS } from '../../queries';

/** We want to take our newly created ad and write it to the cache **/
export function writeAdToCache(
  lineItemId: string,
  adFormValues: AdFormValues,
  newAdData: CreateAdsPageAdMutation
): void {
  // If we do not have data, we will skip writing to the cache
  if (!newAdData?.createAd) return;

  const currentAds = campaignClient.readQuery<GetSavedAdsQuery, GetSavedAdsQueryVariables>({
    query: GET_SAVED_ADS,
    variables: { lineItemIds: [lineItemId], lineItemId },
  });

  const newAdSchedule = newAdData.createAd.schedule;

  const createdAd: AdsPageAdFragment = {
    ...newAdData.createAd,
    startDate: getEarliestDateRange(newAdSchedule)?.startDate,
    endDate: getLatestDateRange(newAdSchedule)?.endDate,
    schedule: newAdSchedule,
    targetingTermValues: newAdData.createAd.targetingTermValues.length
      ? newAdData.createAd.targetingTermValues
      : (adFormValues.targetingTermValues as TargetingTermValue[]),
  };

  const newEdges: GetSavedAdsQuery['ads']['edges'] = [{ node: createdAd, __typename: 'AdEdgeV5' }];

  if (currentAds) {
    newEdges.push(...currentAds.ads.edges);
  }

  campaignClient.writeQuery<GetSavedAdsQuery, GetSavedAdsQueryVariables>({
    query: GET_SAVED_ADS,
    variables: { lineItemIds: [lineItemId], lineItemId },
    data: {
      ads: {
        edges: newEdges,
        __typename: 'AdsPageV5',
      },
      lineItems: {
        edges: currentAds?.lineItems.edges || [],
      },
      getTargetingTermValuesByLineItemIDV5: currentAds?.getTargetingTermValuesByLineItemIDV5 ?? [],
    },
  });
}
