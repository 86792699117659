import './MultiOption.scss';

import React from 'react';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';

import type { Nullable } from '../../../models';
import bem from '../../../utils/bem';
import ControlledCheckbox from '../../ControlledCheckbox';
import type { DropdownOption, DropdownOptionGroup } from '../../Dropdown';
import Tooltip from '../../Tooltip/Tooltip';
import { CLEAR_OPTION } from '../MultiSelectContainer/hooks/useMultiSelectContainer';

const [block, element] = bem('multi-option');

function MultiOption<T = string>({
  children,
  ...props
}: OptionProps<DropdownOption<T>, true, DropdownOptionGroup<T>>): Nullable<JSX.Element> {
  if (props.label === props.selectProps.multiOptionLabel && props.selectProps.inputValue) return null;

  return (
    <components.Option {...props} className={block()}>
      {props.data === CLEAR_OPTION ? (
        <span data-testid="clear-options" className={element('clear-text', props.hasValue ? [] : ['disabled'])}>
          {children}
        </span>
      ) : (
        <>
          <ControlledCheckbox
            tabIndex={-1}
            checked={props.isSelected}
            indeterminate={false}
            // checkbox itself does not have a special click/key handler. Use the handler on the dropdown option.
            onChange={(): void => {}}
            onKeyDown={(): void => {}}
          />
          {props?.data?.tooltip ? <Tooltip {...props.data.tooltip}>{children}</Tooltip> : children}
        </>
      )}
    </components.Option>
  );
}

export default MultiOption;
