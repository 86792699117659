import './TableFilterBar.scss';

import React, { useMemo, useState } from 'react';

import type { ScheduleFilterV5, TraffickingFilters } from '../../../apis/graphql';
import Button from '../../../common/Button';
import type { DropdownOption, SingleSelectValue } from '../../../common/Dropdown/Dropdown';
import { openToastAlert } from '../../../common/ToastAlert/toastAlert';
import ToggleSwitch from '../../../common/ToggleSwitch';
import { TRAFFICKING_SEARCH_PARAMS } from '../../../constants';
import { useFiltersContext } from '../../../contexts/FilterContext';
import bem from '../../../utils/bem';
import AdditionalFilters from './AdditionalFilters';
import type { AdditionalFilterDropdown, SelectedFiltersValues } from './AdditionalFilters/constants';
import SelectedFiltersChips from './AdditionalFilters/SelectedFiltersChips';
import { DateFilter } from './DateFilter';
import type { DateType } from './DateFilter/DateFilter';
import {
  useGetAccountExecutives,
  useGetTraffickerEmails,
  useHandleDropdownFilters,
  useSaveTraffickingFilters,
} from './hooks';
import UserFilter from './UserFilter';

export type TableFilterProps = {
  applyCommonFilter: <T extends keyof TraffickingFilters>(filterName: T, filterValue: TraffickingFilters[T]) => void;
};

const [block, element] = bem('table-filter-bar');

const TableFilterBar = ({ applyCommonFilter }: TableFilterProps): React.JSX.Element => {
  const { saveFilters, loading } = useSaveTraffickingFilters();
  const {
    filters: {
      accountExecutive,
      accountManager,
      generalPacingStatusList,
      orderTypeList,
      reviewStatusList,
      adStatusList,
      productType,
      lineItemStatusList,
      billableThirdPartyList,
      priorityValueList,
      tierNameList,
      publisherList,
      adRatingList,
      adTypeList,
      creativeReviewList,
      creativeTypeList,
      isAddedValue,
      isMakegood,
      isCoppaOrCaru,
      agencyIdList,
      brandIdList,
      industryIdList,
      currencyList,
      isRotation,
      isSequence,
      countryList,
      dateFilter,
      pacingRiskList,
      hasMisalignedSchedule,
    },
  } = useFiltersContext();

  const [selectedDropdownFilters, setSelectedDropdownFilters] = useState<AdditionalFilterDropdown[]>([]);

  const setDateFilter = (v?: SingleSelectValue<DateType>): void => {
    if (v) {
      applyCommonFilter(TRAFFICKING_SEARCH_PARAMS.DATE_FILTER, v as DropdownOption<ScheduleFilterV5>);
    }
  };

  const setAccountManagersFilter = (dropdownOptions: DropdownOption[]): void =>
    applyCommonFilter(TRAFFICKING_SEARCH_PARAMS.ACCOUNT_MANAGER, dropdownOptions);

  const setAccountExecutivesFilter = (dropdownOptions: DropdownOption[]): void =>
    applyCommonFilter(TRAFFICKING_SEARCH_PARAMS.ACCOUNT_EXECUTIVE, dropdownOptions);

  const setHasZeroDeliveryFilter = (isChecked: boolean): void => {
    applyCommonFilter(TRAFFICKING_SEARCH_PARAMS.PACING_RISK_LIST, isChecked ? ['ZERO_DELIVERY'] : []);
  };

  const setHasMisalignedSchedule = (isChecked: boolean): void => {
    applyCommonFilter(TRAFFICKING_SEARCH_PARAMS.HAS_MISALIGNED_SCHEDULE, isChecked ? true : null);
  };

  const selectedFiltersValues: SelectedFiltersValues = useMemo(
    () => ({
      [TRAFFICKING_SEARCH_PARAMS.GENERAL_PACING_STATUS_LIST]: generalPacingStatusList,
      [TRAFFICKING_SEARCH_PARAMS.REVIEW_STATUS_LIST]: reviewStatusList,
      [TRAFFICKING_SEARCH_PARAMS.ORDER_TYPE_LIST]: orderTypeList,
      [TRAFFICKING_SEARCH_PARAMS.AD_STATUS_LIST]: adStatusList,
      [TRAFFICKING_SEARCH_PARAMS.PRODUCT_TYPE]: productType,
      [TRAFFICKING_SEARCH_PARAMS.CURRENCY_LIST]: currencyList,
      [TRAFFICKING_SEARCH_PARAMS.LINE_ITEM_STATUS_LIST]: lineItemStatusList,
      [TRAFFICKING_SEARCH_PARAMS.BILLABLE_THIRD_PARTY_LIST]: billableThirdPartyList,
      [TRAFFICKING_SEARCH_PARAMS.PRIORITY_VALUE_LIST]: priorityValueList,
      [TRAFFICKING_SEARCH_PARAMS.TIER_NAME_LIST]: tierNameList,
      [TRAFFICKING_SEARCH_PARAMS.PUBLISHER_LIST]: publisherList,
      [TRAFFICKING_SEARCH_PARAMS.AD_RATING_LIST]: adRatingList,
      [TRAFFICKING_SEARCH_PARAMS.AD_TYPE_LIST]: adTypeList,
      [TRAFFICKING_SEARCH_PARAMS.CREATIVE_REVIEW_LIST]: creativeReviewList,
      [TRAFFICKING_SEARCH_PARAMS.CREATIVE_TYPE_LIST]: creativeTypeList,
      [TRAFFICKING_SEARCH_PARAMS.IS_ADDED_VALUE]: isAddedValue,
      [TRAFFICKING_SEARCH_PARAMS.IS_MAKEGOOD]: isMakegood,
      [TRAFFICKING_SEARCH_PARAMS.IS_COPPA_OR_CARRU]: isCoppaOrCaru,
      [TRAFFICKING_SEARCH_PARAMS.AGENCY_ID_LIST]: agencyIdList,
      [TRAFFICKING_SEARCH_PARAMS.BRAND_ID_LIST]: brandIdList,
      [TRAFFICKING_SEARCH_PARAMS.INDUSTRY_ID_LIST]: industryIdList,
      [TRAFFICKING_SEARCH_PARAMS.IS_ROTATION]: isRotation,
      [TRAFFICKING_SEARCH_PARAMS.IS_SEQUENCE]: isSequence,
      [TRAFFICKING_SEARCH_PARAMS.COUNTRY_LIST]: countryList,
    }),
    [
      generalPacingStatusList,
      orderTypeList,
      reviewStatusList,
      adStatusList,
      productType,
      lineItemStatusList,
      billableThirdPartyList,
      priorityValueList,
      tierNameList,
      publisherList,
      adRatingList,
      adTypeList,
      creativeReviewList,
      creativeTypeList,
      isAddedValue,
      isMakegood,
      isCoppaOrCaru,
      agencyIdList,
      brandIdList,
      industryIdList,
      currencyList,
      isRotation,
      isSequence,
      countryList,
    ]
  );

  const {
    isFilterChipShown,
    onRemoveAllFilter,
    onRemoveSingleFilter,
    transformedSelectedDropdownFilters,
  } = useHandleDropdownFilters({
    selectedFiltersValues,
    applyCommonFilter,
    selectedDropdownFilters,
    onSelectedDropdownFilters: setSelectedDropdownFilters,
  });

  const handleGenerateShareableUrl = async (): Promise<void> => {
    try {
      const shareableUrl = await saveFilters();

      // Clipboard is defined only in https
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(shareableUrl);
      }

      openToastAlert({
        alertType: 'success',
        message: 'Shareable URL was successfully generated and copied',
      });
    } catch (err) {
      openToastAlert({
        alertType: 'error',
        message: 'Something went wrong. Please try to generate shareable URL later',
      });
    }
  };

  return (
    <div className={block()}>
      <div className={element('filter-bar-wrapper')}>
        <AdditionalFilters
          selectedFiltersValues={selectedFiltersValues}
          applyFilter={applyCommonFilter}
          selectedDropdownFilters={selectedDropdownFilters}
          onSelectedDropdownFilters={setSelectedDropdownFilters}
          onRemoveSingleFilter={onRemoveSingleFilter}
        />
        <DateFilter selectedValue={dateFilter} onChange={setDateFilter} />
        <UserFilter
          label="Account Manager"
          selectedValues={accountManager ?? []}
          applyFilter={setAccountManagersFilter}
          useGetUsers={useGetTraffickerEmails}
          userRole="TRAFFICKER"
          entityFilterOption="PUBLISHER_ORGANIZATION"
          entityIdOption="ALL_ORGS"
          valueKey="email"
        />
        <UserFilter
          label="Account Executive"
          selectedValues={accountExecutive ?? []}
          applyFilter={setAccountExecutivesFilter}
          useGetUsers={useGetAccountExecutives}
        />
        <ToggleSwitch isOn={!!pacingRiskList?.length} onChange={setHasZeroDeliveryFilter} label="Zero Delivery" />
        <ToggleSwitch isOn={!!hasMisalignedSchedule} onChange={setHasMisalignedSchedule} label="Schedule Conflict" />
        <Button
          data-testid="shareable-url-button"
          className="shareable-url-button"
          onClick={handleGenerateShareableUrl}
          loading={loading}
        >
          Generate and copy shareable URL
        </Button>
      </div>
      <div className={element(`${isFilterChipShown ? block('chips-with-space') : block('chips-with-no-space')}`)}>
        {isFilterChipShown && (
          <SelectedFiltersChips
            selectedFilters={transformedSelectedDropdownFilters}
            onRemoveSingleChip={onRemoveSingleFilter}
            onRemoveAllChips={onRemoveAllFilter}
          />
        )}
      </div>
    </div>
  );
};

export default TableFilterBar;
