import { adTagTypes } from '../../../../../../../../../../configs';
import type { MultipleEditDrawerAdsFormValues } from '../../../../../../types';
import { AD_HAS_BILLABLE_TAG, AD_HAS_CLICK_TAG, AD_HAS_NON_BILLABLE_TAG_DUPLICATES } from '../../getRows/constants';

export const checkTagsConflicts = ({
  entities,
  adTagList,
  selectedSections,
}: MultipleEditDrawerAdsFormValues): Record<string, string[]> | null => {
  const isSectionSelected = selectedSections.includes('Tags');
  if (!isSectionSelected) {
    return null;
  }

  const errors: Record<string, string[]> = {};
  const newTagsHaveClickTag = adTagList.some(({ type }) => type.key === adTagTypes.CLICK.key);
  const newTagsHaveBillableTag = adTagList.some(({ type }) => type.key === adTagTypes.BILLABLE.key);
  const newTagsNonBillable = adTagList.filter(({ type }) => type.key === adTagTypes.NON_BILLABLE.key);

  entities.forEach(({ hasClickTag, hasBillableTag, id, adTagList: entityTagList }) => {
    if (newTagsHaveClickTag && hasClickTag) {
      errors[AD_HAS_CLICK_TAG] = errors[AD_HAS_CLICK_TAG] ? [...errors[AD_HAS_CLICK_TAG], id] : [id];
    }

    if (newTagsHaveBillableTag && hasBillableTag) {
      errors[AD_HAS_BILLABLE_TAG] = errors[AD_HAS_BILLABLE_TAG] ? [...errors[AD_HAS_BILLABLE_TAG], id] : [id];
    }

    const entityTagsNonBillable = entityTagList.filter(({ type }) => type.key === adTagTypes.NON_BILLABLE.key);

    newTagsNonBillable.forEach(({ url }) => {
      if (
        entityTagsNonBillable.find(({ url: entityUrl }) => url === entityUrl) &&
        !errors[AD_HAS_NON_BILLABLE_TAG_DUPLICATES]?.includes(id)
      ) {
        errors[AD_HAS_NON_BILLABLE_TAG_DUPLICATES] = errors[AD_HAS_NON_BILLABLE_TAG_DUPLICATES]
          ? [...errors[AD_HAS_NON_BILLABLE_TAG_DUPLICATES], id]
          : [id];
      }
    });
  });

  return Object.keys(errors).length ? errors : null;
};
