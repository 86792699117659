import { AlertFiled } from '../TraffickingPage/cells/AlertCell/AlertCell';

export const ALERT_FIELD_TO_DISPLAY_NAME_CONFIG: Record<AlertFiled, string> = {
  [AlertFiled.ZeroDelivery]: 'zero delivery',
  [AlertFiled.MisalignedSchedule]: 'scheduling conflict',
  [AlertFiled.InventoryBlock]: 'has inventory block',
  [AlertFiled.LineItem]: 'has unlinked line item',
};

export const HEADER_COLUMNS_TO_IGNORE = ['CreateAd', 'EditAd', 'deleteAd'];

export const COUNT_ROWS_TO_SHOW_WARNING = 5000;

export const CHUNK_SHIFT = 100;

export const COUNT_PARALLEL_REQUESTS_PER_CHUNK = 5;

export const COUNT_RETRIES = 5;

export const EXPORT_LIMIT_PER_PAGE = 1000;

export const EXPORT_MAX_LIMIT = 50000;

export const SELECTED_ENTITY_MARK = '+';

export const NOT_SELECTED_ENTITY_MARK = '-';
