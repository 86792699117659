import './MultiSelectDropdown.scss';

import { useField } from 'formik';
import React, { useCallback, useMemo } from 'react';

import type { Config, ConfigMap, DisplayEnum } from '../../../configs';
import { UNRECOGNIZED } from '../../../configs';
import bem from '../../../utils/bem';
import type { DropdownOption, DropdownOptionGroup } from '../../Dropdown';
import { configToDropdownOption } from '../../Dropdown';
import BaseMultiSelectDropdown from '../../MultiSelectDropdown';
import type {
  MultiSelectDropdownProps as BaseMultiSelectDropdownProps,
  MultiSelectValue,
} from '../../MultiSelectDropdown/MultiSelectDropdown';
import { findSetDefaultValue } from './helpers';

const [block] = bem('form-multiselect-dropdown');

export type DropdownType<T> = T | typeof UNRECOGNIZED;

export type MultiSelectDropdownProps<T extends string> = Pick<
  BaseMultiSelectDropdownProps<T>,
  'label' | 'multiOptionLabel' | 'isDisabled'
> & {
  options: ConfigMap<T>;
  formFieldName: string;
  withDefaultValue?: Config<T>;
  handleChange?: (newValue: MultiSelectValue<T | typeof UNRECOGNIZED>) => void;
};

function MultiSelectDropdown<T extends string>({
  label,
  options,
  formFieldName,
  multiOptionLabel,
  withDefaultValue,
  isDisabled,
  handleChange,
}: MultiSelectDropdownProps<T>): JSX.Element {
  const [, { value: fieldValue, error: fieldError }, { setValue }] = useField<DisplayEnum<T>[]>(formFieldName);

  const formMultiSelectOptions: ReadonlyArray<DropdownOption<T> | DropdownOptionGroup<T>> = useMemo(() => {
    const optionsArray: Config<T>[] = Object.values(options);
    const dropdownOptions: ReadonlyArray<DropdownOption<T> | DropdownOptionGroup<T>> = optionsArray.map(
      configToDropdownOption
    );
    return dropdownOptions;
  }, [options]);

  const existingSelections: MultiSelectValue<DropdownType<T>> = fieldValue.map(configToDropdownOption);

  const handleMultiSelectChange = useCallback(
    (newValue: MultiSelectValue<T | typeof UNRECOGNIZED>): void => {
      handleChange
        ? handleChange(newValue)
        : setValue(
            newValue.map((nv) =>
              nv.value === UNRECOGNIZED ? { key: UNRECOGNIZED, displayName: nv.label } : options[nv.value]
            )
          );
    },
    [handleChange, options, setValue]
  );

  const defaultValue = findSetDefaultValue(withDefaultValue, existingSelections);
  return (
    <div className={block()}>
      <BaseMultiSelectDropdown<DropdownType<T>>
        label={label}
        value={existingSelections}
        maxMenuHeight={400}
        loading={!fieldValue}
        error={fieldError}
        multiOptionLabel={multiOptionLabel}
        onChange={handleMultiSelectChange}
        options={formMultiSelectOptions}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
      />
    </div>
  );
}

export default MultiSelectDropdown;
