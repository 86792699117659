import React from 'react';

import Loader from '../common/Loader';

const RefreshComponent = (): JSX.Element => {
  const localString = 'localhost';
  const huluString = 'hulu.com';
  const disneyString = 'disneyadvertising';
  const location = window.location;

  if (location.href.includes(localString)) {
    return (
      <div>
        You have reached local development for a route that has been moved to{' '}
        <a href="https://github.prod.hulu.com/AdPlatformV2/mission-control-portal-ui">mc-portal</a> as part of a
        maintenance project.
      </div>
    );
  }
  if (location.href.includes(huluString)) {
    return (
      <div>
        You are using an internal route. The URL should include '{disneyString}' for routing to mc-portal to work.
      </div>
    );
  }

  // refreshes the page in case a user triggers internal react routing ie using the back button
  // forces DNS to redirect using Gopher to the external route - mc-portal
  return (
    <>
      {location.reload()}
      <Loader className={'main'} />
    </>
  );
};

export default RefreshComponent;
