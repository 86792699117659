import type { Hooks, TableInstance } from 'react-table';

export type DeleteItemsCallback = (arg: string[], toggleSelectedCallback: () => void) => void;

type UseDeleteItemsInstanceType<T extends object> = (instance: TableInstance<T>) => void;

export type DeleteItemsPluginType<T extends object> = (hooks: Hooks<T>) => void;

function makeUseInstance<T extends object>(deleteItemsCallback: DeleteItemsCallback): UseDeleteItemsInstanceType<T> {
  return function useInstance(instance: TableInstance<T>): void {
    Object.assign(instance, { deleteItemsCallback });
  };
}

function makeDeleteItemsPlugin<T extends object>(deleteItemsCallback: DeleteItemsCallback): DeleteItemsPluginType<T> {
  return function usePageDrawerPlugin(hooks: Hooks<T>): void {
    hooks.useInstance.push(makeUseInstance(deleteItemsCallback));
  };
}

export default makeDeleteItemsPlugin;
