import { adStatuses, reviews } from '../../../../../../../../../../configs';
import type { AdEntity } from '../../../../../../types';
import { AD_STATUS_CONFLICT_MESSAGE } from '../../getRows/constants';

type Props = {
  entities: Array<AdEntity>;
  isSectionSelected: boolean;
};

export const checkStatusAdsConflicts = ({ entities, isSectionSelected }: Props): Record<string, string[]> | null => {
  if (!isSectionSelected) {
    return null;
  }

  const errors: Record<string, string[]> = {};

  entities.forEach(({ review, status, id }) => {
    if (review !== reviews.APPROVED && status === adStatuses.ON) {
      errors[AD_STATUS_CONFLICT_MESSAGE] = errors[AD_STATUS_CONFLICT_MESSAGE]
        ? [...errors[AD_STATUS_CONFLICT_MESSAGE], id]
        : [id];
    }
  });

  return Object.keys(errors).length ? errors : null;
};
