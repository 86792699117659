import type { ColumnInstance } from 'react-table';

import type { ControlledColumn } from '../ColumnControl';
import type { EntityModel } from '../TraffickingPage/modelConverters';

export const getSelectedColumns = (columns: ColumnInstance<EntityModel>[]): Record<string, boolean> => {
  const selectedColumnsId: Record<string, boolean> = {};

  columns.forEach((column: ColumnInstance<EntityModel>) => {
    if (typeof column.Header === 'string') {
      selectedColumnsId[column.id] = column.isVisible;
    }
  });

  return selectedColumnsId;
};

export const getHiddenColumnsFromSelectedIds = (selectedIds: Record<string, boolean>): string[] => {
  return Object.keys(selectedIds).reduce<string[]>((hiddenColumns, columnId) => {
    return selectedIds[columnId] ? hiddenColumns : [...hiddenColumns, columnId];
  }, []);
};

export const getColumnVisibility = (column: ControlledColumn, value: boolean): boolean => {
  // If the column cannot be hidden, return true to ensure it is always visible and checked
  if (column.disableHiding) {
    return true;
  }

  // If column is hidden by default (isColumnHiddenByDefault = true) then make it hidden when we restore to default (return false to keep it unchecked)
  if (column.isColumnHiddenByDefault) {
    return false;
  }

  return value;
};

export const setColumnsSelectValue = (columns: ControlledColumn[], value: boolean): Record<string, boolean> => {
  return columns.reduce<Record<string, boolean>>((acc, curr) => {
    const isVisible = getColumnVisibility(curr, value);

    return { ...acc, [curr.id]: isVisible };
  }, {});
};

export const getControlledColumns = (columns: ColumnInstance<EntityModel>[]): ControlledColumn[] => {
  return columns.reduce<ControlledColumn[]>((acc, column) => {
    if (typeof column.Header !== 'string') {
      return acc;
    }

    const { Header, id, isVisible, disableHiding, isColumnHiddenByDefault } = column;

    return [...acc, { Header, id, isVisible, disableHiding, isColumnHiddenByDefault }];
  }, []);
};
