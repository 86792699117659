import { DIMENSION_INCLUDE_EXCLUDE_LIST } from '../../../../../../../../../../common/TargetingDropdowns/constants';
import type { TargetingTermValue } from '../../../../../../../../../../models';
import type { MultipleEditDrawerLineItemsFormValues } from '../../../../../../types';
import type { TargetingConflictResult } from '../useCheckTargetingConflicts/useCheckTargetingConflicts';

type Props = {
  existedTargeting?: Record<string, TargetingTermValue[]>;
  formValuesTargeting: MultipleEditDrawerLineItemsFormValues['targetingTermValues'];
};

export type ResultGetTargetConflicts = {
  hasAlreadyThisTarget: boolean;
  hasTargetConflict: boolean;
  hasIncludeConflict: boolean;
  hasExcludeConflict: boolean;
  entityId: string;
  conflictedTargeting: TargetingTermValue[];
};

export const checkTargetingConflicts = ({ existedTargeting, formValuesTargeting }: Props): TargetingConflictResult => {
  const result: TargetingConflictResult = {
    hasAlreadyThisTarget: [],
    hasTargetConflict: [],
    hasIncludeConflict: [],
    hasExcludeConflict: [],
  };

  if (!existedTargeting) {
    return result;
  }

  Object.entries(existedTargeting).forEach(([entityId, targets]) => {
    targets.forEach((target) => {
      formValuesTargeting.forEach((formValueTargeting) => {
        if (
          !DIMENSION_INCLUDE_EXCLUDE_LIST.includes(target.dimension.id) &&
          target.dimension.id === formValueTargeting.dimension.id
        ) {
          if (target.include && !formValueTargeting.include && !result.hasExcludeConflict.includes(entityId)) {
            result.hasExcludeConflict.push(entityId);
          }

          if (!target.include && formValueTargeting.include && !result.hasIncludeConflict.includes(entityId)) {
            result.hasIncludeConflict.push(entityId);
          }
        }

        if (target.value?.id === formValueTargeting.value?.id) {
          if (target.include === formValueTargeting.include && !result.hasAlreadyThisTarget.includes(entityId)) {
            result.hasAlreadyThisTarget.push(entityId);
          }

          if (target.include !== formValueTargeting.include && !result.hasTargetConflict.includes(entityId)) {
            result.hasTargetConflict.push(entityId);
          }
        }
      });
    });
  });

  return result;
};
