import { differenceWith as _differenceWith, isEqual as _isEqual } from 'lodash';

import type { DateRangeV5, SingleValueChange } from '../../../../apis/graphql';
import { ChangeAction } from '../../../../apis/graphql';
import { EntityType } from '../../../../pages/Trafficking/TraffickingPage/hooks/makePageDrawerPlugin';
import type {
  ChangeDetails,
  ChangesValue,
  CreateDatesChangelogResult,
  DateChanges,
  DateRageListObject,
  GetTimeZoneChangesParams,
  NormalizedFields,
  OldAndNewDates,
} from './types';

export const getFormattedDateTime = (dateTime: string, isLineItemChangelog: boolean): string => {
  return isLineItemChangelog ? dateTime.split('T')[0] : dateTime.replace('T', ' ');
};

export const normalizeFields = (
  dates: Partial<DateRangeV5>,
  isPacingChanged: boolean,
  entityType?: string
): NormalizedFields => {
  const { startDate, endDate, pacingShare } = dates;

  const isLineItemChangelog = entityType === EntityType.LINE_ITEM;

  return {
    ...(startDate && {
      start: getFormattedDateTime(startDate, isLineItemChangelog),
    }),
    ...(endDate && { end: getFormattedDateTime(endDate, isLineItemChangelog) }),
    ...(isPacingChanged && { pacingShare: pacingShare ?? '-' }),
  };
};

export const removeDuplicatedValues = ({ oldData, newData }: OldAndNewDates): DateRangeV5[][] => {
  const uniqueOld = _differenceWith(oldData, newData, _isEqual);
  const uniqueNew = _differenceWith(newData, oldData, _isEqual);

  return [uniqueOld, uniqueNew];
};

export const getTimeZoneAndViewerLocalTimeChanges = ({
  timezoneBefore,
  timezoneAfter,
  deliveryInViewerTimeBefore,
  deliveryInViewerTimeAfter,
}: GetTimeZoneChangesParams): Array<SingleValueChange> => {
  const isTimezoneChanged = timezoneBefore !== timezoneAfter;
  const isDeliveryInViewerTimeChanged = deliveryInViewerTimeBefore !== deliveryInViewerTimeAfter;

  const timezoneChanges: Array<SingleValueChange> = [];
  if (isTimezoneChanged) {
    const isShowOldTimezone = isTimezoneChanged && timezoneBefore;
    const action = isShowOldTimezone ? ChangeAction.Update : ChangeAction.Addition;

    timezoneChanges.push({
      fieldName: 'timezone',
      oldValue: {
        payload: isShowOldTimezone ? timezoneBefore : null,
        action,
      },
      newValue: {
        payload: timezoneAfter,
        action,
      },
    });
  }

  if (isDeliveryInViewerTimeChanged) {
    const isShowOldViewerLocalTime = isDeliveryInViewerTimeChanged && deliveryInViewerTimeBefore !== undefined;
    const action = isShowOldViewerLocalTime ? ChangeAction.Update : ChangeAction.Addition;

    timezoneChanges.push({
      fieldName: 'Viewer Local Time',
      oldValue: {
        payload: isShowOldViewerLocalTime ? deliveryInViewerTimeBefore : null,
        action,
      },
      newValue: {
        payload: deliveryInViewerTimeAfter,
        action,
      },
    });
  }

  return timezoneChanges;
};

const processDateChanges = (
  item: DateRangeV5,
  action: ChangeAction,
  entityType?: string
): Record<string, ChangesValue> => ({
  oldValue: {
    value:
      action === ChangeAction.Removal ? normalizeFields(item, entityType === EntityType.LINE_ITEM, entityType) : '-',
    action: ChangeAction.Removal,
  },
  newValue: {
    value:
      action === ChangeAction.Addition ? normalizeFields(item, entityType === EntityType.LINE_ITEM, entityType) : '-',
    action: action === ChangeAction.Addition ? ChangeAction.Addition : ChangeAction.Update,
  },
});

const getDateChanges = ({ oldData, newData, entityType }: OldAndNewDates): DateChanges => {
  const [uniqueOldChanges, uniqueNewChanges] = removeDuplicatedValues({
    oldData,
    newData,
  });

  const dataChanges = {
    oldValue: [] as ChangesValue[],
    newValue: [] as ChangesValue[],
  };

  const dataOldChange = [...uniqueOldChanges];
  const dataNewChange = [...uniqueNewChanges];

  while (dataOldChange.length && dataNewChange.length) {
    const changeDetails: ChangeDetails = {
      oldValue: {
        value: {},
        action: ChangeAction.Removal,
      },
      newValue: {
        value: {},
        action: ChangeAction.Update,
      },
    };

    const oldChange = dataOldChange.pop();
    const newChange = dataNewChange.pop();

    newChange &&
      Object.keys(newChange).forEach((key) => {
        const typedKey = key as keyof DateRangeV5;

        if (newChange[typedKey] !== oldChange?.[typedKey]) {
          changeDetails.oldValue.value[typedKey] = oldChange?.[typedKey];
          changeDetails.newValue.value[typedKey] = newChange[typedKey];
        }

        const isOldPacingNotEqualToNew = typedKey === 'pacingShare' && newChange[typedKey] !== oldChange?.[typedKey];

        if (isOldPacingNotEqualToNew) {
          changeDetails.oldValue.value = oldChange!;
          changeDetails.newValue.value = newChange!;
        }
      });

    const isPacingChanged = changeDetails.oldValue.value?.pacingShare !== changeDetails.newValue.value?.pacingShare;

    dataChanges.oldValue.push({
      ...changeDetails.oldValue,
      value: normalizeFields(changeDetails.oldValue.value, isPacingChanged, entityType),
    });

    dataChanges.newValue.push({
      ...changeDetails.newValue,
      value: normalizeFields(changeDetails.newValue.value, isPacingChanged, entityType),
    });
  }

  dataOldChange.forEach((oldDateChanges) => {
    const { oldValue, newValue } = processDateChanges(oldDateChanges, ChangeAction.Removal, entityType);

    dataChanges.oldValue.push(oldValue);
    dataChanges.newValue.push(newValue);
  });

  dataNewChange.forEach((newDateChanges) => {
    const { oldValue, newValue } = processDateChanges(newDateChanges, ChangeAction.Addition, entityType);

    dataChanges.oldValue.push(oldValue);
    dataChanges.newValue.push(newValue);
  });

  return dataChanges;
};

export const createDatesChangelog = ({
  dateRangeListBefore,
  dateRangeListAfter,
  entityType,
}: DateRageListObject): CreateDatesChangelogResult => {
  const dateChanges = getDateChanges({
    oldData: dateRangeListBefore,
    newData: dateRangeListAfter,
    entityType,
  });

  return {
    fieldName: 'dates',
    oldValue: {
      payload: [...dateChanges.oldValue],
      action: ChangeAction.Update,
    },
    newValue: {
      payload: [...dateChanges.newValue],
      action: ChangeAction.Update,
    },
  };
};
