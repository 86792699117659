import type { ApolloError } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import type { CreateBatchAdsMutation, CreateBatchAdsMutationVariables } from '../../../../../../apis/graphql';
import type { DuplicateAdFormValues } from '../../types';
import { CREATE_ADS_BATCH } from '../mutations';
import type { DuplicateLineItem } from '../useDuplicateLineItemsState';
import { convertFormValuesToDuplicateAdsInput } from '../utils';

type BatchCreateAdsResponse = {
  submitDuplicateAdForm: (formValues: DuplicateAdFormValues) => Promise<void>;
  loading: boolean;
};

const useBatchCreateAds = (
  onCompleted: (data: CreateBatchAdsMutation) => void,
  onError: (savedItems: DuplicateLineItem[], e: ApolloError) => void
): BatchCreateAdsResponse => {
  const [batchCreateAds, { loading }] = useMutation<CreateBatchAdsMutation, CreateBatchAdsMutationVariables>(
    CREATE_ADS_BATCH,
    { onCompleted }
  );

  const submitDuplicateAdForm = useCallback(
    async (formValues: DuplicateAdFormValues): Promise<void> => {
      const adsInput = convertFormValuesToDuplicateAdsInput(formValues);

      await batchCreateAds({ variables: { adsInput } }).catch((e) =>
        onError(formValues.lineItems || [], e as ApolloError)
      );
    },
    [batchCreateAds, onError]
  );

  return {
    loading,
    submitDuplicateAdForm,
  };
};

export default useBatchCreateAds;
