import type { TargetingTermValueInput } from '../../../../../../../../../../apis/graphql';
import type { TargetingTermValue } from '../../../../../../../../../../models';

export type GetTargetingResult =
  | {
      id: string;
      value: TargetingTermValueInput[];
    }[]
  | undefined;

type GetMergedOldAndNewTargetingParams = {
  inputTargeting: GetTargetingResult | null;
  existedTargeting?: Record<string, TargetingTermValue[]>;
};

export const getTransformedExistedTargeting = (
  existedTargeting?: Record<string, TargetingTermValue[]>
): GetTargetingResult =>
  existedTargeting
    ? Object.keys(existedTargeting).map((entityId) => ({
        id: entityId,
        value: existedTargeting[entityId].map((target) => ({
          include: target.include,
          dimensionName: target.dimension.id,
          valueName: target.value.id,
        })),
      }))
    : [];

export const getMergedOldAndNewTargeting = ({
  inputTargeting,
  existedTargeting,
}: GetMergedOldAndNewTargetingParams): GetTargetingResult =>
  inputTargeting?.map((newTargeting) => {
    const findOldTargeting =
      getTransformedExistedTargeting(existedTargeting)?.find(
        (existedTargeting) => existedTargeting.id === newTargeting.id
      ) || null;

    const oldTargetingValues = findOldTargeting ? findOldTargeting?.value : [];

    return {
      id: newTargeting.id,
      value: [...newTargeting.value, ...oldTargetingValues],
    };
  });
