import { User } from 'mission-control-common-components';
import React, { memo } from 'react';
import type { Cell } from 'react-table';

import type { Get_Ad_RatingsQuery } from '../../../../../apis/graphql';
import EditableDropdown from '../../../../../common/EditableTableCells/EditableDropdown';
import { DropdownOptions } from '../../../../../common/hooks/config';
import { useFetchDropdownOptions } from '../../../../../common/hooks/queries/useFetchDropdownOptions';
import type { DisplayEnum } from '../../../../../configs';
import { convertConfigToDropdownOption } from '../../../../../configs';
import type { PermissionsNames } from '../../../../../constants';
import { getIdFromTableCell } from '../../columns';
import { useUpdateAd } from '../../hooks';
import type { AdModel } from '../../modelConverters';

function RatingCell(cell: Cell<AdModel, DisplayEnum<string>>): JSX.Element {
  const permissions = User.getPermissions<PermissionsNames>();
  const id = getIdFromTableCell(cell);
  const { cellVariant, updateAdFields } = useUpdateAd<'rating'>(id);

  const { options: adOptions } = useFetchDropdownOptions<Get_Ad_RatingsQuery>(DropdownOptions.AdRating);

  const onSubmit = (rating: string): void => updateAdFields({ rating });

  return (
    <EditableDropdown<string>
      classNameModifier="status-cell"
      selectedValue={convertConfigToDropdownOption<string>(cell.value)}
      onSubmit={onSubmit}
      options={adOptions}
      variant={cellVariant}
      dataTestId="rating-cell"
      isDisabled={!permissions?.updateAd}
    />
  );
}

export const comparison = (
  prev: Cell<AdModel, DisplayEnum<string>>,
  next: Cell<AdModel, DisplayEnum<string>>
): boolean => prev.row.original.id === next.row.original.id && prev.value.key === next.value.key;

export default memo(RatingCell, comparison);
