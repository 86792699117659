import './Schedule.scss';

import { IconCalendar } from '@hulu-react-style-components/icons';
import type { Dispatch, SetStateAction } from 'react';
import React from 'react';

import type { DayPartV5 } from '../../../../../../../../../apis/graphql';
import DaypartTabs from '../../../../../../../../../common/AdForm/Scheduling/DaypartTabs';
import bem from '../../../../../../../../../utils/bem';
import MultipleEditDatesInfo from './MultipleEditDatesInfo';

const [block, element] = bem('multi-edit-schedule');

const DATES_TITLE = 'Dates';

type Props = {
  stashedDayPartList: DayPartV5[];
  setStashedDayPartList: Dispatch<SetStateAction<DayPartV5[]>>;
};

export default function Schedule({ stashedDayPartList, setStashedDayPartList }: Props): JSX.Element {
  return (
    <div className={block()}>
      <p className={element('subtitle')}>Schedule change applied here will OVERRIDE existing schedule on your ad(s).</p>
      <div className={element('date-title')}>
        <IconCalendar />
        <h5>{DATES_TITLE}</h5>
      </div>
      <MultipleEditDatesInfo editMode={true} readonly={false} />
      <DaypartTabs
        setStashedDayPartList={setStashedDayPartList}
        stashedDayPartList={stashedDayPartList}
        readonly={false}
      />
    </div>
  );
}
