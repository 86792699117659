import { useQuery } from '@apollo/client';

import { EntityType } from '../../TraffickingPage/hooks/makePageDrawerPlugin';
import { getAdTerms, getLineItemTerms } from '../utils/utils';
import { GET_DRAWER_TARGETING_AD, GET_DRAWER_TARGETING_LINE_ITEM } from './queries';
import type { DrawerTargetingTableResult, QueryResult } from './types/results';

type HookProps = {
  id?: string;
  entityType: EntityType.AD | EntityType.LINE_ITEM;
};

export const useFetchTargeting = ({
  entityType,
  id,
}: HookProps): Omit<DrawerTargetingTableResult, 'submitForm' | 'isUpdating'> => {
  const isAd = entityType === EntityType.AD;
  const query = isAd ? GET_DRAWER_TARGETING_AD : GET_DRAWER_TARGETING_LINE_ITEM;

  const { data, loading } = useQuery<QueryResult>(query, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { ids: [id] },
  });

  const terms = isAd ? getAdTerms(data) : getLineItemTerms(data);

  return {
    ...terms,
    isFetching: loading,
  };
};
