import './EntityDetailsDrawer.scss';

import React from 'react';

import Drawer from '../../../../common/Drawer';
import bem from '../../../../utils/bem';
import type { EntityDetailsDrawerData } from '../../TraffickingPage/hooks/makePageDrawerPlugin';
import EntityDetailsDrawerContent from './EntityDetailsDrawerContent';
import EntityDetailsDrawerTitle from './EntityDetailsDrawerTitle';

export type EntityDetailsDrawerProps = {
  isOpen: boolean;
  isExpanded: boolean;
  className: string;
  data: EntityDetailsDrawerData;
  onClose: () => void;
  onToggleExpansion: () => void;
};

const [block] = bem('entity-details-drawer');

export default function EntityDetailsDrawer({
  data,
  isExpanded,
  isOpen,
  className,
  onClose,
  onToggleExpansion,
}: EntityDetailsDrawerProps): JSX.Element {
  return (
    <Drawer<EntityDetailsDrawerData>
      data={data}
      className={`${className} ${block()}`}
      content={EntityDetailsDrawerContent}
      onClose={onClose}
      isOpen={isOpen}
      title={EntityDetailsDrawerTitle}
      isExpanded={isExpanded}
      onToggleExpansion={onToggleExpansion}
    />
  );
}
