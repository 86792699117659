import './ReviewCell.scss';

import type { FC } from 'react';
import React, { memo } from 'react';

import Pill, { PillColor } from '../../../../../common/Pill';
import type { DisplayEnum, Review } from '../../../../../configs';
import bem from '../../../../../utils/bem';

const pillColorsByReview: { [key in DisplayEnum<Review>['key']]: PillColor } = {
  UNRECOGNIZED: PillColor.GRAY,
  DRAFT: PillColor.GRAY,
  UNDER_REVIEW: PillColor.BLUE,
  APPROVED: PillColor.GREEN,
};

type Props = { value: DisplayEnum<Review> };

const [block] = bem('wrapper-review-cell');

const ReviewCell: FC<Props> = ({ value }) => {
  const { key, displayName } = value;
  const pillColor: PillColor = pillColorsByReview[key];

  return (
    <div className={block()}>
      <Pill text={displayName} color={pillColor} />
    </div>
  );
};

const comparison = (prev: Props, next: Props): boolean => prev.value.displayName === next.value.displayName;

export default memo(ReviewCell, comparison);
