import { isString as _isString } from 'lodash';

import type { ScheduleFilterInputV5, ScheduleFilterStatusV5 } from '../../../../../apis/graphql';
import type { DateType } from '../DateFilter';
import { CUSTOM_DATE } from '../makeGroupedDateOptions';
import { MAX_DATE_YEAR_OFFSET, MIN_DATE_DAY, MIN_DATE_MONTH, MIN_DATE_YEAR, STATUS_RUNNING } from './constants';

interface ScheduleData {
  newStartOfDate?: Date | null;
  newEndOfDate?: Date | null;
  dateStatus: string;
}

type GetScheduleData = { value: ScheduleFilterInputV5; label: string } | undefined;

export const getMinDate = (): Date => {
  return new Date(MIN_DATE_YEAR, MIN_DATE_MONTH, MIN_DATE_DAY);
};

export const getMaxDate = (): Date => {
  const date = new Date();

  date.setFullYear(date.getFullYear() + MAX_DATE_YEAR_OFFSET);

  return date;
};

export const getDefaultDateStatus = (value?: DateType): string => {
  if (!value || _isString(value)) return STATUS_RUNNING;

  return value?.status;
};

export const getScheduleData = ({ newStartOfDate, newEndOfDate, dateStatus }: ScheduleData): GetScheduleData => {
  if (!newStartOfDate || !newEndOfDate) return;

  return {
    value: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      start: {
        year: newStartOfDate.getFullYear(),
        month: newStartOfDate.getMonth() + 1,
        day: newStartOfDate.getDate(),
        hour: 1,
      },
      end: {
        year: newEndOfDate.getFullYear(),
        month: newEndOfDate.getMonth() + 1,
        day: newEndOfDate.getDate(),
        hour: 23,
      },
      status: dateStatus as ScheduleFilterStatusV5,
    },
    label: CUSTOM_DATE,
  };
};

/**
 * This function returns a date object created from a date string
 * and works around the JavaScript Date issues described in details here
 * https://dev.to/zachgoll/a-complete-guide-to-javascript-dates-and-why-your-date-is-off-by-1-day-fi1
 * */
export const getDateFromAString = (value: string): Date => {
  const dayOffset = 1;
  const date = new Date(value);
  const dayFromDateObj = date.getDate();
  const dayFromDateStr = parseInt(value.slice(-2), 10);

  if (dayFromDateStr !== dayFromDateObj) {
    date.setDate(date.getDate() + dayOffset);
  }

  return date;
};
