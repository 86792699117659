import { useFormikContext } from 'formik';
import React from 'react';

import Alert, { AlertType } from '../../../common/Alert';
import EntitySearch from '../../../common/EntitySearch';
import type { CreativeType } from '../../../configs';
import { EXCLUDED_SEARCH_CREATIVE_TYPES } from '../../../configs';
import { getPermittedCreativeTypes } from '../../../configs';
import { creativeTypes } from '../../../configs';
import type { AdFormValues } from '../adFormik';
import type { AdsPageLineItemType } from '../types';
import type { SearchCreativesResult, UseSearchCreativesResult } from './hooks';
import { useSearchCreatives, useUpdateCreativeForm } from './hooks';

function CreativeSearcher(props: { lineItemType: AdsPageLineItemType }): JSX.Element {
  const { setFieldValue, values: adFormValues } = useFormikContext<AdFormValues>();

  // If the line item is DRAX, only DRAX creatives are allowed
  // If the line item is not DRAX, we determine the allowed creative types from Ad type
  const searchCreativeTypes: CreativeType[] =
    props.lineItemType === 'DRAX'
      ? [creativeTypes.DRAX.key]
      : Object.values(getPermittedCreativeTypes(adFormValues.type))
          .map((x) => x.key)
          .filter((creativeType) => !EXCLUDED_SEARCH_CREATIVE_TYPES.includes(creativeType));

  const useSearchHook = (query: string): UseSearchCreativesResult => useSearchCreatives(query, searchCreativeTypes);
  const { getCreativeByID, error: getCreativeByIdError } = useUpdateCreativeForm(adFormValues.type, setFieldValue);

  return (
    <>
      <EntitySearch<SearchCreativesResult>
        apiResultsArePaginated={true}
        hasPagination
        label="Search Creative Library"
        maxSearchResults={50}
        onSelect={(creative): void => getCreativeByID({ variables: { id: creative.id } })}
        searchHook={useSearchHook}
      />
      {getCreativeByIdError && adFormValues.creative.id && (
        <Alert
          type={AlertType.ERROR}
          bodyText={`Error retrieving creative by id: ${adFormValues.creative.id}, error message: ${getCreativeByIdError.message}`}
        />
      )}
    </>
  );
}

export default CreativeSearcher;
