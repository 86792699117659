import { findIndex } from 'lodash';

import type { SingleValueChange } from '../../../../apis/graphql';
import type { MultiValueChange } from '../../ChangeLogTable/ChangeLogTable';
import type { RawTerm, Term } from '../types';
import { TermStatus } from '../types';

/**
 * Compares two lists for matching values. Reformats the data, and sets the
 * appropriate status depending on the presence of the value in the comparison list.
 *
 * @param targetList The current working list; return value is derived from this list
 * @param compareList The list used for comparison only
 * @param divergeStatus The status to be used if a targetList value is not present in compareList
 * @returns A list of terms with new structure
 */
function compareTermLists(divergeStatus: TermStatus, targetList?: RawTerm[], compareList?: RawTerm[]): Term[] | null {
  if (!targetList) return null;

  return targetList.map((targetTerm) => {
    const matchingTerm = compareList?.find((t) => t.dimension === targetTerm.dimension);

    return {
      dimension: targetTerm.dimension,
      valueSet: targetTerm.valueSet.map((value) => ({
        value,
        status: matchingTerm?.valueSet.find((v) => v === value) ? TermStatus.PRESENT : divergeStatus,
        include: !targetTerm.not,
      })),
    };
  });
}

export function modifyDefinition(
  change: SingleValueChange,
  restructuredChangeList: Array<SingleValueChange | MultiValueChange>
): void {
  const { action: newAction, payload: newPayload } = change.newValue;
  const { action: oldAction, payload: oldPayload } = change.oldValue;

  const itemsToAdd: SingleValueChange[] = [];

  // Handle "term-list" property
  if (Array.isArray(oldPayload?.termList) || Array.isArray(newPayload?.termList)) {
    itemsToAdd.push({
      fieldName: 'targeting',
      newValue: {
        action: newAction,
        payload: compareTermLists(TermStatus.ADDED, newPayload?.termList, oldPayload?.termList),
        __typename: 'SimpleChangeValue',
      },
      oldValue: {
        action: oldAction,
        payload: compareTermLists(TermStatus.REMOVED, oldPayload?.termList, newPayload?.termList),
        __typename: 'SimpleChangeValue',
      },
    });
  }

  const index = findIndex(restructuredChangeList, { fieldName: 'definition' });

  if (itemsToAdd.length) {
    if (index === -1) {
      restructuredChangeList.push(...itemsToAdd);
    } else {
      restructuredChangeList.splice(index, 1, ...itemsToAdd);
    }
  }
}
