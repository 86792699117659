import './AdSelectorVideoCreativeOption.scss';

import { bem } from 'mission-control-common-components';

type Props = {
  id: string;
  name: string;
};

const [block, element] = bem('asset-option');
const AdSelectorVideoCreativeOption = ({ id, name }: Props): React.JSX.Element => {
  return (
    <div className={block()}>
      <div className={element('name')}>{name}</div>
      <div className={element('id')}>
        <strong>ID:</strong> {id}
      </div>
    </div>
  );
};

export default AdSelectorVideoCreativeOption;
