import { CREATIVE_STATUSES_ASSET_IS_NOT_REMOVABLE, RESTRICTED_CREATIVE_TYPES } from '../../constants/creative';
import type { CreativesBuilderValues } from '../CreativeBuilders/types';
import { CreativeEditMode } from '../CreativeBuilders/types';

export const isCreativeAssetRemoveForbidden = (
  editMode: CreativeEditMode,
  creative: CreativesBuilderValues
): boolean => {
  return !!(
    editMode === CreativeEditMode.readonly ||
    (RESTRICTED_CREATIVE_TYPES.includes(creative.type) &&
      creative.review &&
      CREATIVE_STATUSES_ASSET_IS_NOT_REMOVABLE.includes(creative.review))
  );
};
