import './AdRotation.scss';

import React, { memo, useCallback } from 'react';

import bem from '../../../utils/bem';
import PercentageInput from '../../PercentageInput/PercentageInput';
import { rotationsColumnsBlock, rotationsColumnsElement } from '../bemClasesConstants';
import type { AdRotation as AdRotationType } from '../types';

type AdRotationProps = {
  rotationId: string;
  adRotation: AdRotationType;
  onWeightChanged: (adId: string, rotationId: string, newWeight: number) => void;
};

const [block] = bem('ad-rotation');

function AdRotation({ rotationId, adRotation, onWeightChanged }: AdRotationProps): JSX.Element {
  const onChange = useCallback((newValue: number): void => onWeightChanged(adRotation.adId, rotationId, newValue), [
    onWeightChanged,
    rotationId,
    adRotation,
  ]);

  return (
    <div className={block()} data-testid="ad-rotation">
      <div className={rotationsColumnsBlock()}>
        <div
          className={rotationsColumnsElement('column', ['main'])}
          data-testid="ad-rotation-name"
          title={adRotation.name}
        >
          <div className={rotationsColumnsElement('column__text')}>{adRotation.name}</div>
        </div>
        <div className={rotationsColumnsElement('column', ['secondary'])} title={adRotation.adId}>
          <div className={rotationsColumnsElement('column__text')}>{adRotation.adId}</div>
        </div>
        <div className={rotationsColumnsElement('column')}>
          <div className={rotationsColumnsElement('column__text')}>{adRotation.startDate}</div>
        </div>
        <div className={rotationsColumnsElement('column')}>
          <div className={rotationsColumnsElement('column__text')}>{adRotation.endDate}</div>
        </div>
      </div>
      <PercentageInput value={adRotation.weight} onChange={onChange} />
    </div>
  );
}

export default memo(AdRotation);
