import { useFormikContext } from 'formik';
import React from 'react';

import type { VastVideoCreativeInputV5, VideoCreativeInputV5 } from '../../../../apis/graphql';
import Loader from '../../../../common/Loader';
import type { CreativeReviewStatus } from '../../../../configs';
import type { AdFormValues } from '../../adFormik';
import { isCreativeAssetRemoveForbidden } from '../../utils/creative';
import { AssetIngestionFooter, SiteServedFooter, VastPreviewBody, VideoImport } from '../components';
import CreativeBuilder from '../CreativeBuilder';
import type { CreativeBuilderProps } from '../CreativeBuilder/CreativeBuilder';
import { useCreativeData } from '../hooks/useCreativeData';
import { useFetchCreativeApprovals } from '../hooks/useFetchCreativeApprovals';
import type { CreativesBuilderValues } from '../types';
import { CreativeEditMode } from '../types';
import { checkIsVastCreativeBuilderAllowed } from '../utils';
import { checkIsVastPreviewAllowed } from '../utils/checkIsVastPreviewAllowed';

export const constructVideoCreativeInput = ({
  name,
  video,
  creativeLibraryId,
}: CreativesBuilderValues): VideoCreativeInputV5 => {
  const input: VideoCreativeInputV5 = { name, type: 'VIDEO' };
  if (video?.id !== '') input.videoAssetId = video?.id;
  if (creativeLibraryId) input.creativeLibraryId = creativeLibraryId;

  return input;
};

export const constructVastVideoCreativeInput = ({
  name,
  url: urlInput,
  vpaid: vpaidInput,
  creativeLibraryId,
}: CreativesBuilderValues): VastVideoCreativeInputV5 => {
  const url = urlInput || '';
  const vpaid = vpaidInput === undefined ? false : vpaidInput;
  const input: VastVideoCreativeInputV5 = { name, type: 'VAST_VIDEO', url, vpaid };

  if (creativeLibraryId) input.creativeLibraryId = creativeLibraryId;

  return input;
};

export function VideoCreativeBuilder(builderProps: Omit<CreativeBuilderProps, 'isCreativeUnDeletable'>): JSX.Element {
  const {
    values: { creative },
  } = useFormikContext<AdFormValues>();

  const {
    data: { assets, ingestionJobStatus, successPercent, trialsWithoutAssets },
    loading: loadingVideoCreative,
  } = useCreativeData(creative);

  const { approvedAdUuids, adUuidsWithApprovedVastUrl, loading: loadingCreativeApprovals } = useFetchCreativeApprovals(
    creative.id
  );

  if (loadingVideoCreative || loadingCreativeApprovals) return <Loader />;

  const savedCreative = builderProps.creativeData?.getCreativeByIDV5;

  const isVastPreviewAllowed = checkIsVastPreviewAllowed(creative, !!builderProps.isPreview);

  const allowVast = checkIsVastCreativeBuilderAllowed(creative, savedCreative);

  const isAssetReadonly = isCreativeAssetRemoveForbidden(builderProps.editMode, creative);

  return (
    <CreativeBuilder classNameModifier="video" isPreview={builderProps.isPreview} editMode={builderProps.editMode}>
      <div className="video-creative-builder">
        {/* Note: VideoImport includes all importer fields AND preview mode for non-Vast assets */}
        {isVastPreviewAllowed ? (
          <>
            <VastPreviewBody
              adUuidsWithApprovedVastUrl={adUuidsWithApprovedVastUrl}
              approvedAdUuids={approvedAdUuids}
              assets={assets}
              creative={creative}
              trialsWithoutAssets={trialsWithoutAssets}
              editMode={builderProps.editMode}
              isURLRemovable={!builderProps.isAdApproved}
            />
            <AssetIngestionFooter
              assets={assets}
              hasIngestError={successPercent !== 1}
              ingestionJobStatus={ingestionJobStatus}
              readonly={builderProps.editMode !== CreativeEditMode.full}
            />
          </>
        ) : (
          <>
            <VideoImport builder={builderProps} allowVast={allowVast} readonly={isAssetReadonly} />
            <SiteServedFooter
              creativeReview={creative.review as CreativeReviewStatus}
              selectedAsset={assets[0]}
              assets={assets}
              isLoading={loadingVideoCreative}
              readonly={builderProps.editMode !== CreativeEditMode.full}
            />
          </>
        )}
      </div>
    </CreativeBuilder>
  );
}

export default VideoCreativeBuilder;
