export type Elements = number | string | Record<string, string> | (null | number | Record<string, string>)[];
type Element = number | Record<string, string> | null;

export const removeEmptyArrayElements = (elements: Elements): Elements | null => {
  if (!Array.isArray(elements)) return elements;

  const allValuesAreNull = Object.values(elements).every((value) => {
    return value === null;
  });

  if (allValuesAreNull) return null;

  return elements.filter((elem: Element) => elem);
};
