import type { Column } from 'react-table';

import bem from '../../../../../../utils/bem';
import type { DuplicateLineItem } from '../../hooks/useDuplicateLineItemsState';
import DuplicateLineItemsNameCell from './DuplicateLineItemsNameCell';

export const [pickerBlock, pickerElement] = bem('duplicate-line-items-picker');

export const sharedTableColumns: Column<DuplicateLineItem>[] = [
  {
    id: 'line_item_name',
    Header: 'Line item name',
    accessor: 'name',
    Cell: DuplicateLineItemsNameCell,
    width: 350,
  },
  {
    id: 'line_item_id',
    Header: 'Line item id',
    accessor: 'id',
    width: 325,
  },
];

export const LINE_ITEMS_LIMIT = 10;
