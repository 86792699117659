import './ViewabilityVendorForm.scss';

import { Form, useFormikContext } from 'formik';
import { bem } from 'mission-control-common-components';
import React, { useMemo } from 'react';

import { AD_TAG_LIST_FIELD } from '../../../../../../common/AdForm/Metadata/hooks';
import ViewabilityVendorsSection from '../../../../../../common/AdForm/Metadata/ViewabilityVendorsSection/ViewabilityVendorsSection';
import AdTagsTable from '../../../../../../common/AdTagsTable';
import Button from '../../../../../../common/Button';
import { useFieldArray } from '../../../../../../common/Form/hooks';
import useFocusTrap from '../../../../../../common/hooks/useFocusTrap';
import type { AdTag } from '../../../../../../models';

type ViewabilityVendorFormProps = {
  readonly: boolean;
  loading: boolean;
  onSubmit: (values: ViewabilityVendorFormProps) => Promise<void>;
  onCancel: () => void;
};

export const VIEWABILITY_VENDORS_LABEL = 'Viewability Vendors';
export const AD_TAGS_LABEL = 'Ad Tags';
export const CANCEL_BUTTON_LABEL = 'Cancel';
export const CONFIRM_BUTTON_LABEL = 'Confirm';

const [block, element] = bem('viewability-vendor-form');

const ViewabilityVendorForm = ({
  loading,
  readonly,
  onSubmit,
  onCancel,
}: ViewabilityVendorFormProps): React.JSX.Element => {
  const { dirty } = useFormikContext();

  const [, { value: adTagList }, { removeAt, replaceAt, push }] = useFieldArray<AdTag>(AD_TAG_LIST_FIELD);
  const listHelpers = useMemo(() => ({ removeAt, replaceAt, push }), [removeAt, replaceAt, push]);

  const focusRef = useFocusTrap<HTMLFormElement>(['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']);

  return (
    <Form className={block()} autoComplete="off" ref={focusRef} aria-label="form">
      <h2 className={element('label')}>{AD_TAGS_LABEL}</h2>
      <div className={element('tags-table')}>
        <AdTagsTable
          adTagList={adTagList}
          readonly={true}
          listHelpers={listHelpers}
          isAdInitiallyWithBillableTag={false}
          unDeletableAd={false}
        />
      </div>
      <h2 className={element('label')}>{VIEWABILITY_VENDORS_LABEL}</h2>
      <ViewabilityVendorsSection readonly={readonly} />
      <div className={element('action-buttons')}>
        <Button variant="outlined" className={element('cancel-button')} data-testid="cancel-btn" onClick={onCancel}>
          {CANCEL_BUTTON_LABEL}
        </Button>
        <Button
          type="button"
          loading={loading}
          disabled={!dirty || readonly || loading}
          className={element('confirm-button')}
          onClick={onSubmit}
          data-testid="confirm-btn"
        >
          {CONFIRM_BUTTON_LABEL}
        </Button>
      </div>
    </Form>
  );
};

export default ViewabilityVendorForm;
