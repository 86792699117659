import './MultipleEditDrawerContentCard.scss';

import { useField } from 'formik';
import React, { memo, useCallback, useMemo } from 'react';
import { Element } from 'react-scroll';

import Card from '../../../../../../common/Card';
import ControlledCheckbox from '../../../../../../common/ControlledCheckbox/ControlledCheckbox';
import Tooltip from '../../../../../../common/Tooltip/Tooltip';
import bem from '../../../../../../utils/bem';

const [block, element] = bem('multiple-edit-drawer-content-card');

type MultipleEditDrawerContentCardProps = {
  sideNavLink: string;
  children: React.ReactNode;
  disabled?: boolean;
  title?: string;
  errorMsg?: string;
};

const TOOLTIP_MESSAGE = 'Select checkbox to enable editing';

function MultipleEditDrawerContentCard({
  sideNavLink,
  children,
  disabled,
  title,
  errorMsg,
}: MultipleEditDrawerContentCardProps): JSX.Element {
  const [, { value: selectedSections }, { setValue }] = useField<string[]>('selectedSections');

  const checked = useMemo(() => {
    return selectedSections.includes(sideNavLink);
  }, [selectedSections, sideNavLink]);

  const onToggleCheck = useCallback(() => {
    if (checked) {
      setValue(selectedSections.filter((link) => link !== sideNavLink));
    } else {
      setValue([...selectedSections, sideNavLink]);
    }
  }, [checked, selectedSections, setValue, sideNavLink]);

  return (
    <Element name={sideNavLink} className={block()} data-testid="multiple-edit-drawer-content-card">
      <div className={element('title-wrapper')}>
        <ControlledCheckbox
          data-testid="section-checkbox"
          disabled={disabled}
          checked={checked}
          indeterminate={false}
          onChange={onToggleCheck}
        />
        <span className={element('title')}>{title || sideNavLink}</span>
        {errorMsg ? <span className={element('error')}>{errorMsg}</span> : null}
      </div>
      <Card className={element('content')}>
        {!checked && (
          <Tooltip
            id={`multi-edit-${sideNavLink}`}
            delayShow={300}
            delayHide={0}
            message={TOOLTIP_MESSAGE}
            disable={false}
            effect="float"
          >
            <div className={element('overlay')} data-testid="content-overlay"></div>
          </Tooltip>
        )}
        {children}
      </Card>
    </Element>
  );
}

export default memo(MultipleEditDrawerContentCard);
