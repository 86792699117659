import './ModeControlButton.scss';

import type { Dispatch, SetStateAction } from 'react';
import React from 'react';

import bem from '../../../utils/bem';
import Button from '../../Button';

const [block, element] = bem('change-mod');

export interface Props {
  onSubmit: () => void;
  onCancel: () => void;
  disableSubmitting?: boolean;
  loading?: boolean;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  canEdit?: boolean;
}

function ModeControlButton({
  onSubmit,
  onCancel,
  disableSubmitting = false,
  editMode,
  loading,
  setEditMode,
  canEdit = true,
}: Props): JSX.Element {
  const handleClickEdit = (): void => setEditMode(true);

  const handleClickCancel = (): void => {
    onCancel();
    setEditMode(false);
  };

  return (
    <div className={block()} data-testid="mode-control-button">
      {!editMode ? (
        <Button onClick={handleClickEdit} className={element('edit-button')} size="medium" disabled={!canEdit}>
          Edit
        </Button>
      ) : (
        <div className={element('save-cancel-wrapper')}>
          <Button variant="outlined" onClick={handleClickCancel} className={element('cancel-button')} size="medium">
            Cancel
          </Button>
          <Button
            disabled={disableSubmitting}
            loading={loading}
            onClick={onSubmit}
            className={element('save-button')}
            size="medium"
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

export default ModeControlButton;
