import { useQuery } from '@apollo/client';

import type { GetTranscodingFailureQuery } from '../../../../apis/graphql';
import type { Nullable } from '../../../../models';
import { GET_TRANSCODING_FAILURE } from '../../hooks/queries';

interface UseTranscodingFailureProps {
  assetId?: Nullable<String>;
  transcodeStatus?: string;
}
interface UseTranscodingFailureResult {
  name: string;
  message: string;
}

export default function useTranscodingFailure({
  assetId,
  transcodeStatus,
}: UseTranscodingFailureProps): UseTranscodingFailureResult {
  const { data } = useQuery<GetTranscodingFailureQuery>(GET_TRANSCODING_FAILURE, {
    variables: { assetId },
    skip: !assetId || transcodeStatus === 'PENDING',
  });

  const name = data?.getTranscodingFailure?.name || '';
  const message = data?.getTranscodingFailure?.message || '';

  return {
    name,
    message,
  };
}
