// TODO: AX-3815 update type for creatives in useAssetId

import { useQuery } from '@apollo/client';

import type { BaseAssetV5, MediaMetadata, Query } from '../../../../apis/graphql';
import { GET_ASSET_BY_ID } from '../../hooks/queries';
import { getReviewTask } from './util';

type UseGetAssetByIDResult = {
  asset: BaseAssetV5 | null;
  hasReviewTaskId: boolean;
  loading: boolean;
  metadata: MediaMetadata | null;
  fetchAssetById(assetID: string): Promise<BaseAssetV5>;
  spReview?: string | null;
};

const useGetAssetById = (assetID?: string, creativeId?: string): UseGetAssetByIDResult => {
  const { data: asset, loading, refetch } = useQuery<Query>(GET_ASSET_BY_ID, {
    variables: {
      assetID,
      paginationOptions: { limit: 100, offset: 0 },
      filterOptions: { assetIdList: assetID ? [assetID] : [], creativeIdList: creativeId ? [creativeId] : [] },
      hasCreative: !!creativeId,
    },
    skip: !assetID,
  });

  const fetchAssetById = async (assetId?: string): Promise<BaseAssetV5> => {
    const { data } = await refetch({ assetID: assetId, hasCreative: !!creativeId });

    return data.getAssetByIDV5;
  };

  const reviewTask = getReviewTask(asset?.getReviewTasksV5);

  const hasReviewTaskId = !!reviewTask?.id;
  const spReview = reviewTask?.spReview;

  return {
    asset: asset?.getAssetByIDV5 || null,
    hasReviewTaskId,
    loading,
    metadata: asset?.getMediaMetadataByAssetIdV5 || null,
    fetchAssetById,
    spReview,
  };
};

export default useGetAssetById;
