import React from 'react';

import { AssetImporterField } from '../../../../../common/Form';
import type { CreativeBuilderProps } from '../../CreativeBuilder/CreativeBuilder';
import useTagUpload from '../../hooks/useTagUpload';

export const VideoImport: React.FC<{
  builder: CreativeBuilderProps;
  allowVast?: boolean;
  readonly?: boolean;
}> = ({ builder: { adTypeDimensions, isPreview }, allowVast, readonly }) => {
  const tagInputProps = useTagUpload();

  return (
    <AssetImporterField
      adTypeDimensions={adTypeDimensions?.video}
      id="asset-uploader"
      formFieldName={'creative.video'}
      assetType="VIDEO"
      label="Video Asset"
      aspectRatio={16 / 9}
      tagInputProps={allowVast ? tagInputProps : undefined}
      isPreview={isPreview}
      allowVast={allowVast}
      readonly={readonly}
    />
  );
};
