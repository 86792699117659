import { gql } from '@apollo/client/core';

import { ROTATIONS_FRAGMENT } from '../../../../common/AdForm/hooks/fragments';

export const UPDATE_ROTATIONS = gql`
  mutation UpdateRotations($rotationsInput: RotationsInput!) {
    updateRotationsV5(input: $rotationsInput) {
      ...rotations
    }
  }
  ${ROTATIONS_FRAGMENT}
`;

export const DELETE_ROTATIONS = gql`
  mutation DeleteRotations($lineItemId: ID!) {
    deleteRotationsV5(lineItemId: $lineItemId)
  }
`;
