import './TimeList.scss';

import { IconAlertInfoOutlined } from '@hulu-react-style-components/icons';
import React from 'react';

import type { DayPartV5 } from '../../apis/graphql';
import type { ActiveDayType, DisplayEnum } from '../../configs';
import { activeDayTypes } from '../../configs';
import bem from '../../utils/bem';
import { formatTimeRanges, formatTruncatedDay } from '../../utils/formatting';
import { prepareDayPartsBeforeRendering } from './utils';

interface TimesListProps {
  dayParts: DayPartV5[];
}

const [block, element] = bem('times-list');

function TimesList({ dayParts }: TimesListProps): JSX.Element {
  const dayPartToShow = prepareDayPartsBeforeRendering(dayParts);

  const dayPartsContent = Object.values(activeDayTypes).map((day: DisplayEnum<ActiveDayType>) => {
    const dayTimes = dayPartToShow[day.key];

    return (
      <React.Fragment key={day.displayName}>
        <span className="time-items__day" data-testid="time-item-day">
          {formatTruncatedDay(day.displayName)}
        </span>
        <span className="time-items__hours" data-testid="time-item-hours">
          {formatTimeRanges({ ranges: dayTimes, isShowMinutes: true })}
        </span>
      </React.Fragment>
    );
  });

  return (
    <div data-testid="times-list" className={block()}>
      <div className={element('icon-wrapper')}>
        <IconAlertInfoOutlined />
      </div>
      <div className={element('content')}>
        <div className={element('content', 'title')}>Scheduled Times (ET)</div>
        <div className="time-items">{dayPartsContent}</div>
      </div>
    </div>
  );
}

export default TimesList;
