import './MultipleEditDatesInfo.scss';

import { useEffect } from 'react';

import DatesInfoContent from '../../../../../../../../../../common/DatesInfo/DatesInfoContent/DatesInfoContent';
import useDatesInfo from '../../../../../../../../../../common/DatesInfo/hooks/useDatesInfo';
import { useFieldFast } from '../../../../../../../../../../common/Form/hooks';
import bem from '../../../../../../../../../../utils/bem';
import { validateDateRangesByDefault } from '../../../../../../../../../../utils/validation';

export type MultipleEditDatesInfoProps = {
  editMode?: boolean;
  readonly?: boolean;
};

const [block] = bem('multiple-edit-dates-info');

const DATE_RANGE_LIST_FIELD = 'schedule.dateRangeList';
const IS_LAST_DATE_RANGE_DISABLED_FIELD = 'isLastDateRangeDisabled';

const MultipleEditDatesInfo = ({ editMode = true, readonly = false }: MultipleEditDatesInfoProps): JSX.Element => {
  const {
    dateRangeList,
    addDateRange,
    validationError,
    removeAt,
    handleDateChange,
    setValidationError,
    handleToggleDisableLastEndDate,
  } = useDatesInfo(DATE_RANGE_LIST_FIELD);

  const [, { value: isLastDateRangeDisabled }, { setValue: setIsLastDateRangeDisabled }] = useFieldFast<boolean>(
    IS_LAST_DATE_RANGE_DISABLED_FIELD
  );

  useEffect(() => {
    setValidationError(validateDateRangesByDefault(dateRangeList));
  }, [dateRangeList, setValidationError]);

  return (
    <div className={block()}>
      <DatesInfoContent
        addDateRange={addDateRange}
        nullableEndDate={true}
        dateRangeList={dateRangeList}
        validationError={validationError}
        handleRemove={removeAt}
        handleDateChange={handleDateChange}
        handleToggleDisableLastEndDate={handleToggleDisableLastEndDate}
        editMode={editMode}
        readonly={readonly}
        isLastDateRangeDisabled={isLastDateRangeDisabled}
        setIsLastDateRangeDisabled={setIsLastDateRangeDisabled}
        column
      />
    </div>
  );
};

export default MultipleEditDatesInfo;
