import type { ChangeEvent } from 'react';
import React from 'react';
import type { ColumnInstance, Hooks, IdType } from 'react-table';

import ControlledCheckbox from '../../../../common/ControlledCheckbox';
import type { CheckboxProps } from '../../../../common/ControlledCheckbox/ControlledCheckbox';
import type { HeaderProps } from '../../../../common/CustomSelectAllCheckbox/CustomSelectAllCheckbox';
import CustomSelectAllCheckbox from '../../../../common/CustomSelectAllCheckbox/CustomSelectAllCheckbox';
import { stickyBlock } from '../../../../common/TraffickingTable/utils';
import { SELECTION_COLUMN_ID } from './constants';

type RowProps = {
  row: {
    getToggleRowSelectedProps(): CheckboxProps;
    toggleRowSelected(): (rowId: IdType<{}>, set?: boolean) => void;
    index: number;
  };
};

const onKeyDownHandler = (callback: () => void) => (
  e: React.KeyboardEvent<Element> | ChangeEvent<HTMLInputElement>
): void => {
  if ('key' in e && (e.key === 'Enter' || e.key === ' ')) {
    e.preventDefault();
    callback();
  }
};

// useRowSelectCheckboxColumn adds a column to the table that contains checkboxes
// that toggle row selection for the table
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useRowSelectCheckboxColumn<T extends object>(hooks: Hooks<T>) {
  hooks.visibleColumns.push((allColumns: ColumnInstance<T>[]) => {
    return [
      {
        id: SELECTION_COLUMN_ID,
        width: 75,
        disableResizing: true,
        className: stickyBlock(),
        disableHiding: true,
        Header: (props: HeaderProps<T>): React.JSX.Element => {
          return <CustomSelectAllCheckbox {...props} onKeyDownHandler={onKeyDownHandler} />;
        },
        Cell: ({ row }: RowProps): JSX.Element => {
          const { getToggleRowSelectedProps, index, toggleRowSelected } = row;

          return (
            <>
              <ControlledCheckbox
                {...getToggleRowSelectedProps()}
                labelContent={`${index + 1}`}
                onKeyDown={onKeyDownHandler(toggleRowSelected)}
                tabIndex={-1}
              />
            </>
          );
        },
      },
      ...allColumns,
    ];
  });
}

export default useRowSelectCheckboxColumn;
