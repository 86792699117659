import './DaypartTabs.scss';

import { IconClockOutlined } from '@hulu-react-style-components/icons';
import { Tab, TabNavigation } from '@hulu/react-style-components';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

import type { DayPartV5 } from '../../../../apis/graphql';
import { ActiveDayTypeV5 } from '../../../../apis/graphql';
import bem from '../../../../utils/bem';
import { useFieldArray } from '../../../Form/hooks';
import Daypart from '../Daypart/Daypart';

const [block, element] = bem('daypart-tabs');

enum TabTitle {
  allTimes = 'All Times',
  specificTimes = 'Specific Times',
}

export const DEFAULT_DAY_PART: DayPartV5 = {
  firstActiveHour: 0,
  lastActiveHour: 23,
  activeDayList: [
    ActiveDayTypeV5.Monday,
    ActiveDayTypeV5.Tuesday,
    ActiveDayTypeV5.Wednesday,
    ActiveDayTypeV5.Thursday,
    ActiveDayTypeV5.Friday,
    ActiveDayTypeV5.Saturday,
    ActiveDayTypeV5.Sunday,
  ],
};

const DAYPART_TITLE = 'Dayparts';

type Props = {
  stashedDayPartList: DayPartV5[];
  setStashedDayPartList: Dispatch<SetStateAction<DayPartV5[]>>;
  readonly?: boolean;
};

const DaypartTabs = ({ stashedDayPartList, setStashedDayPartList, readonly }: Props): JSX.Element => {
  const [
    ,
    { value: dayPartList, initialValue: initialDayPartList },
    { removeAt, replaceAt, push, setValue: setDayPartList },
  ] = useFieldArray<DayPartV5>('schedule.dayPartList');

  const [selectedTab, setSelectedTab] = useState<TabTitle>(
    dayPartList?.length ? TabTitle.specificTimes : TabTitle.allTimes
  );

  useEffect(() => {
    if (dayPartList && dayPartList.length) {
      setStashedDayPartList(dayPartList);
      setSelectedTab(TabTitle.specificTimes);
    }
  }, [dayPartList, setStashedDayPartList]);

  useEffect(() => {
    if (!initialDayPartList?.length) {
      setStashedDayPartList([]);
      setSelectedTab(TabTitle.allTimes);
    }
  }, [initialDayPartList?.length, setStashedDayPartList]);

  const setAllTime = (): void => {
    setStashedDayPartList(dayPartList);
    setDayPartList([]);
  };

  const setSpecificTimes = (): void => {
    const isDefault =
      stashedDayPartList.length === 1 &&
      stashedDayPartList[0].firstActiveHour === DEFAULT_DAY_PART.firstActiveHour &&
      stashedDayPartList[0].lastActiveHour === DEFAULT_DAY_PART.lastActiveHour &&
      stashedDayPartList[0].activeDayList.length === DEFAULT_DAY_PART.activeDayList.length;

    if (!isDefault) setDayPartList(stashedDayPartList);
    if (!stashedDayPartList.length) setStashedDayPartList([DEFAULT_DAY_PART]);
  };

  const handleTabClick = (tabTitle: TabTitle): void => {
    if (tabTitle === selectedTab || readonly) return;

    if (tabTitle === TabTitle.allTimes) setAllTime();
    if (tabTitle === TabTitle.specificTimes) setSpecificTimes();

    setSelectedTab(tabTitle);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, tabTitle: TabTitle): void => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleTabClick(tabTitle);
    }
  };

  return (
    <div className={block()}>
      <div className={element('date-title')}>
        <IconClockOutlined />
        <h5>{DAYPART_TITLE}</h5>
      </div>
      <TabNavigation className={element('segmented-control')} selectedTab={selectedTab} onTabClick={handleTabClick}>
        <Tab
          key="All Times"
          title="All Times"
          tabIndex={0}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>): void => handleKeyDown(e, TabTitle.allTimes)}
          className={readonly ? block('readonly') : ''}
        />
        <Tab
          key="Specific Times"
          title="Specific Times"
          tabIndex={0}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>): void => handleKeyDown(e, TabTitle.specificTimes)}
          className={readonly ? block('readonly') : ''}
        >
          <Daypart
            dayPartStashList={stashedDayPartList}
            dayPartStateList={dayPartList}
            listHelpers={{ removeAt, replaceAt, push }}
            readonly={readonly}
          />
        </Tab>
      </TabNavigation>
    </div>
  );
};

export default DaypartTabs;
