import './DuplicatedAdData.scss';

import { Field, useFormikContext } from 'formik';
import React, { memo, useCallback } from 'react';

import TextInput from '../../../../../../../common/TextInput';
import bem from '../../../../../../../utils/bem';
import { FieldName } from '../../../enums';
import type { DuplicateLineItem } from '../../../hooks';
import type { DuplicateAdFormValues } from '../../../types';

const [block, element] = bem('line-item');

type Props = {
  lineItem: DuplicateLineItem;
  index: number;
  errorMessage?: string;
  onSetAdName: (field: string, value: string) => void;
  setLineItemsFormValue?: (value: DuplicateLineItem[], shouldValidate?: boolean | undefined) => void;
};

const DuplicatedAdData = ({ lineItem, index, errorMessage, onSetAdName }: Props): JSX.Element => {
  const {
    values: { lineItems },
    setFieldValue,
  } = useFormikContext<Pick<DuplicateAdFormValues, 'lineItems'>>();

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const editedAdName = e.target.value;

      const lineItemsWithEditedAdName = lineItems!.map((currentLineItem) => {
        if (currentLineItem.id === lineItem.id) {
          return { ...currentLineItem, editedAdName };
        }
        return currentLineItem;
      });

      setFieldValue('lineItems', lineItemsWithEditedAdName);
      onSetAdName(`${FieldName.lineItems}[${index}].adName`, e.target.value);
    },
    [lineItems, lineItem.id, index, onSetAdName, setFieldValue]
  );

  return (
    <div className={block()}>
      <div className={element('input-wrapper')}>
        <div className={element('label-wrapper')}>
          <h3>Line Item</h3>
          <h3>{lineItem.id}</h3>
        </div>
        <Field value={lineItem.name} component={TextInput} disabled={true} className={element('line-item-input')} />
      </div>
      <div className={element('input-wrapper')}>
        <div className={element('label-wrapper')}>
          <h3>Duplicated Ad</h3>
        </div>
        <Field
          name={`${FieldName.lineItems}[${index}].adName`}
          component={TextInput}
          errMsg={errorMessage}
          value={lineItems && lineItems[index]?.adName}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default memo(DuplicatedAdData);
