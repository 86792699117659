import './MultipleEditDrawerContentTable.scss';

import React from 'react';
import type { Column, IdType, TableState } from 'react-table';

import CommonTable from '../../../../../../common/Table/Table';
import bem from '../../../../../../utils/bem';

const [block] = bem('multiple-edit-drawer-content-table');

export type MultipleEditDrawerContentTableProps<RawObject extends {} = {}> = {
  data: RawObject[];
  isSortable?: boolean;
  addCheckbox?: boolean;
  columns: Column<RawObject>[];
  initialState?: Partial<TableState<RawObject>>;
  listenSelectedRows?: (selectedRows: Record<IdType<RawObject>, boolean>) => void;
};

export default function MultipleEditDrawerContentTable<RawObject extends {} = {}>({
  data,
  columns,
  isSortable,
  addCheckbox,
  initialState,
  listenSelectedRows,
}: MultipleEditDrawerContentTableProps<RawObject>): JSX.Element {
  return (
    <div className={block()}>
      <CommonTable
        body={data}
        headers={columns}
        isSortable={isSortable}
        addCheckbox={addCheckbox}
        initialState={initialState}
        listenSelectedRows={listenSelectedRows}
      />
    </div>
  );
}
