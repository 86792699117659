import './PreviewWrapper.scss';

import { Label } from '@hulu/react-style-components';
import React from 'react';

import bem from '../../../utils/bem';

export interface PreviewWrapperProps {
  children: JSX.Element;
  label?: string;
  value?: string;
  isPreview?: boolean;
}

const [block, element] = bem('preview-wrapper');

/**
 * This wrapper should be used when MC needs a component with both editable mode and preview mode.
 * When isPreview is true, then the component shows just the 'label' and 'value'
 * When isPreview is false or undefined, then the component shows the children component.
 */
function PreviewWrapper({ children, label, value, isPreview }: PreviewWrapperProps): JSX.Element {
  return !isPreview ? (
    children
  ) : (
    <div className={block()}>
      <Label>{label}</Label>
      <p className={element('content')}>{value}</p>
    </div>
  );
}

export default PreviewWrapper;
