import styled from 'styled-components';

import { AssetStatusPill } from '../../../../../atoms/AssetStatusPill';

export const PositionedAssetStatusPill = styled(AssetStatusPill)`
  position: absolute;
  top: 0.625em;
  left: 0.625em;
  max-width: calc(100% - 6em);
  word-break: break-word;
`;
