import type { SyntheticEvent } from 'react';

import type { AdsPageAd } from '../../../../../common/AdForm/hooks/types';
import { isSavedAd } from '../../utils/utils';

interface UseAdListItemProps {
  adIndex: number;
  ad: AdsPageAd;
  onClick: (adIndex: number, adId?: string) => void;
  onClickDelete: (adIndex: number) => void;
}

interface AdListItemReturn {
  handleItemClick(e: SyntheticEvent): void;
  handleSelectAd(e: React.KeyboardEvent<HTMLLIElement>): void;
  handleDeleteClick(e: SyntheticEvent): void;
  handleRemoveAd(e: React.KeyboardEvent<HTMLSpanElement>): void;
}

export default function useAdListItem({ adIndex, ad, onClick, onClickDelete }: UseAdListItemProps): AdListItemReturn {
  const adId = !!ad.id ? ad.id : undefined;

  const handleKeyUp = (e: React.KeyboardEvent<HTMLLIElement>): void => {
    if (isSavedAd(ad) && (e.key === 'Enter' || e.key === ' ')) {
      e.preventDefault();
      e.currentTarget.blur();
    }
  };

  const handleItemClick = (e: SyntheticEvent): void => {
    e.stopPropagation();
    onClick(adIndex, adId);
  };

  const handleSelectAd = (e: React.KeyboardEvent<HTMLLIElement>): void => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      onClick(adIndex, adId);
      handleKeyUp(e);
    }
  };

  const handleDeleteClick = (e: SyntheticEvent): void => {
    e.stopPropagation();
    onClickDelete(adIndex);
  };

  const handleRemoveAd = (e: React.KeyboardEvent<HTMLSpanElement>): void => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.stopPropagation();
      onClickDelete(adIndex);
    }
  };

  return { handleItemClick, handleSelectAd, handleDeleteClick, handleRemoveAd };
}
