import type { CreativeMetadata } from '../../../../apis/graphql';
import { campaignClient } from '../../../../apis/graphql';
import { UPDATE_CREATIVE_METADATA_BY_CREATIVE_ID } from '../../../../pages/Ads/hooks/mutations';

export const updateCreativeMetaDataByCreativeId = async (
  creativeId: string,
  countryCodes: string[],
  isLegalApproved: boolean
): Promise<CreativeMetadata | null> => {
  const { data, errors } = await campaignClient.mutate({
    mutation: UPDATE_CREATIVE_METADATA_BY_CREATIVE_ID,
    variables: { creativeId, countryCodes, isLegalApproved },
  });
  if (errors?.length) {
    console.error('UPDATE_CREATIVE_METADATA_BY_CREATIVE_ID FAILED', errors);
    return null;
  }
  return data;
};
