import React from 'react';
import ReactPlayer from 'react-player';

import type { VideoAssetV5 } from '../../../../../apis/graphql';
import { useAsset } from '../../../../../hooks/useAsset';
import { getPreviewUrl } from '../../../../AssetPreview/util';
import Loader from '../../../../Loader';
// import { XButton } from '../../atoms/XButton';
import { LoaderWrapper, VideoContainer, VideoFigure } from './styles';

export const VideoPlayer: React.FC<{ asset: VideoAssetV5 }> = ({ asset, children }) => {
  const { assetUrl } = useAsset(getPreviewUrl(asset.id), asset?.transcodeStatus);

  return (
    <VideoFigure>
      {/* Removed X button for now until revisited if removal of asset is needed */}
      {/* <XButton /> */}
      {children}
      <VideoContainer>
        {!assetUrl ? (
          <>
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
            <video aria-label="video-player" controls src={`assetUrl`} />
          </>
        ) : (
          <ReactPlayer url={assetUrl} controls={true} width={`100%`} height={`100%`} />
        )}
      </VideoContainer>
    </VideoFigure>
  );
};
