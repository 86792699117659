import type { GetAssetByIdQuery, ReviewTaskV5 } from '../../../../../apis/graphql';
import type { Nullable } from '../../../../../models';

export const getReviewTask = (
  res?: GetAssetByIdQuery['getReviewTasksV5']
): Nullable<Pick<ReviewTaskV5, 'id' | 'spReview'>> => {
  if (res?.edges[0]?.node.id) return res?.edges[0].node;

  return null;
};
