import { sortBy } from 'lodash';

import type { AdSelectorOption, CreativesFragment, CreativeV5Asset, Maybe } from '../../../../apis/graphql';
import { getDefaultAdSelectorSlateOptionList } from '../../adFormik';
import type { CreativesBuilderValues, CreativeV5Types } from '../../CreativeBuilders/types';

type AssetList = Maybe<Array<{ __typename?: 'CreativeV5Asset' } & Pick<CreativeV5Asset, 'id'>>>;

const transformedAssetList = (assetList: AssetList): Array<{ id: string }> =>
  assetList ? sortBy(assetList, 'id').map((asset) => ({ id: asset.id })) : [];

const getCreativeFieldsByType = (creative: CreativesFragment): CreativeV5Types => {
  switch (creative.__typename) {
    case 'DisplayCreativeV5':
      return { image: { id: creative.image?.id }, altText: creative.altText || '' };
    case 'PauseCreativeV5':
      return {
        image: { id: creative.image?.id },
        altText: creative.altText || '',
        accent: creative.accent || null,
      };
    case 'SlateCreativeV5':
      return { slate: { id: creative.slate?.id } };
    case 'VASTVideoCreativeV5':
      return {
        url: creative.url || '',
        videoAssetList: transformedAssetList(creative?.videoAssetList),
        vpaid: creative.vpaid || false,
      };
    case 'VideoCreativeV5':
      return creative?.video?.id ? { video: { id: creative.video.id } } : {};
    // Placeholders for other creative types, if needed
    case 'MagniteCreativeV5':
      return {
        adSourceId: creative.adSourceId || '',
        videoAssetList: transformedAssetList(creative?.videoAssetList),
        url: creative.url || '',
      };
    case 'DraxCreativeV5':
      return {
        videoAssetList: transformedAssetList(creative?.videoAssetList),
        url: creative.url || '',
      };
    case 'AdSelectorVideoCreativeV5':
      return creative?.video?.id ? { video: { id: creative.video.id } } : {};
    case 'AdSelectorSlateCreativeV5':
      return {
        headline: creative.headline || '',
        randomOrder: !!creative.randomOrder,
        logo: creative.logo ? { id: creative.logo.id } : {},
        voiceoverUrl: creative.voiceoverUrl || '',
        optionList: getDefaultAdSelectorSlateOptionList(creative.optionList as AdSelectorOption[]),
      };
    case 'GatewayGoCreativeV5':
      return {
        livingRoomCopy: creative.livingRoomCopy || '',
        destinationUrl: creative.destinationUrl || '',
        pushTitle: creative.pushTitle || '',
        pushBody: creative.pushBody || '',
        emailBody: creative.emailBody || '',
        emailSubject: creative.emailSubject || '',
        offerImage: creative.offerImage?.id ? { id: creative.offerImage.id } : {},
        video: creative.video?.id ? { id: creative.video.id } : {},
      };
    case 'BrightlineCreativeV5':
    case 'InnovidCreativeV5':
      return {
        template: creative.template || '',
        videoAssetList: transformedAssetList(creative?.videoAssetList),
        url: creative.url || '',
      };
    case 'MarqueePromotedContentCreativeV5':
    default:
      return {};
  }
};

export const creativeFragmentToBuilderValues = (creative: CreativesFragment): CreativesBuilderValues => {
  const { type, name, id, review, assetTagList, creativeLibraryId, createdAt, status } = creative;
  return {
    createdAt,
    mode: 'build',
    type,
    name,
    id,
    assetTagList,
    review,
    creativeLibraryId,
    status,
    ...getCreativeFieldsByType(creative),
  };
};
