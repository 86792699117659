export const SELECTION_COLUMN_ID = 'selection';

export const LINE_ITEMS_STATUSES = {
  ON: 'ON',
  OFF: 'OFF',
  CANCELLED: 'CANCELLED',
  PAUSED: 'PAUSED',
};

export const LINE_ITEMS_SUB_STATUSES = {
  READY: 'READY',
  LIVE: 'LIVE',
  LATE: 'LATE',
  ENDED: 'ENDED',
  DID_NOT_RUN: 'DID_NOT_RUN',
};

export const MISSING_LINE_ITEM_ERROR_MESSAGE = 'Missing Line Item';

export const MISSING_LINE_ITEM_DURING_PACING_TYPE_UPDATE_DESCRIPTION =
  'Cannot update pacing type because Ad does not have a Line Item';
