import type { CampaignTagV5, FrequencyCapV5, Get_AdsQuery, ScheduleV5 } from '../../../../apis/graphql';
import { getSortedAdTagsFromFragment } from '../../../../common/AdForm/hooks/modelConverters';
import { defaultSupportedTimezones } from '../../../../common/commonMocks/timezones';
import {
  convertAdProduct,
  convertAdRating,
  convertAdStatus,
  convertAdType,
  convertBillableThirdParty,
  convertFrequencyCapUnitType,
  convertReview,
  convertViewabilityVendor,
} from '../../../../configs';
import type { AdAccount, AdV5, CrmLink, Nullable, TargetingTermValue } from '../../../../models';
import type { CampaignModel } from './campaignConverter';
import type { LineItemModel } from './lineItemConverter';

export type AdNode = NonNullable<Get_AdsQuery['ads']['edges'][number]>['node'];

export type AssociatedLineItem = Pick<
  LineItemModel,
  | 'id'
  | 'name'
  | 'adProduct'
  | 'startDate'
  | 'endDate'
  | 'schedule'
  | 'displayPublisherTarget'
  | 'billableThirdParty'
  | 'isCoppaOrCaru'
  | 'parentLineOmsLink'
  | 'exclusiveBlock'
  | 'country'
  | 'inventoryBlockId'
> & {
  campaign: Pick<CampaignModel, 'id' | 'name' | 'startDate'> & {
    adAccount: Pick<AdAccount, 'timezone' | 'organizationId'>;
    campaignTagList: Array<Pick<CampaignTagV5, 'type' | 'url'>>;
    crmLink: Nullable<Pick<CrmLink, 'name'>>;
  };
};

export type AdModel = { __typename?: 'AdV5' } & Pick<
  AdV5,
  | 'id'
  | 'name'
  | 'type'
  | 'review'
  | 'rating'
  | 'status'
  | 'frequencyCapList'
  | 'createdAt'
  | 'updatedAt'
  | 'viewabilityVendorList'
  | 'sequence'
  | 'adRotation'
  | 'adSequence'
  | 'startDate'
  | 'endDate'
  | 'trackingId'
  | 'unDeletable'
  | 'displayPublisherTarget'
  | 'hasMisalignedSchedule'
  | 'adTagList'
  | 'country'
> & {
    lineItem: Nullable<{ __typename?: 'LineItemV5' } & AssociatedLineItem>;
    schedule: Nullable<Pick<ScheduleV5, 'timezone' | 'deliveryInViewerTime' | 'dateRangeList' | 'dayPartList'>>;
    creative: AdNode['creative'];
  };

export const adlineItemNodeToModel = (lineItem: AdNode['lineItem']): Nullable<AssociatedLineItem> => {
  return lineItem
    ? {
        ...lineItem,
        billableThirdParty: lineItem?.billableThirdParty
          ? convertBillableThirdParty(lineItem?.billableThirdParty)
          : null,
        adProduct: convertAdProduct(lineItem?.adProduct || 'VIDEO'),
        campaign: {
          name: lineItem?.campaign?.name || '',
          id: lineItem?.campaign?.id || '',
          adAccount: {
            timezone: lineItem?.campaign?.adAccount?.timezone || defaultSupportedTimezones.ET,
            organizationId: lineItem?.campaign?.adAccount?.organizationId || '',
          },
          campaignTagList: lineItem?.campaign?.campaignTagList || [],
          crmLink: lineItem?.campaign?.crmLink || null,
          startDate: lineItem?.campaign?.startDate,
        },
        exclusiveBlock: lineItem.exclusiveBlock
          ? {
              ...lineItem.exclusiveBlock,
              id: lineItem.exclusiveBlock?.id ?? null,
              name: lineItem.exclusiveBlock?.name ?? null,
              lineItemIdList: lineItem.exclusiveBlock?.lineItemIdList ?? [],
              blockedIndustryList: lineItem.exclusiveBlock?.blockedIndustryList ?? [],
              targetingTermValues: (lineItem?.exclusiveBlock?.targetingTermValues ?? []) as TargetingTermValue[],
            }
          : null,
      }
    : null;
};

export function adNodeToModel(node: AdNode): AdModel {
  return {
    ...node,
    adSequence: node?.adSequence,
    type: convertAdType(node.type),
    review: convertReview(node.review),
    rating: convertAdRating(node.rating),
    status: convertAdStatus(node.status),
    unDeletable: node.unDeletable,
    schedule: node.schedule
      ? {
          timezone: node.schedule.timezone,
          deliveryInViewerTime: node.schedule.deliveryInViewerTime,
          dateRangeList: node.schedule.dateRangeList,
          dayPartList: node.schedule.dayPartList,
        }
      : null,
    lineItem: adlineItemNodeToModel(node.lineItem),
    viewabilityVendorList: node.viewabilityVendorList.map((vv) => convertViewabilityVendor(vv)),
    frequencyCapList: node.frequencyCapList
      ? node.frequencyCapList.map((fC: FrequencyCapV5) => ({
          ...fC,
          duration: fC.duration || 0,
          durationUnit: convertFrequencyCapUnitType(fC.durationUnit),
        }))
      : null,
    adTagList: getSortedAdTagsFromFragment(node.adTagList),
  };
}
