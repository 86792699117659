import React from 'react';

const BufferLabel = (): React.JSX.Element => {
  return (
    <>
      <div>
        <span>Buffer &</span>
      </div>
      <div>
        <span>Frontloading</span>
      </div>
    </>
  );
};

export default BufferLabel;
