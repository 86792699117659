export type Modifier = string | null;
export type Modifiers = string | null | Modifier[];

export interface BlockBuilder {
  (modifiers?: Modifiers): string;
}

export interface ElementBuilder {
  (element: string, modifiers?: Modifiers): string;
}

const baseBuilder = (base: string, modifiers?: Modifiers): string => {
  if (!modifiers) return base;

  if (!Array.isArray(modifiers)) {
    return `${base} ${base}--${modifiers}`;
  }

  if (modifiers.length === 0) return base;

  const toModStr = (accum: string, modifier: Modifier): string => {
    if (modifier) {
      if (accum === '') {
        // prepend ' ' if accum is still empty
        return ` ${base}--${modifier}`;
      } else {
        return `${accum} ${base}--${modifier}`;
      }
    }

    return accum;
  };

  return `${base}${modifiers.reduce(toModStr, '')}`;
};

export default function bem(block: string): [BlockBuilder, ElementBuilder] {
  const toElementName = (element: string): string => `${block}__${element}`;
  const bmBuilder = (modifiers?: Modifiers): string => baseBuilder(block, modifiers);
  const emBuilder = (element: string, modifiers?: Modifiers): string => baseBuilder(toElementName(element), modifiers);
  return [bmBuilder, emBuilder];
}

export const withOptionalClassName = (base: string, className?: string): string =>
  className ? base.concat(' ', className) : base;
