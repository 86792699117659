import type { PreIngestResult } from '../../../../apis/graphql';
import { campaignClient } from '../../../../apis/graphql';
import { AlertType } from '../../../../common/Alert';
import * as Toast from '../../../../common/ToastAlert/toastAlert';
import { START_ASSET_INGESTION } from './mutations/startAssetIngestion';

export function showToast(success: boolean, description?: string): void {
  Toast.openToastAlert({
    alertType: success ? AlertType.SUCCESS : AlertType.ERROR,
    message: `Ingestion ${success ? 'Started' : 'Failed'}`,
    description,
  });
}

export async function startAssetIngestion(creativeId?: string, vastUrl?: string): Promise<void> {
  if (!creativeId) {
    showToast(false, 'Please save the ad before beginning ingestion.');
    return;
  }
  if (!vastUrl) {
    showToast(false, 'Please enter a URL for the Vast Creative.');
    return;
  }

  try {
    const result = await campaignClient.mutate<{ startAssetIngestion: PreIngestResult }>({
      mutation: START_ASSET_INGESTION,
      variables: { creativeId, vastUrl },
    });

    if (result.data?.startAssetIngestion.duplicate) {
      showToast(false, 'Duplicate Job requested. Please wait up to 15 minutes before ingesting again.');
      return;
    }

    showToast(!!(result && result.data));
  } catch (err) {
    const msg = err instanceof Error && err.message ? err.message : 'Unknown error encountered.';

    showToast(false, msg);
  }
}
