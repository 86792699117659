import './Frequency.scss';

import { useFormikContext } from 'formik';
import type { SetStateAction } from 'react';
import React from 'react';

import Metadata from '../../../../../../../../../common/AdForm/Metadata/Metadata';
import type { ClearableFrequencyCap } from '../../../../../../../../../common/FrequencyCaps/FrequencyCaps';
import bem from '../../../../../../../../../utils/bem';
import type { MultipleEditDrawerLineItemsFormValues } from '../../../../../types';

const [block, element] = bem('frequency-multi-edit-section');

export default function Frequency(): JSX.Element {
  const {
    values: { frequencyCapList },
    setFieldValue,
  } = useFormikContext<MultipleEditDrawerLineItemsFormValues>();

  const onChange = (value: SetStateAction<ClearableFrequencyCap[]>): void => {
    setFieldValue('frequencyCapList', value);
  };

  return (
    <div className={block()}>
      <div className={element('header')}>Changes will override existing frequency caps.</div>
      <Metadata
        stashedFrequencyCapList={frequencyCapList}
        setStashedFrequencyCapList={onChange}
        withViewabilityVendor={false}
        withUnlimitedTab={false}
        frequencyCapType="LINE_ITEM"
      />
    </div>
  );
}
