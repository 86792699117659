import './DragDropList.scss';

import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import ListToggle from '../../ListToggle';
import DragDropEntity from '../DragDropEntity';
import type { DraggedElementsGroup } from '../types';

export type DragDropListProps = DraggedElementsGroup & {
  beforeListElement?: JSX.Element | null;
  onToggleHandler: (listId: string, expanded: boolean) => void;
};

const DragDropList: React.FC<DragDropListProps> = ({
  id,
  element,
  childElements,
  onToggleHandler,
  initialExpanded,
  endElement,
  errorElement,
  beforeListElement,
}) => {
  const toggleList = (expanded: boolean): void => onToggleHandler(id, expanded);

  return (
    <Droppable droppableId={id}>
      {(provided): JSX.Element => (
        <div ref={provided.innerRef} className="dnd-list" data-testid={id}>
          <ListToggle initialExpanded={initialExpanded} header={element} toggleHandler={toggleList}>
            {beforeListElement}
            {childElements.map((childEl, index) => (
              <DragDropEntity key={childEl.id} element={childEl.element} id={childEl.id} index={index} />
            ))}
          </ListToggle>
          {errorElement}
          {endElement}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default DragDropList;
