import './TraffickingTable.scss';

import { IconAlertInfoOutlined } from '@hulu-react-style-components/icons';
import { colorGray1 } from '@hulu/design-tokens/lib/colors.common';
import { isEmpty as _isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import type { ColumnInstance, HeaderGroup, Row, TableInstance } from 'react-table';
import ReactTooltip from 'react-tooltip';

import type { TraffickingTableName } from '../../constants';
import { sortOptionMap } from '../../pages/Trafficking/TraffickingPage/hooks';
import useGroupedTraffickerData from '../../pages/Trafficking/TraffickingPage/hooks/useGroupedTraffickerData';
import type { EntityModel } from '../../pages/Trafficking/TraffickingPage/modelConverters';
import bem from '../../utils/bem';
import { COLUMNS_TOOLTIP_MESSAGES, FOCUSABLE_ELEMENT_SELECTOR } from '../constants';
import Loader from '../Loader';
import SortArrow from '../SortArrow';
import TableStatus from '../TableStatus';
import type { TableStatusProps } from '../TableStatus/TableStatus';
import TraffickingTableGrouping from '../TraffickingTableGrouping';
import AnimatedBordersContainer from './AnimatedBordersContainer';
import type { ComposedTraffickingTableProps } from './ComposedTraffickingTable';
import ComposedTraffickingTable from './ComposedTraffickingTable';
import type { ScrollingBlockMod } from './types';
import { resizeSeparator, resizingData } from './utils';

export interface TableProps {
  tableName: TraffickingTableName;
  emptyMessage?: React.ReactNode;
  errorMessage?: string;
  loading: boolean;
  initialLoading: boolean;
  tableInstance: TableInstance<EntityModel>;
  hasMore: boolean;
  onNext: () => void;
  keyboardOnNext: (keyboardRowPosition: number) => void;
  defaultLimit: number;
  isColumnResizing: boolean;
  isSequenceViewEnabled: boolean;
}

const IS_SEQUENCE_VIEW_MESSAGE = 'Cannot sort rows when Sequence View is enabled.';

const [block, element] = bem('trafficking-table');

const defaultWidthValue = 300;

const headerToElement = ({
  getHeaderProps,
  render,
  getSortByToggleProps,
  canSort,
  canResize,
  isResizing,
  getResizerProps,
  isSorted,
  isSortedDesc,
  id,
  tableName,
  width,
  rows,
  className,
  isSequenceViewEnabled,
  tableIndex,
}: ColumnInstance<EntityModel> & {
  tableName: TraffickingTableName;
  className?: string;
  isSequenceViewEnabled?: boolean;
  rows: Row<EntityModel>[];
  tableIndex: number;
}): JSX.Element => {
  isResizing && resizingData.setData(tableName, id, width || defaultWidthValue);
  const columnTooltip = COLUMNS_TOOLTIP_MESSAGES.find((tooltip) => tooltip.columnId === id) ?? null;
  const { onClick: onSortClick, ...sortToggleProps } = getSortByToggleProps();

  const handleSortClick = (e: React.MouseEvent<Element, MouseEvent>): void => {
    if (!isSequenceViewEnabled && onSortClick) {
      onSortClick(e);
    }
  };

  const renderTableHeader = (
    <>
      {!columnTooltip ? (
        <>{render('Header', { rows, isGrouped: !!isSequenceViewEnabled })}</>
      ) : (
        <>
          {render('Header', { rows, isGrouped: !!isSequenceViewEnabled })}
          <IconAlertInfoOutlined
            className={element('info-icon')}
            aria-label={`${columnTooltip?.columnId}Tooltip`}
            data-for={id + '_' + tableIndex}
            data-tip={columnTooltip?.message}
            data-place="bottom"
          />
        </>
      )}
    </>
  );

  return (
    /**
     * position: 'sticky' is important here because it overwrite useResizeColumns position: 'relevant'. Need for resizing
     * Tooltip is moved outside of 'th' in order to avoid 'stacking contexts'
     * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
     */
    <th {...getHeaderProps({ className, style: { position: 'sticky' } })} key={id}>
      <div
        className={`${element(isSorted ? 'header-cell-sorted' : 'header-cell')} ${element(
          isSequenceViewEnabled ? 'sequence-view' : ''
        )}`}
        title=""
      >
        {renderTableHeader}
        <div aria-label="sort" {...sortToggleProps} onClick={handleSortClick}>
          {canSort && sortOptionMap[tableName][id] && (
            <span
              data-for={id + '_' + tableIndex}
              data-html
              data-place="bottom"
              data-tip={IS_SEQUENCE_VIEW_MESSAGE}
              data-tip-disable={!isSequenceViewEnabled}
            >
              <SortArrow isSorted={isSorted} isDescending={isSortedDesc} />
            </span>
          )}
        </div>
        {canResize && getResizerProps && <div {...getResizerProps({ className: resizeSeparator() })} />}
      </div>
      <ReactTooltip id={id + '_' + tableIndex} backgroundColor={colorGray1} effect="solid" />
    </th>
  );
};

const headerGroupToElement = ({
  getHeaderGroupProps,
  headers,
  tableName,
  isSequenceViewEnabled,
  rows,
  tableIndex,
}: HeaderGroup<EntityModel> & {
  tableName: TraffickingTableName;
  isSequenceViewEnabled?: boolean;
  rows: Row<EntityModel>[];
  tableIndex: number;
}): JSX.Element => {
  return (
    <tr {...getHeaderGroupProps()}>
      {headers.map((headerContext: HeaderGroup<EntityModel>) =>
        headerToElement({ ...headerContext, tableName, isSequenceViewEnabled, rows, tableIndex })
      )}
    </tr>
  );
};

const scrollStyle = { overflow: 'initial' };

const TraffickingTable = ({
  tableName,
  emptyMessage,
  errorMessage,
  loading,
  initialLoading,
  tableInstance,
  hasMore,
  onNext,
  keyboardOnNext,
  defaultLimit,
  isColumnResizing,
  isSequenceViewEnabled,
}: TableProps): JSX.Element => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds },
    toggleRowSelected,
  } = tableInstance;
  const [scrollModifiers, setScrollModifiers] = useState<ScrollingBlockMod[]>([]);
  const [lastSelectedRowId, setLastSelectedRowId] = useState<string>('');
  const { isGrouped, groupedData, ungroupedData } = useGroupedTraffickerData({
    tableName,
    rows: [...rows],
    isSequenceViewEnabled,
  });

  useEffect(() => {
    if (_isEmpty(selectedRowIds)) {
      setLastSelectedRowId('');
    }
  }, [selectedRowIds]);

  const scrollRef = useRef<HTMLDivElement>(null);

  const [selectedColIndex, setSelectedColIndex] = useState(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const maxRowIndex = rows.length - 1;
  const maxColIndex = rows?.[0]?.cells?.length - 1;

  const focusCell = (colIndex: number, rowIndex: number): void => {
    let focusableElement;
    const selectedCell = document.querySelector(`[data-col="${colIndex}"][data-row="${rowIndex}"]`) as HTMLElement;
    // there's sometimes unexpected behavior when we don't move focus to the parent cell first before the focusableElement
    selectedCell.focus();

    // select inner focusable element, this allows for keyboard interactions directed with the relevant element
    if (selectedCell) focusableElement = selectedCell.querySelector(FOCUSABLE_ELEMENT_SELECTOR) as HTMLElement;
    if (focusableElement) focusableElement.focus();
  };

  const onKeyDownHandler = useCallback(
    (e: React.KeyboardEvent<HTMLTableElement>): void => {
      const allowedKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
      const { key } = e;

      let newColIndex = selectedColIndex;
      let newRowIndex = selectedRowIndex;

      if (key === 'ArrowLeft') {
        newColIndex = selectedColIndex - 1;
      }
      if (key === 'ArrowRight') {
        newColIndex = selectedColIndex + 1;
      }
      if (key === 'ArrowUp') {
        newRowIndex = selectedRowIndex - 1;
      }
      if (key === 'ArrowDown') {
        newRowIndex = selectedRowIndex + 1;
      }

      if (allowedKeys.includes(key)) {
        e.preventDefault();
        newColIndex = Math.min(Math.max(0, newColIndex), maxColIndex);
        newRowIndex = Math.min(Math.max(0, newRowIndex), maxRowIndex);
        setSelectedColIndex(newColIndex);
        setSelectedRowIndex(newRowIndex);
        focusCell(newColIndex, newRowIndex);

        if (selectedRowIndex !== 0 && (selectedRowIndex + 1) % defaultLimit === 0) {
          keyboardOnNext(selectedRowIndex);
        }
      }
    },
    [defaultLimit, keyboardOnNext, maxColIndex, maxRowIndex, selectedColIndex, selectedRowIndex]
  );

  // class for when the table should be "invisible"
  const tableModifier = loading || rows.length === 0 || errorMessage ? 'invisible' : null;
  const hasMoreThanMinimumBatchRequirement = rows.length !== 0 && rows.length >= 20;

  const getComposedTraffickerTableProps = useCallback((): Omit<
    ComposedTraffickingTableProps,
    'rows' | 'tableIndex'
  > => {
    return {
      errorMessage,
      getTableBodyProps,
      getTableProps,
      headerGroups,
      initialLoading,
      isColumnResizing,
      isSequenceViewEnabled,
      lastSelectedRowId,
      onKeyDownHandler,
      prepareRow,
      selectedRowIndex,
      tableModifier,
      tableName,
      headerGroupToElement,
      setLastSelectedRowId,
    };
  }, [
    errorMessage,
    getTableBodyProps,
    getTableProps,
    headerGroups,
    initialLoading,
    isColumnResizing,
    isSequenceViewEnabled,
    lastSelectedRowId,
    onKeyDownHandler,
    prepareRow,
    selectedRowIndex,
    tableModifier,
    tableName,
  ]);

  const getEmptyStatusProps = useCallback((): Omit<TableStatusProps, 'empty'> => {
    return {
      emptyMessage,
      errorMessage,
      loading: initialLoading,
    };
  }, [emptyMessage, errorMessage, initialLoading]);

  const scrollContainerClasses = useMemo(() => {
    if (isGrouped) return block();

    return block(scrollModifiers);
  }, [isGrouped, scrollModifiers]);

  return (
    <div ref={scrollRef} id="scroll-container" className={scrollContainerClasses}>
      <InfiniteScroll
        scrollableTarget="scroll-container"
        dataLength={rows.length}
        next={onNext}
        hasMore={hasMore}
        style={scrollStyle}
        loader={
          <div className={element('loader')}>
            {loading && !errorMessage && hasMoreThanMinimumBatchRequirement && <Loader />}
          </div>
        }
      >
        {isGrouped && !errorMessage ? (
          <TraffickingTableGrouping
            groupedData={groupedData}
            ungroupedData={ungroupedData}
            toggleRowSelected={toggleRowSelected}
            getEmptyStatusProps={getEmptyStatusProps}
            getComposedTraffickerTableProps={getComposedTraffickerTableProps}
          />
        ) : (
          <AnimatedBordersContainer scrollRef={scrollRef} setScrollModifiers={setScrollModifiers}>
            <ComposedTraffickingTable {...getComposedTraffickerTableProps()} rows={rows} tableIndex={0} />
          </AnimatedBordersContainer>
        )}
      </InfiniteScroll>
      {!isGrouped && <TableStatus {...getEmptyStatusProps()} empty={rows.length === 0} />}
    </div>
  );
};

export default TraffickingTable;
