import { defaultSupportedTimezones } from '../../../../../../../../../common/commonMocks/timezones';
import type { MultipleEditDrawerAdsFormValues } from '../../../../../types';
import type { Row, TargetingResult } from '../../types';
import type { TargetingConflictResult } from '../checkConflicts/useCheckTargetingConflicts/useCheckTargetingConflicts';
import { getConflictComponents } from './conflicts';
import {
  AD_TARGETING_ALREADY_EXIST_MESSAGE,
  AD_TARGETING_CONFLICT_MESSAGE,
  TARGETING_TYPE_EXCLUDE_CONFLICT_MESSAGE,
  TARGETING_TYPE_INCLUDE_CONFLICT_MESSAGE,
} from './constants';
import { RatingRow, SchedulingRow, TagsRow, TargetingRow } from './rows';

type Props = {
  targeting: TargetingResult;
  values: MultipleEditDrawerAdsFormValues;
  isTargetingErrorsLoading?: boolean;
  conflicts: {
    targeting?: TargetingConflictResult;
    scheduling?: Record<string, string[]> | null;
    tags?: Record<string, string[]> | null;
  };
};

export const getAdsRows = ({ targeting, values, conflicts, isTargetingErrorsLoading }: Props): Row[] => {
  const { schedule, rating, adTagList, selectedSections } = values;

  const isShowTargeting = !!selectedSections.includes('Targeting');
  const isShowScheduling = !!selectedSections.includes('Scheduling');
  const isShowTags = !!selectedSections.includes('Tags');
  const isShowRating = !!selectedSections.includes('Rating');

  const { targeting: targetingConflict, scheduling: schedulingConflict, tags: tagsConflicts } = conflicts;

  const isShowTargetingErrors = !!targetingConflict && isShowTargeting;

  return [
    {
      label: 'Type',
      value: 'Value',
    },
    {
      label: 'Targeting',
      value: <TargetingRow targeting={targeting} isShowTargetingValues={isShowTargeting} />,
      isLoading: isTargetingErrorsLoading,
      conflicts: getConflictComponents([
        {
          conflictIds: targetingConflict?.hasTargetConflict,
          message: AD_TARGETING_CONFLICT_MESSAGE,
          isShown: isShowTargetingErrors,
        },
        {
          message: AD_TARGETING_ALREADY_EXIST_MESSAGE,
          conflictIds: targetingConflict?.hasAlreadyThisTarget,
          isShown: isShowTargetingErrors,
        },
        {
          message: TARGETING_TYPE_EXCLUDE_CONFLICT_MESSAGE,
          conflictIds: targetingConflict?.hasExcludeConflict,
          isShown: isShowTargetingErrors,
        },
        {
          message: TARGETING_TYPE_INCLUDE_CONFLICT_MESSAGE,
          conflictIds: targetingConflict?.hasIncludeConflict,
          isShown: isShowTargetingErrors,
        },
      ]),
    },
    {
      label: 'Scheduling',
      value: (
        <SchedulingRow
          dateRangeList={schedule?.dateRangeList || []}
          dayPartList={schedule?.dayPartList || []}
          timezone={schedule?.timezone || defaultSupportedTimezones.ET}
          deliveryInViewerTime={!!schedule?.deliveryInViewerTime}
          isShown={isShowScheduling}
        />
      ),
      conflicts:
        schedulingConflict &&
        getConflictComponents(
          Object.keys(schedulingConflict).map((conflictKey) => ({
            message: conflictKey,
            conflictIds: schedulingConflict[conflictKey],
            isShown: !!schedulingConflict && isShowScheduling,
          }))
        ),
    },
    {
      label: 'Tags',
      value: <TagsRow adTagList={adTagList} isShown={isShowTags} />,
      conflicts:
        tagsConflicts &&
        getConflictComponents(
          Object.keys(tagsConflicts).map((conflictKey) => ({
            message: conflictKey,
            conflictIds: tagsConflicts[conflictKey],
            isShown: !!tagsConflicts && isShowTags,
          }))
        ),
    },
    {
      label: 'Rating',
      value: <RatingRow rating={rating} isShown={isShowRating} />,
    },
  ];
};
