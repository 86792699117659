import './TraffickingTableGrouping.scss';

import { bem } from 'mission-control-common-components';
import React from 'react';
import type { Row } from 'react-table';

import type { GroupedData, GroupedRows } from '../../pages/Trafficking/TraffickingPage/hooks/useGroupedTraffickerData';
import type { EntityModel } from '../../pages/Trafficking/TraffickingPage/modelConverters';
import type { TableStatusProps } from '../TableStatus/TableStatus';
import TableStatus from '../TableStatus/TableStatus';
import type { ComposedTraffickingTableProps } from '../TraffickingTable/ComposedTraffickingTable';
import ComposedTraffickingTable from '../TraffickingTable/ComposedTraffickingTable';
import TraffickingToggleSection from './TraffickingToggleSection';

export type TraffickingTableGroupingProps = {
  groupedData: GroupedData[];
  ungroupedData: GroupedRows;
  toggleRowSelected: (rowId: string, set?: boolean | undefined) => void;
  getComposedTraffickerTableProps: () => Omit<ComposedTraffickingTableProps, 'rows' | 'tableIndex'>;
  getEmptyStatusProps: () => Omit<TableStatusProps, 'empty'>;
};

const [block, element] = bem('trafficking-table-grouping');

const TraffickingTableGrouping = ({
  groupedData,
  ungroupedData,
  getComposedTraffickerTableProps,
  getEmptyStatusProps,
  toggleRowSelected,
}: TraffickingTableGroupingProps): React.JSX.Element => {
  return (
    <div className={block()}>
      {groupedData.map(({ title, data }) => {
        return (
          <div className={element('section-wrapper')} key={title}>
            <div className={element('title-wrapper')}>
              <div className={element('title')}>{title}</div>
            </div>
            <div className={element('grouping-wrapper')}>
              {data.map(({ title, rows, id }, index) => {
                return (
                  <TraffickingToggleSection
                    key={id}
                    title={title}
                    getComposedTraffickerTableProps={getComposedTraffickerTableProps}
                    toggleRowSelected={toggleRowSelected}
                    rows={rows as Row<EntityModel>[]}
                    tableIndex={index}
                  />
                );
              })}
              <TableStatus {...getEmptyStatusProps()} empty={data.length === 0} />
            </div>
          </div>
        );
      })}
      <div className={element('section-wrapper')}>
        <div className={element('title-wrapper')}>
          <div className={element('title')}>{ungroupedData.title}</div>
        </div>
        <div className={element('ungrouped-wrapper')}>
          <ComposedTraffickingTable
            {...getComposedTraffickerTableProps()}
            rows={ungroupedData.rows as Row<EntityModel>[]}
            tableIndex={-1}
          />
          <TableStatus {...getEmptyStatusProps()} empty={ungroupedData.rows.length === 0} />
        </div>
      </div>
    </div>
  );
};

export default TraffickingTableGrouping;
