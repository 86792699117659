import type { Maybe } from 'graphql/jsutils/Maybe';

import type { TargetingRuleDefinitionV4 } from '../../../../apis/graphql';

export const mapTermList = (termList: Maybe<TargetingRuleDefinitionV4>[]): Maybe<TargetingRuleDefinitionV4>[] => {
  return termList.map((term) => {
    if (!term?.rangeList?.length) {
      return term;
    }
    return {
      ...term,
      valueSet:
        term.rangeList.map((rangeObj) => `${rangeObj?.lower ?? 'NOT_FOUND'} - ${rangeObj?.upper ?? 'NOT_FOUND'}`) || [],
    };
  });
};
