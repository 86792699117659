import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

// Templates
export type BrightlineTemplate = keyof typeof brightlineTemplateDisplayNames;

const brightlineTemplateDisplayNames = {
  AD_SELECTOR: enumToTitleCase,
  DYNAMIC_ADDRESSABLE: enumToTitleCase,
  MAX_SELECTOR: enumToTitleCase,
  EXPANDABLE: enumToTitleCase,
  INSTREAM_INTERACTIVE: enumToTitleCase,
  GATEWAY_SHOP: enumToTitleCase,
  TEST_BRIGHTLINE: enumToTitleCase,
};

export const brightlineTemplates = makeConfig<BrightlineTemplate>(brightlineTemplateDisplayNames);
export const brightlineTemplateKeys = Object.keys(brightlineTemplates) as BrightlineTemplate[];

export function convertBrightlineTemplate(apiValue: string): DisplayEnum<BrightlineTemplate> {
  return convertToDisplayEnum(apiValue, brightlineTemplates);
}

// Feature List
export type BrightlineFeature = keyof typeof brightlineFeatureDisplayNames;

const brightlineFeatureDisplayNames = {
  IN_STREAM_SCROLLABLE: 'In Stream Scrollable',
};

export const brightlineFeatures = makeConfig<BrightlineFeature>(brightlineFeatureDisplayNames);
export const brightlineFeatureKeys = Object.keys(brightlineFeatures) as BrightlineFeature[];

export function convertBrightlineFeature(apiValue: string): DisplayEnum<BrightlineFeature> {
  return convertToDisplayEnum(apiValue, brightlineFeatures);
}
