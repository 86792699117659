import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

export type OrderOMSLinkType = keyof typeof orderOmsLinkTypeDisplayNames;

const orderOmsLinkTypeDisplayNames = {
  OP1_ORDER: enumToTitleCase,
  OP1_PARENT_LINE: enumToTitleCase,
  OP1_CHILD_LINE: enumToTitleCase,
};

export const orderOmsLinkTypes = makeConfig<OrderOMSLinkType>(orderOmsLinkTypeDisplayNames);

export function convertOrderOmsLinkType(apiValue: string): DisplayEnum<OrderOMSLinkType> {
  return convertToDisplayEnum(apiValue, orderOmsLinkTypes);
}
