import type { Dispatch, SetStateAction } from 'react';
import React from 'react';
import type { Row } from 'react-table';

import type { EntityModel } from '../../../pages/Trafficking/TraffickingPage/modelConverters';
import bem from '../../../utils/bem';
import { TraffickingTableCells } from '../TraffickingTableCells';

type Props = {
  prepareRow: (row: Row<EntityModel>) => void;
  rows: Row<EntityModel>[];
  selectedColIndex: number;
  selectedRowIndex: number;
  lastSelectedRowId: string;
  setLastSelectedRowId: Dispatch<SetStateAction<string>>;
};

const [, element] = bem('trafficking-table');

const TraffickingTableRows = ({
  prepareRow,
  rows,
  selectedColIndex,
  selectedRowIndex,
  lastSelectedRowId,
  setLastSelectedRowId,
}: Props): JSX.Element => {
  return (
    <>
      {rows.map((row: Row<EntityModel>) => {
        prepareRow(row);

        const rowCheckedCls = row.isSelected === true ? 'checked' : '';
        const { toggleRowSelected } = row;

        const selectTableRow = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>): void => {
          if (e.shiftKey) {
            const lastSelectedRowIndex = lastSelectedRowId
              ? rows.findIndex((el: Row<EntityModel>) => el.id === lastSelectedRowId)
              : 0;
            const currentSelectedRowIndex = rows.findIndex((el: Row<EntityModel>) => el.id === row.id);
            let rangeForSelection = [];

            if (currentSelectedRowIndex > lastSelectedRowIndex) {
              rangeForSelection = rows.slice(lastSelectedRowIndex, currentSelectedRowIndex + 1);
            } else {
              rangeForSelection = rows.slice(currentSelectedRowIndex, lastSelectedRowIndex + 1);
            }

            rangeForSelection.forEach((row) => row.toggleRowSelected(true));
            window.getSelection()?.removeAllRanges();
          } else {
            toggleRowSelected();
          }

          setLastSelectedRowId(row.id);
        };

        return (
          <tr {...row.getRowProps()} className={element('row', rowCheckedCls)} onClick={selectTableRow}>
            <TraffickingTableCells
              cells={row.cells}
              selectedColIndex={selectedColIndex}
              selectedRowIndex={selectedRowIndex}
            />
          </tr>
        );
      })}
    </>
  );
};

export default TraffickingTableRows;
