import { gql } from '@apollo/client/core';

import { ROTATIONS_FRAGMENT } from '../../../../common/AdForm/hooks/fragments';

export const GET_ROTATIONS_LIST = gql`
  query getRotationsList($lineItemId: ID!) {
    getRotationsV5(lineItemId: $lineItemId) {
      ...rotations
    }
  }
  ${ROTATIONS_FRAGMENT}
`;
