import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

export type BillingTerm = keyof typeof billingTermsDisplayNames;

const billingTermsDisplayNames = {
  NET_30: enumToTitleCase,
  NET_45: enumToTitleCase,
  NET_60: enumToTitleCase,
  NET_90: enumToTitleCase,
  IMMEDIATE: enumToTitleCase,
  PREPAYMENT: enumToTitleCase,
};

export const billingTerms = makeConfig<BillingTerm>(billingTermsDisplayNames);

export function convertBillingTerm(apiValue: string): DisplayEnum<BillingTerm> {
  return convertToDisplayEnum(apiValue, billingTerms);
}
