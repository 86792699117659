import { useState } from 'react';

import { showSuccess } from '../../../../../../common/utils';

type UseViewabiltyVendorModal = {
  isModalOpened: boolean;
  onModalOpen: (event?: React.MouseEvent) => void;
  onModalClose: (event?: React.MouseEvent) => void;
  onSuccess: () => void;
};

export const useViewabilityVendorModal = (): UseViewabiltyVendorModal => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const onModalOpen = (event?: React.MouseEvent): void => {
    event?.stopPropagation();
    setIsModalOpened(true);
  };

  const onModalClose = (event?: React.MouseEvent): void => {
    event?.stopPropagation();
    setIsModalOpened(false);
  };

  const onSuccess = (): void => {
    showSuccess();
    onModalClose();
  };

  return {
    isModalOpened,
    onModalOpen,
    onModalClose,
    onSuccess,
  };
};
