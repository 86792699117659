import { merge as _merge } from 'lodash';
import React from 'react';

import type { AdRotation, AdListData } from '../../../../common/SequenceWeight/types';
import { ComponentWithRouter, withMockedProvider } from '../../../../utils/testing';
import { mockFormValues } from '../AdListItem';
import type { AdListItemsProps } from './AdListItems';
import AdListItems from './AdListItems';
import { PathName } from '../../../../constants';
import { getMockAsset, mockAssetId } from '../../../../common/AdForm/CreativeBuilders/VideoCreativeBuilder/mocks';
import { GET_ASSETS_BY_IDS } from '../../hooks/queries';
import { Query, ScheduleV5 } from '../../../../apis/graphql';
import { AdsPageAd } from '../../../../common/AdForm/hooks/types';
import { DEFAULT_SCHEDULE_OBJECT } from '../../../../common/AdForm/hooks/modelConverters';
import { AdListElement } from '../types';
import { makeAdListElement } from '../utils';
import { mockAd } from '../mocks';
import { AD_SEQUENCE_MOCK } from '../../../Sequence/utils/mocks';

export const generateAd = (
  id: string,
  name: string,
  startDate: string | null,
  endDate: string | null,
  schedule: ScheduleV5 = DEFAULT_SCHEDULE_OBJECT
): AdsPageAd => {
  return {
    ...mockAd,
    name,
    id: id,
    startDate,
    endDate,
    schedule,
  };
};

const ad1 = generateAd('ad1', 'Ad1', '11.11.2000', '11.11.2001');
const ad2 = generateAd('ad2', 'Ad2', '11.11.2001', '11.11.2002');
const ad3 = generateAd('ad3', 'Ad3', null, null, {
  ...DEFAULT_SCHEDULE_OBJECT,
  dateRangeList: [
    {
      startDate: '11.11.2004 00:00:00',
      endDate: '11.11.2005 23:59:59',
      pacingShare: null,
    },
    {
      startDate: '11.11.2002 00:00:00',
      endDate: '11.11.2003 23:59:59',
      pacingShare: null,
    },
  ],
});
const ad4 = generateAd('ad4', 'Ad4', '11.11.2003', '11.11.2004');

export const adsList: AdsPageAd[] = [ad1, ad2, ad3];

export const sequencedAdList: AdsPageAd[] = [
  {
    ...ad1,
    adSequence: {
      ...AD_SEQUENCE_MOCK,
      sequenceId: 'test-sequence-id',
      name: null,
      order: 2,
    },
  },
  {
    ...ad2,
    adSequence: {
      ...AD_SEQUENCE_MOCK,
      order: 1,
    },
  },
  {
    ...ad3,
    adSequence: {
      ...AD_SEQUENCE_MOCK,
      sequenceId: 'test-sequence-id',
      name: null,
      order: 1,
    },
  },
  {
    ...ad4,
    adSequence: {
      ...AD_SEQUENCE_MOCK,
      order: 2,
    },
  },
];

export const adsWithoutSequencesOrWeights = [
  {
    ...ad1,
    adSequence: null,
  },
  {
    ...ad2,
    adSequence: null,
  },
  {
    ...ad3,
    adSequence: null,
  },
  {
    ...ad4,
    adSequence: null,
  },
];

export const adListData: AdListData = {
  rotations: [
    {
      id: 'id1',
      name: 'Rotation 1',
      order: 0,
      adsList: [
        {
          adId: 'ad1',
          weight: 50,
          order: 0,
          name: 'Ad1',
          startDate: adsList[0].startDate,
          endDate: adsList[0].endDate,
          adSequence: null,
          dayPartList: [],
          targetingTermValues: [],
        },
        {
          adId: 'ad2',
          weight: 21,
          order: 2,
          name: 'Ad2',
          startDate: adsList[1].startDate,
          endDate: adsList[1].endDate,
          adSequence: null,
          dayPartList: [],
          targetingTermValues: [],
        },
      ],
    },
    {
      id: 'id2',
      name: 'Rotation 2',
      order: 0,
      adsList: [
        {
          adId: 'ad3',
          weight: 100,
          order: 0,
          name: 'Ad3',
          startDate: adsList[2].schedule?.dateRangeList[0].startDate,
          endDate: adsList[2].schedule?.dateRangeList[0].endDate,
          adSequence: null,
          dayPartList: [],
          targetingTermValues: [],
        },
      ],
    },
  ],
  sequences: [],
  adsWithoutWeightOrSequence: [],
};

export const adsWithoutWeightOrSequence: AdRotation[] = [
  {
    adId: 'ad1',
    order: 0,
    weight: 0,
    name: 'Ad1',
    startDate: null,
    endDate: null,
    adSequence: null,
    dayPartList: [],
    targetingTermValues: [],
  },
  {
    adId: 'ad2',
    order: 1,
    weight: 0,
    name: 'Ad2',
    startDate: null,
    endDate: null,
    adSequence: null,
    dayPartList: [],
    targetingTermValues: [],
  },
  {
    adId: 'ad3',
    order: 2,
    weight: 0,
    name: 'Ad3',
    startDate: null,
    endDate: null,
    adSequence: null,
    dayPartList: [],
    targetingTermValues: [],
  },
];

export const sequencedAds: AdRotation[] = [
  {
    ...adsWithoutWeightOrSequence[0],
    adSequence: AD_SEQUENCE_MOCK,
  },
  {
    ...adsWithoutWeightOrSequence[1],
    adSequence: { ...AD_SEQUENCE_MOCK, order: 2 },
  },
  {
    ...adsWithoutWeightOrSequence[2],
    adSequence: { ...AD_SEQUENCE_MOCK, order: 3 },
  },
];

export const mockAds: AdListElement[] = [
  makeAdListElement(mockFormValues, ad1),
  makeAdListElement(mockFormValues, ad2),
  makeAdListElement(mockFormValues, ad3),
];

export const mockSequencedAds: AdListElement[] = [
  makeAdListElement(mockFormValues, {
    ...ad1,
    adSequence: {
      ...AD_SEQUENCE_MOCK,
      order: 0,
    },
  }),
  makeAdListElement(mockFormValues, {
    ...ad2,
    adSequence: {
      ...AD_SEQUENCE_MOCK,
      order: 1,
    },
  }),
  makeAdListElement(mockFormValues, {
    ...ad3,
    adSequence: {
      ...AD_SEQUENCE_MOCK,
      order: 2,
    },
  }),
];

export default {
  title: 'Ads/AdListItems',
  component: AdListItems,
  excludeStories: ['mockAds', 'mockAd', 'adsList', 'rotationsData', 'adsWithoutWeightOrSequence', 'generateAd'],
  argTypes: {
    adList: { control: { disable: true } },
    onClickAd: {
      action: 'clicked with ad index',
    },
    onClickDelete: {
      action: 'clicked delete with ad index',
    },
  },
};

const defaultArgs: AdListItemsProps = {
  adList: [],
  error: undefined,
  loading: false,
  selectedAdIndex: 0,
  onClickAd: (): void => {},
  onClickDelete: (): void => {},
  adListData: { rotations: [], sequences: [], adsWithoutWeightOrSequence: [] },
};

const location = {
  pathname: PathName.adsEdit,
  search: '',
  hash: '',
  state: { values: { id: 123 } },
  key: '',
};

const makeAssetIdsMock = (assetIdList: string[], result: Query['getAssetsByIDsV5']) => ({
  request: {
    query: GET_ASSETS_BY_IDS,
    variables: {
      assetIdList,
    },
  },
  result: {
    data: {
      getAssetsByIDsV5: result,
    },
    error: null,
  },
});

export const Base = (args?: Partial<AdListItemsProps>): JSX.Element =>
  withMockedProvider(
    <AdListItems {..._merge({}, defaultArgs, args)} />,
    [makeAssetIdsMock([mockAssetId], [getMockAsset()])],
    false
  );
Base.story = { name: 'Without Ads' };

export const WithAds = (args?: Partial<AdListItemsProps>): JSX.Element => (
  <ComponentWithRouter
    componentWithRedirection={(): JSX.Element => Base({ ..._merge({}, defaultArgs, args) })}
    startingUrl={location.pathname}
    initialEntries={[location]}
    redirectUrl={location.pathname}
  />
);

WithAds.args = {
  adListData,
  adList: mockAds,
};
