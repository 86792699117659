import type { AdInputV5 } from '../../../../../apis/graphql';
import { AdStatusV5, removeTypeNamesFromObject } from '../../../../../apis/graphql';
import { AdReviewV5 } from '../../../../../apis/graphql';
import type { AdFormValues } from '../../../../../common/AdForm/adFormik';
import { getFrequencyCapInput } from '../../../../../common/AdForm/hooks/modelConverters/adsPageAdModelToNode';
import {
  getAdTagInput,
  getSchedule,
  getTargetingTermValuesInput,
  getViewabilityVendorEnumsFromFormValue,
} from '../../../../../common/AdForm/hooks/modelConverters/adsPageAdModelToNode';

export function constructCreateAdInput(formValues: AdFormValues, lineItemId: string): AdInputV5 {
  return removeTypeNamesFromObject({
    name: formValues.name,
    type: formValues.type,
    review: AdReviewV5.Draft,
    status: formValues.deliveryStatus ? AdStatusV5.On : AdStatusV5.Off,
    rating: formValues.rating,
    lineItemId,
    creativeId: formValues.creative.id || null,
    frequencyCapList: getFrequencyCapInput(formValues.frequencyCapList),
    viewabilityVendorList: getViewabilityVendorEnumsFromFormValue(formValues.viewabilityVendorList),
    adTagList: getAdTagInput(formValues.adTagList),
    targetingTermValues: getTargetingTermValuesInput(formValues.targetingTermValues),
    schedule: getSchedule(formValues.schedule),
  });
}
