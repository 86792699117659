import type { Get_Ads_For_ExportQuery } from '../../../../apis/graphql';
import type { Nullable } from '../../../../models';
import type { AdModel, AssociatedLineItem } from '../../TraffickingPage/modelConverters';
import { adNodeToModel } from '../../TraffickingPage/modelConverters';

export type AdNodeForExport = NonNullable<Get_Ads_For_ExportQuery['ads']['edges'][number]>['node'];

export type AdModelForExport = AdModel & {
  lineItem: Nullable<{ __typename?: 'LineItemV5' } & Omit<AssociatedLineItem, 'exclusiveBlock'>>;
};

export const adNodeToModelForExport = (node: AdNodeForExport): AdModelForExport => {
  return adNodeToModel({ ...node, lineItem: node.lineItem ? { ...node.lineItem, exclusiveBlock: null } : null });
};
