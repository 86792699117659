import { gql } from '@apollo/client';

import { TARGETING_TERM_VALUES } from '../../../../../apis/graphql';
import { AD_TRAFFICKING_COLUMN } from '../fragments';

export const GET_ADS_WITH_TARGETINGS = gql`
  query getAdsWithTargetings($ids: [ID!]!) {
    ads: adsV5(filterOptions: { idList: $ids }) {
      edges {
        node {
          ...adTraffickingColumn
          targetingTermValues {
            ...targetingTermValues
          }
          lineItem {
            startDate
            endDate
            id
            name
            adProduct
            exclusiveBlock {
              id
              name
              lineItemIdList
              blockedIndustryList {
                id
                name
              }
              targetingTermValues {
                category {
                  id
                  name
                  displayName
                }
                dimension {
                  id
                  name
                  displayName
                }
                value {
                  id
                  name
                  displayName
                }
                include
              }
            }
            parentLineOmsLink {
              id
            }
            campaign {
              name
              id
              adAccount {
                id
                timezone
                organizationId
              }
              campaignTagList {
                id
                type
                url
              }
              crmLink {
                id
                name
              }
              startDate
            }
            schedule {
              timezone
              dateRangeList {
                startDate
                endDate
                pacingShare
              }
              dayPartList {
                firstActiveHour
                lastActiveHour
                activeDayList
              }
              deliveryInViewerTime
            }
            displayPublisherTarget
            billableThirdParty
            isCoppaOrCaru
            country
            targetingTermValues {
              ...targetingTermValues
            }
          }
        }
      }
      total
    }
  }
  ${AD_TRAFFICKING_COLUMN}
  ${TARGETING_TERM_VALUES}
`;
