import './TargetingTableActionCell.scss';

import { IconCheck, IconDelete, IconLayers, IconSlash } from '@hulu-react-style-components/icons';
import { useLocation } from '@hulu/react-router-dom';
import type { ModalProps } from 'antd';
import React, { useState } from 'react';
import type { CellProps } from 'react-table';

import { TraffickingTableName } from '../../../constants';
import { PathName } from '../../../constants';
import { useFiltersContext } from '../../../contexts/FilterContext/FilterContext';
import type { TargetingTermValue } from '../../../models';
import { disableByDimension } from '../../../pages/Trafficking/DrawerTargetingTable/utils/utils';
import bem from '../../../utils/bem';
import type { ListHelpers } from '../../../utils/listHelpers';
import ModalPopup from '../../ModalPopup';
import { CustomDimension } from '../../TargetingDropdowns/constants';
import TargetingPresetModal from '../../TargetingPresetModal/TargetingPresetModal';
import ToggleSwitch from '../../ToggleSwitch';

const [block, element] = bem('targeting-table-action-cell');

type TargetingTableActionCellProps = CellProps<TargetingTermValue> & {
  listHelpers: Pick<ListHelpers<TargetingTermValue>, 'removeAt' | 'replaceAt'>;
  isLineItemTargetingTermValue: boolean;
  editMode: boolean;
  changeToggleByDimension?: (value: boolean, row: TargetingTermValue, subGroupType: string) => void;
};

function TargetingTableActionCell({
  row,
  listHelpers: { removeAt, replaceAt },
  value,
  isLineItemTargetingTermValue,
  editMode,
  changeToggleByDimension,
}: TargetingTableActionCellProps): JSX.Element {
  const { pathname } = useLocation();
  const isAdPage = pathname === PathName.adsCreate || pathname === PathName.adsEdit;
  const isLineItemTargetingTermValueNotInCreateAd = isLineItemTargetingTermValue && !isAdPage;
  const isAdTargetingTermValueInCreateAd = !isLineItemTargetingTermValue && isAdPage;
  const isEditableTargetingTermValueInLineItemTab =
    isLineItemTargetingTermValueNotInCreateAd || isAdTargetingTermValueInCreateAd;
  const targetingTerm: TargetingTermValue = row.original;
  const isTargetingPreset = targetingTerm.dimension.id === CustomDimension.TargetingPreset;

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [targetingPresetModalVisible, setTargetingPresetModalVisible] = useState(false);

  const modalProps: ModalProps = {
    title: 'Delete Targeting Rule',
    okText: 'Yes, delete rule',
    cancelText: 'No, do not delete',
  };

  const handleToggleInclude = (): void => {
    if (changeToggleByDimension) {
      const subGroupType: string = isLineItemTargetingTermValue ? 'Line Item Targeting' : 'Ad Targeting';
      changeToggleByDimension(!value, targetingTerm, subGroupType);
    } else {
      replaceAt(row.index, { ...targetingTerm, include: !value });
    }
  };

  const handleRemoveTargetingRule = (): void => {
    removeAt(row.index);
    setDeleteModalVisible(false);
  };

  const { filters } = useFiltersContext();

  const selectedTab = filters.selectedTab;

  const isShowDeleteButton =
    selectedTab === TraffickingTableName.lineItems
      ? isEditableTargetingTermValueInLineItemTab
      : !isLineItemTargetingTermValue;

  const isDisabled =
    selectedTab === TraffickingTableName.lineItems
      ? !isEditableTargetingTermValueInLineItemTab
      : isLineItemTargetingTermValue;

  editMode = disableByDimension(row.original.dimension.id, editMode);

  return (
    <div className={block()}>
      <div className={element('action-icons')}>
        {isTargetingPreset && (
          <>
            <button onClick={(): void => setTargetingPresetModalVisible(true)} className={element('button')}>
              <IconLayers
                className={element('modal-trigger-icon')}
                aria-label="show preset details"
                onClick={(): void => setTargetingPresetModalVisible(true)}
              />
            </button>
            <TargetingPresetModal
              visible={targetingPresetModalVisible}
              onClose={(): void => setTargetingPresetModalVisible(false)}
              targetingPresetId={targetingTerm?.value?.id}
            />
          </>
        )}
      </div>

      <ToggleSwitch
        isOn={value}
        onChange={handleToggleInclude}
        disabled={!editMode || isDisabled}
        checkedIcon={<IconCheck />}
        uncheckedIcon={<IconSlash />}
      />
      {editMode && isShowDeleteButton && (
        <button onClick={(): void => setDeleteModalVisible(true)} className={element('button')}>
          <IconDelete aria-label="delete targeting rule" />
        </button>
      )}
      <ModalPopup
        visible={deleteModalVisible}
        onOk={handleRemoveTargetingRule}
        modalProps={modalProps}
        modalMessage="Are you sure you want to remove this targeting rule?"
        onCancel={(): void => setDeleteModalVisible(false)}
      />
    </div>
  );
}

export default TargetingTableActionCell;
