import './SequencedHeader.scss';

import { IconDelete } from '@hulu-react-style-components/icons';
import { bem } from 'mission-control-common-components';

import TextInputWithActions from '../../../common/TextInputWithActions';
import { useSequenceForm } from '../hooks';

const [block, element] = bem('sequenced-header');

type SequencedTableHeaderProps = {
  name: string;
  sequenceIndex: number;
};

const SequencedHeader = ({ name, sequenceIndex }: SequencedTableHeaderProps): React.JSX.Element => {
  const { deleteSequence, updateSequenceName } = useSequenceForm();

  const handleUpdateSequenceName = (newValue: string): string | null => {
    return updateSequenceName(newValue, sequenceIndex);
  };

  return (
    <div className={block()}>
      <div className={element('name-cell')}>
        <TextInputWithActions initialValue={name} onSubmit={handleUpdateSequenceName} />
      </div>
      <div className={element('delete-sequence-cell')}>
        <IconDelete data-testid="icon-delete" onClick={(): void => deleteSequence(sequenceIndex)} />
      </div>
    </div>
  );
};

export default SequencedHeader;
