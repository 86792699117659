import './CellItem.scss';

import React from 'react';

import type { SimpleChangeValue } from '../../../../../apis/graphql';
import bem from '../../../../../utils/bem';
import {
  formatArray,
  formatCellDate,
  formatCurrency,
  formatDefaultChangeLogCell,
  formatMicroCurrencyToDollarAmount,
  formatMilitaryTime,
  formatWeightToPercentage,
} from '../../../../../utils/formatting';
import { GreenPlus } from '../../../../assets';
import { formattingArrayOrPrimitiveValueToString } from '../../utils/changeLogTableHelpers';

export interface Action {
  addition: boolean;
  removal: boolean;
}

export interface CellItemProps {
  value: SimpleChangeValue;
  fieldNameAsKey: string;
  action: Action;
}

const [block, element] = bem('change-log-cell');

const fieldNameCellItem: Record<string, (payload: string | number | string[] | number[]) => string> = {
  budget: formatCurrency,
  daypart: (payload): string => (payload ? formatMilitaryTime(payload as number) : '-'),
  'unit-cost': formatMicroCurrencyToDollarAmount,
  'viewability-vendor-list': (payload) => formattingArrayOrPrimitiveValueToString(payload),
  'actual-start': (payload) => (payload ? formatCellDate(payload) : '-'),
  'line-item-list': (payload) => formatArray(payload as string[] | number[]),
  'ad-rotation.weight': (payload): string => (payload !== null ? formatWeightToPercentage(payload as number) : '-'),
};

function CellItem({ fieldNameAsKey, value, action }: CellItemProps): JSX.Element {
  if (!value) return <span>-</span>;

  const valueToRender = fieldNameCellItem[fieldNameAsKey]
    ? fieldNameCellItem[fieldNameAsKey](value.payload)
    : formatDefaultChangeLogCell(value.payload);

  return (
    <span className={block(action.removal ? 'removal' : '')}>
      {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
      {valueToRender}
    </span>
  );
}

export default CellItem;
