import './MSTRReport.scss';

import React from 'react';

import { ReportEntityType, ReportType } from '../types';

type MSTRReportProps = {
  reportType: ReportType;
  entityType: ReportEntityType;
  entityId: string;
  mstrToken: string | null;
};

const BASE_MSTR_URL =
  'https://mstr.prod.hulu.com/MicroStrategyNoSAML/servlet/mstrWeb?evt=2048001&src=mstrWeb.2048001&currentViewMedia=1&visMode=0&Server=LAS01MSTRINT01&Project=Hulu%20Ad%20Sales%20Data%20%28Goldmine%29&Port=0&share=1&promptAnswerMode=2';

// Each report type has a unique documentID param, and differentiates between campaignId and flightId with a %5e prefix (encoded from ^)
const getMstrParamsFromProps = ({
  reportType,
  entityType,
  entityId,
}: MSTRReportProps): { documentID: string; valuePromptAnswers: string } => {
  switch (reportType) {
    case ReportType.DELIVERY:
      return {
        documentID: '760AFA4A498DE01DF93B9BBB72DFDB4E',
        valuePromptAnswers: entityType === ReportEntityType.CAMPAIGN ? entityId : `^${entityId}`,
      };
    case ReportType.END_OF_FLIGHT:
      return {
        documentID: '83DD5CD242176D404B73EB92A0172442',
        valuePromptAnswers: entityType === ReportEntityType.CAMPAIGN ? `^${entityId}` : entityId,
      };
    case ReportType.END_OF_CAMPAIGN:
      return {
        documentID: '78BF94E94BEC22317DE92E8C7779AA8B',
        valuePromptAnswers: entityId,
      };
  }
};

const MSTRReport = ({ reportType, entityType, entityId, mstrToken }: MSTRReportProps): React.ReactElement => {
  const { documentID, valuePromptAnswers } = getMstrParamsFromProps({ reportType, entityType, entityId, mstrToken });
  const iframeUrl = new URL(BASE_MSTR_URL);
  const searchParams = iframeUrl.searchParams;

  searchParams.set('documentID', documentID);
  searchParams.set('valuePromptAnswers', valuePromptAnswers);
  if (mstrToken !== null) {
    searchParams.set('usrSmgr', mstrToken);
  }

  return (
    <div className="mstr-report page">
      <iframe frameBorder={0} src={iframeUrl.toString()} title="MicroStrategy Report" />
    </div>
  );
};

export default MSTRReport;
