import { Prompt } from '@hulu/react-router-dom';
import React from 'react';

import { useWindowPrompt } from './useWindowPrompt';

type WindowPromptProps = {
  promptCondition: boolean;
  message: string;
};

const WindowPrompt = ({ promptCondition, message }: WindowPromptProps): JSX.Element => {
  // Prompt if users attempt to close the window or navigate to another URL
  useWindowPrompt(promptCondition);

  // react-router-dom prompt if users attempt to navigate away using actions captured by React Router (links, back button, redirects, etc)
  return <Prompt when={promptCondition} message={message} />;
};

export default WindowPrompt;
