import styled from 'styled-components';

import { theme } from '../../../styles/styled';

export type StatusPillStatus = 'SUCCESS' | 'WARN' | 'ERROR' | 'IN_PROGRESS';

type ColorStyle = { color: string; backgroundColor: string; borderColor: string };

export const colorStyleMap: Record<StatusPillStatus, ColorStyle> = {
  SUCCESS: {
    color: theme.color.custom.greenDark,
    backgroundColor: theme.color.design.green6,
    borderColor: theme.color.custom.greenDark,
  },
  IN_PROGRESS: {
    color: theme.color.design.gray1,
    backgroundColor: theme.color.design.gray4,
    borderColor: theme.color.design.gray1,
  },
  WARN: {
    color: theme.color.design.yellowBase,
    backgroundColor: theme.color.design.yellow6,
    borderColor: theme.color.design.yellowBase,
  },
  ERROR: {
    color: theme.color.custom.redDark,
    backgroundColor: theme.color.design.red6,
    borderColor: theme.color.custom.redDark,
  },
};

export const StatusPill = styled.div<{ status: StatusPillStatus }>`
  display: inline-block;
  padding: 0 1em;

  font-size: 0.7em;
  font-weight: ${theme.constant.pageTitle.fontWeight};
  line-height: 1.7em;

  border: 1px solid;
  border-radius: 1em;
  opacity: 0.8;
  z-index: 1;

  ${(props): ColorStyle => colorStyleMap[props.status]}
`;
