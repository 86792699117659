import { useLocation } from '@hulu/react-router-dom';
import React from 'react';

import { isNumeric } from '../../../utils/string';
import NotFoundPage from '../../NotFound';
import ReportsPageContainer from '../ReportsPageContainer';
import { ReportEntityType, ReportType } from '../types';

const determineReportType = (reportTypeParam: string | null): ReportType | null => {
  if (reportTypeParam === null) {
    return null;
  }

  switch (reportTypeParam.toLowerCase()) {
    case 'delivery':
      return ReportType.DELIVERY;
    case 'endofflight':
      return ReportType.END_OF_FLIGHT;
    case 'endofcampaign':
      return ReportType.END_OF_CAMPAIGN;
    default:
      return null;
  }
};

const ReportsPageController = (): React.ReactElement => {
  const queryParams = new URLSearchParams(useLocation().search);

  const reportTypeParam = queryParams.get('reportType');
  const campaignIdParam = queryParams.get('campaignId');
  const flightIdParam = queryParams.get('flightId');

  const reportType = determineReportType(reportTypeParam);
  if (reportType === null) {
    return <NotFoundPage />;
  }

  // All report types can be rendered with a Campaign ID
  if (campaignIdParam !== null && isNumeric(campaignIdParam)) {
    return (
      <ReportsPageContainer reportType={reportType} entityType={ReportEntityType.CAMPAIGN} entityId={campaignIdParam} />
    );
  }

  // DELIVERY and END_OF_FLIGHT reports can be rendered with a Flight ID
  if (
    flightIdParam !== null &&
    isNumeric(flightIdParam) &&
    (reportType === ReportType.DELIVERY || reportType === ReportType.END_OF_FLIGHT)
  ) {
    return (
      <ReportsPageContainer reportType={reportType} entityType={ReportEntityType.FLIGHT} entityId={flightIdParam} />
    );
  }

  return <NotFoundPage />;
};

export default ReportsPageController;
