export enum AdFormFields {
  Name = 'name',
  Type = 'type',
  Status = 'status',
  AdTagList = 'adTagList',
  Rating = 'rating',
  DeliveryStatus = 'deliveryStatus',
  TargetingTemValue = 'targetingTermValues',
  Creative = 'creative',
  Schedule = 'schedule',
  FrequencyCupList = 'frequencyCapList',
  ViewabilityVendorList = 'viewabilityVendorList',
  IASCampaignLineItemId = 'iasCampaignLineItemId',
}

export enum AdFormScheduleFields {
  Timezone = 'schedule.timezone',
  DateRange = 'schedule.dateRange',
  DayPart = 'schedule.dayPart',
  DeliveryInViewerTime = 'schedule.deliveryInViewerTime',
}

export enum AdFormCreativeFields {
  Name = 'creative.name',
  Type = 'creative.type',
  Duration = 'creative.duration',
  CreativeLibraryId = 'creative.creativeLibraryId',
  Logo = 'creative.logo',
  OfferImage = 'creative.offerImage',
  Poster = 'creative.poster',
  Review = 'creative.review',
  Slate = 'creative.slate',
  Video = 'creative.video',
  Image = 'creative.image',
  AdSelectorOptionList = 'creative.adSelectorOptionList',
  VideoAssetList = 'creative.videoAssetList',
  FeatureList = 'creative.featureList',
  Template = 'creative.template',
  Url = 'creative.url',
  Vpaid = 'creative.vpaid',
  AdSourceId = 'creative.adSourceId',
  Filesize = 'creative.filesize',
  ImportMethod = 'creative.importMethod',
  IsSubmitted = 'creative.isSubmitted',
  AltText = 'creative.altText',
  Accent = 'creative.accent',
  LivingRoomCopy = 'creative.livingRoomCopy',
  DestinationUrl = 'creative.destinationUrl',
  PushTitle = 'creative.pushTitle',
  PushBody = 'creative.pushBody',
  EmailSubject = 'creative.emailSubject',
  EmailBody = 'creative.emailBody',
  Headline = 'creative.headline',
  RandomOrder = 'creative.randomOrder',
  VoiceoverUrl = 'creative.voiceoverUrl',
  OptionList = 'creative.optionList',
}
