import { gql } from '@apollo/client';

import { LINE_ITEM_TRAFFICKING_COLUMNS } from '../fragments';

export const UPDATE_TRAFFICKING_PAGE_LINE_ITEM = gql`
  mutation UpdateLineItem($id: ID!, $updateLineItemInput: UpdateLineItemInputV5!) {
    updateLineItem: updateLineItemV5(id: $id, input: $updateLineItemInput) {
      ...lineItemTraffickingColumns
    }
  }
  ${LINE_ITEM_TRAFFICKING_COLUMNS}
`;
