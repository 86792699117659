import type { MagniteCreativeInputV5 } from '../../../../apis/graphql';
import type { CreativesBuilderValues } from '../types';

export const constructMagniteCreativeInput = ({
  name,
  url,
  vpaid,
  adSourceId,
  type,
  creativeLibraryId,
}: CreativesBuilderValues): MagniteCreativeInputV5 => {
  const input: MagniteCreativeInputV5 = {
    name,
    url: url || '',
    vpaid: !!vpaid,
    adSourceId: adSourceId || '',
    type: type || 'MAGNITE',
  };

  if (creativeLibraryId) input.creativeLibraryId = creativeLibraryId;

  return input;
};
