import { gql } from '@apollo/client';

import { TARGETING_RULE_DEFINITION, TARGETING_TERM_VALUES } from '../../../../../apis/graphql';

export const GET_DRAWER_TARGETING_LINE_ITEM = gql`
  query getDrawerTargetingLineItem($ids: [ID!]!) {
    lineItemsV5(filterOptions: { idList: $ids }) {
      edges {
        node {
          id
          targetingTermValues {
            ...targetingTermValues
          }
          displayPublisherTarget
          targetingRule {
            ...targetingRuleDefinition
          }
        }
      }
    }
  }
  ${TARGETING_TERM_VALUES}
  ${TARGETING_RULE_DEFINITION}
`;
