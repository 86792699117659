export function bytesToLabel(bytes?: number | null): string {
  const unitLabels = ['bytes', 'KB', 'MB', 'GB', 'TB'];

  let reducedBytes = bytes ?? 0;
  let unit = 0;

  while (reducedBytes > 1024) {
    reducedBytes = reducedBytes / 1024;
    unit++;
  }

  return `${reducedBytes.toFixed(unit >= 3 ? 2 : 0)} ${unitLabels[unit]}`;
}
