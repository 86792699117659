import type { CreativeMetadata } from '../../../../../apis/graphql';
import { campaignClient } from '../../../../../apis/graphql';
import { GET_CREATIVE_METADATA } from '../../queries/getCreativeMetadata';

export async function fetchCreativeMetadata(
  creativeId?: string,
  disableCache = false
): Promise<CreativeMetadata | null> {
  if (!creativeId) return null;

  const { data } = await campaignClient.query({
    query: GET_CREATIVE_METADATA,
    variables: { filterOptions: { creativeIdList: [creativeId] } },
    fetchPolicy: disableCache ? undefined : 'no-cache',
  });

  return data.creativeMetadata?.edges[0]?.node ?? null;
}
