import type { MutationResult } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';

import type { EditableInputVariant } from '../../../../common/EditableTableCells/EditableCell/EditableCell';
import { getVariantFromMutationResult } from './utils';

const useCellVariant = (mutationResult: MutationResult): EditableInputVariant => {
  const variantFromMutationResult = useMemo(() => getVariantFromMutationResult(mutationResult), [mutationResult]);

  const [cellVariant, setCellVariant] = useState<EditableInputVariant>(variantFromMutationResult);

  useEffect(() => {
    setCellVariant(variantFromMutationResult);
    if (variantFromMutationResult === 'saveSuccessful') {
      setTimeout(() => setCellVariant('base'), 600);
    }
  }, [variantFromMutationResult]);

  return cellVariant;
};

export default useCellVariant;
