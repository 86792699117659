import { gql } from '@apollo/client';

// Data for Targeting Dropdowns
export const GET_TARGETING_CATEGORIES = gql`
  query getTargetingCategories($includeTargetingPresets: Boolean) {
    getTargetingCategories(includePreset: $includeTargetingPresets) {
      id
      name
      displayName
      primarySource {
        id
        name
        source
        system
      }
      additionalSource {
        id
        name
        source
        system
      }
    }
  }
`;
