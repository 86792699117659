import './AdListItem.scss';

import { IconAlertWarningYellow, IconDelete } from '@hulu-react-style-components/icons';
import type { SyntheticEvent } from 'react';
import React from 'react';

import type { AdsPageAd } from '../../../../common/AdForm/hooks/types';
import DetailsTable from '../../../../common/DetailsTable';
import type { PillProps } from '../../../../common/Pill';
import { PillColor } from '../../../../common/Pill';
import Pill from '../../../../common/Pill';
import bem from '../../../../utils/bem';
import { formatTimeDetail, stringifyTagList } from '../../../../utils/formatting';
import { useAssetIdsForTranscodeStatus } from './hooks/useAssetIdsForTranscodeStatus';

const unsavedChangesMessage = (
  <span className="unsaved-changes">
    <IconAlertWarningYellow />
    <span> Unsaved changes!</span>
  </span>
);

const [block, element] = bem('ad-list-item');

export interface AdListItemProps {
  weight: number;
  ad: AdsPageAd;
  isSelected: boolean;
  isSaved: boolean;
  selectedFocusRef?: (el: HTMLLIElement) => HTMLLIElement | null;
  pills: PillProps[];
  handleItemClick?(e: SyntheticEvent): void;
  handleDeleteClick?(e: SyntheticEvent): void;
  handleRemoveAd?(e: React.KeyboardEvent<HTMLSpanElement>): void;
  handleSelectAd?(e: SyntheticEvent): void;
  isAdListWithOneItem?: boolean;
  isSequence: boolean;
}

function AdListItem({
  ad,
  isSelected,
  handleItemClick,
  handleDeleteClick,
  handleRemoveAd,
  handleSelectAd,
  isSaved,
  selectedFocusRef,
  weight,
  pills,
  isAdListWithOneItem,
  isSequence,
}: AdListItemProps): JSX.Element {
  const { loading, spReview, transcodePillColor, transcodeStatus } = useAssetIdsForTranscodeStatus(ad?.creative);

  const createdAt: string | undefined = ad.creative?.createdAt?.toString();
  const creativeCreatedAt = createdAt ? formatTimeDetail(createdAt) : '';

  if (transcodeStatus && !loading && transcodePillColor) {
    pills.push({ text: transcodeStatus, color: transcodePillColor, className: 'transcode-status' });
  }

  if (!!spReview && !loading) {
    pills.push({ text: spReview, color: PillColor.YELLOW, className: 'transcode-status' });
  }
  const assetDisplayName = stringifyTagList(ad.creative?.assetTagList ?? []);
  const detailsTable = (
    <DetailsTable
      details={[
        ['Ad ID', ad.id],
        ['Created', formatTimeDetail(ad.createdAt)],
        ['Creative ID', ad.creative?.id],
        ['Creative Created', creativeCreatedAt],
        [
          'Saved',
          <>
            {ad.updatedAt && formatTimeDetail(ad.updatedAt)} {ad.dirty && unsavedChangesMessage}
          </>,
        ],
        ['Asset Tag', <>{assetDisplayName}</>],
      ]}
    />
  );

  const newAdDetailsTable = (
    <DetailsTable
      details={[
        [
          null,
          <>
            {ad.updatedAt && formatTimeDetail(ad.updatedAt)}{' '}
            {ad.dirty && (
              <span className="unsaved-changes">
                <IconAlertWarningYellow />
                <span> Unsaved changes!</span>
              </span>
            )}
          </>,
        ],
      ]}
    />
  );

  return (
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    <li
      ref={selectedFocusRef}
      tabIndex={0}
      className={block(isSelected ? 'selected' : '')}
      aria-selected={isSelected}
      onClick={handleItemClick}
      onKeyDown={handleSelectAd}
    >
      <span className={element('percentage')} data-testid="sequence-rotation-order">
        {isSequence || !ad.id ? ad?.adSequence?.order : `${weight}%`}
      </span>

      <div className={element('content-container')}>
        <header>
          <h1 className={element('name', ad.name ? '' : 'no-ad')}>{ad.name || 'Untitled Ad'}</h1>
          {isSelected && !isSaved && !isAdListWithOneItem && (
            <div className={element('actions')}>
              <span
                tabIndex={0}
                role="button"
                className={element('action')}
                onClick={handleDeleteClick}
                onKeyDown={handleRemoveAd}
                data-testid="delete-btn"
              >
                <IconDelete aria-label="delete ad" />
              </span>
            </div>
          )}
        </header>
        {isSaved ? detailsTable : newAdDetailsTable}
        <div className={element('pills')} data-testid="status-pills">
          {pills.map(
            (pill: PillProps): JSX.Element => (
              <Pill key={pill.text} {...pill} />
            )
          )}
        </div>
      </div>
    </li>
  );
}

export default AdListItem;
