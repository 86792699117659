import type { Config } from '../../../../configs';
import type { DropdownOption } from '../../../Dropdown';
import type { MultiSelectValue, OptionTypeWithExtras } from '../../../MultiSelectDropdown/MultiSelectDropdown';
import type { DropdownType } from '../MultiSelectDropdown';

export function findSetDefaultValue(
  withDefaultValue: Config<string> | undefined,
  existingSelections: MultiSelectValue<DropdownType<string>>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): DropdownOption<OptionTypeWithExtras<DropdownType<any>>> | null {
  const indexOfDefaultValue: number | null =
    withDefaultValue && existingSelections.length && !!existingSelections[0].value
      ? existingSelections.map((selection) => selection.value.toString()).indexOf(withDefaultValue.toString())
      : null;
  return indexOfDefaultValue ? existingSelections[indexOfDefaultValue] : null;
}
