import type { Row } from 'react-table';

import type { AdProduct, AdType, DisplayEnum } from '../../../../../../configs';
import { getPermittedAdTypes } from '../../../../../../configs';
import type { DuplicateLineItem } from '../../hooks/useDuplicateLineItemsState';
import { determineAdNameForLineItem, getDefaultOrChangedDuplicateAdName } from './../../utils';

export const transformSelectedRowsToFormValue = (
  rows: Row<DuplicateLineItem>[],
  adName: string,
  lineItems: DuplicateLineItem[] = []
): DuplicateLineItem[] => {
  let initialIndex = 0;

  return rows.map((selectedFlatRow) => {
    const transformedAdName = getDefaultOrChangedDuplicateAdName({
      id: selectedFlatRow.original.id,
      adName,
      lineItems,
      index: initialIndex,
    });

    initialIndex = transformedAdName.index;

    return {
      ...selectedFlatRow.original,
      adName: transformedAdName.adName,
      editedAdName: transformedAdName.editedAdName,
    };
  });
};

export const transformDuplicateLineItemsToFormValue = (
  duplicateLineItems: DuplicateLineItem[],
  adName: string
): DuplicateLineItem[] => {
  let initialIndex = 0;

  return duplicateLineItems?.map((item) => {
    const transformedAdName = determineAdNameForLineItem({ lineItem: item, adName, index: initialIndex });

    initialIndex = transformedAdName.index;

    return {
      ...item,
      adName: transformedAdName.adName,
      editedAdName: transformedAdName.editedAdName,
    };
  });
};

type SelectedRows = { [key: string]: boolean };

const setSelectedRow = (
  selectedRows: SelectedRows,
  lineItemId: string,
  lineItemType: DisplayEnum<AdProduct>,
  adType: DisplayEnum<AdType>
): SelectedRows => {
  selectedRows[lineItemId] = getPermittedAdTypes(lineItemType?.key as AdProduct)?.some(
    (lineItemAdType) => lineItemAdType.key === adType.key
  );
  return selectedRows;
};

export const getInitiallySelectedIds = (
  adType: DisplayEnum<AdType>,
  duplicateLineItemsFormValue?: DuplicateLineItem[],
  selectedLineItems?: DuplicateLineItem[],
  adLineItemId?: string
): Record<string, boolean> =>
  duplicateLineItemsFormValue
    ? duplicateLineItemsFormValue.reduce(
        (selectedRows: SelectedRows, currentLineItem) =>
          setSelectedRow(selectedRows, currentLineItem.id, currentLineItem.adProduct, adType),
        {}
      )
    : (selectedLineItems || []).reduce((selectedRows: SelectedRows, currentLineItem) => {
        if (currentLineItem.id === adLineItemId) return selectedRows;

        return setSelectedRow(selectedRows, currentLineItem.id, currentLineItem.adProduct, adType);
      }, {});

export const isDifferentSelectionFromSaved = (selectedRowIds: string[], selectedSavedRowIds: string[]): boolean => {
  if (selectedRowIds.length !== selectedSavedRowIds.length) return true;

  const anyNewSelected = selectedRowIds?.some((selectedRowId) => !selectedSavedRowIds?.includes(selectedRowId));
  const anyNewDeSelected = selectedSavedRowIds?.some((selectedRowId) => !selectedRowIds?.includes(selectedRowId));

  return anyNewSelected || anyNewDeSelected;
};
