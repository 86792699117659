import './SaveInput.scss';

import { IconCheck } from '@hulu-react-style-components/icons';
import type { ChangeEvent } from 'react';
import React, { memo } from 'react';

import bem from '../../utils/bem';

const [block, element] = bem('save-input');

type SaveInputProps = {
  value: string;
  placeholder: string;
  disabled?: boolean;
  onSave: () => void;
  onChange: (value: string) => void;
};

function SaveInput({ value, placeholder, disabled = false, onSave, onChange }: SaveInputProps): JSX.Element {
  const buttonDisabled = disabled || !value;

  const buttonModifiers = buttonDisabled ? ['disabled'] : [];

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const searchTerm = e.target?.value;
    onChange(searchTerm);
  };

  return (
    <div className={block()} data-testid="save-input-wrapper">
      <input
        data-testid="save-input-field"
        value={value}
        placeholder={placeholder}
        className={element('input')}
        onChange={onChangeHandler}
      />
      <button
        data-testid="save-input-button"
        className={element('input-button', buttonModifiers)}
        disabled={buttonDisabled}
        onClick={onSave}
      >
        <span>
          <IconCheck />
        </span>
      </button>
    </div>
  );
}

export default memo(SaveInput);
