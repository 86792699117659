import React from 'react';

import type { MultipleEditDrawerLineItemsFormValues } from '../../../../../types';
import type { Row, TargetingResult } from '../../types';
import type { BufferConflictIdsResult } from '../checkConflicts/checkBufferConflicts/checkBufferConflicts';
import type { TargetingConflictResult } from '../checkConflicts/useCheckTargetingConflicts/useCheckTargetingConflicts';
import { getConflictComponents } from './conflicts';
import {
  BUFFER_CONFLICT_MESSAGE,
  FRONTLOAD_CONFLICT_MESSAGE,
  PRIORITY_CONFLICT_MESSAGE,
  TARGETING_ALREADY_EXIST_MESSAGE,
  TARGETING_CONFLICT_MESSAGE,
  TARGETING_TYPE_EXCLUDE_CONFLICT_MESSAGE,
  TARGETING_TYPE_INCLUDE_CONFLICT_MESSAGE,
} from './constants';
import { BufferLabel, BufferRow, FrequencyRow, PriorityRow, TargetingRow } from './rows';

export type GetLineItemsRowsParams = {
  values: MultipleEditDrawerLineItemsFormValues;
  targeting: TargetingResult;
  conflicts: {
    targeting?: TargetingConflictResult;
    buffer?: BufferConflictIdsResult | null;
    priority?: string[] | null;
  };
  isTargetingErrorsLoading?: boolean;
};

export const getLineItemsRows = ({
  values,
  targeting,
  conflicts,
  isTargetingErrorsLoading,
}: GetLineItemsRowsParams): Row[] => {
  const {
    selectedSections,
    buffer,
    priority: { priority, priorityValue, tier },
    frequencyCapList,
  } = values;

  const isShowTargetingValues = !!selectedSections.includes('Targeting');
  const isShowBufferValues = !!selectedSections.includes('Buffer & Frontload');
  const isShowPriorityValues = !!selectedSections.includes('Priority');
  const isShowFrequencyValues = !!selectedSections.includes('Frequency');

  const { targeting: targetingConflict, buffer: bufferConflict, priority: priorityConflict } = conflicts;

  const isShowTargetingErrors = !!targetingConflict && isShowTargetingValues;

  return [
    {
      label: 'Type',
      value: 'Value',
    },
    {
      label: 'Targeting',
      value: <TargetingRow targeting={targeting} isShowTargetingValues={isShowTargetingValues} />,
      isLoading: isTargetingErrorsLoading,
      conflicts: getConflictComponents([
        {
          conflictIds: targetingConflict?.hasTargetConflict,
          message: TARGETING_CONFLICT_MESSAGE,
          isShown: isShowTargetingErrors,
        },
        {
          message: TARGETING_ALREADY_EXIST_MESSAGE,
          conflictIds: targetingConflict?.hasAlreadyThisTarget,
          isShown: isShowTargetingErrors,
        },
        {
          message: TARGETING_TYPE_EXCLUDE_CONFLICT_MESSAGE,
          conflictIds: targetingConflict?.hasExcludeConflict,
          isShown: isShowTargetingErrors,
        },
        {
          message: TARGETING_TYPE_INCLUDE_CONFLICT_MESSAGE,
          conflictIds: targetingConflict?.hasIncludeConflict,
          isShown: isShowTargetingErrors,
        },
      ]),
    },
    {
      label: <BufferLabel />,
      value: (
        <BufferRow
          deliveryBufferPercent={buffer.deliveryBufferPercent}
          frontLoadPercent={buffer.frontLoadPercent}
          isShowBufferValues={isShowBufferValues}
        />
      ),
      conflicts: getConflictComponents([
        {
          conflictIds: bufferConflict?.bufferConflictIds,
          message: BUFFER_CONFLICT_MESSAGE,
          isShown: isShowBufferValues,
        },
        {
          conflictIds: bufferConflict?.frontloadConflictIds,
          message: FRONTLOAD_CONFLICT_MESSAGE,
          isShown: isShowBufferValues,
        },
      ]),
    },
    {
      label: 'Priority',
      value: (
        <PriorityRow
          priorityValue={priorityValue}
          priority={priority}
          tier={tier}
          isShowPriorityValues={isShowPriorityValues}
        />
      ),
      conflicts: priorityConflict
        ? getConflictComponents([
            {
              conflictIds: priorityConflict,
              message: PRIORITY_CONFLICT_MESSAGE,
              isShown: isShowPriorityValues,
            },
          ])
        : [],
    },
    {
      label: 'Frequency',
      value: <FrequencyRow isShowFrequencyValues={isShowFrequencyValues} frequencyCapList={frequencyCapList} />,
    },
  ];
};
