import './TableNavigationActionButtons.scss';

import { bem } from 'mission-control-common-components';
import React, { useMemo } from 'react';

import type { ActionButtonConfig } from '../TraffickingPage';
import { generateActionButtons } from './utils';

const [block, element] = bem('action-buttons');

export type TableNavigationActionButtonsProps = {
  config: ActionButtonConfig[];
};

const TableNavigationActionButtons = ({ config }: TableNavigationActionButtonsProps): React.JSX.Element => {
  const actionButtons = useMemo(() => generateActionButtons(config), [config]);

  return (
    <div className={block()} data-testid="action-buttons">
      <div className={element('wrapper')}>{actionButtons}</div>
    </div>
  );
};

export default TableNavigationActionButtons;
