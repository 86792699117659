export const AD_FIELD_ERROR_MESSAGE = 'Ad Field Validation Failed';
export const CREATIVE_ERROR_MESSAGE = 'Creative Validation Failed';
export const AD_SCHEDULE_ERROR_MESSAGE = 'Scheduling Validation Failed';
export const TAG_LIST_ERROR_MESSAGE = 'Tag List Field Validation Failed';
export const TARGETING_LIST_ERROR_MESSAGE = 'Targeting List Validation Failed';
export const VIEWABILITY_VENDORS_ERROR_MESSAGE = 'Viewability Vendors Validation Failed';

export const AD_NAME = 'Ad name is required field';
export const SELECT_CREATIVE = 'Select or create creative';
export const FILL_CREATIVE_FIELDS = 'Fill in all required fields';
export const SUBMIT_TAG = 'Please submit the VAST tag';
export const REQUIRED_COUNTRY_TARGETING = 'At least one country should be selected';

export const NO_LINE_ITEM = 'No associated line item for current entity';

export const CREATIVE_IS_NOT_ALLOWED = "Can't set selected creative";
export const TEMPLATE_IS_NOT_ALLOWED = "template isn't allowed for";

export const IAS_CAMPAIGN_LINE_ITEM_ID_FIELD_EMPTY =
  'IAS Campaign / Line Item Id field cannot be empty, if IAS is selected';
export const CANNOT_SELECT_OTHER_VIEWABILITY_VENDOR_IF_IAS_SELECTED =
  'Cannot select other viewability vendors, if IAS is selected';
