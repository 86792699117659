import './EditableCell.scss';

import { IconAlertWarningSolid, IconCheck, IconEdit } from '@hulu-react-style-components/icons';
import { kebabCase as _kebabCase } from 'lodash';
import React from 'react';

import bem from '../../../utils/bem';
import Loader from '../../assets/Loader.svg';

export type EditableInputVariant = 'base' | 'disabled' | 'saving' | 'saveSuccessful' | 'saveFailed';
// The "active" variant is controlled by the Input rendering the EditableCell
export type EditableCellVariant = EditableInputVariant | 'active';

export interface EditableCellProps {
  children?: React.ReactNode;
  onClick?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  usePencil?: boolean;
  variant: EditableCellVariant;
  tabIndex: number;
  allowSpaceTyping?: boolean;
}

const [block, element] = bem('editable-cell');

const variantToIcon: Record<EditableCellVariant, React.ReactNode> = {
  base: <IconEdit className={element('icon', 'base')} aria-label="can edit" />,
  active: null,
  disabled: null,
  saving: <img aria-label="saving" className={element('icon', 'saving')} src={Loader} alt="saving spinner" />,
  saveSuccessful: <IconCheck aria-label="save successful" className={element('icon', 'save-successful')} />,
  saveFailed: <IconAlertWarningSolid aria-label="save failed" className={element('icon', 'save-failed')} />,
};

const renderIcon = (variant: EditableCellVariant, usePencil?: boolean): React.ReactNode => {
  if (variant === 'base' && !usePencil) {
    return null;
  }

  return variantToIcon[variant];
};

/**
 * A wrapper around an editable component that renders common styles and icons based on the variant
 */
const EditableCell = ({
  onClick,
  onKeyDown,
  variant,
  usePencil,
  allowSpaceTyping,
  children,
  ...props
}: EditableCellProps): JSX.Element => {
  const handleCellClick = (): void => {
    if (onClick) {
      onClick();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' || (e.key === ' ' && !allowSpaceTyping)) handleCellClick();
    if (onKeyDown) {
      onKeyDown(e);
    }
    if (e.key === ' ' && !allowSpaceTyping) {
      e.preventDefault();
    }
  };

  return (
    <div
      className={block(_kebabCase(variant))}
      onClick={handleCellClick}
      onKeyDown={handleKeyDown}
      data-focusable
      data-testid="editable-cell"
      {...props}
    >
      {renderIcon(variant, usePencil)}
      {/* Provide editing component as a child */}
      {children}
    </div>
  );
};

export default EditableCell;
