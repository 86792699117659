import { EntityTypeArray } from '../../../../../TraffickingPage/hooks/makePageDrawerPlugin';
import type { MultipleEditDrawerFormValues } from '../../../types';
import type { MultipleEditDrawerContentFirstStepTableData } from './types';

export const getData = (
  entitiesValues: Pick<MultipleEditDrawerFormValues, 'entities'>['entities']
): MultipleEditDrawerContentFirstStepTableData[] =>
  entitiesValues.map(({ id, name }: { id: string; name: string }) => ({ id, name }));

export const getHeaderText = (entityType: EntityTypeArray): string =>
  `Confirm these are the ${entityType === EntityTypeArray.LINE_ITEM_ARRAY ? 'line items' : 'ads'} you want to edit.`;

export const getTotalText = (entityType: EntityTypeArray): string =>
  `Total ${entityType === EntityTypeArray.LINE_ITEM_ARRAY ? 'Line Items' : 'Ads'} Selected: `;
