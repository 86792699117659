import { useQuery } from '@apollo/client';
import { sortBy as _sortBy } from 'lodash';
import { useState } from 'react';

import type { Get_Salesperson_EmailsQuery as GetSalespersonEmailsQuery } from '../../../../apis/graphql';
import type { DropdownOption } from '../../../../common/Dropdown';
import { fromEmailToName } from '../../../../utils/common';
import { GET_SALESPERSON_EMAILS } from './queries';
import type { UseGetUsersResult } from './types';

const mapUsersToOptions = (accountExecutives: string[]): DropdownOption[] => {
  const options = accountExecutives.map((user) => ({ label: fromEmailToName(user), value: user }));
  return _sortBy(options, ['label']);
};

const filterOptions = (options: DropdownOption[], searchTerm: string): DropdownOption[] =>
  options.filter(
    ({ label, value }) => label.toLowerCase().includes(searchTerm) || value.toLowerCase().includes(searchTerm)
  );

export const useGetAccountExecutives = (): UseGetUsersResult => {
  const { data, loading, error } = useQuery<GetSalespersonEmailsQuery>(GET_SALESPERSON_EMAILS);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const searchUsers = (value: string): void => {
    setSearchTerm(value.toLocaleLowerCase());
  };

  const resetOptions = (): void => {
    setSearchTerm('');
  };

  const options = mapUsersToOptions(data?.getSalespersonEmails || []);
  const filteredOptions = filterOptions(options, searchTerm);

  return {
    loading,
    error: error?.message,
    userDropdownOptions: filteredOptions,
    searchUsers,
    resetOptions,
    searchTerm,
  };
};
