import { Navigate, Outlet, Route } from '@hulu/react-router-dom';
import type { GeneratedPermissions } from 'mission-control-common-components';
import type { ComponentType } from 'react';
import React, { lazy, Suspense } from 'react';

import Loader from '../common/Loader';
import type { PermissionsNames } from '../constants';
import { PathName } from '../constants';

type PluginConfiguration = {
  name: string;
  route: PathName;
  permissionName: PermissionsNames;
  getComponent: () => Promise<{ default: ComponentType }>;
};

const RoutesContainer = (): React.JSX.Element => {
  return (
    <Suspense fallback={<Loader className={'main'} />}>
      <Outlet />
    </Suspense>
  );
};

export const traffickingRouteElement = (
  permission: boolean | undefined,
  Controller: React.ReactElement
): React.ReactElement => (permission ? Controller : <Navigate to={PathName.forbidden} />);

export const combinePlugins = (
  configuration: PluginConfiguration[],
  permissions: GeneratedPermissions<PermissionsNames> | null
): React.JSX.Element => {
  return (
    <Route path="/" element={<RoutesContainer />}>
      {configuration.map(({ route, name, permissionName, getComponent }) => {
        const Component = lazy(getComponent);
        return (
          <Route
            key={name}
            path={`${route}/*`}
            element={traffickingRouteElement(permissions?.[permissionName], <Component />)}
          />
        );
      })}
    </Route>
  );
};
