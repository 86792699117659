import { cloneDeep } from 'lodash';
import React, { useCallback } from 'react';
import type { DraggableLocation, DropResult } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';

import DragDropList from './DragDropList';
import type { DraggedElementsGroup } from './types';
import type { DragDropListCustomToggle } from './types';

export type UpdateHandlerProps = {
  draggedList: DraggedElementsGroup[];
  source?: DraggableLocation;
  destination?: DraggableLocation;
  draggableId?: string;
};

type Props = {
  list: DragDropListCustomToggle[];
  updateListHandler: (props: UpdateHandlerProps) => void;
};

const DragDropLists: React.FC<Props> = ({ list, updateListHandler }) => {
  const onDragEnd = ({ source, destination, draggableId }: DropResult): void => {
    if (!destination) return;

    updateListHandler({
      draggedList: list,
      source,
      destination,
      draggableId,
    });
  };

  const onToggle = useCallback(
    (listId: string, expanded: boolean): void => {
      const updatingLists = cloneDeep(list);

      const updatingList = updatingLists.find((entityList) => entityList.id === listId);

      if (!updatingList) return;

      updatingList.initialExpanded = expanded;

      updateListHandler({ draggedList: updatingLists });
    },
    [list, updateListHandler]
  );

  return (
    <div data-testid="drag-drop-lists">
      <DragDropContext onDragEnd={onDragEnd}>
        {list.map(
          ({ id, element, childElements, initialExpanded = true, endElement, beforeListElement, errorElement }) => (
            <DragDropList
              key={id}
              id={id}
              element={element}
              childElements={childElements}
              onToggleHandler={onToggle}
              initialExpanded={initialExpanded}
              endElement={endElement}
              errorElement={errorElement}
              beforeListElement={beforeListElement}
            />
          )
        )}
      </DragDropContext>
    </div>
  );
};

export default DragDropLists;
