import { bem } from 'mission-control-common-components';
import React from 'react';

import type { AssetExtended } from '../../../../_ForCommonLibrary/types/extended';
import type { IngestionJobStatus } from '../../../../../apis/graphql';
import Tooltip from '../../../../Tooltip';
import { ingestButton } from '../../configs';
import { AssetIngestButton } from '../AssetIngestButton';
import { CreativeFooterStyling } from '../CreativeFooterStyling';
import { IngestPill } from '../IngestPill';
import { SetAssetErrorButton } from '../SetAssetErrorButton';

const element = bem('creative-footer')[1];

export const AssetIngestionFooter: React.FC<{
  assets?: AssetExtended[] | null;
  hasIngestError?: boolean;
  ingestionJobStatus: IngestionJobStatus;
  readonly?: boolean;
}> = ({ assets, hasIngestError, ingestionJobStatus, readonly }) => {
  const hasTranscodeAbort = assets?.find((a) => a.transcodeStatus === 'PUBLISHING_ABORT');
  const { tooltipConfig, tooltipText } = ingestButton;

  return (
    <CreativeFooterStyling>
      <hr />
      <div className={element('content', 'interaction')}>
        <IngestPill ingestStatus={ingestionJobStatus} />
        <Tooltip id="vast-footer-tooltip" className={element('tooltip')} message={tooltipText} {...tooltipConfig}>
          {hasTranscodeAbort ? (
            <SetAssetErrorButton assets={assets} readonly={readonly} />
          ) : (
            <AssetIngestButton displayRetry={hasIngestError} />
          )}
        </Tooltip>
      </div>
    </CreativeFooterStyling>
  );
};
