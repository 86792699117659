import { gql } from '@apollo/client';

import { CREATIVE_ASSET_FRAGMENT } from '../../../hooks/fragments/creativeAssetFragment';

export const GET_CREATIVE_DATA = gql`
  query getCreativeData($creativeId: String!, $assetIdList: [String!]!, $filterOptions: ReviewTaskV5FilterOptions) {
    getAssetLatestIngestionStatus(creativeId: $creativeId) {
      jobStatus
      assetStatus {
        assetId
        trialStatus
        detail
        s3Url
        updatedAt
      }
    }
    getReviewTasksV5(filterOptions: $filterOptions) {
      total
      edges {
        node {
          assetId
          spReview
        }
      }
    }
    getAssetsByIDsV5(assetIdList: $assetIdList) {
      ...creativeAsset
    }
  }
  ${CREATIVE_ASSET_FRAGMENT}
`;
