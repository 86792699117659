import './Header.scss';

import NavBar from '@hulu-react-style-components/nav-bar';
import envConfig from '@hulu/env-config';
import { NavLink, useLocation, useNavigate } from '@hulu/react-router-dom';
import type { GeneratedPermissions } from 'mission-control-common-components';
import { User } from 'mission-control-common-components';
import React, { useCallback, useMemo, useState } from 'react';

import type { PermissionsNames } from '../../constants';
import { PathName } from '../../constants';
import bem from '../../utils/bem';
// TODO(AX-1460): add this back when we're ready for global Elasticsearch
//import SearchBar from '../SearchBar';
import { AuthUserNav } from '../AuthUserNav';
import BasicSelect from '../BasicSelect';
import NavigationSelectOptions from '../NavigationSelectOptions';
import { forbidWhenNoPermission } from './utils';

export interface HeaderProps {
  isAuthEnabled?: boolean;
  permissions: GeneratedPermissions<PermissionsNames> | null;
}

const [block, element] = bem('header');
const [userModalBlock, userModalElement] = bem('custom_user_modal');

export type NavigationItem = {
  id: string;
  isDisabled?: boolean;
  label: string;
  isSelected?: boolean;
  pathName: string;
  hasPermission: boolean;
};

export type NavigationItemsGroup = {
  isActive?: boolean;
  isDisabled?: boolean;
  label: string;
  items: NavigationItem[];
};

const Header = ({ isAuthEnabled, permissions }: HeaderProps): React.ReactElement => {
  const [widthOfModal, setWidthOfModal] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const logout = (): void => {
    if (isAuthEnabled) {
      User.logout();
      return;
    }

    navigate('/logout');
  };

  const navItemsGroups = useMemo<NavigationItemsGroup[]>(() => {
    const groups: NavigationItemsGroup[] = [
      {
        label: 'Campaign Setup & Review',
        isDisabled: false,
        items: [
          {
            id: 'Trafficking',
            label: 'Trafficking',
            pathName: PathName.trafficking,
            hasPermission: !!permissions?.traffickingPageRead,
          },
          {
            id: 'Campaign-Review',
            label: 'Campaign Review',
            pathName: PathName.campaignReview,
            hasPermission: !!permissions?.campaignReviewPageRead,
          },
          {
            id: 'Asset-Review',
            label: 'Asset Review',
            pathName: PathName.assetReview,
            hasPermission: !!permissions?.assetReviewPageRead,
          },
        ],
      },
      {
        label: 'Inventory',
        isDisabled: false,
        items: [
          {
            id: 'Ad-Load-Manager',
            label: 'Ad Load Manager',
            pathName: PathName.adLoadManager,
            hasPermission: !!permissions?.inventoryPageRead,
          },
          {
            id: 'Inventory-Restriction-Manager',
            label: 'Inventory Restriction Manager',
            pathName: PathName.inventoryRestrictionManager,
            hasPermission: !!permissions?.inventoryPageRead,
          },
          ...(envConfig.REACT_APP_FEATURE_ENABLE_TARGETING_PRESETS === 'true'
            ? [
                {
                  id: 'Targeting-Presets',
                  label: 'Targeting Presets',
                  pathName: PathName.targetingPreset,
                  hasPermission: !!permissions?.presetPageRead,
                },
              ]
            : []),
          ...(envConfig.REACT_APP_FEATURE_ENABLE_INVENTORY_BLOCK === 'true'
            ? [
                {
                  id: 'Inventory-Block',
                  label: 'Inventory Block',
                  pathName: PathName.inventoryBlock,
                  hasPermission: !!permissions?.readInventoryBlock,
                },
              ]
            : []),
        ],
      },
      {
        label: 'Programmatic',
        isDisabled:
          envConfig.REACT_APP_ENABLE_DEMAND_MANAGEMENT_TAB !== 'true' &&
          envConfig.REACT_APP_ENABLE_SUPPLY_MANAGEMENT_TAB !== 'true',
        items: [
          {
            id: 'Demand-Management',
            label: 'Demand Management',
            isDisabled: envConfig.REACT_APP_ENABLE_DEMAND_MANAGEMENT_TAB !== 'true',
            pathName: PathName.demandManagement,
            hasPermission: !!permissions?.demandManagementPageRead,
          },
          {
            id: 'Supply-Management',
            label: 'Supply Management',
            pathName: PathName.supplyManagement,
            isDisabled: envConfig.REACT_APP_ENABLE_SUPPLY_MANAGEMENT_TAB !== 'true',
            hasPermission: !!permissions?.supplyManagementPageRead,
          },
        ],
      },
      {
        label: 'Finance & Pricing',
        items: [
          {
            id: 'Pricing',
            label: 'Pricing',
            pathName: PathName.pricing,
            hasPermission: !!permissions?.pricingPageRead,
          },
        ],
      },
    ];

    return groups
      .filter((group) => !group.isDisabled)
      .map((group) => ({
        ...group,
        isActive: group.items.some((item) => location.pathname.includes(item.pathName)),
        items: group.items.map((item) => ({
          ...item,
          isSelected: location.pathname.includes(item.pathName),
        })),
      }));
  }, [location.pathname, permissions]);

  const getNavigationOptionsComponent = useCallback(
    (items: NavigationItem[]) => (toggleOpen: () => void): JSX.Element => {
      return <NavigationSelectOptions toggleOpen={toggleOpen} options={items} />;
    },
    []
  );

  const menuLinks: JSX.Element = (
    <div className={element('menu')}>
      {navItemsGroups.map(({ isActive, label, items }) => {
        return (
          <div key={label} className={element('menu-item')}>
            <BasicSelect
              isActive={isActive}
              optionsComponent={getNavigationOptionsComponent(items)}
              overlayClassName={`${block()} ${element('navigation-tooltip')}`}
              buttonText={label}
            />
          </div>
        );
      })}
    </div>
  );

  const accessManagerComponent = useMemo(
    () => (
      <NavLink
        className={userModalElement('link')}
        data-testid="access-manager"
        to={PathName.accessManager}
        onClick={forbidWhenNoPermission(permissions?.accessManagerPageRead)}
      >
        Access Manager
      </NavLink>
    ),
    // Memoized as forbidWhenNoPermission can throw forbidden when component is inserted dynamically
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <NavBar
        className={block()}
        appName="Mission Control"
        // TODO
        // leftChildren: app sidenav bar
        // rightChildren[0]: search, notifications, and grid-menu
        leftChildren={null}
        rightChildren={[
          menuLinks,
          // TODO(AX-1460): add this back when we're ready for global Elasticsearch and remove "null"
          null,
          // // <SearchBar />,
          <AuthUserNav
            isAuthEnabled={isAuthEnabled}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            setWidthOfModal={setWidthOfModal}
          />,
        ]}
        logo={null}
      />
      {isOpen && (
        <div className={userModalBlock()} style={{ width: widthOfModal ?? 0 }} onClick={(): void => setIsOpen(false)}>
          {accessManagerComponent}
          <div onClick={logout} className={userModalElement('logout')}>
            Logout
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
