import type { ApolloError } from '@apollo/client';
import { useLazyQuery, useQuery } from '@apollo/client';
import envConfig from '@hulu/env-config';
import { useEffect, useMemo } from 'react';

import type {
  GetTargetingCategoriesQuery,
  GetTargetingDimensionsByCategoryQuery,
  TargetingSource,
} from '../../../apis/graphql';
import type { DropdownOption } from '../../../common/Dropdown';
import type { Nullable } from '../../../models';
import { GET_TARGETING_CATEGORIES, GET_TARGETING_DIMENSIONS_BY_CATEGORY } from '../../../pages/Ads/hooks/queries';
import type { AdFormValues, TargetingValueOption } from '../../AdForm/adFormik';
import { useFieldFast } from '../../Form/hooks';
import { formatForDropdown } from './utils';

interface useTargetingDropdownResult {
  categoryOptions: DropdownOption[];
  selectedCategory: DropdownOption | null;
  setSelectedCategory: (option: TargetingDropdownOption) => void;
  isLoadingCategories: boolean;
  errorGettingCategories?: ApolloError;
  dimensionsOptions: DropdownOption[] | null;
  selectedDimension: DropdownOption | null;
  setSelectedDimension: (option: TargetingDropdownOption | null) => void;
  isLoadingDimensionsByCategory: boolean;
  errorGettingDimensionsByCategory?: ApolloError;
  selectedValues: TargetingValueOption[];
  setSelectedValues: (options: TargetingValueOption[]) => void;

  clearAll(e?: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

export interface Category {
  id: string;
  name: string;
  displayName: string;
  description?: Nullable<string>;
  primarySource: TargetingSource;
  additionalSource?: TargetingSource[];
}

export interface TargetingDropdownOption extends DropdownOption {
  id: string;
}

export default function useTargetingDropdowns(): useTargetingDropdownResult {
  const [, { value: newTargeting }, { setValue: setNewTargeting }] = useFieldFast<AdFormValues['newTargeting']>(
    'newTargeting'
  );

  const { loading: isLoadingCategories, error: errorGettingCategories, data } = useQuery<GetTargetingCategoriesQuery>(
    GET_TARGETING_CATEGORIES,
    {
      variables: {
        includeTargetingPresets: envConfig.REACT_APP_FEATURE_ENABLE_TARGETING_PRESETS === 'true',
      },
      fetchPolicy: 'no-cache',
    }
  );

  const [
    getTargetingDimensionsByCategory,
    { loading: isLoadingDimensionsByCategory, error: errorGettingDimensionsByCategory, data: dimensionsByCategory },
  ] = useLazyQuery<GetTargetingDimensionsByCategoryQuery>(GET_TARGETING_DIMENSIONS_BY_CATEGORY, {
    fetchPolicy: 'no-cache',
  });

  const categories = data?.getTargetingCategories;
  const categoryOptions: DropdownOption[] = categories ? formatForDropdown(categories) : [];

  useEffect(() => {
    if (newTargeting?.category) {
      getTargetingDimensionsByCategory({ variables: { categoryGuid: newTargeting.category.id } });
    }
  }, [newTargeting?.category, getTargetingDimensionsByCategory]);

  const dimensionsOptions = useMemo(
    (): DropdownOption[] | null =>
      dimensionsByCategory?.getTargetingDimensionsByCategory
        ? formatForDropdown(dimensionsByCategory.getTargetingDimensionsByCategory)
        : [],
    [dimensionsByCategory]
  );

  const clearAll = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e?.preventDefault();
    setNewTargeting({ category: null, dimension: null, values: [] });
  };

  const setSelectedCategoryAndClearChildren = (category: TargetingDropdownOption | null): void => {
    if (newTargeting?.category?.value !== category?.value) setNewTargeting({ category, dimension: null, values: [] });
  };

  const setSelectedDimensionAndClearChildren = (dimension: TargetingDropdownOption | null): void => {
    if (newTargeting?.dimension?.value !== dimension?.value)
      setNewTargeting({ ...newTargeting, dimension, values: [] });
  };

  const setSelectedValues = (values: TargetingValueOption[]): void => setNewTargeting({ ...newTargeting, values });

  return {
    categoryOptions,
    selectedCategory: newTargeting?.category,
    setSelectedCategory: setSelectedCategoryAndClearChildren,
    isLoadingCategories,
    errorGettingCategories,
    dimensionsOptions,
    selectedDimension: newTargeting?.dimension,
    setSelectedDimension: setSelectedDimensionAndClearChildren,
    isLoadingDimensionsByCategory,
    errorGettingDimensionsByCategory,
    selectedValues: newTargeting?.values,
    setSelectedValues,
    clearAll,
  };
}
