import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

export type DeliveryTier = keyof typeof deliveryTierDisplayNames;

const deliveryTierDisplayNames = {
  guaranteed: enumToTitleCase,
  non_guaranteed: enumToTitleCase,
  house: enumToTitleCase,
  sponsorship: enumToTitleCase,
  test: enumToTitleCase,
};

export const deliveryTiers = makeConfig<DeliveryTier>(deliveryTierDisplayNames);

export function convertDeliveryTier(apiValue: string): DisplayEnum<DeliveryTier> {
  //Currently, 7/22/2022, inside the LI fragment the deliveryTier values are UPPER case,
  // however, the update hook returned value are lowercase, thus do this "toLowerCase" converter
  return convertToDisplayEnum(apiValue.toLowerCase(), deliveryTiers);
}
