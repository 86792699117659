import type { DraxCreativeInputV5 } from '../../../../apis/graphql';
import type { CreativesBuilderValues } from '../types';

export const constructDraxCreativeInput = ({
  name,
  url,
  vpaid,
  type,
  creativeLibraryId,
}: CreativesBuilderValues): DraxCreativeInputV5 => {
  const input: DraxCreativeInputV5 = {
    name,
    url: url || '',
    vpaid: !!vpaid,
    type: type || 'DRAX',
  };

  if (creativeLibraryId) input.creativeLibraryId = creativeLibraryId;

  return input;
};
