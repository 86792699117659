import './DuplicateAd.scss';

import { IconCopy } from '@hulu-react-style-components/icons';
import { Modal } from 'antd';
import React, { memo, useCallback, useState } from 'react';

import Button from '../../../common/Button';
import Tooltip from '../../../common/Tooltip';
import bem from '../../../utils/bem';
import type { AdModel, LineItemModel } from '../TraffickingPage/modelConverters';
import { TOOLTIP_MESSAGE } from './constants';
import DuplicateAdForm from './DuplicateAdForm';
import { arePropsEqual } from './utils';

const [block, element] = bem('duplicate-ad-button');

export interface DuplicateAdProps {
  selectedAd?: AdModel;
  selectedLineItems: LineItemModel[];
  isDisabled: boolean;
}

const DuplicateAd = ({ selectedAd, selectedLineItems, isDisabled }: DuplicateAdProps): JSX.Element => {
  const [showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false);

  const handleOpenDuplicateModal = useCallback((): void => {
    setShowDuplicateModal(true);
  }, []);

  const handleCloseDuplicateModal = useCallback((): void => {
    setShowDuplicateModal(false);
  }, []);

  return (
    <>
      <Tooltip id="duplicate-ad" className={element('tooltip')} message={TOOLTIP_MESSAGE} disable={!isDisabled}>
        <Button
          size="medium"
          variant="outlined"
          className={block()}
          disabled={isDisabled}
          aria-label="Copy Ad to Line Items"
          icon={<IconCopy />}
          onClick={handleOpenDuplicateModal}
          data-testid="duplicate-ad-button"
        >
          Copy Ad to Line Items
        </Button>
      </Tooltip>

      {showDuplicateModal && selectedAd && (
        <Modal
          visible={showDuplicateModal}
          footer={null}
          width={'85%'}
          onCancel={handleCloseDuplicateModal}
          data-testid="duplicate-ad-modal"
        >
          <DuplicateAdForm ad={selectedAd} selectedLineItems={selectedLineItems} onClose={handleCloseDuplicateModal} />
        </Modal>
      )}
    </>
  );
};

export default memo(DuplicateAd, arePropsEqual);
