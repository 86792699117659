import type { AdTypeV5 } from '../../../apis/graphql';
import { enumToTitleCase } from '../../../utils/formatting';
import { AlertType } from '../../Alert';
import type { ToastAlertProps } from '../../ToastAlert/toastAlert';
import { CREATIVE_IS_NOT_ALLOWED, TEMPLATE_IS_NOT_ALLOWED } from './const';

export const getTemplateMismatchAlertBody = (adType: AdTypeV5, template: string): ToastAlertProps => ({
  alertType: AlertType.ERROR,
  message: CREATIVE_IS_NOT_ALLOWED,
  description: `${enumToTitleCase(template)} ${TEMPLATE_IS_NOT_ALLOWED} ${enumToTitleCase(adType)} ad type`,
});
