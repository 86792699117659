import React from 'react';

import type { InnovidCreativeInputV5 } from '../../../../apis/graphql';
import { AdTypeV5 } from '../../../../apis/graphql';
import { creativeTypes, innovidFeatures } from '../../../../configs';
import { getAdPermittedTemplateTypes } from '../../../../configs/adPermittedTemplateTypes';
import { getInitialCreativeBuilderValues } from '../../adFormik';
import type { CreativeBuilderProps } from '../CreativeBuilder/CreativeBuilder';
import { InteractiveCreativeBuilder } from '../index';
import type { CreativesBuilderValues } from '../types';

export const constructInnovidCreativeInput = (
  { name, template, url }: CreativesBuilderValues = getInitialCreativeBuilderValues('build', AdTypeV5.Innovid)
): InnovidCreativeInputV5 => {
  const input: InnovidCreativeInputV5 = {
    name,
    type: 'INNOVID',
    url: url || 'https://',
    template: template!,
    // @deprecated should be removed in demand migration UML updates
    vpaid: false,
    // @deprecated should be removed in demand migration UML updates
    featureList: [{ type: innovidFeatures.IN_STREAM_SCROLLABLE.key }],
  };

  return input;
};

function InnovidCreativeBuilder({ editMode, isPreview = false }: CreativeBuilderProps): React.JSX.Element {
  const templateOptions = getAdPermittedTemplateTypes(AdTypeV5.Innovid);

  return (
    <InteractiveCreativeBuilder
      creativeType={creativeTypes.INNOVID.key}
      templateOptions={templateOptions}
      isPreview={isPreview}
      editMode={editMode}
    />
  );
}

export default InnovidCreativeBuilder;
