import type { ChangeLogUnionObject, DayPartV5, Maybe } from '../../../apis/graphql';
import type { ChangesPrefixes } from '../constants';
import type { Audits } from '../hooks/useChangeLog';
import type { TargetingEntry } from '../hooks/useChangeLog';
import type { ChangeLogRecordsList } from '../types';
import { getChangeLogTablesProps, getChangesLengthWithoutDuplicates, getChangesPluralString } from '../utils';
import type { FilteredLogs } from './useChangeLogList';
import { countTargetingChanges } from './useChangeLogList';

type Props = {
  previousObject: ChangeLogUnionObject | null;
  nestedChangesPrefix?: ChangesPrefixes;
  previousDayPartList?: Maybe<Array<DayPartV5>>;
  node: Audits;
  entityType?: string;
  targetingEntry: TargetingEntry | null;
};

export const getNormalizedChanges = ({
  previousObject,
  nestedChangesPrefix,
  previousDayPartList,
  node,
  entityType,
  targetingEntry,
}: Props): FilteredLogs => {
  const tablesProps = getChangeLogTablesProps({
    previousObject,
    nestedChangesPrefix,
    previousDayPartList,
    node,
    entityType,
  });

  let changesLength = getChangesLengthWithoutDuplicates(
    tablesProps.auditProps.reduce<ChangeLogRecordsList>((acc, { changes }) => [...acc, ...changes], [])
  );

  const creativeChanges = node.creativeAudits.length > 0 ? node.creativeAudits[0].changeList : [];

  let creativeChangesLength = getChangesLengthWithoutDuplicates(
    tablesProps.creativeProps[0]?.changes! ? tablesProps.creativeProps[0].changes! : creativeChanges!
  );

  if (targetingEntry) {
    const targetingChangesLength = countTargetingChanges(targetingEntry);
    if (targetingChangesLength) {
      changesLength -= 1;
      changesLength += targetingChangesLength;
    }
  }

  const changesPlural = getChangesPluralString(changesLength);
  const creativeChangesPlural = getChangesPluralString(creativeChangesLength);

  return {
    audits: {
      tablesProps: tablesProps.auditProps,
      changesPlural,
      changesLength,
    },
    creativeAudits: {
      tablesProps: tablesProps.creativeProps,
      changesPlural: creativeChangesPlural,
      changesLength: creativeChangesLength,
    },
  };
};
