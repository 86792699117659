import { gql } from '@apollo/client';

import { ASSET_IMPORTER_ASSET } from '../../../hooks/fragments';

export const GET_VIDEO_CREATIVE_DATA = gql`
  query getVideoCreativeData(
    $creativeId: String!
    $assetIdList: [String!]!
    $filterOptions: ReviewTaskV5FilterOptions
  ) {
    getAssetLatestIngestionStatus(creativeId: $creativeId) {
      jobStatus
      assetStatus {
        assetId
        trialStatus
        detail
        s3Url
        updatedAt
      }
    }
    getReviewTasksV5(filterOptions: $filterOptions) {
      edges {
        node {
          assetId
          spReview
        }
      }
    }
    getAssetsByIDsV5(assetIdList: $assetIdList) {
      id
      review
      rejectionReasons
      name
      type
      s3Url
      assetTagList {
        value
      }
      createdAt
      updatedAt
      md5
      fileSize
      transcodeStatus
      transcodeFailureDetails {
        name
        message
      }
      ...assetImporterAssetV5
      ... on VideoAssetV5 {
        duration
        mediaMetadata {
          format
        }
      }
    }
  }
  ${ASSET_IMPORTER_ASSET}
`;
