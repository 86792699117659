export enum SortState {
  SORTED_ASC = 'sorted-asc',
  SORTED_DESC = 'sorted-desc',
  UNSORTED = 'un-sorted',
}

export const changeSortStateMap = {
  [SortState.SORTED_ASC]: SortState.SORTED_DESC,
  [SortState.SORTED_DESC]: SortState.UNSORTED,
  [SortState.UNSORTED]: SortState.SORTED_ASC,
};
