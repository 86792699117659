import type { ApolloError } from '@apollo/client';
import { find as _find } from 'lodash';

import type { AdTypeV5 } from '../../../../apis/graphql';
import type { AdFormValues } from '../../../../common/AdForm/adFormik';
import {
  defaultScheduleFormikValues,
  getInitialCreativeBuilderValues,
  getScheduleFromLineItem,
  isLineItemScheduleIsValid,
} from '../../../../common/AdForm/adFormik';
import type { ToastAlertProps } from '../../../../common/ToastAlert/toastAlert';
import type { AdModel } from '../../TraffickingPage/modelConverters';
import type { DuplicateLineItem } from './hooks';
import type { BatchCreateAdFieldError } from './types';

type ConvertAdNameToCorrectDuplicateAdNameResult = {
  transformedAdName: string;
  currentIndex: number;
};

type GetDefaultOrChangedDuplicateAdNameProps = {
  adName: string;

  id: string;
  lineItems: DuplicateLineItem[];
  index: number;
};

type GetDefaultOrChangedDuplicateAdNameResult = {
  adName: string;
  editedAdName: string;
  index: number;
};

type DetermineAdNameForLineItem = {
  lineItem?: DuplicateLineItem;
  adName: string;
  index: number;
};

export const getDuplicateAdInitialValues = (ad: AdModel): AdFormValues => {
  const lineItemSchedule = ad.lineItem?.schedule;
  const schedule =
    lineItemSchedule && isLineItemScheduleIsValid(lineItemSchedule)
      ? getScheduleFromLineItem(lineItemSchedule)
      : defaultScheduleFormikValues;

  return {
    id: ad.id,
    name: ad.name,
    type: ad.type.key as AdTypeV5,
    deliveryStatus: false,
    rating: ad.rating.key,
    adTagList: [],
    creative: getInitialCreativeBuilderValues('selectOption'),
    frequencyCapList: [],
    viewabilityVendorList: ad.viewabilityVendorList,
    targetingTermValues: [],
    targetingRule: { id: '', definition: null },
    actualStart: null,
    newAdTag: {
      url: '',
    },
    newTargeting: {
      category: null,
      dimension: null,
      values: [],
    },
    schedule,
    iasCampaignLineItemId: '',
    isLastDateRangeDisabled: false,
    lineItem: {
      id: ad.lineItem?.id || '',
      parentLineOmsLink: ad.lineItem?.parentLineOmsLink || null,
    },
  };
};

export const parseCreateBatchAdsError = (lineItemsToCreate: DuplicateLineItem[], e: ApolloError): ToastAlertProps[] => {
  const parsedError = JSON.parse(e.message);

  if (parsedError['400']) {
    const errorFields: ToastAlertProps[] = [];

    parsedError['400'].forEach((field: BatchCreateAdFieldError) => {
      const adIndex = field['request-object-element'];
      const lineItemName = lineItemsToCreate[adIndex].name;
      const message =
        field.error.details && field.error.details[0].issue ? field.error.details[0].issue : field.error.message;

      errorFields.push({
        alertType: 'error',
        message,
        description: `Ad can't be copied to ${lineItemName}`,
        duration: 30,
      });
    });

    return errorFields;
  }

  return [
    {
      alertType: 'error',
      message: e.message,
    },
  ];
};

export const convertAdNameToCorrectDuplicateAdName = (
  name: string,
  index: number
): ConvertAdNameToCorrectDuplicateAdNameResult => ({
  transformedAdName: `${name}_${index + 1}`,
  currentIndex: index + 1,
});

export const determineAdNameForLineItem = ({
  lineItem,
  adName,
  index,
}: DetermineAdNameForLineItem): GetDefaultOrChangedDuplicateAdNameResult => {
  if (lineItem?.editedAdName) {
    return {
      adName: lineItem?.editedAdName,
      editedAdName: lineItem?.editedAdName,
      index,
    };
  } else {
    const { transformedAdName, currentIndex } = convertAdNameToCorrectDuplicateAdName(adName, index);

    return {
      adName: transformedAdName,
      editedAdName: '',
      index: currentIndex,
    };
  }
};

export const getDefaultOrChangedDuplicateAdName = ({
  adName,
  id,
  lineItems = [],
  index,
}: GetDefaultOrChangedDuplicateAdNameProps): GetDefaultOrChangedDuplicateAdNameResult => {
  const lineItem = _find(lineItems, { id });

  return determineAdNameForLineItem({ lineItem, adName, index });
};
