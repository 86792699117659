import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import type { LineItemPrioritiesQuery, LineItemPriorityV5 } from '../../../../apis/graphql';
import { GET_LINE_ITEM_PRIORITIES } from './queries/getLineItemPriorities';

type UseLineItemPriorities = {
  priorities: LineItemPriorityV5[];
  priorityNames: string[];
  loading: boolean;
  error?: string;
};

export const useLineItemPriorities = (): UseLineItemPriorities => {
  const { data, loading, error } = useQuery<LineItemPrioritiesQuery>(GET_LINE_ITEM_PRIORITIES, {
    fetchPolicy: 'cache-first',
  });

  const priorities = useMemo(() => data?.lineItemPrioritiesV5 || [], [data]);

  const priorityNames = useMemo(() => [...new Set(data?.lineItemPrioritiesV5.map(({ name }) => name))] || [], [data]);

  return {
    priorities,
    priorityNames,
    loading,
    error: error?.message,
  };
};
