import { Switch } from '@hulu/react-style-components';
import React from 'react';

import { TOGGLE_DISABLE_LAST_END_DATE_LABEL } from '../constants';

type Props = {
  isLastDateRangeDisabled: boolean;
  handleToggleDisableLastEndDate: (isDisabled: boolean, index: number) => void;
  setIsLastDateRangeDisabled: (value: boolean) => void;
  lastDateRangeIndex: number;
  className: string;
  readonly?: boolean;
};

const SafariToggleDisableLastEndDate = ({
  isLastDateRangeDisabled,
  handleToggleDisableLastEndDate,
  setIsLastDateRangeDisabled,
  lastDateRangeIndex,
  className,
  readonly,
}: Props): React.JSX.Element => {
  const onToggleHandler = (): void => {
    handleToggleDisableLastEndDate(isLastDateRangeDisabled, lastDateRangeIndex);
    setIsLastDateRangeDisabled(!isLastDateRangeDisabled);
  };

  return (
    <div className={className}>
      <span>{TOGGLE_DISABLE_LAST_END_DATE_LABEL}</span>
      <Switch disabled={readonly} isOn={!!isLastDateRangeDisabled} onChange={onToggleHandler} />
    </div>
  );
};

export default SafariToggleDisableLastEndDate;
