import './EditableDropdown.scss';

import React, { useMemo, useState } from 'react';
import type { SelectComponentsConfig } from 'react-select/src/components';

import { UNRECOGNIZED } from '../../../configs';
import type { DropdownOption, DropdownOptionGroup } from '../../Dropdown';
import Dropdown from '../../Dropdown';
import type { SingleSelectValue } from '../../Dropdown/Dropdown';
import type { PillColor } from '../../Pill';
import EditableCell from '../EditableCell';
import type { EditableInputVariant } from '../EditableCell/EditableCell';

export interface EditableDropdownProps<T extends string> {
  classNameModifier?: string;
  selectedValue: DropdownOption<T | typeof UNRECOGNIZED>;
  options: DropdownOption<T | typeof UNRECOGNIZED>[];
  onSubmit: (value: T) => void;
  variant: EditableInputVariant;
  isDisabled?: boolean;
  isSearchable?: boolean;
  isShowSelectedOption?: boolean;
  error?: string;
  isLoading?: boolean;
  dataTestId?: string;
  components?: SelectComponentsConfig<
    DropdownOption<typeof UNRECOGNIZED | T>,
    false,
    DropdownOptionGroup<typeof UNRECOGNIZED | T>
  >;
  onNext?: () => void;
  hasMore?: boolean;
  onInputChange?: (newValue: string) => void;
  highlightSelectedValue?: PillColor;
}

/**
 * A controlled dropdown that accepts a Config<T> and provides the selected value to an onSubmit callback for updating
 */
const EditableDropdown = <T extends string>({
  classNameModifier,
  selectedValue,
  options,
  onSubmit,
  variant,
  isDisabled,
  isSearchable,
  isShowSelectedOption = true,
  error,
  isLoading,
  dataTestId,
  components,
  onNext,
  hasMore,
  onInputChange,
  highlightSelectedValue,
}: EditableDropdownProps<T>): JSX.Element => {
  // Manage "isActive" state to provide to EditableCell wrapper
  // The isActive state does not control the active state of the dropdown
  // It controls the style of the containing EditableCell div
  const [isActive, setIsActive] = useState(false);
  const setNotActive = (): void => setIsActive(false);

  const onChange = (selectedOption: SingleSelectValue): void => {
    setNotActive();
    if (selectedOption && selectedOption.value !== selectedValue.value) {
      onSubmit(selectedOption.value as T);
    }
  };

  const dropdownOptions = useMemo(() => {
    if (selectedValue.value === UNRECOGNIZED) {
      return [{ value: selectedValue.value, label: selectedValue.label }, ...options];
    }

    if (!isShowSelectedOption) {
      return options.filter((option) => option.value !== selectedValue.value);
    }

    return options;
  }, [options, selectedValue, isShowSelectedOption]);

  return (
    <EditableCell variant={isActive ? 'active' : variant} tabIndex={-1}>
      <Dropdown
        classNameModifier={`${classNameModifier}${isSearchable ? ' is-searchable' : ''}`}
        isDisabled={isDisabled || variant === 'saving'}
        onBlur={setNotActive}
        onChange={onChange}
        options={dropdownOptions}
        tabIndex={'-1'}
        value={selectedValue}
        isSearchable={isSearchable ?? false}
        isLoading={isLoading}
        error={error}
        dataTestId={dataTestId}
        onInputChange={onInputChange}
        components={components}
        onNext={onNext}
        hasMore={hasMore}
        highlightSelectedValue={highlightSelectedValue}
      />
    </EditableCell>
  );
};

export default EditableDropdown;
