import './TableStatus.scss';

import React, { memo } from 'react';

import bem from '../../utils/bem';
import ErrorMessage from '../ErrorMessage';

export interface TableStatusProps {
  empty: boolean;
  emptyMessage?: React.ReactNode;
  errorMessage?: string;
  loading: boolean;
  loadingMessage?: string;
}

type TableState = 'empty' | 'error' | 'loading' | 'searching' | null;

const defaultEmptyMessage = 'No data provided for table.';

const [block, element] = bem('table-status');

function getTableStatusMod(empty: boolean, loading: boolean, errorMessage?: string): TableState {
  if (loading) return 'loading';
  if (errorMessage) return 'error';
  if (empty) return 'empty';

  return null;
}

function TableStatus({ empty, emptyMessage, errorMessage, loading, loadingMessage }: TableStatusProps): JSX.Element {
  const currentTableStatus = getTableStatusMod(empty, loading, errorMessage);

  let tableStatusContent: JSX.Element | null = null;

  // table status content depends on prop values so conditional block is cleaner than
  // status value-to-component map
  if (currentTableStatus) {
    if (currentTableStatus === 'loading') {
      tableStatusContent = <div className={element('status-text')}>{loadingMessage || 'Loading'}</div>;
    } else if (currentTableStatus === 'searching') {
      tableStatusContent = <div className={element('status-text')}>Searching</div>;
    } else if (currentTableStatus === 'error') {
      tableStatusContent = <ErrorMessage message={errorMessage} showEmailAddress />;
    } else if (currentTableStatus === 'empty') {
      tableStatusContent = <div className={element('status-text')}>{emptyMessage || defaultEmptyMessage}</div>;
    }
  }

  return (
    <div className={block(currentTableStatus)} data-testid="table-status">
      {tableStatusContent}
    </div>
  );
}

export default memo(TableStatus);
