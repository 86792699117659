import React, { memo } from 'react';
import type { Cell } from 'react-table';

import { DELIVERY_FORENSICS_LINK } from '../../..';
import { DataBarGraph, ShareIcon } from '../../../../../common/assets';
import Tooltip from '../../../../../common/Tooltip';
import bem from '../../../../../utils/bem';
import type { LineItemModel } from '../../modelConverters';

const [block] = bem('delivery-forensics-link');

type Props = Cell<LineItemModel>;

const ShareIconImg = `<img src='${ShareIcon}' alt='share link' />`;

function DeliveryForensicsCell({ row }: Props): JSX.Element {
  const { id } = row.original;

  return (
    <Tooltip id={id} message={`Delivery Forensics ${ShareIconImg}`}>
      <a
        href={`${DELIVERY_FORENSICS_LINK}?line-item-id=${id}`}
        className={block()}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={DataBarGraph} alt="data bar graph" />
      </a>
    </Tooltip>
  );
}

const comparison = (prev: Props, next: Props): boolean => prev.row.original.id === next.row.original.id;

export default memo(DeliveryForensicsCell, comparison);
