import './DateRangeFilter.scss';

import React from 'react';

import bem from '../../../utils/bem';
import Button from '../../Button';
import type { DefaultRangeType } from '../../DateRange';
import { DateRange } from '../../DateRange';
import type { FilterVariables } from '../ChangeLogList/usePagination';
import { useDateRangeFilter } from './useDateRangeFilter';
import { isStartDateAfterEndDate } from './utils';

export const [block] = bem('change-log-filter');

type Props = {
  refetch: (variables?: FilterVariables) => void;
  setLoadingFilter: (isLoading: boolean) => void;
  id?: string;
  toggleAll: (isOpen: boolean) => void;
};

export const DateRangeFilter = ({ refetch, id, setLoadingFilter, toggleAll }: Props): JSX.Element => {
  const { dateRange, onDateRangeChange, onFilterSubmit, onClearFilter } = useDateRangeFilter({
    refetch,
    id,
    setLoadingFilter,
    toggleAll,
  });

  const isFilterButtonDisabled = !dateRange.startDate || isStartDateAfterEndDate(dateRange);
  const isClearButtonDisabled = !dateRange.startDate && !dateRange.endDate;

  const formattedDateRange: DefaultRangeType = {
    start: dateRange.startDate,
    end: dateRange.endDate,
  };

  return (
    <div className={block()}>
      <DateRange dateRange={formattedDateRange} onChange={onDateRangeChange} type="date" />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button disabled={isFilterButtonDisabled} onClick={onFilterSubmit}>
          Filter
        </Button>
        <Button disabled={isClearButtonDisabled} onClick={onClearFilter}>
          Clear
        </Button>
      </div>
    </div>
  );
};
