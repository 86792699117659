import './IngestPill.scss';

import { Label } from '@hulu/react-style-components';
import React from 'react';

import type { IngestionJobStatus } from '../../../../../apis/graphql';
import type { StatusPillStatus } from '../../../../../common/_ForCommonLibrary/components/atoms/StatusPill';
import { StatusPill } from '../../../../../common/_ForCommonLibrary/components/atoms/StatusPill';

export const IngestPill: React.FC<{ ingestStatus: IngestionJobStatus }> = ({ ingestStatus }) => {
  let status: StatusPillStatus = 'IN_PROGRESS';

  if (ingestStatus === 'COMPLETE') status = 'SUCCESS';
  if (ingestStatus === 'ABORTED') status = 'ERROR';

  // const message = !ingestStatus || ingestStatus === 'NO_JOBS' ? 'NOT STARTED' : ingestStatus?.replaceAll('_', ' ');
  const message = ingestStatus === 'NO_JOBS' ? 'NOT STARTED' : ingestStatus;

  return (
    <div className="video-footer-ingest-pill">
      <Label>Ingestion Status:</Label> <StatusPill status={status}>{message}</StatusPill>
    </div>
  );
};
