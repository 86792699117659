import './BasicSelectOption.scss';

import React from 'react';

import bem from '../../../../utils/bem';

export type BasicSelectOptionProps = {
  id: string | number;
  value: unknown;
  label: string;
  isSelected?: boolean;
  isDisabled?: boolean;
};

export type BasicSelectOptionComponentProps = BasicSelectOptionProps & { handleSelect: Function };

const [block, element] = bem('basic-select-option');

const BasicSelectOption = ({
  id,
  value,
  handleSelect,
  label,
  isSelected,
}: BasicSelectOptionComponentProps): JSX.Element => {
  return (
    <div
      className={`${block()} ${isSelected ? element('selected') : ''}`}
      data-testid={`basic-select-option-${id}`}
      key={id}
      onClick={(): void => {
        handleSelect(value, id);
      }}
    >
      {label}
    </div>
  );
};

export default BasicSelectOption;
