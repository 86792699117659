import type { DocumentNode } from '@apollo/client';

import type { CreativeType } from '../../../../configs';
import {
  CREATE_AD_SELECTOR_SLATE_CREATIVE,
  CREATE_AD_SELECTOR_VIDEO_CREATIVE,
  CREATE_BRIGHTLINE_CREATIVE,
  CREATE_DISPLAY_CREATIVE,
  CREATE_DRAX_CREATIVE,
  CREATE_GATEWAY_GO_CREATIVE,
  CREATE_INNOVID_CREATIVE,
  CREATE_PAUSE_CREATIVE,
  CREATE_SLATE_CREATIVE,
  CREATE_VAST_VIDEO_CREATIVE,
  CREATE_VIDEO_CREATIVE,
  UPDATE_AD_SELECTOR_SLATE_CREATIVE,
  UPDATE_AD_SELECTOR_VIDEO_CREATIVE,
  UPDATE_BRIGHTLINE_CREATIVE,
  UPDATE_DISPLAY_CREATIVE,
  UPDATE_DRAX_CREATIVE,
  UPDATE_GATEWAY_GO_CREATIVE,
  UPDATE_INNOVID_CREATIVE,
  UPDATE_MAGNITE_CREATIVE,
  UPDATE_PAUSE_CREATIVE,
  UPDATE_SLATE_CREATIVE,
  UPDATE_VAST_VIDEO_CREATIVE,
  UPDATE_VIDEO_CREATIVE,
} from '../../../../pages/Ads/hooks/mutations';
import type { CreativeInput, CreativeInputGenerator } from '../../../../pages/Ads/hooks/types';
import { constructAdSelectorSlateCreativeInput } from '../../CreativeBuilders/AdSelectorSlateCreativeBuilder';
import { constructAdSelectorVideoCreativeInput } from '../../CreativeBuilders/AdSelectorVideoCreativeBuilder';
import { constructBrightlineCreativeInput } from '../../CreativeBuilders/BrightlineCreativeBuilder';
import { constructDisplayCreativeInput } from '../../CreativeBuilders/DisplayCreativeBuilder';
import { constructDraxCreativeInput } from '../../CreativeBuilders/DraxCreativeBuilder';
import { constructGatewayGoCreativeInput } from '../../CreativeBuilders/GatewayGoCreativeBuilder';
import { constructInnovidCreativeInput } from '../../CreativeBuilders/InnovidCreativeBuilder';
import { constructMagniteCreativeInput } from '../../CreativeBuilders/MagniteCreativeBuilder';
import { constructPauseCreativeInput } from '../../CreativeBuilders/PauseCreativeBuilder';
import { constructSlateCreativeInput } from '../../CreativeBuilders/SlateCreativeBuilder';
import type { CreativesBuilderValues } from '../../CreativeBuilders/types';
import {
  constructVastVideoCreativeInput,
  constructVideoCreativeInput,
} from '../../CreativeBuilders/VideoCreativeBuilder';

interface CreateAndUpdateMutations {
  create: {
    mutation: DocumentNode;
    idAccessKey: string;
  } | null;
  update: {
    mutation: DocumentNode;
    idAccessKey: string;
  };
}

export interface MutationConfig extends CreateAndUpdateMutations {
  inputGenerator: CreativeInputGenerator;
}

export function getBaseCreativeInput({ name }: CreativesBuilderValues): Pick<CreativeInput, 'name'> {
  return { name };
}

export const creativeInputGeneratorMap: { [key in CreativeType]?: CreativeInputGenerator } = {
  AD_SELECTOR_SLATE: constructAdSelectorSlateCreativeInput,
  AD_SELECTOR_VIDEO: constructAdSelectorVideoCreativeInput,
  DISPLAY: constructDisplayCreativeInput,
  PAUSE: constructPauseCreativeInput,
  MAGNITE: constructMagniteCreativeInput,
  SLATE: constructSlateCreativeInput,
  VIDEO: constructVideoCreativeInput,
  VAST_VIDEO: constructVastVideoCreativeInput,
  BRIGHTLINE: constructBrightlineCreativeInput,
  INNOVID: constructInnovidCreativeInput,
  DRAX: constructDraxCreativeInput,
  MARQUEE_PROMOTED_CONTENT: constructDisplayCreativeInput,
  GATEWAY_GO: constructGatewayGoCreativeInput,
  // VPAID
  // MARQUEE_POSTER
  // PREROLL_VPAID
};

// Map creative types to the mutations we need to save them
const creativeMutationMap: { [key in CreativeType]?: CreateAndUpdateMutations } = {
  AD_SELECTOR_SLATE: {
    create: {
      mutation: CREATE_AD_SELECTOR_SLATE_CREATIVE,
      idAccessKey: 'createAdSelectorSlateCreativeV5',
    },
    update: {
      mutation: UPDATE_AD_SELECTOR_SLATE_CREATIVE,
      idAccessKey: 'updateAdSelectorSlateCreativeV5',
    },
  },
  AD_SELECTOR_VIDEO: {
    create: {
      mutation: CREATE_AD_SELECTOR_VIDEO_CREATIVE,
      idAccessKey: 'createAdSelectorVideoCreativeV5',
    },
    update: {
      mutation: UPDATE_AD_SELECTOR_VIDEO_CREATIVE,
      idAccessKey: 'updateAdSelectorVideoCreativeV5',
    },
  },
  DISPLAY: {
    create: {
      mutation: CREATE_DISPLAY_CREATIVE,
      idAccessKey: 'createDisplayCreativeV5',
    },
    update: {
      mutation: UPDATE_DISPLAY_CREATIVE,
      idAccessKey: 'updateDisplayCreativeV5',
    },
  },
  PAUSE: {
    create: { mutation: CREATE_PAUSE_CREATIVE, idAccessKey: 'createPauseCreativeV5' },
    update: { mutation: UPDATE_PAUSE_CREATIVE, idAccessKey: 'updatePauseCreativeV5' },
  },
  SLATE: {
    create: { mutation: CREATE_SLATE_CREATIVE, idAccessKey: 'createSlateCreativeV5' },
    update: { mutation: UPDATE_SLATE_CREATIVE, idAccessKey: 'updateSlateCreativeV5' },
  },
  VIDEO: {
    create: { mutation: CREATE_VIDEO_CREATIVE, idAccessKey: 'createVideoCreativeV5' },
    update: { mutation: UPDATE_VIDEO_CREATIVE, idAccessKey: 'updateVideoCreativeV5' },
  },
  VAST_VIDEO: {
    create: { mutation: CREATE_VAST_VIDEO_CREATIVE, idAccessKey: 'createVastVideoCreativeV5' },
    update: { mutation: UPDATE_VAST_VIDEO_CREATIVE, idAccessKey: 'updateVastVideoCreativeV5' },
  },
  BRIGHTLINE: {
    create: { mutation: CREATE_BRIGHTLINE_CREATIVE, idAccessKey: 'createBrightlineCreativeV5' },
    update: { mutation: UPDATE_BRIGHTLINE_CREATIVE, idAccessKey: 'updateBrightlineCreativeV5' },
  },
  INNOVID: {
    create: { mutation: CREATE_INNOVID_CREATIVE, idAccessKey: 'createInnovidCreativeV5' },
    update: { mutation: UPDATE_INNOVID_CREATIVE, idAccessKey: 'updateInnovidCreativeV5' },
  },
  GATEWAY_GO: {
    create: { mutation: CREATE_GATEWAY_GO_CREATIVE, idAccessKey: 'createGatewayGoCreativeV5' },
    update: { mutation: UPDATE_GATEWAY_GO_CREATIVE, idAccessKey: 'updateGatewayGoCreativeV5' },
  },
  MAGNITE: {
    create: null,
    update: { mutation: UPDATE_MAGNITE_CREATIVE, idAccessKey: 'updateMagniteCreativeV5' },
  },
  DRAX: {
    create: { mutation: CREATE_DRAX_CREATIVE, idAccessKey: 'createDraxCreativeV5' },
    update: { mutation: UPDATE_DRAX_CREATIVE, idAccessKey: 'updateDraxCreativeV5' },
  },
  // VPAID
  // MARQUEE_POSTER
  // MARQUEE_PROMOTED_CONTENT
  // PREROLL_VPAID
};

export function getCreativeMutation(creativeType: CreativeType): MutationConfig {
  const mutations = creativeMutationMap[creativeType];
  const inputGenerator = creativeInputGeneratorMap[creativeType];

  if (!mutations || !inputGenerator) {
    throw Error(`There is no known mutation for creative type ${creativeType}`);
  }

  return {
    inputGenerator,
    ...mutations,
  };
}
