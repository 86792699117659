import { gql } from '@apollo/client';

export const LINE_ITEM_TRAFFICKING_COLUMNS = gql`
  fragment lineItemTraffickingColumns on LineItemV5 {
    __typename
    id
    pacingMetrics {
      yodaPacingStatus
      generalPacingStatus
      yodaThreshold
    }
    pacingType
    trackingId
    name
    tier {
      huluCommitment
      name
      rank
    }
    priority {
      name
      rank
    }
    review
    status
    subStatus
    parentLineOmsLink {
      id
    }
    lineOmsLink {
      id
    }
    adProduct
    billableThirdParty
    unitCost
    unitCostDto {
      currency
      micros
    }
    costMethod
    secondRoundEligible
    dhxpBackfill
    creativeMaxSeconds
    exclusiveBlock {
      id
      name
      lineItemIdList
    }
    draxDeal {
      packageId
      packageName
      dealId
      dealName
      dspId
    }
    campaign {
      id
      name
      traffickerEmail
      order {
        salespersonEmail
      }
      adAccount {
        id
        timezone
        organizationId
      }
      campaignTagList {
        id
        type
        url
      }
      crmLink {
        id
        name
      }
      startDate
    }
    lineItemSequenceId
    revenue
    priorityValue
    schedule {
      timezone
      dateRangeList {
        startDate
        endDate
        pacingShare
      }
      dayPartList {
        firstActiveHour
        lastActiveHour
        activeDayList
      }
      deliveryInViewerTime
    }
    frequencyCapList {
      type
      limit
      duration
      durationUnit
      enabled
    }
    deliveryBufferPercent
    frontLoadPercent
    preemptible
    goal
    isFiller
    podBuyout
    industryExclusive
    metrics {
      pacingStatus
      percentExpected
      percentPacing
      percentCompletions
      remainingExpectedCompletionsAcc
      revenueAtRisk
      completions
      underPacingRevenue
      completionsAtRisk
    }
    startDate
    endDate
    createdAt
    updatedAt
    hasZeroDeliveryRisk
    hasMisalignedSchedule
    deliveryGoal
    displayPublisherTarget
    isCoppaOrCaru
    isMakegood
    isAddedValue
    orderLineItemDto {
      parentLineOmsLink {
        id
        type
      }
      packageName
      childLineOmsLink {
        id
        type
      }
      isMakeGood
      buyerName
      buyerId
    }
    magniteDealDto {
      dealId
      dealName
      adSourceId
      adSourceName
      dspName
    }
    metadata {
      actualStartDate
    }
    exclusiveBlock {
      id
      name
      lineItemIdList
      blockedIndustryList {
        id
        name
      }
      targetingTermValues {
        category {
          id
          name
          displayName
        }
        dimension {
          id
          name
          displayName
        }
        value {
          id
          name
          displayName
        }
        include
      }
    }
    maxCreativeMs
    country
    inventoryBlockId
  }
`;
