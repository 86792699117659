import * as yup from 'yup';

export const LINE_ITEM_SCHEMA = yup.object().shape({
  id: yup.string(),
  name: yup.string(),
  adName: yup
    .string()
    .min(3, 'Must be 3 characters at least')
    .required('Ad name can not be empty')
    .max(255, 'Must be no longer than 255 characters'),
});

export const DUPLICATED_AD_FORM_SECOND_STEP_FORM_VALIDATION = yup.object().shape({
  lineItems: yup.array().of(LINE_ITEM_SCHEMA),
});
