import './RemoveEntityButton.scss';

import type { ModalProps } from 'antd';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import ModalPopup from '../../../common/ModalPopup';
import bem from '../../../utils/bem';
import type { AdFormValues } from '../adFormik';

type ButtonRendererProps = {
  onClick: () => void;
  tabIndex?: number;
  onKeyDown?: (e: React.KeyboardEvent<HTMLOrSVGElement>) => void;
};

export type ButtonRendererFunction = (props: ButtonRendererProps) => JSX.Element;

export interface RemoveEntityButtonProps<T> {
  formFieldName: string; // field in formik form
  emptyValue: T; // empty value to reset to
  modalMessage: JSX.Element;
  modalProps: Pick<ModalProps, 'cancelText' | 'okText' | 'title'>;
  ButtonRenderer: ButtonRendererFunction;
  deleteEntity?: () => Promise<void>;
}

const [block] = bem('remove-entity-button');

function RemoveEntityButton<T>({
  formFieldName,
  emptyValue,
  modalProps,
  ButtonRenderer,
  modalMessage,
  deleteEntity,
}: RemoveEntityButtonProps<T>): JSX.Element {
  const [modalVisible, setModalVisible] = useState(false);
  const { setFieldValue } = useFormikContext<AdFormValues>();
  const removeEntity = (): void => {
    setFieldValue(formFieldName, emptyValue);
    if (deleteEntity) deleteEntity();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLOrSVGElement>): void => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      setModalVisible(true);
    }
  };

  return (
    <>
      <ButtonRenderer onClick={(): void => setModalVisible(true)} onKeyDown={handleKeyDown} />
      <ModalPopup
        visible={modalVisible}
        onOk={removeEntity}
        onCancel={(): void => setModalVisible(false)}
        modalMessage={modalMessage}
        modalProps={{
          ...modalProps,
          wrapClassName: block(),
        }}
      />
    </>
  );
}

export default RemoveEntityButton;
