import type { MultipleEditDrawerAdsFormValues, MultipleEditDrawerLineItemsFormValues } from '../../../../../../types';
import { useGetNormalizedTargeting } from '../../useGetNormalizedTargeting/useGetNormalizedTargeting';
import { checkTargetingConflicts } from '../checkTargetingConflicts/checkTargetingConflicts';

export type TargetingConflictResult = {
  hasAlreadyThisTarget: string[];
  hasTargetConflict: string[];
  hasIncludeConflict: string[];
  hasExcludeConflict: string[];
};

type Props = {
  isLineItem: boolean;
  values: MultipleEditDrawerLineItemsFormValues | MultipleEditDrawerAdsFormValues;
};

type Result = {
  targetingConflicts: TargetingConflictResult;
  loading?: boolean;
};

export const useCheckTargetingConflicts = ({ isLineItem, values }: Props): Result => {
  const targeting = useGetNormalizedTargeting({ values, isLineItem });

  const targetingConflicts = checkTargetingConflicts({
    existedTargeting: targeting?.filterTargetingWithEntityId,
    formValuesTargeting: values.targetingTermValues,
  });

  return {
    targetingConflicts,
    loading: targeting?.loading,
  };
};
