import { IconClose } from '@hulu-react-style-components/icons';
import { format } from 'date-fns';
import type { Dispatch, FocusEvent, SetStateAction } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import type { ScheduleFilterInputV5 } from '../../../../../apis/graphql';
import type { CalendarDate } from '../../../../../common/_ForCommonLibrary/components/molecules/DatePicker';
import { DatePicker } from '../../../../../common/_ForCommonLibrary/components/molecules/DatePicker';
import ControlledCheckbox from '../../../../../common/ControlledCheckbox';
import type { DefaultRangeType } from '../../../../../common/DateRange';
import { DateRange } from '../../../../../common/DateRange';
import type { DropdownOption } from '../../../../../common/Dropdown';
import type { SingleSelectValue } from '../../../../../common/Dropdown/Dropdown';
import { openToastAlert } from '../../../../../common/ToastAlert/toastAlert';
import type { IDateRange } from '../DateFilter';
import type { SelectedDateValue } from '../helpers';
import type { CUSTOM_DATE } from '../makeGroupedDateOptions';
import { DEFAULT_DATE_FORMAT, STATUSES } from './constants';
import { getDateFromAString, getDefaultDateStatus, getMaxDate, getScheduleData } from './helpers';
import { checkValidation, ErrorVariants } from './validation';

export interface Errors {
  startDate?: string;
  endDate?: string;
}

type Props = {
  isCustomDateOption: boolean;
  dateRange: IDateRange;
  setDateRange: Dispatch<SetStateAction<IDateRange>>;
  onChange: (v: undefined | SingleSelectValue<ScheduleFilterInputV5>) => void;
  setIsCustomDateOption: (isCustomDateOption: boolean) => void;
  selectedValue?: SelectedDateValue;
  activeDatesValue: DropdownOption<typeof CUSTOM_DATE> | null;
};

export const Modal = ({
  isCustomDateOption,
  dateRange,
  setDateRange,
  onChange,
  setIsCustomDateOption,
  selectedValue,
  activeDatesValue,
}: Props): JSX.Element => {
  const [dateStatus, setDateStatus] = useState(getDefaultDateStatus(selectedValue?.value));
  const [isCancelRequest, setIsCancelRequest] = useState(false);
  const [errors, setErrors] = useState<Errors | null | undefined>(null);
  const [isEndDateTouchedAndError, setIsEndDateTouchedAndError] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const onCancel = useCallback((): void => {
    setIsCancelRequest(true);
    setIsCustomDateOption(false);
  }, [setIsCustomDateOption]);

  useEffect(() => {
    const onEscape = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        onCancel();
      }
    };

    window.addEventListener('keydown', onEscape);

    return (): void => window.removeEventListener('keydown', onEscape);
  }, [onCancel]);

  useEffect(() => {
    ref.current?.focus();
  }, [isCustomDateOption]);

  useEffect(() => {
    if (activeDatesValue) {
      const parseDates = activeDatesValue.label.split('-');
      const start = format(new Date(parseDates[0]), DEFAULT_DATE_FORMAT);
      const end = format(new Date(parseDates[1]), DEFAULT_DATE_FORMAT);

      setDateRange((prev) => ({
        ...prev,
        startDate: new Date(start),
        endDate: new Date(end),
      }));
    } else {
      setDateRange((prev) => ({
        ...prev,
        startDate: new Date(),
        endDate: null,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEndDateTouchedAndError) {
      checkValidation(setErrors, dateRange);
    }
  }, [dateRange, isEndDateTouchedAndError]);

  const onChangeCheckbox = (checkboxName: string): void => {
    setDateStatus(checkboxName.toUpperCase());
  };

  const onCalendarChange = (date: CalendarDate[] | CalendarDate): void => {
    if (!Array.isArray(date)) return;

    const [startDate, endDate] = date;

    if (!startDate) return;

    setDateRange((prev) => ({
      ...prev,
      startDate,
      endDate,
    }));
  };

  const onDateRangeChange = (field: string, value: string): void => {
    setDateRange((prev) => ({
      ...prev,
      [`${field}Date`]: value ? getDateFromAString(value) : '',
    }));

    if (field === 'end') {
      if (!value) {
        setIsEndDateTouchedAndError(true);
        setErrors((prev) => ({
          ...prev,
          endDate: ErrorVariants.INCORRECT_END_DATE,
        }));

        return;
      }

      setErrors((prev) => ({
        ...prev,
        end: undefined,
      }));
    }

    setIsEndDateTouchedAndError(false);
  };

  const onClickOutside = (event: FocusEvent<HTMLDivElement> | null): void => {
    const { endDate, startDate } = dateRange;
    const target = event?.currentTarget;

    if (!event || !target?.contains(event.relatedTarget as HTMLElement)) {
      if (!dateRange?.endDate) {
        setErrors((prev) => ({ ...prev, endDate: ErrorVariants.INCORRECT_END_DATE }));
        openToastAlert({
          alertType: 'error',
          message: ErrorVariants.INCORRECT_END_DATE,
        });

        return;
      }

      if (errors?.startDate || errors?.endDate) {
        openToastAlert({
          alertType: 'error',
          message: ErrorVariants.INCORRECT_DATE,
        });

        return;
      }

      if (isCancelRequest) return;

      onChange(getScheduleData({ dateStatus, newStartOfDate: startDate, newEndOfDate: endDate }));
      setIsCustomDateOption(false);
    }
  };

  const isSubmitDisable = (): boolean => {
    if (!dateRange?.endDate) return true;
    if (!errors) return false;

    return !!(errors?.startDate || errors?.endDate);
  };

  const formattedDateRange: DefaultRangeType = {
    start: dateRange.startDate ? format(dateRange.startDate, 'yyyy-MM-dd') : '',
    end: dateRange.endDate ? format(dateRange.endDate, 'yyyy-MM-dd') : null,
  };

  return (
    <div className="modal-datepicker" data-testid="modal-datepicker" tabIndex={0} onBlur={onClickOutside} ref={ref}>
      <div className="icon-close" onClick={onCancel} data-testid="icon-close">
        <IconClose width="20px" height="20px" title="Close" />
      </div>
      <div className="container-checkbox-range-inputs">
        <div className="checkbox-container">
          {STATUSES.map((status) => (
            <ControlledCheckbox
              labelContent={status}
              checked={dateStatus === status.toUpperCase()}
              indeterminate={false}
              onChange={(): void => onChangeCheckbox(status)}
              onKeyDown={(): void => {}}
              key={status}
            />
          ))}
        </div>
        <DateRange
          dateRange={formattedDateRange}
          onChange={onDateRangeChange}
          customTitles={{
            start: 'Start date',
            end: 'End date',
          }}
          type="date"
        />
      </div>
      {errors?.startDate && <div className="error-date-message">{errors?.startDate}</div>}
      {errors?.endDate && <div className="error-date-message">{errors?.endDate}</div>}
      <div>
        <DatePicker
          selectsRange
          inline
          disabledKeyboardNavigation
          dateRange={[dateRange.startDate, dateRange.endDate]}
          onChange={onCalendarChange}
          openToDate={dateRange.endDate ? dateRange.endDate : undefined}
          onOk={(): void => onClickOutside(null)}
          minDate={new Date(2022, 0, 1)}
          maxDate={getMaxDate()}
          disabled={isSubmitDisable()}
        />
      </div>
    </div>
  );
};
