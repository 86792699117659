import { gql } from '@apollo/client/core';

import { ROTATIONS_FRAGMENT } from '../../../../../common/AdForm/hooks/fragments';

export const UPDATE_ROTATION_NAME = gql`
  mutation UpdateRotationName($rotationId: ID!, $name: String!) {
    updateRotationName: updateRotationNameV5(input: { rotationId: $rotationId, name: $name }) {
      ...rotations
    }
  }
  ${ROTATIONS_FRAGMENT}
`;
