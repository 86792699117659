import type { StatusLabel } from '../types';
import { LabelType } from './LabelType';

export function getBestLabel(
  ingest: StatusLabel,
  transcode: StatusLabel,
  review: StatusLabel,
  spReview: StatusLabel
): StatusLabel {
  const onlyIngest = !!ingest && !transcode && !review && !spReview;
  const onlyTranscode = !ingest && !!transcode && !review && !spReview;
  const onlySpReview = !ingest && !transcode && !review && !!spReview;

  // Show Ingest if ingestion is not complete
  if (onlyIngest || (ingest && ingest?.type !== LabelType.SUCCESS && review?.type !== LabelType.SUCCESS)) return ingest;

  // Show Transcode if transcoding is not complete
  if (onlyTranscode || (transcode && transcode?.type !== LabelType.SUCCESS)) return transcode;

  // Show SPReview if SPReview status is locked
  if (onlySpReview || (spReview && spReview?.type === LabelType.WARN)) return spReview;

  // Show Review if no other status is shown
  return review;
}
