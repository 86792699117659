import { gql } from '@apollo/client';

export const GET_CREATIVE_APPROVALS = gql`
  query getCreativeApprovals($creativeId: String!) {
    getCreativeApprovalsV5(creativeId: $creativeId) {
      adUuidsWithApprovedVastUrl
      approvedAdUuids
    }
  }
`;
