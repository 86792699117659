import { gql } from '@apollo/client';

import { ASSET_IMPORTER_ASSET } from '../../../../AdForm/hooks/fragments';

export const SEARCH_ASSETS = gql`
  query SearchAssets($name: String, $type: [AssetTypeV5!], $limit: Int, $offset: Int) {
    searchAssetsV5(name: $name, type: $type, limit: $limit, offset: $offset) {
      edges {
        node {
          ...assetImporterAssetV5
        }
      }
    }
  }
  ${ASSET_IMPORTER_ASSET}
`;
