import type { ApolloError, FieldPolicy, Reference } from '@apollo/client';
import type { SafeReadonly } from '@apollo/client/cache/core/types/common';
import type { GraphQLError } from 'graphql';

import type { ResultKeys } from '../../../pages/Trafficking';
import type { CampaignClientErrors, GQLError, GQLErrorDetail } from '../graphqlAPI';

type Edge<NodeType> = {
  node: NodeType;
};

export type PaginatedResults<NodeType> = {
  edges: Edge<NodeType>[];
};

export type NodeTransformFunction<NodeType, Model> = (node: NodeType) => Model;

type Key = keyof typeof ResultKeys;

export function getDataFromNodes<NodeType, Model>(
  data: {} | undefined,
  key: Key,
  transformFn: NodeTransformFunction<NodeType, Model>
) {
  return (): Model[] => {
    if (!data) return [];
    return getNodes<NodeType, Model>((data as Record<Key, PaginatedResults<NodeType>>)[key], transformFn);
  };
}

// Exported for testing only
export function getNodes<NodeType, Model>(
  data: PaginatedResults<NodeType> | undefined,
  transform: NodeTransformFunction<NodeType, Model>
): Model[] {
  if (data === undefined) {
    return [];
  }
  return data.edges.map<Model>((e: Edge<NodeType>) => transform(e.node));
}

/**
 * Create a mapping of status to error message
 * @param errors GraphQLErrors[] List of graphQL Errors
 */
export const createGraphQLErrorMap = (errors: ReadonlyArray<GraphQLError>): CampaignClientErrors => {
  const errorMap: CampaignClientErrors = {};

  // loop through all graphql errors
  errors.forEach((gqlErr) => {
    const response = gqlErr.extensions?.response || {};

    if (Array.isArray(response?.body)) {
      errorMap[response?.status] = response?.body;
    } else {
      const errorPropertyNames: string[] | undefined =
        response?.body?.error && Object.getOwnPropertyNames(response?.body?.error);
      const bodyPropertyNames: string[] | undefined = response?.body && Object.getOwnPropertyNames(response?.body);

      const status = response?.status;

      let details: GQLErrorDetail[] | undefined = undefined;
      let message: string | undefined = undefined;
      let name: string | undefined = undefined;

      // we need to make sure there is something in there so we can get the response
      if (errorPropertyNames?.includes('details') && errorPropertyNames?.includes('message')) {
        details = response.body.error.details;
        message = response.body.error.message;
      } else if (bodyPropertyNames?.includes('message') && bodyPropertyNames.includes('name')) {
        message = response.body.message;
        name = response.body.name;
      }

      // Initialize status array if necessary
      if ((details || message || name) && !errorMap[status]) errorMap[status] = [];

      // for each detail issue we want to capture that for showing errors from service
      details?.forEach((detail: GQLErrorDetail) => errorMap[status].push(detail.issue));
      if (message?.length) errorMap[status].push(message);
      if (name?.length) errorMap[status].push(name);
    }
  });

  return errorMap;
};

export const getErrorMessageFromGraphQlErrors = (errors: ReadonlyArray<GraphQLError>): string => {
  const errorMap = createGraphQLErrorMap(errors);
  const isErrorMapEmpty = !Object.values(errorMap).length;
  const errorMsgList = isErrorMapEmpty ? errors?.map(({ message }) => message) : Object.values(errorMap).flat();

  const errorMsg = errorMsgList
    .map<string>((errorValue) => {
      if (typeof errorValue === 'string') return errorValue;

      const { error } = errorValue;

      const errorMessage = error.details
        ? error.details.reduce((acc, detail) => {
            return acc + detail.issue;
          }, '')
        : error.message;

      return errorMessage;
    })
    .join('\r\n');

  return errorMsg;
};

/**
 * This function is used to handle errors in a readable state to help make error
 * handling more generic so that the app can extract error messages from the server easier.
 * @param error ApolloError The error that came back from an Apollo Client call.
 */
export const handleGraphQLErrors = (error: ApolloError): CampaignClientErrors => {
  return createGraphQLErrorMap(error.graphQLErrors);
};

export const removeTypeNamesFromObject = <T>(data: Record<string, unknown>): T => {
  return JSON.parse(JSON.stringify(data, (key, value) => (key === '__typename' ? undefined : value)));
};

type KeyArgs = FieldPolicy<unknown>['keyArgs'];

export const concatPaginationWithEdges = <T extends { edges: unknown[] }>(
  keyArgs: KeyArgs = false
): FieldPolicy<T> => ({
  keyArgs,
  merge(existing, incoming, { args }): SafeReadonly<T> & { edges: unknown[] } {
    const offset = args?.paginationOptions?.offset || 0;
    const mergedEdges = existing && offset ? existing.edges.slice(0) : [];

    for (let i = 0; i < incoming.edges.length; ++i) {
      mergedEdges[offset + i] = incoming.edges[i];
    }

    return {
      ...incoming,
      edges: mergedEdges,
    };
  },
});

export const mergeUniqueReferences = (existing: Reference[], incoming: Reference[]): Reference[] => {
  const mergedItems = [...existing, ...incoming];
  const uniqueRefs = new Set();

  return mergedItems.filter((item) => {
    if (uniqueRefs.has(item.__ref)) {
      return false;
    }

    uniqueRefs.add(item.__ref);

    return true;
  });
};

export const generateIdsList = (ids: Set<string>, maxCount: number): string => {
  const idsArray = [...ids].slice(0, maxCount);
  const idsList = idsArray.join(', ').replace(/\.\s*/g, '');

  if (ids.size > maxCount) {
    return `${idsList} out of ${ids.size} ids`;
  }

  return idsList;
};

export const generateMisalignmentGQLErrorMessage = (errors: ReadonlyArray<GraphQLError>): string => {
  const errorMap = createGraphQLErrorMap(errors);
  const errorList = Object.values(errorMap).flatMap((item) => item);

  const adIds = new Set<string>();
  const lineItemIds = new Set<string>();

  const gqlErrorList = errorList.filter((item): item is GQLError => typeof item === 'object' && 'error' in item);

  gqlErrorList.forEach((item) => {
    const error = item.error;

    if (!error) return;

    const adIdMatch = error.message.match(/Ad ID: ([^ ]+)/);
    const lineItemIdMatch = error.message.match(/Line Item ID: ([^ ]+)/);

    adIdMatch && adIds.add(adIdMatch[1]);
    lineItemIdMatch && lineItemIds.add(lineItemIdMatch[1]);
  });

  const adIdsList = generateIdsList(adIds, 10);
  const lineItemIdsList = generateIdsList(lineItemIds, 10);

  return adIds.size > 0 && lineItemIds.size > 0
    ? `Scheduling conflict for Ad ID: ${adIdsList} on Line Item ID: ${lineItemIdsList}`
    : '';
};
