import { startCase } from 'lodash';

import type { Maybe } from '../../../../../apis/graphql';
import type { DropdownOption } from '../../../../../common/Dropdown';
import { PillColor } from '../../../../../common/Pill';
import type { LineItemStatus } from '../../../../../configs';
import { lineItemDerivedState, lineItemStatuses, UNRECOGNIZED } from '../../../../../configs';
import { derivedStateColorsMap } from '../../../../../constants';
import type { LineItemStaticStatusData, LineItemStaticStatusProps, LineItemStatusState } from './types';

export const getLineItemStaticState = ({
  lineItemStatus,
  subStatus,
}: LineItemStaticStatusProps): Maybe<LineItemStaticStatusData> => {
  if (subStatus && subStatus.key !== UNRECOGNIZED) {
    return {
      displayName: startCase(subStatus.displayName),
      pillColor: derivedStateColorsMap[subStatus.key],
    };
  } else if (lineItemStatus === lineItemStatuses.CANCELLED || lineItemStatus === lineItemStatuses.OFF) {
    return {
      displayName: lineItemStatus.displayName,
      pillColor: lineItemStatus === lineItemStatuses.CANCELLED ? PillColor.RED : null,
    };
  }

  return null;
};

export const getPillColor = ({ subStatus, status }: LineItemStatusState): PillColor | undefined => {
  const isPausedStatus = status.key === lineItemStatuses.PAUSED.key;
  const isLateSubStatus = subStatus?.key === lineItemDerivedState.LATE.key;
  const isDidNotRunSubStatus = subStatus?.key === lineItemDerivedState.DID_NOT_RUN.key;
  const isReadySubStatus = subStatus?.key === lineItemDerivedState.READY.key;
  const isLiveSubStatus = subStatus?.key === lineItemDerivedState.LIVE.key;

  if (isPausedStatus) {
    return PillColor.ORANGE;
  }

  if (isLateSubStatus || isDidNotRunSubStatus) {
    return PillColor.YELLOW;
  }

  if (isReadySubStatus || isLiveSubStatus) {
    return PillColor.GREEN;
  }

  return;
};

export const getSubStatusSelectedValue = ({
  status,
  subStatus,
}: LineItemStatusState): DropdownOption<LineItemStatus | typeof UNRECOGNIZED> => {
  const isLateSubStatus = subStatus?.key === lineItemDerivedState.LATE.key;
  const isPausedStatus = status === lineItemStatuses.PAUSED;
  const isLiveSubStatus = subStatus?.key === lineItemDerivedState.LIVE.key;
  const isReadySubStatus = subStatus?.key === lineItemDerivedState.READY.key;

  if (isLateSubStatus) {
    return { label: subStatus?.key || '', value: lineItemStatuses.PAUSED.key };
  }

  if (isPausedStatus && (isLiveSubStatus || isReadySubStatus)) {
    return { label: lineItemStatuses.PAUSED.key || '', value: status.key };
  }

  return { label: subStatus?.key || '', value: status.key };
};
