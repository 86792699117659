import './SortArrow.scss';

import { IconArrowDown, IconArrowUp } from '@hulu-react-style-components/icons';
import React from 'react';

import bem from '../../utils/bem';
import { SortState } from './constants';

export interface SortArrowProps {
  isSorted: boolean;
  isDescending?: boolean;
  onClick?: () => void;
}

const [block, element] = bem('sort-arrow');

/**
 * This SortArrow will serve specifically for https://jira.hulu.com/browse/AX-1872, the initial work of targeting table.
 * Full implementation for sort arrow will be at https://jira.hulu.com/browse/AX-2144
 * More details for react table sorting, please refer to: https://react-table.tanstack.com/docs/api/useSortBy
 */
function SortArrow({ isSorted, isDescending, onClick }: SortArrowProps): React.JSX.Element {
  const icon = isSorted ? (
    isDescending ? (
      <IconArrowDown />
    ) : (
      <IconArrowUp />
    )
  ) : (
    <div>
      <IconArrowDown />
      <IconArrowUp />
    </div>
  );
  const sortState = isSorted ? (isDescending ? SortState.SORTED_DESC : SortState.SORTED_ASC) : SortState.UNSORTED;

  return (
    <div data-testid={block()} className={block()} onClick={onClick}>
      <div className={element(sortState)}>{icon}</div>
    </div>
  );
}

export default SortArrow;
