import { useQuery } from '@apollo/client';

import type { Query, TranscodeStatusV5 } from '../../../../../apis/graphql';
import type { CreativesBuilderValues } from '../../../../../common/AdForm/CreativeBuilders/types';
import { getAssetIds } from '../../../../../common/AdForm/utils';
import type { PillColor } from '../../../../../common/Pill';
import type { Nullable } from '../../../../../models';
import { GET_ASSETS_BY_IDS } from '../../../hooks/queries';
import { getTranscodePillColor, setLatestTranscodingStatus, setSPReviewPill } from '../helpers';
import { getReviewTask } from './utils';

interface UseAssetIdsForTranscodeStatusReturn {
  loading: boolean;
  spReview?: string;
  transcodePillColor: PillColor | null;
  transcodeStatus: string;
}

export function useAssetIdsForTranscodeStatus(
  creative: Nullable<CreativesBuilderValues>
): UseAssetIdsForTranscodeStatusReturn {
  const assetIds = getAssetIds(creative);

  const { data, loading } = useQuery<Query>(GET_ASSETS_BY_IDS, {
    variables: {
      assetIdList: assetIds,
      paginationOptions: { limit: 1, offset: 0 },
      filterOptions: { creativeIdList: [creative?.id] },
    },
    skip: !assetIds || !assetIds.length || !creative?.id,
  });

  const assets = data?.getAssetsByIDsV5;
  let transcodeStatus: string = '';

  if (assets && assets.length) {
    transcodeStatus = setLatestTranscodingStatus(assets);
  }

  const transcodePillColor = getTranscodePillColor(transcodeStatus as TranscodeStatusV5);
  const spReview = setSPReviewPill(getReviewTask(data?.getReviewTasksV5)?.derivedSpReview);

  return {
    loading,
    spReview,
    transcodePillColor,
    transcodeStatus: transcodeStatus.replace(/_/g, ' '),
  };
}
