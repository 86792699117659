import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

export type CostMethod = keyof typeof costMethodDisplayNames;

const costMethodDisplayNames = {
  FIXED_CPM: enumToTitleCase,
  FLAT: enumToTitleCase,
};

export const costMethods = makeConfig<CostMethod>(costMethodDisplayNames);

export function convertCostMethod(apiValue: string): DisplayEnum<CostMethod> {
  return convertToDisplayEnum(apiValue, costMethods);
}
