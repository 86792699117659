import './Badge.scss';

import React from 'react';

import bem from '../../utils/bem';

export interface BadgeProps {
  text: string;
  as?: 'button' | 'span';
  className?: string;
}

const [block] = bem('badge');

function Badge({ text, className = '', as = 'span' }: BadgeProps): JSX.Element {
  if (as === 'button') return <button className={`${block()} ${className}`}>{text}</button>;

  return <span className={`${block()} ${className}`}>{text}</span>;
}

export default Badge;
