import type { AssetInputV5 } from '../../../../apis/graphql';
import type { AssetExtended } from '../../../../common/_ForCommonLibrary/types/extended';
import type { AdsPageLineItem } from '../../../../common/AdForm/types';
import { getCountryCodesFromAsset } from './getCountryCodesFromAsset';
import { getCountryCodesFromLineItem } from './getCountryCodesFromLineItem';

export function buildAssetInput(lineItem?: AdsPageLineItem, assets?: AssetExtended[]): AssetInputV5 | undefined {
  if (!assets?.length) return;

  const { comments, name, rating, rejectionReasons, type } = assets[0];

  const countriesFromAsset = getCountryCodesFromAsset(assets[0]);
  const countriesFromLineItem = getCountryCodesFromLineItem(lineItem);
  const countryCodes = [...new Set([...countriesFromAsset, ...countriesFromLineItem])];

  return { comments, countryCodes, name, rating, rejectionReasons, type };
}
