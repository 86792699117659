import React, { useEffect, useMemo } from 'react';
import type { Row } from 'react-table';
import ReactTooltip from 'react-tooltip';

import ControlledCheckbox from '../../../../../../../../common/ControlledCheckbox';
import Tooltip from '../../../../../../../../common/Tooltip';
import type { AdProduct, AdType, DisplayEnum } from '../../../../../../../../configs';
import { getPermittedAdTypes } from '../../../../../../../../configs';
import { TYPE_DIVERGENCE_HOVER_MESSAGE } from '../../../../../constants';
import type { DuplicateLineItem } from '../../../../hooks';
import { pickerElement } from '../../constants';

interface TableCellProps {
  row: Row<DuplicateLineItem>;
  adType: DisplayEnum<AdType>;
}

const CheckboxLineItemBasicCell: React.FC<TableCellProps> = ({ adType, row }) => {
  const isAdTypeMatched = useMemo(
    () =>
      getPermittedAdTypes(row.original.adProduct?.key as AdProduct)?.every(
        (lineItemAdType) => lineItemAdType.key !== adType.key
      ),
    [row.original, adType]
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <Tooltip
      id={'disabled-checkbox-tooltip'}
      message={isAdTypeMatched ? TYPE_DIVERGENCE_HOVER_MESSAGE : ''}
      className={pickerElement('name-tooltip')}
      data-testid="tooltip-basic-controlled-checkbox"
      shouldRenderInPortal={false}
    >
      <ControlledCheckbox
        {...row.getToggleRowSelectedProps()}
        title={isAdTypeMatched ? '' : row.getToggleRowSelectedProps().title}
        disabled={isAdTypeMatched}
        className={pickerElement('checkbox')}
        data-testid="line-item-basic-controlled-checkbox"
      />
    </Tooltip>
  );
};

export default CheckboxLineItemBasicCell;
