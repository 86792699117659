import './DatePacing.scss';

import React from 'react';

import type { DateRangeV5 } from '../../../../../apis/graphql';
import bem from '../../../../../utils/bem';
import { formatStringDate } from '../../../../../utils/formatting';
import { formatPacing } from './utils';

interface DatePacingProps {
  dateRangeList: DateRangeV5[];
}

const [block, element] = bem('date-pacing');

function DatePacing({ dateRangeList }: DatePacingProps): JSX.Element {
  return (
    <div className={block()} data-testid={block()}>
      <div className={element('header')}>
        <span>start</span>
        <span>end</span>
        <span>pacing</span>
      </div>
      <div className={element('list')}>
        {dateRangeList.map((date, index) => {
          const pacingValue = formatPacing(date.pacingShare);

          return (
            <React.Fragment key={index}>
              <span data-testid="star-date">{formatStringDate(date.startDate)}</span>
              <span>{date.endDate ? '-' : ''}</span>
              <span data-testid="end-date">{formatStringDate(date.endDate)}</span>
              <span>{pacingValue && 'at'}</span>
              <span data-testid="pacing">{pacingValue}</span>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default DatePacing;
