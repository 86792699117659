import { useMutation } from '@apollo/client';

import type {
  AdV5,
  ApproveLineItemsAndCorrelatedAdsMutation,
  ApproveLineItemsAndCorrelatedAdsMutationVariables,
  LineItemV5,
  OverrideAdsReviewMutation,
  OverrideAdsReviewMutationVariables,
  ResponseError,
} from '../../../../apis/graphql';
import { createGraphQLErrorMap } from '../../../../apis/graphql/utils';
import { AlertType } from '../../../../common/Alert';
import { openToastAlert } from '../../../../common/ToastAlert/toastAlert';
import { TraffickingTableName } from '../../../../constants';
import type { Nullable } from '../../../../models';
import type { FlatRowType } from '../../CreateReviewButton/types';
import type { EntityModel } from '../../TraffickingPage/modelConverters';
import { overrideItemType } from '../OverrideReviewModal/OverrideReviewModal';
import { APPROVE_LINE_ITEMS_AND_CORRELATED_ADS } from './mutations/approveLineItemsAndCorrelatedAds';
import { OVERRIDE_ADS_REVIEW } from './mutations/overrideAdsReview';
import { validateValuesProposedForApproval } from './validation';

const showErrorToast = ({ title, errorMsg }: { title?: string; errorMsg: string }): void => {
  openToastAlert({
    key: 'override-review-toast-error',
    alertType: AlertType.ERROR,
    restrictToPage: false,
    message: title || `Overriding campaign review failed`,
    description: errorMsg,
  });
};

interface OverrideReviewMutationData {
  ads: Pick<AdV5, 'id' | 'review' | 'status'>[];
  lineItems: Pick<LineItemV5, 'id' | 'review' | 'status'>[];
}

interface UseOverrideReviewReturnType {
  loading: boolean;
  overrideReview: (params: {
    selectedFlatRows: FlatRowType<EntityModel>;
    tableName: TraffickingTableName;
  }) => Promise<undefined>;
  data: OverrideReviewMutationData | null | undefined;
}

// normalize returned data from mutation
const normalizeOverrideReviewData = ({
  apiData,
}: {
  apiData: OverrideAdsReviewMutation | ApproveLineItemsAndCorrelatedAdsMutation | null | undefined;
}): OverrideReviewMutationData | null | undefined => {
  if (!apiData) {
    return apiData;
  }

  if ('overrideAdsReviewV5' in apiData) {
    return {
      ads: apiData?.overrideAdsReviewV5,
      lineItems: [],
    };
  } else if ('approveLineItemsAndCorrelatedAdsV5' in apiData) {
    return apiData?.approveLineItemsAndCorrelatedAdsV5;
  }

  return null;
};

const useOverrideReview = (): UseOverrideReviewReturnType => {
  const [overrideAdsReviewV5, { data: approveAdsData, loading: approveAdsIsLoading }] = useMutation<
    OverrideAdsReviewMutation,
    OverrideAdsReviewMutationVariables
  >(OVERRIDE_ADS_REVIEW);

  const [
    approveLineItemsAndCorrelatedAdsV5,
    { data: approveLineItemsData, loading: approveLineItemsIsLoading },
  ] = useMutation<ApproveLineItemsAndCorrelatedAdsMutation, ApproveLineItemsAndCorrelatedAdsMutationVariables>(
    APPROVE_LINE_ITEMS_AND_CORRELATED_ADS
  );

  const overrideReview = async ({
    selectedFlatRows,
    tableName,
  }: {
    selectedFlatRows: FlatRowType<EntityModel>;
    tableName: TraffickingTableName;
  }): Promise<undefined> => {
    const idsList = selectedFlatRows.map((el) => el.id);

    let overrideMutation;

    // only override campaign review for ads or line items
    if (tableName === TraffickingTableName.lineItems) {
      overrideMutation = approveLineItemsAndCorrelatedAdsV5;
    } else if (tableName === TraffickingTableName.ads) {
      overrideMutation = overrideAdsReviewV5;
    }

    if (!idsList.length || !overrideMutation) {
      return;
    }

    const validationErrors = validateValuesProposedForApproval({ selectedFlatRows, tableName });

    if (validationErrors) {
      showErrorToast({
        title: 'Validation Error',
        errorMsg: validationErrors.join(', '),
      });

      return;
    }

    const options = {
      variables: {
        ids: idsList,
      },
      errorPolicy: 'all',
    };

    try {
      const res = await overrideMutation(options);
      const { errors, data } = res;
      let partialErrors: Nullable<Pick<ResponseError, 'id' | 'message'>[]> = null;

      if (data && 'approveLineItemsAndCorrelatedAdsV5' in data) {
        partialErrors = data?.approveLineItemsAndCorrelatedAdsV5?.errors;
      }

      if (errors) {
        const errorMap = createGraphQLErrorMap(errors);
        // some errors are not located on the response but on a message for each error
        const errorMsg = Object.values(errorMap).join(', ') || errors?.map(({ message }) => message).join(', ');

        showErrorToast({ errorMsg });
      } else if (partialErrors?.length) {
        // request partially succeeded
        const errorMsg = [...new Set<string>(partialErrors.map(({ message }) => message))].join(', ');

        openToastAlert({
          key: 'override-review-toast-warning',
          message: 'Alert: Partial Success',
          alertType: AlertType.WARNING,
          description: errorMsg,
          restrictToPage: false,
        });
      } else {
        openToastAlert({
          key: 'override-review-toast-success',
          alertType: AlertType.SUCCESS,
          restrictToPage: false,
          message: `Override Campaign Review Successful`,
          description: `Selected ${overrideItemType[tableName]} were successfully approved`,
        });
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      showErrorToast({ errorMsg: error?.message });
    }
  };

  return {
    loading: approveAdsIsLoading || approveLineItemsIsLoading,
    overrideReview,
    data: normalizeOverrideReviewData({ apiData: approveAdsData || approveLineItemsData }),
  };
};

export default useOverrideReview;
