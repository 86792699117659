import React from 'react';

import { ReviewStatusModal, StatusModal } from '../../../../../../StatusModal';
import type { AssetExtended } from '../../../../../types/extended';
import { AcceptableTranscodingFailureNames } from '../../constants';
import { PositionedAssetStatusPill } from './styles';

export const StatusModalWithPill: React.FC<{
  asset: AssetExtended;
}> = ({ asset }) => {
  const { review, transcodeStatus, ingestionTrial, reviewTask } = asset;

  const pill = (
    <PositionedAssetStatusPill
      spReviewStatus={reviewTask?.spReview}
      reviewStatus={review}
      transcodeStatus={transcodeStatus}
      ingestionStatus={ingestionTrial?.trialStatus}
    />
  );

  const hasTranscodeFailure = AcceptableTranscodingFailureNames.includes(transcodeStatus || '');
  const isPending = asset.review === 'PENDING';

  if (
    ingestionTrial?.trialStatus &&
    ingestionTrial.trialStatus !== 'INGESTING' &&
    ingestionTrial.trialStatus !== 'INGESTION_COMPLETE'
  ) {
    return (
      <StatusModal activator={pill} title="Ingestion Error">
        <p>The follow error occurred during ingestion of this asset:</p>
        <pre>{JSON.stringify(ingestionTrial, undefined, '  ')}</pre>
      </StatusModal>
    );
  }

  return review === 'REJECTED' || hasTranscodeFailure ? (
    <ReviewStatusModal
      asset={asset}
      customActivator={pill}
      status={transcodeStatus || ''}
      rejectionReasons={
        isPending ? 'ASSET PENDING PRE-QC INGESTION. Check back later.' : asset?.transcodeFailureDetails?.message || ''
      }
      name={isPending ? asset?.name : asset?.transcodeFailureDetails?.name || asset.name || ''}
    />
  ) : (
    pill
  );
};
