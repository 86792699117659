import { useCallback, useEffect, useState } from 'react';

import type { DateRange as Range } from '../../../models';
import type { FilterVariables } from '../ChangeLogList/usePagination';
import { endOfDayTime, limitAudits, startOfDayTime } from '../constants';

const initialDateRange: Range = {
  startDate: '',
  endDate: null,
  pacingShare: null,
};

const initialPaginationOptions = {
  offset: 0,
  limit: limitAudits,
};

type Props = {
  id?: string;
  refetch: (variables?: FilterVariables) => void;
  setLoadingFilter: (isLoading: boolean) => void;
  toggleAll: (isLoading: boolean) => void;
};

type FilterResult = {
  dateRange: Range;
  onDateRangeChange: (field: 'start' | 'end', value: string) => void;
  onFilterSubmit: () => void;
  onClearFilter: () => void;
};

export const useDateRangeFilter = ({ refetch, id, setLoadingFilter, toggleAll }: Props): FilterResult => {
  const [dateRange, setDateRange] = useState(initialDateRange);

  useEffect(() => {
    setDateRange(initialDateRange);
  }, [id]);

  const onDateRangeChange = useCallback(
    (field: 'start' | 'end', value: string) => {
      setDateRange((prev) => ({
        ...prev,
        [`${field}Date`]: value ? value : '',
        [`${field}DateTime`]: value ? value : '',
      }));
    },
    [setDateRange]
  );

  const onFilterSubmit = useCallback((): void => {
    setLoadingFilter(true);
    toggleAll(false);

    refetch({
      paginationOptions: initialPaginationOptions,
      filterOptions: {
        dateRange: {
          startDate: `${dateRange.startDate}${startOfDayTime}`,
          endDate: dateRange.endDate ? `${dateRange.endDate}${endOfDayTime}` : null,
        },
      },
    });
  }, [dateRange, refetch, setLoadingFilter, toggleAll]);

  const onClearFilter = useCallback((): void => {
    setLoadingFilter(true);
    toggleAll(false);
    setDateRange(initialDateRange);

    refetch({
      paginationOptions: initialPaginationOptions,
      filterOptions: undefined,
    });
  }, [refetch, setLoadingFilter, toggleAll]);

  return { dateRange, onDateRangeChange, onFilterSubmit, onClearFilter };
};
