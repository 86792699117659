import './DateFilter.scss';

import React, { useMemo, useState } from 'react';

import type { ScheduleFilterInputV5 } from '../../../../apis/graphql';
import type { CalendarDate } from '../../../../common/_ForCommonLibrary/components/molecules/DatePicker';
import Dropdown from '../../../../common/Dropdown';
import type { SingleSelectValue } from '../../../../common/Dropdown/Dropdown';
import { DropdownTypes } from '../../../../common/Dropdown/Dropdown';
import bem from '../../../../utils/bem';
import { Modal } from './CustomDateRange/Modal';
import type { SelectedDateValue } from './helpers';
import { getCustomDateDropdownOption, getSavedDates, getSelectedDropdownOption } from './helpers';
import type { ALL_TIME_DATE_KEY } from './makeGroupedDateOptions';
import makeGroupedDateOptions, { CUSTOM_DATE } from './makeGroupedDateOptions';

export type DateType = ScheduleFilterInputV5 | typeof ALL_TIME_DATE_KEY | typeof CUSTOM_DATE;

export interface IDateRange {
  startDate: CalendarDate;
  endDate: CalendarDate;
  pacingShare?: number | null;
}

const [block] = bem('date-filter');

const dropdownOptions = makeGroupedDateOptions();

type Props = {
  selectedValue?: SelectedDateValue;
  onChange: (v: undefined | SingleSelectValue<DateType>) => void;
};

export const DateFilter = ({ selectedValue, onChange }: Props): React.JSX.Element => {
  const dates = useMemo(() => getSavedDates(selectedValue), [selectedValue]);
  const [dateRange, setDateRange] = useState<IDateRange>({
    startDate: dates?.start || new Date(),
    endDate: dates?.end ?? null,
    pacingShare: null,
  });
  const [isCustomDateOption, setIsCustomDateOption] = useState(false);

  const onChangeHandler = (value: SingleSelectValue<DateType>): void => {
    if (value?.label === CUSTOM_DATE) {
      setIsCustomDateOption(true);

      return;
    }

    setIsCustomDateOption(false);
    onChange(value);
  };

  const isCustomDate = selectedValue?.label === CUSTOM_DATE;
  const dropdownValue = isCustomDate
    ? getCustomDateDropdownOption(dates)
    : getSelectedDropdownOption(dropdownOptions, selectedValue);

  return (
    <div className={block()}>
      <Dropdown<DateType>
        isCustomDateOption={isCustomDateOption}
        options={dropdownOptions}
        value={dropdownValue}
        maxMenuHeight={400}
        type={DropdownTypes.transparent}
        onChange={onChangeHandler}
        label="Date"
      />
      {isCustomDateOption && (
        <Modal
          isCustomDateOption={isCustomDateOption}
          setIsCustomDateOption={setIsCustomDateOption}
          dateRange={dateRange}
          setDateRange={setDateRange}
          onChange={onChange}
          selectedValue={selectedValue}
          activeDatesValue={getCustomDateDropdownOption(dates)}
        />
      )}
    </div>
  );
};
