import { gql } from '@apollo/client';

export const GET_INGESTION_STATUS = gql`
  query getAssetIngestionTracesByCreativeIDs($creativeIds: [String!]!) {
    getAssetIngestionTracesByCreativeIDs(creativeIds: $creativeIds) {
      edges {
        node {
          id
          trials {
            trialStatus
            detail
          }
        }
      }
    }
  }
`;
