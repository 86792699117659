import './Dropdown.scss';

import { IconSearch } from '@hulu-react-style-components/icons';
import React from 'react';
import type { ActionMeta, NamedProps as SelectProps, ValueContainerProps, ValueType } from 'react-select';
import Select, { components } from 'react-select';

import { CUSTOM_DATE } from '../../pages/Trafficking/TableFilterBar/DateFilter';
import bem from '../../utils/bem';
import type { PillColor } from '../Pill';
import type { TooltipProps } from '../Tooltip/Tooltip';
import DropdownButton from './DropdownButton';
import { useDropdown } from './hooks';

const formatGroupLabel = (): null => null;

// A single option in the dropdown
export interface DropdownOption<T = string> {
  id?: string;
  label: string;
  value: T;
  isDisabled?: boolean;
  tooltip?: Omit<TooltipProps, 'children'>;
  customOption?: React.JSX.Element;
}

export interface DropdownOptionGroup<T = string> {
  label: string;
  options: DropdownOption<T>[];
}

// The value of the dropdown
export type SingleSelectValue<T = string> = ValueType<DropdownOption<T>, false>;

export enum DropdownTypes {
  transparent = 'transparent',
}

export interface DropdownProps<T = string> extends SelectProps<DropdownOption<T>, false, DropdownOptionGroup<T>> {
  classNameModifier?: string;
  label?: string;
  help?: string | React.JSX.Element;
  tooltip?: Partial<TooltipProps>;
  secondaryLabel?: string;
  errMsg?: string;
  emptyDisplayText?: string;
  value: SingleSelectValue<T>; // do not allow undefined value
  isLoading?: boolean;
  error?: string;
  dataTestId?: string;
  type?: DropdownTypes;
  onNext?: () => void;
  hasMore?: boolean;
  highlightSelectedValue?: PillColor;
  isCustomDateOption?: boolean;
  optionCompareKey?: 'value' | 'label';
}

const [block, element] = bem('dropdown');
const [listBlock] = bem('dropdown-list');

// we're manually creating a new ValueContainer in order to render a search icon before the input field
// by default from react-select, it comes afterwards
export const CustomValueContainer = <IsMulti extends boolean, T = string>({
  children,
  ...props
}: ValueContainerProps<DropdownOption<T>, IsMulti, DropdownOptionGroup<T>>): JSX.Element => {
  return (
    <components.ValueContainer<DropdownOption<T>, IsMulti, DropdownOptionGroup<T>> {...props}>
      {!!children && <IconSearch className="icon-search" />}
      {children}
    </components.ValueContainer>
  );
};

function getDisplayValue<T = string>(selectedValue: SingleSelectValue<T>): string {
  return selectedValue?.label || '';
}

export const commonCustomComponents = {
  DropdownIndicator: null,
  IndicatorSeparator: null,
  ClearIndicator: null,
  ValueContainer: CustomValueContainer,
};

export const commonSelectProps = {
  autoFocus: true,
  backspaceRemovesValue: false,
  controlShouldRenderValue: false,
  hideSelectedOptions: false,
  menuIsOpen: true,
  placeholder: 'Search',
  tabSelectsValue: false,
  formatGroupLabel,
  classNamePrefix: listBlock(),
};

function Dropdown<T = string>({
  classNameModifier,
  label,
  secondaryLabel,
  errMsg,
  isLoading = false,
  tooltip,
  error = '',
  type,
  components = {
    ...commonCustomComponents,
  },
  isCustomDateOption,
  optionCompareKey = 'value',
  help,
  ...props
}: DropdownProps<T>): JSX.Element {
  const { active, setActive, focusRef, dropdownRef, handleKeyDown } = useDropdown();

  const handleChange = (selectedValue: SingleSelectValue<T>, actionMeta: ActionMeta<DropdownOption<T>>): void => {
    props.onChange && props.onChange(selectedValue, actionMeta);
    setActive(false);
  };

  const isOptionSelected = (option: DropdownOption<T | string>): boolean => {
    if (isCustomDateOption && option.value === CUSTOM_DATE) {
      return true;
    }

    return props.value?.[optionCompareKey] === option[optionCompareKey];
  };

  const modifiers = [];

  if (classNameModifier) {
    modifiers.push(classNameModifier);
  }

  if (type) {
    modifiers.push(type);
  }

  if (active) {
    modifiers.push('active');
  }

  if (!!errMsg) {
    modifiers.push('val-error');
  }

  return (
    <div className={block(modifiers)} ref={dropdownRef}>
      <DropdownButton
        label={label}
        secondaryLabel={secondaryLabel}
        errMsg={errMsg}
        emptyDisplayText={props.emptyDisplayText}
        displayValue={isCustomDateOption ? CUSTOM_DATE : getDisplayValue(props.value)}
        active={active}
        setActive={setActive}
        isDisabled={props.isDisabled}
        dataTestId={props?.dataTestId}
        highlightSelectedValue={props.highlightSelectedValue}
      />
      {/* using react-select for dropdown functionality, since as of 3/17/21, the Trek Dropdown is missing functionality such as search, multi-select, and accessibility */}
      <div
        className={element('container', props.isSearchable === false ? 'not-searchable' : undefined)}
        ref={focusRef}
        onKeyDown={handleKeyDown}
      >
        {active && !isLoading && !error && (
          <Select<DropdownOption<T>, false, DropdownOptionGroup<T>>
            {...commonSelectProps}
            components={components}
            isOptionSelected={isOptionSelected}
            {...props}
            tooltip={tooltip}
            onChange={handleChange}
          />
        )}
        {active && isLoading && <div className={element('loading')}>Loading...</div>}
        {active && error && <div className={element('error')}>An error occurred.</div>}
      </div>
      {help && <div className={element('help-text')}>{help}</div>}
    </div>
  );
}

export default Dropdown;
