import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

export type AssetType = keyof typeof assetTypeDisplayNames;

const assetTypeDisplayNames = {
  DISPLAY: enumToTitleCase,
  VIDEO: enumToTitleCase,
};

export const assetTypes = makeConfig<AssetType>(assetTypeDisplayNames);

// as AssetType[] is necessary since Object.keys always returns string[]
export const validAssetTypes = Object.keys(assetTypes) as AssetType[];

export function convertAssetType(apiValue: string): DisplayEnum<AssetType> {
  return convertToDisplayEnum(apiValue, assetTypes);
}
