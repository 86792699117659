import React from 'react';

const IconPackage = (): React.JSX.Element => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.59386 0.0861884C9.85243 -0.0287295 10.1476 -0.0287295 10.4061 0.0861884L19.4061 4.08619C19.7673 4.24669 20 4.60481 20 5V14C20 14.3632 19.8031 14.6978 19.4856 14.8742L10.4856 19.8742C10.1836 20.0419 9.81638 20.0419 9.51436 19.8742L0.514357 14.8742C0.196892 14.6978 0 14.3632 0 14V5C0 4.60481 0.232734 4.24669 0.593862 4.08619L9.59386 0.0861884ZM7.96221 3L14.5 5.90568L16.5378 5L10 2.09432L7.96221 3ZM18 6.53876L15.5 7.64987V10C15.5 10.5523 15.0523 11 14.5 11C13.9477 11 13.5 10.5523 13.5 10V8.53876L11 9.64987V17.3005L18 13.4116V6.53876ZM9 17.3005V9.64987L2 6.53876V13.4116L9 17.3005ZM3.46221 5L10 7.90568L12.0378 7L5.5 4.09432L3.46221 5Z"
        fill="#000000"
      />
    </svg>
  );
};

export default IconPackage;
