import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import type { Get_Ads_By_Line_Item_IdsQuery } from '../../../../../../../../../../apis/graphql';
import type { LineItemModel } from '../../../../../../../../TraffickingPage/modelConverters';
import type {
  AdEntity,
  MultipleEditDrawerAdsFormValues,
  MultipleEditDrawerLineItemsFormValues,
} from '../../../../../../types';
import { GET_ADS_BY_LINE_ITEM_IDS } from '../../../queries';
import type { UseCheckConflictsParams } from '../../../types';
import { checkStatusAdsConflicts } from './checkStatusAdsConflicts';
import { checkStatusLineItemConflicts } from './checkStatusLineItemConflicts';

export type UseCheckStatusConflictsResult = {
  lineItemStatusConflicts?: {
    lineItemsWithoutActiveAd: Array<LineItemModel['id']>;
    lineItemsWithoutApproved: Array<LineItemModel['id']>;
  };
  lineItemStatusErrorLoading?: boolean;
  adsStatusConflicts?: Record<string, string[]> | null;
};

export const useCheckStatusConflicts = ({
  values,
  isLineItem,
}: UseCheckConflictsParams & {
  values: MultipleEditDrawerAdsFormValues | MultipleEditDrawerLineItemsFormValues;
}): UseCheckStatusConflictsResult => {
  const selectedEntitiesIds = useMemo(() => values.entities.filter((entity) => entity.isSelected).map(({ id }) => id), [
    values.entities,
  ]);
  const selectedEntities = useMemo(() => values.entities.filter(({ id }) => selectedEntitiesIds.includes(id)), [
    selectedEntitiesIds,
    values.entities,
  ]);
  const isStatusSectionSelected = useMemo(() => !!values.selectedSections.includes('Status'), [
    values.selectedSections,
  ]);

  const { data: adsByLineItemsIds, loading } = useQuery<Get_Ads_By_Line_Item_IdsQuery>(GET_ADS_BY_LINE_ITEM_IDS, {
    variables: {
      selectedEntitiesIds,
    },
    skip: !isLineItem || !isStatusSectionSelected,
  });

  return isLineItem
    ? {
        lineItemStatusConflicts: checkStatusLineItemConflicts({
          values: values as MultipleEditDrawerLineItemsFormValues,
          adsByLineItemsIds,
          isStatusSectionSelected,
        }),
        lineItemStatusErrorLoading: loading,
      }
    : {
        adsStatusConflicts: checkStatusAdsConflicts({
          isSectionSelected: isStatusSectionSelected,
          entities: selectedEntities as Array<AdEntity>,
        }),
      };
};
