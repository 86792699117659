import type { RefObject } from 'react';
import { useLayoutEffect } from 'react';
import { useScroll } from 'react-use';

import type { ScrollingBlockMod } from '../types';

type AnimatedBordersContainerProps = {
  children: React.JSX.Element | null;
  scrollRef: RefObject<HTMLDivElement>;
  setScrollModifiers: React.Dispatch<React.SetStateAction<ScrollingBlockMod[]>>;
};

const getMaxScrollXPosition = (ref: RefObject<HTMLDivElement>): number => {
  // using '9999' as the theoretical max width of a div element
  const elementWidth = ref?.current?.scrollWidth || 9999;

  // using '9998' as the theoretical max width of the scrolling pane
  const scrollWidth = ref?.current?.offsetWidth || 9998;

  return elementWidth - scrollWidth;
};

// calcScrollModifiers returns an array of block modifiers for our BEM block() function.
const calcScrollModifiers = (maxScrollXPos: number, scrollXPos: number, scrollYPos: number): ScrollingBlockMod[] => {
  // If the scroll position is greater than 0 (meaning we've scrolled right some), then we
  // include a "left-shadow" modifier.
  // If the scroll position is less than the maximum scroll position (meaning we have not
  // scrolled all the way right), then we include a "right-shadow" modifier.
  return [
    scrollXPos > 0 ? 'left-shadow' : null,
    scrollXPos < maxScrollXPos ? 'right-shadow' : null,
    scrollYPos > 0 ? 'sticky-header' : null,
  ];
};

const AnimatedBordersContainer = ({
  children,
  scrollRef,
  setScrollModifiers,
}: AnimatedBordersContainerProps): React.JSX.Element => {
  const { x: scrollXPosition, y: scrollYPosition } = useScroll(scrollRef);

  useLayoutEffect(() => {
    const maxScrollXPosition = getMaxScrollXPosition(scrollRef);
    setScrollModifiers(calcScrollModifiers(maxScrollXPosition, scrollXPosition, scrollYPosition));
  }, [scrollRef, scrollXPosition, scrollYPosition, setScrollModifiers]);

  return <>{children}</>;
};

export default AnimatedBordersContainer;
