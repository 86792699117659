import type { LazyQueryHookOptions } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';
import { useMemo } from 'react';

import type { GetCreativeByIdQuery, GetCreativeByIdQueryVariables } from '../../../../apis/graphql';
import type { Nullable } from '../../../../models';
import type { CreativesBuilderValues } from '../../CreativeBuilders/types';
import { creativeFragmentToBuilderValues } from '../../hooks/modelConverters';
import { GET_CREATIVE_BY_ID } from './queries';

export function getCreativeByIdToModel(data?: GetCreativeByIdQuery): Nullable<CreativesBuilderValues> {
  if (!data) {
    return null;
  }

  return creativeFragmentToBuilderValues(data.getCreativeByIDV5);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useGetCreativeById = () => {
  const queryOptions: LazyQueryHookOptions<GetCreativeByIdQuery, GetCreativeByIdQueryVariables> = {
    fetchPolicy: 'network-only', // always refetch the creative and update the results object
  };

  const [getCreativeByID, { loading, error, data }] = useLazyQuery<GetCreativeByIdQuery, GetCreativeByIdQueryVariables>(
    GET_CREATIVE_BY_ID,
    queryOptions
  );

  const results = useMemo(() => getCreativeByIdToModel(data), [data]);

  return {
    getCreativeByID,
    loading,
    error,
    results,
  };
};
