import { gql } from '@apollo/client';

export const BULK_CREATE_SEQUENCES_V5 = gql`
  mutation bulkCreateSequencesV5($inputs: [SequenceInput!]!) {
    bulkCreateSequencesV5: bulkCreateSequencesV5(inputs: $inputs) {
      adSequence {
        order
        adId
        name
        sequenceId
        updatedAt
      }
      id
      name
      type
    }
  }
`;
