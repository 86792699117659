import './DatesInfo.scss';

import React, { useEffect, useMemo } from 'react';

import type { ScheduleV5 } from '../../apis/graphql';
import type { Nullable } from '../../models';
import bem from '../../utils/bem';
import { validateDateRanges } from '../../utils/validation';
import { useFieldFast } from '../Form/hooks';
import DatesInfoContent from './DatesInfoContent';
import useDatesInfo from './hooks/useDatesInfo';
import { getLineItemIntervalMessage, lineItemHasNoEndDate } from './utils';

export interface Props {
  lineItemSchedule?: Nullable<ScheduleV5>;
  editMode?: boolean;
  column?: boolean;
  readonly?: boolean;
}

const [block, element] = bem('dates-info');

const DATE_RANGE_LIST_FIELD = 'schedule.dateRangeList';
const SCHEDULE_FIELD = 'schedule.timezone';
const IS_LAST_DATE_RANGE_DISABLED_FIELD = 'isLastDateRangeDisabled';

function DatesInfo({ lineItemSchedule, editMode = true, column = false, readonly }: Props): React.JSX.Element {
  const {
    dateRangeList,
    addDateRange,
    setValidationError,
    validationError,
    removeAt,
    handleDateChange,
    handleToggleDisableLastEndDate,
  } = useDatesInfo(DATE_RANGE_LIST_FIELD);
  const [{ value: dateRangeTimezone }] = useFieldFast<string>(SCHEDULE_FIELD);
  const [, { value: isLastDateRangeDisabled }, { setValue: setIsLastDateRangeDisabled }] = useFieldFast<boolean>(
    IS_LAST_DATE_RANGE_DISABLED_FIELD
  );
  const nullableEndDate = useMemo(() => lineItemHasNoEndDate(lineItemSchedule), [lineItemSchedule]);

  useEffect(() => {
    if (lineItemSchedule) setValidationError(validateDateRanges(lineItemSchedule, dateRangeList));
  }, [dateRangeList, lineItemSchedule, dateRangeTimezone, setValidationError]);

  // Handle cases where bad data is provided
  if (!lineItemSchedule) return <p>Cannot schedule without a Line Item.</p>;

  const message = getLineItemIntervalMessage(lineItemSchedule?.dateRangeList);

  return (
    <div className={block()}>
      <div className={element('message')} data-testid="interval-message">
        {editMode && message}
      </div>
      <DatesInfoContent
        addDateRange={addDateRange}
        nullableEndDate={nullableEndDate}
        dateRangeList={dateRangeList}
        validationError={validationError}
        handleRemove={removeAt}
        handleDateChange={handleDateChange}
        handleToggleDisableLastEndDate={handleToggleDisableLastEndDate}
        editMode={editMode}
        readonly={readonly}
        column={column}
        isLastDateRangeDisabled={isLastDateRangeDisabled}
        setIsLastDateRangeDisabled={setIsLastDateRangeDisabled}
      />
    </div>
  );
}

export default DatesInfo;
