import './NavigationSelectOptions.scss';

import { NavLink } from '@hulu/react-router-dom';
import React from 'react';

import bem from '../../utils/bem';
import type { NavigationItem } from '../Header/Header';
import { forbidWhenNoPermission } from '../Header/utils';

const [block, element] = bem('navigation-select-options');

type Props = { options: NavigationItem[]; toggleOpen: () => void };

const NavigationSelectOptions = ({ options, toggleOpen }: Props): JSX.Element => {
  return (
    <div className={block()} data-testid="navigation-select-options">
      {options
        .filter((option) => !option.isDisabled)
        .map(({ id, pathName, label, hasPermission, isSelected }) => {
          return (
            <span onClick={toggleOpen} key={id}>
              <NavLink
                className={element('option', isSelected ? 'selected' : '')}
                data-testid={`navigation-select-option-${id}`}
                to={pathName}
                onClick={forbidWhenNoPermission(hasPermission)}
              >
                {label}
              </NavLink>
            </span>
          );
        })}
    </div>
  );
};

export default NavigationSelectOptions;
