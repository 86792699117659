import './ColumnToggle.scss';

import { IconDrag } from '@hulu-react-style-components/icons';
import React, { memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import ControlledCheckbox from '../../../../common/ControlledCheckbox';
import bem from '../../../../utils/bem';

const [block, element] = bem('column-toggle');

type Props = {
  columnName: string;
  index: number;
  checked: boolean;
  toggleHiddenColumn: (id: string, value: boolean) => void;
  id: string;
  disabled?: boolean;
  disableDragging?: boolean;
};

const ColumnToggle: React.FC<Props> = ({
  columnName,
  index,
  checked,
  toggleHiddenColumn,
  id,
  disabled = false,
  disableDragging = false,
}) => {
  const toggleColumn = (): void => toggleHiddenColumn(id, !checked);

  return (
    <Draggable draggableId={id} isDragDisabled={disableDragging} index={index}>
      {(provided, snapshot): JSX.Element => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={block(snapshot.isDragging ? 'dragging' : '')}
          data-testid="column-toggle"
        >
          <IconDrag className={element('icon-drag')} />
          <ControlledCheckbox
            onKeyDown={toggleColumn}
            onChange={toggleColumn}
            checked={checked}
            disabled={disabled}
            indeterminate={false}
            data-testid={`column-toggle-${id}`}
          />
          <span data-testid="column-name">{columnName}</span>
        </div>
      )}
    </Draggable>
  );
};

export default memo(ColumnToggle);
