import '../CellItems.scss';

import React from 'react';

import bem from '../../../../../../utils/bem';
import { GreenPlus } from '../../../../../assets';
import type { Action } from '../../CellItem';
import type { CellItemsProps } from '../index';

const [block, element] = bem('multiple-change-log-cell');

interface OrderOMSLinkListProps {
  index: number;
  action: Action;
  id: string;
  type: string;
  url: string;
}

function OrderOMSLinkList({ index, action, id, type, url }: OrderOMSLinkListProps): JSX.Element {
  const removalClass = action.removal ? 'removal' : '';

  return (
    <ul key={index} className={element('oms-link', removalClass)}>
      <li>id: {id}</li>
      {type && <li>type: {type}</li>}
      {url && (
        <li>
          <a title={url} href={url}>
            {url}
          </a>
        </li>
      )}
    </ul>
  );
}

function OrderOMSLinkCell({ changes, action }: CellItemsProps): JSX.Element {
  if (changes && Array.isArray(changes)) {
    return (
      <div className={block()}>
        {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
        {changes.map(({ payload: { id, type, url } }, index) => {
          return (
            <OrderOMSLinkList
              key={`order-oms-link-${index}`}
              index={index}
              action={action}
              id={id}
              type={type}
              url={url}
            />
          );
        })}
      </div>
    );
  }
  if (changes.payload && Array.isArray(changes.payload)) {
    return (
      <div className={block()}>
        {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
        {changes.payload.map(({ id, type, url }, index: number) => {
          return (
            <OrderOMSLinkList
              key={`order-oms-link-${index}`}
              index={index}
              action={action}
              id={id}
              type={type}
              url={url}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div className={block(action.removal ? 'removal' : '')}>
      {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
      <OrderOMSLinkList
        index={0}
        action={action}
        id={changes.payload.id}
        type={changes.payload.type}
        url={changes.payload.url}
      />
    </div>
  );
}

export default OrderOMSLinkCell;
