import { gql } from '@apollo/client';

export const CAMPAIGN_V5_PATCH_RESPONSE_FRAGMENT = gql`
  fragment campaignV5PatchResponse on CampaignV5PatchResponse {
    __typename
    id
    name
    adAccount {
      id
      name
      timezone
      organizationId
    }
    campaignTagList {
      id
      type
      url
      isVerified
    }
    orderOmsLink {
      id
    }
    trackingId
    traffickerEmail
    createdAt
    updatedAt
  }
`;
