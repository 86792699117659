import './RemoveButton.scss';

import { IconDelete } from '@hulu-react-style-components/icons';
import React from 'react';

type Props = {
  onClick: (e: React.MouseEvent) => void;
  arialLabel: string;
  readonly?: boolean;
};

const RemoveButton = ({ onClick, arialLabel, readonly }: Props): JSX.Element => (
  <button type={'button'} data-testid="remove-button" className="delete-btn" disabled={readonly} onClick={onClick}>
    <IconDelete aria-label={arialLabel} />
  </button>
);

export default RemoveButton;
