import './AdListActionButtons.scss';

import { User } from 'mission-control-common-components';
import React from 'react';

import Button from '../../../../common/Button';
import { openToastAlert } from '../../../../common/ToastAlert/toastAlert';
import type { PermissionsNames } from '../../../../constants';
import type { AdListElement } from '../types';

type Props = {
  adList: AdListElement[];
  openSequenceModal: () => void;
  createAd: () => void;
};

const AdListActionButtons = ({ adList, openSequenceModal, createAd }: Props): JSX.Element => {
  const permissions = User.getPermissions<PermissionsNames>();

  const handleUnsavedAlert = (): void => {
    const hasUnsavedChange = adList.find((adListElement) => adListElement.ad.dirty === true || !adListElement.ad.id);

    if (hasUnsavedChange) {
      openToastAlert({
        alertType: 'error',
        message: 'UNSAVED ADS',
        description: 'Please save all the ads in this line item before editing sequence or weight',
      });
    } else {
      openSequenceModal();
    }
  };

  return (
    <span className={'button-group'}>
      <Button
        variant="outlined"
        data-testid="edit-sequence-and-weight-button"
        onClick={handleUnsavedAlert}
        tabIndex={0}
        aria-label="edit sequence"
        disabled={!permissions?.createRotation && !permissions?.deleteRotation}
      >
        Edit Ad Weight
      </Button>
      <Button
        onClick={createAd}
        variant="outlined"
        icon={'IconAdd'}
        tabIndex={0}
        disabled={!permissions?.createAd || adList[0]?.ad.id === ''}
        data-testid="create-draft-ad-button"
      >
        New
      </Button>
    </span>
  );
};

export default AdListActionButtons;
