import gql from 'graphql-tag';

export const GET_LINE_ITEMS_IDS = gql`
  query GET_LINE_ITEMS_IDS($ids: [ID!]!, $limit: Int!) {
    lineItems: lineItemsV5(filterOptions: { campaignIdList: $ids }, paginationOptions: { limit: $limit }) {
      edges {
        node {
          id
        }
      }
    }
  }
`;
