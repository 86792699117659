import { ApolloClient, ApolloQueryResult, FetchResult, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import envConfig from '@hulu/env-config';

import introspectionResult from './introspection';
import { handleGraphQLErrors } from './utils';
import { User } from 'mission-control-common-components';
import { typePolicies } from './typePolicies';
import { sendTokenToWorker } from '../../configs/serviceWorkerInit';

export type GQLError = {
  error: {
    id: string;
    status: string;
    name: string;
    message: string;
    details?: GQLErrorDetail[];
  };
};

export type CampaignClientErrors = {
  [key: string]: (string | GQLError)[];
};

export type GQLErrorDetail = {
  code: string;
  issue: string;
  field?: string;
  value?: string;
  location?: string;
};

const uri = `${envConfig.REACT_APP_CAMPAIGN_API}graphql`;
const isAuthFeatureEnabled: boolean = envConfig.REACT_APP_AUTH_FEATURE_ENABLED === 'true';

const httpLink = new HttpLink({ uri });

const authLink = setContext(async (_, { headers }) => {
  await User.checkIdToken()
    .then(() => {
      sendTokenToWorker(navigator);
    })
    .catch((error) => {
      console.error(error);
    });
    
  const { idToken } = User.getUserInfo();

  if (process.env.DISABLE_AUTH_TOKEN === 'true') return { headers };

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${idToken}`,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map((error) => {
      // TODO: update when we add error handling
      // eslint-disable-next-line no-console
      console.error(`[GraphQL error]: ${JSON.stringify(error, null, 2)}`);
    });
  }

  // TODO: update when we add error handling
  // eslint-disable-next-line no-console
  if (networkError) {
    if (networkError.name === 'MissingRefreshTokenError' || networkError.name === 'CognitoRequestFailedError') {
      console.error(`[Network error]: authentication error: ${networkError}, please retry login through /login`);
    } else {
      console.error(`[Network error]: ${networkError}, ${uri}`);
    }
  }
});

// Resolve Interface and Union types
// https://graphql-code-generator.com/docs/plugins/fragment-matcher
const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies,
});

let links = [errorLink, httpLink];
if (isAuthFeatureEnabled) {
  links = [authLink].concat(links);
}

export const campaignClient = new ApolloClient({
  uri,
  link: from(links),
  cache,
  // On error, include both error messages and partial data
  // https://www.apollographql.com/docs/react/data/error-handling/#graphql-error-policies
  defaultOptions: {
    watchQuery: { errorPolicy: 'all' },
    query: { errorPolicy: 'all' },
    mutate: { errorPolicy: 'all' },
  },
});

const { query, mutate } = campaignClient;

// This is an apply method to be able to catch all errors when doing queries
campaignClient.query = (...args): Promise<ApolloQueryResult<any>> => {
  return query.apply(campaignClient, args).catch((error) => {
    throw new Error(JSON.stringify(handleGraphQLErrors(error)));
  });
};

// This is an apply method to be able to catch all errors when doing mutations
campaignClient.mutate = (...args: any): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> => {
  return mutate.apply(campaignClient, args).catch((error) => {
    throw new Error(JSON.stringify(handleGraphQLErrors(error)));
  });
};

export const campaignClientNoCache = new ApolloClient({
  uri,
  link: from(links),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

const { query: q2, mutate: m2 } = campaignClientNoCache;

// This is an apply method to be able to catch all errors when doing queries
campaignClientNoCache.query = (...args): Promise<ApolloQueryResult<any>> => {
  return q2.apply(campaignClientNoCache, args).catch((error) => {
    throw new Error(JSON.stringify(handleGraphQLErrors(error)));
  });
};

// This is an apply method to be able to catch all errors when doing mutations
campaignClientNoCache.mutate = (...args: any): Promise<FetchResult<any, Record<string, any>, Record<string, any>>> => {
  return m2.apply(campaignClientNoCache, args).catch((error) => {
    throw new Error(JSON.stringify(handleGraphQLErrors(error)));
  });
};
