import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from './types';
import { convertToDisplayEnum, makeConfig } from './utils';

export type Publisher = keyof typeof publisherDisplayNames;

const publisherDisplayNames = {
  DISNEY_PLUS: enumToTitleCase,
  HULU: enumToTitleCase,
};

export const publisher = makeConfig<Publisher>(publisherDisplayNames);

export function convertPublisher(value: string): DisplayEnum<Publisher> {
  return convertToDisplayEnum(value, publisher);
}
