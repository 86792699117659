import { User } from 'mission-control-common-components';
import React from 'react';

export const UserNavLogoutButton = (): React.ReactElement => {
  return (
    <div className="logout-btn" key="logout" onClick={User.logout}>
      Logout
    </div>
  );
};
