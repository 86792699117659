import styled from 'styled-components';

import { theme } from '../../../../styles/styled';

export const SwiperWrapper = styled.div`
  padding: ${theme.constant.gridPx(1)};
  border: 1px solid ${theme.color.design.gray3};
  background-color: ${theme.color.design.gray5};
  max-width: 100%;

  .swiper-slide {
    height: auto;
    padding: ${theme.constant.gridPx(0.5)};
  }

  .swiper-slide > div {
    height: 100%;
  }

  .swiper-button-disabled {
    display: none;
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: calc(50% - 2.1em);
    height: 2em;
    width: 2em;

    background-color: ${theme.color.design.gray4};
    background-repeat: no-repeat;
    background-size: 1.5em;
    background-position: center;

    border: 1px solid ${theme.util.hexToRGB(theme.color.design.gray1, '0.3')};
    border-radius: 1em;
    opacity: 0.8;

    &::after {
      display: none;
    }
  }
  .swiper-button-prev {
    left: 0.9em;
    background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.7071 20.7071C12.3166 21.0976 11.6834 21.0976 11.2929 20.7071L3.29289 12.7071C2.90237 12.3166 2.90237 11.6834 3.29289 11.2929L11.2929 3.29289C11.6834 2.90237 12.3166 2.90237 12.7071 3.29289C13.0976 3.68342 13.0976 4.31658 12.7071 4.70711L6.41421 11L20 11C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13L6.41421 13L12.7071 19.2929C13.0976 19.6834 13.0976 20.3166 12.7071 20.7071Z" fill="%23040405"></path></svg>');
  }
  .swiper-button-next {
    right: 0.9em;
    background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.2929 3.29289C11.6834 2.90237 12.3166 2.90237 12.7071 3.29289L20.7071 11.2929C21.0976 11.6834 21.0976 12.3166 20.7071 12.7071L12.7071 20.7071C12.3166 21.0976 11.6834 21.0976 11.2929 20.7071C10.9024 20.3166 10.9024 19.6834 11.2929 19.2929L17.5858 13H4C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11H17.5858L11.2929 4.70711C10.9024 4.31658 10.9024 3.68342 11.2929 3.29289Z" fill="%23040405"/></svg>');
  }
`;
