import './YodaPacingStatusCell.scss';

import type { FC } from 'react';
import { memo } from 'react';

import type { LineItemYodaPacingStatus } from '../../../../../apis/graphql';
import Pill, { PillColor } from '../../../../../common/Pill';
import bem from '../../../../../utils/bem';

const pillColorsByYodaPacingStatus: { [key in LineItemYodaPacingStatus]: PillColor } = {
  DELIVERED: PillColor.GREEN,
  UNDER_DELIVERED: PillColor.RED,
  UNDER_PACING: PillColor.RED,
  OVER_PACING: PillColor.BLUE,
  ON_PACE: PillColor.GREEN,
  NOT_EVEN: PillColor.RED,
};

type Props = { value: LineItemYodaPacingStatus };

const [block] = bem('wrapper-yoda-pacing-status-cell');

const YodaPacingStatusCell: FC<Props> = ({ value }) => {
  const showPill = value === 'NOT_EVEN';
  return showPill ? (
    <span>N/A</span>
  ) : (
    <div className={block()}>
      <Pill text={value} color={pillColorsByYodaPacingStatus[value]} />
    </div>
  );
};

const comparison = (prev: Props, next: Props): boolean => prev.value === next.value;

export default memo(YodaPacingStatusCell, comparison);
