import './SequenceButton.scss';

import { IconAdd } from '@hulu-react-style-components/icons';
import type { Location } from 'history';
import { bem } from 'mission-control-common-components';
import React from 'react';

import Button from '../../../common/Button';
import useNavigationWithLocalStorage from '../../../common/hooks/useNavigationWithLocalStorage';
import Tooltip from '../../../common/Tooltip';
import { PathName } from '../../../constants';
import type { SequenceLocationState } from '../SequencePage';

const [block, element] = bem('sequence-button');

type SequenceButtonProps = {
  isDisabled: boolean;
  uniqueParentLineOmsLinkIds: string[];
};

export const SEQUENCE_DISABLED_TOOLTIP_MESSAGE =
  'Please select at least one ad that is a part of the sequence you want to update.';

const SequenceButton = ({ isDisabled, uniqueParentLineOmsLinkIds }: SequenceButtonProps): React.JSX.Element => {
  const { navigateWithLocalStorage } = useNavigationWithLocalStorage();

  const locationState: SequenceLocationState = {
    uniqueParentLineOmsLinkIds,
  };

  const location: Location = {
    pathname: PathName.sequence,
    state: {
      locationState,
    },
    key: '',
    search: '',
    hash: '',
  };

  return (
    <Tooltip
      id="create-sequence"
      className={element('tooltip')}
      message={SEQUENCE_DISABLED_TOOLTIP_MESSAGE}
      disable={!isDisabled}
    >
      <Button
        size="medium"
        variant="outlined"
        className={block()}
        disabled={isDisabled}
        aria-label="Create Sequence"
        icon={<IconAdd />}
        onClick={(e): void => navigateWithLocalStorage(e, location, locationState)}
        data-testid="create-sequence-button"
      >
        Create Sequence
      </Button>
    </Tooltip>
  );
};

export default SequenceButton;
