import { useFormikContext } from 'formik';
import React from 'react';

import type { SlateCreativeInputV5 } from '../../../../apis/graphql';
import AssetImporterField from '../../../../common/Form/AssetImporterField';
import type { AdFormValues } from '../../adFormik';
import { isCreativeAssetRemoveForbidden } from '../../utils/creative';
import { CreativeFooter } from '../components/CreativeFooter';
import CreativeBuilder from '../CreativeBuilder';
import type { CreativeBuilderProps } from '../CreativeBuilder/CreativeBuilder';
import { useCreativeData } from '../hooks/useCreativeData';
import type { CreativesBuilderValues } from '../types';

export const constructSlateCreativeInput = ({ name, slate }: CreativesBuilderValues): SlateCreativeInputV5 => {
  const input: SlateCreativeInputV5 = { name, type: 'SLATE' };
  if (slate?.id !== '') input.slateId = slate?.id;

  return input;
};

function SlateCreativeBuilder({ isPreview, adTypeDimensions, editMode }: CreativeBuilderProps): React.JSX.Element {
  const { values: adFormValues } = useFormikContext<AdFormValues>();
  const { creative } = adFormValues;

  const {
    data: { assets },
  } = useCreativeData(creative);

  const hasTranscodeAbort = assets[0]?.transcodeStatus === 'PUBLISHING_ABORT';

  const assetReadonly = isCreativeAssetRemoveForbidden(editMode, creative);

  return (
    <CreativeBuilder classNameModifier="slate" isPreview={isPreview} editMode={editMode}>
      <AssetImporterField
        adTypeDimensions={adTypeDimensions?.video}
        id="asset-uploader"
        formFieldName="creative.slate"
        assetType="VIDEO"
        label="Slate Asset"
        aspectRatio={16 / 9}
        isPreview={isPreview}
        readonly={assetReadonly}
      />
      <CreativeFooter assets={assets} hasTranscodeAbort={hasTranscodeAbort} />
    </CreativeBuilder>
  );
}

export default SlateCreativeBuilder;
