import { DEFAULT_SCHEDULE_OBJECT } from '../../../common/AdForm/hooks/modelConverters';
import type { AdsPageAd } from '../../../common/AdForm/hooks/types';
import { defaultSupportedTimezones } from '../../../common/commonMocks/timezones';
import type { PillProps } from '../../../common/Pill';
import { PillColor } from '../../../common/Pill';
import { adStatuses, UNRECOGNIZED } from '../../../configs';
import type { Ad } from './../../../models/ad';

type AdStatusPillMap = Record<Ad['status']['key'], PillProps>;

export const adStatusPillMap: AdStatusPillMap = {
  ON: { text: 'live', color: PillColor.GREEN },
  OFF: { text: 'not live', color: PillColor.GRAY },
  UNRECOGNIZED: { text: 'unknown status', color: PillColor.RED },
};

export const blankAd: AdsPageAd = {
  name: '',
  status: adStatuses.OFF,
  review: { key: 'APPROVED', displayName: 'Approved' },
  rating: { key: 'CHILD_SAFE', displayName: 'Child Safe' },
  id: '',
  createdAt: '',
  updatedAt: '',
  type: {
    key: UNRECOGNIZED,
    displayName: '',
  },
  creative: null,
  dirty: false,
  frequencyCapList: [],
  viewabilityVendorList: [],
  adRotation: null,
  adTagList: [],
  targetingTermValues: [],
  targetingRule: { id: '', definition: null },
  actualStart: null,
  schedule: DEFAULT_SCHEDULE_OBJECT,
  startDate: null,
  endDate: null,
  lineItem: {
    id: '',
    schedule: {
      timezone: defaultSupportedTimezones.ET,
      dateRangeList: [],
      dayPartList: [],
      deliveryInViewerTime: false,
    },
    parentLineOmsLink: {
      id: '',
    },
  },
  unDeletable: false,
  adSequence: null,
  sequence: null,
};

export const DELETE_CONFIRMATION_MSG = 'Are you sure you want to delete this ad with unsaved changes?';

export const CHANGE_SELECTED_AD_CONFIRMATION_MSG =
  'Are you sure you want to leave this page? Your unsaved changes will be lost.';
