import './FormikStepper.scss';

import type { FormikConfig, FormikErrors, FormikHelpers, FormikTouched, FormikValues } from 'formik';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';

import bem from '../../utils/bem';
import { genericMemo } from '../../utils/genericMemo';
import Button from '../Button';
import type { FormikStepperChildren, FormikStepperChildrenProps } from './types';

const [block, element] = bem('stepper');

export type Props<T extends FormikValues = Record<string, string>> = {
  onCancel?: () => void;
  children: React.ReactElement<FormikStepperChildrenProps>[];
} & FormikConfig<T>;

// This component uses for validation validationSchema prop of current child
const FormikStepper = <T extends FormikValues>({ children, onCancel, ...props }: Props<T>): JSX.Element => {
  const childrenArray = React.Children.toArray(children) as FormikStepperChildren[];
  const [step, setStep] = useState(0);
  const isLastStep = step === childrenArray.length - 1;
  const currentChild = childrenArray[step];
  const currentValidationSchema = currentChild?.props.validationSchema;

  const handleBackStep = (setTouched: (touched: FormikTouched<T>) => void): void => {
    setStep((preStep) => preStep - 1);
    setTouched({});
  };

  const handleNextStep = async (values: T, helpers: FormikHelpers<T>): Promise<void> => {
    if (isLastStep) {
      await props.onSubmit(values, helpers);
      return;
    }
    setStep((preStep) => preStep + 1);
    helpers.resetForm({ values });
  };

  const hasStepActiveErrors = (errors: FormikErrors<T>, touched: FormikTouched<T>, isValid: boolean): boolean =>
    !isValid || Object.keys(touched).some((touchedField) => errors[touchedField]);

  return (
    <Formik<T> {...props} onSubmit={handleNextStep} validationSchema={currentValidationSchema}>
      {({ errors, touched, setTouched, isValid, isSubmitting }): JSX.Element => (
        <Form autoComplete="off" className={block()} data-testid="formik-stepper-form">
          {currentChild}
          <div className={element('buttons')}>
            {step > 0 && (
              <Button onClick={(): void => handleBackStep(setTouched)} data-testid="prev-btn">
                Back
              </Button>
            )}
            <div className={element('buttons__right-block')}>
              <Button
                variant="outlined"
                className={element('cancel-button')}
                data-testid="cancel-btn"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={hasStepActiveErrors(errors, touched, isValid) || isSubmitting}
                className={element('next-submit-button')}
                data-testid="next-submit-btn"
              >
                {isLastStep ? 'Confirm' : 'Next'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default genericMemo(FormikStepper);
