import type { DropdownOption } from '../../../../common/Dropdown';
import type { SearchParamsValuesType } from '../../../../constants';
import type { Nullable } from '../../../../models';

type OptionType = { value: string; label: string };

export type SelectedFiltersValues = Partial<Record<SearchParamsValuesType, Nullable<(string | DropdownOption)[]>>>;

export type MultiDropdownPlaceholder = {
  dropdownButtonPlaceholder: string;
  searchInputPlaceholder: string;
};

export enum AdditionalFilterType {
  CHECKBOX = 'CHECKBOX',
  DROPDOWN = 'DROPDOWN',
  RADIOBUTTON = 'RADIOBUTTON',
  Chip = 'Chip',
}

export type AdditionalFilterDefault = {
  searchParam: SearchParamsValuesType;
  label: string;
};

export type UseOptions = {
  options: OptionType[];
  loading?: boolean;
  error?: string;
  hasMore?: boolean;
  searching?: boolean;
  searchTerm?: string;
  onNext?: () => Promise<void>;
  onResetOptions?: () => void;
  onSearch?: (searchTerm: string) => void;
};

export type AdditionalFilterDropdown = {
  type: AdditionalFilterType.CHECKBOX | AdditionalFilterType.DROPDOWN | AdditionalFilterType.RADIOBUTTON;
  useOptions: () => UseOptions;
  value: string[];
  multiOptionalLabel: string;
  isSearchable?: boolean;
  selectedFilterValues?: (string | DropdownOption)[];
  multiDropdownPlaceholder?: MultiDropdownPlaceholder;
} & AdditionalFilterDefault;

export type AdditionalFilterChip = {
  type: AdditionalFilterType.Chip;
  value: string;
} & AdditionalFilterDefault;

export type AdditionalFilter = AdditionalFilterChip | AdditionalFilterDropdown;

export const DEFAULT_BRAND_DROPDOWN_BUTTON_PLACEHOLDER = 'Select brand(s)';
export const DEFAULT_INDUSTRY_DROPDOWN_BUTTON_PLACEHOLDER = 'Select industry(s)';
export const DEFAULT_AGENCY_DROPDOWN_BUTTON_PLACEHOLDER = 'Select agency(s)';

export const DEFAULT_BRAND_SEARCH_INPUT_PLACEHOLDER = 'Search by brand name';
export const DEFAULT_INDUSTRY_SEARCH_INPUT_PLACEHOLDER = 'Search by industry name';
export const DEFAULT_AGENCY_SEARCH_INPUT_PLACEHOLDER = 'Search by agency name';

export const FILTER_LABELS_WITH_FIXED_ORDER = ['Ad Status', 'Line Item Status', 'Pacing Status'];
