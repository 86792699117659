import type { BaseAssetV5 } from '../../../../../apis/graphql';
import { TranscodeStatusV5 } from '../../../../../apis/graphql';

export function setLatestTranscodingStatus(assets: BaseAssetV5[]): string {
  const transcodeStatuses: string[] = assets.reduce((acc: string[], asset): string[] => {
    if (asset.transcodeStatus === 'STREAMABLE_CHECKING') return acc;
    if (!!asset && !!asset.transcodeStatus) {
      acc.push(asset.transcodeStatus);
    }
    return acc;
  }, []);

  let transcodeStatus = '';

  // Display the status from the latest / most recent stage
  if (transcodeStatuses.includes('PRE_PROCESSING')) transcodeStatus = TranscodeStatusV5.PreProcessing;
  if (transcodeStatuses.includes('PRE_PROCESSING_COMPLETE')) transcodeStatus = TranscodeStatusV5.PreProcessingComplete;
  if (transcodeStatuses.includes('PUBLISHING')) transcodeStatus = TranscodeStatusV5.Publishing;
  if (transcodeStatuses.includes('PUBLISHING_COMPLETE')) transcodeStatus = TranscodeStatusV5.PublishingComplete;
  if (transcodeStatuses.includes('STREAMABLE')) transcodeStatus = TranscodeStatusV5.Streamable;

  // Get error if transcode is still empty and an error exists on the array of statuses
  if (!transcodeStatus && !!transcodeStatuses[0]) transcodeStatus = transcodeStatuses[0] as TranscodeStatusV5;

  return transcodeStatus;
}
