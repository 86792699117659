import type { UpdateAdsPageAdMutation, UpdateAdsPageWithAssetMutation } from '../../../../../apis/graphql';
import { campaignClient } from '../../../../../apis/graphql';
import { updateAdWithNewSchedule } from '../../../../../common/utils';

export function updateAdInCache(
  data?: UpdateAdsPageAdMutation | UpdateAdsPageWithAssetMutation | null,
  adId = ''
): void {
  if (!data) return;

  const updatedDataWithSchedule = updateAdWithNewSchedule({ data });

  campaignClient.cache.modify({
    id: `AdV5:${adId}`,
    fields: {
      startDate: () => updatedDataWithSchedule?.startDate,
      endDate: () => updatedDataWithSchedule?.endDate,
    },
  });
}
