import * as yup from 'yup';

import type { AdSelectorOption } from '../../../models/creativeTypeProperties';

const assetValidationSchema = yup.object({ id: yup.string().required() });
const tagValidationSchema = yup.object({ url: yup.string().required() });
const marqueeDisplayAssetSchema = yup.object({ altText: yup.string().required(), image: assetValidationSchema });

// enforce an id in either "video" or "tag"
const optionallyVastVideo = yup.object().shape(
  {
    video: yup.object().when('tag', {
      is: (tag: { id: string }) => !tag?.id,
      then: assetValidationSchema,
      otherwise: yup.object(),
    }),
    tag: yup.object().when('video', {
      is: (video: { id: string }) => !video?.id,
      then: tagValidationSchema,
      otherwise: yup.object(),
    }),
  },
  [['video', 'tag']]
);

const adSelectorOptionSchema = yup.object({
  image: assetValidationSchema,
  video: assetValidationSchema,
  thumbnailCaption: yup.string().required(),
});

const adSelectorValidationSchema = yup.object().shape(
  {
    headline: yup.string().required(),
    logo: yup.object({
      image: assetValidationSchema,
    }),
    optionA: yup.object().when(['optionB', 'optionC'], {
      is: (optionB: AdSelectorOption, optionC: AdSelectorOption) => {
        return !adSelectorOptionSchema.isValidSync(optionB) || !adSelectorOptionSchema.isValidSync(optionC);
      },
      then: adSelectorOptionSchema,
    }),
    optionB: yup.object().when(['optionA', 'optionC'], {
      is: (optionA: AdSelectorOption, optionC: AdSelectorOption) => {
        return !adSelectorOptionSchema.isValidSync(optionA) || !adSelectorOptionSchema.isValidSync(optionC);
      },
      then: adSelectorOptionSchema,
    }),
    optionC: yup.object().when(['optionA', 'optionB'], {
      is: (optionA: AdSelectorOption, optionB: AdSelectorOption) =>
        !adSelectorOptionSchema.isValidSync(optionA) || !adSelectorOptionSchema.isValidSync(optionB),
      then: adSelectorOptionSchema,
    }),
  },
  [
    ['optionA', 'optionB'],
    ['optionA', 'optionC'],
    ['optionB', 'optionC'],
  ]
);

const besValidationSchema = yup.object({
  copy: yup.string().required(),
  midrollCopy: yup.string().required(),
  buttonText: yup.string().required(),
  posterImage: assetValidationSchema,
  video: assetValidationSchema,
});

const brightlineValidationSchema = yup.object({
  tag: tagValidationSchema,
  template: yup.string().required(),
  featureList: yup.array().min(1),
});

const displayValidationSchema = yup.object({
  image: assetValidationSchema,
  imageAltText: yup.string().required(),
});

const gatewayValidationSchema = yup.object({
  livingRoomCopy: yup.string().required(),
  pushTitle: yup.string().required(),
  pushBody: yup.string().required(),
  destinationUrl: yup.string().required(),
  emailSubject: yup.string().required(),
  emailBody: yup.string().required(),
  image: assetValidationSchema,
  video: assetValidationSchema,
});

const marqueeValidationSchema = yup.object({
  name: yup.string().required(),
  callToAction: yup.string().required(),
  title: yup.string().required(),
  description: yup.string().required(),
  buttonText: yup.string().required(),
  contentAssetId: yup.string().required(),
  accent: yup.string().required(),
  logo: marqueeDisplayAssetSchema,
  poster: marqueeDisplayAssetSchema,
});

const innovidValidationSchema = yup.object({
  tag: tagValidationSchema,
  template: yup.string().required(),
  featureList: yup.array().min(1),
});

const videoValidationSchema = optionallyVastVideo;

const pauseValidationSchema = yup.object({
  image: assetValidationSchema,
  imageAltText: yup.string().required(),
});

const prerollVideoValidationSchema = yup.object({
  slate: assetValidationSchema,
  optionallyVastVideo,
});

const slateValidationSchema = yup.object({
  slate: assetValidationSchema,
});

export const validationSchema = {
  adSelector: adSelectorValidationSchema,
  bes: besValidationSchema,
  brightline: brightlineValidationSchema,
  display: displayValidationSchema,
  gateway: gatewayValidationSchema,
  marquee: marqueeValidationSchema,
  innovid: innovidValidationSchema,
  pause: pauseValidationSchema,
  prerollVideo: prerollVideoValidationSchema,
  slate: slateValidationSchema,
  video: videoValidationSchema,
};
