import './GroupHeader.scss';

import { IconDelete, IconEdit } from '@hulu-react-style-components/icons';
import React, { memo, useState } from 'react';

import bem from '../../../utils/bem';
import SaveInput from '../../SaveInput/SaveInput';

const [block, element] = bem('group-header');

type GroupHeaderProps = {
  name: string;
  id: string;
  newGroupHeader?: boolean;
  forbiddenGroupNames?: string[];
  onSaveName: (id: string, newName: string) => void;
  onDelete: (id: string) => void;
  onStartedEditing: (id: string) => void;
};

function GroupHeader({
  id,
  name,
  newGroupHeader = false,
  onSaveName,
  onDelete,
  forbiddenGroupNames = [],
  onStartedEditing,
}: GroupHeaderProps): JSX.Element {
  const [groupName, setGroupName] = useState<string>(name);
  const [isEditing, setIsEditing] = useState<boolean>(newGroupHeader);
  const deleteActionAllowed = !isEditing || newGroupHeader;
  const deleteButtonModifiers = deleteActionAllowed ? ['delete'] : ['delete', 'disabled'];

  const onSaveGroupName = (): void => {
    onSaveName(id, groupName);
    setIsEditing(false);
  };

  const onDeleteHandler = (): void => {
    if (deleteActionAllowed) {
      onDelete(id);
    }
  };

  const onGroupNameChangeHandler = (value: string): void => {
    setGroupName(value);
  };

  const rotationGroupExists = (): boolean => {
    return forbiddenGroupNames.includes(groupName) && groupName !== name;
  };

  const onEditHandler = (): void => {
    setIsEditing(true);
    onStartedEditing(id);
  };

  return (
    <div className={block()}>
      {!isEditing && (
        <span data-testid="group-header-name" className={element('title')}>
          {groupName}
        </span>
      )}
      {isEditing && (
        <div data-testid="group-header-edited-name" className={element('edit-name-wrapper')}>
          <SaveInput
            value={groupName}
            placeholder={'Rotation'}
            onSave={onSaveGroupName}
            onChange={onGroupNameChangeHandler}
            disabled={rotationGroupExists()}
          />
        </div>
      )}
      <div className={element('icons-wrapper')}>
        {!isEditing && (
          <span data-testid="group-header-edit-icon" onClick={onEditHandler} className={element('icon', 'edit')}>
            <IconEdit />
          </span>
        )}
        <button
          data-testid="group-header-remove-button"
          onClick={onDeleteHandler}
          disabled={!deleteActionAllowed}
          className={element('icon', deleteButtonModifiers)}
        >
          <IconDelete />
        </button>
      </div>
    </div>
  );
}

export default memo(GroupHeader);
