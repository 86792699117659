export const HEADLINE_LABEL = 'Headline';
export const HEADLINE_DEFAULT_VALUE = 'Which ad experience do you prefer?';

export const RANDOM_SEQUENCE_LABEL = 'Random Sequence';
export const RANDOM_SEQUENCE_INFO_TRUTHY = 'Options will be displayed in random order.';
export const RANDOM_SEQUENCE_INFO_FALSY = 'Options will be displayed according to sequence defined below.';

export const VOICEOVER_URL_LABEL = 'Voiceover URL';
export const VOICEOVER_URL_DEFAULT_VALUE = 'https://assetshuluimcom-a.akamaihd.net/ad/voltron/AdSelector_voiceover.mp3';

export const LOGO_LABEL = 'Logo';
export const LOGO_IMAGE_ASSET_LABEL = 'Logo Image Asset';
export const IMAGE_ASPECT_RATIO = 16 / 9;

export const OPTION_LABEL = 'Option';

export const THUMBNAIL_IMAGE_LABEL = 'Thumbnail Image';

export const THUMBNAIL_CAPTION_LABEL = 'Thumbnail Caption';
export const THUMBNAIL_CAPTION_PLACEHOLDER = '25 characters max';
export const THUMBNAIL_CAPTION_CHARACTERS_RESTRICTION = 25;

export const AD_SELECTOR_VIDEO_CREATIVE_LABEL = 'Ad Selector Video Creative';
export const AD_SELECTOR_VIDEO_CREATIVE_HELP_TEXT =
  'Link the MC Ad Selector Video creative that serves with this option.';
export const AD_SELECTOR_VIDEO_CREATIVE_TOOLTIP = 'Video is already assigned to an option.';

export const SEQUENCE_ORDER_LABEL = 'Sequence Order';
export const SEQUENCE_ORDER_HELP_TEXT = 'Order in which each option will be displayed.';
export const SEQUENCE_ORDER_ENABLED_TOOLTIP_MESSAGE = 'Set sequence by dragging and dropping each option in order.';
export const SEQUENCE_ORDER_DISABLED_TOOLTIP_MESSAGE = 'Random Sequence toggle must be disabled to set option order.';
