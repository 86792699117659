import './GatewayGoCreativeBuilder.scss';

import { IconEmail, IconNotificationsDefault } from '@hulu-react-style-components/icons';
import { useFormikContext } from 'formik';
import { bem } from 'mission-control-common-components';
import React, { useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';

import type { GatewayGoCreativeInputV5 as CreativeInput } from '../../../../apis/graphql';
import { CreativeV5Type } from '../../../../apis/graphql';
import TaskTabNavigation from '../../../../common/TaskTabNavigation';
import type { CreativeReviewStatus } from '../../../../configs';
import { AssetImporterField, TextArea, TextField } from '../../../Form';
import Loader from '../../../Loader';
import type { AdFormValues } from '../../adFormik';
import { AdFormCreativeFields } from '../../adFormik/fields';
import { isCreativeAssetRemoveForbidden } from '../../utils/creative';
import { SiteServedFooter, VideoImport } from '../components';
import CreativeSectionWrapper from '../components/CreativeSectionWrapper';
import type { CreativeBuilderProps } from '../CreativeBuilder';
import CreativeBuilder from '../CreativeBuilder';
import { useCreativeData } from '../hooks/useCreativeData';
import type { CreativesBuilderValues } from '../types';
import { CreativeEditMode } from '../types';
import { isTabComplete, isTabFailed } from '../utils';
import {
  DESTINATION_URL_LABEL,
  DESTINATION_URL_PLACEHOLDER,
  EMAIL_BODY_CHARACTERS_RESTRICTION,
  EMAIL_BODY_LABEL,
  EMAIL_BODY_PLACEHOLDER,
  EMAIL_LABEL,
  EMAIL_SUBJECT_CHARACTERS_RESTRICTION,
  EMAIL_SUBJECT_LABEL,
  EMAIL_SUBJECT_PLACEHOLDER,
  IMAGE_ASPECT_RATIO,
  IMAGE_ASSET_LABEL,
  IMAGE_TITLE,
  LIVING_ROOM_CHARACTERS_RESTRICTION,
  LIVING_ROOM_COPY_LABEL,
  LIVING_ROOM_COPY_PLACEHOLDER,
  PUSH_BODY_CHARACTERS_RESTRICTION,
  PUSH_BODY_LABEL,
  PUSH_BODY_PLACEHOLDER,
  PUSH_NOTIFICATION_LABEL,
  PUSH_TITLE_CHARACTERS_RESTRICTION,
  PUSH_TITLE_DEFAULT_VALUE,
  PUSH_TITLE_HELP_TEXT,
  PUSH_TITLE_LABEL,
  VIDEO_TITLE,
} from './constants';

const { TaskTab } = TaskTabNavigation;

const [block, element] = bem('gateway-go-builder');

const CLASS_NAME_MODIFIER = 'gateway-go';

export const constructGatewayGoCreativeInput = ({
  name,
  destinationUrl,
  livingRoomCopy,
  pushTitle,
  pushBody,
  emailSubject,
  emailBody,
  offerImage,
  video,
}: CreativesBuilderValues): CreativeInput => {
  return {
    name,
    destinationUrl,
    livingRoomCopy,
    pushTitle,
    pushBody,
    emailSubject,
    emailBody,
    offerImageId: offerImage?.id,
    videoId: video?.id,
    type: CreativeV5Type.GatewayGo,
  };
};

type TabComponent = {
  isComplete: boolean;
  isFailed: boolean;
  title: string;
  assetId?: string | null;
  element: React.JSX.Element;
};

type TabSelectProps = {
  title: string;
  index: number;
};

const GatewayGoCreativeBuilder = ({
  editMode,
  adTypeDimensions,
  isPreview,
  ...props
}: CreativeBuilderProps): React.JSX.Element => {
  const {
    values: { creative },
    errors,
    setFieldValue,
  } = useFormikContext<AdFormValues>();

  const {
    data: { assets },
    loading: loadingCreativeData,
  } = useCreativeData(creative);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const onTabSelect = ({ index }: TabSelectProps): void => {
    setSelectedTabIndex(index);
  };

  useEffectOnce(() => {
    if (!creative.pushTitle) {
      setFieldValue(AdFormCreativeFields.PushTitle, PUSH_TITLE_DEFAULT_VALUE);
    }
  });

  const isReadonly = editMode === CreativeEditMode.readonly;
  const isAssetReadonly = isCreativeAssetRemoveForbidden(editMode, creative);

  const tabComponents: TabComponent[] = useMemo(
    () => [
      {
        title: IMAGE_TITLE,
        isComplete: isTabComplete(creative.offerImage?.id),
        isFailed: isTabFailed(errors, ['offerImage']),
        assetId: creative.offerImage?.id,
        element: (
          <AssetImporterField
            adTypeDimensions={adTypeDimensions?.display}
            assetType="DISPLAY"
            id="display.image"
            formFieldName={AdFormCreativeFields.OfferImage}
            aspectRatio={IMAGE_ASPECT_RATIO}
            label={IMAGE_ASSET_LABEL}
            isPreview={isPreview}
            readonly={isAssetReadonly}
          />
        ),
      },
      {
        title: VIDEO_TITLE,
        isComplete: isTabComplete(creative.video?.id),
        isFailed: isTabFailed(errors, ['video']),
        assetId: creative.video?.id,
        element: (
          <VideoImport
            builder={{ ...props, editMode, isPreview, adTypeDimensions }}
            allowVast={false}
            readonly={isAssetReadonly}
          />
        ),
      },
    ],
    [adTypeDimensions, creative.offerImage?.id, creative.video?.id, editMode, errors, isAssetReadonly, isPreview, props]
  );

  const selectedAsset = useMemo(() => {
    return assets.find(({ id }) => {
      return id === tabComponents[selectedTabIndex].assetId;
    });
  }, [assets, selectedTabIndex, tabComponents]);

  if (loadingCreativeData) return <Loader />;

  return (
    <CreativeBuilder classNameModifier={CLASS_NAME_MODIFIER} editMode={editMode}>
      <div className={block()} data-testid="gateway-go-template">
        <div className={element('long-text-field')}>
          <TextField
            id="livingRoomCopy"
            formFieldName={AdFormCreativeFields.LivingRoomCopy}
            label={LIVING_ROOM_COPY_LABEL}
            placeholder={LIVING_ROOM_COPY_PLACEHOLDER}
            disabled={isReadonly}
            maxLength={LIVING_ROOM_CHARACTERS_RESTRICTION}
          />
        </div>
        <TextField
          id="destinationUrl"
          formFieldName={AdFormCreativeFields.DestinationUrl}
          label={DESTINATION_URL_LABEL}
          placeholder={DESTINATION_URL_PLACEHOLDER}
          disabled={isReadonly}
        />
        <CreativeSectionWrapper title={PUSH_NOTIFICATION_LABEL} icon={<IconNotificationsDefault />}>
          <div className={element('long-text-field')}>
            <TextField
              id="pushTitle"
              formFieldName={AdFormCreativeFields.PushTitle}
              label={PUSH_TITLE_LABEL}
              placeholder={DESTINATION_URL_PLACEHOLDER}
              disabled={isReadonly}
              help={PUSH_TITLE_HELP_TEXT}
              maxLength={PUSH_TITLE_CHARACTERS_RESTRICTION}
            />
          </div>
          <div className={element('multiline-text-field')}>
            <TextArea
              id="pushBody"
              formFieldName={AdFormCreativeFields.PushBody}
              label={PUSH_BODY_LABEL}
              placeholder={PUSH_BODY_PLACEHOLDER}
              disabled={isReadonly}
              maxLength={PUSH_BODY_CHARACTERS_RESTRICTION}
            />
          </div>
        </CreativeSectionWrapper>
        <CreativeSectionWrapper title={EMAIL_LABEL} icon={<IconEmail />}>
          <div className={element('multiline-text-field')}>
            <TextArea
              id="emailSubject"
              formFieldName={AdFormCreativeFields.EmailSubject}
              label={EMAIL_SUBJECT_LABEL}
              placeholder={EMAIL_SUBJECT_PLACEHOLDER}
              disabled={isReadonly}
              maxLength={EMAIL_SUBJECT_CHARACTERS_RESTRICTION}
            />
          </div>
          <div className={element('multiline-text-field')}>
            <TextArea
              id="emailBody"
              formFieldName={AdFormCreativeFields.EmailBody}
              label={EMAIL_BODY_LABEL}
              placeholder={EMAIL_BODY_PLACEHOLDER}
              disabled={isReadonly}
              maxLength={EMAIL_BODY_CHARACTERS_RESTRICTION}
            />
          </div>
        </CreativeSectionWrapper>
        <TaskTabNavigation selectedIndex={selectedTabIndex} onTabClick={onTabSelect}>
          {tabComponents.map(({ title, isComplete, element }) => (
            <TaskTab key={title} title={title} isComplete={isComplete}>
              {element}
            </TaskTab>
          ))}
        </TaskTabNavigation>
        <SiteServedFooter
          creativeReview={creative.review as CreativeReviewStatus}
          assets={assets}
          selectedAsset={selectedAsset}
          isLoading={loadingCreativeData}
          creativeStatus={creative?.status}
        />
      </div>
    </CreativeBuilder>
  );
};

export default GatewayGoCreativeBuilder;
