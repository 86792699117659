import { User } from 'mission-control-common-components';
import type { FC } from 'react';
import React, { memo } from 'react';
import type { Cell } from 'react-table';

import EditableFrequencyCap from '../../../../../common/EditableTableCells/EditableFrequencyCap';
import { onLineItemFailureUpdate } from '../../../../../common/utils';
import type { PermissionsNames } from '../../../../../constants';
import { cellPropsComparison } from '../../../../../utils/common';
import { getIdFromTableCell } from '../../columns';
import { useUpdateLineItem } from '../../hooks';
import type { LineItemModel } from '../../modelConverters';

type Props = Cell<LineItemModel, LineItemModel['frequencyCapList']>;

const LineItemFrequencyCapCell: FC<Props> = (props) => {
  const permissions = User.getPermissions<PermissionsNames>();
  const { value: frequencyCaps } = props;

  const id = getIdFromTableCell(props);

  const { cellVariant, updateLineItemField } = useUpdateLineItem(id, 'frequencyCapList', onLineItemFailureUpdate);

  return (
    <EditableFrequencyCap
      initialFrequencyCaps={frequencyCaps}
      updateField={updateLineItemField}
      variant={cellVariant}
      defaultFrequencyCapType="LINE_ITEM"
      isDisabled={!permissions?.updateLineItem}
    />
  );
};

export default memo(LineItemFrequencyCapCell, cellPropsComparison);
