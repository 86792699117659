import type { PopulateTargetingValuesInput, PopulateTargetingValuesQuery } from '../../../../../../apis/graphql';
import type { CellChanges } from '../DefaultCellItems';
import type { Term, ValueSet } from './TargetingCell';

export const getValidTermsArray = (terms: Term[] | Term): Term[] => {
  const validTerms: Term[] = [];
  if (Array.isArray(terms)) {
    terms.forEach((term) => {
      if (term.dimension) {
        validTerms.push({
          ...term,
        });
      }
    });
  } else {
    if (terms.dimension) {
      validTerms.push({
        ...terms,
      });
    }
  }

  return validTerms;
};

export const getTermListFromCellChanges = (changes: CellChanges[] | CellChanges): Term[] => {
  const terms: Term[] = [];
  if (Array.isArray(changes)) {
    changes.forEach((change) => {
      terms.push(...getValidTermsArray(change.payload as Term[] | Term));
    });
  } else {
    terms.push(...getValidTermsArray(changes.payload as Term[] | Term));
  }

  return terms;
};

export const getTermValueSet = (term: Term): (ValueSet | string)[] => {
  return term.valueSet || term['value-set'] || [];
};

export const getTargetingValuesToPopulateAndSetTermPreparedValues = (
  termsList: Term[]
): PopulateTargetingValuesInput[] => {
  const targetingsToPopulate: PopulateTargetingValuesInput[] = [];
  termsList.forEach((term) => {
    if (term?.dimension) {
      const termTargetingsToPopulate: PopulateTargetingValuesInput = {
        dimension: term.dimension,
        valueSet: [],
      };

      const valueSet = getTermValueSet(term);

      const eachValueIsString = valueSet.every((value) => typeof value === 'string');
      if (eachValueIsString) {
        term.preparedValues = [];
        valueSet.forEach((value) => {
          if (typeof value === 'string') {
            termTargetingsToPopulate.valueSet.push(value);
            term.preparedValues?.push({
              id: value,
              name: '',
            });
          }
        });
        if (termTargetingsToPopulate.valueSet.length) {
          targetingsToPopulate.push(termTargetingsToPopulate);
        }
      }
    }
  });

  return targetingsToPopulate;
};

export const getValueToShowInCaseDisplayNameWasNotFound = (dimension: string, value: string): string => {
  return dimension !== 'age' ? `${value}_NOT_FOUND` : value;
};

export const prepareTermsForUi = (
  termsList: Term[],
  termsWithValues: PopulateTargetingValuesQuery['populateTargetingValues']
): Term[] => {
  const simplifiedTermsWithValues: Record<string, Record<string, string>> = {};

  termsWithValues.forEach((termsWithValues) => {
    if (!simplifiedTermsWithValues[termsWithValues.dimension]) {
      simplifiedTermsWithValues[termsWithValues.dimension] = {};
    }
    termsWithValues.valueSet.forEach((valueSetItem) => {
      simplifiedTermsWithValues[termsWithValues.dimension][valueSetItem.id] = valueSetItem.displayName;
    });
  });

  return termsList.map((term) => {
    const newTerm: Term = {
      ...term,
      preparedValues: term.preparedValues?.map((preparedValue) => {
        const displayName =
          term.dimension &&
          simplifiedTermsWithValues[term.dimension] &&
          simplifiedTermsWithValues[term.dimension][preparedValue.id]
            ? simplifiedTermsWithValues[term.dimension][preparedValue.id]
            : getValueToShowInCaseDisplayNameWasNotFound(term.dimension || '', preparedValue.id);

        return {
          id: preparedValue.id,
          name: displayName,
        };
      }),
    };

    return newTerm;
  });
};
