import { camelCase } from 'lodash';

import type { ChangeLogUnionObject, DayPartV5, Maybe, SingleValueChange } from '../../../apis/graphql';
import { ChangeAction } from '../../../apis/graphql';
import type { ChangesPrefixes } from '../constants';
import type { Audits } from '../hooks/useChangeLog';
import { restructureData } from '../restructureData';
import type { ChangeLogTableProps, NewChangeLogTableProps } from '../types';
import {
  createSingleChange,
  divideChangesByType,
  getChangesAction,
  hasNestedChanges,
  isLineThrough,
} from './changesUtils';
import { getArrayPropertyWithIndex, getHeaderTitleForArrayChange, getHeaderTitleForPrefixedChange } from './formatters';

export const getPrefixedNestedChangesChangeLogTableProps = (
  nestedChangesPrefix: ChangesPrefixes,
  changeList: SingleValueChange[]
): ChangeLogTableProps => {
  const prefixedNestedChanges = changeList.filter((change) => change.fieldName.includes(nestedChangesPrefix));

  const action = getChangesAction(prefixedNestedChanges);

  return {
    changes: prefixedNestedChanges,
    subHeaderTitle: getHeaderTitleForPrefixedChange(nestedChangesPrefix, action),
    isLineThrough: isLineThrough(action, [ChangeAction.Removal, ChangeAction.Update]),
  };
};

export const getArrayChangeTableProps = (
  changeList: SingleValueChange[],
  object: ChangeLogUnionObject,
  changeFieldName: string
): ChangeLogTableProps => {
  const changes = changeList.map((change) => ({
    ...change,
    // We delete from field name array prefix and index
    fieldName: change.fieldName.replace(changeFieldName + '.', ''),
  }));

  const changesAction = getChangesAction(changeList);

  const { index, property } = getArrayPropertyWithIndex(changeFieldName);

  const arrayChangeFieldName = camelCase(property) as keyof ChangeLogUnionObject;

  const idOfUpdatedEntity =
    index !== undefined && object[arrayChangeFieldName] && object[arrayChangeFieldName][index]?.id;

  // We want to show id of updated array change that's why we add one more change
  // to ignore duplicates of values we need to add field name to ROWS_TO_IGNORE_DUPLICATE const
  if (idOfUpdatedEntity && changesAction === ChangeAction.Update) {
    changes.unshift(createSingleChange('id', ChangeAction.Addition, idOfUpdatedEntity, idOfUpdatedEntity, true));
  }

  return {
    changes,
    subHeaderTitle: getHeaderTitleForArrayChange(arrayChangeFieldName, changesAction),
    isLineThrough: isLineThrough(changesAction, [ChangeAction.Removal, ChangeAction.Update]),
  };
};

export const getDefaultChangesChangeLogTableProps = (
  previousObject: ChangeLogUnionObject | null,
  object: ChangeLogUnionObject,
  changeList?: SingleValueChange[],
  entityType?: string
): ChangeLogTableProps => ({
  changes: changeList ? restructureData(previousObject, object, changeList, entityType) : [],
  subHeaderTitle: '',
  isLineThrough: false,
});

interface Props {
  previousObject: ChangeLogUnionObject | null;
  changeList?: SingleValueChange[];
  object?: ChangeLogUnionObject;
  nestedChangesPrefix?: ChangesPrefixes;
  previousDayPartList?: Maybe<Array<DayPartV5>>;
  node: Audits;
  entityType?: string;
}

export const getChangeLogTablesProps = ({
  previousObject,
  nestedChangesPrefix,
  node,
  entityType,
}: Props): NewChangeLogTableProps => {
  const {
    audits: { notArrayChanges, arrayGroupedChanges },
    creativeAudits,
  } = divideChangesByType(node);

  let auditProps = [];
  let creativeProps = [] as ChangeLogTableProps[];

  if (notArrayChanges.length > 0) {
    if (nestedChangesPrefix && hasNestedChanges(nestedChangesPrefix, notArrayChanges)) {
      auditProps.push(getPrefixedNestedChangesChangeLogTableProps(nestedChangesPrefix, notArrayChanges));
    } else {
      auditProps.push(
        getDefaultChangesChangeLogTableProps(previousObject, node.audits.object, notArrayChanges, entityType)
      );
    }
  }

  let arrayTablesProps = Object.entries(arrayGroupedChanges).map(([propertyName, changes]) =>
    getArrayChangeTableProps(changes, node.audits.object, propertyName)
  );

  auditProps = auditProps.concat(arrayTablesProps);
  creativeAudits.forEach((creative) => {
    if (creative.notArrayChanges.length > 0) {
      if (nestedChangesPrefix && hasNestedChanges(nestedChangesPrefix, creative.notArrayChanges)) {
        creativeProps.push(getPrefixedNestedChangesChangeLogTableProps(nestedChangesPrefix, creative.notArrayChanges));
      } else {
        creativeProps.push(
          getDefaultChangesChangeLogTableProps(previousObject, node.creativeAudits[0].object, creative.notArrayChanges)
        );
      }
    }
  });

  let creativeArrayTablesProps: ChangeLogTableProps[] | undefined;

  if (creativeAudits[0]?.arrayGroupedChanges) {
    creativeArrayTablesProps = Object.entries(creativeAudits[0].arrayGroupedChanges).map(([propertyName, changes]) =>
      getArrayChangeTableProps(changes, node.creativeAudits[0].object, propertyName)
    );
  }

  creativeProps = creativeProps.concat(creativeArrayTablesProps || []).filter(Boolean);

  return { auditProps, creativeProps };
};
