import { isEqual } from 'lodash';

import type { DuplicateAdProps } from './DuplicateAd';

export function arePropsEqual(prev: DuplicateAdProps, next: DuplicateAdProps): boolean {
  const isDisabledEqual = prev.isDisabled === next.isDisabled;
  const areSelectedAdEqual = prev.selectedAd?.id === next.selectedAd?.id;

  const prevLineItemIds = prev.selectedLineItems.map((item) => item.id);
  const nextLineItemIds = next.selectedLineItems.map((item) => item.id);

  const areSelectedLineItemsEqual = isEqual(prevLineItemIds, nextLineItemIds);

  return isDisabledEqual && areSelectedAdEqual && areSelectedLineItemsEqual;
}
