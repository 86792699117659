import './DefaultFormSectionWrapper.scss';

import React, { memo } from 'react';
import { Element } from 'react-scroll';

import bem from '../../utils/bem';
import Card from '../Card';
import Loader from '../Loader';

const [block, element] = bem('default-form-section-wrapper');

type DefaultFormSectionWrapperProps = {
  title: string;
  sectionName: string;
  customTitle?: React.JSX.Element | null;
  children: React.JSX.Element;
  isLoading?: boolean;
};

const DefaultFormSectionWrapper = ({
  title,
  customTitle,
  sectionName,
  children,
  isLoading,
}: DefaultFormSectionWrapperProps): React.JSX.Element => {
  return (
    <Element name={sectionName} className={block()} data-testid="form-section">
      {customTitle ? customTitle : <span className={element('title')}>{title}</span>}
      <Card>
        {isLoading ? (
          <div className={element('loader')}>
            <Loader />
          </div>
        ) : (
          children
        )}
      </Card>
    </Element>
  );
};

export default memo(DefaultFormSectionWrapper);
