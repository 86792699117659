import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { DeselectOptionActionMeta, InputActionMeta, SelectOptionActionMeta } from 'react-select';

import type { EntityFilterOptionV4, UserFilter as UserFilterDropdownOption } from '../../../../apis/graphql';
import { SelectInputAction } from '../../../../common/constants/select';
import { commonCustomComponents, DropdownTypes } from '../../../../common/Dropdown/Dropdown';
import MultiSelectDropdown, {
  ActionTypes,
  isAllSelectedValue,
  isClearAllOption,
  isDeselectAllOptions,
  isSelectAllOption,
} from '../../../../common/MultiSelectDropdown';
import { SELECT_ALL_OPTIONS_VALUE } from '../../../../common/MultiSelectDropdown/MultiSelectContainer/hooks/useMultiSelectContainer';
import type { MultiSelectValue } from '../../../../common/MultiSelectDropdown/MultiSelectDropdown';
import { useFiltersContext } from '../../../../contexts/FilterContext';
import bem from '../../../../utils/bem';
import type { User } from '../hooks';
import type { UseGetUsersParams, UseGetUsersResult } from '../hooks/types';
import MenuList from '../MenuList';

const [block] = bem('user-filter');

const selectAllDropdownOptions = { label: SELECT_ALL_OPTIONS_VALUE, value: SELECT_ALL_OPTIONS_VALUE };

type UserFilterProps = {
  label: string;
  multiOptionLabel?: string;
  selectedValues: UserFilterDropdownOption[];
  applyFilter: (value: UserFilterDropdownOption[]) => void;
  useGetUsers: (params: UseGetUsersParams) => UseGetUsersResult;
  userRole?: string;
  entityFilterOption?: EntityFilterOptionV4;
  entityIdOption?: string;
  valueKey?: keyof User;
};

function UserFilter({
  label,
  selectedValues,
  applyFilter,
  useGetUsers,
  userRole,
  entityFilterOption,
  entityIdOption,
  valueKey = 'id',
  ...restProps
}: UserFilterProps): JSX.Element {
  let params: UseGetUsersParams = undefined;

  if (userRole && entityFilterOption && entityIdOption) {
    params = {
      roleName: userRole,
      entityType: entityFilterOption,
      entityId: entityIdOption,
      selectedIds: isAllSelectedValue(selectedValues)
        ? []
        : selectedValues.map(({ value: selectedValue }) => selectedValue),
      valueKey: valueKey,
    };
  }

  const { userDropdownOptions, loading, error, onNext, resetOptions, searchUsers, hasMore, searchTerm } = useGetUsers(
    params
  );

  const [selectedOptions, setSelectedOptions] = useState<UserFilterDropdownOption[]>(selectedValues);
  const [selectedOptionsDifference, setSelectedOptionsDifference] = useState<UserFilterDropdownOption[]>([]);
  const [isAllSelected, setAllSelected] = useState<boolean>(isAllSelectedValue(selectedValues));

  const { shareableId } = useFiltersContext();

  useEffect(() => {
    if (shareableId) setSelectedOptions(selectedValues);
  }, [selectedValues, shareableId]);

  useEffect(() => {
    const optionsIntersection = selectedOptionsDifference.filter(({ value: optionDifference }) =>
      userDropdownOptions.some(({ value: originalOption }) => originalOption === optionDifference)
    );
    if (optionsIntersection.length !== 0) {
      const updatedOptionsDifference = optionsIntersection.filter(
        ({ value: intersectedOption }) =>
          !selectedOptionsDifference.some(
            ({ value: existingOptionDifference }) => existingOptionDifference === intersectedOption
          )
      );
      setSelectedOptionsDifference(updatedOptionsDifference);
    }
  }, [userDropdownOptions, selectedOptionsDifference]);

  const allDropdownOptions = useMemo(() => {
    return [...userDropdownOptions, ...selectedOptionsDifference];
  }, [userDropdownOptions, selectedOptionsDifference]);

  const selectedDropdownOptions = useMemo(() => {
    return isAllSelected ? allDropdownOptions : selectedOptions;
  }, [isAllSelected, allDropdownOptions, selectedOptions]);

  const handleChange = useCallback(
    (
      dropdownValues: MultiSelectValue,
      meta: SelectOptionActionMeta<UserFilterDropdownOption> | DeselectOptionActionMeta<UserFilterDropdownOption>
    ): void => {
      const optionsExistingDifference = [
        ...selectedOptions.filter(
          ({ value: selectedOption }) =>
            !userDropdownOptions.some(({ value: originalOption }) => originalOption === selectedOption) &&
            !selectedOptionsDifference.some(({ value: optionDifference }) => optionDifference === selectedOption) &&
            selectedOption !== '*'
        ),
      ];
      setSelectedOptionsDifference([...selectedOptionsDifference, ...optionsExistingDifference]);

      if (isClearAllOption(meta) || isDeselectAllOptions(meta)) {
        setSelectedOptions([]);

        return setAllSelected(false);
      }

      if (isSelectAllOption(meta)) {
        return setAllSelected(true);
      }

      if (meta.action === ActionTypes.deselect) {
        setSelectedOptions((existingSelectedOptions) =>
          isAllSelected
            ? [...dropdownValues]
            : existingSelectedOptions.filter(({ value: selectedOption }) => selectedOption !== meta.option?.value)
        );
      }

      if (meta.action === ActionTypes.select && meta.option?.value) {
        const { option } = meta;
        setSelectedOptions((options) => [...new Set([...options, option])]);
      }

      setAllSelected(false);
    },
    [isAllSelected, selectedOptions, selectedOptionsDifference, userDropdownOptions]
  );

  const onClose = useCallback((): void => {
    if (isAllSelected) setSelectedOptionsDifference([]);

    resetOptions();

    const filterValue = isAllSelected
      ? [selectAllDropdownOptions]
      : selectedOptions.filter(({ value: option }) => option !== SELECT_ALL_OPTIONS_VALUE);
    applyFilter(filterValue);
  }, [applyFilter, isAllSelected, resetOptions, selectedOptions]);

  const handleSearch = (value: string, { action }: InputActionMeta): void => {
    if (action === SelectInputAction.InputChange) searchUsers(value);
  };

  const components = useMemo(() => ({ ...commonCustomComponents, MenuList }), []);

  return (
    <div className={block()} data-testid="user-filter">
      <MultiSelectDropdown<string>
        hasMore={hasMore}
        inputValue={searchTerm}
        label={label}
        value={selectedDropdownOptions}
        filterOption={(): boolean => true}
        options={allDropdownOptions}
        loading={loading}
        error={error}
        maxMenuHeight={400}
        classNameModifier={DropdownTypes.transparent}
        onInputChange={handleSearch}
        onChange={handleChange}
        components={components}
        onNext={onNext}
        onClose={onClose}
        {...restProps}
      />
    </div>
  );
}

export default UserFilter;
