import type { FrequencyCap } from '../../../../../../../../../../models';
import type { MultipleEditDrawerLineItemsFormValues } from '../../../../../../types';

type Props = {
  values: MultipleEditDrawerLineItemsFormValues;
  isLineItem: boolean;
};

type ResultValue = Omit<FrequencyCap, 'durationUnit'> & {
  durationUnit: string;
};

type Result = {
  frequency?: {
    id?: string;
    value?: ResultValue[];
  }[];
};

export const getPreparedInputForFrequency = ({ values, isLineItem }: Props): Result => {
  if (!isLineItem || !values.frequencyCapList.length || !values.selectedSections.includes('Frequency')) {
    return {};
  }

  return values.entities.length
    ? {
        frequency: values.entities.map((entity) => ({
          id: entity.id,
          value: values.frequencyCapList.map((freq) => ({
            ...freq,
            durationUnit: freq.durationUnit.key,
          })),
        })),
      }
    : {};
};
