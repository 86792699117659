import type { DisplayEnum, ViewabilityVendor } from '../../../../configs';
import { formatAdStartAndEndDateToCorrectFormat, formatWeightToPercentage } from '../../../../utils/formatting';
import { AdColumn } from '../../constants/ad';
import { getAdSequenceName } from '../../TraffickingPage/columns';
import type { AdModelForExport } from '../convertors/ad';
import type { AdModelValueAccessor } from '../types';
import {
  getAlertMessage,
  getCreatedAt,
  getDisplayPublisherTarget,
  getFrequencyCapList,
  getScheduleTimezoneOffset,
  getTrackingId,
  getUpdatedAt,
} from './commonAccessors';

export const adModelColumnAccessors: AdModelValueAccessor = {
  [AdColumn.Alert]: (ad: AdModelForExport): string => {
    return getAlertMessage({
      hasMisalignedSchedule: !!ad.hasMisalignedSchedule,
      hasMissedLineItem: !ad.lineItem,
      hasLineItemExclusiveBlock: !!ad.lineItem?.inventoryBlockId,
    });
  },
  [AdColumn.Name]: (ad: AdModelForExport): string => ad.name,
  [AdColumn.TrackingId]: getTrackingId,
  [AdColumn.AdID]: (ad: AdModelForExport): string => ad.id,
  [AdColumn.Country]: (ad: AdModelForExport): string => ad.lineItem?.country || '',
  [AdColumn.Type]: (ad: AdModelForExport): string => ad.type.displayName,
  [AdColumn.Status]: (ad: AdModelForExport): string => ad.status.displayName,
  [AdColumn.Review]: (ad: AdModelForExport): string => ad.review.displayName,
  [AdColumn.ScheduleStart]: (ad: AdModelForExport): string =>
    formatAdStartAndEndDateToCorrectFormat(ad.startDate, true) || '',
  [AdColumn.ScheduleEnd]: (ad: AdModelForExport): string =>
    formatAdStartAndEndDateToCorrectFormat(ad.endDate, true) || '',
  [AdColumn.LegalApprovedCreative]: (ad: AdModelForExport): string =>
    ad.creative?.metadata?.isLegalApproved ? 'Yes' : '',
  [AdColumn.DisplayPublisherTarget]: getDisplayPublisherTarget,
  [AdColumn.ScheduleTimezoneOffset]: getScheduleTimezoneOffset,
  [AdColumn.Rating]: (ad: AdModelForExport): string => ad.rating.displayName,
  [AdColumn.Weight]: (ad: AdModelForExport): string =>
    formatWeightToPercentage(ad.adRotation?.weight ? ad.adRotation?.weight : 0),
  [AdColumn.ViewabilityVendorList]: (ad: AdModelForExport): string =>
    ad.viewabilityVendorList.map((vv: DisplayEnum<ViewabilityVendor>) => vv.displayName).join(' '),
  [AdColumn.FrequencyCapList]: getFrequencyCapList,
  [AdColumn.CreatedAt]: getCreatedAt,
  [AdColumn.UpdatedAt]: getUpdatedAt,
  [AdColumn.LineItemName]: (ad: AdModelForExport): string => ad.lineItem?.name || '',
  [AdColumn.LineItemId]: (ad: AdModelForExport): string => ad.lineItem?.id || '',
  [AdColumn.CampaignName]: (ad: AdModelForExport): string => ad.lineItem?.campaign?.name || '',
  [AdColumn.CampaignId]: (ad: AdModelForExport): string => ad.lineItem?.campaign?.id || '',
  [AdColumn.RotationName]: (ad: AdModelForExport): string => ad.adRotation?.rotationName || '',
  [AdColumn.SequenceId]: (ad: AdModelForExport): string => ad.adSequence?.sequenceId || '',
  [AdColumn.SequenceName]: (ad: AdModelForExport): string => getAdSequenceName(ad?.adSequence),
  [AdColumn.SequenceOrder]: (ad: AdModelForExport): string => ad.adSequence?.order.toString() || '',
};
