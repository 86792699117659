import React from 'react';

import { convertAdRating } from '../../../../../../../../../../configs';
import { element } from '../../../components/Row/Row';

type Props = {
  rating: string;
  isShown?: boolean;
};

const RatingRow = ({ rating, isShown }: Props): React.JSX.Element => {
  return (
    <div className={isShown ? element('value-title') : ''}>{isShown ? convertAdRating(rating).displayName : '-'}</div>
  );
};

export default RatingRow;
