import type { Dispatch, SetStateAction } from 'react';
import { useMemo } from 'react';
import type { PluginHook, TableInstance, TableState } from 'react-table';
import { useTable } from 'react-table';

import type { NodeTransformFunction } from '../../../../apis/graphql';
import { getDataFromNodes } from '../../../../apis/graphql';
import { hiddenData, orderData, visibleInitialHiddenColumns } from '../../../../common/TraffickingTable/utils';
import type { TraffickingTableName } from '../../../../constants';
import { useFiltersContext } from '../../../../contexts/FilterContext';
import type { ResultKeys } from '../../constants';
import type { TraffickerTableData } from '../../types';
import { getSelectedRowIdsFromState } from '../../utils';
import type { CustomColumnConfig } from '../columns';
import type { EntityModel } from '../modelConverters';
import {
  getInitialHiddenColumnsWithoutVisibleColumnsIds,
  getInitiallyHiddenColumnIds,
  prepareSortOptionsForTable,
} from './utils';

type TableOptions = {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
};

const getRowId = (row: EntityModel): typeof row['id'] => row['id'];

function useTraffickerTableInstance<NodeType, Model extends EntityModel>(
  loadedData: TraffickerTableData,
  nodesKey: ResultKeys,
  transformFn: NodeTransformFunction<NodeType, Model>,
  columns: CustomColumnConfig<Model>[],
  tableName: TraffickingTableName,
  options: TableOptions,
  plugins: PluginHook<Model>[]
): TableInstance<Model> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tableData = useMemo<Model[]>(getDataFromNodes<NodeType, Model>(loadedData, nodesKey, transformFn), [
    loadedData,
  ]);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const { filters } = useFiltersContext();

  const hiddenColumns = useMemo(() => {
    const hiddenColumnsIds = hiddenData.getStorageData(tableName) || [];
    const initiallyHiddenColumnsIds = getInitiallyHiddenColumnIds(memoizedColumns);
    const shownInitialHiddenColumnsIds = visibleInitialHiddenColumns.getStorageData(tableName) || [];

    return [
      ...hiddenColumnsIds,
      ...getInitialHiddenColumnsWithoutVisibleColumnsIds(initiallyHiddenColumnsIds, shownInitialHiddenColumnsIds),
    ];
  }, [memoizedColumns, tableName]);

  const columnOrder = useMemo(() => orderData.getStorageData(tableName) || [], [tableName]);
  const selectedRows = useMemo(() => getSelectedRowIdsFromState(filters[tableName]?.selectedRowIds || []), [
    filters,
    tableName,
  ]);

  const initialState: Partial<TableState<Model>> = {
    sortBy: prepareSortOptionsForTable(tableName, filters[tableName]?.sortBy),
    selectedRowIds: selectedRows,
    hiddenColumns,
    columnOrder,
  };

  return useTable<Model>(
    {
      columns: memoizedColumns,
      data: tableData,
      manualSortBy: true,
      getRowId,
      initialState,
      ...options,
    },
    ...plugins
  );
}

export default useTraffickerTableInstance;
