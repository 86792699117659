import './BasicSelectOptions.scss';

import React from 'react';

import bem from '../../../../utils/bem';
import type { BasicSelectOptionProps } from '../BasicSelectOption';
import BasicSelectOption from '../BasicSelectOption';

type Props = {
  options: BasicSelectOptionProps[];
  handleSelect: Function;
};

const [block] = bem('basic-select-options');

const BasicSelectOptions = ({ options, handleSelect }: Props): JSX.Element => {
  return (
    <div data-testid="basic-select-options" className={block()}>
      {options
        .filter((option) => !option.isDisabled)
        .map((option) => {
          return (
            <div key={option.id}>
              <BasicSelectOption {...option} handleSelect={handleSelect} />
            </div>
          );
        })}
    </div>
  );
};

export default BasicSelectOptions;
