import type { GetAdsWithTargetingsQuery, TargetingTermValuesFragment } from '../../../../apis/graphql';
import type { Maybe } from '../../../../apis/graphql';
import type { Nullable } from '../../../../models';
import type { AdModel, AssociatedLineItem } from './adConverter';
import { adlineItemNodeToModel, adNodeToModel } from './adConverter';

export type AdWithTargetingsNode = NonNullable<GetAdsWithTargetingsQuery['ads']['edges'][number]>['node'];
export type AssociatedAdWithTargeting = AssociatedLineItem & {
  targetingTermValues: Maybe<Array<{ __typename?: 'TargetingTermValue' } & TargetingTermValuesFragment>>;
};

export type AdWithTargetingsModel = AdModel & {
  targetingTermValues: Array<{ __typename?: 'TargetingTermValue' } & TargetingTermValuesFragment>;
  lineItem: Nullable<AssociatedAdWithTargeting>;
};

export function adWithTargetingsNodeToModel(node: AdWithTargetingsNode): AdWithTargetingsModel {
  const lineItem = adlineItemNodeToModel(node.lineItem);
  return {
    ...adNodeToModel(node),
    lineItem: lineItem
      ? {
          ...lineItem,
          targetingTermValues: node.lineItem?.targetingTermValues || null,
        }
      : null,
    targetingTermValues: node.targetingTermValues,
  };
}
