import { useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import type {
  DeleteRotationsMutation,
  DeleteRotationsMutationVariables,
  Rotation as RawRotation,
  RotationsInput,
  UpdateRotationsMutation,
  UpdateRotationsMutationVariables,
} from '../../../apis/graphql';
import { DELETE_ROTATIONS, UPDATE_ROTATIONS } from '../../../pages/Ads/hooks/mutations/rotations';
import { AlertType } from '../../Alert';
import type { UpdateHandlerProps } from '../../DragDropLists/DragDropLists';
import { openToastAlert } from '../../ToastAlert/toastAlert';
import type { AdListData, AdRotation, Rotation } from '../types';
import {
  getRotationsWithResetErrorMessages,
  getValidateRotationsErrors,
  onUpdateRotationsFailed,
  updateAdRotationCache,
  updateEmptyAdRotationCache,
  updateRotationsCache,
} from '../utils';

export type UseRotationProps = {
  rotationList: Rotation[];
  deleteRotation: (id: string) => void;
  sequenceChanged: (props: UpdateHandlerProps) => void;
  adsWithoutWeightOrSequence: AdRotation[];
  addRotation: () => void;
  changeAdWeight: (adId: string, rotationId: string, newWeight: number) => void;
  forbiddenGroupNames: string[];
  changeRotationName: (id: string, newName: string) => void;
  newRotationGroup: (rotationGroup: Rotation) => boolean;
  saveRotations: () => void;
  loading: boolean;
  rotationNameStartedEditing: (rotationId: string) => void;
  isSaveChangesDisabled: boolean;
};

function useRotations(adListData: AdListData, lineItemId: string): UseRotationProps {
  const [rotationList, setRotationList] = useState<Rotation[]>([]);
  const [adsWithoutWeightOrSequence, setAdsWithoutWeightOrSequence] = useState<AdRotation[]>([]);

  const isSaveChangesDisabled = useMemo(() => {
    return rotationList.findIndex(({ hasError }) => !!hasError) !== -1;
  }, [rotationList]);

  const [updateRotations, { loading: updateRotationsLoading }] = useMutation<
    UpdateRotationsMutation,
    UpdateRotationsMutationVariables
  >(UPDATE_ROTATIONS);

  const [deleteRotations, { loading: deleteRotationsLoading }] = useMutation<
    DeleteRotationsMutation,
    DeleteRotationsMutationVariables
  >(DELETE_ROTATIONS);

  useEffect(() => {
    setRotationList(
      adListData.rotations.map((rotation) => {
        const error = getValidateRotationsErrors(rotation.adsList[0], rotation.adsList);

        return { ...rotation, initialExpanded: true, errorMessage: error, hasError: !!error };
      })
    );

    setAdsWithoutWeightOrSequence(adListData.adsWithoutWeightOrSequence);
  }, [adListData]);

  const forbiddenGroupNames = useMemo(() => rotationList.map((rotationGroup: Rotation) => rotationGroup.name), [
    rotationList,
  ]);

  const newRotationGroup = (rotationGroup: Rotation): boolean => !!(rotationGroup.new && !rotationGroup.saved);

  const changeRotationName = (id: string, newName: string): void => {
    let newRotationList: Rotation[] = getRotationsWithResetErrorMessages(cloneDeep(rotationList));

    const changedRotationGroup = newRotationList.find((rotationGroup: Rotation) => rotationGroup.id === id);
    if (!changedRotationGroup) return;

    if (newRotationGroup(changedRotationGroup)) {
      // If there are conflicts with names, prevent saving the rotation name
      if (!newName || forbiddenGroupNames.includes(newName)) {
        newRotationList = newRotationList.filter((rotationGroup: Rotation) => rotationGroup.id !== id);
        setRotationList(newRotationList);

        return;
      } else {
        changedRotationGroup.saved = true;

        // If it's the only rotation we have, push all unassigned ads
        if (newRotationList.length === 1) {
          changedRotationGroup.adsList.push(...adsWithoutWeightOrSequence);
          setAdsWithoutWeightOrSequence([]);
        }
      }
    }

    if (forbiddenGroupNames.includes(newName)) return;

    // Validate updated rotation
    const changedRotationGroupError = getValidateRotationsErrors(
      changedRotationGroup.adsList[0],
      changedRotationGroup.adsList
    );

    changedRotationGroup.errorMessage = changedRotationGroupError;
    changedRotationGroup.hasError = !!changedRotationGroupError;

    changedRotationGroup.name = newName;
    changedRotationGroup.editing = false;

    setRotationList(newRotationList);
  };

  const deleteRotation = (id: string): void => {
    let newRotationList: Rotation[] = getRotationsWithResetErrorMessages(cloneDeep(rotationList));
    const rotationGroupIndex = newRotationList.findIndex((rotationGroup: Rotation) => rotationGroup.id === id);

    if (rotationGroupIndex === -1) return;

    const deletedRotationGroup: Rotation = newRotationList[rotationGroupIndex];

    // Reset to default weight
    deletedRotationGroup.adsList.forEach((adRotation: AdRotation) => {
      adRotation.weight = 0;
    });

    if (newRotationList.length === 1) {
      // If it is the last rotation and is editing, then it's a new rotation without saved adList
      if (!deletedRotationGroup.editing) setAdsWithoutWeightOrSequence([...deletedRotationGroup.adsList]);

      setRotationList([]);
    } else {
      const elToMoveToIndex =
        rotationGroupIndex + 1 < newRotationList.length ? rotationGroupIndex + 1 : rotationGroupIndex - 1;

      newRotationList[elToMoveToIndex].adsList.push(...deletedRotationGroup.adsList);

      // Validate updated rotation
      const newRotationListError = getValidateRotationsErrors(
        newRotationList[elToMoveToIndex].adsList[0],
        newRotationList[elToMoveToIndex].adsList
      );

      newRotationList[elToMoveToIndex].errorMessage = newRotationListError;
      newRotationList[elToMoveToIndex].hasError = !!newRotationListError;

      newRotationList = newRotationList.filter((rotationGroup: Rotation) => rotationGroup.id !== id);

      setRotationList(newRotationList);
    }
  };

  const changeAdWeight = (adId: string, rotationId: string, newWeight: number): void => {
    const newRotationList = getRotationsWithResetErrorMessages(cloneDeep(rotationList));

    const rotationGroup = newRotationList.find((rotation: Rotation) => rotation.id === rotationId);
    if (!rotationGroup) return;

    const adRotationElement = rotationGroup.adsList.find((adRotation: AdRotation) => adRotation.adId === adId);
    if (!adRotationElement) return;

    adRotationElement.weight = newWeight;

    setRotationList(newRotationList);
  };

  const addRotation = (): void => {
    const newRotationList: Rotation[] = cloneDeep(rotationList);

    const newRotation: Rotation = {
      id: Date.now().toString(),
      name: '',
      adsList: [],
      order: 1,
      new: true,
      saved: false,
      editing: true,
      initialExpanded: true,
      errorMessage: null,
      hasError: false,
    };

    newRotationList.unshift(newRotation);

    setRotationList(newRotationList);
  };

  const sequenceChanged = ({ draggedList, source, destination, draggableId }: UpdateHandlerProps): void => {
    // Preparing the expanded state
    const newRotationList = getRotationsWithResetErrorMessages(cloneDeep(rotationList)).map((rotation) => {
      const correspondingDraggedItem = draggedList.find(({ id }) => id === rotation.id);

      return {
        ...rotation,
        initialExpanded: correspondingDraggedItem
          ? !!correspondingDraggedItem?.initialExpanded
          : rotation.initialExpanded,
      };
    });

    const sourceList = newRotationList.find((entity) => entity.id === source?.droppableId);
    const destinationList = newRotationList.find((entity) => entity.id === destination?.droppableId);
    const draggable = sourceList?.adsList?.find((el) => el.adId === draggableId);

    let destinationError = null;

    // Validate dragged item according to destination list's ads
    if (sourceList && destinationList && source && destination && draggable) {
      destinationError = getValidateRotationsErrors(draggable, destinationList.adsList);
      destinationList.errorMessage = destinationError;
    }

    // If there is no error in destination or drag was made inside the same object,
    // then move  ad
    if (
      (!destinationError || source?.droppableId === destination?.droppableId) &&
      source &&
      destination &&
      draggable &&
      sourceList &&
      destinationList
    ) {
      sourceList?.adsList.splice(source?.index, 1);
      destinationList?.adsList.splice(destination.index, 0, draggable);

      // Validate source object across existing ads, to check, if there are more conflicts
      sourceList.errorMessage = getValidateRotationsErrors(sourceList.adsList[0], sourceList.adsList);
      sourceList.hasError = !!sourceList.errorMessage;
    }

    setRotationList(newRotationList);
  };

  const rotationNameStartedEditing = (rotationId: string): void => {
    const newRotationList = cloneDeep(rotationList);

    const rotationGroup = newRotationList.find((rotation: Rotation) => rotation.id === rotationId);
    if (!rotationGroup) return;
    rotationGroup.editing = true;

    setRotationList(newRotationList);
  };

  const onUpdateRotationsSucceed = (isEmptyRotationSaved: boolean = true, rotations: RawRotation[] = []): void => {
    updateRotationsCache(rotations, lineItemId);

    if (isEmptyRotationSaved) {
      updateEmptyAdRotationCache(adListData);
    } else {
      updateAdRotationCache(rotations);
    }

    openToastAlert({
      alertType: 'success',
      message: `Rotations have been successfully updated!`,
    });
  };

  const saveEmptyRotations = async (): Promise<void> => {
    try {
      const data = await deleteRotations({
        variables: {
          lineItemId,
        },
      });

      if (data && !!data.errors?.length) {
        onUpdateRotationsFailed();
      } else {
        onUpdateRotationsSucceed();
      }
    } catch (error) {
      onUpdateRotationsFailed(error as Error);
    }
  };

  const saveNewRotations = async (): Promise<void> => {
    const rotationsInput: RotationsInput = {
      lineItemId,
      rotationRequestList: rotationList.map((rotation: Rotation, index: number) => {
        return {
          name: rotation.name,
          order: index,
          adRotationList: rotation.adsList.map((adRotation: AdRotation, adRotationIndex: number) => {
            return {
              adId: adRotation.adId,
              weight: adRotation.weight ? adRotation.weight : 1,
              order: adRotationIndex,
            };
          }),
          ...(!rotation.new && { rotationId: rotation.id }),
        };
      }),
    };

    try {
      const data = await updateRotations({
        variables: {
          rotationsInput,
        },
      });

      if (data && !!data.errors?.length) {
        onUpdateRotationsFailed();
      }

      if (data && data.data) {
        onUpdateRotationsSucceed(false, data.data.updateRotationsV5);
      }
    } catch (error) {
      onUpdateRotationsFailed(error as Error);
    }
  };

  const saveRotations = async (): Promise<void> => {
    const someRotationNameIsEditing = rotationList.find((rotation: Rotation) => rotation.editing);
    if (someRotationNameIsEditing) {
      openToastAlert({ alertType: AlertType.ERROR, message: 'Please save all rotation group names' });

      return;
    }

    const someRotationGroupIsEmpty = rotationList.find((rotation: Rotation) => !rotation.adsList.length);
    if (someRotationGroupIsEmpty) {
      openToastAlert({
        alertType: AlertType.ERROR,
        message: 'Each rotation group should have the ad. Please add at least one ad to each group.',
      });

      return;
    }

    rotationList.length ? await saveNewRotations() : await saveEmptyRotations();
  };

  return {
    rotationList,
    deleteRotation,
    sequenceChanged,
    adsWithoutWeightOrSequence,
    addRotation,
    changeAdWeight,
    forbiddenGroupNames,
    changeRotationName,
    newRotationGroup,
    saveRotations,
    loading: updateRotationsLoading || deleteRotationsLoading,
    rotationNameStartedEditing,
    isSaveChangesDisabled,
  };
}

export default useRotations;
