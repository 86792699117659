import { gql } from '@apollo/client';

export const GET_TRAFFICKER_EMAILS = gql`
  query GET_TRAFFICKER_EMAILS($search: String, $paginationOptions: PaginationOptions) {
    getTraffickerEmails(search: $search, paginationOptions: $paginationOptions) {
      edges {
        node {
          id
          email
        }
      }
      total
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
