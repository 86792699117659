import type { ChangeAction } from '../../../apis/graphql';
import { arrayChangesTitles, changeActionToTitleActionMap } from '../constants';

type ArrayPropertyWithIndex = {
  property: string;
  index?: number;
};

export const formatPrefixToHeader = (prefix: string): string => {
  if (!prefix) return '';

  let result = prefix.replace(/-/g, ' ');

  return result[0].toLocaleUpperCase() + result.substring(1);
};

export const getHeaderTitleForPrefixedChange = (prefix: string, action: ChangeAction): string => {
  return [
    formatPrefixToHeader(prefix),
    changeActionToTitleActionMap[action] ? changeActionToTitleActionMap[action] : 'data changed',
  ].join(' ');
};

export const getHeaderTitleForArrayChange = (propertyName: string, action: ChangeAction): string => {
  return [
    arrayChangesTitles[propertyName] || propertyName,
    changeActionToTitleActionMap[action] ? changeActionToTitleActionMap[action] : 'data changed',
  ].join(' ');
};

export const getArrayPropertyWithIndex = (fieldName: string): ArrayPropertyWithIndex => {
  const foundArraySymbols = fieldName.match(/\[\d\]/g);

  let indexSymbol = foundArraySymbols && foundArraySymbols[0][1];

  let index = indexSymbol && +indexSymbol >= 0 ? +indexSymbol : undefined;

  const arrayEndSymbolIndex = fieldName.indexOf('[');
  let property = arrayEndSymbolIndex > 0 ? fieldName.substring(0, arrayEndSymbolIndex) : fieldName;

  return {
    index,
    property,
  };
};

export const getChangesPluralString = (changesLength: number): string =>
  changesLength === 1 ? `${changesLength} change` : `${changesLength} changes`;
