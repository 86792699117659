import './antd-modal-required.scss';
import './ModalPopup.scss';
import 'antd/lib/modal/style/index.css';

import type { ModalProps } from 'antd';
import { Button, Modal } from 'antd';
import React, { useCallback } from 'react';

import bem from '../../utils/bem';

export interface ModalPopupProps {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  modalMessage?: string | JSX.Element;
  modalProps?: ModalProps;
  wrapWithParagraphTag?: boolean;
}

const [block] = bem('modal-popup');

const ModalPopup = ({
  visible,
  onOk,
  onCancel,
  modalProps,
  modalMessage,
  wrapWithParagraphTag = true,
}: ModalPopupProps): JSX.Element | null => {
  // As of 04/12/21, the Modal's `visible` prop is not always correctly unmounting the modal when `false`
  // it could potentially be because of how we're using the `Modal` inside of `ModalPopup`, since this Modal works elsewhere on the page

  const cancelButtonRef = useCallback((node) => {
    if (node !== null) {
      setTimeout(() => {
        node.focus();
      }, 0);
    }
  }, []);

  return visible ? (
    <Modal
      {...modalProps}
      onOk={onOk}
      onCancel={onCancel}
      visible
      className={block()}
      footer={[
        <Button ref={cancelButtonRef} key="back" onClick={onCancel} {...modalProps?.cancelButtonProps}>
          {modalProps?.cancelText}
        </Button>,
        <Button key="submit" type="primary" onClick={onOk} {...modalProps?.okButtonProps}>
          {modalProps?.okText}
        </Button>,
      ]}
    >
      {wrapWithParagraphTag ? <p>{modalMessage}</p> : modalMessage}
    </Modal>
  ) : null;
};

export default ModalPopup;
