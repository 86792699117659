import '../style.scss';

import { useLocation, useNavigate } from '@hulu/react-router-dom';
import { AccountMenu, ProfileMenu } from '@hulu/react-style-components';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { PathName } from '../../../constants';
import { useAuthenticationContext } from '../../../contexts/authenticationContext';
import bem from '../../../utils/bem';
import type { Props } from '../UserNav/UserNav';

const [block] = bem('account-menu');

export const SSOUserNav: React.FC<Props> = ({ isOpen, setIsOpen, setWidthOfModal }) => {
  const { auth: ssoUser } = useAuthenticationContext();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (setWidthOfModal) {
      setWidthOfModal(ref.current ? ref?.current?.offsetWidth : 0);
    }
  }, [setWidthOfModal, isOpen]);

  const logout = useCallback(() => {
    navigate('/logout');
  }, [navigate]);

  const successSSOUserNav = useMemo(() => {
    return (
      ssoUser && (
        <div className={block()} onClick={(): void => setIsOpen(!isOpen)} ref={ref}>
          <AccountMenu firstName={ssoUser.first_name} lastName={ssoUser.last_name} isOpen={isOpen}>
            <ProfileMenu isOpen={isOpen}>
              <div className="logout-btn" key="logout" onClick={logout}>
                Logout
              </div>
            </ProfileMenu>
          </AccountMenu>
        </div>
      )
    );
  }, [ssoUser, isOpen, setIsOpen, logout]);

  if (!ssoUser) {
    if (location.pathname !== PathName.forbidden) navigate(PathName.forbidden);
    return null;
  }

  return successSSOUserNav;
};
