import { useFormikContext } from 'formik';
import React from 'react';

import type { AdSelectorVideoCreativeInputV5 as CreativeInput } from '../../../../apis/graphql';
import { CreativeV5Type } from '../../../../apis/graphql';
import type { CreativeReviewStatus } from '../../../../configs';
import Loader from '../../../Loader';
import type { AdFormValues } from '../../adFormik';
import { isCreativeAssetRemoveForbidden } from '../../utils/creative';
import { SiteServedFooter, VideoImport } from '../components';
import type { CreativeBuilderProps } from '../CreativeBuilder';
import CreativeBuilder from '../CreativeBuilder';
import { useCreativeData } from '../hooks/useCreativeData';
import type { CreativesBuilderValues } from '../types';

const CLASS_NAME_MODIFIER = 'ad-selector-video';

export const constructAdSelectorVideoCreativeInput = ({ name, video }: CreativesBuilderValues): CreativeInput => {
  return { name, type: CreativeV5Type.AdSelectorVideo, videoId: video?.id };
};

const AdSelectorVideoCreativeBuilder = ({
  editMode,
  adTypeDimensions,
  isPreview,
  ...props
}: CreativeBuilderProps): React.JSX.Element => {
  const {
    values: { creative },
  } = useFormikContext<AdFormValues>();

  const {
    data: { assets },
    loading: loadingCreativeData,
  } = useCreativeData(creative);

  if (loadingCreativeData) return <Loader />;

  return (
    <CreativeBuilder classNameModifier={CLASS_NAME_MODIFIER} editMode={editMode}>
      <VideoImport
        builder={{ ...props, editMode, isPreview, adTypeDimensions }}
        allowVast={false}
        readonly={isCreativeAssetRemoveForbidden(editMode, creative)}
      />
      <SiteServedFooter
        creativeReview={creative.review as CreativeReviewStatus}
        assets={assets}
        selectedAsset={assets[0]}
        isLoading={loadingCreativeData}
        creativeStatus={creative?.status}
      />
    </CreativeBuilder>
  );
};

export default AdSelectorVideoCreativeBuilder;
