import './UnsequencedHeader.scss';

import { SearchInput } from '@hulu/react-style-components';
import { bem } from 'mission-control-common-components';
import React from 'react';

import { SEARCH_PLACEHOLDER, UNSEQUENCED_TITLE } from '../constants';
import { useSequenceContext } from '../context';
import { SequenceAction } from '../context/actions';

const [block, element] = bem('unsequenced-header');

const UnsequencedHeader = (): React.JSX.Element => {
  const { sequenceState, sequenceDispatch } = useSequenceContext();
  const { searchUnsequencedAdsTerm } = sequenceState;

  const searchUnsequencedAds = (term: string): void => {
    sequenceDispatch({
      type: SequenceAction.SET_SEARCH_UNSEQUENCED_ADS_TERM,
      payload: term,
    });
  };

  const onClearSearch = (): void => {
    sequenceDispatch({
      type: SequenceAction.SET_SEARCH_UNSEQUENCED_ADS_TERM,
      payload: '',
    });
  };

  return (
    <div className={block()}>
      <div className={element('title')}>{UNSEQUENCED_TITLE}</div>
      <div className={element('search', searchUnsequencedAdsTerm ? 'active' : '')}>
        <SearchInput
          value={searchUnsequencedAdsTerm}
          data-testid="search-input"
          onInput={searchUnsequencedAds}
          onClear={onClearSearch}
          placeholder={SEARCH_PLACEHOLDER}
        />
      </div>
    </div>
  );
};

export default UnsequencedHeader;
