import React from 'react';

import Tooltip from '../../../common/Tooltip';
import type { ActionButtonConfig } from '../TraffickingPage';

export const generateActionButtons = (config: ActionButtonConfig[]): (React.JSX.Element | null)[] =>
  config
    .filter(({ isShown }) => isShown)
    .map(({ id, tooltipLabel, tooltipClassName, component: Component }) => (
      <Tooltip key={id} id={id} message={tooltipLabel} className={tooltipClassName} shouldRenderInPortal={false}>
        {Component}
      </Tooltip>
    ));
