import { gql } from '@apollo/client';

import { TARGETING_RULE_DEFINITION } from '../../../../apis/graphql';
import { CREATIVES_FRAGMENTS } from './creativesFragments';

// Ad on Ads Page
export const ADS_PAGE_AD = gql`
  fragment adsPageAd on AdV5 {
    __typename
    id
    name
    status
    review
    rating
    createdAt
    updatedAt
    type
    startDate
    endDate
    creative {
      ...creatives
    }
    adRotation {
      rotationId
      rotationName
      weight
    }
    adSequence {
      order
      adId
      name
      sequenceId
      updatedAt
    }
    adTagList {
      id
      type
      event
      url
      isVerified
      unDeletable
      isIas
    }
    frequencyCapList {
      type
      limit
      duration
      durationUnit
      enabled
    }
    targetingTermValues {
      category {
        id
        name
        displayName
      }
      dimension {
        id
        name
        displayName
      }
      value {
        id
        name
        displayName
        description
      }
      include
    }
    targetingRule {
      ...targetingRuleDefinition
    }
    lineItem {
      id
      schedule {
        timezone
        dateRangeList {
          startDate
          endDate
          pacingShare
        }
        dayPartList {
          firstActiveHour
          lastActiveHour
          activeDayList
        }
        deliveryInViewerTime
      }
      parentLineOmsLink {
        id
      }
    }
    viewabilityVendorList
    actualStart
    schedule {
      deliveryInViewerTime
      timezone
      dateRangeList {
        startDate
        endDate
        pacingShare
      }
      dayPartList {
        firstActiveHour
        lastActiveHour
        activeDayList
      }
    }
    unDeletable
    sequence
  }
  ${CREATIVES_FRAGMENTS}
  ${TARGETING_RULE_DEFINITION}
`;
