import { Authentication as SSOAuthentication } from '@hulu/react-sso';
import type { Dispatch, SetStateAction } from 'react';
import React from 'react';

import UserNav, { SSOUserNav } from '../UserNav';

interface Auth {
  isAuthEnabled?: boolean;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setWidthOfModal: Dispatch<SetStateAction<number | null>>;
}

export const AuthUserNav = ({ isAuthEnabled, isOpen, setIsOpen, setWidthOfModal }: Auth): React.ReactElement => {
  const CHECK_INTERVAL = 10000;

  return isAuthEnabled ? (
    <UserNav isOpen={isOpen} setIsOpen={setIsOpen} setWidthOfModal={setWidthOfModal} />
  ) : (
    <SSOAuthentication checkInterval={CHECK_INTERVAL}>
      <SSOUserNav isOpen={isOpen} setIsOpen={setIsOpen} setWidthOfModal={setWidthOfModal} />
    </SSOAuthentication>
  );
};
