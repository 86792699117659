import type { ModalProps } from 'antd';
import { bem } from 'mission-control-common-components';
import React, { useState } from 'react';

import ModalPopup from '../../../../../common/ModalPopup';
import RemoveButton from '../../../../../common/RemoveButton';
import type { ListHelpers } from '../../../../../utils/listHelpers';

type DeleteDayPartCellProps = {
  listHelpers: Pick<ListHelpers<null>, 'removeAt'>;
  rowIndex: number;
  readonly?: boolean;
};

const [block] = bem('delete-daypart-cell');

function DeleteDaypartCell({ listHelpers: { removeAt }, rowIndex, readonly }: DeleteDayPartCellProps): JSX.Element {
  const [modalVisible, setModalVisible] = useState(false);

  const modalProps: ModalProps = {
    title: 'Delete Daypart',
    okText: 'Yes, delete daypart',
    cancelText: 'No, do not delete',
  };

  const handleRemoveDaypart = (): void => {
    removeAt(rowIndex);
    setModalVisible(false);
  };

  const openModal = (e: React.MouseEvent): void => {
    e.preventDefault();
    setModalVisible(true);
  };

  return (
    <div className={block()}>
      <RemoveButton arialLabel="remove daypart" onClick={openModal} readonly={readonly} />
      <ModalPopup
        visible={modalVisible}
        onOk={(): void => handleRemoveDaypart()}
        modalProps={modalProps}
        modalMessage="Are you sure you want to remove this daypart?"
        onCancel={(): void => setModalVisible(false)}
      />
    </div>
  );
}

export default DeleteDaypartCell;
