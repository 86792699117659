import './Loader.scss';

import React from 'react';

import { Loader as loaderSvg } from '../assets';

interface Props {
  text?: string;
  className?: string;
}

function Loader({ text, className = '' }: Props): JSX.Element {
  return (
    <span className={`loading-content ${className}`} data-testid="loader">
      <img className="loading" src={loaderSvg} alt="spinner" />
      {text && <span className="loading-text">{text}</span>}
    </span>
  );
}

export default Loader;
