import '../CellItems.scss';

import React from 'react';

import bem from '../../../../../../utils/bem';
import { GreenPlus } from '../../../../../assets';
import type { CellItemsProps } from '../index';

type ChangesByDimension = {
  inrangeSet: string[];
  exrangeSet: string[];
  includeSet: string[];
  excludeSet: string[];
};

type DimensionMap = {
  [key: string]: ChangesByDimension;
};

const [block, element] = bem('multiple-change-log-cell');

const getIncludeListItem = (item: string): JSX.Element => {
  let string = `Include: ${item}`;
  return <li key={item}>{string}</li>;
};

const getExcludeListItem = (item: string): JSX.Element => {
  let string = 'Exclude: ';

  if (item.toLowerCase().endsWith('exclusion')) {
    let formattedItem = item.slice(0, -10);
    string = string + formattedItem;
  }

  return <li key={item}>{string}</li>;
};

function TargetingRulesCell({ changes, action }: CellItemsProps): JSX.Element {
  const removalClass = action.removal ? 'removal' : '';
  return (
    <>
      {changes && !Array.isArray(changes) ? (
        changes.payload.filterList.map(({ dimensionMap }: DimensionMap, index: number) => {
          const dimensionName = Object.keys(dimensionMap)[0];
          // dimension, typed as ChangesByDimension, includes the four string arrays
          // plus a property for the dimension display name that looks like dimensionName: "Dimension Name"
          const dimension = (Object.values(dimensionMap)[0] as unknown) as ChangesByDimension & {
            [key: string]: string;
          };

          return dimensionMap ? (
            <div className={`${block(removalClass)} ${element('targeting-rules')}`} key={`${dimensionName}-${index}`}>
              {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
              <ul>
                {/* this is how the dimension display name is accessed */}
                <li>{dimension[dimensionName]}</li>
                {dimension.includeSet.length > 0 &&
                  dimension.includeSet.map((item: string) => getIncludeListItem(item))}
                {dimension.excludeSet.length > 0 &&
                  dimension.excludeSet.map((item: string) => getExcludeListItem(item))}
              </ul>
            </div>
          ) : (
            <span>-</span>
          );
        })
      ) : (
        <span>-</span>
      )}
    </>
  );
}

export default TargetingRulesCell;
