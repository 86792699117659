import './OptionHeader.scss';

import { IconDrag } from '@hulu-react-style-components/icons';
import { bem } from 'mission-control-common-components';
import type { MouseEventHandler } from 'react';

import TodoItem from '../../../../TodoItem';
import Tooltip from '../../../../Tooltip';

type OptionHeaderProps = {
  tooltipMessage?: string;
  tooltipId?: string;
  label: string;
  isComplete?: boolean;
  isFailed?: boolean;
  isDragDisabled?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
};

const [block, element] = bem('option-header');

const OptionHeader = ({
  tooltipId,
  tooltipMessage,
  label,
  isDragDisabled,
  className,
  onClick = (): void => {},
  isComplete,
  isFailed,
}: OptionHeaderProps): React.JSX.Element => {
  return (
    <Tooltip delayShow={500} id={tooltipId ?? ''} message={tooltipMessage ?? ''} disable={isDragDisabled}>
      <span onClick={onClick} className={`${block()} ${className}`}>
        {!isDragDisabled ? <IconDrag data-testid="icon-drag" className={element('icon-drag')} /> : null}
        <TodoItem isComplete={!!isComplete} text={label} isFailed={isFailed} />
      </span>
    </Tooltip>
  );
};

export default OptionHeader;
