import './ServiceDownPage.scss';

import React from 'react';

import { MISSION_CONTROL_SUPPORT_EMAIL } from '../../constants';

const ServiceDownPage = (): JSX.Element => (
  <div className="service-down-page">
    <h1>Seems like the service is down</h1>
    <br />
    Please try again later or contact support team:
    <br />
    <br />
    <a href={`mailto: ${MISSION_CONTROL_SUPPORT_EMAIL}`}>{MISSION_CONTROL_SUPPORT_EMAIL}</a>
  </div>
);

export default ServiceDownPage;
