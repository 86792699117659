import type { MultiEditAdTagInput } from '../../../../../../../../../../apis/graphql';
import type { PrepareAdsInputProps } from '../../../types';

type Result = {
  tags?: Array<MultiEditAdTagInput>;
};

export const getPreparedInputForTags = ({ values, conflictIds }: PrepareAdsInputProps): Result => {
  const { entities, adTagList, selectedSections } = values;
  if (!entities.length || !adTagList.length || !selectedSections.includes('Tags')) {
    return {};
  }

  return {
    tags: entities
      .filter(({ id }) => !conflictIds?.includes(id))
      .map(({ id }) => {
        return adTagList.reduce((result, { type, event, url, isVerified, unDeletable, isIas }) => {
          if (type.key !== 'UNRECOGNIZED' && event.key !== 'UNRECOGNIZED') {
            result.push({
              id,
              value: {
                type: type.key,
                event: event.key,
                url,
                isVerified: !!isVerified,
                unDeletable,
                isIas,
              },
            });
          }

          return result;
        }, [] as MultiEditAdTagInput[]);
      })
      .flat(),
  };
};
