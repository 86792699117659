import './EntitySearch.scss';

import type { ApolloError } from '@apollo/client';
import { SearchField, SearchResultItem, SearchResults } from '@hulu/react-style-components';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import Highlighter from 'react-highlight-words';

import bem, { withOptionalClassName } from '../../utils/bem';
import Alert, { AlertType } from '../Alert';
import LoaderSvg from '../assets/Loader.svg';
import { SearchResultsWrapper } from './SearchResultsWrapper';

interface BaseEntity {
  id: string;
  name: string;
}

interface SearchHookResults<T extends BaseEntity> {
  error?: ApolloError;
  hasMore?: boolean;
  loading: boolean;
  onNext?: () => void;
  results: T[]; // return empty array if no results are returned by API
}

export interface EntitySearchProps<T extends BaseEntity> {
  apiResultsArePaginated: boolean; // API paginates results. If false, limit the number of results displayed in the component
  className?: string;
  expandedEntityDetails?: React.FC<T>;
  hasPagination?: boolean;
  label: string;
  maxSearchResults: number; // Limit displayed search results and display a helpful message
  onSelect: (entity: T) => void;
  readonly?: boolean;
  searchHook: (query: string) => SearchHookResults<T>;
  style?: React.CSSProperties;
}

const [block, element] = bem('entity-search');

const DEBOUNCE_DURATION_MS = 250;

function EntitySearch<T extends BaseEntity>({
  apiResultsArePaginated,
  className,
  expandedEntityDetails,
  hasPagination,
  label,
  maxSearchResults,
  onSelect,
  readonly,
  searchHook,
  style,
}: EntitySearchProps<T>): JSX.Element {
  const [query, setQuery] = useState('');

  const { loading, error, results, hasMore, onNext } = searchHook(query);

  const numResults = results.length;
  const maxSearchResultsScrolled = Boolean(numResults && !hasMore);
  const maxSearchResultsReached = hasPagination ? maxSearchResultsScrolled : numResults >= maxSearchResults;
  const searchResults = hasPagination ? results : results.slice(0, maxSearchResults);

  return (
    <div className={withOptionalClassName(block(), className)} style={style} data-testid="entity-search">
      <SearchField
        label={label}
        className={element('input')}
        onInput={debounce((input) => setQuery(input.trim()), DEBOUNCE_DURATION_MS)}
        placeholder="Search"
        disabled={readonly}
      />
      {loading && <img className={element('loading-spinner')} src={LoaderSvg} alt="loading spinner" />}
      <SearchResults isOpen={!loading && !!query} id="scrollableDiv">
        <SearchResultsWrapper
          hasMore={hasMore}
          hasPagination={hasPagination}
          loaderClass={element('loader')}
          numResults={numResults}
          onNext={onNext}
        >
          <>
            {searchResults.map((entity) => (
              <SearchResultItem key={entity.id} onClick={(): void => onSelect(entity)}>
                <Highlighter
                  highlightClassName={element('search-term')}
                  searchWords={[query]}
                  autoEscape={true}
                  textToHighlight={entity.name}
                />
                {expandedEntityDetails && expandedEntityDetails(entity)}
              </SearchResultItem>
            ))}
            {!searchResults?.length && (
              <SearchResultItem className={element('no-results-prompt')}>Sorry, no results found.</SearchResultItem>
            )}
            {maxSearchResultsReached && (
              <SearchResultItem className={element('max-results-prompt')}>
                {apiResultsArePaginated
                  ? 'More results not displayed. '
                  : `${numResults - maxSearchResults} more results not displayed. `}
                Narrow your search for more targeted results
              </SearchResultItem>
            )}
          </>
        </SearchResultsWrapper>
      </SearchResults>
      {error && <Alert type={AlertType.ERROR} bodyText={error.message} />}
    </div>
  );
}

export default EntitySearch;
