import './Header.scss';

import React from 'react';

import Button from '../../../../../../../../../common/Button';
import Loader from '../../../../../../../../../common/Loader';
import bem from '../../../../../../../../../utils/bem';
import MultipleEditDrawerContentHeader from '../../../../MultipleEditDrawerContentHeader';

type Props = {
  onSubmit: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
};

const [block, element] = bem('multiple-edit-third-step-header');

export const Header = ({ onSubmit, isLoading, isDisabled }: Props): React.JSX.Element => {
  return (
    <MultipleEditDrawerContentHeader>
      <div className={block()}>
        <div className={element('summary')}>Summary of Changes</div>
        <div className={element('description')}>Confirm the changes to apply to the selected line items:</div>
      </div>

      <Button size="medium" onClick={onSubmit} disabled={isDisabled} data-testid="confirm-button">
        <div className={element('button-content-wrapper')}>
          <div>Confirm</div>
          {isLoading && <Loader />}
        </div>
      </Button>
    </MultipleEditDrawerContentHeader>
  );
};
