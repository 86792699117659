import type { ValueOf } from '../../../../types/util';
import { IngestionStatus, IngestionStatusMap } from '../constants';
import type { StatusLabel } from '../types';
import { LabelType } from './LabelType';

export function getIngestLabel(status?: string | null): StatusLabel {
  if (!status || !Object.keys(IngestionStatus).includes(status)) return null;

  let type: ValueOf<typeof LabelType> = LabelType.ERROR;

  if (status === IngestionStatus.INGESTING) type = LabelType.IN_PROGRESS;
  if (status === IngestionStatus.INGESTION_COMPLETE) type = LabelType.SUCCESS;

  return {
    label: IngestionStatusMap[status as keyof typeof IngestionStatus],
    type,
    stage: 'INGEST',
  };
}
