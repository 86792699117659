import 'mission-control-common-components/dist/static/css/main.css';

import envConfig from '@hulu/env-config';
import { Navigate, Route, Routes, useLocation } from '@hulu/react-router-dom';
import { notification } from 'antd';
import { User } from 'mission-control-common-components';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';

import { LOCATION_BEFORE_LOGIN } from './common/constants';
import Footer from './common/Footer';
import Header from './common/Header';
import Loader from './common/Loader';
import { sendTokenToWorker } from './configs/serviceWorkerInit';
import { PathName, requiredPermissions } from './constants';
import FilterProvider from './contexts/FilterContext';
import { useGetRoleDefinitions } from './hooks';
import AdsPageController from './pages/Ads';
import ForbiddenPage from './pages/Forbidden';
import NotFoundPage from './pages/NotFound';
import OauthCallback from './pages/OauthCallback';
import ReportsPageController from './pages/Reports';
import SequencePageWrapper from './pages/Sequence/SequencePageWrapper';
import ServiceDownPage from './pages/ServiceDown';
import TraffickingPageController from './pages/Trafficking';
import configuration, { demandManagerConfig, pricingManagerConfig, supplyManagerConfig } from './plugin/configuration';
import RefreshComponent from './plugin/refreshComponent';
import { combinePlugins, traffickingRouteElement } from './utils/plugins';

const fallback = <Loader className={'main'} />;

/** Pricing lazy component can't be created inside a loop in this same function as React.Suspense because loosing reference to the Router */
const PricingManagerLazy = React.lazy(pricingManagerConfig.getComponent);
const SupplyManagerLazy = React.lazy(supplyManagerConfig.getComponent);
const DemandManagerLazy = React.lazy(demandManagerConfig.getComponent);

const App: React.FC<{}> = () => {
  const [idTokenChecked, setIdTokenChecked] = useState({ checked: false, isChecking: true });
  const isAuthEnabled: boolean = envConfig.REACT_APP_AUTH_FEATURE_ENABLED === 'true';

  const { data: roleDefinitions, loading: roleDefinitionsLoading } = useGetRoleDefinitions();

  const permissions = User.generateUserPermissions(isAuthEnabled, requiredPermissions, roleDefinitions);

  const location = useLocation();
  const isUserAuthenticated = isAuthEnabled ? User.getUserInfo().idToken : true;
  const isCurrentPageSecure = location.pathname !== PathName.oauthCallback && location.pathname !== PathName.forbidden;

  useEffect(() => {
    notification.destroy();
  }, [location.pathname]);

  useEffect(() => {
    if (idTokenChecked.checked) {
      return;
    }

    if (!isAuthEnabled) {
      setIdTokenChecked({ checked: true, isChecking: false });

      return;
    }
    if (location.pathname === PathName.oauthCallback || location.pathname === PathName.forbidden) {
      setIdTokenChecked({ checked: true, isChecking: false });

      return;
    }

    const { idToken } = User.getUserInfo();
    if (User.isIdTokenValid(idToken)) {
      setIdTokenChecked({ checked: true, isChecking: false });

      return;
    }

    setIdTokenChecked({ checked: true, isChecking: true });
    User.checkIdToken()
      .then(() => {
        sendTokenToWorker(navigator);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIdTokenChecked({ checked: true, isChecking: false });
      });
  }, [setIdTokenChecked, idTokenChecked, location.pathname, isAuthEnabled]);

  // Display the loader until role definitions are available
  if (roleDefinitionsLoading || !idTokenChecked) return fallback;

  if (!isUserAuthenticated && isCurrentPageSecure) {
    localStorage.setItem(LOCATION_BEFORE_LOGIN, JSON.stringify(location));
    User.login();
    return fallback;
  }

  if (isUserAuthenticated) {
    const item = localStorage.getItem(LOCATION_BEFORE_LOGIN);

    if (item) {
      localStorage.removeItem(LOCATION_BEFORE_LOGIN);
      return <Navigate to={JSON.parse(item)} replace />;
    }
  }

  return (
    <div id="app">
      <Header isAuthEnabled={isAuthEnabled} permissions={permissions} />
      <FilterProvider>
        <Routes>
          <Route path={PathName.reports} element={<ReportsPageController />} />
          <Route path={PathName.forbidden} element={<ForbiddenPage />} />
          <Route path={PathName.serviceDown} element={<ServiceDownPage />} />
          <Route path={PathName.oauthCallback} element={<OauthCallback />} />
          <Route
            path={PathName.trafficking}
            element={traffickingRouteElement(permissions?.traffickingPageRead, <TraffickingPageController />)}
          />
          <Route
            path={PathName.adsEdit}
            element={traffickingRouteElement(permissions?.updateAd, <AdsPageController />)}
          />
          <Route
            path={PathName.adsCreate}
            element={traffickingRouteElement(permissions?.createAd, <AdsPageController />)}
          />
          <Route
            path={PathName.sequence}
            element={traffickingRouteElement(permissions?.createAd, <SequencePageWrapper />)}
          />
          <Route
            path={PathName.targetingPreset}
            element={traffickingRouteElement(permissions?.presetPageRead, <RefreshComponent />)}
          />
          <Navigate to={PathName.trafficking} />
          {combinePlugins(configuration, permissions)}
          <Route
            path={`${pricingManagerConfig.route}/*`}
            element={traffickingRouteElement(
              permissions?.[pricingManagerConfig.permissionName],
              <React.Suspense fallback={fallback}>
                <PricingManagerLazy />
              </React.Suspense>
            )}
          />
          <Route
            path={`${demandManagerConfig.route}/*`}
            element={traffickingRouteElement(
              permissions?.[demandManagerConfig.permissionName],
              <React.Suspense fallback={fallback}>
                <DemandManagerLazy />
              </React.Suspense>
            )}
          />
          <Route
            path={`${supplyManagerConfig.route}/*`}
            element={traffickingRouteElement(
              permissions?.[supplyManagerConfig.permissionName],
              <React.Suspense fallback={fallback}>
                <SupplyManagerLazy />
              </React.Suspense>
            )}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </FilterProvider>
      <Footer />
    </div>
  );
};

export default hot(module)(App);
