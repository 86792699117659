export const IngestionStatus = Object.freeze({
  INGESTING: 'INGESTING',
  INGESTION_COMPLETE: 'INGESTION_COMPLETE',
  VAST_PARSING_ERROR_MANDATORY_ELEMENT_MISSING: 'VAST_PARSING_ERROR_MANDATORY_ELEMENT_MISSING',
  VAST_PARSING_ERROR_VPAID_ISSUE: 'VAST_PARSING_ERROR_VPAID_ISSUE',
  VAST_PARSING_ERROR_TRACKING_ISSUE: 'VAST_PARSING_ERROR_TRACKING_ISSUE',
  VAST_PARSING_ERROR_COMPANION_ISSUE: 'VAST_PARSING_ERROR_COMPANION_ISSUE',
  VAST_PARSING_ERROR_MEDIA_FILE_ISSUE: 'VAST_PARSING_ERROR_MEDIA_FILE_ISSUE',
  ASSET_VALIDATION_ERROR_INVALID_CODEC: 'ASSET_VALIDATION_ERROR_INVALID_CODEC',
  ASSET_VALIDATION_ERROR_INCORRECT_FRAMERATE: 'ASSET_VALIDATION_ERROR_INCORRECT_FRAMERATE',
  ASSET_VALIDATION_ERROR_VIDEO_TRACK_ISSUE: 'ASSET_VALIDATION_ERROR_VIDEO_TRACK_ISSUE',
  ASSET_VALIDATION_ERROR_AUDIO_TRACK_ISSUE: 'ASSET_VALIDATION_ERROR_AUDIO_TRACK_ISSUE',
  DUPLICATED_ASSET: 'DUPLICATED_ASSET',
  DOWNLOAD_FAILED: 'DOWNLOAD_FAILED',
  ASSET_CREATION_FAILED: 'ASSET_CREATION_FAILED',
  ASSET_BINDING_FAILED: 'ASSET_BINDING_FAILED',
  INVALID_TRIAL: 'INVALID_TRIAL',
});

/**
 * Note: If an asset has DUPLICATED_ASSET status, we must fetch the status of the asset that it
 * is duplicating and display that status instead. DUPLICATED_ASSET will never appear as a status in the pill.
 */
export const IngestionStatusMap = Object.freeze({
  INGESTING: 'INGESTING',
  INGESTION_COMPLETE: 'INGESTION COMPLETE',
  VAST_PARSING_ERROR_MANDATORY_ELEMENT_MISSING: 'FIX VAST: MANDATORY ELEMENT MISSING',
  VAST_PARSING_ERROR_VPAID_ISSUE: 'FIX VAST: ERROR VPAID ISSUE',
  VAST_PARSING_ERROR_TRACKING_ISSUE: 'FIX VAST: TRACKING ISSUE',
  VAST_PARSING_ERROR_COMPANION_ISSUE: 'FIX VAST: COMPANION ISSUE',
  VAST_PARSING_ERROR_MEDIA_FILE_ISSUE: 'FIX VAST: MEDIA FILE ISSUE',
  ASSET_VALIDATION_ERROR_INVALID_CODEC: 'FIX ASSET: INVALID CODEC',
  ASSET_VALIDATION_ERROR_INCORRECT_FRAMERATE: 'FIX ASSET: INCORRECT FRAMERATE',
  ASSET_VALIDATION_ERROR_VIDEO_TRACK_ISSUE: 'FIX ASSET: VIDEO TRACK ISSUE',
  ASSET_VALIDATION_ERROR_AUDIO_TRACK_ISSUE: 'FIX ASSET: AUDIO TRACK ISSUE',
  DUPLICATED_ASSET: '',
  DOWNLOAD_FAILED: 'RETRY INGEST: DOWNLOAD FAILED',
  ASSET_CREATION_FAILED: 'RETRY INGEST: ASSET CREATION FAILED',
  ASSET_BINDING_FAILED: 'RETRY INGEST: ASSET BINDING FAILED',
  INVALID_TRIAL: 'RETRY INGEST: INVALID TRIAL',
});
