import type { Values } from '../../models';
import type { IStorage } from './types';

export class StorageEntity<T> {
  private readonly storageKey: string;
  private storage: IStorage;

  constructor(storageKey: string, storage: IStorage) {
    this.storageKey = storageKey;
    this.storage = storage;
  }

  getAllStorageData(): T | undefined {
    return this.storage.getData<T>(this.storageKey);
  }

  setAllStorageData(data: T): void {
    this.storage.setData(this.storageKey, data);
  }

  getStorageData(name: keyof T): Values<T> | undefined {
    return this.storage.getData<T>(this.storageKey)?.[name];
  }

  setStorageData(name: string, data: T[keyof T]): void {
    const storageData = this.storage.getData<Record<string, T>>(this.storageKey);

    if (!storageData) {
      return this.storage.setData(this.storageKey, { [name]: data });
    }

    const newData = { ...storageData, [name]: data };
    this.storage.setData(this.storageKey, newData);
  }
}
