import { deliveryTiers, pacingTypes } from '../../../../../../../../../../configs';
import type { Tier } from '../../../../../../../../../../models/tier';
import type { LineItemModel } from '../../../../../../../../TraffickingPage/modelConverters';

type Props = {
  tier: Tier | null;
  pacingType?: LineItemModel['pacingType'];
};

const PRIORITY_CONFLICT_PAIRS: Record<string, string[]> = {
  [pacingTypes.SOV.key]: [deliveryTiers.house.key, deliveryTiers.non_guaranteed.key, deliveryTiers.guaranteed.key],
  [pacingTypes.AFAP.key]: [deliveryTiers.non_guaranteed.key],
  [pacingTypes.YIELD.key]: [deliveryTiers.guaranteed.key, deliveryTiers.sponsorship.key],
};

export const getIsTierNameHasConflictByPacingType = ({ tier, pacingType }: Props): boolean => {
  if (!pacingType || !tier) return false;

  return PRIORITY_CONFLICT_PAIRS[pacingType.key]?.includes(tier.name.key);
};
