import React, { useMemo } from 'react';

import type { DropdownOption } from '../../../../common/Dropdown/Dropdown';
import { commonCustomComponents } from '../../../../common/Dropdown/Dropdown';
import MultiSelectDropdown from '../../../../common/MultiSelectDropdown';
import { getNoOptionsMessage } from '../../../../common/TargetingDropdowns/utils';
import type { SearchParamsValuesType } from '../../../../constants';
import type { MultiDropdownPlaceholder, UseOptions } from '../AdditionalFilters/constants';
import MenuList from '../MenuList';
import { useMultiDropdownFilter } from './hooks/useMultiDropdownFilter';

export interface MultiDropdownFilterProps {
  searchParam: SearchParamsValuesType;
  selectedValues: DropdownOption[];
  multiDropdownPlaceholder?: MultiDropdownPlaceholder;
  onSetSelectedFilter: () => void;
  onRemoveFilter: (selectedSearchParam: SearchParamsValuesType) => void;
  useOptions: () => UseOptions;
}

const MultiDropdownFilter = ({
  searchParam,
  selectedValues,
  multiDropdownPlaceholder,
  onSetSelectedFilter,
  onRemoveFilter,
  useOptions,
}: MultiDropdownFilterProps): React.JSX.Element => {
  const { hasMore, options, searching, searchTerm, error, onNext, onResetOptions, onSearch } = useOptions();

  const {
    selectedDropdownOptions,
    onClearAll,
    onCloseDropdownMenu,
    onHandleChange,
    onHandleSearchValues,
    onSelectAll,
  } = useMultiDropdownFilter({
    searchParam,
    selectedValues,
    options,
    onSearch,
    onSetSelectedFilter,
    onRemoveFilter,
    onResetOptions,
  });

  const components = useMemo(() => ({ ...commonCustomComponents, MenuList }), []);

  const dropdownNoOptionMessage = useMemo(() => getNoOptionsMessage(searching ?? false, false, searchTerm ?? ''), [
    searchTerm,
    searching,
  ]);

  return (
    <MultiSelectDropdown<string>
      value={selectedDropdownOptions}
      options={options}
      placeholder={multiDropdownPlaceholder?.searchInputPlaceholder}
      hasMore={hasMore}
      error={error}
      noOptionsMessage={(): string => dropdownNoOptionMessage}
      emptyDisplayText={multiDropdownPlaceholder?.dropdownButtonPlaceholder}
      components={components}
      multipleSelectControls={true}
      onInputChange={onHandleSearchValues}
      onNext={onNext}
      onClose={onCloseDropdownMenu}
      inputValue={searchTerm}
      onChange={onHandleChange}
      handleSelectAll={onSelectAll}
      handleClearAll={onClearAll}
    />
  );
};

export default MultiDropdownFilter;
