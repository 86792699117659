import './AdRotationsHeader.scss';

import bem from '../../../utils/bem';
import { rotationsColumnsBlock, rotationsColumnsElement } from '../bemClasesConstants';

const [block] = bem('ad-rotations-header');

const AdRotationsHeader = (): JSX.Element => {
  return (
    <div className={`${block()} ${rotationsColumnsBlock()}`} data-testid="ad-rotations-header">
      <div className={rotationsColumnsElement('column', ['main'])}>Ad name</div>
      <div className={rotationsColumnsElement('column', ['secondary'])}>Ad ID</div>
      <div className={rotationsColumnsElement('column', [])}>Start date</div>
      <div className={rotationsColumnsElement('column', [])}>End date</div>
    </div>
  );
};

export default AdRotationsHeader;
