import { sortBy } from 'lodash';

import type { DayPartV5 } from '../../apis/graphql';
import type { DayPartTimeRange, DayPartTimeRanges, WeekDayRanges } from './types';

const optimizeDayParts = (sortedDayParts: DayPartTimeRanges): DayPartTimeRanges => {
  if (sortedDayParts.length <= 1) {
    return sortedDayParts;
  }
  const dayParts: DayPartTimeRanges = [];

  let tempDayPart: DayPartTimeRange = [...sortedDayParts[0]];
  for (let i = 1; i < sortedDayParts.length; i++) {
    const dayPart: DayPartTimeRange = sortedDayParts[i];
    if (dayPart[0] > tempDayPart[1]) {
      dayParts.push(tempDayPart);
      tempDayPart = [...dayPart];
    } else if (dayPart[0] <= tempDayPart[1] && dayPart[1] > tempDayPart[1]) {
      tempDayPart[1] = dayPart[1];
    }
  }
  dayParts.push(tempDayPart);

  return dayParts;
};

const optimizeAndSortDayParts = (dayParts: WeekDayRanges): WeekDayRanges => {
  for (const day in dayParts) {
    dayParts[day] = sortBy(dayParts[day], [(day): number => day[0]]);
    dayParts[day] = optimizeDayParts(dayParts[day]);
  }

  return dayParts;
};

export const prepareDayPartsBeforeRendering = (dayParts: Array<DayPartV5>): WeekDayRanges => {
  const dayPartToShow: WeekDayRanges = {};

  dayParts.forEach((dayPart: DayPartV5) => {
    dayPart.activeDayList.forEach((activeDay) => {
      if (!dayPartToShow[activeDay]) {
        dayPartToShow[activeDay] = [];
      }
      dayPartToShow[activeDay].push([dayPart.firstActiveHour || 0, dayPart.lastActiveHour || 0]);
    });
  });

  return optimizeAndSortDayParts(dayPartToShow);
};
