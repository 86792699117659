import './TextArea.scss';

import { Paragraph as TrekParagraph } from '@hulu/react-style-components';
import { useFormikContext } from 'formik';
import type { TextareaHTMLAttributes } from 'react';
import React from 'react';

import { withOptionalClassName } from '../../../utils/bem';
import type { FormFieldsNames, FormFieldsValues } from '../../AdForm/adFormik';
import { useFieldFast } from '../hooks';
import PreviewWrapper from '../PreviewWrapper';
import { makeLabelChild } from '../utils';

// Note: this interface is defined but not exported directly from react-style-components
interface TrekTextAreaProps {
  charMax?: number;
  errMsg?: string | React.ReactElement;
  help?: string | React.ReactElement;
  info?: string;
  label?: string;
  className?: string;
}

export interface TextAreaProps extends TrekTextAreaProps, Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'rows'> {
  formFieldName: string;
  secondaryLabel?: string;
  displayValueConverter?: (formValue: string) => string; // Method to transform form value to display value
  disabled?: boolean;
  isPreview?: boolean;
  rows?: string;
}

// A Formik-connected Trek Paragraph component
export function TextArea({
  formFieldName,
  label,
  secondaryLabel,
  disabled,
  className,
  displayValueConverter,
  isPreview,
  ...props
}: TextAreaProps): JSX.Element {
  const [, { value }, { setValue }] = useFieldFast(formFieldName);
  const { isSubmitting, errors } = useFormikContext<FormFieldsValues>();

  return (
    <PreviewWrapper isPreview={isPreview} value={value} label={label}>
      <TrekParagraph
        value={displayValueConverter ? displayValueConverter(value) : value}
        label={makeLabelChild(label, secondaryLabel)}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void => setValue(e.target.value)}
        disabled={disabled || isSubmitting}
        className={withOptionalClassName('text-area', className)}
        errMsg={errors[formFieldName as FormFieldsNames]}
        {...props}
      />
    </PreviewWrapper>
  );
}

export default TextArea;
