import './Targeting.scss';

import React, { useMemo } from 'react';

import { useFieldArray, useFieldFast } from '../../../../../../../../../common/Form/hooks';
import TargetingDropdowns from '../../../../../../../../../common/TargetingDropdowns/TargetingDropdowns';
import type { TargetingTableRowGroup } from '../../../../../../../../../common/TargetingTable/TargetingTable';
import TargetingTable from '../../../../../../../../../common/TargetingTable/TargetingTable';
import type { LineItem, TargetingTermValue } from '../../../../../../../../../models';
import bem from '../../../../../../../../../utils/bem';
import {
  getCountryTargetLineItem,
  getPublisherTargetLineItem,
} from '../../../../../../../DrawerTargetingTable/utils/utils';
import { EntityTypeArray } from '../../../../../../../TraffickingPage/hooks/makePageDrawerPlugin';
import { generateTargetingTableData, getHeaderText } from './utils';

const [body, element] = bem('multiple-edit-targeting-table');

type TargetingProps = {
  entityType: EntityTypeArray;
};

export default function Targeting({ entityType }: TargetingProps): JSX.Element {
  const isLineItem = entityType === EntityTypeArray.LINE_ITEM_ARRAY;

  const [
    ,
    { value: targetingTermValues },
    { insertAt, removeAt, replaceAt, removeAll },
  ] = useFieldArray<TargetingTermValue>('targetingTermValues');
  const [, { value: lineItem }] = useFieldFast<LineItem>(isLineItem ? 'entities[0]' : 'entities[0].lineItem');
  const countryOption = getCountryTargetLineItem({ value: lineItem?.targetingTermValues || [] });

  const data: TargetingTableRowGroup[] = useMemo(() => generateTargetingTableData(targetingTermValues, isLineItem), [
    targetingTermValues,
    isLineItem,
  ]);

  return (
    <div className={body()}>
      <div className={element('header')}>{getHeaderText(entityType)}</div>
      <TargetingDropdowns
        listHelpers={{ insertAt, replaceAt, removeAt }}
        targetings={targetingTermValues}
        countryOption={countryOption || undefined}
        publisherOption={getPublisherTargetLineItem(lineItem?.displayPublisherTarget || []) || undefined}
      />
      <TargetingTable data={data} listHelpers={{ removeAt, replaceAt, removeAll }} isReadOnly isTableToggleable />
    </div>
  );
}
