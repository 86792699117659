/**
 * Project Margaret has a "built-in" service worker registration util which is
 * focused primarily on building an offline-capable PWA worker for production environments.
 *
 * Our use-case is more narrow in that we only need to use the proxy capabilities of
 * the service worker, without caching, and we need to be able to communicate data
 * to the service worker.
 *
 * This custom init script is tailored for this use-case.
 */
export function sendTokenToWorker(navigator?: Navigator): void {
  if (navigator && 'serviceWorker' in navigator) {
    navigator.serviceWorker.controller?.postMessage({ idToken: localStorage.getItem('id_token') });
  }
}

export function serviceWorkerInit(nav?: Navigator): void {
  if (!nav || !('serviceWorker' in nav)) return;

  nav.serviceWorker.ready.then((registration) => {
    registration.active?.postMessage({ idToken: localStorage.getItem('id_token') });
  });

  nav.serviceWorker.register('/service-worker.js', { scope: '/' });
}
