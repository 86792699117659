import './TimeMatrix.scss';

import type { ChangeEvent } from 'react';
import React, { useEffect, useState } from 'react';

import bem from '../../utils/bem';
import { getInitialColumnsValue, getInitialRowsValue } from './utils';

interface TimeMatrixProps {
  values: boolean[][];
  onValueChange: (x: number, y: number) => void;
  onRowChange: (index: number, value: boolean) => void;
  onColumnChange: (index: number, value: boolean) => void;
}

const hours = ['12A', '3A', '6A', '9A', '12P', '3P', '6P', '9P'];
const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

const [b, e] = bem('time-matrix');

function TimeMatrix({ values, onValueChange, onRowChange, onColumnChange }: TimeMatrixProps): JSX.Element | null {
  const [selectedRows, setSelectedRows] = useState(getInitialRowsValue(values));
  const [selectedColumns, setSelectedColumns] = useState(getInitialColumnsValue(values));

  const handleChange = ({ target: { name } }: ChangeEvent<HTMLInputElement>): void => {
    const xy = name.split('_').map((value) => Number(value));
    onValueChange(xy[0], xy[1]);
  };

  const handleChangeRow = ({ target: { checked, name } }: ChangeEvent<HTMLInputElement>): void => {
    const index = Number(name.split('-')[1]);
    onRowChange(index, checked);
    setSelectedRows((state) => {
      const newState = [...state];
      newState[index] = !checked;
      return newState;
    });
  };

  const handleChangeColumn = ({ target: { checked, name } }: ChangeEvent<HTMLInputElement>): void => {
    onColumnChange(Number(name.split('-')[1]), checked);
  };

  useEffect(() => {
    setSelectedRows(getInitialRowsValue(values));
    setSelectedColumns(getInitialColumnsValue(values));
  }, [values]);

  if (!values.length) {
    return null;
  }

  return (
    <div className={b()} data-testid="time-matrix">
      <div className={e('content')}>
        <div className={e('content-inner')}>
          <div className={e('hours-names')}>
            {hours.map((hour, i) => (
              <span key={`${hour}-${i}`}>{hour}</span>
            ))}
          </div>
          <div className={e('days-names')}>
            {days.map((day, i) => (
              <span key={`${day}-${i}`}>{day}</span>
            ))}
          </div>
          {values.map((times, i) => (
            <div className={e('column')} data-testid="time-matrix-column" key={`column-${i}`}>
              {times.map((time, j) => (
                <input
                  data-testid="time-matrix-cell"
                  name={`${i}_${j}`}
                  key={`cell-${i}-${j}`}
                  className={e('cell', time ? 'active' : '')}
                  type="checkbox"
                  checked={time}
                  onChange={handleChange}
                />
              ))}
            </div>
          ))}
        </div>
        <div className={e('all-column')}>
          <span className={e('all-column-title')}>ALL</span>
          {selectedRows.map((value, i) => (
            <input
              key={`selectedRow-${i}`}
              name={`selectedRow-${i}`}
              className={e('cell', value ? 'active' : '')}
              checked={value}
              type="checkbox"
              onChange={handleChangeRow}
            />
          ))}
        </div>
      </div>
      <div className={e('all-row')}>
        <span className={e('all-row-title')}>ALL</span>
        <div className={e('all-row-content')}>
          {selectedColumns.map((value, i) => (
            <input
              key={`selectedColumn-${i}`}
              name={`selectedColumn-${i}`}
              className={e('cell', value ? 'active' : '')}
              type="checkbox"
              checked={value}
              onChange={handleChangeColumn}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default TimeMatrix;
