import type {
  Get_Ad_ProductQuery,
  Get_Ad_RatingsQuery,
  Get_Ad_TypesQuery,
  Get_Creative_Review_StatusesQuery,
  Get_Creative_TypesQuery,
  Get_Currency_TypesQuery,
  Get_Line_Item_Billable_Third_PartiesQuery,
  Get_Line_Item_Tier_NamesQuery,
  Get_Line_Priority_ValuesQuery,
  Get_Order_TypesQuery,
} from '../../../../apis/graphql';
import { DropdownOptions, MultiDropdownOptions, PredefinedDropdownOptions } from '../../../../common/hooks/config';
import { usePredefinedDropdownOptions } from '../../../../common/hooks/filters/usePredefinedDropdownOptions';
import { useFetchDropdownOptions } from '../../../../common/hooks/queries/useFetchDropdownOptions';
import type { MultiDropdownQueryType } from '../../../../common/hooks/queries/useFetchMultiDropdownOptions';
import { useFetchMultiDropdownOptions } from '../../../../common/hooks/queries/useFetchMultiDropdownOptions';
import { TRAFFICKING_SEARCH_PARAMS } from '../../../../constants';
import type { AdditionalFilterDropdown, UseOptions } from './constants';
import {
  AdditionalFilterType,
  DEFAULT_AGENCY_DROPDOWN_BUTTON_PLACEHOLDER,
  DEFAULT_AGENCY_SEARCH_INPUT_PLACEHOLDER,
  DEFAULT_BRAND_DROPDOWN_BUTTON_PLACEHOLDER,
  DEFAULT_BRAND_SEARCH_INPUT_PLACEHOLDER,
  DEFAULT_INDUSTRY_DROPDOWN_BUTTON_PLACEHOLDER,
  DEFAULT_INDUSTRY_SEARCH_INPUT_PLACEHOLDER,
} from './constants';

/**
 * Filters such as Pacing Status, Line Item Status, and Ad Status have an exact, fixed order and always come first of other filters
 * A new filter could be added in a particular, fixed position based on the requirements
 */
export const filtersWithFixedOrderConfig: AdditionalFilterDropdown[] = [
  {
    type: AdditionalFilterType.CHECKBOX,
    label: 'Pacing Status',
    multiOptionalLabel: 'All Pacing statuses',
    searchParam: TRAFFICKING_SEARCH_PARAMS.GENERAL_PACING_STATUS_LIST,
    value: [],
    useOptions: (): UseOptions => usePredefinedDropdownOptions(PredefinedDropdownOptions.GeneralPacingStatus),
  },
  {
    type: AdditionalFilterType.CHECKBOX,
    label: 'Line Item Status',
    multiOptionalLabel: 'All Line Item statuses',
    searchParam: TRAFFICKING_SEARCH_PARAMS.LINE_ITEM_STATUS_LIST,
    value: [],
    useOptions: (): UseOptions => usePredefinedDropdownOptions(PredefinedDropdownOptions.LineItemStatus),
  },
  {
    type: AdditionalFilterType.RADIOBUTTON,
    label: 'Ad Status',
    multiOptionalLabel: 'All Ad Statuses',
    searchParam: TRAFFICKING_SEARCH_PARAMS.AD_STATUS_LIST,
    value: [],
    useOptions: (): UseOptions => usePredefinedDropdownOptions(PredefinedDropdownOptions.AdStatus),
  },
];

/**
 * A new filter with dynamic order could be added to the end of the config
 */
export const filtersWithDynamicOrderConfig: AdditionalFilterDropdown[] = [
  {
    type: AdditionalFilterType.CHECKBOX,
    label: 'Review Status',
    multiOptionalLabel: 'All Review Statuses',
    searchParam: TRAFFICKING_SEARCH_PARAMS.REVIEW_STATUS_LIST,
    value: [],
    useOptions: (): UseOptions => usePredefinedDropdownOptions(PredefinedDropdownOptions.ReviewStatus),
  },
  {
    type: AdditionalFilterType.CHECKBOX,
    label: 'Order Type',
    multiOptionalLabel: 'All Order types',
    searchParam: TRAFFICKING_SEARCH_PARAMS.ORDER_TYPE_LIST,
    value: [],
    useOptions: (): UseOptions => useFetchDropdownOptions<Get_Order_TypesQuery>(DropdownOptions.OrderTypes),
  },
  {
    type: AdditionalFilterType.DROPDOWN,
    label: 'Brand',
    multiOptionalLabel: 'All Brands',
    searchParam: TRAFFICKING_SEARCH_PARAMS.BRAND_ID_LIST,
    value: [],
    multiDropdownPlaceholder: {
      dropdownButtonPlaceholder: DEFAULT_BRAND_DROPDOWN_BUTTON_PLACEHOLDER,
      searchInputPlaceholder: DEFAULT_BRAND_SEARCH_INPUT_PLACEHOLDER,
    },
    useOptions: (): UseOptions => useFetchMultiDropdownOptions<MultiDropdownQueryType>(MultiDropdownOptions.Brands),
  },
  {
    type: AdditionalFilterType.DROPDOWN,
    label: 'Agency',
    multiOptionalLabel: 'All Agencies',
    searchParam: TRAFFICKING_SEARCH_PARAMS.AGENCY_ID_LIST,
    value: [],
    multiDropdownPlaceholder: {
      dropdownButtonPlaceholder: DEFAULT_AGENCY_DROPDOWN_BUTTON_PLACEHOLDER,
      searchInputPlaceholder: DEFAULT_AGENCY_SEARCH_INPUT_PLACEHOLDER,
    },
    useOptions: (): UseOptions => useFetchMultiDropdownOptions<MultiDropdownQueryType>(MultiDropdownOptions.Agencies),
  },
  {
    type: AdditionalFilterType.DROPDOWN,
    label: 'Industry',
    multiOptionalLabel: 'All Industries',
    searchParam: TRAFFICKING_SEARCH_PARAMS.INDUSTRY_ID_LIST,
    value: [],
    multiDropdownPlaceholder: {
      dropdownButtonPlaceholder: DEFAULT_INDUSTRY_DROPDOWN_BUTTON_PLACEHOLDER,
      searchInputPlaceholder: DEFAULT_INDUSTRY_SEARCH_INPUT_PLACEHOLDER,
    },
    useOptions: (): UseOptions => useFetchMultiDropdownOptions<MultiDropdownQueryType>(MultiDropdownOptions.Industries),
  },
  {
    type: AdditionalFilterType.CHECKBOX,
    label: 'Product Type',
    multiOptionalLabel: 'All Product Types',
    searchParam: TRAFFICKING_SEARCH_PARAMS.PRODUCT_TYPE,
    value: [],
    useOptions: (): UseOptions => useFetchDropdownOptions<Get_Ad_ProductQuery>(DropdownOptions.ProductType),
  },
  {
    type: AdditionalFilterType.CHECKBOX,
    label: 'Currency',
    multiOptionalLabel: 'All Currency Types',
    searchParam: TRAFFICKING_SEARCH_PARAMS.CURRENCY_LIST,
    value: [],
    useOptions: (): UseOptions => useFetchDropdownOptions<Get_Currency_TypesQuery>(DropdownOptions.CurrencyList),
  },
  {
    type: AdditionalFilterType.CHECKBOX,
    label: 'Billable 3p',
    multiOptionalLabel: 'All Billable 3ps',
    searchParam: TRAFFICKING_SEARCH_PARAMS.BILLABLE_THIRD_PARTY_LIST,
    value: [],
    useOptions: (): UseOptions =>
      useFetchDropdownOptions<Get_Line_Item_Billable_Third_PartiesQuery>(DropdownOptions.LineItemBillableThirdParties),
  },
  {
    type: AdditionalFilterType.CHECKBOX,
    label: 'Priority Value',
    multiOptionalLabel: 'Priority Values',
    searchParam: TRAFFICKING_SEARCH_PARAMS.PRIORITY_VALUE_LIST,
    value: [],
    useOptions: (): UseOptions =>
      useFetchDropdownOptions<Get_Line_Priority_ValuesQuery>(DropdownOptions.LineItemPriorityValues),
  },
  {
    type: AdditionalFilterType.CHECKBOX,
    label: 'Tier Name',
    multiOptionalLabel: 'Tier Names',
    searchParam: TRAFFICKING_SEARCH_PARAMS.TIER_NAME_LIST,
    value: [],
    useOptions: (): UseOptions =>
      useFetchDropdownOptions<Get_Line_Item_Tier_NamesQuery>(DropdownOptions.LineItemTierNames),
  },
  {
    type: AdditionalFilterType.CHECKBOX,
    label: 'Publisher',
    multiOptionalLabel: 'Publishers',
    searchParam: TRAFFICKING_SEARCH_PARAMS.PUBLISHER_LIST,
    value: [],
    useOptions: (): UseOptions => usePredefinedDropdownOptions(PredefinedDropdownOptions.PublisherList),
  },
  {
    type: AdditionalFilterType.RADIOBUTTON,
    label: 'COPPA',
    multiOptionalLabel: 'COPPA',
    searchParam: TRAFFICKING_SEARCH_PARAMS.IS_COPPA_OR_CARRU,
    value: [],
    useOptions: (): UseOptions => usePredefinedDropdownOptions(PredefinedDropdownOptions.IsCoppaOrCaru),
  },
  {
    type: AdditionalFilterType.RADIOBUTTON,
    label: 'Is Added Value',
    multiOptionalLabel: 'Is Added Value',
    searchParam: TRAFFICKING_SEARCH_PARAMS.IS_ADDED_VALUE,
    value: [],
    useOptions: (): UseOptions => usePredefinedDropdownOptions(PredefinedDropdownOptions.IsAddedValue),
  },
  {
    type: AdditionalFilterType.RADIOBUTTON,
    label: 'Is Makegood',
    multiOptionalLabel: 'Is Makegood',
    searchParam: TRAFFICKING_SEARCH_PARAMS.IS_MAKEGOOD,
    value: [],
    useOptions: (): UseOptions => usePredefinedDropdownOptions(PredefinedDropdownOptions.IsMakegood),
  },
  {
    type: AdditionalFilterType.CHECKBOX,
    label: 'Ad Type',
    multiOptionalLabel: 'Ad Types',
    searchParam: TRAFFICKING_SEARCH_PARAMS.AD_TYPE_LIST,
    value: [],
    useOptions: (): UseOptions => useFetchDropdownOptions<Get_Ad_TypesQuery>(DropdownOptions.AdType),
  },
  {
    type: AdditionalFilterType.CHECKBOX,
    label: 'Ad Rating',
    multiOptionalLabel: 'Ad Ratings',
    searchParam: TRAFFICKING_SEARCH_PARAMS.AD_RATING_LIST,
    value: [],
    useOptions: (): UseOptions => useFetchDropdownOptions<Get_Ad_RatingsQuery>(DropdownOptions.AdRating),
  },
  {
    type: AdditionalFilterType.CHECKBOX,
    label: 'Creative Type',
    multiOptionalLabel: 'Creative Types',
    searchParam: TRAFFICKING_SEARCH_PARAMS.CREATIVE_TYPE_LIST,
    value: [],
    useOptions: (): UseOptions => useFetchDropdownOptions<Get_Creative_TypesQuery>(DropdownOptions.CreativeType),
  },
  {
    type: AdditionalFilterType.CHECKBOX,
    label: 'Creative Review Status',
    multiOptionalLabel: 'Creative Review Statuses',
    searchParam: TRAFFICKING_SEARCH_PARAMS.CREATIVE_REVIEW_LIST,
    value: [],
    useOptions: (): UseOptions =>
      useFetchDropdownOptions<Get_Creative_Review_StatusesQuery>(DropdownOptions.CreativeReviewStatus),
  },
  {
    type: AdditionalFilterType.RADIOBUTTON,
    label: 'Ad Rotation',
    multiOptionalLabel: 'Ad Rotation',
    searchParam: TRAFFICKING_SEARCH_PARAMS.IS_ROTATION,
    value: [],
    useOptions: (): UseOptions => usePredefinedDropdownOptions(PredefinedDropdownOptions.isRotation),
  },
  {
    type: AdditionalFilterType.RADIOBUTTON,
    label: 'Ad Sequence',
    multiOptionalLabel: 'Ad Sequence',
    searchParam: TRAFFICKING_SEARCH_PARAMS.IS_SEQUENCE,
    value: [],
    useOptions: (): UseOptions => usePredefinedDropdownOptions(PredefinedDropdownOptions.isSequence),
  },
  {
    type: AdditionalFilterType.CHECKBOX,
    label: 'Country',
    multiOptionalLabel: 'All countries',
    searchParam: TRAFFICKING_SEARCH_PARAMS.COUNTRY_LIST,
    value: [],
    useOptions: (): UseOptions => usePredefinedDropdownOptions(PredefinedDropdownOptions.countryList),
  },
];
