import './SequencePage.scss';

import { Formik } from 'formik';
import React, { useEffect } from 'react';

import DefaultPageHeader from '../../common/DefaultPageHeader';
import { showFailure } from '../../common/utils';
import { ERROR_TITLE } from './constants';
import { useSequenceContext } from './context';
import { useSequenceData, useSequencePage } from './hooks';
import SequenceForm from './SequenceForm';

export type SequenceLocationState = {
  uniqueParentLineOmsLinkIds: string[];
};

const SequencePage = (): React.JSX.Element => {
  const { sequenceState } = useSequenceContext();
  const { sequenceValues, isLoading, error } = useSequenceData({ ...sequenceState });
  const { submitForm } = useSequencePage({ loadedData: sequenceValues });

  useEffect(() => {
    if (error) showFailure({ message: ERROR_TITLE, description: error });
  }, [error]);

  return (
    <div className="page">
      <DefaultPageHeader />
      <Formik initialValues={sequenceValues} enableReinitialize onSubmit={submitForm}>
        {({ submitForm }): React.JSX.Element => <SequenceForm submitForm={submitForm} isLoading={isLoading} />}
      </Formik>
    </div>
  );
};

export default SequencePage;
