import { gql } from '@apollo/client';

import { CAMPAIGN_V5_PATCH_RESPONSE_FRAGMENT } from '../fragments';

export const UPDATE_TRAFFICKING_PAGE_CAMPAIGN = gql`
  mutation UpdateCampaign($id: ID!, $campaignInput: CampaignInputV5!) {
    updateCampaign: updateCampaignV5(id: $id, input: $campaignInput) {
      ...campaignV5PatchResponse
    }
  }
  ${CAMPAIGN_V5_PATCH_RESPONSE_FRAGMENT}
`;
