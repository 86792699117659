import type { Get_Line_Items_For_ExportQuery } from '../../../../apis/graphql';
import type { LineItemModel } from '../../TraffickingPage/modelConverters';
import { lineItemNodeToModel } from '../../TraffickingPage/modelConverters';

export type LineItemNodeForExport = NonNullable<Get_Line_Items_For_ExportQuery['lineItems']['edges'][number]>['node'];

export type LineItemModelForExport = Omit<LineItemModel, 'exclusiveBlock'>;

export const lineItemNodeToModelForExport = (node: LineItemNodeForExport): LineItemModelForExport => {
  return lineItemNodeToModel({ ...node, exclusiveBlock: null });
};
