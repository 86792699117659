import React from 'react';

import { element } from '../../../components/Row/Row';
import type { GetLineItemsRowsParams } from '../getLineItemsRows';

const BufferRow = ({
  deliveryBufferPercent,
  frontLoadPercent,
  isShowBufferValues,
}: GetLineItemsRowsParams['values']['buffer'] & { isShowBufferValues: boolean }): React.JSX.Element => {
  return (
    <>
      {isShowBufferValues && (
        <div>
          <div>
            <span className={element('value-title')}>B: </span>
            {deliveryBufferPercent ? <span>{deliveryBufferPercent}%</span> : '-'}
          </div>

          <div>
            <span className={element('value-title')}>FL: </span>
            {frontLoadPercent ? <span>{frontLoadPercent}%</span> : '-'}
          </div>
        </div>
      )}
    </>
  );
};

export default BufferRow;
