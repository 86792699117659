import '../CellItems.scss';

import React from 'react';

import bem from '../../../../../../utils/bem';
import { GreenPlus } from '../../../../../assets';
import type { CellItemsProps } from '../index';

const [block, element] = bem('multiple-change-log-cell');

type TierChangePayloadType = {
  brandCapping: boolean;
  huluCommitment: string;
  name: string;
  rank: string;
};

// This renders fieldName Tier, which has Name, BrandCapping, Rank, and HuluCommittment keys
// Similarily to Priority, these are rendered with key: value in the ChangeLog
function TierCell({ changes, action }: CellItemsProps): JSX.Element {
  // If this changes is an array, return array of payloads
  const removalClass = action.removal ? 'removal' : '';
  if (changes && Array.isArray(changes)) {
    return (
      <div className={block()}>
        {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
        {changes.map(({ payload: { brandCapping, huluCommitment, name, rank } }, index) => {
          return (
            <ul key={index} className={element('tier', removalClass)}>
              <li>Name: {name}</li>
              {brandCapping && <li>Brand Capping: {brandCapping.toString()}</li>}
              {rank && <li>Rank: {rank}</li>}
              {huluCommitment && <li>Brand Capping: {huluCommitment}</li>}
            </ul>
          );
        })}
      </div>
    );
  }
  // If the changes isn't an array, but Payload is an array, loop over that data and return it
  if (changes.payload && Array.isArray(changes.payload)) {
    return (
      <>
        {changes.payload.map((payload: TierChangePayloadType, index: number) => {
          return payload ? (
            <div className={block()} key={index}>
              {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
              <ul className={element('priority', removalClass)}>
                <li>Name: {payload.name}</li>
                {payload.brandCapping && <li>Brand Capping: {payload.brandCapping.toString()}</li>}
                {payload.rank && <li>Rank: {payload.rank}</li>}
                {payload.huluCommitment && <li>Hulu Commitment: {payload.huluCommitment}</li>}
              </ul>
            </div>
          ) : (
            <span>-</span>
          );
        })}
      </>
    );
  }

  // Finally, if Changes isn't an array, and Payload is an object, return data off that object
  // This approach is similar to other CellItems, where there is a bit of uncertainty about what the
  // Auditing Manager service is returning (as they are making changes on their end to the shape of the data)
  return changes.payload ? (
    <div className={block()}>
      {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
      <ul className={element('priority', removalClass)}>
        <li>Name: {changes.payload.name}</li>
        {changes.payload.brandCapping && <li>Brand Capping: {changes.payload.brandCapping.toString()}</li>}
        {changes.payload.rank && <li>Rank: {changes.payload.rank}</li>}
        {changes.payload.huluCommitment && <li>Hulu Commitment: {changes.payload.huluCommitment}</li>}
      </ul>
    </div>
  ) : (
    <span>-</span>
  );
}

export default TierCell;
