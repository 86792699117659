import { gql } from '@apollo/client';

export const SEARCH_CREATIVES = gql`
  query SearchCreatives($filterOptions: BaseCreativeFilterOptionsV5, $paginationOptions: PaginationOptions) {
    searchCreatives: searchCreativesV5(filterOptions: $filterOptions, paginationOptions: $paginationOptions) {
      edges {
        node {
          id
          name
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
