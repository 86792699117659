import { useCallback, useEffect, useState } from 'react';
import { useList } from 'react-use';

import type { BaseAssetV5 } from '../../../apis/graphql';

type Asset = Pick<BaseAssetV5, 'name' | 'rejectionReasons'> | Pick<BaseAssetV5, 'name' | 'rejectionReasons'>[];

interface UseStatusModalResult {
  closeModal(): void;
  openModal(): void;
  toggles: boolean[];
  toggleAt: (index: number, value: boolean) => void;
  visible: boolean;
}

export default function useStatusModal(asset?: Asset): UseStatusModalResult {
  const [visible, setVisible] = useState<boolean>(false);
  const [toggles, { set, updateAt }] = useList<boolean>([]);

  const closeModal = (): void => setVisible(false);
  const openModal = (): void => setVisible(true);

  useEffect(() => {
    if (asset && toggles.length === 0) {
      if (Array.isArray(asset) && asset.length) {
        set(new Array(asset?.length).fill(false));
      } else {
        set([true]);
      }
    }
  }, [asset, toggles, set]);

  const toggleAt = useCallback(
    (index: number, value: boolean) => {
      const newValue = value === undefined ? !toggles[index] : value;
      updateAt(index, !newValue);
    },
    [toggles, updateAt]
  );

  return {
    closeModal,
    openModal,
    toggles,
    toggleAt,
    visible,
  };
}
