import React from 'react';

import IconButton from '../../../../../../../../common/IconButton';
import type { HandleRemoveSavedItem } from '../../../../hooks/useDuplicateLineItemsState';
import { pickerElement } from '../../constants';

type Props = {
  id: string;
  handleRemoveSavedItem: HandleRemoveSavedItem;
  toggleRowSelected: (set?: boolean | undefined) => void;
};

const DeleteSavedLineItemCell = ({ id, handleRemoveSavedItem, toggleRowSelected }: Props): JSX.Element => {
  const handleClick = (): void => {
    toggleRowSelected(false);

    handleRemoveSavedItem(id);
  };

  return (
    <div className={pickerElement('delete-button')} data-testid="delete-saved-line-item-cell">
      <IconButton
        icon="IconDelete"
        variant="outlined"
        className={pickerElement('delete-icon')}
        data-testid="delete-saved-line-item-button"
        onClick={handleClick}
      />
    </div>
  );
};
export default DeleteSavedLineItemCell;
