import { computeRenderValue } from '../../../../common/EditableTableCells/EditableFrequencyCap/EditableFrequencyCap';
import { getTimezoneWithGMT } from '../../../../common/SelectTimeZone/utils';
import { viewerLocalTimeLabel } from '../../../../configs';
import { AlertFiled } from '../../TraffickingPage/cells/AlertCell/AlertCell';
import { getPublisherTargetingList, stringToDateTimeString } from '../../TraffickingPage/columns';
import { ALERT_FIELD_TO_DISPLAY_NAME_CONFIG } from '../constants';
import type { AdModelForExport } from '../convertors/ad';
import type { LineItemModelForExport } from '../convertors/lineItem';
import type { EntityModelForExport } from '../types';

type GetAlertMessageProps = {
  hasZeroDeliveryRisk?: boolean;
  hasMisalignedSchedule?: boolean;
  hasMissedLineItem?: boolean;
  hasLineItemExclusiveBlock?: boolean;
};

export const getAlertMessage = ({
  hasZeroDeliveryRisk,
  hasMisalignedSchedule,
  hasMissedLineItem,
  hasLineItemExclusiveBlock,
}: GetAlertMessageProps): string => {
  const alertMessages: string[] = [];

  if (hasZeroDeliveryRisk) {
    alertMessages.push(ALERT_FIELD_TO_DISPLAY_NAME_CONFIG[AlertFiled.ZeroDelivery]);
  }

  if (hasMisalignedSchedule) {
    alertMessages.push(ALERT_FIELD_TO_DISPLAY_NAME_CONFIG[AlertFiled.MisalignedSchedule]);
  }

  if (hasMissedLineItem) {
    alertMessages.push(ALERT_FIELD_TO_DISPLAY_NAME_CONFIG[AlertFiled.LineItem]);
  }

  if (hasLineItemExclusiveBlock) {
    alertMessages.push(ALERT_FIELD_TO_DISPLAY_NAME_CONFIG[AlertFiled.InventoryBlock]);
  }

  return alertMessages.join(', ');
};

export const getTrackingId = (model: EntityModelForExport): string => {
  return model.trackingId || '';
};

export const getScheduleTimezoneOffset = (model: AdModelForExport | LineItemModelForExport): string => {
  if (!model.schedule) return '';
  if (model.schedule?.deliveryInViewerTime) return viewerLocalTimeLabel;

  return getTimezoneWithGMT(model.schedule?.timezone);
};

export const getDisplayPublisherTarget = (model: AdModelForExport | LineItemModelForExport): string =>
  getPublisherTargetingList({ value: model.displayPublisherTarget });

export const getFrequencyCapList = (model: AdModelForExport | LineItemModelForExport): string =>
  model.frequencyCapList ? computeRenderValue(model.frequencyCapList).replace(',', '') : '';

export const getCreatedAt = (model: EntityModelForExport): string =>
  stringToDateTimeString({ value: model.createdAt }) || '';

export const getUpdatedAt = (model: EntityModelForExport): string =>
  stringToDateTimeString({ value: model.updatedAt }) || '';
