import './ScheduleOptions.scss';

import React from 'react';

import bem from '../../utils/bem';

const [block, element] = bem('schedule-options');

interface ScheduleOptionsProps {
  firstText: string;
  secondText: string;
  isFirstActive?: boolean;
}

function ScheduleOptions({ firstText, secondText, isFirstActive = false }: ScheduleOptionsProps): JSX.Element {
  return (
    <div className={block()}>
      <span className={element('option', isFirstActive ? 'active' : '')}>{firstText}</span>
      <span className={element('option', isFirstActive ? '' : 'active')}>{secondText}</span>
    </div>
  );
}

export default ScheduleOptions;
