import './AdList.scss';

import React, { useMemo, useState } from 'react';

import type { AdsPageAd } from '../../../../common/AdForm/hooks/types';
import SequenceWeight from '../../../../common/SequenceWeight';
import type { AdListData } from '../../../../common/SequenceWeight/types';
import { convertAdStartAndEndDateToRightFormat } from '../../../../common/utils';
import bem from '../../../../utils/bem';
import useFetchRotations from '../../hooks/useFetchRotations';
import AdListActionButtons from '../AdListActionButtons';
import AdListItems from '../AdListItems';
import { getAdListData } from '../utils/utils';
import { useAdList } from './hooks';

export interface AdListProps {
  ads: AdsPageAd[];
  lineItemId: string;
  error?: string;
  editAdId?: string;
}

const [block, element] = bem('ad-list');

function AdList({ ads, lineItemId, error, editAdId }: AdListProps): JSX.Element {
  const { rotations, loading: loadingRotations } = useFetchRotations(lineItemId);

  const { adList, createAd, deleteAd, selectAd, selectedAdIndex } = useAdList(ads, editAdId);

  const adListData: AdListData = useMemo(() => {
    if (ads && rotations) {
      const adsRotationWithTransformedStartAndEndDate = convertAdStartAndEndDateToRightFormat(ads);

      return getAdListData(adsRotationWithTransformedStartAndEndDate, rotations);
    }

    return { rotations: [], sequences: [], adsWithoutWeightOrSequence: [] };
  }, [ads, rotations]);

  const [sequenceModalOpen, setSequenceModalOpen] = useState<boolean>(false);

  const openSequenceModal = (): void => setSequenceModalOpen(true);
  const closeSequenceModal = (): void => setSequenceModalOpen(false);

  return (
    <div className={block()}>
      <div className={element('header')}>
        <h1>Ad List</h1>
        <AdListActionButtons adList={adList} openSequenceModal={openSequenceModal} createAd={createAd} />
      </div>
      <AdListItems
        adList={adList}
        error={error}
        selectedAdIndex={selectedAdIndex}
        onClickAd={selectAd}
        onClickDelete={deleteAd}
        adListData={adListData}
        loading={loadingRotations}
      />
      {sequenceModalOpen && (
        <SequenceWeight
          adListData={adListData}
          lineItemId={lineItemId}
          onCancel={closeSequenceModal}
          onOk={closeSequenceModal}
        />
      )}
    </div>
  );
}

export default AdList;
