import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

import type { AdTag, AdTypeV5, AdV5, ScheduleV5, TargetingTermValue } from '../../apis/graphql';
import { AdFormFields } from '../AdForm/adFormik/fields';
import type { CreativesBuilderValues } from '../AdForm/CreativeBuilders/types';
import { useFieldArray, useFieldFast } from '../Form/hooks';
import type { ClearableFrequencyCap } from '../FrequencyCaps/FrequencyCaps';
import { areFormInitialValueAndValueEqual } from '../utils';

export type ReturnedHookType = {
  areFormFieldsDirty: boolean;
};

/**
 * useTrackFormFieldChanges hook allows to keep track on Ad Form fields in order to compare initial and current form value and keeps track on Discard changes and Save Ad buttons disable/enable behavior based on form fields state
 */

export function useTrackFormFieldChanges(): ReturnedHookType {
  //Get initialValue and value for needed Ad Form fields
  const [, { initialValue: nameInitialValue, value: nameValue }] = useFieldFast<string>(AdFormFields.Name);
  const [, { initialValue: typeInitialValue, value: typeValue }] = useFieldFast<AdTypeV5>(AdFormFields.Type);
  const [, { initialValue: tagListInitialValue, value: tagListValue }] = useFieldArray<AdTag>(AdFormFields.AdTagList);
  const [, { initialValue: ratingInitialValue, value: ratingValue }] = useFieldFast<string>(AdFormFields.Rating);
  const [, { initialValue: deliveryStatusInitialValue, value: deliveryStatusValue }] = useFieldFast<boolean>(
    AdFormFields.DeliveryStatus
  );
  const [, { initialValue: targetingInitialValue, value: targetingValue }] = useFieldArray<TargetingTermValue>(
    AdFormFields.TargetingTemValue
  );
  const [, { initialValue: creativeInitialValue, value: creativeValue }] = useFieldArray<CreativesBuilderValues>(
    AdFormFields.Creative
  );
  const [, { initialValue: scheduleInitialValue, value: scheduleListValue }] = useFieldArray<ScheduleV5>(
    AdFormFields.Schedule
  );
  const [
    ,
    { initialValue: frequencyCapListInitialValue, value: frequencyCapListValue },
  ] = useFieldArray<ClearableFrequencyCap>(AdFormFields.FrequencyCupList);
  const [, { initialValue: vendorListInitialValue, value: vendorListValue }] = useFieldArray<
    AdV5[AdFormFields.ViewabilityVendorList]
  >(AdFormFields.ViewabilityVendorList);
  const [, { initialValue: timezoneInitialValue, value: timezoneValue }] = useFieldFast<string>('schedule.timezone');

  const sortedTargetingInitialValue = useMemo(
    () => sortBy(targetingInitialValue, ['category.id', 'dimension.id', 'value.id']),
    [targetingInitialValue]
  );

  const sortedTargetingValue = useMemo(() => sortBy(targetingValue, ['category.id', 'dimension.id', 'value.id']), [
    targetingValue,
  ]);

  // Determine whether initialValue and value are equal or not
  const isTagListEqual = areFormInitialValueAndValueEqual(tagListInitialValue, tagListValue);
  const isNameEqual = areFormInitialValueAndValueEqual(nameInitialValue, nameValue);
  const isTypeEqual = areFormInitialValueAndValueEqual(typeInitialValue, typeValue);
  const isRatingEqual = areFormInitialValueAndValueEqual(ratingInitialValue, ratingValue);
  const isDeliveryStatusEqual = areFormInitialValueAndValueEqual(deliveryStatusInitialValue, deliveryStatusValue);
  const isCreativeEqual = areFormInitialValueAndValueEqual(creativeInitialValue, creativeValue);
  const isTargetingEqual = areFormInitialValueAndValueEqual(sortedTargetingInitialValue, sortedTargetingValue);
  const isScheduleEqual = areFormInitialValueAndValueEqual(scheduleInitialValue, scheduleListValue);
  const isFrequencyCapListEqual = areFormInitialValueAndValueEqual(frequencyCapListInitialValue, frequencyCapListValue);
  const isVendorListEqual = areFormInitialValueAndValueEqual(vendorListInitialValue, vendorListValue);
  const isTimezoneEqual = areFormInitialValueAndValueEqual(timezoneInitialValue, timezoneValue);

  const areFormFieldsDirty =
    !isTagListEqual ||
    !isNameEqual ||
    !isTypeEqual ||
    !isRatingEqual ||
    !isDeliveryStatusEqual ||
    !isCreativeEqual ||
    !isTargetingEqual ||
    !isScheduleEqual ||
    !isFrequencyCapListEqual ||
    !isVendorListEqual ||
    !isTimezoneEqual;

  return { areFormFieldsDirty };
}
