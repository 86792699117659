import type { ScheduleV5 } from '../../../apis/graphql';
import type { Nullable } from '../../../models';
import { validateDateRanges } from '../../../utils/validation/dateRangeList';
import { validateDayParts } from '../../../utils/validation/dayPartList';
import type { AdFormValues } from '../adFormik';
import { AdFormScheduleFields } from '../adFormik/fields';
import type { ValidationError } from '../types';
import * as validationErrors from './const';

const generateScheduleErrorObject = (description: string, field: AdFormScheduleFields): ValidationError => ({
  message: validationErrors.AD_SCHEDULE_ERROR_MESSAGE,
  description,
  fields: [field],
});

export const validateAdSchedule = (
  schedule: AdFormValues['schedule'],
  lineItemSchedule: Nullable<ScheduleV5>
): Nullable<ValidationError> => {
  const { dateRangeList, dayPartList } = schedule;

  if (!lineItemSchedule) {
    return generateScheduleErrorObject(validationErrors.NO_LINE_ITEM, AdFormScheduleFields.DateRange);
  }

  if (dateRangeList.length > 0) {
    const error = validateDateRanges(lineItemSchedule, dateRangeList);
    if (error) return generateScheduleErrorObject(error, AdFormScheduleFields.DateRange);
  }

  if (dayPartList && dayPartList.length > 0) {
    const error = validateDayParts(dayPartList);

    if (error) return generateScheduleErrorObject(error, AdFormScheduleFields.DayPart);
  }

  return null;
};
