import { CreativeV5Type } from '../../../../apis/graphql';
import type { CreativesBuilderValues } from '../types';

const creativeTypesAllowsVastPreview = [
  CreativeV5Type.Drax,
  CreativeV5Type.Magnite,
  CreativeV5Type.VastVideo,
  CreativeV5Type.Brightline,
  CreativeV5Type.Innovid,
];

/**
 * Determines whether this is a Vast Creative based on its attributes
 */
export function checkIsVastPreviewAllowed(creative: CreativesBuilderValues, isPreviewMode: boolean): boolean {
  const creativeTypeAllowsVastPreview = creativeTypesAllowsVastPreview.some((type) => type === creative.type);

  const hasSavedUrl = !!(creative.id && creative.url && creative.isSubmitted);
  const hasNoVideo = !creative?.video?.id;

  return (
    isPreviewMode &&
    creativeTypeAllowsVastPreview &&
    (creative.type === CreativeV5Type.Magnite || creative.type === CreativeV5Type.Drax || hasSavedUrl) &&
    hasNoVideo
  );
}
