import React from 'react';

import type { Get_Ad_Permitted_TimezonesQuery } from '../../apis/graphql';
import { viewerLocalTimeLabel } from '../../configs';
import { AdFormScheduleFields } from '../AdForm/adFormik/fields';
import type { DropdownOption } from '../Dropdown';
import Dropdown from '../Dropdown';
import { useFieldFast } from '../Form/hooks';
import { DropdownOptions } from '../hooks/config';
import { useFetchDropdownOptions } from '../hooks/queries/useFetchDropdownOptions';
import TimezoneWithGMT from '../TimezoneWithGMT';
import { getSelectedTimezone } from './utils';

type Props = {
  editMode?: boolean;
  readonly?: boolean;
};

function SelectTimeZone({ editMode = true, readonly }: Props): JSX.Element {
  const [{ value: timezone }, , { setValue: setTimezone }] = useFieldFast<string>(AdFormScheduleFields.Timezone);
  const [{ value: deliveryInViewerTime }, , { setValue: setDeliveryInViewerTime }] = useFieldFast<boolean>(
    AdFormScheduleFields.DeliveryInViewerTime
  );

  const { options } = useFetchDropdownOptions<Get_Ad_Permitted_TimezonesQuery>(DropdownOptions.AdPermittedTimezones);

  if (!editMode) return <TimezoneWithGMT timezone={timezone} deliveryInViewerTime={deliveryInViewerTime} />;

  const optionsWithViewerLocalTime = [{ label: viewerLocalTimeLabel, value: timezone }, ...options];

  const selectedTimezone = getSelectedTimezone(timezone, deliveryInViewerTime, optionsWithViewerLocalTime);

  const onTimeZoneChange = (selectedTimeZone: DropdownOption | null): void => {
    if (selectedTimeZone?.label === viewerLocalTimeLabel) {
      return setDeliveryInViewerTime(true);
    }

    setDeliveryInViewerTime(false);
    setTimezone(selectedTimeZone?.value || '');
  };

  return (
    <Dropdown
      classNameModifier="select-timezone"
      options={optionsWithViewerLocalTime}
      isSearchable={false}
      isDisabled={readonly}
      value={selectedTimezone}
      onChange={onTimeZoneChange}
      dataTestId="timezone-dropdown"
      optionCompareKey={'label'}
    />
  );
}

export default SelectTimeZone;
