import { adTypes } from '../../../configs';
import {
  AD_SELECTOR_SLATE_AD_SELECTOR_VIDEO_AMOUNT_AD_SEQUENCE_ERROR,
  AD_TYPE_CONFLICT_AD_SEQUENCE_ERROR,
  CONFLICTING_PARENT_LINE_OMS_LINKS_AD_SEQUENCE_ERROR,
  EMPTY_AD_SEQUENCE_ERROR,
  SLATE_ON_TOP_AD_SEQUENCE_ERROR,
  SLATE_VIDEO_AMOUNT_AD_SEQUENCE_ERROR,
} from '../constants';
import type { SequenceAdV5, TransformedSequence } from '../types';

export const getDefaultErrorsState = (): Record<string, Set<string>> => {
  return {
    [SLATE_ON_TOP_AD_SEQUENCE_ERROR]: new Set(),
    [AD_TYPE_CONFLICT_AD_SEQUENCE_ERROR]: new Set(),
    [SLATE_VIDEO_AMOUNT_AD_SEQUENCE_ERROR]: new Set(),
    [AD_SELECTOR_SLATE_AD_SELECTOR_VIDEO_AMOUNT_AD_SEQUENCE_ERROR]: new Set(),
    [EMPTY_AD_SEQUENCE_ERROR]: new Set(),
    [CONFLICTING_PARENT_LINE_OMS_LINKS_AD_SEQUENCE_ERROR]: new Set(),
  };
};

const FIRST_AD_VARIANTS: string[] = [adTypes.SLATE.key, adTypes.AD_SELECTOR_SLATE.key];

// property name is a type of an ad to validate
// min shows, if quantity number should be minimum or exact amount of ads
type AdSequenceValidationRule = Record<
  string,
  {
    min: boolean;
    quantity: number;
  }
>;

// property name is an error, that will be shown
export const AD_SEQUENCE_VALIDATION_RULES: Record<string, AdSequenceValidationRule> = {
  [SLATE_VIDEO_AMOUNT_AD_SEQUENCE_ERROR]: {
    [adTypes.SLATE.key]: {
      min: false,
      quantity: 1,
    },
    [adTypes.VIDEO.key]: {
      min: true,
      quantity: 1,
    },
  },
  [AD_SELECTOR_SLATE_AD_SELECTOR_VIDEO_AMOUNT_AD_SEQUENCE_ERROR]: {
    [adTypes.AD_SELECTOR_SLATE.key]: {
      min: false,
      quantity: 1,
    },
    [adTypes.AD_SELECTOR_VIDEO.key]: {
      min: true,
      quantity: 2,
    },
  },
};

// get rules key to use according to SLATE/VIDEO or AD_SELECTOR_SLATE/AD_SELECTOR_VIDEO combinations
export const getAdSequenceValidationKey = (type: string): string | null => {
  return (
    Object.keys(AD_SEQUENCE_VALIDATION_RULES).find((key) => {
      return Object.keys(AD_SEQUENCE_VALIDATION_RULES[key]).includes(type);
    }) || null
  );
};

// check, if ad quantities meets rule
export const validateAdsQuantities = (
  rule: AdSequenceValidationRule,
  adQuantities: Record<string, number>
): boolean => {
  let isValid = true;

  // check, if ad sequence to contain all ad types with need amount of quantity
  Object.entries(rule).forEach((entries) => {
    const [key, value] = entries;
    const adQuantity = adQuantities[key];

    if (
      !adQuantity ||
      (adQuantity && value.quantity > adQuantity) ||
      (adQuantity && !value.min && value.quantity !== adQuantity)
    ) {
      isValid = false;
    }
  });

  return isValid;
};

// calculate ad quantities and check, if there are ads out of the combination
export const calculateAdQuantities = (
  rule: AdSequenceValidationRule,
  ads: SequenceAdV5[]
): { errors: string[]; adQuantities: Record<string, number> } => {
  const errors: Set<string> = new Set();
  const uniqueParentLineOmsLink: Set<string> = new Set();
  const initialQuantities: Record<string, number> = {};

  // calculate amount of appearances by each type in the ad sequence
  const adQuantities = ads.reduce((acc, current) => {
    // check, if ad sequence has ads that are not in the combination
    if (rule[current.type]) {
      acc[current.type] = acc[current.type] ? ++acc[current.type] : 1;
      if (current.lineItem?.parentLineOmsLink?.id) uniqueParentLineOmsLink.add(current.lineItem?.parentLineOmsLink?.id);
    } else {
      errors.add(AD_TYPE_CONFLICT_AD_SEQUENCE_ERROR);
    }

    return acc;
  }, initialQuantities);

  // check, if all ads have the same parent line oms link
  if (uniqueParentLineOmsLink.size > 1) {
    errors.add(CONFLICTING_PARENT_LINE_OMS_LINKS_AD_SEQUENCE_ERROR);
  }

  return {
    adQuantities,
    errors: Array.from(errors),
  };
};

export const validateSequences = (sequences: TransformedSequence[]): Record<string, Set<string>> => {
  // error messages with ids of sequences that contain errors
  const errorsWithSequenceIds: Record<string, Set<string>> = getDefaultErrorsState();

  sequences.forEach(({ id, ads }) => {
    if (ads.length) {
      // check, if SLATE ad is on top
      if (!FIRST_AD_VARIANTS.includes(ads[0].type)) {
        errorsWithSequenceIds[SLATE_ON_TOP_AD_SEQUENCE_ERROR].add(id);
      } else {
        // get current rule error key
        const adSequenceValationKey = getAdSequenceValidationKey(ads[0].type);

        if (adSequenceValationKey) {
          // get rule object
          const validationRule = AD_SEQUENCE_VALIDATION_RULES[adSequenceValationKey];

          const { errors: adQuantitiesErrors, adQuantities } = calculateAdQuantities(validationRule, ads);

          if (!adQuantitiesErrors.length) {
            if (!validateAdsQuantities(validationRule, adQuantities)) {
              errorsWithSequenceIds[adSequenceValationKey].add(id);
            }
          } else {
            adQuantitiesErrors.forEach((error) => {
              errorsWithSequenceIds[error].add(id);
            });
          }
        }
      }
    }
    // if ad sequence is empty, show the error
    else {
      errorsWithSequenceIds[EMPTY_AD_SEQUENCE_ERROR].add(id);
    }
  });

  return errorsWithSequenceIds;
};
