import type { AssetExtended } from '../../../../_ForCommonLibrary/types/extended';
import type { IngestionTrial } from '../../../../../apis/graphql';

export function getTrialsWithoutAssets(
  baseAssets?: AssetExtended[],
  ingestionTrials?: IngestionTrial[]
): IngestionTrial[] {
  const ingestionTrialList =
    ingestionTrials?.filter((t) => !baseAssets?.find((a) => a.id.replace(/-/g, '') === t.assetId?.replace(/-/g, ''))) ||
    [];

  return ingestionTrialList;
}
