import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import type { GetRotationsListQuery, GetRotationsListQueryVariables, Rotation } from '../../../apis/graphql';
import type { LineItem } from '../../../models';
import { GET_ROTATIONS_LIST } from './queries/getRotations';

interface FetchRotationsResponse {
  rotations: Rotation[];
  error?: ApolloError;
  loading: boolean;
}

export default function useFetchRotations(lineItemId: LineItem['id'], skip: boolean = false): FetchRotationsResponse {
  const { loading, error, data } = useQuery<GetRotationsListQuery, GetRotationsListQueryVariables>(GET_ROTATIONS_LIST, {
    variables: {
      lineItemId,
    },
    skip,
  });

  const rotations = useMemo(() => (data ? data.getRotationsV5 : []), [data]);

  return { rotations, loading, error };
}
