import { gql } from '@apollo/client';

export const GET_ADS_WITH_CREATIVE_BY_PARENT_LINE_OMS_LINK = gql`
  query GET_ADS_WITH_CREATIVE_BY_PARENT_LINE_OMS_LINK(
    $lineItemParentLineOmsLinkList: [ID!]!
    $adTypeList: [String!]
    $creativeName: String
  ) {
    adsV5(
      filterOptions: {
        lineItemParentLineOmsLinkList: $lineItemParentLineOmsLinkList
        adTypeList: $adTypeList
        creativeName: $creativeName
      }
    ) {
      edges {
        node {
          id
          creative {
            id
            name
          }
        }
      }
    }
  }
`;
