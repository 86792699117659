import type { AdV5, Get_Ads_By_Line_Item_IdsQuery } from '../../../../../../../../../../apis/graphql';
import { adStatuses, lineItemStatuses } from '../../../../../../../../../../configs';
import type { LineItemModel } from '../../../../../../../../TraffickingPage/modelConverters';
import type { MultipleEditDrawerLineItemsFormValues } from '../../../../../../types';
import type { UseCheckConflictsParams } from '../../../types';

type NormalizedAds = {
  lineItemId?: LineItemModel['id'];
  hasActiveAd: boolean;
};

type AdNode = {
  node: Pick<AdV5, 'status'> & {
    lineItem: Pick<LineItemModel, 'id'> | null;
  };
};

export const getAds = (data?: Get_Ads_By_Line_Item_IdsQuery): NormalizedAds[] | undefined => {
  const getNormalizedAds = (acc: NormalizedAds[], { node }: AdNode): NormalizedAds[] => [
    ...acc,
    {
      lineItemId: node.lineItem?.id,
      hasActiveAd: node.status === adStatuses.ON.key,
    },
  ];

  return data?.ads.edges.reduce<NormalizedAds[]>(getNormalizedAds, []);
};

export const getLineItemsToBeActivated = (
  values: UseCheckConflictsParams['values']
): MultipleEditDrawerLineItemsFormValues['entities'] =>
  values.entities.filter((lineItemWithActivatedStatus) => {
    const originLineItem = values.initialStatuses.find((lineItem) => lineItem.id === lineItemWithActivatedStatus.id);

    return (
      lineItemWithActivatedStatus.status?.key === lineItemStatuses.ON.key &&
      originLineItem?.status?.key !== lineItemStatuses.ON.key
    );
  });
