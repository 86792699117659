import { validateDateRanges, VALIDATION_ERROR } from '../../../../../../../../../../utils/validation';
import type { MultipleEditDrawerAdsFormValues } from '../../../../../../types';
import { AD_SCHEDULE_HAS_CONFLICT_WITH_LINE_ITEM, AD_SCHEDULING_SECOND_PART_MESSAGE } from '../../getRows/constants';

export const checkSchedulingConflicts = ({
  entities,
  schedule,
  selectedSections,
}: MultipleEditDrawerAdsFormValues): Record<string, string[]> | null => {
  const isSectionSelected = selectedSections.includes('Scheduling');
  if (!isSectionSelected) {
    return null;
  }

  const errors: Record<string, string[]> = {};

  if (schedule && schedule.dateRangeList.length && schedule.dateRangeList[0].startDate) {
    entities.forEach(({ lineItem, id }) => {
      const error = validateDateRanges(lineItem, schedule.dateRangeList);

      if (error) {
        const concatError =
          error === VALIDATION_ERROR.noOutsideSchedule
            ? AD_SCHEDULE_HAS_CONFLICT_WITH_LINE_ITEM
            : error + AD_SCHEDULING_SECOND_PART_MESSAGE;
        errors[concatError] = errors[concatError] ? [...errors[concatError], id] : [id];
      }
    });
  }

  return Object.keys(errors).length ? errors : null;
};
