import { gql } from '@apollo/client';

// Data for Targeting Dropdowns
export const GET_TARGETING_VALUE_BY_DIMENSION_AND_VALUE_ID = gql`
  query getTargetingValuesByDimensionAndValueID($dimensionGuid: String!, $valueId: String!) {
    getTargetingValuesByDimensionAndValueID(dimensionGuid: $dimensionGuid, valueId: $valueId) {
      id
      name
      displayName
      primarySource {
        id
        name
        system
      }
      additionalSource {
        id
        name
        system
      }
    }
  }
`;
