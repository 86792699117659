import './IASCampaignLineItemIdField.scss';

import { bem } from 'mission-control-common-components';

import { TextField } from '../../../Form';
import { AdFormFields } from '../../adFormik/fields';
import { IAS_CAMPAIGN_LINE_ITEM_ID_FIELD_NAME } from '../hooks';

const [block, element] = bem('ias-campaign-line-item-id-field');

type Props = {
  value: string;
  isDisabled?: boolean;
  handleChange: (value: string) => void;
};

const IASCampaignLineItemIdField = ({ value, isDisabled, handleChange }: Props): React.JSX.Element => {
  const highlightedText: React.JSX.Element = (
    <span className={element('helper-text', ['highlighted'])}>123456/87654321</span>
  );

  const help: React.JSX.Element = (
    <div className={element('helper-text')}>
      <div>Copy and paste the campaign ID and line item ID from the IAS tag.</div>
      <div className={element('extra-helper-text')}>
        EX: 'https://pixel.adsafeprotected.com/rjss/st/
        {highlightedText}/skeleton.js'. <br />
      </div>
      <div className={element('extra-helper-text')}>Copy {highlightedText} from IAS tag and paste into this field.</div>
    </div>
  );

  return (
    <div className={block()}>
      <TextField
        id={IAS_CAMPAIGN_LINE_ITEM_ID_FIELD_NAME}
        name={IAS_CAMPAIGN_LINE_ITEM_ID_FIELD_NAME}
        formFieldName={AdFormFields.IASCampaignLineItemId}
        label="IAS Campaign / Line Item Id"
        onChange={(e): void => handleChange(e.target.value)}
        disabled={!!isDisabled}
        data-testid="ias-campaign-line-item-id-field"
        value={value}
        help={help}
      />
    </div>
  );
};

export default IASCampaignLineItemIdField;
