import type {
  BulkCreateSequencesV5Mutation,
  BulkDeleteSequencesV5Mutation,
  BulkUpdateSequencesV5Mutation,
  Get_Ads_By_Parent_Line_Oms_Link_Id_ListQuery,
  SequenceInput,
} from '../../../apis/graphql';
import { getErrorMessageFromGraphQlErrors } from '../../../apis/graphql';
import { showFailure } from '../../../common/utils';
import { adTypes } from '../../../configs';
import { NEW_SEQUENCE_ID, NEW_SEQUENCE_LABEL, UNSEQUENCED_TABLE_ID } from '../constants';
import type { SequenceAdV5, TransformedSequence } from '../types';
import type { ChangeOrderProps } from './useSequenceForm';
import type { SaveChangesResults, SubmitError } from './useSequencePage';

type GetUpdatedUnsequencedAdsProps = ChangeOrderProps & {
  unsequencedAds: SequenceAdV5[];
};

type GetUpdatedSequencesProps = ChangeOrderProps & {
  sequences: TransformedSequence[];
};

type ApplySequenceMutationProps = {
  mutation: Function;
  inputs: SequenceInput[] | string[];
  errorMessage: string;
};

export const getRotatedUnsequencedAds = ({
  unsequencedAds,
  source,
  destination,
  draggedAd,
}: GetUpdatedUnsequencedAdsProps): SequenceAdV5[] => {
  const updatedUnsequencedAds = [...unsequencedAds];

  if (source.droppableId === UNSEQUENCED_TABLE_ID) {
    updatedUnsequencedAds.splice(source.index, 1);
  }

  if (destination.droppableId === UNSEQUENCED_TABLE_ID) {
    updatedUnsequencedAds.splice(destination.index, 0, { ...draggedAd });
  }

  return updatedUnsequencedAds;
};

export const getRotatedSequences = ({
  sequences,
  source,
  destination,
  draggedAd,
}: GetUpdatedSequencesProps): TransformedSequence[] => {
  return sequences.map((sequence, index) => {
    const ads = [...sequence.ads];

    if (index === Number(source.droppableId)) {
      ads.splice(source.index, 1);
    }

    if (index === Number(destination.droppableId)) {
      ads.splice(destination.index, 0, { ...draggedAd });
    }

    return {
      ...sequence,
      ads,
    };
  });
};

export const getSortedUnsequencedAds = (ads: SequenceAdV5[]): SequenceAdV5[] => {
  return ads.sort((a, b) => {
    const isSlateType = a.type === adTypes.SLATE.key && b.type !== adTypes.SLATE.key;
    const isAdSelectorSlateType =
      a.type === adTypes.AD_SELECTOR_SLATE.key &&
      b.type !== adTypes.AD_SELECTOR_SLATE.key &&
      b.type !== adTypes.SLATE.key;
    const isVideoType = a.type === adTypes.VIDEO.key && b.type === adTypes.AD_SELECTOR_VIDEO.key;

    if (isSlateType || isAdSelectorSlateType || isVideoType) return -1;

    if (a.adRotation && !b.adRotation) return 1;

    if (!a.adRotation && b.adRotation) return -1;

    return 0;
  });
};

export const getSortedAdsByOrder = (ads: SequenceAdV5[]): SequenceAdV5[] => {
  return ads.sort((a, b) => (a.adSequence && b.adSequence && a.adSequence?.order > b.adSequence?.order ? 1 : -1));
};

export const checkErrors = (errors: Record<string, Set<string>>): boolean => {
  let isValid = true;

  Object.values(errors).forEach((error) => {
    if (error.size > 0) {
      isValid = false;
    }
  });

  return isValid;
};

export const getSequenceInput = ({ id, name, ads }: TransformedSequence, sequenceIndex: number): SequenceInput => {
  const sequenceInput: SequenceInput = {
    type: 'PRODUCT',
    name: name ? name : NEW_SEQUENCE_LABEL + (sequenceIndex + 1),
    adSequence: ads.map(({ id }, index) => ({
      order: index + 1,
      adId: id,
    })),
  };

  if (!id.includes(NEW_SEQUENCE_ID)) {
    sequenceInput.id = id;
  }

  return sequenceInput;
};

export const getSequenceDataToSave = (
  sequences: TransformedSequence[],
  initialSequences: TransformedSequence[],
  unsequencedAds: SequenceAdV5[]
): {
  createInputs: SequenceInput[];
  updateInputs: SequenceInput[];
  deleteInputs: string[];
  updateSequenceIds: Set<string>;
} => {
  const createInputs: SequenceInput[] = [];
  const updateInputs: SequenceInput[] = [];
  const deleteInputs: Set<string> = new Set();
  const updateSequenceIds: Set<string> = new Set();

  sequences.forEach((sequence, index) => {
    if (sequence.id.includes(NEW_SEQUENCE_ID)) {
      createInputs.push(getSequenceInput(sequence, index));
    } else {
      updateInputs.push(getSequenceInput(sequence, index));
      updateSequenceIds.add(sequence.id);
    }
  });

  initialSequences.forEach(({ id }) => {
    if (!updateSequenceIds.has(id)) {
      deleteInputs.add(id);
    }
  });

  unsequencedAds.forEach(({ adSequence }) => {
    if (adSequence && !updateSequenceIds.has(adSequence.sequenceId)) {
      deleteInputs.add(adSequence.sequenceId);
    }
  });

  return {
    createInputs,
    updateInputs,
    deleteInputs: Array.from(deleteInputs),
    updateSequenceIds,
  };
};

export const showErrorToasts = (errors: (SubmitError | null)[]): void => {
  errors.forEach((error) => {
    if (error) {
      showFailure({ message: error.message, description: error.description });
    }
  });
};

// used to handle create/update/delete mutations in the unified format
export const applySequenceMutation = async <
  T extends BulkCreateSequencesV5Mutation | BulkUpdateSequencesV5Mutation | BulkDeleteSequencesV5Mutation
>({
  mutation,
  inputs,
  errorMessage,
}: ApplySequenceMutationProps): Promise<SaveChangesResults<T>> => {
  if (inputs.length) {
    const { errors, data } = await mutation({
      variables: { inputs },
    });

    return {
      error: errors
        ? {
            message: errorMessage,
            description: getErrorMessageFromGraphQlErrors(errors),
          }
        : null,
      data: data || null,
    };
  }

  return {
    error: null,
    data: null,
  };
};

export const getSortedAdsEdgesBySavedAdsOrder = (
  data: Get_Ads_By_Parent_Line_Oms_Link_Id_ListQuery,
  savedAdsOrder: Map<string, number>
): Get_Ads_By_Parent_Line_Oms_Link_Id_ListQuery['ads']['edges'] => {
  return [...data.ads.edges].sort((a, b) => {
    const aOrder = savedAdsOrder.get(a.node.id) || -1;
    const bOrder = savedAdsOrder.get(b.node.id) || -1;

    if (aOrder === bOrder) return 0;
    return aOrder > bOrder ? 1 : -1;
  });
};
