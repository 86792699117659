import React, { useMemo } from 'react';

import type { LineItemList, Nullable } from '../../../../../models';
import { getUniqueCountriesString } from './utils';

type CountryCellProps = {
  lineItemList?: Nullable<LineItemList[]>;
};

const CountryCell = ({ lineItemList }: CountryCellProps): React.JSX.Element => {
  const countryValue = useMemo(() => getUniqueCountriesString(lineItemList), [lineItemList]);

  return <span title={countryValue}>{countryValue}</span>;
};

export default CountryCell;
