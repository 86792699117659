import './SequenceCell.scss';

import type { Location } from 'history';
import { bem } from 'mission-control-common-components';
import React, { memo } from 'react';

import EditableCell from '../../../../../common/EditableTableCells/EditableCell';
import useNavigationWithLocalStorage from '../../../../../common/hooks/useNavigationWithLocalStorage';
import Tooltip from '../../../../../common/Tooltip';
import { PathName } from '../../../../../constants';
import type { SequenceLocationState } from '../../../../Sequence/SequencePage';

export type SequenceCellProps = {
  parentLineOmsLinkId?: string;
  title?: string | number;
};

const [block, element] = bem('sequence-cell');

export const SEQUENCE_CELL_TOOLTIP_MESSAGE = 'Edit in MC Sequencing pages';

const SequenceCell = ({ parentLineOmsLinkId, title }: SequenceCellProps): React.JSX.Element => {
  const { navigateWithLocalStorage } = useNavigationWithLocalStorage();

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    if (parentLineOmsLinkId) {
      const locationState: SequenceLocationState = {
        uniqueParentLineOmsLinkIds: [parentLineOmsLinkId],
      };

      const location: Location = {
        pathname: PathName.sequence,
        state: {
          locationState,
        },
        key: '',
        search: '',
        hash: '',
      };

      navigateWithLocalStorage(e, location, locationState);
    }
  };

  return (
    <div className={block()} onClick={handleClick}>
      <Tooltip
        delayShow={700}
        delayHide={0}
        id={'sequence-cell-tooltip'}
        message={SEQUENCE_CELL_TOOLTIP_MESSAGE}
        disable={!parentLineOmsLinkId}
      >
        <EditableCell variant={'base'} tabIndex={0} usePencil={!!parentLineOmsLinkId}>
          <div data-testid="sequence-cell-title" className={element('title')}>
            {title ?? ''}
          </div>
        </EditableCell>
      </Tooltip>
    </div>
  );
};

export default memo(SequenceCell);
