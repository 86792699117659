import './MultipleEditDrawerContent.scss';

import React from 'react';

import bem from '../../../../../utils/bem';
import type { EntityTypeArray } from '../../../TraffickingPage/hooks/makePageDrawerPlugin';
import { Steps } from '../constants';
import { isActiveStep } from '../utils';
import {
  MultipleEditDrawerContentFirstStep,
  MultipleEditDrawerContentSecondStep,
  MultipleEditDrawerContentThirdStep,
} from './steps';

const [block] = bem('multiple-edit-drawer-content');

type MultipleEditDrawerContentProps = {
  activeStep: Steps;
  isExpanded: boolean;
  entityType: EntityTypeArray;
  onNextStep: () => void;
  onSubmit: () => void;
};

export default function MultipleEditDrawerContent({
  activeStep,
  entityType,
  isExpanded,
  onNextStep,
  onSubmit,
}: MultipleEditDrawerContentProps): JSX.Element {
  return (
    <div className={block()}>
      {isActiveStep(activeStep, Steps.SELECT) && (
        <MultipleEditDrawerContentFirstStep onNextStep={onNextStep} entityType={entityType} />
      )}
      {isActiveStep(activeStep, Steps.EDIT) && (
        <MultipleEditDrawerContentSecondStep onNextStep={onNextStep} isExpanded={isExpanded} entityType={entityType} />
      )}
      {isActiveStep(activeStep, Steps.CONFIRM) && (
        <MultipleEditDrawerContentThirdStep onSubmit={onSubmit} entityType={entityType} />
      )}
    </div>
  );
}
