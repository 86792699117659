import type { ChangeLogUnionObject, SingleValueChange } from '../../../../apis/graphql';
import { joinTermsByDimensionIdFromObject } from '../../utils';

/**
 * In case only 'not' field was changed, API does not provides us with dimension and valueSet values.
 * Without this values we cannot show what object was actually changed.
 * In this function we fetch dimension and valueSet from 'objectJoinedByTerms' which originates from 'object' we receive from API
 * Also, in this function we add 'not' fields to those objects which do not have it
 */

export function modifyTargetingRule(
  restructuredChange: SingleValueChange,
  object: ChangeLogUnionObject,
  previousObject: ChangeLogUnionObject | null
): void {
  type Term = {
    dimension?: string;
    'value-set'?: string[];
    'range-list'?: { lower: number; upper: number }[];
    not?: boolean;
  };

  const objectJoinedByTerms = joinTermsByDimensionIdFromObject(object);
  const previousObjectJoinedByTerms = previousObject ? joinTermsByDimensionIdFromObject(previousObject) : null;

  [restructuredChange.newValue, restructuredChange.oldValue].forEach((valueType, valueTypeIndex) => {
    if (valueType?.payload?.definition?.['term-list']) {
      const filledTermList = valueType.payload.definition['term-list'].map((term: Term, index: number) => {
        // case when only 'not' field is present
        if (term && Object.keys(term).length === 1 && Object.keys(term)[0] === 'not') {
          return {
            ...term,
            dimension: objectJoinedByTerms[index].dimensionId,
            'value-set': objectJoinedByTerms[index].valuesIds,
          };
        }

        // case when new payload has 'dimension' and 'values', but doesn't have 'not'
        if (term && !valueTypeIndex && !term.hasOwnProperty('not')) {
          return {
            ...term,
            not: objectJoinedByTerms[index]?.not,
          };
        }

        // case when old payload has 'dimension' and 'values', but doesn't have 'not'
        if (term && valueTypeIndex && !term.hasOwnProperty('not') && term.hasOwnProperty('dimension')) {
          const notPreviousValue = previousObjectJoinedByTerms
            ? previousObjectJoinedByTerms?.find((previousTerm) => previousTerm.dimensionId === term.dimension!)?.not
            : false;
          return {
            ...term,
            not: notPreviousValue ?? false,
          };
        }

        return term;
      });

      valueType.payload.definition['term-list'] = filledTermList;
    }
  });
}
