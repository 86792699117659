import { findIndex } from 'lodash';

import type { Maybe, ScheduleV5, SingleValueChange } from '../../../../apis/graphql';
import type { MultiValueChange } from '../../ChangeLogTable/ChangeLogTable';
import { getFormattedDayPartChangedDays } from '../util';
import { createDatesChangelog, getTimeZoneAndViewerLocalTimeChanges } from './utils';

type Props = {
  change: SingleValueChange;
  restructuredChangeList: Array<SingleValueChange | MultiValueChange>;
  scheduleBefore: Maybe<ScheduleV5>;
  scheduleAfter: Maybe<ScheduleV5>;
  entityType?: string;
};

export function modifySchedule({ restructuredChangeList, scheduleBefore, scheduleAfter, entityType }: Props): void {
  const dayPartListBefore = scheduleBefore?.dayPartList || [];
  const dayPartListAfter = scheduleAfter?.dayPartList || [];

  const dateRangeListBefore = scheduleBefore?.dateRangeList || [];
  const dateRangeListAfter = scheduleAfter?.dateRangeList || [];

  const timezoneBefore = scheduleBefore?.timezone;
  const timezoneAfter = scheduleAfter?.timezone;

  const deliveryInViewerTimeBefore = scheduleBefore?.deliveryInViewerTime;
  const deliveryInViewerTimeAfter = scheduleAfter?.deliveryInViewerTime;

  const dayPartObject: SingleValueChange = getFormattedDayPartChangedDays(dayPartListBefore, dayPartListAfter);

  const timezoneChanges = getTimeZoneAndViewerLocalTimeChanges({
    timezoneBefore,
    timezoneAfter,
    deliveryInViewerTimeBefore,
    deliveryInViewerTimeAfter,
  });

  if (timezoneChanges.length) {
    restructuredChangeList.push(...timezoneChanges);
  }

  const dateRangeObject = createDatesChangelog({
    dateRangeListBefore: dateRangeListBefore,
    dateRangeListAfter: dateRangeListAfter,
    entityType,
  });

  // If Old or New Exist, include it, else, don't include
  let index = findIndex(restructuredChangeList, { fieldName: 'schedule' });

  if (index === -1) {
    if (dateRangeObject.oldValue.payload || dateRangeObject.newValue.payload)
      restructuredChangeList.push(dateRangeObject);
    if (dayPartObject.oldValue.payload || dayPartObject.newValue.payload) restructuredChangeList.push(dayPartObject);
  } else {
    restructuredChangeList.splice(index, 1, dayPartObject, dateRangeObject);
  }
}
