import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

export type CurrencyTypes = keyof typeof currencyTypeDisplayNames;

const currencyTypeDisplayNames = {
  USD: 'USD',
  ARS: 'ARS',
  BRL: 'BRL',
  CLP: 'CPL',
  COP: 'COP',
  MXN: 'MXN',
};

export const currencyTypes = makeConfig<CurrencyTypes>(currencyTypeDisplayNames);

export function convertCurrencyTypes(apiValue: string): DisplayEnum<CurrencyTypes> {
  return convertToDisplayEnum(apiValue, currencyTypes);
}
