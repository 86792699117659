import type { MultipleEditDrawerLineItemsFormValues } from '../../../../types';
import type { TargetingResult } from '../types';

type GetTargetingValuesParams = {
  formTargeting: MultipleEditDrawerLineItemsFormValues['targetingTermValues'];
};

export const getTargetingValues = ({ formTargeting }: GetTargetingValuesParams): TargetingResult => {
  return formTargeting.reduce<TargetingResult>(
    (acc, currentTarget) => {
      if (currentTarget.include) {
        return { ...acc, include: [...acc.include, currentTarget.value.displayName] };
      }
      if (!currentTarget.include) {
        return { ...acc, exclude: [...acc.exclude, currentTarget.value.displayName] };
      }

      return acc;
    },
    { include: [], exclude: [] }
  );
};
