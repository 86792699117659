/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { IconCalendar } from '@hulu-react-style-components/icons';
import envConfig from '@hulu/env-config';
import * as React from 'react';
import type { Cell } from 'react-table';

import { getTimezoneWithGMT } from '../../../../common/SelectTimeZone/utils';
import { resizingData, stickyBlock } from '../../../../common/TraffickingTable/utils';
import { pacingTypes } from '../../../../configs';
import { TraffickingTableName } from '../../../../constants';
import bem from '../../../../utils/bem';
import { DEFAULT_COLUMN_MIN_WIDTH } from '../../constants';
import { LineItemColumn } from '../../constants/lineItems';
import {
  AlertCell,
  CreateAndEditAdCell,
  DeliveryForensicsCell,
  LineItemFrequencyCapCell,
  LineItemStatusCell,
  NameCell,
  PacingStatusCell,
  PacingTypeCell,
  PercentCell,
  PriorityNameCell,
  ReviewCell,
  TierNameCell,
} from '../cells';
import type { AlertFlagProps } from '../cells/AlertCell';
import { AlertFiled } from '../cells/AlertCell/AlertCell';
import InventoryBlockCell from '../cells/InventoryBlockCell';
import YodaPacingStatusCell from '../cells/YodaPacingStatusCell';
import { EntityType } from '../hooks/makePageDrawerPlugin';
import type { LineItemModel } from '../modelConverters';
import { CellType } from '../types';
import type { CustomColumnConfig } from './utils';
import {
  boolToYes,
  formatStartEndDateString,
  formatUTCToDefaultTimezone,
  getAccountRelatedColumnAccessor,
  getIdFromTableCell,
  getLineItemThreshold,
  getMetricsPercentRelatedColumnAccessor,
  getMetricsRelatedColumnAccessor,
  getPublisherTargetingList,
  microCurrencyToDollarAmount,
  numberToDollarAmount,
  numberToPercentage,
  stringToDateTimeString,
} from './utils';

const [, element] = bem('alert-cell');

export const getLineItemsAlertFlags = ({
  id,
  hasMisalignedSchedule,
  hasZeroDeliveryRisk,
  exclusiveBlock,
}: LineItemModel): AlertFlagProps[] => {
  return [
    {
      id,
      field: AlertFiled.ZeroDelivery,
      isShown: !!hasZeroDeliveryRisk,
      hoverMessage: 'This line item has not delivered any impressions in the last 12 hours.',
    },
    {
      id,
      field: AlertFiled.MisalignedSchedule,
      isShown: !!hasMisalignedSchedule,
      icon: <IconCalendar width={18} height={18} />,
      hoverMessage: 'This line item has ads with a misaligned schedule.',
      className: element('red-icon'),
    },
    {
      id,
      field: AlertFiled.InventoryBlock,
      icon: <InventoryBlockCell exclusiveBlock={exclusiveBlock} />,
      isShown: !!exclusiveBlock,
      hoverMessage: 'This line item has an inventory block.',
      className: element('red-icon', 'clickable'),
    },
  ];
};

const commonBooleanCellConfig = (id: string) => ({
  Cell: boolToYes,
  width: resizingData.getData(175, TraffickingTableName.lineItems, id),
  minWidth: DEFAULT_COLUMN_MIN_WIDTH,
});

// NOTE: `Cell` property cannot return `undefined` or the page will crash
const getLineItemsColumns = (): CustomColumnConfig<LineItemModel>[] => {
  const columns: CustomColumnConfig<LineItemModel>[] = [
    {
      id: LineItemColumn.Alert,
      Header: 'Alert',
      Cell: (cell: Cell<LineItemModel>) => <AlertCell flags={getLineItemsAlertFlags(cell.row.original)} />,
      width: resizingData.getData(65, TraffickingTableName.lineItems, 'Alert'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.Name,
      accessor: 'name',
      Header: 'Line Item Name',
      Cell: ({ row: { original }, openTraffickingPageDrawer }) => (
        <NameCell
          rowOriginal={original}
          entityType={EntityType.LINE_ITEM}
          openTraffickingPageDrawer={openTraffickingPageDrawer}
        />
      ),
      disableHiding: true,
      width: resizingData.getData(360, TraffickingTableName.lineItems, 'Name'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      className: stickyBlock('name'),
    },
    {
      id: LineItemColumn.CreateAd,
      Header: 'Create Ad',
      Cell: (cell: Cell<LineItemModel>) => <CreateAndEditAdCell cell={cell} cellType={CellType.create} />,
      width: resizingData.getData(100, TraffickingTableName.lineItems, 'CreateAd'),
    },
    {
      id: LineItemColumn.Country,
      accessor: ({ country }) => country || '',
      Header: 'Country',
      width: resizingData.getData(100, TraffickingTableName.lineItems, 'Country'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.DeliverableLineItemPackageName,
      accessor: (li) => li.orderLineItemDto?.packageName,
      Header: 'OMS Package Name',
      width: resizingData.getData(300, TraffickingTableName.lineItems, 'DeliverableLineItemPackageName'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.TrackingId,
      accessor: 'trackingId',
      Header: 'Tracking ID',
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'TrackingId'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.ParentOmsLinkId,
      accessor: (li) => li.parentLineOmsLink?.id,
      Header: 'Parent OMS ID',
      width: resizingData.getData(165, TraffickingTableName.lineItems, 'ParentOmsLinkId'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.Status,
      Header: 'Status',
      accessor: (li) => li.status,
      isEditable: true,
      Cell: LineItemStatusCell,
      width: resizingData.getData(120, TraffickingTableName.lineItems, 'Status'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.Review,
      accessor: (li) => li.review,
      Header: 'Review',
      Cell: ReviewCell,
      width: resizingData.getData(150, TraffickingTableName.lineItems, 'Review'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.ScheduleStart,
      accessor: (li) => li.startDate,
      Header: 'Start Date',
      Cell: formatStartEndDateString,
      width: resizingData.getData(135, TraffickingTableName.lineItems, 'ScheduleStart'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.ActualStartDate,
      accessor: (li) => li.metadata?.actualStartDate || null,
      Header: 'Actual Start Date',
      Cell: formatUTCToDefaultTimezone,
      width: resizingData.getData(190, TraffickingTableName.lineItems, 'ActualStartDate'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.ScheduleEnd,
      accessor: (li) => li.endDate,
      Header: 'End Date',
      Cell: formatStartEndDateString,
      width: resizingData.getData(135, TraffickingTableName.lineItems, 'ScheduleEnd'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.DisplayPublisherTarget,
      accessor: 'displayPublisherTarget',
      Header: 'Publisher',
      Cell: getPublisherTargetingList,
      width: resizingData.getData(230, TraffickingTableName.lineItems, 'DisplayPublisherTarget'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.ScheduleTimezoneOffset,
      accessor: (li) => (li.schedule?.timezone ? getTimezoneWithGMT(li.schedule?.timezone) : ''),
      Header: 'Time Zone',
      width: resizingData.getData(230, TraffickingTableName.lineItems, 'ScheduleTimezoneOffset'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.GeneralPacingStatus,
      accessor: (li) => li.pacingMetrics?.generalPacingStatus || null,
      Header: 'General Pacing Status',
      Cell: PacingStatusCell,
      width: resizingData.getData(160, TraffickingTableName.lineItems, 'GeneralPacingStatus'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.Goal,
      accessor: (li) => (li.goal !== null ? li.goal.toLocaleString() : ''),
      Header: 'Completion Goal',
      width: resizingData.getData(180, TraffickingTableName.lineItems, 'Goal'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.MetricsCompletions,
      accessor: (li) => getMetricsRelatedColumnAccessor(li.metrics?.completions),
      Header: 'Completions',
      width: resizingData.getData(170, TraffickingTableName.lineItems, 'MetricsCompletions'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.MetricsPercentPacing,
      accessor: (li) => getMetricsPercentRelatedColumnAccessor(li.metrics?.percentPacing),
      Header: '% Pacing',
      Cell: numberToPercentage,
      width: resizingData.getData(130, TraffickingTableName.lineItems, 'MetricsPercentPacing'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.MetricsPercentExpected,
      accessor: (li) => getMetricsPercentRelatedColumnAccessor(li.metrics?.percentExpected),
      Header: '% Expected',
      Cell: numberToPercentage,
      width: resizingData.getData(150, TraffickingTableName.lineItems, 'MetricsPercentExpected'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.MetricsPercentCompletions,
      accessor: (li) => getMetricsPercentRelatedColumnAccessor(li.metrics?.percentCompletions),
      Header: '% Delivered',
      Cell: numberToPercentage,
      width: resizingData.getData(145, TraffickingTableName.lineItems, 'MetricsPercentCompletions'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.PacingType,
      accessor: (li) => ({ pacingType: li.pacingType, tierName: li.tier.name.key }),
      Header: 'Pacing Type',
      Cell: PacingTypeCell,
      isEditable: true,
      width: resizingData.getData(180, TraffickingTableName.lineItems, 'PacingType'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.Tier,
      accessor: (li) => li.tier,
      Header: 'Tier Name',
      Cell: TierNameCell,
      isEditable: true,
      width: resizingData.getData(230, TraffickingTableName.lineItems, 'Tier'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.YodaThreshold,
      accessor: (li) => getLineItemThreshold(li?.pacingMetrics?.yodaThreshold ?? null),
      Header: 'Yoda Threshold',
      width: resizingData.getData(230, TraffickingTableName.lineItems, 'YodaThreshold'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.YodaPacingStatus,
      accessor: (li) => li?.pacingMetrics?.yodaPacingStatus,
      Header: 'Yoda Pacing Status',
      Cell: YodaPacingStatusCell,
      width: resizingData.getData(230, TraffickingTableName.lineItems, 'YodaPacingStatus'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.PriorityName,
      accessor: (li) => li.priority,
      Header: 'Priority Name',
      Cell: PriorityNameCell,
      isEditable: true,
      width: resizingData.getData(230, TraffickingTableName.lineItems, 'PriorityName'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.PriorityValue,
      accessor: (li) => li.priorityValue,
      Header: 'Priority Value',
      width: resizingData.getData(150, TraffickingTableName.lineItems, 'PriorityValue'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.AdProduct,
      accessor: (li) => li.adProduct.displayName,
      Header: 'Ad Product',
      width: resizingData.getData(250, TraffickingTableName.lineItems, 'AdProduct'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.FrequencyCapList,
      accessor: (li) => li.frequencyCapList,
      isEditable: true,
      Header: 'Frequency Cap',
      Cell: LineItemFrequencyCapCell,
      width: resizingData.getData(135, TraffickingTableName.lineItems, 'frequencyCapList'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.DeliveryBufferPercent,
      accessor: (li) => li.deliveryBufferPercent,
      isEditable: true,
      Header: 'Buffer',
      Cell: (cell: Cell<LineItemModel>) => (
        <PercentCell
          value={cell.value}
          id={getIdFromTableCell(cell)}
          name="deliveryBufferPercent"
          lowerLimit={-99}
          upperLimit={800}
          showEmptyCell={cell.row.original.pacingType === pacingTypes.SOV}
        />
      ),
      width: resizingData.getData(125, TraffickingTableName.lineItems, 'DeliveryBufferPercent'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.FrontLoadPercent,
      accessor: (li) => li.frontLoadPercent,
      isEditable: true,
      Header: 'Frontload',
      Cell: (cell: Cell<LineItemModel>) => (
        <PercentCell
          value={cell.value}
          id={getIdFromTableCell(cell)}
          name="frontLoadPercent"
          lowerLimit={0}
          upperLimit={400}
          showEmptyCell={
            cell.row.original.pacingType === pacingTypes.SOV || cell.row.original.pacingType === pacingTypes.AFAP
          }
        />
      ),
      width: resizingData.getData(150, TraffickingTableName.lineItems, 'FrontLoadPercent'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.DeliveryForensics,
      Header: 'DF',
      Cell: DeliveryForensicsCell,
      isEditable: true,
      width: resizingData.getData(60, TraffickingTableName.lineItems, 'deliveryForensics'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      className: 'df-cell',
    },
    {
      id: LineItemColumn.AccessorId,
      accessor: 'id',
      Header: 'Line Item ID',
      width: resizingData.getData(330, TraffickingTableName.lineItems, 'accessorId'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.MetricsRemainingExpectedCompletionsAcc,
      accessor: (li) => getMetricsRelatedColumnAccessor(li.metrics?.remainingExpectedCompletionsAcc),
      Header: 'Completions Remaining',
      width: resizingData.getData(230, TraffickingTableName.lineItems, 'MetricsRemainingExpectedCompletionsAcc'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.MetricsCompletionsAtRisk,
      accessor: (li) => getMetricsRelatedColumnAccessor(li?.metrics?.completionsAtRisk),
      Header: 'Completions at Risk',
      width: resizingData.getData(200, TraffickingTableName.lineItems, 'MetricsCompletionsAtRisk'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.Revenue,
      accessor: 'revenue',
      Header: 'Revenue',
      Cell: numberToDollarAmount,
      width: resizingData.getData(125, TraffickingTableName.lineItems, 'Revenue'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      className: 'price-cell',
    },
    {
      id: LineItemColumn.MetricsRevenueAtRisk,
      accessor: (li) => li.metrics?.revenueAtRisk || null,
      Header: 'Revenue at Risk',
      Cell: numberToDollarAmount,
      width: resizingData.getData(190, TraffickingTableName.lineItems, 'MetricsRevenueAtRisk'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      className: 'price-cell',
    },
    {
      id: LineItemColumn.BillableThirdParty,
      accessor: (li) => li.billableThirdParty?.displayName,
      Header: 'Billable Third Party',
      width: resizingData.getData(225, TraffickingTableName.lineItems, 'BillableThirdParty'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.UnitCost,
      accessor: ({ unitCostDto }) => unitCostDto?.micros || '',
      Header: 'CPM',
      Cell: microCurrencyToDollarAmount,
      width: resizingData.getData(100, TraffickingTableName.lineItems, 'UnitCost'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      className: 'price-cell',
    },
    {
      id: LineItemColumn.MetricsUnderPacingRevenue,
      accessor: (li) => li.metrics?.underPacingRevenue || null,
      Header: 'Under-pacing Revenue',
      Cell: numberToDollarAmount,
      width: resizingData.getData(220, TraffickingTableName.lineItems, 'MetricsUnderPacingRevenue'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
      className: 'price-cell',
    },
    {
      id: LineItemColumn.LineOmsLinkId,
      accessor: (li) => li.lineOmsLink?.id,
      Header: 'Line OMS ID',
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'LineOmsLinkId'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.CreatedAt,
      accessor: 'createdAt',
      Header: 'Created Date',
      Cell: stringToDateTimeString,
      width: resizingData.getData(190, TraffickingTableName.lineItems, 'CreatedAt'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.UpdatedAt,
      accessor: 'updatedAt',
      Header: 'Updated Date',
      Cell: stringToDateTimeString,
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'UpdatedAt'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.CreativeMaxSeconds,
      accessor: 'creativeMaxSeconds',
      Header: 'Max Duration',
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'CreativeMaxSeconds'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.CampaignName,
      accessor: (li) => li?.campaign?.name,
      Header: 'Campaign Name',
      width: resizingData.getData(525, TraffickingTableName.lineItems, 'CampaignName'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.DeliveryGoal,
      accessor: (li) => li.deliveryGoal?.toLocaleString(),
      Header: 'Delivery Goal',
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'DeliveryGoal'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.IsCoppaOrCaru,
      accessor: 'isCoppaOrCaru',
      Header: 'Needs Fort D Review',
      ...commonBooleanCellConfig('IsCoppaOrCaru'),
    },
    {
      id: LineItemColumn.IsAddedValue,
      accessor: 'isAddedValue',
      Header: 'Is Added Value?',
      ...commonBooleanCellConfig('IsAddedValue'),
    },
    {
      id: LineItemColumn.IsMakegood,
      accessor: 'isMakegood',
      Header: 'Is Makegood?',
      ...commonBooleanCellConfig('IsMakegood'),
    },
    {
      id: LineItemColumn.IsFiller,
      accessor: 'isFiller',
      Header: 'Filler Ad',
      isColumnHiddenByDefault: true,
      ...commonBooleanCellConfig('isFiller'),
    },
    {
      id: LineItemColumn.DeliverableLineItemBuyerName,
      accessor: (li) => li.orderLineItemDto?.buyerName,
      Header: 'Buyer Name',
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'DeliverableLineItemBuyerName'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.DeliverableLineItemBuyerId,
      accessor: (li) => li.orderLineItemDto?.buyerId,
      Header: 'Buyer Seat ID',
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'DeliverableLineItemBuyerId'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.MagniteDealDspName,
      accessor: (li) => li.magniteDealDto?.dspName || '',
      Header: 'DSP Name',
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'MagniteDealDspName'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.MagniteDealDealName,
      accessor: (li) => li.magniteDealDto?.dealName,
      Header: 'Deal ID',
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'DealId'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.CampaignTraffickerEmail,
      accessor: ({ campaign }) => getAccountRelatedColumnAccessor(campaign?.traffickerEmail),
      Header: 'Account Manager',
      width: resizingData.getData(200, TraffickingTableName.lineItems, 'CampaignTraffickerEmail'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.CampaignOrderSalespersonEmail,
      accessor: ({ campaign }) => getAccountRelatedColumnAccessor(campaign?.order?.salespersonEmail),
      Header: 'Account Executive',
      width: resizingData.getData(200, TraffickingTableName.lineItems, 'CampaignOrderSalespersonEmail'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
    {
      id: LineItemColumn.Currency,
      accessor: ({ unitCostDto }) => unitCostDto?.currency || '',
      Header: 'Currency Type',
      width: resizingData.getData(200, TraffickingTableName.lineItems, 'Currency'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    },
  ];

  if (envConfig.REACT_APP_FEATURE_ENABLE_CREATE_SEQUENCE === 'true') {
    columns.push({
      id: LineItemColumn.Sequence,
      accessor: (li) => boolToYes({ value: !!li.lineItemSequenceId, isNoShown: true }),
      Header: 'Sequence',
      width: resizingData.getData(175, TraffickingTableName.lineItems, 'Sequence'),
      minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    });
  }

  return columns;
};

export default getLineItemsColumns;
