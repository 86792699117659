import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import type { GetSavedAdsQuery, GetSavedAdsQueryVariables, LineItemV5 } from '../../../apis/graphql';
import { getDataFromNodes } from '../../../apis/graphql';
import { adsPageAdNodeToModel } from '../../../common/AdForm/hooks/modelConverters';
import type { AdsPageAd } from '../../../common/AdForm/hooks/types';
import type { AdsPageLineItem } from '../../../common/AdForm/types';
import { filterTermValues } from '../../../common/utils';
import type { TargetingTermValue } from '../../../models';
import { GET_SAVED_ADS } from './queries';

interface FetchAdsResponse {
  ads: AdsPageAd[];
  lineItems: AdsPageLineItem[];
  lineItemTargetingTermValues: TargetingTermValue[];
  error?: ApolloError;
  loading: boolean;
}

function nodeToModelLineItem(node: AdsPageLineItem): AdsPageLineItem {
  const { schedule } = node;
  return {
    ...node,
    schedule: schedule,
  };
}

export default function useFetchSavedAds(lineItemId: LineItemV5['id'], skip: boolean = false): FetchAdsResponse {
  const { loading, error, data } = useQuery<GetSavedAdsQuery, GetSavedAdsQueryVariables>(GET_SAVED_ADS, {
    variables: { lineItemIds: [lineItemId], lineItemId: lineItemId },
    errorPolicy: 'all',
    skip,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ads = useMemo<AdsPageAd[]>(getDataFromNodes(data, 'ads', adsPageAdNodeToModel), [data]);

  const lineItemTargetingTermValues = useMemo<TargetingTermValue[]>(
    () => filterTermValues(data?.getTargetingTermValuesByLineItemIDV5 as TargetingTermValue[]),
    [data]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lineItems = useMemo<AdsPageLineItem[]>(getDataFromNodes(data, 'lineItems', nodeToModelLineItem), [data]);

  return { ads, lineItems, lineItemTargetingTermValues, loading, error };
}
