import './Daypart.scss';

import React, { useEffect, useState } from 'react';

import type { ActiveDayTypeV5, DayPartV5 } from '../../../../apis/graphql';
import Button from '../../../../common/Button';
import bem from '../../../../utils/bem';
import { orderedDaysOfWeek } from '../../../../utils/formatting';
import type { ListHelpers } from '../../../../utils/listHelpers';
import { validateDayParts } from '../../../../utils/validation';
import { compareDateTimeValues } from '../../../DateInput/utils';
import { DEFAULT_DAY_PART } from '../DaypartTabs/DaypartTabs';
import DeleteDaypartCell from './DeleteDaypartCell';

const [block, element] = bem('daypart');

// TODO: AX-2033: verify these types are in agreement with types generated from gql
export type DayPartProps = {
  dayPartStashList: DayPartV5[]; // display of daypart uses this.
  dayPartStateList: DayPartV5[]; // push removeAt, replaceAt modify state of this.
  listHelpers: Pick<ListHelpers<DayPartV5>, 'removeAt' | 'replaceAt' | 'push'>;
  readonly?: boolean;
};

const daysOfWeek: Array<ActiveDayTypeV5> = orderedDaysOfWeek as ActiveDayTypeV5[];

type FormattedDaypart = DayPartV5 & {
  firstActiveHourTime: string;
  lastActiveHourTime: string;
};

const getFormattedTime = (hour: number, predefinedMinutes: number): string => {
  return `${compareDateTimeValues(hour, 23)}:${compareDateTimeValues(predefinedMinutes, 59)}`;
};

function Daypart({
  dayPartStashList,
  dayPartStateList,
  listHelpers: { push, removeAt, replaceAt },
  readonly,
}: DayPartProps): React.JSX.Element {
  // we need to work with formatted list to fix time inputs bugs due to re-rendering
  const [formattedDayPartStashedList, setFormattedDayPartStashedList] = useState<FormattedDaypart[]>([]);

  useEffect(() => {
    setFormattedDayPartStashedList(() =>
      dayPartStashList.map((dayPart) => ({
        ...dayPart,
        firstActiveHourTime: getFormattedTime(dayPart.firstActiveHour || 0, 0),
        lastActiveHourTime: getFormattedTime(dayPart.lastActiveHour || 0, 59),
      }))
    );
  }, [dayPartStashList]);

  const [validationError, setValidationError] = useState<string | null>(null);
  const handleTimeChange = (type: string, value: string, index: number, predefinedMinutes: number): void => {
    if (value === '') return;
    const newTimeValue = Number(value.split(':')[0]);
    replaceAt(index, { ...dayPartStashList[index], [type]: newTimeValue });
    setFormattedDayPartStashedList((formattedList) =>
      formattedList.map((dayPart, id) =>
        id === index
          ? {
              ...dayPart,
              [type]: newTimeValue,
              [`${type}Time`]: getFormattedTime(newTimeValue, predefinedMinutes),
            }
          : dayPart
      )
    );
  };

  const handleActiveDayChange = (value: ActiveDayTypeV5, index: number): void => {
    const activeDayList: Array<ActiveDayTypeV5> = dayPartStashList[index].activeDayList.map((el) => el);

    if (activeDayList.includes(value)) {
      activeDayList.splice(activeDayList.indexOf(value), 1);
    } else {
      activeDayList.push(value);
    }

    replaceAt(index, { ...dayPartStashList[index], activeDayList });
  };

  /**
   * Since other behavior is dependent on the split state of this component
   * displayed row is a stashedDayPart and actual dayPartList = []
   * add 2 rows initally to resync state and keep proper formik dirty behavior
   * this set of components should be refactored.
   */
  const handleAddRow = (): void => {
    if (dayPartStashList.length === 1 && dayPartStateList.length === 0) {
      // a fake row is present to the user push twice.
      push(DEFAULT_DAY_PART);
      push(DEFAULT_DAY_PART);
    } else {
      push(DEFAULT_DAY_PART);
    }
  };

  useEffect(() => {
    setValidationError(validateDayParts(dayPartStashList));
  }, [dayPartStashList]);

  return (
    <div className={block()} data-testid="dayparts-container">
      <div className={element('daypart-headers')}>
        <span className={element('daypart-headers--time-header')}>Start Time</span>
        <span className={element('daypart-headers--time-header')}>End Time</span>
        <span>M</span>
        <span>Tu</span>
        <span>W</span>
        <span>Th</span>
        <span>F</span>
        <span>Sa</span>
        <span>Su</span>
        <span />
      </div>
      {formattedDayPartStashedList.map(
        (dayPart: FormattedDaypart, index: number): JSX.Element => {
          return (
            <div key={index} className={element('daypart-row')}>
              <input
                aria-label="time picker"
                className={element('time-input')}
                type="time"
                value={dayPart.firstActiveHourTime}
                onChange={(e): void => handleTimeChange('firstActiveHour', e.target.value, index, 0)}
                step={3600000}
                disabled={readonly}
                required
              />
              <input
                aria-label="time picker"
                className={element('time-input')}
                type="time"
                value={dayPart.lastActiveHourTime}
                onChange={(e): void => handleTimeChange('lastActiveHour', e.target.value, index, 59)}
                step={3600000}
                disabled={readonly}
                required
              />
              <fieldset>
                {daysOfWeek.map(
                  (day: ActiveDayTypeV5): JSX.Element => {
                    const activeDays = dayPart.activeDayList.map((day: ActiveDayTypeV5) => {
                      return day;
                    });
                    return (
                      <input
                        type="checkbox"
                        aria-label={`${day}-checkbox`}
                        key={day}
                        name={day}
                        checked={activeDays.includes(day)}
                        onChange={(e): void => handleActiveDayChange(e.target.name as ActiveDayTypeV5, index)}
                        disabled={readonly}
                      />
                    );
                  }
                )}
              </fieldset>
              <DeleteDaypartCell listHelpers={{ removeAt }} rowIndex={index} readonly={readonly} />
            </div>
          );
        }
      )}
      {validationError && <div className={element('daypart-validation')}>{validationError}</div>}
      <Button
        icon="IconCirclePlus"
        variant="outlined"
        aria-label="add daypart"
        className={element('add-btn')}
        onClick={handleAddRow}
        disabled={readonly}
      >
        New Row
      </Button>
    </div>
  );
}

export default Daypart;
