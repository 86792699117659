export enum TermStatus {
  ADDED,
  REMOVED,
  PRESENT,
}

export type RawTerm = {
  dimension: string;
  operation: TermStatus;
  valueSet: string[];
  not: boolean;
};

export type Term = {
  dimension: string;
  valueSet: {
    value: string;
    status: TermStatus;
    include: boolean;
  }[];
};
