//----- Context -----//

import type { Dispatch } from 'react';
import { createContext, useContext, useMemo, useReducer } from 'react';

import type { SequenceActions } from './actions';
import { sequenceReducer } from './reducer';
import type { SequenceState } from './state';
import { SEQUENCE_INITIAL_STATE } from './state';

type SequenceContextState = {
  sequenceState: SequenceState;
  sequenceDispatch: Dispatch<SequenceActions>;
};

const SequenceContext = createContext<SequenceContextState | null>(null);

//----- Consumer -----//

export const useSequenceContext = (): SequenceContextState => useContext(SequenceContext) as SequenceContextState;

//----- Provider -----//

export const SequenceProvider: React.FC<{ initialState?: SequenceState }> = (props) => {
  const [sequenceState, sequenceDispatch] = useReducer(sequenceReducer, props.initialState || SEQUENCE_INITIAL_STATE);

  const contextValues = useMemo(
    () => ({
      sequenceState,
      sequenceDispatch,
    }),
    [sequenceState]
  );

  return <SequenceContext.Provider value={contextValues}>{props.children}</SequenceContext.Provider>;
};
