import './SharedMultipleEditLoader.scss';

import React from 'react';

import Drawer from '../../../../../../common/Drawer';
import Loader from '../../../../../../common/Loader';
import bem from '../../../../../../utils/bem';
import type { MultipleEditDrawerData } from '../../../../TraffickingPage/hooks/makePageDrawerPlugin';
import { Steps } from '../../constants';
import type { MultipleEditDrawerProps } from '../../MultipleEditDrawer';
import MultipleEditDrawerTitle from '../../MultipleEditDrawerTitle';

const [block] = bem('multiple-edit-loader-wrapper');
const SharedMultipleEditLoader = ({
  data,
  isExpanded,
  isOpen,
  className,
  onClose,
  onToggleExpansion,
}: MultipleEditDrawerProps): JSX.Element => {
  return (
    <Drawer<MultipleEditDrawerData>
      size="large"
      data={undefined}
      isOpen={isOpen}
      onClose={(): void => {
        onClose();
      }}
      className={className}
      isExpanded={isExpanded}
      onToggleExpansion={onToggleExpansion}
      title={(): JSX.Element => (
        <MultipleEditDrawerTitle onPrevStep={(): void => {}} activeStep={Steps.SELECT} entityType={data.dataType} />
      )}
      content={(): JSX.Element => (
        <div className={block()}>
          <Loader text="Loading..." />{' '}
        </div>
      )}
    />
  );
};

export default SharedMultipleEditLoader;
