import React from 'react';

const DefaultSequenceTableHeader = (): React.JSX.Element => {
  return (
    <thead>
      <tr>
        <th>{/* Empty Header for Drag Handles */}</th>
        <th>{/* Empty Header for Order */}</th>
        <th>Ad Name</th>
        <th>Ad Type</th>
        <th>Ad Start</th>
        <th>Ad End</th>
        <th>Ad Tracking ID</th>
        <th>Line Item Name</th>
      </tr>
    </thead>
  );
};

export default DefaultSequenceTableHeader;
