import './ForbiddenPage.scss';

import React from 'react';

const ForbiddenPage = (): JSX.Element => (
  <div className="forbidden-page">
    <h1>You do not have access to view this page.</h1>
  </div>
);

export default ForbiddenPage;
