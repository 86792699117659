import { isAfter, isBefore } from 'date-fns';

import type { Maybe, ScheduleV5 } from '../../apis/graphql';
import type { DateRange, LineItem, Nullable } from '../../models';
import { formatDateOrCreateIfNotValid, isValidDate, NEXT_MONTH, TODAY } from '../formatting';

type DateRangeWithTime = {
  start: string;
  end: Nullable<string>;
  startTime: number;
  endTime: number;
  pacingShare: Nullable<number>;
};

const mergeTwoRanges = (dateA: DateRangeWithTime, dateB: DateRangeWithTime): DateRangeWithTime => {
  const endDate = dateB.endTime > dateA.endTime ? dateB : dateA;

  // Ad cannot be scheduled within a Line Item schedule with 0% pacing, but can be scheduled if one of the merging date has pacing more than 0%
  // We pass 0 if both date ranges have 0% pacing, otherwise null
  return {
    pacingShare: dateA.pacingShare === 0 && dateB.pacingShare === 0 ? 0 : null,
    start: dateA.start,
    end: endDate.end,
    startTime: dateA.startTime,
    endTime: endDate.endTime,
  };
};

const mergeCrossedDates = (sortedDates: DateRangeWithTime[]): DateRangeWithTime[] => {
  if (sortedDates.length <= 1) return sortedDates;

  // 24 hours was added to include cases when next range starts next day
  const additionalHours = 24 * 60 * 60 * 1000;
  const mergedDates: DateRangeWithTime[] = [];
  let prev: DateRangeWithTime = { ...sortedDates[0] };

  for (let i = 1; i < sortedDates.length; i++) {
    const date: DateRangeWithTime = sortedDates[i];

    if (prev.endTime + additionalHours >= date.startTime) {
      prev = mergeTwoRanges(prev, date);
    } else {
      mergedDates.push(prev);
      prev = date;
    }
  }
  mergedDates.push(prev);

  return mergedDates;
};

export const mergeCrossedInDateRange = (dateRange: DateRange[]): DateRange[] => {
  const dateRangeWithTime = dateRange.map(({ startDate, endDate, pacingShare }) => {
    return {
      start: startDate,
      end: endDate,
      startTime: new Date(startDate).getTime(),
      endTime: endDate ? new Date(endDate).getTime() : Infinity,
      pacingShare,
    };
  });

  const sortedDateRangeWithTime = [...dateRangeWithTime].sort((d1, d2) => d1.startTime - d2.startTime);
  const mergedDateRanges = mergeCrossedDates(sortedDateRangeWithTime);

  // Drop startTime and endTime for return
  return mergedDateRanges.map(({ start, end, pacingShare }) => {
    return {
      startDate: start,
      endDate: end,
      pacingShare,
    };
  });
};

export const defaultDateRange: DateRange = {
  startDate: formatDateOrCreateIfNotValid(TODAY, true),
  endDate: formatDateOrCreateIfNotValid(NEXT_MONTH, true),
  pacingShare: 0,
};

export const VALIDATION_ERROR = {
  noInvalidStartDate: 'Ad start date must be a valid date',
  noInvalidEndDate: 'Ad end date must be a valid date',
  noStartAfterEnd: 'Ad start date must be before its end date',
  noOutsideSchedule: "Ad must be scheduled within a Line Item's schedule",
  noZeroPacingShare: 'Ad cannot be scheduled within a Line Item schedule with 0% pacing',
  conflictDateRanges: 'Invalid date range in Scheduling section',
};

const endDateBoundary = new Date('2112-01-01');

const parseDateRange = ({ startDate, endDate }: DateRange): { start: Date; end: Date } => {
  return {
    start: new Date(startDate),
    end: endDate !== null ? new Date(endDate) : endDateBoundary,
  };
};

/**
 * Tests the given DateRange values to ensure they meet the validation
 * criteria for LineItem availability
 */
export function validateDateRangeWithLineItem(
  lineItemSchedule: Nullable<ScheduleV5>,
  dateRange: DateRange
): string | null {
  const date = parseDateRange(dateRange);

  const defaultValidationResult = validateDateRangeByDefault(date.start, date.end);

  if (defaultValidationResult) {
    return defaultValidationResult;
  }

  const lineItemDateRange = mergeCrossedInDateRange(lineItemSchedule?.dateRangeList || []);

  // Find a schedule where DateRange start & end are within LineItem schedule start & end
  const matchingSchedule = lineItemDateRange.find((r) => {
    const range = parseDateRange(r);

    return !isBefore(date.start, range.start) && !isAfter(date.end, range.end);
  });

  // DateRange does not fall within any LineItem schedules
  if (!matchingSchedule) return VALIDATION_ERROR.noOutsideSchedule;

  // Matching LineItem schedule has its pacing share set to 0
  if (matchingSchedule.pacingShare !== null && matchingSchedule.pacingShare === 0)
    return VALIDATION_ERROR.noZeroPacingShare;

  // No validation errors found
  return null;
}

/**
 *  Used to cover all default validations for any date range
 */
export const validateDateRangeByDefault = (start: Date, end: Date, nullableEnd?: boolean): string | null => {
  // Start or End date is not a valid date
  if (!isValidDate(start)) return VALIDATION_ERROR.noInvalidStartDate;
  if (!isValidDate(end)) return VALIDATION_ERROR.noInvalidEndDate;

  // DateRange start is after DateRange end
  if (isAfter(start, end) && !nullableEnd) return VALIDATION_ERROR.noStartAfterEnd;

  return null;
};

export const validateDateRangesByDefault = (dateRanges: DateRange[]): string | null => {
  for (let index = 0; index < dateRanges.length; index++) {
    const dateRange = dateRanges[index];
    const date = parseDateRange(dateRange);
    const nextDateRange = dateRanges[index + 1];

    const error = validateDateRangeByDefault(date.start, date.end);

    if (error) return error;

    if (nextDateRange) {
      if (isBefore(parseDateRange(nextDateRange).start, date.end)) return VALIDATION_ERROR.conflictDateRanges;
    }
  }

  return null;
};

export const validateDateRanges = (lineItemSchedule: Nullable<ScheduleV5>, dateRanges: DateRange[]): string | null => {
  for (let index = 0; index < dateRanges.length; index++) {
    const dateRange = dateRanges[index];
    const date = parseDateRange(dateRange);
    const nextDateRange = dateRanges[index + 1];

    const error = validateDateRangeWithLineItem(lineItemSchedule, dateRange);

    if (error) return error;

    if (nextDateRange) {
      if (isBefore(parseDateRange(nextDateRange).start, date.end)) return VALIDATION_ERROR.conflictDateRanges;
    }
  }

  return null;
};

type ConflictLineItem = Pick<LineItem, 'id' | 'startDate' | 'endDate'> & {
  schedule: Maybe<ScheduleV5>;
};

export const getConflictLineItemsIdsWithAdDateRangeList = (
  lineItems: ConflictLineItem[],
  dateRanges: DateRange[]
): string[] => {
  return lineItems.reduce<string[]>((ids, lineItem) => {
    if (validateDateRanges(lineItem.schedule as ScheduleV5, dateRanges)) return [...ids, lineItem.id];

    return ids;
  }, []);
};
