import { campaignClient } from '../../../../../apis/graphql';
import type { BaseCreativeV5 } from '../../../../../apis/graphql/types';
import { getErrorMessageFromGraphQlErrors } from '../../../../../apis/graphql/utils';
import type { AdFormValues } from '../../../../../common/AdForm/adFormik/types';
import { getCreativeTypeFromForm } from '../../../../../common/AdForm/adFormik/utils';
import { getCreativeMutation } from '../../../../../common/AdForm/hooks/utils';
import type { CreativeInput } from '../../types';

export async function createOrUpdateCreative(
  values: AdFormValues,
  mutationType: 'create' | 'update'
): Promise<BaseCreativeV5['id']> {
  const { creative } = values;

  // Fall back to the Ad's default creative type if no creative type is defined
  const creativeType = creative?.type ?? getCreativeTypeFromForm(values);

  const creativeMutation = getCreativeMutation(creativeType);
  const mappedMutation = creativeMutation[mutationType];

  if (!mappedMutation) throw Error(`${[mutationType]} does not exist for creative type ${creativeType}`);

  const creativeInput: CreativeInput = creativeMutation.inputGenerator(creative);

  const mutationVariables: { creativeInput: CreativeInput; id?: string | null } = { creativeInput };

  if (mutationType === 'update') {
    mutationVariables.id = values.creative.id;
  }

  // We would prefer to use useMutation, but saveCreative is only invoked during onSubmit, which is not a React Hook
  // You cannot call use a hook outside of a component (or another hook), so we call mutate directly on our GQL Client instead
  const { data, errors } = await campaignClient.mutate({
    mutation: mappedMutation.mutation,
    variables: mutationVariables,
  });

  if (data && data[mappedMutation.idAccessKey]) {
    return data[mappedMutation.idAccessKey].id;
  }

  throw Error(errors ? getErrorMessageFromGraphQlErrors(errors) : '');
}
