import { bem } from 'mission-control-common-components';
import React from 'react';

import type { AssetExtended } from '../../../../../common/_ForCommonLibrary/types/extended';
import type { CreativeReviewStatus } from '../../../../../configs';
import Tooltip from '../../../../Tooltip';
import CreativeReviewBadge from '../../../CreativeReviewBadge';
import { ingestButton } from '../../configs';
import { reviewAlertPropsMap } from '../../utils';
import { CreativeFooterStyling } from '../CreativeFooterStyling';
import { SetAssetErrorButton } from '../SetAssetErrorButton';

const element = bem('creative-footer')[1];

export const CreativeFooter: React.FC<{
  assets?: AssetExtended[] | null;
  hasTranscodeAbort?: boolean;
  isLoading?: boolean;
  readonly?: boolean;
}> = ({ assets, hasTranscodeAbort, isLoading, readonly }) => {
  const review = reviewAlertPropsMap?.[assets?.[0]?.review as CreativeReviewStatus];
  const spReviewLocked = assets?.length && assets?.[0].reviewTask?.spReview === 'LOCKED';

  if (!review || spReviewLocked) return null;
  const { tooltipConfig, tooltipText } = ingestButton;

  return (
    <CreativeFooterStyling>
      <hr />
      {hasTranscodeAbort ? (
        <div className={element('content', 'interaction')}>
          <Tooltip id="vast-footer-tooltip" className={element('tooltip')} message={tooltipText} {...tooltipConfig}>
            <SetAssetErrorButton readonly={readonly} assets={assets} />
          </Tooltip>
        </div>
      ) : (
        <div className={element('content', 'information')}>
          <CreativeReviewBadge loading={isLoading} asset={assets?.[0]} alertProps={review} />
        </div>
      )}
    </CreativeFooterStyling>
  );
};
