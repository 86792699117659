import './SequenceTable.scss';

import { bem } from 'mission-control-common-components';
import React, { useMemo } from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { NEW_SEQUENCE_LABEL } from '../constants';
import { useSequenceContext } from '../context';
import DefaultSequenceTableHeader from '../DefaultSequenceTableHeader';
import { useSequenceForm } from '../hooks';
import SequencedHeader from '../SequencedHeader';
import SequenceTableRow from '../SequenceTableRow';
import type { SequenceAdV5, TransformedSequence } from '../types';
import UnsequencedHeader from '../UnsequencedHeader';

const [block, element] = bem('sequence-table');

type SequenceTableProps = {
  tableIndex: number;
  sequence?: TransformedSequence;
  rows: SequenceAdV5[];
};

const SequenceTable = ({ tableIndex, sequence, rows }: SequenceTableProps): React.JSX.Element => {
  const { sequenceState } = useSequenceContext();
  const { searchUnsequencedAdsTerm } = sequenceState;

  const { status } = useSequenceForm();

  const sequenceName = sequence?.name || `${NEW_SEQUENCE_LABEL}${tableIndex + 1}`;

  const tableBodyModifiers = useMemo(() => {
    if (!sequence && searchUnsequencedAdsTerm) {
      return 'searched-rows';
    }

    return '';
  }, [searchUnsequencedAdsTerm, sequence]);

  const errorsComponents = useMemo(() => {
    const components: React.JSX.Element[] = [];

    if (status) {
      Object.entries(status).forEach((entries) => {
        const [key, value] = entries;

        if (sequence && value.has(sequence.id)) {
          components.push(
            <div date-testid="table-error" className={element('error')} key={sequence.id + key}>
              {key}
            </div>
          );
        }
      });
    }

    return components;
  }, [sequence, status]);

  return (
    <div className={block()}>
      <div className={element('sequence-table-bar', errorsComponents.length ? 'error' : '')}>
        {sequence ? <SequencedHeader sequenceIndex={tableIndex} name={sequenceName} /> : <UnsequencedHeader />}
      </div>
      <Droppable droppableId={`${tableIndex}`}>
        {(provided): React.ReactElement => (
          <div className={element('table-wrapper')}>
            <table ref={provided?.innerRef} {...provided?.droppableProps}>
              <DefaultSequenceTableHeader />
              <tbody className={element('table-body', tableBodyModifiers)}>
                {rows?.map((row, rowIndex) => (
                  <SequenceTableRow key={rowIndex} {...{ row: row, tableIndex, rowIndex }} />
                ))}
                {provided.placeholder}
              </tbody>
            </table>
          </div>
        )}
      </Droppable>
      {errorsComponents}
    </div>
  );
};

export default SequenceTable;
