import './MarqueePosterCreativeBuilder.scss';

import { useFormikContext } from 'formik';

import type { Scalars } from '../../../../apis/graphql';
import { AssetImporterField, ColorPicker, TextArea, TextField } from '../../../../common/Form';
import TaskTabNavigation from '../../../../common/TaskTabNavigation';
import bem from '../../../../utils/bem';
import type { AdFormValues } from '../../adFormik';
import type { CreativeBuilderProps } from '../CreativeBuilder/CreativeBuilder';
import CreativeBuilder from '../CreativeBuilder/CreativeBuilder';
import type { CreativesBuilderValues, DaapiEntity } from '../types';
import { CreativeEditMode } from '../types';
import { isTabComplete } from '../utils';

const { TaskTab } = TaskTabNavigation;
const IMAGE_ASPECT_RATIO = 16 / 9;

const [, element] = bem('marquee');

// TODO: Both these types should come from graphql/types when created, but here for constructor return type
export type MarqueeTypePropertiesInput = {
  title: Scalars['String'];
  description: Scalars['String'];
  buttonText: Scalars['String'];
  contentAssetId: Scalars['String'];
  accent: Scalars['String'];
  callToAction: Scalars['String'];
  logo: DaapiEntity;
  poster: DaapiEntity;
};

export type MarqueePosterCreativeInput = {
  name: Scalars['String'];
  title: string;
  description: string;
  buttonText: string;
  contentAssetId: string;
  accent: string;
  callToAction: string;
  logo: { id: string };
  poster: { id: string };
};

export const constructMarqueePosterCreativeInput = (creative: CreativesBuilderValues): MarqueePosterCreativeInput => {
  const { name, logo, poster } = creative;

  // TODO: Marquee is currently incomplete; these values are not available at this time
  const marqueeDefault = {
    title: '',
    description: '',
    buttonText: '',
    contentAssetId: '',
    accent: '',
    callToAction: '',
    logo: { id: logo?.id || '' },
    poster: { id: poster?.id || '' },
  };
  return {
    name,
    ...marqueeDefault,
  };
};

function MarqueePosterCreativeBuilder({
  isPreview,
  adTypeDimensions,
  editMode,
}: CreativeBuilderProps): React.JSX.Element {
  const {
    values: { creative },
  } = useFormikContext<AdFormValues>();

  return (
    <CreativeBuilder classNameModifier="marquee" isPreview={isPreview} editMode={editMode}>
      <div className={element('marquee-first-row')}>
        <TextField
          id="callToAction"
          formFieldName="creative.marquee.callToAction"
          className={element('marquee-name-input')}
          label="Call To Action"
          isPreview={isPreview}
        />
        <TextField
          id="title"
          formFieldName="creative.marquee.title"
          className={element('marquee-title-input')}
          label="Marquee Title"
          isPreview={isPreview}
        />
      </div>
      <TextArea
        id="description"
        formFieldName="creative.marquee.description"
        className={element('marquee-description')}
        label="Marquee Description"
        charMax={70}
        isPreview={isPreview}
      />
      <div className={element('marquee-second-row')}>
        <TextField
          id="buttonText"
          formFieldName="creative.marquee.buttonText"
          className={element('marquee-button-text-input')}
          label="Button Text"
          isPreview={isPreview}
        />
        <TextField
          id="contentAssetId"
          formFieldName="creative.marquee.contentAssetId"
          className={element('marquee-asset-id-input')}
          label="Content Asset Id"
          isPreview={isPreview}
        />
      </div>
      <ColorPicker
        id="accent"
        formFieldName="creative.marquee.accent"
        label="Background accent color"
        className={element('color-picker')}
      />
      <TaskTabNavigation>
        <TaskTab title="LOGO" isComplete={isTabComplete(creative.logo?.id)}>
          <div className={element('image-row')}>
            <AssetImporterField
              adTypeDimensions={adTypeDimensions?.logo}
              assetType="DISPLAY"
              id="logo.image"
              formFieldName="creative.marquee"
              aspectRatio={IMAGE_ASPECT_RATIO}
              className="logo-uploader"
              label="Logo Image Asset"
              isPreview={isPreview}
              readonly={editMode !== CreativeEditMode.full}
            />
            <TextField
              id="logo.altText"
              formFieldName="creative.altText"
              className="marquee-alt-text"
              label="Logo Alt Text"
              isPreview={isPreview}
            />
          </div>
        </TaskTab>
        <TaskTab title="POSTER" isComplete={isTabComplete(creative.poster?.id)}>
          <div className={element('image-row')}>
            <AssetImporterField
              adTypeDimensions={adTypeDimensions?.orientation?.portrait}
              heightOverride={464}
              assetType="DISPLAY"
              id="poster.image"
              formFieldName="creative.marquee"
              aspectRatio={IMAGE_ASPECT_RATIO}
              className="poster-uploader"
              label="Poster Image Asset"
              isPreview={isPreview}
              readonly={editMode !== CreativeEditMode.full}
            />
            <TextField
              id="poster.altText"
              formFieldName="creative.altText"
              className={element('marquee-alt-text')}
              label="Image Alt Text"
              isPreview={isPreview}
            />
          </div>
        </TaskTab>
      </TaskTabNavigation>
    </CreativeBuilder>
  );
}

export default MarqueePosterCreativeBuilder;
