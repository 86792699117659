import './MultiControl.scss';

import { bem } from 'mission-control-common-components';
import React, { useMemo } from 'react';
import type { ControlProps } from 'react-select';
import { components } from 'react-select';

import type { DropdownOption, DropdownOptionGroup } from '../../Dropdown';
export type MultiControlProps<T = string> = ControlProps<DropdownOption<T>, true, DropdownOptionGroup<T>>;

const [block, element] = bem('multi-controls');

function MultiControl<T = string>(props: MultiControlProps<T>): React.JSX.Element {
  const { hasValue, getValue, selectProps } = props;
  const { handleClearAll, handleSelectAll, options } = selectProps;

  const isSelectAllVisible = useMemo(() => {
    return (
      options &&
      options.map((option) => ('options' in option ? option.options : option)).flat().length !== getValue().length &&
      handleSelectAll
    );
  }, [getValue, handleSelectAll, options]);

  return (
    <div className={block()}>
      <components.Control {...props} />
      {props.selectProps.multipleSelectControls ? (
        <div data-testid="multiple-select-controls" className={element('wrapper')}>
          {isSelectAllVisible && (
            <span data-testid="select-all-control" className={element('button')} onClick={handleSelectAll}>
              Select All
            </span>
          )}
          {isSelectAllVisible && hasValue && ' / '}
          {hasValue && handleClearAll && (
            <span data-testid="clear-all-control" className={element('button')} onClick={handleClearAll}>
              Clear All
            </span>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default MultiControl;
