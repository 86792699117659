import { gql } from '@apollo/client';

import { CREATIVE_METADATA } from '../../../../common/AdForm/hooks/fragments/creativeMetadataFragment';

export const GET_CREATIVE_METADATA = gql`
  query CREATIVE_METADATA($filterOptions: CreativeMetadataFilterOptions, $paginationOptions: PaginationOptions) {
    creativeMetadata(filterOptions: $filterOptions, paginationOptions: $paginationOptions) {
      edges {
        node {
          ...creativeMetadata
        }
      }
    }
  }
  ${CREATIVE_METADATA}
`;
