import './Tags.scss';

import React from 'react';

import AdTagsTable from '../../../../../../../../../common/AdTagsTable/AdTagsTable';
import { useFieldArray } from '../../../../../../../../../common/Form/hooks';
import type { AdTag } from '../../../../../../../../../models';
import bem from '../../../../../../../../../utils/bem';

const [block, element] = bem('multiple-edit-tags');

type TagsProps = {
  isExpanded: boolean;
};

export default function Tags({ isExpanded }: TagsProps): JSX.Element {
  const tableClassName = `${isExpanded ? element('table') : element('table', 'not-expanded')}`;

  const [, { value: adTagList }, { removeAt, replaceAt, push }] = useFieldArray<AdTag>('adTagList');

  return (
    <div className={block()}>
      <div className={element('header')}>
        Adding tags to active ad will change ad status to "OFF" and "Under Review".
      </div>
      <div className={tableClassName}>
        <AdTagsTable
          adTagList={adTagList}
          listHelpers={{
            removeAt,
            replaceAt,
            push,
          }}
          isAdInitiallyWithBillableTag={false}
          unDeletableAd={false}
        />
      </div>
    </div>
  );
}
