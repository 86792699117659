import './AssetImporterField.scss';

import { IconArrowLeft } from '@hulu-react-style-components/icons';
import { useLocation } from '@hulu/react-router-dom';
import { Label as TrekLabel } from '@hulu/react-style-components';
import { useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import type { FileRejection } from 'react-dropzone';

import { TagInput } from '../../../common/Form';
import type { AssetType } from '../../../configs';
import type { AssetV5 } from '../../../models';
import type { LocationState } from '../../../pages/Ads/AdsPageController';
import { getCountryCodesFromLineItem } from '../../../pages/Ads/hooks/util/getCountryCodesFromLineItem';
import { LOCAL_STORAGE_LOCATION_STATE } from '../../../pages/Trafficking/constants';
import bem, { withOptionalClassName } from '../../../utils/bem';
import { workWithLocalStorage } from '../../../utils/storage/localStorage';
import type { AdFormValues } from '../../AdForm/adFormik';
import type { Dimensions } from '../../AdForm/CreativeBuilders/CreativeBuilder';
import useGetAssetById from '../../AdForm/CreativeBuilders/hooks/useGetAssetById';
import RemoveAssetButton from '../../AdForm/RemoveAssetButton';
import AssetPreview from '../../AssetPreview';
import AssetUploader from '../../AssetUploader';
import EntitySearch from '../../EntitySearch';
import { openToastAlert } from '../../ToastAlert/toastAlert';
import VastPreview from '../../VastPreview';
import type { TagInputProps } from '../TagInput';
import { makeLabelChild } from '../utils';
import { AssetPublisher } from './AssetPublisher';
import { AssetTags } from './AssetTags';
import type { UseSearchAssetsResult } from './hooks';
import { useSearchAssets } from './hooks';
import useAssetUpload from './hooks/useAssetUpload';

type ImportMethod = 'upload' | 'search' | 'tag';
export interface AssetImporterFieldProps {
  assetType: AssetType;
  id: string;
  formFieldName: string;
  label?: string;
  secondaryLabel?: string;
  className?: string;
  aspectRatio: number;
  isPreview?: boolean; // is the field in a non-editable "preview" state
  adTypeDimensions?: Dimensions;

  // this field is only required if VAST is allowed, otherwise leave empty
  tagInputProps?: TagInputProps;
  heightOverride?: number;
  initialImportMethod?: ImportMethod;
  allowVast?: boolean;
  readonly?: boolean;
}

const [block, element] = bem('asset-importer-field');

const IMPORT_MEDIA_LINK_HEIGHT_PX = 64;
const SEARCH_FIELD_HEIGHT_PX = 64;
const FORM_ELEMENT_MARGIN = 24;
// This is the minimum height at which the search field can be vertically centered in the container with enough padding around the "Import Media" link
const IMPORT_WRAPPER_MIN_HEIGHT = 2 * (IMPORT_MEDIA_LINK_HEIGHT_PX + FORM_ELEMENT_MARGIN) + SEARCH_FIELD_HEIGHT_PX;

function AssetImporterField({
  assetType,
  id,
  formFieldName,
  label,
  secondaryLabel,
  className,
  aspectRatio,
  tagInputProps,
  heightOverride = 0,
  adTypeDimensions,
  initialImportMethod = 'upload',
  allowVast = false,
  readonly,
}: AssetImporterFieldProps): JSX.Element {
  const [importMethod, setImportMethod] = useState<ImportMethod>(initialImportMethod);
  const { fetchAssetById } = useGetAssetById();

  const {
    values: { creative },
    errors,
  } = useFormikContext<AdFormValues>();

  const creativeLibraryId = creative?.creativeLibraryId || '';

  const location = useLocation();
  const state = location.state as LocationState;
  const localLocationState = workWithLocalStorage.getData<LocationState>(LOCAL_STORAGE_LOCATION_STATE);
  const lineItemState = state?.lineItemState || localLocationState?.lineItemState;

  const countries = getCountryCodesFromLineItem(lineItemState?.lineItem);

  const { asset, setAsset, loading, percentUploaded, cancelRequest, onDrop } = useAssetUpload(
    formFieldName,
    assetType,
    creativeLibraryId,
    countries
  );

  const switchToSearch = (): void => {
    setImportMethod('search');
  };

  const switchToUpload = (): void => {
    // if exiting vast experience, this should omit url,
    // which will cause creative to change back from VAST_VIDEO to VIDEO

    tagInputProps?.setUrl('');
    setImportMethod('upload');
  };

  const handleBackKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      switchToUpload();
    }
  };

  const handleSelect = async (newAsset: AssetV5): Promise<void> => {
    try {
      const { review } = await fetchAssetById(newAsset.id);
      setAsset({
        ...newAsset,
        review: review || undefined,
      });
    } catch (err) {
      openToastAlert({
        alertType: 'error',
        message: 'Selecting asset Failed',
        description: <>Something went wrong when . {prompt}</>,
      });
    }
  };

  const switchToTag = tagInputProps
    ? (): void => {
        setImportMethod('tag');
      }
    : undefined;

  const maxSearchResults = 50;

  // Styles computed based on aspect ratio:
  const [width, setWidth] = useState(0);
  const ref = useCallback((node) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  // Determine the wrapper height based on the aspect ratio. If too short to fit all components, use IMPORT_WRAPPER_MIN_HEIGHT instead.
  const importWrapperHeight = Math.max(width / aspectRatio || 0, IMPORT_WRAPPER_MIN_HEIGHT);
  // Vertically center the search bar or tag input within the import-wrapper without allowing it to reposition when search results render.
  const searchBarTopMargin = 0.5 * (importWrapperHeight - SEARCH_FIELD_HEIGHT_PX) - IMPORT_MEDIA_LINK_HEIGHT_PX;
  const searchBarTopMarginStyle = { marginTop: `${searchBarTopMargin}px` };

  const validationError = errors[formFieldName as keyof AdFormValues];
  const additionalClassName = validationError ? element('validation-error') : undefined;

  const useSearchHook = (query: string): UseSearchAssetsResult => useSearchAssets(query, [assetType], maxSearchResults);

  const BackToUploader = (): JSX.Element => (
    <button
      aria-label="back-to-uploader"
      className={element('back-to-uploader')}
      onClick={switchToUpload}
      onKeyDown={handleBackKeyDown}
      type="button"
    >
      <IconArrowLeft />
      <span>Import Media</span>
    </button>
  );

  return (
    <div className={withOptionalClassName(block(), className)}>
      {(label || secondaryLabel) && !tagInputProps?.isSubmitted && id && (
        <TrekLabel htmlFor={id} isErr={!!validationError}>
          {makeLabelChild(label, secondaryLabel)}
        </TrekLabel>
      )}
      {tagInputProps?.isSubmitted ? (
        <VastPreview {...tagInputProps} />
      ) : asset?.id ? (
        <>
          <AssetPreview asset={asset} creativeId={creative?.id} />
          <RemoveAssetButton
            assetName={asset?.name || ''}
            assetType={assetType}
            formFieldName={formFieldName}
            readonly={readonly}
            deleteIds={{ creativeId: creative?.id, assetId: asset?.id }}
          />
        </>
      ) : (
        <div
          className={withOptionalClassName(element('import-wrapper'), additionalClassName)}
          ref={ref}
          style={{ height: heightOverride || importWrapperHeight }}
        >
          {importMethod === 'search' && (
            <>
              <BackToUploader />
              <EntitySearch<AssetV5>
                apiResultsArePaginated
                className={element('search')}
                expandedEntityDetails={(asset): JSX.Element => (
                  <>
                    <AssetPublisher publisher={asset.publisher} />
                    <AssetTags tagList={asset.assetTagList} />
                  </>
                )}
                label="Search for Existing Assets"
                maxSearchResults={maxSearchResults}
                onSelect={handleSelect}
                readonly={readonly}
                searchHook={useSearchHook}
                style={searchBarTopMarginStyle}
              />
            </>
          )}
          {importMethod === 'upload' && (
            <AssetUploader
              assetType={assetType}
              assetName={asset.name || asset.filename}
              adTypeDimensions={adTypeDimensions}
              switchToSearch={switchToSearch}
              switchToTag={switchToTag}
              onDrop={async (acceptedFiles: File[], fileRejections: FileRejection[]): Promise<void> =>
                await onDrop(acceptedFiles[0], fileRejections)
              }
              aspectRatio={aspectRatio}
              loading={loading}
              percentUploaded={percentUploaded}
              cancelRequest={cancelRequest}
              allowVast={allowVast}
              readonly={readonly}
            />
          )}
          {importMethod === 'tag' && tagInputProps && (
            <>
              <div className={element('vast-input')}>
                <TagInput style={searchBarTopMarginStyle} {...tagInputProps} />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default AssetImporterField;
