import './TableActionBar.scss';

import envConfig from '@hulu/env-config';
import { SearchInput } from '@hulu/react-style-components';
import type { ModalProps } from 'antd';
import { User } from 'mission-control-common-components';
import type { Dispatch, SetStateAction } from 'react';
import React, { useState } from 'react';
import type { Row, TableInstance } from 'react-table';

import Button from '../../../common/Button';
import ModalPopup from '../../../common/ModalPopup';
import ToggleSwitch from '../../../common/ToggleSwitch';
import type { PermissionsNames } from '../../../constants';
import { TraffickingTableName } from '../../../constants';
import { useFiltersContext } from '../../../contexts/FilterContext';
import bem from '../../../utils/bem';
import { singularTitleCase } from '../../../utils/formatting';
import { SequenceButton } from '../../Sequence';
import CreateReviewButton from '../CreateReviewButton';
import CustomizeColumns from '../CustomizeColumns';
import DuplicateAd from '../DuplicateAd';
import EditMultipleButton from '../EditMultipleButton';
import ExportDropdown from '../ExportDropdown';
import OverrideReviewButton from '../OverrideReviewButton/OverrideReviewButton';
import { DrawerType, EntityTypeArray } from '../TraffickingPage/hooks/makePageDrawerPlugin';
import useDeleteAds from '../TraffickingPage/hooks/useDeleteAds';
import type { UseSequenceViewResult } from '../TraffickingPage/hooks/useSequenceView';
import type { AdModel, EntityModel, LineItemModel } from '../TraffickingPage/modelConverters';

const [block, element] = bem('table-action-bar');

export interface TableActionBarProps extends UseSequenceViewResult {
  tableInstance: TableInstance<EntityModel>;
  tableName: TraffickingTableName;
  total: number;
  setOffset: Dispatch<SetStateAction<number>>;
  selectedLineItems: LineItemModel[];
  selectedAds: AdModel[];
  handleDeleteItemsFromTable(ids: string[]): void;
}

const TableActionBar = ({
  tableInstance,
  tableName,
  total,
  setOffset,
  selectedLineItems,
  selectedAds,
  handleDeleteItemsFromTable,
  isSequenceViewEnabled,
  toggleIsSequenceViewEnabled,
}: TableActionBarProps): JSX.Element => {
  const permissions = User.getPermissions<PermissionsNames>();

  const { applyTableFilter } = useFiltersContext();

  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);

  const { deleteAds, loading } = useDeleteAds();

  const selectedFlatRows = tableInstance?.selectedFlatRows;

  const selectedOriginalRows = tableInstance?.selectedFlatRows.map((item) => item.original);

  const handleOpenConfirmationModal = (): void => {
    setConfirmationModalVisible(true);
  };

  const handleCloseConfirmationModal = (): void => {
    setConfirmationModalVisible(false);
  };

  const handleSearchSubmit = (term: string): void => {
    if (term.length < 3) return;
    setOffset(0);
    applyTableFilter(tableName, 'searchTerm', term);
    tableInstance.setSearchTerm(term);
  };

  const onClearSearch = (): void => {
    setOffset(0);
    applyTableFilter(tableName, 'searchTerm', '');
    tableInstance.setSearchTerm('');
  };

  const handleDeleteAds = async (): Promise<void> => {
    const idList: string[] = selectedFlatRows.map((row: Row<EntityModel>) => row.id);

    const response = await deleteAds(idList);

    if (!response?.errors?.length) handleDeleteItemsFromTable(idList);

    handleCloseConfirmationModal();
  };

  const handleMultipleEdit = (): void =>
    tableInstance.openTraffickingPageDrawer({
      drawerType: DrawerType.MULTIPLE_EDIT,
      dataType: tableName === TraffickingTableName.ads ? EntityTypeArray.AD_ARRAY : EntityTypeArray.LINE_ITEM_ARRAY,
      entities: selectedOriginalRows,
    });

  const okButtonProps = {
    loading,
  };

  const cancelButtonProps = {
    disabled: loading,
  };

  const confirmationModalProps: ModalProps = {
    title: 'Delete Ad',
    okText: 'Yes',
    cancelText: 'No',
    className: 'free-btn',
    okButtonProps,
    cancelButtonProps,
  };

  const someUnDeletableAds = selectedFlatRows.some((item: Row<EntityModel>) => (item.original as AdModel)?.unDeletable);

  const modalDeleteAdsMessage = `Are you sure you want to delete ${selectedFlatRows.length > 1 ? "ad's" : 'ad'}?`;

  const uniqueParentLineOmsLinkIds = selectedAds.reduce((accumulator, ad) => {
    if (ad.lineItem && ad.lineItem?.parentLineOmsLink?.id) {
      accumulator.add(ad.lineItem.parentLineOmsLink.id);
    }

    return accumulator;
  }, new Set<string>());

  return (
    <>
      <div className={block()}>
        <div className={element('left-children')}>
          {/* Only render the button for ads and line items, not campaigns */}
          {[TraffickingTableName.lineItems, TraffickingTableName.ads].includes(tableName) && (
            <ToggleSwitch
              isHorizontalPositioning
              isOn={isSequenceViewEnabled}
              onChange={toggleIsSequenceViewEnabled}
              label="Sequence View"
            />
          )}
          {/* Only render the button for campaigns and line items, not ads */}
          {[TraffickingTableName.campaigns, TraffickingTableName.lineItems].includes(tableName) && (
            <>
              <CreateReviewButton
                selectedFlatRows={tableInstance.selectedFlatRows}
                tableName={tableName as TraffickingTableName.campaigns | TraffickingTableName.lineItems}
              />
            </>
          )}
          {TraffickingTableName.ads === tableName && (
            <>
              <DuplicateAd
                selectedAd={tableInstance?.selectedFlatRows[0]?.original as AdModel | undefined}
                selectedLineItems={selectedLineItems}
                isDisabled={!permissions?.duplicateAd || tableInstance.selectedFlatRows.length !== 1}
              />
              {envConfig.REACT_APP_FEATURE_ENABLE_CREATE_SEQUENCE === 'true' && (
                <SequenceButton
                  isDisabled={!permissions?.createAd || !uniqueParentLineOmsLinkIds.size}
                  uniqueParentLineOmsLinkIds={Array.from(uniqueParentLineOmsLinkIds)}
                />
              )}
              <Button
                variant="outlined"
                className={element('button')}
                disabled={
                  !permissions?.deleteAd || !(tableInstance?.selectedFlatRows?.length > 0) || someUnDeletableAds
                }
                onClick={handleOpenConfirmationModal}
              >
                Delete ad
              </Button>
            </>
          )}
          {/* Only render the button for ads and line items, not campaigns */}
          {[TraffickingTableName.lineItems, TraffickingTableName.ads].includes(tableName) && (
            <EditMultipleButton
              tableName={tableName}
              selectedObjectsLength={selectedOriginalRows.length}
              onClick={handleMultipleEdit}
              hasPermission={permissions?.updateLineItem}
            />
          )}
          <OverrideReviewButton selectedFlatRows={tableInstance?.selectedFlatRows} tableName={tableName} />
        </div>
        <div className={element('right-children')}>
          <SearchInput
            value={tableInstance.searchTerm}
            onSubmit={(t: string): void => handleSearchSubmit(t)}
            onClear={onClearSearch}
            placeholder={`Search ${singularTitleCase(tableName)}s`}
          />
          <CustomizeColumns
            originalColumns={tableInstance.columns}
            setHiddenColumns={tableInstance.setHiddenColumns}
            columns={tableInstance.allColumns}
            setColumnOrder={tableInstance.setColumnOrder}
            tableName={tableName}
          />
          <ExportDropdown tableInstance={tableInstance} tableName={tableName} total={total} />
        </div>
      </div>
      <ModalPopup
        visible={confirmationModalVisible}
        onOk={handleDeleteAds}
        modalProps={confirmationModalProps}
        modalMessage={modalDeleteAdsMessage}
        onCancel={handleCloseConfirmationModal}
      />
    </>
  );
};

export default TableActionBar;
