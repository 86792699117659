import './ScheduleCalendar.scss';

import { IconClockOutlined } from '@hulu-react-style-components/icons';
import { Tab, TabNavigation } from '@hulu/react-style-components';
import { cloneDeep as _cloneDeep } from 'lodash';
import React, { memo, useCallback, useEffect, useState } from 'react';

import type { ActiveDayTypeV5, DayPartV5 } from '../../../apis/graphql';
import { useFieldArray } from '../../../common/Form/hooks';
import TimeMatrix from '../../../common/TimeMatrix';
import TimesList from '../../../common/TimesList';
import bem from '../../../utils/bem';
import { orderedDaysOfWeek } from '../../../utils/formatting';
import { getInitialTimeMatrix, getScheduleFromMatrix } from './utils';

export interface Props {
  editMode: boolean;
  dayPartList: DayPartV5[];
}

type TabTitle = 'All Times' | 'Specific Times';

const [block, element] = bem('schedule-calendar');

const DEFAULT_PART_LIST: DayPartV5 = {
  activeDayList: orderedDaysOfWeek as ActiveDayTypeV5[],
  firstActiveHour: 0,
  lastActiveHour: 23,
};

function ScheduleCalendar({ editMode, dayPartList }: Props): JSX.Element {
  const [, , { setValue }] = useFieldArray<DayPartV5>('schedule.dayPartList');
  const [draftDayPartList, setDraftDayPartList] = useState(dayPartList || DEFAULT_PART_LIST);
  const [timeMatrix, setTimeMatrix] = useState(getInitialTimeMatrix(draftDayPartList));

  useEffect(() => {
    setDraftDayPartList(dayPartList || DEFAULT_PART_LIST);
    setTimeMatrix(getInitialTimeMatrix(dayPartList));
  }, [dayPartList]);

  useEffect(() => {
    setValue(draftDayPartList);
  }, [draftDayPartList, setValue]);

  const changeValueByXY = useCallback(
    (x: number, y: number): void => {
      editMode &&
        setTimeMatrix((value) => {
          const newValue = _cloneDeep(value);
          newValue[x][y] = !newValue[x][y];
          setDraftDayPartList(getScheduleFromMatrix(newValue));
          return newValue;
        });
    },
    [editMode]
  );

  const changeRow = useCallback(
    (index: number, input: boolean): void => {
      editMode &&
        setTimeMatrix((value) => {
          const newValue = _cloneDeep(value);
          newValue[index].fill(input);
          setDraftDayPartList(getScheduleFromMatrix(newValue));
          return newValue;
        });
    },
    [editMode]
  );

  const changeColumn = useCallback(
    (index: number, input: boolean): void => {
      editMode &&
        setTimeMatrix((value) => {
          const newValue = _cloneDeep(value);
          newValue.forEach((hours, i) => {
            hours[index] = input;
          });
          setDraftDayPartList(getScheduleFromMatrix(newValue));
          return newValue;
        });
    },
    [editMode]
  );

  const handleTabClick = useCallback(
    (tabTitle: TabTitle): void => {
      if (tabTitle === 'All Times') {
        setDraftDayPartList([]);
      }

      if (tabTitle === 'Specific Times') {
        const scheduleFromMatrix = getScheduleFromMatrix(timeMatrix);
        setDraftDayPartList(scheduleFromMatrix.length ? scheduleFromMatrix : [DEFAULT_PART_LIST]);
      }
    },
    [timeMatrix]
  );

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, tabTitle: TabTitle): void => {
    e.stopPropagation();
    if (editMode && e.key === 'Enter') {
      handleTabClick(tabTitle);
    }
  };

  return (
    <div className={block()}>
      <div className={element('date-title')}>
        <IconClockOutlined />
        <h5>Dayparts</h5>
      </div>
      <TabNavigation
        className={element('segmented-control', !editMode ? 'disabled' : '')}
        selectedTab={draftDayPartList && draftDayPartList.length ? 'Specific Times' : 'All Times'}
        onTabClick={editMode ? handleTabClick : undefined}
      >
        <Tab
          key="All Times"
          title="All Times"
          tabIndex={0}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>): void => handleKeyDown(e, 'All Times')}
        />
        <Tab
          key="Specific Times"
          title="Specific Times"
          tabIndex={0}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>): void => handleKeyDown(e, 'Specific Times')}
        >
          <TimeMatrix
            values={timeMatrix}
            onValueChange={changeValueByXY}
            onColumnChange={changeColumn}
            onRowChange={changeRow}
          />
          <TimesList dayParts={dayPartList} />
        </Tab>
      </TabNavigation>
    </div>
  );
}

export default memo(ScheduleCalendar);
