import gql from 'graphql-tag';

export const OVERRIDE_ADS_REVIEW = gql`
  mutation OverrideAdsReview($ids: [ID!]!) {
    overrideAdsReviewV5(input: $ids) {
      id
      review
      status
    }
  }
`;
