import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import Button from '../../../../../common/Button';
import type { AdFormValues } from '../../../adFormik';
import { ingestButton } from '../../configs';
import { startAssetIngestion } from '../../hooks/startAssetIngestion';

/**
 * Displays a button that will trigger an asset ingestion mutation when clicked.
 * Will be disabled if the Ad form is dirty.
 * @param displayRetry Displays a retry message if true.
 * @param disabled **Deprecated:** Used only until Pause Creative Builder is implemented
 */
export const AssetIngestButton: React.FC<{ displayRetry?: boolean; disabled?: boolean }> = ({
  displayRetry,
  disabled,
}) => {
  const [ingestBusy, setIngestBusy] = useState(false);

  const {
    values: { creative },
  } = useFormikContext<AdFormValues>();
  const buttonLabel = displayRetry ? ingestButton.retryText : ingestButton.defaultText;

  const handleClick = async (): Promise<void> => {
    setIngestBusy(true);
    await startAssetIngestion(creative.id, creative.url).finally(() => {
      setIngestBusy(false);
    });
  };

  return (
    <Button size="medium" title={buttonLabel} onClick={handleClick} disabled={ingestBusy || disabled}>
      {buttonLabel}
    </Button>
  );
};
