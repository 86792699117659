import './SideNav.scss';

import React, { useState } from 'react';
import { Link, scroller } from 'react-scroll';

import bem, { withOptionalClassName } from '../../utils/bem';
import NavIndicator from './NavIndicator';

interface SideNavProps {
  links: string[];
  containerId?: string;
  className?: string;
  testid?: string;
}

const [block, element] = bem('side-nav');

export default function SideNav({ links, containerId, className, testid }: SideNavProps): JSX.Element {
  const [activeNav, setActiveNav] = useState<number>(0);
  const scrollTo = (elementName: string, linkIndex: number): void => {
    setActiveNav(linkIndex);
    scroller.scrollTo(elementName, {
      duration: 0,
      delay: 0,
      containerId,
    });
  };

  return (
    <div className={withOptionalClassName(block(), className)} data-testid={testid}>
      <div className={element('nav-container')}>
        <NavIndicator linksLength={links.length} activeLink={activeNav} element={element} />
        <div className={element('nav-items')}>
          {links.map((link: string, linkIndex: number) => {
            const isActiveLink = linkIndex === activeNav ? 'active' : '';
            return (
              <Link
                key={link}
                to={link}
                spy={false}
                hashSpy={false}
                containerId={containerId}
                className={element(`nav-link`, isActiveLink)}
                activeClass="active"
                smooth={false}
                duration={0}
                onClick={(): void => scrollTo(link, linkIndex)}
              >
                {link}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
