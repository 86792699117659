import { User } from 'mission-control-common-components';
import type React from 'react';
import { useEffect } from 'react';

const OauthCallback: React.FC = () => {
  const oauthCallback = async (): Promise<void> => {
    await User.oauthCallback({ redirectToLoginOnError: true });
  };

  useEffect(() => {
    oauthCallback();
  }, []);

  return null;
};

export default OauthCallback;
