import {
  ADS_KEY_FIELDS,
  CAMPAIGNS_KEY_FIELDS,
  LINE_ITEMS_KEY_FIELDS,
  SEARCH_CREATIVES_KEY_FIELDS,
  TARGETING_VALUES_KEY_FIELD,
  TRAFFICKER_EMAILS_KEY_FIELDS,
} from '../../../constants';
import { concatPaginationWithEdges } from '../utils';

export const Query = {
  fields: {
    campaignsV5: concatPaginationWithEdges(CAMPAIGNS_KEY_FIELDS),
    lineItemsV5: concatPaginationWithEdges(LINE_ITEMS_KEY_FIELDS),
    adsV5: concatPaginationWithEdges(ADS_KEY_FIELDS),
    getTargetingValuesByCategoryAndDimensionID: concatPaginationWithEdges(TARGETING_VALUES_KEY_FIELD),
    getTraffickerEmails: concatPaginationWithEdges(TRAFFICKER_EMAILS_KEY_FIELDS),
    searchCreativesV5: concatPaginationWithEdges(SEARCH_CREATIVES_KEY_FIELDS),
  },
};
