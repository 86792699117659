import './DatesInfoContent.scss';

import { detect } from 'detect-browser';
import React from 'react';

import type { DateRange as Range } from '../../../models';
import bem from '../../../utils/bem';
import Button from '../../Button';
import type { DefaultRangeType } from '../../DateRange';
import { DateRange } from '../../DateRange';
import RemoveButton from '../../RemoveButton';
import SelectTimeZone from '../../SelectTimeZone';
import SafariToggleDisableLastEndDate from './SafariToggleDisableLastEndDate';

export type DatesInfoContentProps = {
  dateRangeList: Range[];
  editMode?: boolean;
  column?: boolean;
  readonly?: boolean;
  validationError: string | null;
  addDateRange: () => void;
  nullableEndDate?: boolean;
  handleDateChange: (field: 'start' | 'end', value: string, index: number) => void;
  handleToggleDisableLastEndDate: (isDisabled: boolean, index: number) => void;
  handleRemove: (index: number) => void;
  isLastDateRangeDisabled: boolean;
  setIsLastDateRangeDisabled: (value: boolean) => void;
};

const CUSTOM_TITLES = {
  start: 'Start',
  end: 'End',
};

const [block, element] = bem('dates-info-content');

const DatesInfoContent = ({
  dateRangeList,
  editMode,
  column,
  readonly,
  validationError,
  addDateRange,
  nullableEndDate,
  handleDateChange,
  handleToggleDisableLastEndDate,
  handleRemove,
  isLastDateRangeDisabled,
  setIsLastDateRangeDisabled,
}: DatesInfoContentProps): JSX.Element => {
  const formatDateRange = ({ startDate, endDate }: Range): DefaultRangeType => {
    return {
      start: startDate,
      end: endDate,
    };
  };

  const browser = detect();
  const isSafari = browser?.name === 'safari';
  const lastDateRangeIndex = dateRangeList.length - 1;
  const nullableDate = isSafari && !isLastDateRangeDisabled ? false : nullableEndDate;

  return (
    <div className={block()}>
      {isSafari && (
        <SafariToggleDisableLastEndDate
          isLastDateRangeDisabled={isLastDateRangeDisabled}
          handleToggleDisableLastEndDate={handleToggleDisableLastEndDate}
          setIsLastDateRangeDisabled={setIsLastDateRangeDisabled}
          lastDateRangeIndex={lastDateRangeIndex}
          readonly={readonly}
          className={element('date-range-switch-disable-end-date')}
        />
      )}
      <div data-testid="fieldset" className={element('fieldset', column ? 'column' : '')}>
        <div data-testid="date-range-list" className={element('date-range-list')}>
          <div>
            <div className={element('date-range-list-title')}>
              <div className={element('date-range-list-title-start')}>{CUSTOM_TITLES.start}</div>
              <div className={element('date-range-list-title-end')}>{CUSTOM_TITLES.end}</div>
            </div>
            <div data-testid="date-range-list-items" className={element('date-range-list-items')}>
              {dateRangeList.map((dateRange, index) => (
                <div className={element('date-range')} key={index}>
                  <DateRange
                    dateRange={formatDateRange(dateRange)}
                    editMode={editMode}
                    nullableEndDate={nullableDate}
                    onChange={(field: 'start' | 'end', value: string): void => handleDateChange(field, value, index)}
                    customTitles={CUSTOM_TITLES}
                    showTitle={false}
                    readonly={readonly}
                    showInfinity={index === lastDateRangeIndex && isLastDateRangeDisabled}
                  />
                  {index !== 0 && !readonly && (
                    <div className={element('remove-date-range')}>
                      <RemoveButton
                        arialLabel="remove day range"
                        onClick={(): void => {
                          if (index === lastDateRangeIndex && isLastDateRangeDisabled) {
                            setIsLastDateRangeDisabled(false);
                          }

                          handleRemove(index);
                        }}
                        readonly={readonly}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          {validationError && (
            <div className={element('validation')} data-testid="validation-message">
              {/* &nbsp character prevents the whitespace from resizing if empty */}
              {validationError || '\u00A0'}
            </div>
          )}
        </div>

        <div className="timezone-wrapper">
          <SelectTimeZone editMode={editMode} readonly={readonly} />
        </div>
      </div>
      {editMode && (
        <Button
          className={element('add')}
          icon="IconCirclePlus"
          variant="outlined"
          aria-label="add day range"
          onClick={addDateRange}
          data-testid="add-date-range"
          disabled={readonly}
        >
          New Row
        </Button>
      )}
    </div>
  );
};

export default DatesInfoContent;
