import type { TargetingTermValue } from '../../../../../apis/graphql/types';
import type { TargetingTermValuesFragment } from '../../../../../apis/graphql/types';
import type { AdFormValues } from '../../../../../common/AdForm/adFormik/types';
import type { LocationState } from '../../../AdsPageController';
import { reduceTargetingTermsToCountryStrings } from './reduceTargetingTermsToCountryStrings';

type ParsedCountryCodeData = {
  combinedCountryCodes: string[];
};

export const getParsedCountryCodeData = (values: AdFormValues, locationState: LocationState): ParsedCountryCodeData => {
  // pull country codes from line item targeting
  const lineItemTargeting: TargetingTermValuesFragment[] =
    locationState?.lineItemState?.lineItem?.targetingTermValues || [];

  // full country targeting terms
  const lineItemCountryTerms = lineItemTargeting.filter((term) => term?.dimension?.id === 'country');
  const adCountryTerms: TargetingTermValue[] = (values?.targetingTermValues as TargetingTermValue[]).filter(
    (term: TargetingTermValue) => term?.dimension?.id === 'country'
  );

  // simple string array of country codes
  const lineItemCountryCodes = reduceTargetingTermsToCountryStrings(lineItemCountryTerms);
  const adCountryCodes = reduceTargetingTermsToCountryStrings(adCountryTerms);

  // combine country codes and remove duplicates
  const combinedCountryCodes: string[] = [...lineItemCountryCodes, ...adCountryCodes].filter(
    (value, index, array) => array.indexOf(value) === index
  );

  return {
    combinedCountryCodes,
  };
};
