import './TraffickingToggleSection.scss';

import { bem } from 'mission-control-common-components';
import { memo, useCallback, useMemo, useState } from 'react';
import type { Row } from 'react-table';

import SelectedItemsPill from '../../../pages/Trafficking/SelectedItemsPill';
import type { EntityModel } from '../../../pages/Trafficking/TraffickingPage/modelConverters';
import { ToggleSection } from '../../ToggleSection';
import type { ComposedTraffickingTableProps } from '../../TraffickingTable/ComposedTraffickingTable';
import ComposedTraffickingTable from '../../TraffickingTable/ComposedTraffickingTable';

type Props = {
  getComposedTraffickerTableProps: () => Omit<ComposedTraffickingTableProps, 'rows' | 'tableIndex'>;
  title?: string;
  rows: Row<EntityModel>[];
  toggleRowSelected: (rowId: string, set?: boolean | undefined) => void;
  tableIndex: number;
};

const [block] = bem('trafficking-toggle-section');

const TraffickingToggleSection = ({
  title,
  getComposedTraffickerTableProps,
  rows,
  toggleRowSelected,
  tableIndex,
}: Props): React.JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);

  const unselectRows = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      rows.forEach(({ id }) => {
        toggleRowSelected(id, false);
      });
    },
    [rows, toggleRowSelected]
  );

  const titleComponent = useMemo(() => {
    const selectedAmount = rows.filter(({ isSelected }) => !!isSelected).length;

    return (
      <div>
        <span>{title}</span>
        {selectedAmount > 0 && <SelectedItemsPill selectedRowsCount={selectedAmount} onClearFunction={unselectRows} />}
      </div>
    );
  }, [rows, title, unselectRows]);

  return (
    <div className={block()}>
      <ToggleSection
        title={titleComponent}
        isExpanded={isExpanded}
        handleToggle={(): void => setIsExpanded(!isExpanded)}
      >
        <ComposedTraffickingTable {...getComposedTraffickerTableProps()} tableIndex={tableIndex} rows={rows} />
      </ToggleSection>
    </div>
  );
};

export default memo(TraffickingToggleSection);
