import './SelectedFiltersChips.scss';

import React from 'react';

import ClearAllButton from '../../../../../common/Button/ClearAllButton';
import type { SearchParamsValuesType } from '../../../../../constants';
import bem from '../../../../../utils/bem';
import type { AdditionalFilterDropdown } from '../constants';
import { CLEAR_ALL_BUTTON_LABEL } from './constants';
import FilterChip from './FilterChip';

const [block, element] = bem('selected-filters-chips');

export type SelectedFiltersChipsProps = {
  selectedFilters: AdditionalFilterDropdown[];
  onRemoveSingleChip(searchParam: SearchParamsValuesType): void;
  onRemoveAllChips: () => void;
};

const SelectedFiltersChips: React.FC<SelectedFiltersChipsProps> = ({
  selectedFilters,
  onRemoveSingleChip,
  onRemoveAllChips,
}) => {
  return (
    <div data-testid="filterChips" className={block()}>
      {selectedFilters.map(({ label, selectedFilterValues, searchParam }, idx) => {
        return (
          <FilterChip
            key={`${label}_${idx}`}
            label={label}
            selectedValues={selectedFilterValues ?? []}
            searchParam={searchParam}
            onRemoveSingleChip={onRemoveSingleChip}
          />
        );
      })}
      <ClearAllButton label={CLEAR_ALL_BUTTON_LABEL} className={element('clear-all-btn')} onClick={onRemoveAllChips} />
    </div>
  );
};

export default SelectedFiltersChips;
