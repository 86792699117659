import type { MutationFunction } from '@apollo/client';

import type {
  AssetInputV5,
  BatchReviewAssetInputV5,
  CountryCode,
  CreateAdsPageAdMutation,
  CreateAdsPageAdMutationVariables,
  CreateAdsPageAdWithAssetMutation,
  CreateAdsPageAdWithAssetMutationVariables,
  CreateAdWithBatchAssetsMutation,
  CreateAdWithBatchAssetsMutationVariables,
  CreateAdWithGatewayAssetsMutation,
  CreateAdWithGatewayAssetsMutationVariables,
} from '../../../../../apis/graphql';
import type { AdFormValues } from '../../../../../common/AdForm/adFormik';
import type { GatewayGoAssetInput } from '../../util/buildGatewayAssetInput';
import { constructCreateAdInput } from './constructCreateAdInput';
import { getAssetId } from './getAssetId';

type Mutations = {
  createAd: MutationFunction<CreateAdsPageAdMutation>;
  createAdWithAsset: MutationFunction<CreateAdsPageAdWithAssetMutation>;
  createAdWithBatchAssets: MutationFunction<CreateAdWithBatchAssetsMutation>;
  createAdWithGatewayAssets: MutationFunction<CreateAdWithGatewayAssetsMutation>;
};

type WithoutAsset = { mutation: Mutations['createAd']; variables: CreateAdsPageAdMutationVariables };
type WithAsset = { mutation: Mutations['createAdWithAsset']; variables: CreateAdsPageAdWithAssetMutationVariables };
type WithBatchAssets = {
  mutation: Mutations['createAdWithBatchAssets'];
  variables: CreateAdWithBatchAssetsMutationVariables;
};
type WithGatewayAssets = {
  mutation: Mutations['createAdWithGatewayAssets'];
  variables: CreateAdWithGatewayAssetsMutationVariables;
};

export function getCreateMutation(
  mutations: Mutations,
  adFormValues: AdFormValues,
  lineItemId: string,
  assetInput?: AssetInputV5 | GatewayGoAssetInput
): WithoutAsset | WithAsset | WithBatchAssets | WithGatewayAssets {
  const assetId = getAssetId(adFormValues.creative.type, adFormValues);

  // The Ad has a GatewayGo Asset
  if ((assetInput as GatewayGoAssetInput)?.display && (assetInput as GatewayGoAssetInput)?.video) {
    const withGatewayAssets: WithGatewayAssets = {
      mutation: mutations.createAdWithGatewayAssets,
      variables: {
        adInput: constructCreateAdInput(adFormValues, lineItemId),
        displayAssetId: adFormValues.creative.image?.id || '',
        displayAssetInput: (assetInput as GatewayGoAssetInput).display as BatchReviewAssetInputV5,
        videoAssetId: adFormValues.creative.video?.id || '',
        videoAssetInput: (assetInput as GatewayGoAssetInput).video as BatchReviewAssetInputV5,
      },
    };
    return withGatewayAssets;
  }

  // The Ad has one or more standard Assets for mutation
  if (assetId && assetInput) {
    // The Ad hsa a single Asset
    if (typeof assetId === 'string') {
      const withAsset: WithAsset = {
        mutation: mutations.createAdWithAsset,
        variables: {
          adInput: constructCreateAdInput(adFormValues, lineItemId),
          assetId,
          assetInput: assetInput as AssetInputV5,
        },
      };
      return withAsset;
    }

    // The Ad has multiple Assets
    if (Array.isArray(assetId)) {
      // Destructure to remove 'name' and keep the rest of the object to match the typing of batch mutation
      const { name, ...rest } = assetInput as AssetInputV5;

      const withBatchAssets: WithBatchAssets = {
        mutation: mutations.createAdWithBatchAssets,
        variables: {
          adInput: constructCreateAdInput(adFormValues, lineItemId),
          idList: assetId,
          assetInput: { ...rest, countryCodes: (assetInput as AssetInputV5).countryCodes as CountryCode[] },
        },
      };
      return withBatchAssets;
    }
  }

  // No Asset condition has been met; we're creating the Ad only
  const withoutAsset: WithoutAsset = {
    mutation: mutations.createAd,
    variables: { adInput: constructCreateAdInput(adFormValues, lineItemId) },
  };
  return withoutAsset;
}
