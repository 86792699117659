import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

export type ViewabilityVendor = keyof typeof viewabilityVendorDisplayNames;
export type CoppaViewabilityVendor = keyof typeof coppaViewabilityVendorDisplayNames;

const viewabilityVendorDisplayNames = {
  MOAT: enumToTitleCase,
  IAS: 'IAS',
  IAB: 'IAB',
};
const coppaViewabilityVendorDisplayNames = {
  IAS: 'IAS',
  IAB: 'IAB',
};

export const viewabilityVendors = makeConfig<ViewabilityVendor>(viewabilityVendorDisplayNames);
export const coppaApprovedViewabilityVendors = makeConfig<CoppaViewabilityVendor>(coppaViewabilityVendorDisplayNames);

export function convertViewabilityVendor(apiValue: string): DisplayEnum<ViewabilityVendor> {
  return convertToDisplayEnum(apiValue, viewabilityVendors);
}
