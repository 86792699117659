import './EditableFrequencyCap.scss';

import React, { memo, useMemo, useState } from 'react';
import { useList } from 'react-use';

import type { FrequencyCapInputV5, FrequencyCapTypeV5 } from '../../../apis/graphql';
import type { FrequencyCapUnitType } from '../../../configs';
import { UNRECOGNIZED } from '../../../configs';
import type { FrequencyCap } from '../../../models';
import bem from '../../../utils/bem';
import FrequencyCaps from '../../FrequencyCaps';
import type { ClearableFrequencyCap } from '../../FrequencyCaps/FrequencyCaps';
import EditableCell from '../EditableCell';
import type { EditableInputVariant } from '../EditableCell/EditableCell';
import EditableCellModal from '../EditableCellModal';

export interface FrequencyCapErrorObject {
  hasErrors: boolean;
  limitErrorPositions: number[];
  countErrorPositions: number[];
  unitErrorPositions: number[];
  errorMessage?: string;
}

export interface EditableFrequencyCapProps {
  updateField: (frequencyCaps: FrequencyCapInputV5[]) => void;
  variant: EditableInputVariant;
  initialFrequencyCaps: FrequencyCap[] | null;
  defaultFrequencyCapType: FrequencyCapTypeV5;
  isDisabled?: boolean;
}

const [block, element] = bem('editable-frequency-cap');

const abbreviatedUnitMap: Record<FrequencyCapUnitType, string> = {
  MINUTE: 'Min',
  HOUR: 'Hour',
  DAY: 'Day',
  LIFETIME: 'Lifetime',
  UNKNOWN: 'Unknown',
};

export const computeRenderValue = (value: FrequencyCap[]): string => {
  // depending on the value, returns a formatted string like "2/30 Mins" or "2/30 Mins, 6/10 Hours, 8/1 Day"
  return value
    .map((item: FrequencyCap) => {
      const unitLabel =
        item.durationUnit.key === UNRECOGNIZED
          ? item.durationUnit.displayName
          : abbreviatedUnitMap[item.durationUnit.key];
      return `${item.limit}/${item.duration} ${unitLabel}${unitLabel !== 'Lifetime' && item.duration > 1 ? 's' : ''}`;
    })
    .join(', ');
};

// Validation checks for null limit and count
const isFrequencyCapList = (
  freqCapList: ClearableFrequencyCap[],
  validationResult: FrequencyCapErrorObject
): freqCapList is FrequencyCap[] => {
  return !validationResult.hasErrors;
};

const getEmptyValidationResult = (): FrequencyCapErrorObject => ({
  hasErrors: false,
  limitErrorPositions: [],
  countErrorPositions: [],
  unitErrorPositions: [],
  errorMessage: '',
});

const validateFrequencyCaps = (frequencyCaps: ClearableFrequencyCap[]): FrequencyCapErrorObject => {
  let validationResult: FrequencyCapErrorObject = getEmptyValidationResult();
  let includesInvalidValues = false;

  frequencyCaps.forEach((frequencyCap: ClearableFrequencyCap, index: number) => {
    if (frequencyCap.limit === null) {
      validationResult.hasErrors = true;
      validationResult.limitErrorPositions = [...validationResult.limitErrorPositions, index];
    } else if (Number(frequencyCap.limit) < 1) {
      includesInvalidValues = true;
      validationResult.hasErrors = true;
      validationResult.limitErrorPositions = [...validationResult.limitErrorPositions, index];
    }

    if (frequencyCap.duration === null) {
      validationResult.hasErrors = true;
      validationResult.countErrorPositions = [...validationResult.countErrorPositions, index];
    } else if (Number(frequencyCap.duration) < 1) {
      includesInvalidValues = true;
      validationResult.hasErrors = true;
      validationResult.countErrorPositions = [...validationResult.countErrorPositions, index];
    }
  });

  if (validationResult.hasErrors) {
    validationResult.errorMessage = includesInvalidValues ? 'Enter valid numbers only' : 'Fill out empty fields';
  }
  return validationResult;
};

function EditableFrequencyCap({
  updateField,
  initialFrequencyCaps,
  variant,
  defaultFrequencyCapType,
  isDisabled,
  ...props
}: EditableFrequencyCapProps): JSX.Element {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [validationResult, setValidationResult] = useState<FrequencyCapErrorObject>(getEmptyValidationResult());
  const [frequencyCaps, { set, updateAt, removeAt, insertAt }] = useList<ClearableFrequencyCap>(
    initialFrequencyCaps ?? []
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const invalidCharacters = ['e', '-', '+', '.'];

  const memoizedRenderValue = useMemo(() => (initialFrequencyCaps ? computeRenderValue(initialFrequencyCaps) : ''), [
    initialFrequencyCaps,
  ]);

  if (initialFrequencyCaps === null) return <div data-testid="frequency-cap-empty-cell" />;

  const onSubmit = (frequencyCaps: FrequencyCap[]): void => {
    const submitVal: FrequencyCapInputV5[] = frequencyCaps
      .map((fC) => {
        return {
          type: fC.type,
          duration: fC.duration,
          limit: fC.limit,
          durationUnit: fC.durationUnit.key,
          enabled: fC.enabled ?? true,
        };
      })
      .filter((fC): fC is FrequencyCapInputV5 => fC !== null);

    updateField(submitVal);
  };

  const handleActivate = (): void => {
    if (isDisabled) {
      return;
    }
    if (variant !== 'saving') {
      setIsActive(true);
    }
  };

  const handleEscape = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Escape') {
      setIsActive(false);
    }
  };
  const handleCancel = (): void => {
    setValidationResult(getEmptyValidationResult());
    set(initialFrequencyCaps ?? []);
    setIsActive(false);
  };

  const handleSubmit = (): void => {
    const validationResult = validateFrequencyCaps(frequencyCaps);
    setValidationResult(validationResult);
    if (isFrequencyCapList(frequencyCaps, validationResult)) {
      onSubmit(frequencyCaps);
      setIsActive(false);
    }
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      handleSubmit();
    }
    if (e.key === 'Escape') {
      e.stopPropagation();
      handleCancel();
    }

    if (invalidCharacters.includes(e.key.toLowerCase())) {
      e.preventDefault();
    }
  };

  const handleClickAway = (): void => {
    // Clicking on a dropdown option counts as clicking away from the EditableCellModal
    if (!isDropdownOpen) {
      handleCancel();
    }
  };

  return (
    <EditableCell
      usePencil={true}
      onClick={handleActivate}
      onKeyDown={handleEscape}
      variant={isActive ? 'active' : variant}
      tabIndex={-1}
      {...props}
    >
      <div className={block()}>
        {isActive ? (
          <EditableCellModal
            onCancel={handleCancel}
            onClickAway={handleClickAway}
            onSubmit={handleSubmit}
            errorMessage={validationResult.errorMessage ?? undefined}
            hasValue={frequencyCaps.length > 0}
          >
            <FrequencyCaps
              frequencyCapList={frequencyCaps}
              listHelpers={{ insertAt, removeAt, replaceAt: updateAt }}
              frequencyCapType={defaultFrequencyCapType}
              validationResult={validationResult}
              onKeyDown={onKeyDownHandler}
              setIsDropdownOpen={setIsDropdownOpen}
              isClearable={defaultFrequencyCapType === 'AD'}
            />
          </EditableCellModal>
        ) : (
          <div
            data-testid="frequency-cap-display"
            className={element('display', frequencyCaps.length > 1 ? 'multiple-fc' : '')}
          >
            <span className={element('rendered-value')}>{memoizedRenderValue}</span>
          </div>
        )}
      </div>
    </EditableCell>
  );
}

export default memo(EditableFrequencyCap);
