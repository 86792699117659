import type { MultiEditAdRatingInput } from '../../../../../../../../../../apis/graphql';
import type { MultipleEditDrawerAdsFormValues } from '../../../../../../types';

type Result = {
  rating?: Array<MultiEditAdRatingInput>;
};

export const getPreparedInputForRating = ({
  entities,
  rating,
  selectedSections,
}: MultipleEditDrawerAdsFormValues): Result => {
  if (!entities.length || !selectedSections.includes('Rating') || rating === '') {
    return {};
  }

  return {
    rating: entities.map(({ id }) => ({
      id,
      value: rating,
    })),
  };
};
