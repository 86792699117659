import './BufferAndFrontload.scss';

import { useFormikContext } from 'formik';
import React from 'react';

import EditablePercentInput from '../../../../../../../../../common/EditableTableCells/EditablePercentInput';
import bem from '../../../../../../../../../utils/bem';
import type { MultipleEditDrawerLineItemsFormValues } from '../../../../../types';

const [block, element] = bem('buffer-multi-edit-section');

export default function BufferAndFrontload(): JSX.Element {
  const {
    values: { buffer },
    setFieldValue,
  } = useFormikContext<MultipleEditDrawerLineItemsFormValues>();

  const onChangeBuffer = (bufferValue: number): void => {
    setFieldValue('buffer.deliveryBufferPercent', bufferValue);
  };

  const onChangeFrontLoad = (frontLoadValue: number): void => {
    setFieldValue('buffer.frontLoadPercent', frontLoadValue);
  };

  return (
    <div className={block()}>
      <div className={element('header')}>Override existing buffer or frontload values on selected line items.</div>
      <div className={element('container')}>
        <div className={element('wrapper-percent-input')}>
          <EditablePercentInput
            labelText="Buffer"
            initialValue={buffer.deliveryBufferPercent}
            onSubmit={onChangeBuffer}
            variant={'base'}
            lowerLimit={-99}
            upperLimit={800}
            withBorder
          />
          <span>%</span>
        </div>

        <div className={element('wrapper-percent-input')}>
          <EditablePercentInput
            labelText="FrontLoad"
            initialValue={buffer.frontLoadPercent}
            onSubmit={onChangeFrontLoad}
            variant={'base'}
            lowerLimit={0}
            upperLimit={50}
            withBorder
          />
          <span>%</span>
        </div>
      </div>
    </div>
  );
}
