import './RemoveAssetButton.scss';

import { IconAlertErrorSolid } from '@hulu-react-style-components/icons';
import type { ModalProps } from 'antd';
import React from 'react';

import type { AssetType } from '../../../configs';
import type { AssetV5 } from '../../../models';
import bem from '../../../utils/bem';
import { enumToTitleCase } from '../../../utils/formatting';
import Button from '../../Button';
import { makeEmptyAsset } from '../adFormik';
import { deleteCreativeAssetByIds } from '../CreativeBuilders/hooks/deleteCreativeAssetByIDs';
import RemoveEntityButton from '../RemoveEntityButton';
import type { ButtonRendererFunction } from '../RemoveEntityButton/RemoveEntityButton';

type DeleteIds = { creativeId: string | undefined | null; assetId: string | undefined | null };
export interface RemoveAssetButtonProps {
  assetType: AssetType;
  assetName: string;
  formFieldName: string;
  deleteIds: DeleteIds;
  readonly?: boolean;
}

const [block] = bem('remove-asset-button');

function RemoveAssetButton({
  assetName,
  assetType,
  formFieldName,
  deleteIds,
  readonly,
}: RemoveAssetButtonProps): JSX.Element {
  const { creativeId, assetId } = deleteIds;

  const modalProps: ModalProps = {
    cancelText: 'NO, DO NOT REMOVE',
    okText: 'YES, REMOVE ASSET',
    title: `Remove ${enumToTitleCase(assetType)} asset`,
  };

  const ButtonRenderer: ButtonRendererFunction = (props) => (
    <Button className={block()} aria-label="remove asset" {...props} disabled={readonly}>
      <IconAlertErrorSolid />
    </Button>
  );

  const modalMessage = (
    <span>
      Are you sure you want to remove the file
      <span className="modal-asset-name"> {assetName}</span> from this ad?
    </span>
  );

  return (
    <RemoveEntityButton<Partial<AssetV5>>
      formFieldName={formFieldName}
      emptyValue={makeEmptyAsset()}
      modalProps={modalProps}
      ButtonRenderer={ButtonRenderer}
      modalMessage={modalMessage}
      deleteEntity={(): Promise<void> => deleteCreativeAssetByIds(creativeId || '', assetId || '')}
    />
  );
}

export default RemoveAssetButton;
