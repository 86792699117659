import './Details.scss';

import { useFormikContext } from 'formik';
import React from 'react';

import type { Get_Ad_RatingsQuery } from '../../../apis/graphql';
import type { AdType, Config } from '../../../configs';
import { convertAdRating, convertAdType } from '../../../configs';
import bem from '../../../utils/bem';
import type { DropdownOption } from '../../Dropdown';
import Dropdown from '../../Dropdown';
import { Switch, TextField } from '../../Form';
import { useFieldFast } from '../../Form/hooks';
import { DropdownOptions } from '../../hooks/config';
import { useFetchDropdownOptions } from '../../hooks/queries/useFetchDropdownOptions';
import { AdFormFields } from '../adFormik/fields';

export interface DetailsProps {
  permittedAdTypes: Config<AdType>[];
  isDeliveryStatusDisabled: boolean;
  readonly?: boolean;
}

const [block, element] = bem('details');

const Details = ({ permittedAdTypes, isDeliveryStatusDisabled, readonly }: DetailsProps): JSX.Element => {
  const [{ value: adType }, , { setValue: setAdType }] = useFieldFast<string>('type');
  const [{ value: adRating }, , { setValue: setAdRating }] = useFieldFast<string>('rating');
  const { options: adOptions } = useFetchDropdownOptions<Get_Ad_RatingsQuery>(DropdownOptions.AdRating);
  const [{ value: id }] = useFieldFast<string>('id');
  const { isSubmitting } = useFormikContext();
  const isExistingAd = !!id;
  const adTypeDisplayName = convertAdType(adType).displayName;
  const adRatingDisplayName = convertAdRating(adRating).displayName;

  const mapUserAdTypeDataToDropdownOptions = (permittedTypes: Config<AdType>[]): DropdownOption[] => {
    return permittedTypes.map(({ key, displayName }) => ({ label: `${displayName}`, value: key })) || [];
  };

  const onAdTypeChange = (newAdType: DropdownOption | null): void => {
    setAdType(newAdType?.value as string);
  };

  const onAdRatingChange = (newAdRating: DropdownOption | null): void => {
    setAdRating(newAdRating?.value as string);
  };

  return (
    <div className={block()}>
      <TextField
        id="name"
        name="name"
        formFieldName={AdFormFields.Name}
        className={element('ad-name-input')}
        label="Ad Name"
        secondaryLabel=" (Required)"
        disabled={readonly}
      />
      <div className={element('type-rating-wrapper')}>
        <Dropdown
          id="type"
          value={(adTypeDisplayName as unknown) as DropdownOption | null}
          emptyDisplayText={adTypeDisplayName}
          label="Ad Type (Required)"
          name="type"
          options={mapUserAdTypeDataToDropdownOptions(permittedAdTypes)}
          isSearchable={false}
          isDisabled={isSubmitting || isExistingAd || readonly}
          onChange={onAdTypeChange}
          dataTestId="ad-type"
        />
        <Dropdown
          id="rating"
          value={(adTypeDisplayName as unknown) as DropdownOption | null}
          emptyDisplayText={adRatingDisplayName}
          label="Ad Rating (Required)"
          name="rating"
          options={adOptions}
          isSearchable={false}
          onChange={onAdRatingChange}
          dataTestId="ad-rating"
          isDisabled={readonly}
        />
      </div>
      <Switch
        id="deliveryStatus"
        formFieldName="deliveryStatus"
        className={element('delivery-switch')}
        colorWhenOn={'black'}
        disabled={isDeliveryStatusDisabled || readonly}
        label="Delivery Status"
        info={(value): string =>
          value ? 'Your ad is live!' : 'Your ad cannot go live until it has been approved in ATC'
        }
      />
    </div>
  );
};

export default Details;
