import { IconMeterHigh, IconMeterLow, IconMeterMid } from '@hulu-react-style-components/icons';
import React from 'react';

export const cellContentsByPacingStatus = {
  UNDER_PACING: {
    title: 'Under Pacing',
    messageTitle: null,
    message: null,
    pacingMeter: <IconMeterLow aria-label="Underpacing Meter" />,
    classModifier: 'underpacing',
  },
  OVER_PACING: {
    title: 'Over Pacing',
    messageTitle: null,
    message: null,
    pacingMeter: <IconMeterHigh aria-label="Overpacing Meter" />,
    classModifier: 'overpacing',
  },
  ON_PACE: {
    title: 'On Pace',
    messageTitle: null,
    message: null,
    pacingMeter: <IconMeterMid aria-label="On Pace Meter" />,
    classModifier: 'onpace',
  },
  DELIVERED: {
    title: 'Delivered',
    messageTitle: null,
    message: null,
    pacingMeter: <IconMeterMid aria-label="On Pace Meter" />,
    classModifier: 'delivered',
  },
  UNDER_DELIVERED: {
    title: 'Under Delivered',
    messageTitle: null,
    message: null,
    pacingMeter: <IconMeterLow aria-label="On Pace Meter" />,
    classModifier: 'underdelivered',
  },
  CANCELLED: {
    title: 'Cancelled',
    messageTitle: null,
    message: null,
    pacingMeter: null,
    classModifier: 'cancelled',
  },
  DID_NOT_RUN: {
    title: 'Did Not Run',
    messageTitle: null,
    message: null,
    pacingMeter: null,
    classModifier: 'didnotrun',
  },
  NOT_EVEN: {
    title: 'Not Even',
    messageTitle: null,
    message: null,
    pacingMeter: null,
    classModifier: 'noteven',
  },
};
