import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

export type FrequencyCapUnitType = keyof typeof frequencyCapUnitTypeDisplayNames;

const frequencyCapUnitTypeDisplayNames = {
  MINUTE: 'Minutes',
  HOUR: 'Hours',
  DAY: 'Days',
  LIFETIME: 'Lifetime',
  UNKNOWN: 'Unknown',
};

export const frequencyCapUnitTypes = makeConfig<FrequencyCapUnitType>(frequencyCapUnitTypeDisplayNames);

export function convertFrequencyCapUnitType(apiValue: string): DisplayEnum<FrequencyCapUnitType> {
  return convertToDisplayEnum(apiValue, frequencyCapUnitTypes);
}
