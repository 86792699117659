import { useFieldFast } from '../../../../common/Form/hooks';
import type { TagInputProps } from '../../../../common/Form/TagInput';
import { validateAndPerformActionOnURL } from '../../../utils';
import { AdFormCreativeFields } from '../../adFormik/fields';

export const DEFAULT_TAG_URL = 'https://';

export default function useTagUpload(): TagInputProps {
  const [, { value: url }, { setValue: setUrl }] = useFieldFast<string>(AdFormCreativeFields.Url);
  const [, { value: isSubmitted }, { setValue: setIsSubmitted }] = useFieldFast<boolean>(
    AdFormCreativeFields.IsSubmitted
  );

  const submitTag = (): void => {
    validateAndPerformActionOnURL(url, () => {
      setIsSubmitted(true);
    });
  };

  const onTagChange = (url: string): void => {
    setUrl(url.trim());
  };

  const clearTag = (): void => {
    setUrl(DEFAULT_TAG_URL);
    setIsSubmitted(false);
  };

  return {
    url,
    isSubmitted,
    setUrl: onTagChange,
    submitTag,
    clearTag,
  };
}
