import type {
  AdTagInput,
  FrequencyCapInputV5,
  ScheduleInputV5,
  ScheduleV5,
  TargetingTermValueInput,
  ViewabilityVendor,
} from '../../../../apis/graphql';
import { UNRECOGNIZED } from '../../../../configs';
import type { AdTag } from '../../../../models';
import type { ClearableFrequencyCap } from '../../../FrequencyCaps/FrequencyCaps';
import { convertToDateRangeInput, convertToDayPartInput } from '../../../utils';
import type { AdFormValues } from '../../adFormik';
import { NIELSEN_TAG_HOST } from '../../utils';

export const getViewabilityVendorEnumsFromFormValue = (
  formViewabilityVendors: AdFormValues['viewabilityVendorList']
): Array<ViewabilityVendor> => {
  const newViewabilityVendors = formViewabilityVendors.map((vv) => {
    if (vv.key === UNRECOGNIZED) {
      throw new Error(`"${vv.displayName}" is not a recognized viewability vendor.`);
    } else {
      return vv.key;
    }
  });

  return newViewabilityVendors;
};

export const getFrequencyCapInput = (frequencyCaps: ClearableFrequencyCap[]): FrequencyCapInputV5[] => {
  const submitVal: FrequencyCapInputV5[] = frequencyCaps
    .map((fC) => {
      if (fC.durationUnit.key === UNRECOGNIZED) {
        throw new Error(`"${fC.durationUnit.displayName}" is not a recognized unit.`);
      } else {
        return {
          type: fC.type,
          limit: fC.limit,
          duration: fC.duration,
          durationUnit: fC.durationUnit.key,
          enabled: fC.enabled,
        };
      }
    })
    .filter((frequencyCap): frequencyCap is FrequencyCapInputV5 => frequencyCap !== null);

  return submitVal;
};

export const getAdTagInput = (adTagList: AdFormValues['adTagList']): AdTagInput[] => {
  const sortedAdTagListBySecureTag = [...adTagList].sort((adTag: AdTag): number =>
    adTag.url.includes(NIELSEN_TAG_HOST) ? -1 : 1
  );

  return sortedAdTagListBySecureTag.map((adTag) => convertAdTagToAdTagInput(adTag));
};

export const convertAdTagToAdTagInput = (adTag: AdTag): AdTagInput => {
  if (adTag.type.key === UNRECOGNIZED) {
    throw new Error(`"${adTag.type.displayName}" is not a recognized ad tag type.`);
  } else if (adTag.event.key === UNRECOGNIZED) {
    throw new Error(`"${adTag.event.displayName}" is not a recognized ad tag event.`);
  } else {
    return {
      id: adTag.id || null,
      type: adTag.type.key,
      event: adTag.event.key,
      url: adTag.url,
      isVerified: adTag.isVerified || false,
      isIas: adTag.isIas,
    };
  }
};

export const getTargetingTermValuesInput = (
  targetingTermValues: AdFormValues['targetingTermValues']
): TargetingTermValueInput[] => {
  return targetingTermValues.map((targetingTermValue) => ({
    dimensionName: targetingTermValue.dimension.id,
    valueName: targetingTermValue.value.id,
    include: targetingTermValue.include,
  }));
};

export const getSchedule = (schedule: ScheduleV5): ScheduleInputV5 => ({
  deliveryInViewerTime: schedule.deliveryInViewerTime,
  timezone: schedule.timezone,
  dateRangeList: convertToDateRangeInput(schedule.dateRangeList),
  dayPartList: convertToDayPartInput(schedule.dayPartList ?? []),
});
