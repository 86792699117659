import { useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Column } from 'react-table';

import type { ElementBuilder } from '../../../../../../../../../../../common/_ForCommonLibrary/util/bem';
import { adStatuses, lineItemStatuses } from '../../../../../../../../../../../configs';
import { EntityTypeArray } from '../../../../../../../../../TraffickingPage/hooks/makePageDrawerPlugin';
import type { MultipleEditDrawerFormValues } from '../../../../../../../types';
import type { SelectedRows, StatusTableData } from '../../types';
import { getColumns } from '../getColumns';
import { getEntities } from '../utils';

type UseStatusTable = {
  data: StatusTableData[];
  isAnyEntitySelected: boolean;
  columns: Column<StatusTableData>[];
  initialSelectedRowIds: SelectedRows;
  changeEntitiesStatus: () => void;
  listenSelectedRows: (selectedRows: SelectedRows) => void;
};

export const useStatusTable = (
  status: 'On' | 'Off',
  element: ElementBuilder,
  entitiesValues: Array<Pick<MultipleEditDrawerFormValues, 'entities'>['entities'][0]>,
  entitiesIdToIndexMap: Record<string, number>,
  entityType: EntityTypeArray
): UseStatusTable => {
  const isLineItem = entityType === EntityTypeArray.LINE_ITEM_ARRAY;
  const { setFieldValue } = useFormikContext<MultipleEditDrawerFormValues>();

  const columns = useMemo(() => getColumns(element('cell')), [element]);
  const data = useMemo(() => getEntities(entitiesValues), [entitiesValues]);

  const initialSelectedRowIds: SelectedRows = useMemo(
    () => entitiesValues.reduce((prevRowsIds, _, index) => ({ ...prevRowsIds, [index]: true }), {}),
    [entitiesValues]
  );

  const [selectedEntities, setSelectedEntities] = useState<SelectedRows>(initialSelectedRowIds);

  useEffect(() => {
    setSelectedEntities(initialSelectedRowIds);
  }, [initialSelectedRowIds]);

  const listenSelectedRows = useCallback((selectedRows: SelectedRows): void => {
    setSelectedEntities(selectedRows);
  }, []);

  const changeEntitiesStatus = (): void => {
    const entitiesIdsToChange = entitiesValues.filter((_, index) => selectedEntities[index]).map(({ id }) => id);

    const activeStatus = isLineItem ? lineItemStatuses.ON : adStatuses.ON;
    const notActiveStatus = isLineItem ? lineItemStatuses.PAUSED : adStatuses.OFF;
    const newStatus = status === 'On' ? activeStatus : notActiveStatus;

    entitiesIdsToChange.forEach((entityId) => {
      setFieldValue(`entities[${entitiesIdToIndexMap[entityId]}].status`, newStatus);
    });
  };

  const isAnyEntitySelected = Object.values(selectedEntities).some((rowSelectionState) => !!rowSelectionState);

  return {
    data,
    columns,
    initialSelectedRowIds,
    isAnyEntitySelected,
    listenSelectedRows,
    changeEntitiesStatus,
  };
};
