import './SequenceWeight.scss';

import { Button as AntButton, Modal } from 'antd';
import { User } from 'mission-control-common-components';
import type { RefObject } from 'react';
import React, { useEffect, useState } from 'react';

import type { PermissionsNames } from '../../constants';
import bem from '../../utils/bem';
import { AlertType } from '../Alert';
import Button from '../Button';
import DragDropLists, { DragDropItem } from '../DragDropLists';
import type { DragDropListProps } from '../DragDropLists/DragDropList/DragDropList';
import type { DragDropListCustomToggle } from '../DragDropLists/types';
import Loader from '../Loader';
import { openToastAlert } from '../ToastAlert/toastAlert';
import { default as AdRotationElement } from './AdRotation/AdRotation';
import AdRotationsHeader from './AdRotationsHeader/AdRotationsHeader';
import { modalBlock, modalBlockElement, rotationsBlock, rotationsElement } from './bemClasesConstants';
import GroupHeader from './GroupHeader/GroupHeader';
import useRotations from './hooks/useRotations';
import type { AdListData, AdRotation, Rotation } from './types';
import WeightSumInfo from './WeightSumInfo/WeightSumInfo';

const [, element] = bem('dnd-list');

export interface SequenceWeightProps {
  adListData: AdListData;
  onOk: () => void;
  onCancel: () => void;
  lineItemId: string;
  modalRef?: RefObject<HTMLDivElement> | null;
  loading?: boolean;
}

function SequenceWeight({
  adListData,
  onOk,
  onCancel,
  lineItemId,
  loading,
}: SequenceWeightProps): React.JSX.Element | null {
  const permissions = User.getPermissions<PermissionsNames>();

  const {
    rotationList,
    deleteRotation,
    sequenceChanged,
    adsWithoutWeightOrSequence,
    addRotation,
    changeAdWeight,
    forbiddenGroupNames,
    changeRotationName,
    newRotationGroup,
    saveRotations,
    loading: loadingRotations,
    rotationNameStartedEditing,
    isSaveChangesDisabled,
  } = useRotations(adListData, lineItemId);

  const [draggedElements, setDraggedElements] = useState<DragDropListCustomToggle[]>([]);

  useEffect(() => {
    const elements: Omit<DragDropListProps, 'onToggleHandler'>[] = rotationList.map((rotationGroup: Rotation) => {
      const newGroup: boolean = newRotationGroup(rotationGroup);
      const rotationWeightSum = rotationGroup.adsList.reduce((sum: number, adRotation: AdRotation) => {
        return sum + adRotation.weight;
      }, 0);

      return {
        id: rotationGroup.id,
        element: (
          <GroupHeader
            key={rotationGroup.id}
            name={rotationGroup.name}
            id={rotationGroup.id}
            newGroupHeader={newGroup}
            onSaveName={changeRotationName}
            forbiddenGroupNames={forbiddenGroupNames}
            onDelete={deleteRotation}
            onStartedEditing={rotationNameStartedEditing}
          />
        ),
        beforeListElement: rotationGroup.adsList.length > 0 ? <AdRotationsHeader /> : null,
        initialExpanded: rotationGroup.initialExpanded,
        endElement: !newGroup ? <WeightSumInfo sum={rotationWeightSum} /> : <></>,
        errorElement: rotationGroup.errorMessage ? (
          <span className={element('error')}>{rotationGroup.errorMessage}</span>
        ) : (
          <></>
        ),
        childElements: rotationGroup.adsList.map((adRotation: AdRotation) => {
          return {
            id: adRotation.adId,
            element: (
              <AdRotationElement
                rotationId={rotationGroup.id}
                adRotation={adRotation}
                onWeightChanged={changeAdWeight}
                key={adRotation.adId}
              />
            ),
          };
        }),
      };
    });

    setDraggedElements(elements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rotationList]);

  const onAddWithoutRotationGroupWeightChanged = (): void => {
    openToastAlert({
      alertType: AlertType.ERROR,
      message: 'Must "Add Rotation" to input ad weight.',
    });
  };

  let content = loading ? (
    <div className={rotationsElement('loader-container')}>
      <Loader />
    </div>
  ) : (
    <div>
      <div className={rotationsElement('header')}>
        <p className={rotationsElement('header-text', 'text')}>Ads will air in the order shown below.</p>
        <Button
          disabled={!permissions?.createRotation}
          key="back"
          variant="outlined"
          data-testid="add-rotation"
          className={rotationsElement('add-rotation-button')}
          onClick={addRotation}
        >
          Add Rotation
        </Button>
      </div>
      <div className={rotationsElement('container')}>
        <DragDropLists list={draggedElements} updateListHandler={sequenceChanged} />
        {adsWithoutWeightOrSequence.length ? <AdRotationsHeader /> : null}
        {adsWithoutWeightOrSequence.map((adRotation: AdRotation) => {
          const adRotationElement = (
            <AdRotationElement
              rotationId={''}
              adRotation={adRotation}
              onWeightChanged={onAddWithoutRotationGroupWeightChanged}
              key={adRotation.adId}
            />
          );

          return <DragDropItem element={adRotationElement} key={adRotation.adId} />;
        })}
      </div>
    </div>
  );

  return (
    <Modal
      onOk={onOk}
      onCancel={onCancel}
      visible={true}
      className={modalBlock()}
      width={1200}
      data-testid="sequence-weight-modal"
      footer={[
        <div className={modalBlockElement('modal-footer')} key="modal-footer">
          <div>
            <AntButton
              key="cancel-button"
              prefixCls={`ant-btn ${modalBlockElement('cancel-button')}`}
              onClick={onCancel}
            >
              <span data-testid="cancel-button">Cancel</span>
            </AntButton>
            <Button
              key="submit-save"
              aria-label="save changes"
              data-testid="save-rotations"
              className={modalBlockElement('submit-button')}
              tabIndex={0}
              disabled={loadingRotations || loading || isSaveChangesDisabled}
              onClick={saveRotations}
            >
              Save Changes
            </Button>
          </div>
        </div>,
      ]}
    >
      <div tabIndex={0} className={rotationsBlock()} data-testid="rotations-container">
        <div className={rotationsElement('title')}>Edit Ad Weight</div>
        {content}
      </div>
    </Modal>
  );
}

export default SequenceWeight;
