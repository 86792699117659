import type { TargetingTermValue } from '../../../../../../../../../../models';
import type { MultipleEditDrawerAdsFormValues } from '../../../../../../types';
import { getConflictIdsWithoutDuplicates } from '../../../utils';
import {
  getPreparedInputForAdStatuses,
  getPreparedInputForRating,
  getPreparedInputForScheduling,
  getPreparedInputForTags,
  getPreparedInputForTargeting,
} from '../index';

export type AdInputResult = {
  resultInput: {
    targeting?: ReturnType<typeof getPreparedInputForTargeting>['targeting'];
    schedule?: ReturnType<typeof getPreparedInputForScheduling>['schedule'];
    tags?: ReturnType<typeof getPreparedInputForTags>['tags'];
    rating?: ReturnType<typeof getPreparedInputForRating>['rating'];
    status?: ReturnType<typeof getPreparedInputForAdStatuses>['status'];
  };
  hasInputForUpdate?: boolean | null | number;
};

type Props = {
  values: MultipleEditDrawerAdsFormValues;
  schedulingConflictsIds?: string[] | null;
  existedTargeting?: Record<string, TargetingTermValue[]>;
  targetingConflicts?: Record<string, string[]>;
  tagsConflictsIds?: string[] | null;
  statusConflictIds?: Record<string, string[]> | null;
};

export const getInputForUpdateAds = ({
  values,
  schedulingConflictsIds,
  existedTargeting,
  targetingConflicts,
  tagsConflictsIds,
  statusConflictIds,
}: Props): AdInputResult => {
  const resultInput: AdInputResult['resultInput'] = {
    ...getPreparedInputForScheduling({ values, conflictIds: schedulingConflictsIds }),
    ...getPreparedInputForTargeting({ values, existedTargeting, targetingConflicts }),
    ...getPreparedInputForTags({ values, conflictIds: tagsConflictsIds }),
    ...getPreparedInputForRating(values),
    ...getPreparedInputForAdStatuses({
      values,
      statusErrors: getConflictIdsWithoutDuplicates(statusConflictIds),
    }),
  };

  const hasInputForUpdate =
    !!resultInput.schedule?.length ||
    !!resultInput.targeting?.length ||
    !!resultInput.tags?.length ||
    !!resultInput.rating?.length ||
    !!resultInput.status?.length;

  return {
    resultInput,
    hasInputForUpdate,
  };
};
