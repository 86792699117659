import type { EventType } from './adTagEvents';
import { adTagEvents } from './adTagEvents';
import type { AdTagType } from './adTagTypes';
import type { Config } from './types';

const permittedAdTagEvents: Record<AdTagType, Config<EventType>[]> = {
  BILLABLE: [adTagEvents.COMPLETE],
  NON_BILLABLE: [
    adTagEvents.START,
    adTagEvents.FIRSTQUARTILE,
    adTagEvents.MIDPOINT,
    adTagEvents.THIRDQUARTILE,
    adTagEvents.COMPLETE,
  ],
  CLICK: [adTagEvents.CLICK],
};

export function getPermittedAdTagEvents(tagTypeKey: AdTagType): Config<EventType>[] {
  return permittedAdTagEvents[tagTypeKey];
}
