import type { TargetingTermValueInput } from '../../../../../../../../../../apis/graphql';
import type { TargetingTermValue } from '../../../../../../../../../../models';
import type { MultipleEditDrawerAdsFormValues, MultipleEditDrawerLineItemsFormValues } from '../../../../../../types';
import { getConflictIdsWithoutDuplicates } from '../../../utils';
import { getMergedOldAndNewTargeting } from '../getInputForUpdate/utils';

export type Props = {
  values: MultipleEditDrawerLineItemsFormValues | MultipleEditDrawerAdsFormValues;
  existedTargeting?: Record<string, TargetingTermValue[]>;
  targetingConflicts?: Record<string, string[]>;
  isLineItem?: boolean;
};

export type GetPreparedInputForTargetingResult = {
  targeting?: {
    id: string;
    value: TargetingTermValueInput[];
  }[];
};

export const getPreparedInputForTargeting = ({
  values,
  targetingConflicts,
  existedTargeting,
}: Props): GetPreparedInputForTargetingResult => {
  if (!values.selectedSections.includes('Targeting')) {
    return {};
  }

  const conflictAdsIdsWithoutDuplicated = getConflictIdsWithoutDuplicates(targetingConflicts);
  const selectedEntities = values.entities.filter(({ id }) => !conflictAdsIdsWithoutDuplicated.includes(id));

  const inputTargeting = selectedEntities
    .map(({ id }) => {
      return {
        id,
        value: values.targetingTermValues.map(({ include, dimension, value }) => ({
          include,
          dimensionName: dimension.id,
          valueName: value.id,
        })),
      };
    })
    .filter((targeting) => targeting.value.length);

  const targeting = getMergedOldAndNewTargeting({
    inputTargeting,
    existedTargeting,
  });

  return targeting?.length
    ? {
        targeting,
      }
    : {};
};
