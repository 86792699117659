import type { ScheduleV5 } from '../../../apis/graphql';
import type { Nullable } from '../../../models';
import type { AdFormValues } from '../adFormik';
import { AdFormFields } from '../adFormik/fields';
import type { ValidationError } from '../types';
import * as validationErrors from './const';
import { validateCreativeByType } from './creative';
import { validateMetadata } from './metadata';
import { validateAdSchedule } from './schedule';
import { validateTagList } from './tags';

export const validateAdForm = (values: AdFormValues, lineItemSchedule: Nullable<ScheduleV5>): ValidationError[] => {
  const errors: ValidationError[] = [];

  if (!values.name) {
    errors.push({
      message: validationErrors.AD_FIELD_ERROR_MESSAGE,
      description: validationErrors.AD_NAME,
      fields: [AdFormFields.Name],
    });
  }

  if (values.creative.mode === 'build') {
    const error = validateCreativeByType(values.creative);

    if (error) errors.push(error);
  } else if (values.creative.mode === 'search') {
    errors.push({
      message: validationErrors.CREATIVE_ERROR_MESSAGE,
      description: validationErrors.SELECT_CREATIVE,
      fields: [],
    });
  }

  const scheduleError = validateAdSchedule(values.schedule, lineItemSchedule);

  if (scheduleError) errors.push(scheduleError);

  const tagError = validateTagList(values.adTagList);

  if (tagError) errors.push({ ...tagError, fields: [AdFormFields.AdTagList] });

  const metadataError = validateMetadata(values.viewabilityVendorList, values.iasCampaignLineItemId);

  if (metadataError) errors.push(metadataError);

  return errors;
};
