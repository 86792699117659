import { useFormikContext } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import type { DropResult } from 'react-beautiful-dnd';

import { AssetImporterField } from '../../../../Form';
import type { AdFormValues } from '../../../adFormik';
import { AdFormCreativeFields } from '../../../adFormik/fields';
import type { AdDimensionsType } from '../../CreativeBuilder/CreativeBuilder';
import { isTabFailed } from '../../utils';
import type { DraggableTabComponent } from '../AdSelectorSlateCreativeBuilder';
import {
  IMAGE_ASPECT_RATIO,
  LOGO_IMAGE_ASSET_LABEL,
  LOGO_LABEL,
  OPTION_LABEL,
  SEQUENCE_ORDER_ENABLED_TOOLTIP_MESSAGE,
} from '../constants';
import OptionAssetForm from '../OptionAssetForm';
import { isOptionAssetFilled } from './utils';

export type SelectTabOptions = {
  title: string;
  index: number;
};

export type UseAssetTabsProps = {
  isPreview: boolean;
  isReadonly: boolean;
  adTypeDimensions?: AdDimensionsType;
};

export type UseAssetTabs = {
  onDragEnd: (result: DropResult) => void;
  selectedTabIndex: number;
  handleTabChange: (tabOptions: SelectTabOptions) => void;
  tabs: DraggableTabComponent[];
};

export const useAssetTabs = ({ isPreview, isReadonly, adTypeDimensions }: UseAssetTabsProps): UseAssetTabs => {
  const {
    values: { creative },
    errors,
    setFieldValue,
  } = useFormikContext<AdFormValues>();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabChange = ({ index }: SelectTabOptions): void => {
    setSelectedTabIndex(index);
  };

  // create components to be used in tabs
  const optionListComponents = useMemo((): DraggableTabComponent[] => {
    return (
      creative.optionList?.map((option, index) => {
        return {
          id: `option-${index}`,
          label: OPTION_LABEL,
          isDragDisabled: creative.randomOrder,
          tooltipMessage: creative.randomOrder ? '' : SEQUENCE_ORDER_ENABLED_TOOLTIP_MESSAGE,
          isComplete: isOptionAssetFilled(option),
          isFailed: isTabFailed(errors, [
            `${AdFormCreativeFields.OptionList}[${index}].index`,
            `${AdFormCreativeFields.OptionList}[${index}].thumbnail`,
            `${AdFormCreativeFields.OptionList}[${index}].caption`,
            `${AdFormCreativeFields.OptionList}[${index}].adSelectorVideoCreativeId`,
          ]),
          component: (
            <OptionAssetForm
              option={option}
              isReadonly={isReadonly}
              isPreview={isPreview}
              adTypeDimensions={adTypeDimensions}
            />
          ),
        };
      }) ?? []
    );
  }, [adTypeDimensions, creative.optionList, creative.randomOrder, errors, isPreview, isReadonly]);

  // add logo tab component to option list components
  const tabs = useMemo((): DraggableTabComponent[] => {
    return [
      {
        id: 'logo',
        label: LOGO_LABEL,
        isDragDisabled: true,
        isComplete: !!creative.logo?.id,
        isFailed: isTabFailed(errors, [AdFormCreativeFields.Logo]),
        component: (
          <AssetImporterField
            adTypeDimensions={adTypeDimensions?.logo}
            assetType="DISPLAY"
            id="display.logo"
            formFieldName={AdFormCreativeFields.Logo}
            aspectRatio={IMAGE_ASPECT_RATIO}
            label={LOGO_IMAGE_ASSET_LABEL}
            isPreview={isPreview}
            readonly={isReadonly}
          />
        ),
      },
      ...optionListComponents,
    ];
  }, [adTypeDimensions?.logo, creative.logo?.id, isReadonly, errors, isPreview, optionListComponents]);

  // function to handle drag of tabs
  const onDragEnd = useCallback(
    (result: DropResult): void => {
      if (result.destination?.index && creative.optionList) {
        // options index in tabs is +1 compared to option list in creative
        const sourceIndex = result.source.index - 1;
        const destinationIndex = result.destination.index - 1;

        const newOptions = [...creative.optionList];
        const draggedOption = creative.optionList[sourceIndex];

        if (draggedOption) {
          newOptions.splice(sourceIndex, 1);
          newOptions.splice(destinationIndex, 0, draggedOption);

          // dragged tab is going to be shown
          setSelectedTabIndex(result.destination?.index);

          setFieldValue(
            AdFormCreativeFields.OptionList,
            newOptions.map((tab, index) => ({ ...tab, index: index + 1 }))
          );
        }
      }
    },
    [creative.optionList, setFieldValue]
  );

  return {
    onDragEnd,
    selectedTabIndex,
    handleTabChange,
    tabs,
  };
};
