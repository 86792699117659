import { gql } from '@apollo/client';

export const GET_USERS_BY_ROLE = gql`
  query GET_USERS_BY_ROLE(
    $roleName: String!
    $entityType: EntityFilterOptionV4!
    $entityId: String!
    $paginationOptions: PaginationOptions
    $filterOptions: UserFilterOptions
  ) {
    getUsersByRoleV4(
      roleName: $roleName
      entityType: $entityType
      entityId: $entityId
      paginationOptions: $paginationOptions
      filterOptions: $filterOptions
    ) {
      edges {
        node {
          id
          email
          firstName
          lastName
          contactCrmLink {
            crmId
            type
            url
          }
          title
          phone
          roles {
            entityId
            entityType
            role {
              name
            }
          }
          adAccountTypes
        }
      }
      total
    }
  }
`;
