import { useCallback, useEffect, useRef, useState } from 'react';

import type { GetChangelogFilterOptions, PaginationOptions } from '../../../apis/graphql';
import { defaultAmountOfLogsToShow, limitAudits } from '../constants';
import type { ChangeLogEntryNode } from '../hooks/useChangeLog';
import type { UseChangeLogList } from './useChangeLogList';

export type FilterVariables = {
  paginationOptions: PaginationOptions;
  filterOptions?: GetChangelogFilterOptions;
};

interface Props {
  filteredChangeLogList: UseChangeLogList[];
  total?: number;
  changeLogEntries: ChangeLogEntryNode[];
  refetch: (variables?: FilterVariables) => void;
}

interface UsePaginationResponse {
  resultFilteredLogs: UseChangeLogList[];
  hasMore: boolean;
  onNext: () => void;
}

export const usePagination = ({
  filteredChangeLogList,
  total,
  changeLogEntries,
  refetch,
}: Props): UsePaginationResponse => {
  const [resultFilteredLogs, setResultFilteredLogs] = useState(filteredChangeLogList);
  const [limit, setLimit] = useState(limitAudits);
  const [desiredItemsToShow, setDesiredItemsToShow] = useState(defaultAmountOfLogsToShow);
  const [hasMore, setHasMore] = useState(true);

  const isInitialMount = useRef(true);

  const getMoreLogs = useCallback(() => {
    /*We have to check filteredChangeLogList.length < desiredItemsToShow + 1
    since we need previous log to build proper change log for some fields. For instance - day part field.
     It is needed in the next files: app/src/common/ChangeLog/ChangeLogList/useChangeLogList.tsx
     You could find the next code where previous log needed:
         const previousDayPart = (changeLogEntries[index + 1].node.audits.object as AuditLineItemV5)?.schedule
            ?.dayPartList;
         previousObject = changeLogEntries[index + 1].node.audits.object;
     */
    if (filteredChangeLogList.length < desiredItemsToShow + 1 && total! > changeLogEntries.length) {
      refetch({
        paginationOptions: {
          limit: limit + limitAudits,
          offset: 0,
        },
      });
      setLimit((prev) => prev + limitAudits);

      return;
    }

    if (filteredChangeLogList.length >= desiredItemsToShow) {
      setResultFilteredLogs(filteredChangeLogList.slice(0, desiredItemsToShow));
      return;
    }

    if (changeLogEntries.length === total && filteredChangeLogList.length < desiredItemsToShow) {
      setResultFilteredLogs(filteredChangeLogList);
      setHasMore(false);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLogEntries.length, desiredItemsToShow]);

  useEffect(() => {
    getMoreLogs();
  }, [changeLogEntries.length, getMoreLogs]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    getMoreLogs();
  }, [desiredItemsToShow, getMoreLogs]);

  const onNext = (): void => {
    setDesiredItemsToShow((prevItems) => prevItems + defaultAmountOfLogsToShow);
  };

  return { resultFilteredLogs, hasMore, onNext };
};
