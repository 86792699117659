import { useQuery } from '@apollo/client';

import type { Query, TargetingValue } from '../../../../../../apis/graphql';
import { GET_TARGETING_VALUE_BY_DIMENSION_AND_VALUE_ID } from '../../../../../../pages/Ads/hooks/queries';

type Props = {
  dimensionGuid: string;
  valueId: string;
  skip?: boolean;
};

type UseGetTargetingValuesResult = {
  targetingValue: TargetingValue | null;
  loading: boolean;
};

export const useGetTargetingValues = ({ dimensionGuid, valueId, skip = false }: Props): UseGetTargetingValuesResult => {
  const { data, loading } = useQuery<Query>(GET_TARGETING_VALUE_BY_DIMENSION_AND_VALUE_ID, {
    variables: { dimensionGuid, valueId },
    skip,
  });
  // the backend returns an array with a single value.
  const value = data?.getTargetingValuesByDimensionAndValueID[0];
  return { targetingValue: value || null, loading };
};

export default useGetTargetingValues;
