import type { Dispatch, SetStateAction } from 'react';
import React from 'react';

import type { DayPartV5 } from '../../../../../../../../apis/graphql';
import type { CardsContentWithLink } from '../types';
import Ratings from './Rating';
import Schedule from './Schedule';
import Status from './Status';
import Tags from './Tags';
import Targeting from './Targeting';

type AdsCardsOptions = {
  stashedDayPartList: DayPartV5[];
  setStashedDayPartList: Dispatch<SetStateAction<DayPartV5[]>>;
};

export const adsCardsContent = ({
  stashedDayPartList,
  setStashedDayPartList,
}: AdsCardsOptions): Array<CardsContentWithLink> => [
  {
    sideNavLink: 'Status',
    cardContent: (isExpanded, entityType): JSX.Element => <Status isExpanded={isExpanded} entityType={entityType} />,
  },
  {
    sideNavLink: 'Targeting',
    cardContent: (_, entityType): JSX.Element => <Targeting entityType={entityType} />,
  },
  {
    sideNavLink: 'Scheduling',
    cardContent: (): JSX.Element => (
      <Schedule stashedDayPartList={stashedDayPartList} setStashedDayPartList={setStashedDayPartList} />
    ),
  },
  {
    sideNavLink: 'Tags',
    cardContent: (isExpanded): JSX.Element => <Tags isExpanded={isExpanded} />,
    title: 'Tags for Third Party Measurement',
  },
  {
    sideNavLink: 'Rating',
    cardContent: (): JSX.Element => <Ratings />,
  },
];
