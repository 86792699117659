import type { MultipleEditDrawerLineItemsFormValues } from '../../../../../../types';

type BufferConflict = {
  lineItemIdsWithFlexibleBuffer: string[];
  lineItemIdsWithFlexibleFrontload: string[];
};

export type BufferConflictIdsResult = {
  bufferConflictIds: string[];
  frontloadConflictIds: string[];
};

type Props = {
  values: MultipleEditDrawerLineItemsFormValues;
};

export const checkBufferConflicts = ({ values }: Props): BufferConflictIdsResult => {
  const defaultBufferConflicts = {
    bufferConflictIds: [],
    frontloadConflictIds: [],
  };

  const isBufferSectionSelected = values.selectedSections.includes('Buffer & Frontload');

  if (!isBufferSectionSelected) {
    return defaultBufferConflicts;
  }

  const lineItemIdsWithFlexibleBuffer = values.initialStatuses.reduce<BufferConflict>(
    (acc, entity) => {
      if (entity.buffer?.deliveryBufferPercent) {
        acc.lineItemIdsWithFlexibleBuffer.push(entity.id);
      }

      if (entity.buffer?.frontLoadPercent) {
        acc.lineItemIdsWithFlexibleFrontload.push(entity.id);
      }

      return acc;
    },
    { lineItemIdsWithFlexibleBuffer: [], lineItemIdsWithFlexibleFrontload: [] }
  );

  return values.entities
    .filter((entity) => entity.isSelected)
    .reduce<BufferConflictIdsResult>((acc, entity) => {
      const hasBufferConflict =
        values.buffer.deliveryBufferPercent &&
        !lineItemIdsWithFlexibleBuffer.lineItemIdsWithFlexibleBuffer.includes(entity.id);

      const hasFrontloadConflict =
        values.buffer.frontLoadPercent &&
        !lineItemIdsWithFlexibleBuffer.lineItemIdsWithFlexibleFrontload.includes(entity.id);

      if (hasBufferConflict) {
        acc.bufferConflictIds.push(entity.id);
      }

      if (hasFrontloadConflict) {
        acc.frontloadConflictIds.push(entity.id);
      }

      return acc;
    }, defaultBufferConflicts);
};
