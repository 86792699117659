import type { ChangeEvent } from 'react';
import React, { useCallback } from 'react';
import type { IdType, Row } from 'react-table';

import ControlledCheckbox from '../../../../../../../../common/ControlledCheckbox';
import type { AdProduct, AdType, DisplayEnum } from '../../../../../../../../configs';
import { getPermittedAdTypes } from '../../../../../../../../configs';
import type { DuplicateLineItem } from '../../../../hooks';
import { pickerElement } from '../../constants';

interface TableHeaderProps {
  toggleRowSelected: (rowId: IdType<string>, set?: boolean) => void;
  rows: Row<DuplicateLineItem>[];
  adType: DisplayEnum<AdType>;
}

export const CheckboxLineItemHeaderCell: React.FC<TableHeaderProps> = ({ adType, toggleRowSelected, rows }) => {
  const modifiedOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      //check each row if it is not disabled and ad type match
      rows.forEach(
        (row) =>
          !getPermittedAdTypes(row.original.adProduct?.key as AdProduct)?.every(
            (lineItemAdType) => lineItemAdType.key !== adType.key
          ) && toggleRowSelected(row.id, event.currentTarget.checked)
      );
    },
    [toggleRowSelected, rows, adType]
  );

  //Count number of selectable and selected rows in the current page
  //to determine the state of select all checkbox
  let selectableRowsInCurrentPage = 0;
  let selectedRowsInCurrentPage = 0;
  rows.forEach((row) => {
    row.isSelected && selectedRowsInCurrentPage++;
    const isAdTypeMatched = getPermittedAdTypes(row.original.adProduct?.key as AdProduct)?.every(
      (lineItemAdType) => lineItemAdType.key !== adType.key
    );
    !isAdTypeMatched && selectableRowsInCurrentPage++;
  });

  //If there are no selectable rows in the current page
  //select all checkbox will be disabled -> see page 2
  const disabled = selectableRowsInCurrentPage === 0;
  const checked = selectableRowsInCurrentPage === selectedRowsInCurrentPage && !disabled;
  const indeterminate = !checked && !disabled && selectedRowsInCurrentPage !== 0;

  return (
    <ControlledCheckbox
      onChange={modifiedOnChange}
      disabled={disabled}
      checked={checked}
      indeterminate={indeterminate}
      className={pickerElement('checkbox')}
      data-testid="line-item-header-controlled-checkbox"
    />
  );
};

export default CheckboxLineItemHeaderCell;
