import type { TraffickingFilters } from '../../../apis/graphql';
import { TraffickingTableName } from '../../../constants';
import { TRAFFICKING_SEARCH_PARAMS, TRAFFICKING_TABLE_NAMES } from '../../../constants';
import type { Nullable } from '../../../models';
import { ALL_TIME_DATE_KEY } from '../../../pages/Trafficking/TableFilterBar/DateFilter';
import { workWithSessionStorage } from '../../../utils/storage/sessionStorage';
import { StorageEntity } from '../../../utils/storage/storageEntity';
import {
  INITIAL_FILTERS,
  INITIAL_TABLE_FILTERS,
  TRAFFICKING_FILTERS_SEARCH_PARAMS,
  TRAFFICKING_INITIAL_TABLE_FILTERS,
} from './constants';
import type { GlobalFilters, TableFilters } from './types';

export const filtersData = new StorageEntity<Partial<TraffickingFilters>>('filters', workWithSessionStorage);

const mergeTableFilters = <T extends keyof typeof TraffickingTableName>(
  tableName: T,
  tableFilters?: TableFilters[T]
): TableFilters[T] => {
  if (!tableFilters) return INITIAL_TABLE_FILTERS[tableName];

  return {
    selectedRowIds: tableFilters.selectedRowIds?.filter(Boolean) || INITIAL_TABLE_FILTERS[tableName].selectedRowIds,
    searchTerm: tableFilters.searchTerm || INITIAL_TABLE_FILTERS[tableName].searchTerm,
    sortBy: tableFilters.sortBy || INITIAL_TABLE_FILTERS[tableName].sortBy,
  } as TableFilters[T];
};

const isTraffickingTableFilter = (filter: keyof TraffickingFilters): filter is keyof typeof TraffickingTableName =>
  TRAFFICKING_TABLE_NAMES.some((tableName) => tableName === filter);

const isTraffickingDefaultFilter = (filter: keyof TraffickingFilters): filter is keyof typeof TraffickingTableName =>
  TRAFFICKING_INITIAL_TABLE_FILTERS.some((defaultFilter) => defaultFilter === filter);

export const getDefaultFilters = (storageFilter = filtersData.getAllStorageData()): GlobalFilters => {
  if (!storageFilter) {
    return INITIAL_FILTERS as GlobalFilters;
  }

  return (Object.keys(INITIAL_FILTERS) as Array<keyof TraffickingFilters>).reduce((acc, filterName) => {
    if (isTraffickingTableFilter(filterName)) {
      return { ...acc, [filterName]: mergeTableFilters(filterName, storageFilter[filterName]) };
    }

    if (isTraffickingDefaultFilter(filterName)) {
      return { ...acc, [filterName]: storageFilter[filterName] || INITIAL_FILTERS[filterName] };
    }

    return { ...acc, [filterName]: storageFilter[filterName] };
  }, {} as GlobalFilters);
};

const isTraffickingTableFilterParam = (key: string): boolean =>
  ([
    TRAFFICKING_SEARCH_PARAMS.LINE_ITEMS_SELECTED_IDS,
    TRAFFICKING_SEARCH_PARAMS.ADS_ITEMS_SELECTED_IDS,
  ] as string[]).includes(key);

const getTraffickingTableFiltersFromSearchParams = (
  key: string,
  params: URLSearchParams
): TraffickingFilters[TraffickingTableName] => {
  const selectedRowIds = (params.get(key)?.split(',') || []).filter(Boolean);

  return {
    selectedRowIds,
    sortBy: null,
    searchTerm: '',
  };
};

const getTraffickingFiltersFromSearchParams = (params: URLSearchParams): Partial<TraffickingFilters> => {
  let filters: Partial<TraffickingFilters> = {};

  params.forEach((value, key) => {
    if (isTraffickingTableFilterParam(key)) {
      const tableName: TraffickingTableName =
        key === TRAFFICKING_SEARCH_PARAMS.LINE_ITEMS_SELECTED_IDS
          ? TraffickingTableName.lineItems
          : TraffickingTableName.ads;

      filters = { ...filters, [tableName]: getTraffickingTableFiltersFromSearchParams(key, params) };
    }

    if (key === TRAFFICKING_SEARCH_PARAMS.SELECTED_TAB) {
      const selectedTab = (TRAFFICKING_TABLE_NAMES as string[]).includes(value)
        ? value
        : INITIAL_FILTERS[TRAFFICKING_SEARCH_PARAMS.SELECTED_TAB];

      filters = { ...filters, [key]: selectedTab };
    }

    if (key === TRAFFICKING_SEARCH_PARAMS.DATE_FILTER && value === ALL_TIME_DATE_KEY) {
      filters = { ...filters, [key]: { label: ALL_TIME_DATE_KEY, value: null } };
    }
  });

  return filters;
};

export const hasFiltersParams = (params: URLSearchParams): boolean =>
  TRAFFICKING_FILTERS_SEARCH_PARAMS.some((param) => params.has(param));

export const getInitialFilters = (shareableId: Nullable<string>, params: URLSearchParams): GlobalFilters => {
  if (hasFiltersParams(params)) return getDefaultFilters(getTraffickingFiltersFromSearchParams(params));

  if (!shareableId) return getDefaultFilters();

  return INITIAL_FILTERS as GlobalFilters;
};
