import './TraffickingPageController.scss';

import React from 'react';

import Loader from '../../../common/Loader';
import { useFiltersContext } from '../../../contexts/FilterContext';
import TraffickingPage from '../TraffickingPage';

const TraffickingPageController = (): React.ReactElement => {
  const { loading } = useFiltersContext();

  if (loading) return <Loader className="page-loader" />;

  return <TraffickingPage />;
};

export default TraffickingPageController;
