export type Modifier = string | null;
export type Modifiers = string | null | Modifier[];

export type BlockBuilder = (modifiers?: Modifiers) => string;

export type ElementBuilder = (element: string, modifiers?: Modifiers) => string;

const baseBuilder = (base: string, modifiers?: Modifiers): string => {
  if (modifiers === null || modifiers === undefined || modifiers.length === 0) {
    return base;
  }

  if (!Array.isArray(modifiers)) {
    return `${base} ${base}--${modifiers}`;
  }

  const toModStr = (accum: string, modifier: Modifier): string => {
    if (modifier === null || modifier.length === 0) {
      return accum;
    }

    // prepend ' ' if accum is still empty
    return accum === '' ? ` ${base}--${modifier}` : `${accum} ${base}--${modifier}`;
  };

  return `${base}${modifiers.reduce(toModStr, '')}`;
};

/**
 * Using the provided block name, this util provides helpers to generate class
 * names following the BEM styling pattern.
 *
 * Refer here for information about BEM: http://getbem.com/introduction/
 * @param block Name of the component block
 * @returns [block, element] as invokable builder functions
 */
export function bem(block: string): [BlockBuilder, ElementBuilder] {
  const toElementName = (element: string): string => `${block}__${element}`;
  const bmBuilder = (modifiers?: Modifiers): string => baseBuilder(block, modifiers);
  const emBuilder = (element: string, modifiers?: Modifiers): string => baseBuilder(toElementName(element), modifiers);
  return [bmBuilder, emBuilder];
}

export const withOptionalClassName = (base: string, className?: string): string =>
  className !== undefined ? base.concat(' ', className) : base;
