import './NotFoundPage.scss';

import React from 'react';

const NotFoundPage = (): JSX.Element => (
  <div className="pagenotfound-container page">
    <h1 className="pagenotfound-text">Whoops! That page does not exist.</h1>
  </div>
);

export default NotFoundPage;
