import './ListToggle.scss';

import { IconChevronUp } from '@hulu-react-style-components/icons';
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import bem from '../../utils/bem';

type Props = {
  header: JSX.Element;
  initialExpanded?: boolean;
  ignoreInitialExpanded?: boolean;
  toggleHandler?: (expanded: boolean) => void;
};

const [block, element] = bem('list-toggle');

const ListToggle: React.FC<Props> = ({
  initialExpanded = true,
  ignoreInitialExpanded = false,
  toggleHandler,
  header,
  children,
}) => {
  const [toggled, setToggled] = useState(true);
  const toggleExpanded = (): void => {
    if (ignoreInitialExpanded) {
      setToggled(!toggled);
    } else if (toggleHandler) {
      toggleHandler(!initialExpanded);
    }
  };

  const expanded = ignoreInitialExpanded ? toggled : initialExpanded;

  return (
    <div className={block()} data-testid="list-toggle">
      <div className={element('header')}>
        <IconChevronUp
          data-testid="toggle-icon"
          className={element('icon', expanded ? 'expanded' : '')}
          onClick={toggleExpanded}
        />
        <div className={element('header-element')}>{header}</div>
      </div>
      <CSSTransition classNames={element('toggle')} in={expanded} timeout={150} unmountOnExit appear>
        <div data-testid="collapsed-block" className={element('children')}>
          {children}
        </div>
      </CSSTransition>
    </div>
  );
};

export default ListToggle;
