import './style.scss';

import { Link } from '@hulu/react-router-dom';
import { SearchInput } from '@hulu/react-style-components';
import type { Location } from 'history';
import type { RefObject } from 'react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import type { Column, Hooks, IdType, Row, TableState } from 'react-table';
import { useFilters, useRowSelect, useSortBy, useTable } from 'react-table';
import { useScroll } from 'react-use';

import bem from '../../utils/bem';
import { genericMemo } from '../../utils/genericMemo';
import { FOCUSABLE_ELEMENT_SELECTOR } from '../constants';
import ControlledCheckbox from '../ControlledCheckbox';
import type { CheckboxProps } from '../ControlledCheckbox/ControlledCheckbox';
import Loader from '../Loader';
import TableStatus from '../TableStatus';
import Tooltip from '../Tooltip';
import { default as Body } from './TableBody';
import { default as Header } from './TableHeader';

interface CommonTableLinkProps {
  /** A boolean representing if a link prop is disabled. */
  disabled: boolean;
  /** The unique id of the link prop. */
  uniqueId: string;
  /** An icon element to be rendered in react. */
  icon: JSX.Element;
  /** A string to show text to the right of the icon. */
  linkText: string;
  /** A message to show when the custom action is disabled and a user hovers over the element. */
  toolTipMessage: string;
  /** The history object to route the user to the page they are wanting to go to. */
  location?: Location;
  /** A function to be triggered to show an alert when the user clicks on an unsupported feature. */
  unSupportedAlert: () => void;
}
interface CommonTableSearchProps {
  /** The value of the search input in the action bar. */
  value: string;
  /** A function to be fired when the user clicks the enter key on their keybaord. */
  onSubmit: (term: string) => void;
  /** A function to clear out the search value. */
  onClear: () => void;
  /** A placeholder to show when there is no text in the input field. */
  placeholder: string;
}
export interface CommonTableProps<RawObject extends {} = {}> {
  /** A message to show when the table is empty. */
  emptyMessage?: React.ReactNode;
  /** A message to show when there is an error. */
  errorMessage?: string;
  /** A boolean that represents when we are loading something directly to the table. */
  loading: boolean;
  /** A boolean that represents that there is more data to be gathered. */
  hasMore: boolean;
  /** A function to grab more data when a user scrolls using the InfiniteScroll library. */
  onNext: () => void;
  /** A function to trigger an onNext call by keyboard button press. */
  keyboardOnNext: (keyboardRowPosition: number) => void;
  /** A default limit to show for rows. */
  defaultLimit: number;
  /** A boolean that represents this table component using the InfiniteScroll library. */
  hasInfiniteScroll: boolean;
  /** An array of raw objects that can be mapped to react-table Column. */
  headers: Column<RawObject>[];
  /** An array of raw objects that can be used as data for react-table. */
  body: RawObject[];
  /** A boolean that triggers react-table to show checkbox. */
  addCheckbox: boolean;
  /** A boolean that keeps the checkboxes sticky to the left. */
  stickyCheckbox: boolean;
  /** A boolean that will hide or show the action bar on top of the table. */
  hasActionBar: boolean;
  /** An array of objects to show custom buttons to the left of the action bar. */
  leftActions: CommonTableLinkProps[];
  /** A boolean to show the search field in the action bar on the right side. */
  isFilterable: boolean;
  /** An object to pass props to the search input for filtering purposes. */
  filterProps: CommonTableSearchProps;
  /** A boolean to let the table know that it is sortable. */
  isSortable: boolean;
  /** Table initial state */
  initialState?: Partial<TableState<RawObject>>;
  /** A listener to fetch selected ids */
  listenSelectedRows?: (selectedRows: Record<IdType<RawObject>, boolean>) => void;
}

type RowProps = {
  row: {
    getToggleRowSelectedProps(): CheckboxProps;
    toggleRowSelected(): (rowId: IdType<{}>, set?: boolean) => void;
    index: number;
  };
};

const [block, element] = bem('common-table');

const getMaxScrollXPosition = (ref: RefObject<HTMLDivElement>): number => {
  // using '9999' as the theoretical max width of a div element
  const elementWidth = ref?.current?.scrollWidth || 9999;

  // using '9998' as the theoretical max width of the scrolling pane
  const scrollWidth = ref?.current?.offsetWidth || 9998;

  return elementWidth - scrollWidth;
};

type ScrollingBlockMod = 'left-shadow' | 'right-shadow' | 'sticky-header' | null;

// calcScrollModifiers returns an array of block modifiers for our BEM block() function.
const calcScrollModifiers = (maxScrollXPos: number, scrollXPos: number, scrollYPos: number): ScrollingBlockMod[] => {
  // If the scroll position is greater than 0 (meaning we've scrolled right some), then we
  // include a "left-shadow" modifier.
  // If the scroll position is less than the maximum scroll position (meaning we have not
  // scrolled all the way right), then we include a "right-shadow" modifier.
  return [
    scrollXPos > 0 ? 'left-shadow' : null,
    scrollXPos < maxScrollXPos ? 'right-shadow' : null,
    scrollYPos > 0 ? 'sticky-header' : null,
  ];
};

const focusCell = (colIndex: number, rowIndex: number): void => {
  let focusableElement;
  const selectedCell = document.querySelector(`[data-col="${colIndex}"][data-row="${rowIndex}"]`) as HTMLElement;
  // there's sometimes unexpected behavior when we don't move focus to the parent cell first before the focusableElement
  selectedCell.focus();

  // select inner focusable element, this allows for keyboard interactions directed with the relevant element
  if (selectedCell) focusableElement = selectedCell.querySelector(FOCUSABLE_ELEMENT_SELECTOR) as HTMLElement;
  if (focusableElement) focusableElement.focus();
};

const onKeyDownHandler = (
  e: React.KeyboardEvent<HTMLTableElement>,
  selectedColIndex: number,
  setSelectedColIndex: (idx: number) => void,
  maxColIndex: number,
  selectedRowIndex: number,
  setSelectedRowIndex: (idx: number) => void,
  maxRowIndex: number,
  defaultLimit: number,
  keyboardOnNext: (keyboardRowPosition: number) => void
): void => {
  const allowedKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
  const { key } = e;

  let newColIndex = selectedColIndex;
  let newRowIndex = selectedRowIndex;

  if (key === 'ArrowLeft') {
    newColIndex = selectedColIndex - 1;
  }
  if (key === 'ArrowRight') {
    newColIndex = selectedColIndex + 1;
  }
  if (key === 'ArrowUp') {
    newRowIndex = selectedRowIndex - 1;
  }
  if (key === 'ArrowDown') {
    newRowIndex = selectedRowIndex + 1;
  }

  if (allowedKeys.includes(key)) {
    e.preventDefault();
    newColIndex = Math.min(Math.max(0, newColIndex), maxColIndex);
    newRowIndex = Math.min(Math.max(0, newRowIndex), maxRowIndex);
    setSelectedColIndex(newColIndex);
    setSelectedRowIndex(newRowIndex);
    focusCell(newColIndex, newRowIndex);

    if (selectedRowIndex !== 0 && (selectedRowIndex + 1) % defaultLimit === 0) {
      keyboardOnNext(selectedRowIndex);
    }
  }
};

const onCheckboxKeyDownHandler = (callback: () => void) => (e: React.KeyboardEvent): void => {
  if (e.key === 'Enter' || e.key === ' ') {
    e.preventDefault();
    callback();
  }
};

const handleBulkRowToggle = <RawObject extends {} = {}>(
  allRows: Row<RawObject>[],
  onToggleHandler: (value?: boolean | undefined) => void
): void => {
  allRows.some((row: Row<RawObject>) => row.isSelected) ? onToggleHandler(false) : onToggleHandler();
};

const buildLeftActions = (action: CommonTableLinkProps, key: number): JSX.Element => {
  const linkText = <span className={element('link-text')}>{action.linkText}</span>;
  let link = (
    <span className={element('link-container')}>
      <Link to={action.location ? action.location.pathname : ''} state={action.location ? action.location.state : {}}>
        {action.icon}
        {linkText}
      </Link>
    </span>
  );

  if (action.disabled) {
    link = (
      <span className={element('link-container-disabled')}>
        {action.icon}
        {linkText}
      </span>
    );
  }

  if (!action.location || !Object.keys(action.location).length) {
    link = (
      <span className={element('link-container-unsupported')} onClick={action.unSupportedAlert}>
        {action.icon}
        {linkText}
      </span>
    );
  }

  return (
    <Tooltip
      key={`common-table-action-link-${action.uniqueId}-key-${key}`}
      id={`common-table-action-link-${action.uniqueId}`}
      className={element('tooltip')}
      message={action.toolTipMessage}
      disable={!action.disabled}
    >
      {link}
    </Tooltip>
  );
};

const buildActionBar = (
  leftActions: CommonTableLinkProps[],
  isFilterable: boolean,
  filterProps: CommonTableSearchProps
): JSX.Element => {
  const rightActions = (
    <div className={element('right-actions')}>
      <SearchInput {...filterProps} />
    </div>
  );

  return (
    <div className={element('action-bar')}>
      <div className={element('left-actions')}>{leftActions?.map((item, key) => buildLeftActions(item, key))}</div>
      {isFilterable ? rightActions : null}
    </div>
  );
};

const CommonTable = <RawObject extends {} = {}>({
  emptyMessage,
  errorMessage,
  loading,
  hasMore,
  onNext,
  keyboardOnNext,
  defaultLimit,
  hasInfiniteScroll,
  headers,
  body,
  addCheckbox,
  hasActionBar,
  leftActions,
  isFilterable,
  filterProps,
  isSortable,
  stickyCheckbox,
  initialState,
  listenSelectedRows,
}: CommonTableProps<RawObject>): JSX.Element => {
  const [selectedColIndex, setSelectedColIndex] = useState(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const extraHooks = [
    (hooks: Hooks<RawObject>): void => {
      hooks.visibleColumns.push((columns) => {
        if (addCheckbox) {
          return [
            {
              id: 'common-checkbox',
              groupByBoundary: true,
              Header: ({ getToggleAllRowsSelectedProps, toggleAllRowsSelected, rows }): JSX.Element => (
                <ControlledCheckbox
                  checked={false}
                  indeterminate={false}
                  {...getToggleAllRowsSelectedProps()}
                  disabled={rows.length === 0}
                  onChange={(): void => handleBulkRowToggle(rows, toggleAllRowsSelected)}
                  onKeyDown={onCheckboxKeyDownHandler(() => handleBulkRowToggle(rows, toggleAllRowsSelected))}
                />
              ),
              Cell: ({ row }: RowProps): JSX.Element => (
                <>
                  <ControlledCheckbox
                    {...row.getToggleRowSelectedProps()}
                    labelContent={`${row.index + 1}`}
                    tabIndex={-1}
                    onKeyDown={onCheckboxKeyDownHandler(row.toggleRowSelected)}
                  />
                </>
              ),
            },
            ...columns,
          ];
        }

        return [...columns];
      });
    },
  ];

  if (isFilterable) {
    extraHooks.push(useFilters);
  }

  if (isSortable) {
    extraHooks.push(useSortBy);
  }

  if (addCheckbox) {
    extraHooks.push(useRowSelect);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds },
  } = useTable<RawObject>(
    {
      columns: headers,
      data: body,
      initialState,
    },
    ...extraHooks
  );

  useEffect(() => {
    if (listenSelectedRows) {
      listenSelectedRows(selectedRowIds);
    }
  }, [listenSelectedRows, selectedRowIds]);

  const ref = useRef<HTMLDivElement>(null);
  const maxScrollXPosition = getMaxScrollXPosition(ref);
  const { x: scrollXPosition, y: scrollYPosition } = useScroll(ref);
  const scrollModifiers = useMemo(() => calcScrollModifiers(maxScrollXPosition, scrollXPosition, scrollYPosition), [
    maxScrollXPosition,
    scrollXPosition,
    scrollYPosition,
  ]);
  const hasMoreThanMinimumBatchRequirement = rows.length !== 0 && rows.length >= 20;
  const tableElement = (
    <div className={element('table-wrapper')}>
      <table
        className={element('table')}
        onKeyDown={(e): void =>
          onKeyDownHandler(
            e,
            selectedColIndex,
            setSelectedColIndex,
            rows?.[0]?.cells?.length - 1,
            selectedRowIndex,
            setSelectedRowIndex,
            rows.length - 1,
            defaultLimit,
            keyboardOnNext
          )
        }
        {...getTableProps()}
      >
        <Header headers={headerGroups} checkboxSticky={stickyCheckbox} />
        <Body
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          rows={rows}
          selectedColIndex={selectedColIndex}
          selectedRowIndex={selectedRowIndex}
          checkboxSticky={stickyCheckbox}
        />
      </table>
    </div>
  );

  return (
    <div ref={ref} id={'common-table-container'} className={hasInfiniteScroll ? block(scrollModifiers) : ''}>
      {hasActionBar ? buildActionBar(leftActions, isFilterable, filterProps) : null}
      {hasInfiniteScroll ? (
        <InfiniteScroll
          scrollableTarget={'common-table-scroll-container'}
          dataLength={rows.length}
          next={onNext}
          hasMore={hasMore}
          scrollThreshold={1}
          style={{ overflow: 'initial' }}
          loader={
            <div className={element('loader')}>
              {!loading && !errorMessage && hasMoreThanMinimumBatchRequirement && <Loader />}
            </div>
          }
        >
          {tableElement}
        </InfiniteScroll>
      ) : (
        tableElement
      )}
      <TableStatus
        emptyMessage={emptyMessage}
        errorMessage={errorMessage}
        empty={rows.length === 0}
        loading={loading}
      />
    </div>
  );
};

CommonTable.defaultProps = {
  loading: false,
  hasMore: false,
  onNext: (): void => {},
  keyboardOnNext: (): void => {},
  defaultLimit: 0,
  hasInfiniteScroll: false,
  headers: [],
  body: [],
  leftActions: [],
  isFilterable: false,
  addCheckbox: false,
  isSortable: false,
  hasActionBar: false,
  filterProps: {},
  stickyCheckbox: false,
};

export default genericMemo(CommonTable);
