import { gql } from '@apollo/client';

import { TARGETING_RULE_DEFINITION, TARGETING_TERM_VALUES } from '../../../../../apis/graphql';
import { LINE_ITEM_TRAFFICKING_COLUMNS } from '../fragments';

export const GET_LINE_ITEMS_WITH_TARGETINGS = gql`
  query getLineItemsWithTargetings($ids: [ID!]!) {
    lineItems: lineItemsV5(filterOptions: { idList: $ids }) {
      edges {
        node {
          ...lineItemTraffickingColumns
          targetingTermValues {
            ...targetingTermValues
          }
          targetingRule {
            ...targetingRuleDefinition
          }
        }
      }
      total
    }
  }
  ${LINE_ITEM_TRAFFICKING_COLUMNS}
  ${TARGETING_TERM_VALUES}
  ${TARGETING_RULE_DEFINITION}
`;
