import { capitalize as _capitalize, values as _values } from 'lodash';

import { openToastAlert } from '../common/ToastAlert/toastAlert';
import shallowEqual from './shallowEqual';

/**
 * parse and alert all errors from stringified handleGraphQLErrors util used in graphqlAPI for all queries and mutations
 */
export const alertGQLErrors = (error: string): void => {
  _values(JSON.parse(error)).forEach((errorDetails) => {
    openToastAlert({
      alertType: 'error',
      message: errorDetails[1],
      description: errorDetails[0],
    });
  });
};

export const fromEmailToName = (email: string): string =>
  email
    .split('@')[0]
    .split('.')
    .map((xname) => _capitalize(xname))
    .join(' ');

export const cellPropsComparison = <Props extends { row: { original: { id: string } }; value: unknown }>(
  prev: Props,
  next: Props
): boolean => prev.row.original.id === next.row.original.id && shallowEqual(prev.value, next.value);

export const normalizedSearchString = (name: string): string =>
  name.replace(/ +/g, ' ').split(' ').join('.').toLowerCase();
