import { useQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import { debounce as _debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import type { InputActionMeta } from 'react-select';

import type {
  Get_Ads_With_Creative_By_Parent_Line_Oms_LinkQuery,
  Get_Ads_With_Creative_By_Parent_Line_Oms_LinkQueryVariables,
} from '../../../../../../apis/graphql';
import { AdTypeV5 } from '../../../../../../apis/graphql';
import { SelectInputAction } from '../../../../../constants/select';
import type { DropdownOption } from '../../../../../Dropdown';
import { DEFAULT_DEBOUNCE_TIMEOUT } from '../../../../../hooks/constants';
import type { AdFormValues } from '../../../../adFormik';
import { GET_ADS_WITH_CREATIVE_BY_PARENT_LINE_OMS_LINK } from '../../queries';

export type UseFetchAdSelectorVideoCreativeOptions = {
  adSelectorVideoCreativeOptions: DropdownOption[];
  loading: boolean;
  searchTerm: string;
  handleSearch: (value: string, meta: InputActionMeta) => void;
};

export const useFetchAdSelectorVideoCreativeOptions = (): UseFetchAdSelectorVideoCreativeOptions => {
  const {
    values: { lineItem },
  } = useFormikContext<AdFormValues>();
  const [queryData, setQueryData] = useState<Get_Ads_With_Creative_By_Parent_Line_Oms_LinkQuery['adsV5']['edges']>();
  const [searching, setSearching] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');

  // fetch values for AdSelectorVideoCreative dropdown
  const { data, refetch, loading } = useQuery<
    Get_Ads_With_Creative_By_Parent_Line_Oms_LinkQuery,
    Get_Ads_With_Creative_By_Parent_Line_Oms_LinkQueryVariables
  >(GET_ADS_WITH_CREATIVE_BY_PARENT_LINE_OMS_LINK, {
    variables: {
      lineItemParentLineOmsLinkList: lineItem?.parentLineOmsLink ? [lineItem.parentLineOmsLink.id] : [],
      adTypeList: [AdTypeV5.AdSelectorVideo],
    },
  });

  const debouncedSearch = useMemo(
    () =>
      _debounce(async (searchTerm: string): Promise<void> => {
        try {
          setSearching(true);

          const { data } = await refetch({ creativeName: searchTerm });
          setQueryData(data.adsV5.edges);

          setSearching(false);
        } catch (e) {
          console.error(e);
        }
      }, DEFAULT_DEBOUNCE_TIMEOUT),
    [refetch]
  );

  const handleSearch = useCallback(
    (searchTerm: string, { action }: InputActionMeta): void => {
      if (action === SelectInputAction.InputChange) {
        setSearchTerm(searchTerm);

        debouncedSearch(searchTerm);
      }
    },
    [debouncedSearch]
  );

  const adSelectorVideoCreativeOptions = useMemo((): DropdownOption[] => {
    const ads = queryData ?? data?.adsV5.edges ?? [];

    return (
      ads.reduce((acc, { node }) => {
        const { creative } = node;

        if (creative && creative.id && creative.name) {
          acc.push({
            id: creative.id,
            label: creative.name,
            value: creative.id,
          });
        }

        return acc;
      }, new Array<DropdownOption>()) ?? []
    );
  }, [data?.adsV5.edges, queryData]);

  return {
    adSelectorVideoCreativeOptions,
    searchTerm,
    handleSearch,
    loading: loading || searching,
  };
};
