import envConfig from '@hulu/env-config';
import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { User } from 'mission-control-common-components';

import { sendTokenToWorker } from '../../configs/serviceWorkerInit';

const campaignGraphqlManager = axios.create({
  baseURL: envConfig.REACT_APP_CAMPAIGN_API,
});

export const authorizationInterceptors = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  if (envConfig.REACT_APP_AUTH_FEATURE_ENABLED !== 'true') return config;

  await User.checkIdToken()
    .then(() => {
      sendTokenToWorker(navigator);
    })
    .catch((error) => {
      console.error(error);
    });

  const { idToken } = User.getUserInfo();

  return { ...config, headers: { ...config.headers, authorization: `Bearer ${idToken}` } };
};

campaignGraphqlManager.interceptors.request.use(authorizationInterceptors);

export { campaignGraphqlManager };
