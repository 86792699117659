import type { CampaignTagV5, Get_CampaignsQuery, Maybe, OrderV5 } from '../../../../apis/graphql';
import { convertOrderType } from '../../../../configs';
import type { AdAccount, Campaign, CrmLink, Nullable, OrderOmsLink } from '../../../../models';

export type CampaignNode = NonNullable<Get_CampaignsQuery['campaigns']['edges'][number]>['node'];

export type CampaignModel = { __typename?: 'CampaignV5' } & Pick<
  Campaign,
  | 'advertiser'
  | 'agency'
  | 'industryName'
  | 'budget'
  | 'createdAt'
  | 'endDate'
  | 'hasZeroDeliveryRisk'
  | 'hasMisalignedSchedule'
  | 'id'
  | 'traffickerEmail'
  | 'name'
  | 'salespersonEmail'
  | 'orderOmsLink'
  | 'orderType'
  | 'startDate'
  | 'updatedAt'
  | 'trackingId'
  | 'lineItemList'
> & {
    adAccount?: Nullable<
      { __typename?: 'AdAccountV5' } & Pick<AdAccount, 'name' | 'id' | 'timezone' | 'organizationId'>
    >;
    orderOmsLink: Nullable<{ __typename?: 'OmsLinkV5' } & Pick<OrderOmsLink, 'id' | 'type'>>;
    crmLink: Maybe<{ __typename?: 'CrmLink' } & Pick<CrmLink, 'id' | 'name'>>;
    order: Maybe<{ __typename?: 'OrderV5' } & Pick<OrderV5, 'salespersonEmail'>>;
    campaignTagList: Maybe<
      Array<{ __typename?: 'CampaignTagV5' } & Pick<CampaignTagV5, 'id' | 'type' | 'url' | 'isVerified'>>
    >;
  };

export const campaignNodeToModel = (node: CampaignNode): CampaignModel => {
  const { adAccount, order, orderType, hasZeroDeliveryRisk, hasMisalignedSchedule } = node;

  return {
    ...node,
    salespersonEmail: order?.salespersonEmail ? order?.salespersonEmail : null,
    orderType: orderType ? convertOrderType(orderType) : null,
    hasZeroDeliveryRisk: !!hasZeroDeliveryRisk,
    hasMisalignedSchedule: !!hasMisalignedSchedule,
    adAccount: adAccount
      ? {
          ...adAccount,
          organizationId: adAccount?.organizationId || '',
        }
      : undefined,
  };
};
