import { useQuery } from '@apollo/client';
import { useSearchParams } from '@hulu/react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';

import type {
  AdSortOptionsV5Type,
  CampaignSortOptionsV5Type,
  Get_Trafficking_FiltersQuery,
  Get_Trafficking_FiltersQueryVariables,
  LineItemSortOptionsV5Type,
  Maybe,
  TraffickingFilters,
} from '../../../apis/graphql';
import { removeTypeNamesFromObject } from '../../../apis/graphql';
import { openToastAlert } from '../../../common/ToastAlert/toastAlert';
import { TraffickingTableName } from '../../../constants';
import { SearchParams } from '../../../constants';
import type { Nullable } from '../../../models';
import { INITIAL_FILTERS, INITIAL_TABLE_FILTERS, TRAFFICKING_FILTERS_SEARCH_PARAMS } from './constants';
import { GET_TRAFFICKING_FILTERS } from './queries';
import type { GlobalFilters } from './types';
import { filtersData, getDefaultFilters, getInitialFilters, hasFiltersParams } from './utils';

export type UseFilters = {
  filters: GlobalFilters;
  applyFilter: <T extends keyof TraffickingFilters>(filter: T, value: TraffickingFilters[T]) => void;
  applyTableFilter: <
    TableName extends keyof typeof TraffickingTableName,
    TableFilter extends keyof NonNullable<TraffickingFilters[TableName]>
  >(
    tableName: TableName,
    tableFilter: TableFilter,
    values: NonNullable<TraffickingFilters[TableName]>[TableFilter]
  ) => void;
  clearTableFilters: () => void;
  loading: boolean;
  shareableId: Nullable<string>;
};

type SortBy = Maybe<CampaignSortOptionsV5Type> & Maybe<LineItemSortOptionsV5Type> & Maybe<AdSortOptionsV5Type>;

export const useFilters = (): UseFilters => {
  const [params, setSearchParams] = useSearchParams();

  const shareableId = useMemo(() => params.get(SearchParams.ShareableId), [params]);

  const [filters, setFilters] = useState<GlobalFilters>(() => getInitialFilters(shareableId, params));
  const [loading, setLoading] = useState<boolean>(!!shareableId);

  const isShareableIdCleared = useRef<boolean>(false);

  useEffect(() => {
    if (shareableId) {
      isShareableIdCleared.current = false;
    }
  }, [shareableId]);

  useEffectOnce(() => {
    if (hasFiltersParams(params)) {
      TRAFFICKING_FILTERS_SEARCH_PARAMS.forEach((param) => params.delete(param));
      filtersData.setAllStorageData(filters);

      setSearchParams(params, { replace: true });
    }
  });

  const onError = (): void =>
    openToastAlert({
      alertType: 'error',
      message: 'Invalid or expired shareable link',
      restrictToPage: false,
    });

  const { data, error } = useQuery<Get_Trafficking_FiltersQuery, Get_Trafficking_FiltersQueryVariables>(
    GET_TRAFFICKING_FILTERS,
    {
      variables: { shareableId: shareableId || '' },
      skip: !shareableId,
      onError,
    }
  );

  useEffect(() => {
    if (shareableId && data) {
      const shareableFilters = getDefaultFilters(
        removeTypeNamesFromObject<TraffickingFilters>(data.getTraffickingFilters)
      );

      setFilters(shareableFilters);
      filtersData.setAllStorageData(shareableFilters);
      setLoading(false);
    }
  }, [shareableId, data, setLoading]);

  useEffect(() => {
    if (shareableId && error) {
      setFilters(INITIAL_FILTERS as GlobalFilters);
      filtersData.setAllStorageData(INITIAL_FILTERS as GlobalFilters);
      setLoading(false);
    }
  }, [shareableId, error]);

  const applyFilter = useCallback(
    <T extends keyof TraffickingFilters>(filter: T, value: Partial<TraffickingFilters>[T]): void => {
      if (shareableId && !isShareableIdCleared.current) {
        isShareableIdCleared.current = true;
        params.delete(SearchParams.ShareableId);
        setSearchParams(params);
      }

      filtersData.setStorageData(filter, value);

      setFilters((prevState) => ({
        ...prevState,
        [filter]: value,
      }));
    },
    [params, setSearchParams, setFilters, shareableId]
  );

  const applyTableFilter = useCallback(
    <
      TableName extends keyof typeof TraffickingTableName,
      TableFilter extends keyof NonNullable<TraffickingFilters[TableName]>
    >(
      tableName: TableName,
      tableFilter: TableFilter,
      values: NonNullable<TraffickingFilters[TableName]>[TableFilter]
    ) => {
      const tableFilters = filters[tableName];
      const newTableFilters = { ...tableFilters, [tableFilter]: values };

      applyFilter(tableName, newTableFilters);
    },
    [filters, applyFilter]
  );

  const clearTableFilters = useCallback(() => {
    const traffickingTablesName = [
      TraffickingTableName.ads,
      TraffickingTableName.campaigns,
      TraffickingTableName.lineItems,
    ];

    setFilters((prevFilters) => {
      traffickingTablesName.forEach((tableName) => {
        const tableFilters = {
          searchTerm: '',
          selectedRowIds: [],
          sortBy: prevFilters[tableName]?.sortBy as SortBy,
        };

        filtersData.setStorageData(tableName, tableFilters);
      });

      return {
        ...prevFilters,
        ads: { ...INITIAL_TABLE_FILTERS.ads, sortBy: prevFilters?.ads && prevFilters?.ads.sortBy },
        campaigns: {
          ...INITIAL_TABLE_FILTERS.campaigns,
          sortBy: prevFilters?.campaigns && prevFilters?.campaigns.sortBy,
        },
        lineItems: {
          ...INITIAL_TABLE_FILTERS.lineItems,
          sortBy: prevFilters?.lineItems && prevFilters?.lineItems.sortBy,
        },
      };
    });
  }, []);

  return {
    filters,
    applyFilter,
    applyTableFilter,
    clearTableFilters,
    loading,
    shareableId,
  };
};
