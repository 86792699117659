import './AdEditDateCell.scss';

import { Modal } from 'antd';
import { Formik } from 'formik';
import React, { memo, useCallback, useMemo, useState } from 'react';

import type { DayPartV5 } from '../../../../../apis/graphql';
import { getAdSchedule } from '../../../../../common/AdForm/hooks/modelConverters';
import EditableCell from '../../../../../common/EditableTableCells/EditableCell';
import type { AdFormErrorType } from '../../../../../common/utils';
import { showFailure, showSuccess } from '../../../../../common/utils';
import bem from '../../../../../utils/bem';
import { formatAdStartAndEndDateToCorrectFormat } from '../../../../../utils/formatting';
import shallowEqual from '../../../../../utils/shallowEqual';
import { useAdSchedulingForm } from './hooks/useAdSchedulingForm';
import SchedulingForm from './SchedulingForm';
import type { AdEditDateCellProps, SchedulingFormInitialValues } from './types';

const [block, element] = bem('ad-edit-cell');

const AdEditDateCell = ({ row, isAdStartDate = true }: AdEditDateCellProps): JSX.Element => {
  const {
    original: { schedule, lineItem },
  } = row;

  const [isSchedulingModalOpen, setIsSchedulingModalOpen] = useState<boolean>(false);
  const [stashedDayPartList, setStashedDayPartList] = useState<DayPartV5[]>([]);

  const onSchedulingModalClose = useCallback((event?: React.MouseEvent) => {
    event?.stopPropagation();
    setIsSchedulingModalOpen(false);
  }, []);

  const onSchedulingModalOpen = useCallback((event?: React.MouseEvent) => {
    event?.stopPropagation();
    setIsSchedulingModalOpen(true);
  }, []);

  const onSuccessUpdate = useCallback(() => {
    showSuccess();
    onSchedulingModalClose();
  }, [onSchedulingModalClose]);

  const onFailureUpdate = useCallback((error: AdFormErrorType) => {
    showFailure(error);
  }, []);

  const { cellVariant, loading, oldCellValue, onSchedulingFormSubmit } = useAdSchedulingForm({
    row,
    isAdStartDate,
    onSuccessUpdate,
    onFailureUpdate,
  });

  const schedulingFormInitialValues: SchedulingFormInitialValues = {
    schedule: useMemo(() => getAdSchedule(schedule, lineItem?.schedule), [lineItem?.schedule, schedule]),
    isLastDateRangeDisabled: false,
  };

  return (
    <EditableCell
      onClick={onSchedulingModalOpen}
      usePencil={true}
      variant={cellVariant}
      tabIndex={-1}
      data-testid="ad-date-editable-cell"
    >
      <div className={block()}>
        <div className={element('displayed-date')}>{formatAdStartAndEndDateToCorrectFormat(oldCellValue, true)}</div>
        {isSchedulingModalOpen && (
          <Modal
            open={isSchedulingModalOpen}
            footer={null}
            onCancel={onSchedulingModalClose}
            width={800}
            data-testid="scheduling-modal"
            className={element('modal')}
          >
            <Formik initialValues={schedulingFormInitialValues} onSubmit={onSchedulingFormSubmit}>
              {({ submitForm }): JSX.Element => (
                <SchedulingForm
                  schedule={row.original.schedule}
                  lineItemSchedule={row.original.lineItem?.schedule || null}
                  onCancel={onSchedulingModalClose}
                  stashedDayPartList={stashedDayPartList}
                  onSetStashedDayPartList={setStashedDayPartList}
                  onSubmit={submitForm}
                  loading={loading}
                />
              )}
            </Formik>
          </Modal>
        )}
      </div>
    </EditableCell>
  );
};

export const comparison = (prev: AdEditDateCellProps, next: AdEditDateCellProps): boolean => {
  if (prev.isAdStartDate !== next.isAdStartDate) return false;
  if (prev.row.original.id !== next.row.original.id) return false;
  if (
    prev.row.original.startDate !== next.row.original.startDate ||
    prev.row.original.endDate !== next.row.original.endDate
  )
    return false;
  if (!shallowEqual(prev.row.original.schedule, next.row.original.schedule)) return false;
  if (!shallowEqual(prev.row.original.lineItem?.schedule, next.row.original.lineItem?.schedule)) return false;

  return true;
};

export default memo(AdEditDateCell, comparison);
