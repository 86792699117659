import type { DisplayEnum, ViewabilityVendor } from '../../../configs';
import { viewabilityVendors } from '../../../configs';
import { AdFormFields } from '../adFormik/fields';
import { getIASCampaignLineItemIds } from '../Metadata/utils';
import type { ValidationError } from '../types';
import {
  CANNOT_SELECT_OTHER_VIEWABILITY_VENDOR_IF_IAS_SELECTED,
  IAS_CAMPAIGN_LINE_ITEM_ID_FIELD_EMPTY,
  VIEWABILITY_VENDORS_ERROR_MESSAGE,
} from './const';

const validateEmptyIds = (campaignId?: string, lineItemId?: string): ValidationError | null => {
  let missingIdsMessage = 'IAS ';

  if (!campaignId) {
    missingIdsMessage += 'Campaign Id';
  }

  if (!lineItemId) {
    if (!campaignId) {
      missingIdsMessage += ' and ';
    }

    missingIdsMessage += 'Line Item Id';
  }

  return !!campaignId && !!lineItemId
    ? null
    : {
        fields: [AdFormFields.IASCampaignLineItemId],
        message: VIEWABILITY_VENDORS_ERROR_MESSAGE,
        description: `${missingIdsMessage} should not be empty`,
      };
};

const validateNumberIds = (campaignId?: string, lineItemId?: string): ValidationError | null => {
  let failedIdsMessage = 'IAS ';

  const isCampaignIdNumber = campaignId && Number.isInteger(Number(campaignId));
  const isLineItemIdNumber = lineItemId && Number.isInteger(Number(lineItemId));

  if (!isCampaignIdNumber) {
    failedIdsMessage += 'Campaign Id';
  }

  if (!isLineItemIdNumber) {
    if (!isCampaignIdNumber) {
      failedIdsMessage += ' and ';
    }

    failedIdsMessage += 'Line Item Id';
  }

  return isCampaignIdNumber && isLineItemIdNumber
    ? null
    : {
        fields: [AdFormFields.IASCampaignLineItemId],
        message: VIEWABILITY_VENDORS_ERROR_MESSAGE,
        description: `${failedIdsMessage} should be ${
          !isCampaignIdNumber && !isLineItemIdNumber ? 'numbers' : 'a number'
        }`,
      };
};

export const validateMetadata = (
  viewabilityVendorValues: DisplayEnum<ViewabilityVendor>[],
  iasCampaignLineItemId: string
): ValidationError | null => {
  const isIASSelected = viewabilityVendorValues.find(
    (value: DisplayEnum<ViewabilityVendor>) => value.key === viewabilityVendors.IAS.key
  );

  if (isIASSelected) {
    if (viewabilityVendorValues.length > 1) {
      return {
        fields: [],
        message: VIEWABILITY_VENDORS_ERROR_MESSAGE,
        description: CANNOT_SELECT_OTHER_VIEWABILITY_VENDOR_IF_IAS_SELECTED,
      };
    }

    if (!iasCampaignLineItemId) {
      return {
        fields: [AdFormFields.IASCampaignLineItemId],
        message: VIEWABILITY_VENDORS_ERROR_MESSAGE,
        description: IAS_CAMPAIGN_LINE_ITEM_ID_FIELD_EMPTY,
      };
    }

    const { campaignId, lineItemId } = getIASCampaignLineItemIds(iasCampaignLineItemId);

    const emptyIdsError = validateEmptyIds(campaignId, lineItemId);

    if (emptyIdsError) return emptyIdsError;

    const numberIdsError = validateNumberIds(campaignId, lineItemId);

    if (numberIdsError) return numberIdsError;
  }

  return null;
};
