import { COLUMN_NAME_ID } from '../../../constants';

export const LineItemColumn = Object.freeze({
  Alert: 'Alert',
  Name: COLUMN_NAME_ID,
  CreateAd: 'CreateAd',
  Country: 'Country',
  DeliverableLineItemPackageName: 'DeliverableLineItemPackageName',
  TrackingId: 'TrackingId',
  ParentOmsLinkId: 'ParentOmsLinkId',
  Status: 'Status',
  Review: 'Review',
  ScheduleStart: 'ScheduleStart',
  ActualStartDate: 'ActualStartDate',
  ScheduleEnd: 'ScheduleEnd',
  DisplayPublisherTarget: 'DisplayPublisherTarget',
  ScheduleTimezoneOffset: 'ScheduleTimezoneOffset',
  GeneralPacingStatus: 'GeneralPacingStatus',
  Goal: 'Goal',
  MetricsCompletions: 'MetricsCompletions',
  MetricsPercentPacing: 'MetricsPercentPacing',
  MetricsPercentExpected: 'MetricsPercentExpected',
  MetricsPercentCompletions: 'MetricsPercentCompletions',
  PacingType: 'PacingType',
  Tier: 'Tier',
  YodaThreshold: 'YodaThreshold',
  YodaPacingStatus: 'YodaPacingStatus',
  PriorityName: 'PriorityName',
  PriorityValue: 'PriorityValue',
  AdProduct: 'AdProduct',
  FrequencyCapList: 'frequencyCapList',
  DeliveryBufferPercent: 'DeliveryBufferPercent',
  FrontLoadPercent: 'FrontLoadPercent',
  DeliveryForensics: 'deliveryForensics',
  AccessorId: 'accessorId',
  MetricsRemainingExpectedCompletionsAcc: 'MetricsRemainingExpectedCompletionsAcc',
  MetricsCompletionsAtRisk: 'MetricsCompletionsAtRisk',
  Revenue: 'Revenue',
  MetricsRevenueAtRisk: 'MetricsRevenueAtRisk',
  BillableThirdParty: 'BillableThirdParty',
  UnitCost: 'UnitCost',
  MetricsUnderPacingRevenue: 'MetricsUnderPacingRevenue',
  LineOmsLinkId: 'LineOmsLinkId',
  CreatedAt: 'CreatedAt',
  UpdatedAt: 'UpdatedAt',
  CreativeMaxSeconds: 'CreativeMaxSeconds',
  CampaignName: 'CampaignName',
  DeliveryGoal: 'DeliveryGoal',
  IsCoppaOrCaru: 'IsCoppaOrCaru',
  IsAddedValue: 'IsAddedValue',
  IsMakegood: 'IsMakegood',
  IsFiller: 'IsFiller',
  DeliverableLineItemBuyerName: 'DeliverableLineItemBuyerName',
  DeliverableLineItemBuyerId: 'DeliverableLineItemBuyerId',
  MagniteDealDspName: 'MagniteDealDspName',
  MagniteDealDealName: 'MagniteDealDealName',
  CampaignTraffickerEmail: 'CampaignTraffickerEmail',
  CampaignOrderSalespersonEmail: 'CampaignOrderSalespersonEmail',
  Currency: 'Currency',
  Sequence: 'Sequence',
});
