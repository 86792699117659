export const SEQUENCE_FORM_TITLE = 'Create New Sequence';

export const DISCARD_CHANGES_TITLE = 'Discard Changes';
export const SAVE_SEQUENCE_TITLE = 'Save Sequence';

export const DISCARD_CHANGES_ARIA_LABEL = 'discard changes';
export const SAVE_SEQUENCE_ARIA_LABEL = 'save sequence';

export const NEW_SEQUENCE_TITLE = 'New Sequence';

export const UNSEQUENCED_TABLE_ID = '-1';

export const UNSEQUENCED_TITLE = 'Unsequenced';

export const SEARCH_PLACEHOLDER = 'Search by name or ID';

export const NEW_SEQUENCE_LABEL = 'New_Sequence_';
export const NEW_SEQUENCE_ID = 'new-sequence-id-';

export const ERROR_TITLE = 'Error';

export const SEQUENCE_ROW_TOOLTIP_PLACEMENT = 'bottom';
export const SEQUENCE_ROW_TOOLTIP_DELAY_SHOW = 100;
export const SEQUENCE_ROW_TOOLTIP_DELAY_HIDE = 100;
export const SEQUENCE_ROW_TOOLTIP_MESSAGE =
  'Weighted ads cannot be sequenced. Remove ad from rotation in order to set sequence.';

export const SLATE_VIDEO_AMOUNT_AD_SEQUENCE_ERROR =
  'Sequenced Slate and Video lines must contain 1 Slate ad and 1+ Video ad(s) to create sequence. Please create required ads.';
export const AD_SELECTOR_SLATE_AD_SELECTOR_VIDEO_AMOUNT_AD_SEQUENCE_ERROR =
  'Sequenced Ad Selector lines must contain 1 Ad Selector Slate ad and 2+ Ad Selector Video ads to create sequence. Please create required ads.';
export const SLATE_ON_TOP_AD_SEQUENCE_ERROR = 'Sequence must start with a slate. Please update your sequence.';
export const AD_TYPE_CONFLICT_AD_SEQUENCE_ERROR =
  'Ads in the sequence should be SLATE/VIDEO or AD_SELECTOR_SLATE/AD_SELECTOR_VIDEO.';
export const EMPTY_AD_SEQUENCE_ERROR = 'Ad sequence cannot be empty.';
export const CONFLICTING_PARENT_LINE_OMS_LINKS_AD_SEQUENCE_ERROR =
  'Sequence cannot be created for Ads, which Line Items have different parentLineOmsLink.';

export const CREATE_SEQUENCES_ERROR = 'Create sequences failed!';
export const UPDATE_SEQUENCES_ERROR = 'Update sequences failed!';
export const DELETE_SEQUENCES_ERROR = 'Delete sequences failed!';

export const SEQUENCES_SAVED_SUCCESSFULLY_MESSAGE = 'Sequences saved successfully!';
