import './TextField.scss';

import { HelpText, TextField as TrekTextField } from '@hulu/react-style-components';
import { useFormikContext } from 'formik';
import type { InputHTMLAttributes } from 'react';
import * as React from 'react';

import bem from '../../../utils/bem';
import type { FormFieldsNames, FormFieldsValues } from '../../AdForm/adFormik';
import { useFieldFast } from '../hooks';
import PreviewWrapper from '../PreviewWrapper';
import { makeLabelChild } from '../utils';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  formFieldName: string;
  label?: string;
  secondaryLabel?: string;
  className?: string;
  isPreview?: boolean;
  help?: string | React.JSX.Element;
}

const [block, element] = bem('text-field-container');

export const TextField = ({
  formFieldName,
  label,
  secondaryLabel,
  disabled,
  className,
  isPreview,
  help,
  ...props
}: Props): JSX.Element => {
  const [, { value }, { setValue }] = useFieldFast(formFieldName);
  const { isSubmitting, errors } = useFormikContext<FormFieldsValues>();

  return (
    <PreviewWrapper isPreview={isPreview} label={label} value={value}>
      <div className={`${block()} ${className}`}>
        <TrekTextField
          value={value}
          label={makeLabelChild(label, secondaryLabel)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setValue(e.target.value)}
          disabled={disabled || isSubmitting}
          className={element('text-field')}
          errMsg={errors[formFieldName as FormFieldsNames]}
          {...props}
        />
        {help && <HelpText>{help}</HelpText>}
      </div>
    </PreviewWrapper>
  );
};

export default TextField;
