import { deliveryTiers } from '../../../../../../../../../../configs';
import type { MultipleEditDrawerLineItemsFormValues } from '../../../../../../types';

type Props = {
  values: MultipleEditDrawerLineItemsFormValues;
  priorityConflicts?: string[] | null;
  isLineItem: boolean;
};

type Value = {
  id: string;
  value: {
    name?: string;
    rank?: number;
  };
};

type Result = {
  priority?: Value[];
  tier?: Value[];
};

const HASH_TABLE_TIER_RANK = {
  [deliveryTiers.test.key]: 6,
  [deliveryTiers.house.key]: 5,
  [deliveryTiers.non_guaranteed.key]: 4,
  [deliveryTiers.guaranteed.key]: 3,
  [deliveryTiers.sponsorship.key]: 2,
};

export const getPreparedInputForPriority = ({ values, priorityConflicts, isLineItem }: Props): Result => {
  const selectedAndValidatedEntities =
    values.priority.tier && values.entities.filter((entity) => !priorityConflicts?.includes(entity.id));

  if (!isLineItem || !selectedAndValidatedEntities?.length || !values.selectedSections.includes('Priority')) {
    return {};
  }

  const priority = {
    priority: selectedAndValidatedEntities.map((entity) => ({
      id: entity.id,
      value: {
        name: values.priority.priority.name,
      },
    })),
  };

  const tier = {
    tier: selectedAndValidatedEntities.map((entity) => ({
      id: entity.id,
      value: {
        name: values.priority.tier?.name.key,
        rank: HASH_TABLE_TIER_RANK[values.priority.tier?.name.key!],
      },
    })),
  };

  return { ...priority, ...tier };
};
