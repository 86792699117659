import { Tab } from '@hulu/react-style-components';
import { bem } from 'mission-control-common-components';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo } from 'react';

import type { FrequencyCapTypeV5 } from '../../../../apis/graphql';
import FrequencyCaps from '../../../../common/FrequencyCaps';
import { useFieldArray } from '../../../Form/hooks';
import type { ClearableFrequencyCap } from '../../../FrequencyCaps/FrequencyCaps';
import { getDefaultFrequencyCap } from '../../../FrequencyCaps/FrequencyCaps';

type FrequencyCapTabTitle = 'Unlimited' | 'Capped';

type UseFrequencyCapListProps = {
  stashedFrequencyCapList: ClearableFrequencyCap[];
  setStashedFrequencyCapList: Dispatch<SetStateAction<ClearableFrequencyCap[]>>;
  frequencyCapType: FrequencyCapTypeV5;
  withUnlimitedTab: boolean;
  readonly?: boolean;
};

type UseFrequencyCapList = {
  memorizedTabs: React.JSX.Element[];
  handleTabClick: (tabTitle: FrequencyCapTabTitle) => void;
  frequencyCapList: ClearableFrequencyCap[];
};

export const frequencyCapFormFieldName = 'frequencyCapList';

const [block] = bem('metadata');

export const useFrequencyCapList = ({
  setStashedFrequencyCapList,
  stashedFrequencyCapList,
  frequencyCapType,
  withUnlimitedTab,
  readonly,
}: UseFrequencyCapListProps): UseFrequencyCapList => {
  const [
    ,
    { value: frequencyCapList },
    { insertAt, removeAt, replaceAt, setValue: setFrequencyCapList },
  ] = useFieldArray<ClearableFrequencyCap>(frequencyCapFormFieldName);

  const handleTabClick = useCallback(
    (tabTitle: FrequencyCapTabTitle): void => {
      if (readonly) return;

      if (tabTitle === 'Unlimited') {
        setStashedFrequencyCapList(frequencyCapList);
        setFrequencyCapList([]);
      }

      if (tabTitle === 'Capped') {
        setFrequencyCapList(
          stashedFrequencyCapList.length !== 0 ? stashedFrequencyCapList : [getDefaultFrequencyCap(frequencyCapType)]
        );
      }
    },
    [
      frequencyCapList,
      frequencyCapType,
      stashedFrequencyCapList,
      setFrequencyCapList,
      readonly,
      setStashedFrequencyCapList,
    ]
  );

  const memorizedTabs = useMemo(() => {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, tabTitle: FrequencyCapTabTitle): void => {
      e.stopPropagation();
      if (e.key === 'Enter') {
        handleTabClick(tabTitle);
      }
    };

    const tabs = [
      <Tab
        key="capped"
        title="Capped"
        tabIndex={0}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>): void => handleKeyDown(e, 'Capped')}
        className={readonly ? block('readonly') : ''}
      >
        <FrequencyCaps // An ad can have an empty frequency cap list, but we want users to click the "Unlimited" tab to indicate this
          isClearable={false}
          frequencyCapList={frequencyCapList}
          frequencyCapType={frequencyCapType}
          listHelpers={{ insertAt, removeAt, replaceAt }}
          readonly={readonly}
        />
      </Tab>,
    ];

    if (withUnlimitedTab) {
      tabs.unshift(
        <Tab
          key="unlimited"
          title="Unlimited"
          tabIndex={0}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>): void => handleKeyDown(e, 'Unlimited')}
          className={readonly ? block('readonly') : ''}
        />
      );
    }

    return tabs;
  }, [frequencyCapList, frequencyCapType, insertAt, readonly, removeAt, replaceAt, withUnlimitedTab, handleTabClick]);

  return {
    memorizedTabs,
    handleTabClick,
    frequencyCapList,
  };
};
