import { isArray, isNumber } from 'lodash';

import type { Nullable } from '../../../../models';
import { formatArray } from '../../../../utils/formatting';

export const getClassName = (
  cellColumnClassName: string | undefined,
  ignoreRowDuplicate: boolean
): string | undefined => {
  return ignoreRowDuplicate ? '' : cellColumnClassName || '';
};

export const formattingArrayOrPrimitiveValueToString = (
  value: Nullable<string | number | string[] | number[]>
): string => {
  if (value) {
    return isArray(value) ? formatArray(value) : `${value}`;
  }

  return isNumber(value) ? `${value}` : '-';
};
