import { DrawerType, EntityTypeArray } from '../../../../TraffickingPage/hooks/makePageDrawerPlugin';
import type { MultipleEditDrawerProps } from '../../MultipleEditDrawer';
import MultipleAdDrawer from '../MultipleAdDrawer/MultipleAdDrawer';
import MultipleLineItemDrawer from '../MultipleLineItemDrawer/MultipleLineItemDrawer';

const MultipleEditDrawerWrapper = (props: MultipleEditDrawerProps): JSX.Element => {
  switch (props.data.dataType) {
    case EntityTypeArray.AD_ARRAY:
      return (
        <MultipleAdDrawer
          {...props}
          data={{
            drawerType: DrawerType.MULTIPLE_EDIT,
            dataType: EntityTypeArray.AD_ARRAY,
            entities: props.data.entities,
          }}
        />
      );
    case EntityTypeArray.LINE_ITEM_ARRAY:
      return (
        <MultipleLineItemDrawer
          {...props}
          data={{
            drawerType: DrawerType.MULTIPLE_EDIT,
            dataType: EntityTypeArray.LINE_ITEM_ARRAY,
            entities: props.data.entities,
          }}
        />
      );
  }
};

export default MultipleEditDrawerWrapper;
