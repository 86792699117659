import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from './types';
import { convertToDisplayEnum, makeConfig } from './utils';

export type AdTagType = keyof typeof adTagTypeDisplayNames;

const adTagTypeDisplayNames = {
  BILLABLE: enumToTitleCase,
  NON_BILLABLE: 'Non-Billable',
  CLICK: enumToTitleCase,
};

export const adTagTypes = makeConfig<AdTagType>(adTagTypeDisplayNames);

export function convertAdTagType(apiValue: string): DisplayEnum<AdTagType> {
  return convertToDisplayEnum(apiValue, adTagTypes);
}
