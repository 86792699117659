import React from 'react';

import type { FrequencyCap } from '../../../../../../../../../../models';
import { element } from '../../../components/Row/Row';

const FrequencyRow = ({
  frequencyCapList,
  isShowFrequencyValues,
}: {
  isShowFrequencyValues: boolean;
  frequencyCapList: FrequencyCap[];
}): React.JSX.Element => {
  return (
    <>
      {isShowFrequencyValues &&
        frequencyCapList.map((frequency) => (
          <div
            key={`${frequency.durationUnit.displayName}-${frequency.limit}-${frequency.duration}`}
            className={element('value-title')}
          >{`${frequency.limit} / ${frequency.duration} ${frequency.durationUnit.displayName}`}</div>
        ))}
    </>
  );
};

export default FrequencyRow;
