import { Formik } from 'formik';
import React from 'react';

import Drawer from '../../../../common/Drawer';
import { useStepper } from '../../../../hooks/useStepper';
import type { MultipleEditDrawerData } from '../../TraffickingPage/hooks/makePageDrawerPlugin';
import { Steps } from './constants';
import MultipleEditDrawerContent from './MultipleEditDrawerContent';
import MultipleEditDrawerTitle from './MultipleEditDrawerTitle';
import type { MultipleEditDrawerFormValues, onSubmitType } from './types';

export type MultipleEditDrawerProps = {
  isOpen: boolean;
  isExpanded: boolean;
  className: string;
  data: MultipleEditDrawerData;
  onClose: () => void;
  onToggleExpansion: () => void;
};

export type MultipleEditDrawerPropsWithInitialValues = MultipleEditDrawerProps & {
  initialValues: MultipleEditDrawerFormValues;
};

export default function MultipleEditDrawer({
  data,
  isExpanded,
  isOpen,
  className,
  onClose,
  onToggleExpansion,
  initialValues,
}: MultipleEditDrawerPropsWithInitialValues): JSX.Element {
  const { activeStep, onNextStep, onPrevStep, onResetStep } = useStepper(Steps);

  const formikKey = data.entities.map(({ id }: { id: string }) => id).join('');

  const onSubmit: onSubmitType = (_, { resetForm }) => {
    onClose();
    resetForm();
    onResetStep();
  };

  return (
    <Formik<MultipleEditDrawerFormValues> onSubmit={onSubmit} initialValues={initialValues} key={formikKey}>
      {({ submitForm, resetForm }): JSX.Element => {
        return (
          <Drawer<MultipleEditDrawerData>
            size="large"
            data={data}
            isOpen={isOpen}
            onClose={(): void => {
              onClose();
              onResetStep();
              resetForm();
            }}
            className={className}
            isExpanded={isExpanded}
            onToggleExpansion={onToggleExpansion}
            title={(): JSX.Element => (
              <MultipleEditDrawerTitle onPrevStep={onPrevStep} activeStep={activeStep} entityType={data.dataType} />
            )}
            content={(): JSX.Element => (
              <MultipleEditDrawerContent
                onNextStep={onNextStep}
                activeStep={activeStep}
                onSubmit={submitForm}
                isExpanded={isExpanded}
                entityType={data.dataType}
              />
            )}
          />
        );
      }}
    </Formik>
  );
}
