import './SelectedItemsPill.scss';

import { IconClose } from '@hulu-react-style-components/icons';
import { Pill } from '@hulu/react-style-components';
import type { MouseEventHandler } from 'react';
import React from 'react';

import bem from '../../../utils/bem';

export interface SelectedItemsPillProps {
  selectedRowsCount: number;
  onClearFunction: MouseEventHandler<SVGSVGElement>;
}

const [block, element] = bem('selected-items-pill');

function SelectedItemsPill({ selectedRowsCount, onClearFunction }: SelectedItemsPillProps): JSX.Element {
  return (
    <span className={block()}>
      <Pill className={element('pill')}>{selectedRowsCount} Selected</Pill>
      <IconClose className={element('icon-close')} data-testid="close-icon" onClick={onClearFunction} />
    </span>
  );
}

export default SelectedItemsPill;
