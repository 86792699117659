import './MultipleEditNotFirstStepTitle.scss';

import { IconArrowLeft } from '@hulu-react-style-components/icons';
import { useFormikContext } from 'formik';
import React from 'react';

import Badge from '../../../../../../common/Badge';
import bem from '../../../../../../utils/bem';
import type { EntityTypeArray } from '../../../../TraffickingPage/hooks/makePageDrawerPlugin';
import type { MultipleEditDrawerFormValues } from '../../types';
import { getCountText } from './utils';

const [block, element] = bem('multiple-edit-not-first-step-title');

type MultipleEditNotFirstStepTitleProps = {
  className: string;
  entityType: EntityTypeArray;
  onPrevStep: () => void;
};

export default function MultipleEditNotFirstStepTitle({
  className,
  entityType,
  onPrevStep,
}: MultipleEditNotFirstStepTitleProps): JSX.Element {
  const {
    values: { entities },
  } = useFormikContext<MultipleEditDrawerFormValues>();

  const selectEntities = entities.filter(({ isSelected }) => isSelected);

  const entityNames = selectEntities.map(({ name }) => name).join('; ');

  return (
    <div className={`${className} ${block()}`}>
      <div className={element('arrow')}>
        <IconArrowLeft onClick={onPrevStep} />
      </div>
      <Badge text={getCountText(selectEntities, entityType)} className={element('badge')} />
      <div className={element('entities-names')}>{entityNames}</div>
    </div>
  );
}
