import './CellItems.scss';

import { isEmpty as _isEmpty } from 'lodash';
import React from 'react';

import type { ChangeAction, SimpleChangeValue } from '../../../../../apis/graphql';
import bem from '../../../../../utils/bem';
import { formatArray, formatDefaultChangeLogCell } from '../../../../../utils/formatting';
import { GreenPlus } from '../../../../assets';
import type { Action } from '../CellItem';

const [block, element] = bem('multiple-change-log-cell');

export type CellChanges = Omit<SimpleChangeValue, 'payload'> & {
  // JSONDataType is returned from graphql
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
  action?: ChangeAction;
};

export interface CellItemsProps {
  changes: CellChanges[] | CellChanges;
  action: Action;
}

interface DefaultListProps {
  // JSONDataType is returned from graphql
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
  index: number;
  nested?: boolean;
  action: Action;
}

function DefaultList({ payload, index, action, nested = false }: DefaultListProps): JSX.Element | null {
  const defaultKeys = Object.keys(payload);
  const blockModifier = nested ? 'default-list-nested' : 'default-list';

  return payload ? (
    <ul key={`ul-list-${index}`} className={block(blockModifier)}>
      {defaultKeys.map((payloadKey, nestedIndex) => {
        const payloadValue = payload[payloadKey];
        const isObject = payloadValue instanceof Object && !(payloadValue instanceof Array);

        const removalClass = block(payloadValue && action.removal ? 'removal' : '');
        return isObject ? (
          <li key={nestedIndex} className={nestedIndex === 0 ? removalClass : ''}>
            {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className="plus-icon" />}
            {payloadKey}:
            <DefaultList index={nestedIndex} payload={payloadValue} key={index} nested action={action} />
          </li>
        ) : (
          <li key={`ul-list-${nestedIndex}`} className={removalClass}>
            {formatDefaultChangeLogCell(payloadValue)}
          </li>
        );
      })}
    </ul>
  ) : (
    <span>-</span>
  );
}

export function DefaultCellItems({ changes, action }: CellItemsProps): React.ReactElement {
  const removalClass = action.removal ? 'removal' : '';

  if (Array.isArray(changes)) {
    return (
      <>
        {changes.map(({ payload }, index) => (
          <DefaultList payload={payload} index={index} key={index} action={action} />
        ))}
      </>
    );
  }

  if (Array.isArray(changes.payload)) {
    return (
      <span className={block(removalClass)}>
        {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
        {formatArray(changes.payload)}
      </span>
    );
  }

  return changes.payload && _isEmpty(changes.payload) === false ? (
    <DefaultList payload={changes.payload} index={0} action={action} />
  ) : (
    <span>-</span>
  );
}
