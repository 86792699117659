import { sortBy as _sortBy } from 'lodash';

import { filtersWithFixedOrderConfig } from './config';
import type { AdditionalFilterDropdown } from './constants';
import { FILTER_LABELS_WITH_FIXED_ORDER } from './constants';

export const getSortedFiltersWithDynamicOrder = (
  filters: AdditionalFilterDropdown[],
  filterLabelsWithFixedOrder: string[]
): AdditionalFilterDropdown[] => {
  const dynamicFilters = filters.filter((filter) => !filterLabelsWithFixedOrder.includes(filter.label));

  return _sortBy(dynamicFilters, 'label');
};

export const getSortedFilters = (filters: AdditionalFilterDropdown[]): AdditionalFilterDropdown[] => {
  const sortedFiltersWithDynamicOrder = getSortedFiltersWithDynamicOrder(filters, FILTER_LABELS_WITH_FIXED_ORDER);

  return [...filtersWithFixedOrderConfig, ...sortedFiltersWithDynamicOrder];
};
