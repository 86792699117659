import { gql } from '@apollo/client';

export const POPULATE_TARGETING_VALUES = gql`
  query populateTargetingValues($termList: [PopulateTargetingValuesInput!]!) {
    populateTargetingValues(termList: $termList) {
      dimension
      valueSet {
        id
        displayName
      }
    }
  }
`;
