import { useNavigate } from '@hulu/react-router-dom';
import type { Location, State } from 'history';

import { LOCAL_STORAGE_LOCATION_STATE } from '../../pages/Trafficking';
import { workWithLocalStorage } from '../../utils/storage/localStorage';

type UseNavigationWithLocalStorage = {
  navigateWithLocalStorage: (e: React.MouseEvent<HTMLElement>, location: Location, locationState?: State) => void;
};

const useNavigationWithLocalStorage = (): UseNavigationWithLocalStorage => {
  const navigate = useNavigate();

  const navigateWithLocalStorage = (
    e: React.MouseEvent<HTMLElement>,
    location: Location,
    locationState?: State
  ): void => {
    workWithLocalStorage.setData(LOCAL_STORAGE_LOCATION_STATE, locationState);

    if (e.metaKey || e.ctrlKey) {
      window.open(location.pathname, '_blank');
    } else {
      navigate(location.pathname, { state: locationState });
    }
  };

  return {
    navigateWithLocalStorage,
  };
};

export default useNavigationWithLocalStorage;
