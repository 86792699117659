import './EntityDetailsDrawerTitle.scss';

import React from 'react';

import Badge from '../../../../../common/Badge';
import bem from '../../../../../utils/bem';
import type { EntityDetailsDrawerData } from '../../../TraffickingPage/hooks/makePageDrawerPlugin';

const [block, element] = bem('entity-details-drawer-title');

type Props = Partial<{
  data: EntityDetailsDrawerData;
  isExpanded: boolean;
}>;

export default function EntityDetailsDrawerTitle({ data }: Props): JSX.Element {
  return (
    <h1 className={block()}>
      <Badge text={data?.dataType || ''} />
      <span className={element('text')}>{data?.name || ''}</span>
    </h1>
  );
}
