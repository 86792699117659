export enum defaultSupportedTimezones {
  ET = 'America/New_York',
  MT = 'America/Denver',
  CT = 'America/Chicago',
  PT = 'America/Los_Angeles',
}

export const defaultAdPermittedTimezonesMockUp = [
  'America/New_York',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Adak',
  'America/Phoenix',
  'America/Chicago',
  'America/Indiana/Indianapolis',
  'Pacific/Honolulu',
  'America/Indiana/Knox',
  'America/Detroit',
  'America/Denver',
  'Pacific/Pago_Pago',
];
