import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from './types';
import { convertToDisplayEnum, makeConfig } from './utils';

export type CreativeReviewStatus = keyof typeof creativeReviewStatusDisplayNames;

const creativeReviewStatusDisplayNames = {
  APPROVED: enumToTitleCase,
  DRAFT: enumToTitleCase,
  PREVIEW: enumToTitleCase,
  REJECTED: enumToTitleCase,
  REVIEW: enumToTitleCase,
  UNDER_REVIEW: enumToTitleCase,
};

export const creativeReviewStatuses = makeConfig<CreativeReviewStatus>(creativeReviewStatusDisplayNames);

export function convertCreativeReviewStatus(apiValue: string): DisplayEnum<CreativeReviewStatus> {
  return apiValue
    ? convertToDisplayEnum(apiValue, creativeReviewStatuses)
    : convertToDisplayEnum('DRAFT', creativeReviewStatuses);
}
