import type { GetCreativeByIdQuery } from '../../../../apis/graphql';
import { CreativeV5Type } from '../../../../apis/graphql';
import type { CreativesBuilderValues } from '../types';
import { CreativeTypeNamesAllowsVastBuilder } from './types';

const creativeTypesAllowsVastBuilder = [
  CreativeV5Type.VastVideo,
  CreativeV5Type.Magnite,
  CreativeV5Type.Video,
  CreativeV5Type.Drax,
];

export const checkIsVastCreativeBuilderAllowed = (
  creative: Partial<CreativesBuilderValues> | undefined,
  savedCreative?: GetCreativeByIdQuery['getCreativeByIDV5']
): boolean => {
  const creativeTypeAllowsVastBuilder =
    !!creative?.type && creativeTypesAllowsVastBuilder.some((type) => type === creative?.type);

  return (
    creativeTypeAllowsVastBuilder &&
    (!savedCreative ||
      !!(
        (savedCreative?.__typename === CreativeTypeNamesAllowsVastBuilder.VASTVideoCreativeV5 ||
          savedCreative?.__typename === CreativeTypeNamesAllowsVastBuilder.MagniteCreativeV5 ||
          savedCreative?.__typename === CreativeTypeNamesAllowsVastBuilder.DraxCreativeV5) &&
        savedCreative?.url
      ))
  );
};
