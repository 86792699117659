import React, { useMemo } from 'react';

import type { PopulateTargetingValuesInput } from '../../../../../../apis/graphql';
import bem from '../../../../../../utils/bem';
import { titleCase } from '../../../../../../utils/formatting';
import { GreenPlus } from '../../../../../assets';
import Loader from '../../../../../Loader';
import { TermStatus } from '../../../../restructureData/types';
import usePopulateTargetingValues from '../../../hooks/usePopulateTargetingValues';
import type { CellItemsProps } from '../index';
import useGetTargetingValues from './useGetTargetingValues';
import {
  getTargetingValuesToPopulateAndSetTermPreparedValues,
  getTermListFromCellChanges,
  getTermValueSet,
  getValueToShowInCaseDisplayNameWasNotFound,
  prepareTermsForUi,
} from './utils';

const [block, element] = bem('multiple-change-log-cell');

export interface ValueSet {
  status?: TermStatus;
  include?: boolean;
  value?: string | null;
}

export type PreparedTermValue = {
  id: string;
  name: string;
};

export interface Term {
  dimension?: string;
  operation?: string;
  valueSet?: ValueSet[] | string[];
  not?: boolean;
  'value-set'?: ValueSet[] | string[];
  preparedValues?: PreparedTermValue[] | null;
}
interface TermListProps {
  term: Term;
  action?: { removal: boolean; addition: boolean };
  isMultiTerm?: boolean;
  termIndex?: number;
}

export function getModifier(removed: boolean, added: boolean): string {
  if (removed) return 'removed';
  if (added) return 'added';

  return '';
}

export function getInclusion(isIncluded: boolean): string {
  return isIncluded ? 'Include' : 'Exclude';
}

export function getValue(value: ValueSet | string): string {
  if (typeof value === 'string') {
    return value;
  } else if (!value?.value) {
    return '';
  } else if (value?.value) {
    return value?.value;
  }

  return '';
}

interface TermItemProps {
  termIndex: number;
  valueIndex: number;
  modifier: string;
  added: boolean;
  include: string;
  valueToRender: string;
  dimension: string;
  displayName?: string;
}
function TermItem({
  termIndex,
  valueIndex,
  modifier,
  added,
  include,
  valueToRender,
  dimension,
  displayName,
}: TermItemProps): JSX.Element {
  const { targetingValue, loading } = useGetTargetingValues({
    dimensionGuid: dimension,
    valueId: valueToRender,
    skip: !!displayName,
  });

  if (loading) {
    return <Loader />;
  }
  if (displayName) {
    valueToRender = displayName;
  } else if (targetingValue?.displayName) {
    valueToRender = targetingValue.displayName;
  } else {
    // omit age ranges. you can use these values directly
    // append _NOT_FOUND to all other items failing a display name lookup
    valueToRender = getValueToShowInCaseDisplayNameWasNotFound(dimension, valueToRender);
  }

  return (
    <li key={`${termIndex}-${valueIndex}`} className={element('term-list-item', modifier)}>
      {added && (
        <span>
          <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />
        </span>
      )}
      <span className={element('term-list-item-type')}>{include}</span>
      <span>{valueToRender}</span>
    </li>
  );
}

function TermList({ term, action = { removal: false, addition: false }, termIndex = 0 }: TermListProps): JSX.Element {
  const { removal, addition } = action;
  const valueSet = getTermValueSet(term);

  return (
    <ul key={termIndex}>
      <span className={element('term-list-title')}>{titleCase(term?.dimension || '')}</span>
      {term.preparedValues &&
        term.preparedValues.map((preparedValue, valueIndex) => {
          const include = getInclusion(term?.operation === 'INCLUDED' || !term?.not);
          return (
            <TermItem
              termIndex={termIndex}
              valueIndex={valueIndex}
              modifier={''}
              added={false}
              include={include}
              valueToRender={preparedValue.name}
              dimension={preparedValue.id}
              displayName={preparedValue.name}
            />
          );
        })}
      {!term.preparedValues &&
        valueSet &&
        valueSet.map((value: ValueSet | string, valueIndex: number) => {
          const valueSet = typeof value === 'string' ? {} : value;
          const removed = valueSet?.status === TermStatus.REMOVED;
          const added = valueSet?.status === TermStatus.ADDED;
          const modifier = getModifier(removed || removal, added || addition);
          const include = getInclusion(valueSet?.include || term?.operation === 'INCLUDED' || !term?.not);
          const valueToRender = getValue(value);

          return valueToRender ? (
            <TermItem
              termIndex={termIndex}
              valueIndex={valueIndex}
              modifier={modifier}
              added={added}
              include={include}
              valueToRender={valueToRender}
              dimension={term?.dimension || ''}
            />
          ) : null;
        })}
    </ul>
  );
}

/**
 * Displays list of terms depending on data structure of changes prop
 */
export function TargetingCell({ changes, action }: CellItemsProps): JSX.Element {
  const termsList: Term[] = useMemo(() => {
    return getTermListFromCellChanges(changes);
  }, [changes]);

  const targetingValuesToPopulate: PopulateTargetingValuesInput[] = useMemo(() => {
    return getTargetingValuesToPopulateAndSetTermPreparedValues(termsList);
  }, [termsList]);

  const { termsWithValues, loading } = usePopulateTargetingValues(targetingValuesToPopulate);

  const readyTerms: Term[] = useMemo(() => {
    if (!termsWithValues) {
      return termsList;
    }

    return prepareTermsForUi(termsList, termsWithValues);
  }, [termsList, termsWithValues]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={block()}>
      <div className={element('term-list')}>
        {readyTerms.map((termItem, index) => (
          <TermList term={termItem} action={action} key={`targeting-${index}`} />
        ))}
      </div>
    </div>
  );
}

export default TargetingCell;
