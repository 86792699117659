import type { Maybe } from '../../apis/graphql';
import type { DropdownOption } from '../Dropdown';
import type { LabelTransformationFunction } from './config';
import { BILLED_DROPDOWN_OPTION, DropdownOptions } from './config';

type QueryNode = {
  name: Maybe<string>;
  id: string;
};

export const prepareDropdownOptions = (
  options: string[],
  dropdownOption: DropdownOptions,
  labelTransform?: LabelTransformationFunction
): DropdownOption<string>[] => {
  const preparedOptions = options.map((option) => {
    const labelValue = labelTransform ? labelTransform(option) : option;

    return {
      value: option,
      label: labelValue,
    };
  });

  if (dropdownOption === DropdownOptions.LineItemBillableThirdParties) {
    return [BILLED_DROPDOWN_OPTION, ...preparedOptions];
  }

  return preparedOptions;
};

export const convertQueryResultToDropdownOptions = <T extends QueryNode>(
  queryResult?: { edges: { node: T }[] } | null
): DropdownOption[] => {
  return (
    queryResult?.edges
      .map(({ node }) => ({
        label: node.name ?? '',
        value: node.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || []
  );
};
