import './DateRange.scss';

import React, { useCallback, useMemo } from 'react';

import bem from '../../utils/bem';
import DateInput from '../DateInput';
import type { DateTimeValues } from '../DateInput/utils';
import { getDateTimeWithPredefinedValues, getDateWithPredefinedValues } from '../DateInput/utils';
import { formatDateTimeWithoutSecond, getDateRangeModifier } from './utils';

const [block, element] = bem('daterange');

const DISABLE_MINUTES_STEP = 60 * 60 * 1000;

export type DefaultRangeType = {
  start: string;
  end: string | null;
};

export const DateRange: React.FC<{
  dateRange: DefaultRangeType;
  onChange: (field: 'start' | 'end', value: string) => void;
  editMode?: boolean;
  nullableEndDate?: boolean;
  customTitles?: Record<string, string>;
  readonly?: boolean;
  showTitle?: boolean;
  type?: 'date' | 'datetime-local';
  startPredefinedValues?: DateTimeValues;
  endPredefinedValues?: DateTimeValues;
  step?: number;
  showInfinity?: boolean;
}> = ({
  dateRange,
  editMode = true,
  nullableEndDate = false,
  onChange,
  customTitles = {
    start: 'Start',
    end: 'End',
  },
  readonly,
  showTitle = true,
  type = 'datetime-local',
  startPredefinedValues = { minutes: 0, seconds: 0 },
  endPredefinedValues = { minutes: 59, seconds: 59 },
  step = DISABLE_MINUTES_STEP,
  showInfinity,
}) => {
  const isDateInputInvisible = !dateRange.end && !editMode;

  const startDateTimeValue = useMemo(
    () => (type === 'date' ? dateRange.start : formatDateTimeWithoutSecond(dateRange.start)),
    [dateRange.start, type]
  );

  const endDateTimeValue = useMemo(
    () => (type === 'date' ? dateRange.end || '' : formatDateTimeWithoutSecond(dateRange.end || '')),
    [dateRange.end, type]
  );

  const handleStartChange = useCallback(
    (value: string): void => {
      const parsedValue =
        type === 'date'
          ? getDateWithPredefinedValues(value, startPredefinedValues)
          : getDateTimeWithPredefinedValues(value, startPredefinedValues);

      onChange('start', parsedValue);
    },
    [onChange, type, startPredefinedValues]
  );

  const handleEndChange = useCallback(
    (value: string): void => {
      const parsedValue =
        type === 'date'
          ? getDateWithPredefinedValues(value, endPredefinedValues)
          : getDateTimeWithPredefinedValues(value, endPredefinedValues);

      onChange('end', parsedValue);
    },
    [onChange, type, endPredefinedValues]
  );

  return (
    <div className={block()}>
      <div className={element('row')}>
        <div className={element('wrapper')}>
          {showTitle && <div className={element('title')}>{customTitles.start}</div>}
          <DateInput
            value={startDateTimeValue}
            onChange={handleStartChange}
            disabled={!editMode || readonly}
            isReadOnly={!editMode}
            type={type}
            step={step}
          />
        </div>
        <span className={element('separator', getDateRangeModifier(dateRange, editMode))}>–</span>
        <div className={element('wrapper')}>
          {showTitle && <div className={element('title')}>{customTitles.end}</div>}
          {showInfinity ? (
            <div className={element('infinity')}>∞</div>
          ) : (
            <DateInput
              value={endDateTimeValue}
              onChange={handleEndChange}
              disabled={!editMode || readonly}
              nullable={nullableEndDate}
              isInvisible={isDateInputInvisible}
              isReadOnly={!isDateInputInvisible && !editMode}
              type={type}
              step={step}
            />
          )}
        </div>
      </div>
    </div>
  );
};
