import type { ActiveDayTypeV5, DayPartV5 } from '../../apis/graphql';

function isTimeWithinRange(dayPart: DayPartV5 | null, time?: number | null): boolean {
  const timeToCompare = Number(time);

  const firstActiveHour = Number(dayPart!.firstActiveHour);
  const lastActiveHour = Number(dayPart!.lastActiveHour);

  return timeToCompare >= firstActiveHour && timeToCompare <= lastActiveHour;
}

/**
 * Checks the given DayPart list for overlapping periods with the ActiveDay
 * @param compareDay ActiveDay used to compare against the days in the dayPartList
 * @param dayPartIndex Index position the DayPart that `compareDay` belongs to
 * @param dayPartList List of all days for comparison
 * @returns True if an overlap exists; false if not
 */
function dayHasOverlaps(compareDay: ActiveDayTypeV5, dayPartIndex: number, dayPartList: DayPartV5[] | null): boolean {
  let dayHasOverlap = false;

  dayPartList!.forEach((dayPart: DayPartV5, i: number): void => {
    // Don't compare the dayPart against itself
    if (i === dayPartIndex) return;

    const itemStartsWithinDaypart = isTimeWithinRange(dayPart, dayPartList![dayPartIndex].firstActiveHour);
    const itemEndsWithinDaypart = isTimeWithinRange(dayPart, dayPartList![dayPartIndex].lastActiveHour);
    const dayPartStartsWithinItem = isTimeWithinRange(dayPartList![dayPartIndex], dayPart.firstActiveHour);
    const dayPartEndsWithinItem = isTimeWithinRange(dayPartList![dayPartIndex], dayPart.lastActiveHour);

    const hasTimeOverlap =
      itemStartsWithinDaypart || itemEndsWithinDaypart || dayPartStartsWithinItem || dayPartEndsWithinItem;

    if (!hasTimeOverlap) return;

    dayPart.activeDayList.forEach((activeDay) => {
      if (activeDay === compareDay) dayHasOverlap = true;
    });
  });

  return dayHasOverlap;
}

const INVALID_TIME = 'Invalid start or end time';
const INVALID_DAY_PART = 'At least 1 day must be selected when daypart "Specific Times"';
const INVALID_OVERLAP = 'Daypart cannot have overlapping days and times';

/**
 * Checks the given DayPart array for validity
 * @param dayPartList List of DayPart entries to compare against each other
 * @returns A validation error string, or null if no validation errors
 */
export function validateDayParts(dayPartList: DayPartV5[]): string | null {
  const isDayPartTimeValid = dayPartList.every(
    ({ firstActiveHour, lastActiveHour }): boolean =>
      firstActiveHour != null && lastActiveHour != null && lastActiveHour >= firstActiveHour
  );

  if (!isDayPartTimeValid) return INVALID_TIME;

  const isDayPartActiveDaysValid = dayPartList.every(({ activeDayList }): boolean => activeDayList.length !== 0);

  if (!isDayPartActiveDaysValid) return INVALID_DAY_PART;

  let daysHaveOverlap = false;

  // Examine each ActiveDay in each DayPart and check for overlaps with all other DayParts
  dayPartList.forEach((dayPart: DayPartV5, dayPartIndex: number): void => {
    dayPart.activeDayList.forEach((activeDay: ActiveDayTypeV5): void => {
      if (dayHasOverlaps(activeDay, dayPartIndex, dayPartList)) daysHaveOverlap = true;
    });
  });

  return daysHaveOverlap ? INVALID_OVERLAP : null;
}
