import './InteractiveCreativeBuilder.scss';

import type { ModalProps } from 'antd';
import { useFormikContext } from 'formik';
import React from 'react';

import type { DropdownOption } from '../../../../common/Dropdown';
import Dropdown from '../../../../common/Dropdown';
import { useFieldFast } from '../../../../common/Form/hooks';
import type { BrightlineTemplate, CreativeType, InnovidTemplate } from '../../../../configs';
import { enumToTitleCase } from '../../../../utils/formatting';
import Loader from '../../../Loader/Loader';
import VastPreview from '../../../VastPreview';
import type { AdFormValues, FormFieldsValues } from '../../adFormik';
import { AdFormCreativeFields } from '../../adFormik/fields';
import { isCreativeAssetRemoveForbidden } from '../../utils/creative';
import { AssetIngestionFooter, VastPreviewBody } from '../components';
import CreativeBuilder from '../CreativeBuilder';
import { useCreativeData } from '../hooks/useCreativeData';
import { useFetchCreativeApprovals } from '../hooks/useFetchCreativeApprovals';
import useTagUpload from '../hooks/useTagUpload';
import { CreativeEditMode } from '../types';
import { checkIsVastPreviewAllowed } from '../utils/checkIsVastPreviewAllowed';
type Template = InnovidTemplate | BrightlineTemplate;

// remove when asset ingestion is available for this type of creative
const INGESTION_DISABLED = true;

export interface InteractiveCreativeBuilderProps {
  creativeType: CreativeType;
  templateOptions: Template[];
  isPreview: boolean;
  editMode: CreativeEditMode;
  initialTemplate?: Template;
  className?: string;
}

const convertToDropDownValue = (value: string): DropdownOption<string> => ({
  label: enumToTitleCase(value),
  value,
});

function InteractiveCreativeBuilder({
  creativeType,
  templateOptions,
  isPreview,
  editMode,
}: InteractiveCreativeBuilderProps): JSX.Element {
  const {
    values: { creative },
  } = useFormikContext<AdFormValues>();

  const {
    data: { assets, ingestionJobStatus, successPercent, trialsWithoutAssets },
    loading: loadingVideoCreative,
  } = useCreativeData(creative);

  const tagInputProps = useTagUpload();

  const modalProps: ModalProps = {
    cancelText: 'NO, DO NOT REMOVE',
    okText: 'YES, REMOVE ASSET',
    title: `Remove ${creativeType} Asset`,
  };
  const modalMessage = `Are you sure you want to remove ${creativeType} asset from this ad?`;

  const [, { value: currentTemplate }, { setValue }] = useFieldFast(AdFormCreativeFields.Template);
  const { isSubmitting, errors } = useFormikContext<FormFieldsValues>();

  const isVastPreviewAllowed = checkIsVastPreviewAllowed(creative, !!isPreview);

  const { approvedAdUuids, adUuidsWithApprovedVastUrl, loading: loadingCreativeApprovals } = useFetchCreativeApprovals(
    creative.id
  );

  const dropDownOnChange = (value: DropdownOption | null): void => {
    setValue(value?.value as string);
  };

  const mapTemplateOptionsToDropdownOptions = (templateOptions: Template[]): DropdownOption[] =>
    templateOptions.map((template: Template) => convertToDropDownValue(template));

  if (loadingVideoCreative || loadingCreativeApprovals) return <Loader />;

  return (
    <CreativeBuilder classNameModifier="interactive" isPreview={isPreview} editMode={editMode}>
      <div className="grid-container" data-testid="template">
        <Dropdown
          id="template"
          value={convertToDropDownValue(currentTemplate)}
          emptyDisplayText={enumToTitleCase(currentTemplate || '')}
          label="template"
          secondaryLabel=" (Required)"
          errMsg={errors[AdFormCreativeFields.Template]}
          name={AdFormCreativeFields.Template}
          options={mapTemplateOptionsToDropdownOptions(templateOptions)}
          isSearchable={false}
          isDisabled={!!isSubmitting || editMode === CreativeEditMode.readonly}
          onChange={dropDownOnChange}
        />
        {isVastPreviewAllowed ? (
          <VastPreviewBody
            adUuidsWithApprovedVastUrl={adUuidsWithApprovedVastUrl}
            approvedAdUuids={approvedAdUuids}
            assets={assets}
            creative={creative}
            trialsWithoutAssets={trialsWithoutAssets}
            editMode={editMode}
          />
        ) : (
          <VastPreview
            {...tagInputProps}
            modalProps={modalProps}
            modalMessage={modalMessage}
            errMsg={errors[AdFormCreativeFields.Url]}
            isPreview={isPreview && !!tagInputProps.isSubmitted}
            secondaryLabel=" (Required)"
            disabled={isCreativeAssetRemoveForbidden(editMode, creative)}
          />
        )}
      </div>
      <AssetIngestionFooter
        assets={assets}
        hasIngestError={successPercent !== 1}
        ingestionJobStatus={ingestionJobStatus}
        readonly={editMode !== CreativeEditMode.full || INGESTION_DISABLED}
      />
    </CreativeBuilder>
  );
}

export default InteractiveCreativeBuilder;
