import update from 'immutability-helper';
import { isEqual } from 'lodash';

import type { GetRotationsListQuery, Rotation } from '../../apis/graphql';
import { campaignClient } from '../../apis/graphql';
import type { Nullable } from '../../models';
import { GET_ROTATIONS_LIST } from '../../pages/Ads/hooks/queries/getRotations';
import { AlertType } from '../Alert';
import { openToastAlert } from '../ToastAlert/toastAlert';
import { RotationErrorCodes } from './enums';
import type { AdListData, AdRotation, Rotation as RotationModel } from './types';

export const AD_ROTATION_CONFLICTS_ERROR = 'Cannot add Ad with conflicting schedule or targeting to rotation group.';

export const updateAdRotationCache = (rotations: Rotation[]): void => {
  rotations.forEach((rotation) => {
    const { rotationId, name: rotationName, adRotationList } = rotation;

    adRotationList.forEach((adRotation) => {
      const { adId, weight } = adRotation;

      campaignClient.cache.modify({
        id: `AdV5:${adId}`,
        fields: {
          adRotation: () => ({ __typeName: 'AdRotation', weight, adId, rotationName, rotationId }),
        },
      });
    });
  });
};

export const getUpdatedRotations = (
  rotation: Rotation,
  lineItemId: string,
  rotationId: string
): Nullable<Rotation[]> => {
  const data = campaignClient.readQuery<GetRotationsListQuery>({
    query: GET_ROTATIONS_LIST,
    variables: {
      lineItemId,
    },
  });

  if (!data?.getRotationsV5) return null;

  const updatedRotationIndex = data.getRotationsV5.findIndex((rotation) => rotation.rotationId === rotationId);

  if (updatedRotationIndex < 0) return update(data.getRotationsV5, { $push: [rotation] });

  return update(data.getRotationsV5, { [updatedRotationIndex]: { $set: rotation } });
};

export const updateRotationsCache = (rotations: Rotation[], lineItemId: string): void =>
  campaignClient.writeQuery({
    query: GET_ROTATIONS_LIST,
    variables: {
      lineItemId,
    },
    data: { getRotationsV5: rotations },
    broadcast: true,
  });

export const onUpdateRotationsFailed = (error?: Error): void => {
  const isHasSequence = (error?.message as string).includes(RotationErrorCodes.AD_HAS_SEQUENCE);

  const errorMsg = isHasSequence ? 'Ad can not have both sequence and weight' : 'Error saving rotation';

  openToastAlert({ alertType: AlertType.ERROR, message: errorMsg });
};

export const updateEmptyAdRotationCache = (adListData: AdListData): void =>
  adListData.rotations.forEach((rotation) => {
    const { adsList } = rotation;

    adsList.forEach((ad) => {
      const { adId } = ad;

      campaignClient.cache.modify({
        id: `AdV5:${adId}`,
        fields: {
          adRotation: () => null,
        },
      });
    });
  });

// Rotations should have exact the same start/end dates, dayParts and targetingTerm
export const getValidateRotationsErrors = (rotationToAdd?: AdRotation, rotations: AdRotation[] = []): string | null => {
  let error = null;

  if (!rotationToAdd) return error;

  const { dayPartList, startDate, endDate, targetingTermValues } = rotationToAdd;
  const rotationToAddFieldsToCompare = {
    dayPartList,
    startDate,
    endDate,
    targetingTermValues,
  };

  // If there is no rotations to compare, there are no errors
  if (!rotations.length) return error;

  for (const rotation of rotations) {
    const { dayPartList, startDate, endDate, targetingTermValues } = rotation;
    const fieldsToCompare = {
      dayPartList,
      startDate,
      endDate,
      targetingTermValues,
    };

    if (!isEqual(rotationToAddFieldsToCompare, fieldsToCompare)) {
      error = AD_ROTATION_CONFLICTS_ERROR;

      break;
    }
  }

  return error;
};

export const getRotationsWithResetErrorMessages = (rotations: RotationModel[]): RotationModel[] => {
  return rotations.map((rotation) => ({
    ...rotation,
    errorMessage: rotation.hasError ? rotation.errorMessage : null,
  }));
};
