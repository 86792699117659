import './TraffickingPageDrawer.scss';

import React from 'react';

import bem from '../../../utils/bem';
import type { TraffickingPageDrawerData } from '../TraffickingPage/hooks/makePageDrawerPlugin';
import { DrawerType } from '../TraffickingPage/hooks/makePageDrawerPlugin';
import EntityDetailsDrawer from './EntityDetailsDrawer';
import MultipleEditDrawerWrapper from './MultipleEditDrawer/MultipleDrawerWrapper/MultipleEditDrawerWrapper/MultipleEditDrawerWrapper';
export interface TraffickingPageDrawerProps {
  data?: TraffickingPageDrawerData;
  isOpen: boolean;
  onClose: () => void;
  isExpanded: boolean;
  onToggleExpansion: () => void;
}

const [block] = bem('trafficking-page-drawer');

function TraffickingPageDrawer({
  data,
  isOpen,
  onClose,
  isExpanded,
  onToggleExpansion,
}: TraffickingPageDrawerProps): JSX.Element {
  const expandedClass = isExpanded ? `expanded` : '';

  switch (data?.drawerType) {
    case DrawerType.ENTITY_DETAILS:
      return (
        <EntityDetailsDrawer
          data={data}
          className={block(expandedClass)}
          onClose={onClose}
          isOpen={isOpen}
          isExpanded={isExpanded}
          onToggleExpansion={onToggleExpansion}
        />
      );
    case DrawerType.MULTIPLE_EDIT:
      return (
        <MultipleEditDrawerWrapper
          data={data}
          className={block(expandedClass)}
          onClose={onClose}
          isOpen={isOpen}
          isExpanded={isExpanded}
          onToggleExpansion={onToggleExpansion}
        />
      );
    default:
      return <></>;
  }
}

export default TraffickingPageDrawer;
