import { Modal } from 'antd';
import React, { memo, useState } from 'react';

import IconPackage from '../../../../../common/icons/IconPackage';
import InventoryBlockForm from '../../../../../common/InventoryBlockForm';
import type { ExclusiveBlock } from '../../../../../models/exclusiveBlock';

type Props = {
  exclusiveBlock?: ExclusiveBlock | null;
};

const InventoryBlockCell = ({ exclusiveBlock }: Props): React.JSX.Element | null => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onIconClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
    e.preventDefault();
    e.stopPropagation();

    setIsModalOpen(true);
  };

  const onCancelClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    e.preventDefault();
    e.stopPropagation();

    setIsModalOpen(false);
  };

  return exclusiveBlock ? (
    <div
      onClick={(e): void => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <span onClick={onIconClick}>
        <IconPackage />
      </span>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          footer={null}
          onCancel={onCancelClick}
          width={800}
          data-testid="inventory-block-modal"
        >
          <InventoryBlockForm exclusiveBlock={exclusiveBlock} />
        </Modal>
      )}
    </div>
  ) : null;
};

export const comparison = (prev: Props, next: Props): boolean => prev.exclusiveBlock?.id === next.exclusiveBlock?.id;

export default memo(InventoryBlockCell, comparison);
