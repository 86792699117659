import {
  lowerCase as _lowerCase,
  map as _map,
  tail as _tail,
  upperFirst as _upperFirst,
  words as _words,
} from 'lodash';

export const formattedKey = (str: string): string => {
  const words = _words(str);
  const capitalizedFirstWord = _upperFirst(words[0]);
  const formattedWords = [capitalizedFirstWord, ..._map(_tail(words), _lowerCase)];

  return formattedWords.join(' ');
};
