import './ReviewStatusModal.scss';

import { Modal } from 'antd';
//import { title } from 'process';
import React from 'react';

import type { AssetRejectionReasonV5, BaseAssetV5, BaseCreativeV5 } from '../../apis/graphql';
import type { AssetRejectionDisplayValues } from '../../configs';
import { assetRejectionReasonMap } from '../../configs';
import bem from '../../utils/bem';
import { enumToTitleCase } from '../../utils/formatting';
import Alert, { AlertType } from '../Alert';
import Button from '../Button';
import { ToggleSection } from '../ToggleSection';
import { useStatusModal } from './hooks';

interface RejectionReasonToggleProps {
  name: string;
  rejectionReasons: string | AssetRejectionReasonV5[];
  status?: string;
  toggles: boolean[];
  toggleAt: (index: number, value: boolean) => void;
  reasonIndex?: number;
  index?: number;
}

function RejectionReasonToggle({
  rejectionReasons,
  toggles,
  toggleAt,
  index = 0,
}: RejectionReasonToggleProps): JSX.Element {
  const entries = Object.entries(assetRejectionReasonMap);
  const filteredRejectionReasons: [string, AssetRejectionDisplayValues][] = entries.filter(([key]) =>
    rejectionReasons.includes(key as AssetRejectionReasonV5)
  );

  if (Object.keys(filteredRejectionReasons).length === 0) {
    return (
      <ToggleSection
        handleToggle={(): void => toggleAt(index, toggles[index])}
        isExpanded={toggles[index] === undefined ? true : toggles[index]}
        key={`toggle-${index}`}
        title={'No Rejection Explanation Provided'}
      >
        <div className={modalElement('issue-description')}>Service provided no rejection reason.</div>
      </ToggleSection>
    );
  } else {
    return (
      <>
        {filteredRejectionReasons.map(([, value]: [string, AssetRejectionDisplayValues], reasonIndex: number) => (
          <ToggleSection
            handleToggle={(): void => toggleAt(reasonIndex, toggles[reasonIndex])}
            isExpanded={toggles[reasonIndex] === undefined ? true : toggles[reasonIndex]}
            key={`toggle-${reasonIndex}`}
            title={enumToTitleCase(value.title.replace(/_/g, ' '))}
          >
            <div className={modalElement('issue-description')}>{value.description}</div>
          </ToggleSection>
        ))}
      </>
    );
  }
}

export interface ReviewStatusModalProps {
  asset?: BaseAssetV5;
  customActivator?: string | React.ReactNode;
  status?: string;
  rejectionReasons?: string | string[];
  name?: string;
}

export const alphabetize = (
  { name: fieldA }: Pick<BaseCreativeV5, 'name'>,
  { name: fieldB }: Pick<BaseCreativeV5, 'name'>
): number => {
  return fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
};

const [block, element] = bem('review-status-container');
const [modalBlock, modalElement] = bem('review-status-modal');

/**
 * Status Modal including a Rejection Reason toggle list
 * TODO: Separate review-specific logic & reuse generic StatusModal component
 */
export function ReviewStatusModal({ asset, customActivator, status }: ReviewStatusModalProps): JSX.Element {
  const { closeModal, openModal, toggles, toggleAt, visible } = useStatusModal(asset);
  const hasDetails = Array.isArray(asset) ? !!asset.length : asset?.name || '';
  const creativeList = Array.isArray(asset) ? asset : asset ? [asset] : null;

  return (
    <div className={block()}>
      {customActivator ? (
        <span className={element('activator')} onClick={openModal}>
          {customActivator}
        </span>
      ) : (
        <Alert
          type={AlertType.ERROR}
          bodyText={
            <>
              <svg
                className={element('error-svg')}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                  fill="#DE1B4B"
                />
                <path
                  d="M7.70711 6.29289C7.31658 5.90237 6.68342 5.90237 6.29289 6.29289C5.90237 6.68342 5.90237 7.31658 6.29289 7.70711L8.58579 10L6.29289 12.2929C5.90237 12.6834 5.90237 13.3166 6.29289 13.7071C6.68342 14.0976 7.31658 14.0976 7.70711 13.7071L10 11.4142L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L11.4142 10L13.7071 7.70711C14.0976 7.31658 14.0976 6.68342 13.7071 6.29289C13.3166 5.90237 12.6834 5.90237 12.2929 6.29289L10 8.58579L7.70711 6.29289Z"
                  fill="#DE1B4B"
                />
              </svg>
              This creative has been rejected by QC.{' '}
              {hasDetails && (
                <span onClick={openModal}>
                  See reason
                  {asset?.rejectionReasons?.length !== undefined && asset?.rejectionReasons?.length > 1 ? 's' : ''}
                </span>
              )}
            </>
          }
          onDismiss={undefined}
        />
      )}
      {visible && (
        <Modal
          onCancel={closeModal}
          visible={visible}
          title="Reasons for Rejection"
          className={modalBlock()}
          footer={
            <Button key="submit" aria-label="close" tabIndex={0} onClick={closeModal}>
              Close
            </Button>
          }
        >
          Please address the below issues and re-submit creative for review.
          {creativeList &&
            creativeList.sort(alphabetize).map(
              (
                { rejectionReasons, name }: Pick<BaseAssetV5, 'name' | 'rejectionReasons'>,
                reasonIndex: number
              ): JSX.Element => (
                <>
                  <p className={modalElement('asset-name')}>{name}</p>
                  <RejectionReasonToggle
                    key={`rejection-reason-${reasonIndex}`}
                    name={name}
                    reasonIndex={reasonIndex}
                    rejectionReasons={rejectionReasons || ''}
                    status={status}
                    toggleAt={toggleAt}
                    toggles={toggles}
                  />
                </>
              )
            )}
        </Modal>
      )}
    </div>
  );
}
