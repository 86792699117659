import { User } from 'mission-control-common-components';
import React, { memo, useCallback } from 'react';
import type { Cell } from 'react-table';

import type { FrequencyCapInputV5 } from '../../../../../apis/graphql';
import EditableFrequencyCap from '../../../../../common/EditableTableCells/EditableFrequencyCap';
import type { PermissionsNames } from '../../../../../constants';
import { cellPropsComparison } from '../../../../../utils/common';
import { getIdFromTableCell } from '../../columns';
import { useUpdateAd } from '../../hooks';
import type { AdModel } from '../../modelConverters';

type Props = Cell<AdModel, AdModel['frequencyCapList']>;

function AdFrequencyCapCell(cell: Props): JSX.Element {
  const permissions = User.getPermissions<PermissionsNames>();
  const { value: frequencyCaps } = cell;

  const id = getIdFromTableCell(cell);
  const { cellVariant, updateAdFields } = useUpdateAd<'frequencyCapList'>(id);

  const updateField = useCallback(
    (frequencyCapList: FrequencyCapInputV5[]): void => {
      updateAdFields({ frequencyCapList });
    },
    [updateAdFields]
  );

  return (
    <EditableFrequencyCap
      initialFrequencyCaps={frequencyCaps}
      updateField={updateField}
      variant={cellVariant}
      defaultFrequencyCapType="AD"
      isDisabled={!permissions?.updateAd}
    />
  );
}

export default memo(AdFrequencyCapCell, cellPropsComparison);
