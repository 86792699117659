import '../CellItems.scss';
import '../../../../../../models/secondaryPace';

import React from 'react';

import type { SecondaryPace } from '../../../../../../models';
import bem from '../../../../../../utils/bem';
import { GreenPlus } from '../../../../../assets';
import type { CellItemsProps } from '../index';

const [block, element] = bem('multiple-change-log-cell');

const formatSecondaryPace = ({ percent, until }: SecondaryPace): string => {
  return `${percent * 100}% until ${until}`;
};

export function SecondaryPaceCell({ changes, action }: CellItemsProps): JSX.Element {
  const removalClass = action.removal ? 'removal' : '';
  if (changes && Array.isArray(changes)) {
    return (
      <div className={block()}>
        {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
        <span className={element('secondary-pace', removalClass)}>
          {changes.map(({ payload }, index) => {
            return payload ? (
              <ul key={index}>
                <li>{payload.map(formatSecondaryPace).join(', ')}</li>
              </ul>
            ) : (
              <span>-</span>
            );
          })}
        </span>
      </div>
    );
  }

  return changes.payload && changes.payload.length > 0 ? (
    <div className={block()}>
      {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
      <span className={element('secondary-pace', removalClass)}>
        {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
        {changes.payload.map(formatSecondaryPace).join(', ')}
      </span>
    </div>
  ) : (
    <span>-</span>
  );
}

export default SecondaryPaceCell;
