import React from 'react';

import { Error } from '../../../components/Error/Error';

type ErrorProps = {
  message: string;
  conflictIds?: string[];
  isShown?: boolean;
};

export const getConflictComponents = (conflicts: ErrorProps[]): React.JSX.Element[] => {
  return conflicts
    .filter(({ isShown, conflictIds }) => isShown && conflictIds && conflictIds.length > 0)
    .map(({ message, conflictIds }) => <Error key={message} conflictIds={conflictIds} conflictMessage={message} />);
};
