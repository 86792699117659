import { DimensionsToDisable } from '../../../../common/TargetingDropdowns/constants';
import { filterTermValues } from '../../../../common/utils';
import type { TargetingTermValue, TargetingValue } from '../../../../models';
import type { DrawerTargetingFormValues } from '../hooks/types/formValues';
import type { DrawerTargetingTableResult, QueryResult } from '../hooks/types/results';

type TargetingTableDataType = { groupType: string; targetingTermValues: TargetingTermValue[] }[];
export type TargetingTableResult = Omit<DrawerTargetingTableResult, 'isFetching' | 'isUpdating' | 'submitForm'>;

const dimensionName = 'country';
const defaultCountry = 'United States';

export const generateTargetingTableData = (
  adTermValues: TargetingTermValue[],
  lineItemTermValues: TargetingTermValue[]
): TargetingTableDataType => {
  return [
    {
      groupType: 'Ad Targeting',
      targetingTermValues: adTermValues,
    },
    {
      groupType: 'Line Item Targeting',
      targetingTermValues: lineItemTermValues,
    },
  ];
};

export type ValuesForMutationType = {
  targetingTermValues: {
    dimensionName: string;
    include: boolean;
    valueName: string;
  }[];
};

export const convertValuesForMutation = (newValue: TargetingTermValue[]): ValuesForMutationType => {
  const convertedValue = newValue.map(({ value, include, dimension }) => {
    return {
      dimensionName: dimension.id,
      include: include,
      valueName: value.id,
    };
  });

  return {
    targetingTermValues: convertedValue,
  };
};

// Ads have their own targeting values, and may also have line item targeting values
export const getAdTerms = (data?: QueryResult): TargetingTableResult => {
  const adNode = data?.adsV5.edges[0]?.node;

  return {
    adTermValues: filterTermValues(adNode?.targetingTermValues),
    lineItemTermValues: filterTermValues(adNode?.lineItem?.targetingTermValues),
    displayPublisherTarget: adNode?.displayPublisherTarget || [],
    targetingRule: adNode?.targetingRule || null,
  };
};

// Line Items only have their own targeting values
export const getLineItemTerms = (data?: QueryResult): TargetingTableResult => {
  const lineItemNode = data?.lineItemsV5.edges[0]?.node;

  return {
    adTermValues: [],
    lineItemTermValues: filterTermValues(lineItemNode?.targetingTermValues),
    displayPublisherTarget: lineItemNode?.displayPublisherTarget || [],
    targetingRule: lineItemNode?.targetingRule || null,
  };
};

export function getCountryTargetName({ value }: { value: TargetingTermValue[] }): string {
  const country = value
    ?.filter((item) => item.dimension.name.toLowerCase() === dimensionName)
    .map((item) => item.value.name);
  return country && country.length > 0 ? country[0] : defaultCountry;
}

export function filterOutCountryTarget({ value }: { value: TargetingTermValue[] }): TargetingTermValue[] {
  return value?.filter((item) => item.dimension.name.toLowerCase() !== dimensionName);
}

export function getCountryTargetLineItem({ value }: { value: TargetingTermValue[] }): TargetingTermValue | null {
  const country = value?.filter((item) => item.dimension.name.toLowerCase() === dimensionName);
  return country && country.length > 0 ? country[0] : null;
}

export function getPublisherTargetLineItem(publishers: string[]): TargetingValue[] | null {
  return publishers.map((publisher) => ({
    id: publisher,
    displayName: publisher,
    name: publisher,
  }));
}

export function addCountryTargetLineItem(
  formValues: DrawerTargetingFormValues,
  countryValue: TargetingTermValue | null
): DrawerTargetingFormValues {
  if (countryValue) {
    formValues.lineItemTargetingTermValues.push(countryValue);
  }
  return formValues;
}

export function addCountryCode(
  countryLineItem: TargetingTermValue | null,
  countryCode: string | undefined
): TargetingTermValue | null {
  if (countryLineItem && !countryLineItem?.value.id && countryCode) {
    let value = countryLineItem.value;

    if (value && !value.id) {
      countryLineItem = {
        ...countryLineItem,
        value: {
          ...value,
          id: countryCode,
        },
      };
    }
  }
  return countryLineItem;
}

// disables targeting dropdown option and editing in targeting table
export function disableByDimension(dimension: string, editMode?: boolean): boolean {
  for (let d of DimensionsToDisable) {
    if (d === dimension) {
      if (editMode !== undefined) {
        return false;
      } else {
        return true;
      }
    }
  }

  return editMode ? editMode : false;
}
