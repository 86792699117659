import type { Location } from 'history';
import type { Row } from 'react-table';

import type { AdsPageLineItem } from '../../../../../common/AdForm/types';
import type { AdProduct, AdType, Config, DisplayEnum } from '../../../../../configs';
import { getPermittedAdTypes } from '../../../../../configs';
import { PathName } from '../../../../../constants';
import type { AdsPageProps } from '../../../../Ads/AdsPage';
import type { AdModel, LineItemModel } from '../../modelConverters';
import type { LineItemWithTargetingsModel } from '../../modelConverters/lineItemWithTargetingsConvertor';
import { CellType } from '../../types';

const getPermittedAds = (adProduct: DisplayEnum<AdProduct>): Config<AdType>[] => {
  return adProduct && adProduct.key !== 'UNRECOGNIZED' ? getPermittedAdTypes(adProduct?.key ?? adProduct) : [];
};

export const isNavigateAllowed = (selectedFlatRow: Row<LineItemModel> | Row<AdModel>): boolean => {
  const { original } = selectedFlatRow;
  const { adProduct } = (original as AdModel).lineItem ?? (original as LineItemModel);
  const permittedAdTypes = getPermittedAds(adProduct);

  return !!permittedAdTypes.length;
};

export const getLocation = (lineItem: LineItemWithTargetingsModel, cellType: CellType): Location => {
  const {
    id,
    name,
    campaign,
    adProduct,
    startDate,
    endDate,
    schedule,
    displayPublisherTarget,
    billableThirdParty,
    isCoppaOrCaru,
    draxDeal,
    parentLineOmsLink,
    targetingTermValues,
    targetingRule,
  } = lineItem;
  const permittedAdTypes = getPermittedAds(adProduct);

  const adsPageLineItem: AdsPageLineItem = {
    id,
    name,
    campaign,
    startDate,
    endDate,
    schedule,
    displayPublisherTarget,
    billableThirdParty,
    isCoppaOrCaru,
    draxDeal,
    targetingTermValues,
    targetingRule,
    parentLineOmsLink,
  };

  const adsPageProps: AdsPageProps = {
    lineItem: adsPageLineItem,
    permittedAdTypes,
  };

  const pathname = cellType === CellType.edit ? PathName.adsEdit : PathName.adsCreate;

  return {
    pathname,
    state: adsPageProps,
    key: '',
    search: '',
    hash: '',
  };
};
