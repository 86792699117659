import type { SequenceActions } from './actions';
import { SequenceAction } from './actions';
import * as reducers from './reducers';
import type { SequenceState } from './state';

export function sequenceReducer(state: SequenceState, action: SequenceActions): SequenceState {
  switch (action.type) {
    case SequenceAction.SET_SEARCH_UNSEQUENCED_ADS_TERM:
      return reducers.setSearchUnsequencedAdsTerm(state, action.payload);

    case SequenceAction.SET_SEQUENCED_ADS_ORDER:
      return reducers.setSequenceAdsOrder(state, action.payload);

    default:
      return state;
  }
}
