import type { LineItemList, Nullable } from '../../../../../models';

export const getUniqueCountriesString = (lineItemList?: Nullable<LineItemList[]>): string => {
  const countryList = new Set<string>();

  lineItemList?.forEach((lineItem) => {
    if (lineItem?.country) {
      countryList.add(lineItem.country);
    }
  });

  return Array.from(countryList).join(', ');
};
