import { useLocation } from '@hulu/react-router-dom';
import { useMemo } from 'react';

import type { TargetingRuleInput } from '../../../apis/graphql';
import { AdTypeV5 } from '../../../apis/graphql';
import { removeTypeNamesFromObject } from '../../../apis/graphql';
import type { AdFormValues } from '../../../common/AdForm/adFormik';
import { getInitialValues } from '../../../common/AdForm/adFormik';
import type { AdsPageAd } from '../../../common/AdForm/hooks/types';
import type { AdsPageLineItem } from '../../../common/AdForm/types';
import type { NielsenTagProps } from '../../../common/AdForm/utils';
import type { TargetingTermValue } from '../../../models';
import type { LocationState } from '../AdsPageController';
import type { FormSubmit } from './types';
import useFetchSavedAds from './useFetchSavedAds';
import useFormSubmit from './useFormSubmit/useFormSubmit';
import { useSaveAd } from './useSaveAd';

export type UseAdForm = {
  ads: AdsPageAd[];
  lineItemTargetingTermValues: TargetingTermValue[];
  lineItems: AdsPageLineItem[];
  errors: {
    fetchAdsError?: string;
  };
  initialValues: AdFormValues;
  loading: boolean;
  submitForm: FormSubmit;
  selectedAd: AdsPageAd;
};

function useAdForm(lineItem: AdsPageLineItem, adType: AdTypeV5): UseAdForm {
  const { ads, lineItemTargetingTermValues, lineItems, loading, error: fetchAdsError } = useFetchSavedAds(lineItem.id);
  const rule = lineItem?.targetingRule
    ? (removeTypeNamesFromObject(lineItem.targetingRule) as TargetingRuleInput)
    : null;
  const saveAd = useSaveAd(lineItem, ads, rule);
  const submitForm = useFormSubmit(saveAd, lineItem);

  const locationState = useLocation().state as LocationState;
  const selectedAd: AdsPageAd = ads.filter((ad) => ad.id === locationState?.editAdId)[0];

  const creativeLibraryId = useMemo(() => {
    return selectedAd ? selectedAd.creative?.creativeLibraryId : null;
  }, [selectedAd]);

  const adTagList = useMemo(() => {
    return selectedAd ? selectedAd.adTagList : [];
  }, [selectedAd]);

  const initialValues: AdFormValues = useMemo(() => {
    const nielsenTagProps: NielsenTagProps = {
      campaignTagList: lineItem.campaign?.campaignTagList || [],
      lineItem_billableThirdParty: lineItem.billableThirdParty,
      lineItem_isCoppaOrCaru: lineItem.isCoppaOrCaru,
      lineItem_country: lineItem.targetingTermValues?.find((term) => term.dimension?.id === 'country')?.value?.id || '',
      isPausedAd: adType === AdTypeV5.Pause,
    };

    return getInitialValues({
      type: adType,
      nielsenTagProps,
      adTagList: adTagList,
      lineItem,
      creativeLibraryId,
    });
  }, [adType, lineItem, creativeLibraryId, adTagList]);

  return {
    ads,
    lineItemTargetingTermValues,
    lineItems,
    errors: {
      fetchAdsError: fetchAdsError?.message,
    },
    initialValues,
    loading,
    submitForm,
    selectedAd,
  };
}

export default useAdForm;
