import { Alert } from 'antd';
import React, { useMemo } from 'react';

import type { DateRangeV5, DayPartV5 } from '../../../../../../../../../../apis/graphql/types';
import { ActiveDayTypeV5 } from '../../../../../../../../../../apis/graphql/types';
import type { DayPartCellTimeRanges } from '../../../../../../../../../../common/ChangeLog/ChangeLogTable/Cell/CellItems';
import { formatMultiplyDateRanges } from '../../../../../../../../../../common/DatesInfo/utils';
import type { DayPartTimeRanges } from '../../../../../../../../../../common/TimesList/types';
import { viewerLocalTimeLabel } from '../../../../../../../../../../configs';
import { formatTimeRanges, formatTruncatedDay } from '../../../../../../../../../../utils/formatting';
import { element } from '../../../components/Row/Row';

type Props = {
  dateRangeList: DateRangeV5[];
  dayPartList: DayPartV5[];
  timezone: string;
  isShown?: boolean;
  deliveryInViewerTime: boolean;
};

const DayPartChanges = ({
  dayPartTimeRanges,
  noRangeMessage,
}: {
  dayPartTimeRanges: DayPartCellTimeRanges[];
  noRangeMessage: string;
}): React.JSX.Element => {
  return (
    <div>
      {dayPartTimeRanges.map(({ day, ranges }) => (
        <div key={day} data-testid={`daypart-${day}`} className={element('daypart-wrapper')}>
          <div className={element('day')}> {formatTruncatedDay(day)}</div>
          <div className={element('dayparts')}>{formatTimeRanges({ ranges, noRangeMessage })}</div>
        </div>
      ))}
    </div>
  );
};

const SchedulingRow = ({
  dateRangeList,
  timezone,
  dayPartList,
  isShown,
  deliveryInViewerTime,
}: Props): React.JSX.Element => {
  const scheduleMessage = useMemo(
    () => (
      <div data-testid="schedule-title" className={element('value-title')}>{`Scheduled Times (${
        deliveryInViewerTime ? viewerLocalTimeLabel : timezone
      }):`}</div>
    ),
    [deliveryInViewerTime, timezone]
  );

  const dayPartTimeRanges: DayPartCellTimeRanges[] = useMemo(() => {
    return Object.values(ActiveDayTypeV5).map((day) => {
      const ranges: DayPartTimeRanges = [];

      dayPartList.forEach(({ activeDayList, firstActiveHour, lastActiveHour }) => {
        if (activeDayList.includes(day) && firstActiveHour !== null && lastActiveHour !== null) {
          ranges.push([firstActiveHour, lastActiveHour]);
        }
      });

      return {
        day,
        ranges: ranges,
      };
    });
  }, [dayPartList]);

  if (!isShown) {
    return <div>-</div>;
  }

  return (
    <div>
      {dateRangeList.length > 0 && dateRangeList[0].startDate && (
        <div className={element('schedule-dateranges')} data-testid="schedule-dateranges">
          {dateRangeList.map((dateRange) => formatMultiplyDateRanges(dateRange, ' to ')).join(', ')}
        </div>
      )}
      <Alert
        data-testid="schedule-alert"
        className={element('info-alert')}
        type="info"
        showIcon={true}
        message={scheduleMessage}
        description={
          <DayPartChanges
            dayPartTimeRanges={dayPartTimeRanges}
            noRangeMessage={dayPartList.length ? 'Dark' : 'All day'}
          />
        }
      />
    </div>
  );
};

export default SchedulingRow;
