import type { CheckboxProps } from 'mission-control-common-components/src/components/atoms/ControlledCheckbox/ControlledCheckbox';
import type { ChangeEvent } from 'react';
import { useCallback, useMemo } from 'react';
import React from 'react';
import type { IdType, Row } from 'react-table';

import type { EntityModel } from '../../pages/Trafficking/TraffickingPage/modelConverters';
import ControlledCheckbox from '../ControlledCheckbox';

export type HeaderProps<T extends object> = {
  getToggleAllRowsSelectedProps(): CheckboxProps;
  toggleAllRowsSelected(value?: boolean): void;
  toggleRowSelected: (rowId: IdType<EntityModel>, set?: boolean | undefined) => void;
  rows: Row<T>[];
  isGrouped: boolean;
  onKeyDownHandler: (callback: () => void) => (e: React.KeyboardEvent<Element> | ChangeEvent<HTMLInputElement>) => void;
};

const handleBulkRowToggle = (isRowsSelected: boolean, onToggleHandler: (value?: boolean) => void): void => {
  isRowsSelected ? onToggleHandler(false) : onToggleHandler();
};

const CustomSelectAllCheckbox = <T extends object>({
  getToggleAllRowsSelectedProps,
  toggleAllRowsSelected,
  toggleRowSelected,
  rows,
  isGrouped,
  onKeyDownHandler,
}: HeaderProps<T>): React.JSX.Element => {
  const { checked, indeterminate, ...checkBoxProps } = getToggleAllRowsSelectedProps();

  const isChecked = useMemo(() => {
    return isGrouped ? rows.every(({ isSelected }) => !!isSelected) : checked;
  }, [checked, isGrouped, rows]);

  const isSomeRowsSelected = useMemo(() => rows.some(({ isSelected }) => !!isSelected), [rows]);

  const isIndeterminate = useMemo(() => {
    const customIndeterminate = isChecked ? false : isSomeRowsSelected;

    return isGrouped ? customIndeterminate : indeterminate;
  }, [indeterminate, isChecked, isGrouped, isSomeRowsSelected]);

  const selectConcreteRows = useCallback(() => {
    rows.forEach(({ id }) => {
      toggleRowSelected(id, !isSomeRowsSelected);
    });
  }, [isSomeRowsSelected, rows, toggleRowSelected]);

  const getEventHandler = useCallback(
    (isKeyDown: boolean) => {
      const handler = isKeyDown ? onKeyDownHandler : (callback: () => void): (() => void) => callback;

      if (isGrouped) {
        return handler(selectConcreteRows);
      } else {
        return handler(() => handleBulkRowToggle(!!isIndeterminate || !!isChecked, toggleAllRowsSelected));
      }
    },
    [isChecked, isGrouped, isIndeterminate, onKeyDownHandler, selectConcreteRows, toggleAllRowsSelected]
  );

  return (
    <ControlledCheckbox
      {...checkBoxProps}
      checked={isChecked}
      indeterminate={isIndeterminate}
      disabled={rows.length === 0}
      onKeyDown={getEventHandler(true)}
      onChange={getEventHandler(false)}
    />
  );
};

export default CustomSelectAllCheckbox;
