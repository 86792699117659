import '../CellItems.scss';

import React from 'react';

import bem from '../../../../../../utils/bem';
import { formatTimeRanges, formatTruncatedDay } from '../../../../../../utils/formatting';
import { GreenPlus } from '../../../../../assets';
import type { DayPartTimeRanges } from '../../../../../TimesList/types';
import type { CellItemsProps } from '../index';

const [block, element] = bem('multiple-change-log-cell');

export type DayPartCellTimeRanges = {
  day: string;
  ranges: DayPartTimeRanges;
};

interface DaypartListProps {
  list: DayPartCellTimeRanges[];
}

function DayPartList({ list }: DaypartListProps): JSX.Element {
  return (
    <ul className={element('daypart')}>
      {list.map((dayRange, index) => {
        return (
          <li key={`daypart-item-${index}`}>
            <span>
              <b>{formatTruncatedDay(dayRange.day)}</b>
              {formatTimeRanges({ ranges: dayRange.ranges })}
            </span>
          </li>
        );
      })}
    </ul>
  );
}

function DaypartCell({ changes, action }: CellItemsProps): JSX.Element {
  if (Array.isArray(changes)) return <span>-</span>;

  const removalClass = action.removal ? 'removal' : '';

  const allRangesEmpty =
    !changes.payload.length || changes.payload.every((payload: DayPartCellTimeRanges) => !payload.ranges.length);

  return (
    <div className={block(removalClass)}>
      {action.addition && !allRangesEmpty && (
        <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />
      )}
      {Array.isArray(changes.payload) && changes.payload.length ? (
        <DayPartList list={changes.payload} />
      ) : (
        <span>-</span>
      )}
    </div>
  );
}

export default DaypartCell;
