import React from 'react';

import { COLUMN_NAME_ID } from '../../../constants';
import type { CellWithCustomColumnConfig } from '../../../pages/Trafficking/TraffickingPage/columns';
import type { EntityModel } from '../../../pages/Trafficking/TraffickingPage/modelConverters';
import CellElement from '../CellElement';

type Props = {
  cells: CellWithCustomColumnConfig<EntityModel>[];
  selectedColIndex: number;
  selectedRowIndex: number;
};

const TraffickingTableCells = ({ cells, selectedColIndex, selectedRowIndex }: Props): JSX.Element => (
  <>
    {cells.map((cell: CellWithCustomColumnConfig<EntityModel>, colIndex: number) => {
      const { id, isEditable, className, maxWidth } = cell.column;
      const isPossibleToSelectRow = id !== COLUMN_NAME_ID && !isEditable;
      const cellKey = cell.getCellProps().key;

      return (
        <CellElement
          key={cellKey}
          colIndex={colIndex}
          selectedColIndex={selectedColIndex}
          selectedRowIndex={selectedRowIndex}
          isEditable={isEditable}
          rowIndex={cell.row.index}
          className={className}
          maxWidth={maxWidth}
          getCellProps={cell.getCellProps}
          render={cell.render}
          isPossibleToSelectRow={isPossibleToSelectRow}
        />
      );
    })}
  </>
);

export default TraffickingTableCells;
