import styled from 'styled-components';

import { theme } from '../../../../styles/styled';

export const FileDetails = styled.div`
  display: flex;
  margin-top: 0.75em;
  font-size: 0.9em;

  color: ${theme.color.design.gray2};

  span + span::before {
    content: '•';
    padding: 0.5em;
  }
`;
