import './DuplicateLineItemsPicker.scss';

import { SearchInput } from '@hulu/react-style-components';
import { ErrorMessage } from 'formik';
import React, { memo, useCallback, useState } from 'react';

import type { AdType, DisplayEnum } from '../../../../../../configs';
import { FieldName } from '../../enums';
import type { UseDuplicateLineItemsState } from '../../hooks/useDuplicateLineItemsState/useDuplicateLineItemsState';
import { pickerBlock, pickerElement } from './constants';
import DuplicateLineItemsSavedTable from './DuplicateLineItemsSavedTable';
import DuplicateLineItemsSearchTable from './DuplicateLineItemsSearchTable';

export type Props = {
  adLineItemId: string;
  adName: string;
  adType: DisplayEnum<AdType>;
} & UseDuplicateLineItemsState;

const DuplicateLineItemsPicker = ({
  adLineItemId,
  adName,
  adType,
  savedItems,
  handleRemoveSavedItem,
  handleAddSavedItem,
}: Props): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string>('');

  const isSearchActive = searchValue.length === 0;

  const handleSearchInput = useCallback(
    (newSearchValue: string): void => {
      setSearchValue(newSearchValue);
    },

    []
  );

  const handleClearSearch = useCallback((): void => {
    setSearchValue('');
  }, []);

  return (
    <>
      <div className={pickerBlock()} data-testid="duplicate-line-items-picker">
        <div className={pickerElement('search-container')}>
          <SearchInput
            placeholder="Search"
            className={pickerElement('search')}
            value={searchValue}
            onInput={handleSearchInput}
            onClear={handleClearSearch}
            data-testid="duplicate-line-items-search"
          />
        </div>
        {isSearchActive ? (
          <DuplicateLineItemsSavedTable
            savedItems={savedItems}
            adName={adName}
            adType={adType}
            adLineItemId={adLineItemId}
            handleRemoveSavedItem={handleRemoveSavedItem}
          />
        ) : (
          <DuplicateLineItemsSearchTable
            adName={adName}
            adType={adType}
            searchValue={searchValue}
            handleAddSavedItem={handleAddSavedItem}
            handleClearSearch={handleClearSearch}
            handleRemoveSavedItem={handleRemoveSavedItem}
          />
        )}
      </div>
      <div className={pickerElement('error-message')}>
        <ErrorMessage name={FieldName.lineItems} />
      </div>
    </>
  );
};

export default memo(DuplicateLineItemsPicker);
