import './Row.scss';

import React from 'react';

import Loader from '../../../../../../../../../common/Loader';
import bem from '../../../../../../../../../utils/bem';

type Props = {
  label: React.JSX.Element | string;
  value?: React.JSX.Element | string;
  conflicts?: React.JSX.Element[] | null;
  isLoading?: boolean;
};

export const [block, element] = bem('multiple-edit-confirm-row');

export const Row = ({ label, value, conflicts, isLoading }: Props): React.JSX.Element => {
  return (
    <>
      <div className={block()}>
        <div>
          <div className={element('row-item')}>
            <div className={element('label')}>{label}</div>
            <div className={element('value')}>{value}</div>
          </div>
          {isLoading ? <Loader /> : conflicts}
        </div>
      </div>
    </>
  );
};
