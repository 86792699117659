import type { DeselectOptionActionMeta, SelectOptionActionMeta } from 'react-select';

import type { DropdownOption } from '../Dropdown';
import {
  CLEAR_SELECTED_ITEMS_VALUE,
  SELECT_ALL_OPTIONS_VALUE,
} from './MultiSelectContainer/hooks/useMultiSelectContainer';
import type { OptionTypeWithExtras } from './MultiSelectDropdown';

export enum ActionTypes {
  select = 'select-option',
  deselect = 'deselect-option',
}

export function isClearAllOption<T>(
  actionMeta:
    | SelectOptionActionMeta<DropdownOption<OptionTypeWithExtras<T>>>
    | DeselectOptionActionMeta<DropdownOption<OptionTypeWithExtras<T>>>
): actionMeta is SelectOptionActionMeta<DropdownOption<typeof CLEAR_SELECTED_ITEMS_VALUE>> {
  return actionMeta.option?.value === CLEAR_SELECTED_ITEMS_VALUE;
}

export function isDeselectAllOptions<T>(
  actionMeta:
    | SelectOptionActionMeta<DropdownOption<OptionTypeWithExtras<T>>>
    | DeselectOptionActionMeta<DropdownOption<OptionTypeWithExtras<T>>>
): actionMeta is SelectOptionActionMeta<DropdownOption<typeof CLEAR_SELECTED_ITEMS_VALUE>> {
  return actionMeta.action === ActionTypes.deselect && actionMeta.option?.value === SELECT_ALL_OPTIONS_VALUE;
}

export function isSelectAllOption<T>(
  actionMeta:
    | SelectOptionActionMeta<DropdownOption<OptionTypeWithExtras<T>>>
    | DeselectOptionActionMeta<DropdownOption<OptionTypeWithExtras<T>>>
): actionMeta is
  | SelectOptionActionMeta<DropdownOption<typeof SELECT_ALL_OPTIONS_VALUE>>
  | DeselectOptionActionMeta<DropdownOption<typeof SELECT_ALL_OPTIONS_VALUE>> {
  return actionMeta.option?.value === SELECT_ALL_OPTIONS_VALUE;
}

export const isAllSelectedValue = (selectedOptions: DropdownOption[]): boolean =>
  selectedOptions[0]?.value === SELECT_ALL_OPTIONS_VALUE;
