import { useState } from 'react';

export interface DrawerProps<T extends {}> {
  data?: T;
  isOpen: boolean;
  onClose: () => void;
  isExpanded: boolean;
  onToggleExpansion: () => void;
}

export type SetDrawerProps<T extends {}> = (newState: Partial<DrawerProps<T>>) => void;

// StatefulDrawerProps are props that should be updated via handlers and useState's setState function
type StatefulDrawerProps<T extends {}> = Pick<DrawerProps<T>, 'data' | 'isOpen' | 'isExpanded'>;

const defaultDrawerProps = {
  isOpen: false,
  isExpanded: false,
};

function useDrawerProps<T extends {}>(
  initialDrawerProps?: Partial<DrawerProps<T>>
): [DrawerProps<T>, SetDrawerProps<T>] {
  const [state, setState] = useState<StatefulDrawerProps<T>>({ ...defaultDrawerProps, ...initialDrawerProps });

  // setPartialState is a type-safe wrapper function for updating the state using a partial DrawerProps object
  const setPartialState = (newState: Partial<StatefulDrawerProps<T>>): void => {
    setState((prevState: StatefulDrawerProps<T>) => ({ ...prevState, ...newState }));
  };

  // onClose is our default implementation of an "on close" handler so that clients
  // don't have to worry about implementing their own
  const onClose = (): void => {
    setState((prevState: StatefulDrawerProps<T>) => ({ ...prevState, isOpen: false, isExpanded: false }));
  };

  const onToggleExpansion = (): void => {
    setState((prevState: StatefulDrawerProps<T>) => ({ ...prevState, isExpanded: !prevState.isExpanded }));
  };

  // onClose property can be overwritten, but our drawerRef cannot
  return [{ onClose, onToggleExpansion, ...state }, setPartialState];
}

export default useDrawerProps;
