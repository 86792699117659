import './AdListTab.scss';

import React, { useMemo } from 'react';

import type { AdListData } from '../../../common/SequenceWeight/types';
import { convertAdStartAndEndDateToRightFormat } from '../../../common/utils';
import bem from '../../../utils/bem';
import DrawerTitle from '../../Trafficking/DrawerTitle';
import AdListItems from '../AdList/AdListItems';
import { getAdListData } from '../AdList/utils/utils';
import { useFetchSavedAds } from '../hooks';
import useFetchRotations from '../hooks/useFetchRotations';

const [block, element] = bem('ad-list-tab');

interface AdListTabProps {
  lineItemId: string;
}

const AdListTab = ({ lineItemId }: AdListTabProps): JSX.Element => {
  const { ads, loading, error: fetchAdsError } = useFetchSavedAds(lineItemId);
  const { rotations } = useFetchRotations(lineItemId);
  const adListData: AdListData = useMemo(() => {
    if (ads && rotations) {
      const adsRotationWithTransformedStartAndEndDate = convertAdStartAndEndDateToRightFormat(ads);

      return getAdListData(adsRotationWithTransformedStartAndEndDate, rotations);
    }

    return { rotations: [], adsWithoutWeightOrSequence: [], sequences: [] };
  }, [ads, rotations]);

  const adsList = useMemo(
    () =>
      ads.map((ad) => ({
        ad,
      })),
    [ads]
  );

  const error = fetchAdsError?.message;

  return (
    <div className={block()}>
      <DrawerTitle title="Ad list" />
      <AdListItems
        adList={adsList}
        error={error}
        loading={loading}
        adListData={adListData}
        notInteractive={true}
        className={element('ad-list-items')}
      />
    </div>
  );
};

export default AdListTab;
