import React, { useState } from 'react';

import type { TextAreaProps } from '../TextArea/TextArea';
import TextArea from '../TextArea/TextArea';

export interface TextAreaWithTemplateMacroProps extends TextAreaProps {
  templateString: string;
  replacementValue: string;
}

// A TextArea component that displays a templateString when focused and replaces with a value when not focused
// The underlying Formik form value stores the string with the template
export function TextAreaWithTemplateMacro({
  templateString,
  replacementValue,
  ...props
}: TextAreaWithTemplateMacroProps): JSX.Element {
  const [isFocused, setIsFocused] = useState(false);

  function onBlur(): void {
    setIsFocused(false);
  }

  function onFocus(): void {
    setIsFocused(true);
  }

  function getActualStringFromTemplate(stringWithTemplate?: string): string {
    return stringWithTemplate ? stringWithTemplate.replace(templateString, replacementValue) : '';
  }

  function getDisplayValue(formValue: string): string {
    return isFocused ? formValue : getActualStringFromTemplate(formValue);
  }

  return <TextArea {...props} onBlur={onBlur} onFocus={onFocus} displayValueConverter={getDisplayValue} />;
}

export default TextAreaWithTemplateMacro;
