import './NameCell.scss';

import React, { memo } from 'react';

import type { TraffickingPageDrawerCallback } from '../../hooks/makePageDrawerPlugin';
import { DrawerType, EntityType } from '../../hooks/makePageDrawerPlugin';
import type { AdModel, CampaignModel, LineItemModel } from '../../modelConverters';

type NameCellProps = {
  rowOriginal: AdModel | LineItemModel | CampaignModel;
  openTraffickingPageDrawer: TraffickingPageDrawerCallback;
  entityType: EntityType;
};

// TODO: AX-1993 replace "NameCellProps" with type extended from React Table (TableInstance?)
const NameCell = ({ rowOriginal, openTraffickingPageDrawer, entityType }: NameCellProps): JSX.Element => {
  const { name } = rowOriginal;

  const handleNameClick = (): void => {
    switch (entityType) {
      case EntityType.CAMPAIGN: {
        openTraffickingPageDrawer({
          drawerType: DrawerType.ENTITY_DETAILS,
          dataType: entityType,
          ...(rowOriginal as CampaignModel),
        });
        break;
      }
      case EntityType.LINE_ITEM: {
        openTraffickingPageDrawer({
          drawerType: DrawerType.ENTITY_DETAILS,
          dataType: entityType,
          ...(rowOriginal as LineItemModel),
        });
        break;
      }
      case EntityType.AD: {
        openTraffickingPageDrawer({
          drawerType: DrawerType.ENTITY_DETAILS,
          dataType: entityType,
          ...(rowOriginal as AdModel),
        });
        break;
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter' || e.key === ' ') handleNameClick();
  };

  return (
    <div
      onClick={handleNameClick}
      onKeyDown={handleKeyDown}
      className={'name-cell'}
      tabIndex={-1}
      data-focusable
      data-testid="name-cell"
    >
      <span title={name} className={'name-cell__text'}>
        {name}
      </span>
    </div>
  );
};

export default memo(NameCell);
