import type { AdProduct, Config } from '.';
import type { AdType } from './adTypes';
import { adTypes } from './adTypes';

const permittedAdTypes: Record<AdProduct, Config<AdType>[]> = {
  AD_SELECTOR: [adTypes.AD_SELECTOR],
  AD_SELECTOR_SLATE: [adTypes.AD_SELECTOR_SLATE],
  AD_SELECTOR_VIDEO: [adTypes.AD_SELECTOR_VIDEO],
  BE_SELECTOR: [adTypes.BE_SELECTOR_SLATE, adTypes.BE_SELECTOR_VIDEO],
  BINGE_AD_FREE: [adTypes.BRIGHTLINE],
  BINGE_REWARD: [adTypes.VIDEO],
  BINGE_SLATE: [adTypes.SLATE],
  BINGE_TEASER: [adTypes.VIDEO],
  BINGE_VIDEO: [adTypes.VIDEO],
  BRIGHTLINE: [adTypes.BRIGHTLINE],
  COLLECTION_LOGO: [adTypes.COLLECTION_LOGO],
  COVER_STORY_LOGO: [adTypes.COVER_STORY_LOGO],
  GATEWAY_GO: [adTypes.GATEWAY_GO],
  GATEWAY_SHOP: [adTypes.GATEWAY_SHOP],
  HUB_LOGO: [adTypes.HUB_LOGO],
  INNOVID: [adTypes.INNOVID],
  MARQUEE: [adTypes.MARQUEE_PROMOTED_CONTENT],
  MAX_SELECTOR: [adTypes.MAX_SELECTOR],
  PAUSE: [adTypes.PAUSE],
  SLATE: [adTypes.SLATE],
  VIDEO: [adTypes.VIDEO],
  HOUSE_VIDEO: [adTypes.VIDEO],
};

export function getPermittedAdTypes(adProductKey: AdProduct): Config<AdType>[] {
  return permittedAdTypes[adProductKey];
}
