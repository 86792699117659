import type { Reference, TypePolicies } from '@apollo/client';

import { mergeUniqueReferences } from '../utils';
import { AdAccountV4 } from './AdAccountV4';
import { AdV5 } from './AdV5';
import { Query } from './Query';

export const typePolicies: TypePolicies = {
  AdV5,
  AdAccountV4,
  Query,
  LineItemV5: {
    fields: {
      campaignReviewErrors: {
        merge(existing: Reference[], incoming: Reference[]): Reference[] {
          return mergeUniqueReferences(existing || [], incoming);
        },
      },
    },
  },
};
