import type { ActiveDayTypeV5, DayPartV5 } from '../../../apis/graphql';
import { orderedDaysOfWeek } from '../../../utils/formatting';

const getDaysListFromMatrix = (matrix: boolean[][]): string[][] => {
  const days: string[][] = [];

  for (const row of matrix) {
    let day = [];
    let firstHour;
    let lastHour;
    for (let j = 0; j < row.length; j++) {
      if (row[j]) {
        firstHour = firstHour !== undefined ? Math.min(firstHour, j) : j;
        lastHour = lastHour !== undefined ? Math.max(lastHour, j) : j;
        if (!row[j + 1]) {
          day.push(`${firstHour}-${lastHour}`);
          firstHour = undefined;
          lastHour = undefined;
        }
      }
    }
    days.push(day);
  }

  return days;
};

export const getScheduleFromMatrix = (matrix: boolean[][]): DayPartV5[] => {
  const days = getDaysListFromMatrix(matrix);
  const result: DayPartV5[] = [];

  let currentDayIndex = 0;
  while (days.some((day) => day.length > 0)) {
    let currentDay = days[currentDayIndex];

    while (currentDay.length) {
      const repeatedTime = days.reduce((acc: Map<string, number>, curr, j) => {
        if (curr.indexOf(currentDay[0]) !== -1) acc.set(orderedDaysOfWeek[j], curr.indexOf(currentDay[0]));
        return acc;
      }, new Map());

      if (repeatedTime.size > 0) {
        result.push({
          activeDayList: [...repeatedTime.keys()] as ActiveDayTypeV5[],
          firstActiveHour: Number(currentDay[0].split('-')[0]),
          lastActiveHour: Number(currentDay[0].split('-')[1]),
        });

        for (const iterator of repeatedTime) {
          let dayToEdit = days[orderedDaysOfWeek.indexOf(iterator[0])];
          if (dayToEdit) {
            dayToEdit.splice(iterator[1], 1);
          }
        }
      }
    }
    currentDayIndex++;
  }

  return result;
};

export const getInitialTimeMatrix = (dayPartList: DayPartV5[]): boolean[][] => {
  if (dayPartList.length) {
    let values: boolean[][] = [];
    dayPartList.forEach(({ activeDayList, firstActiveHour, lastActiveHour }) => {
      for (let i = 0; i < orderedDaysOfWeek.length; i++) {
        values[i] = values[i] ? values[i] : new Array<boolean>(24).fill(false);
        if (activeDayList.find((day) => day === orderedDaysOfWeek[i])) {
          values[i] = values[i].fill(true, firstActiveHour || 0, (lastActiveHour || 0) + 1);
        }
      }
    });

    return values;
  }

  return [];
};
