import type { ApolloError } from '@apollo/client';
import { isAfter, isBefore } from 'date-fns';
import _ from 'lodash';

import type {
  DateRangeInputV5,
  DateRangeV5,
  DayPartInputV5,
  DayPartV5,
  ScheduleV5,
  UpdateAdMutation,
  UpdateAdsPageAdMutation,
} from '../apis/graphql';
import { campaignClient } from '../apis/graphql';
import { ERROR_PATTERNS } from '../constants';
import type { Nullable, TargetingTermValue } from '../models';
import type { AdRotationInput } from '../pages/Ads/AdList/utils';
import { formatAdStartAndEndDateToCorrectFormat } from '../utils/formatting';
import { AlertType } from './Alert';
import { TAG_VALIDATION_MESSAGE, URL_REGEX } from './constants';
import { openToastAlert } from './ToastAlert/toastAlert';

export type AdFormErrorType = {
  message: string;
  description: string;
};

export const getEarliestDateRange = (schedule: Nullable<ScheduleV5>): DateRangeV5 | undefined =>
  schedule?.dateRangeList.reduce(
    (date, currentDate) =>
      currentDate && isBefore(new Date(currentDate.startDate), new Date(date.startDate)) ? currentDate : date,
    schedule?.dateRangeList[0]
  );

export const getLatestDateRange = (schedule: Nullable<ScheduleV5>): DateRangeV5 | undefined =>
  schedule?.dateRangeList.reduce(
    (date, currentDate) =>
      currentDate?.endDate && isAfter(new Date(currentDate.endDate), new Date(date.endDate)) ? currentDate : date,
    schedule?.dateRangeList[0]
  );

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const updateAdWithNewSchedule = ({ data }: { data?: UpdateAdMutation | UpdateAdsPageAdMutation | null }) => {
  const newSchedule = data?.updateAd?.schedule!;

  const earliestStartDate = getEarliestDateRange(newSchedule)?.startDate;
  const latestEndDate = getLatestDateRange(newSchedule)?.endDate;

  return {
    ...data?.updateAd,
    startDate: earliestStartDate,
    endDate: latestEndDate,
    schedule: newSchedule,
  };
};

export const updateTraffickingColumnCache = ({
  adId,
  data,
  displayPublisherTarget,
}: {
  adId: string;
  displayPublisherTarget: string[];
  data?: UpdateAdMutation | null;
}): void => {
  const updatedData = updateAdWithNewSchedule({ data });

  campaignClient.cache.modify({
    id: `AdV5:${adId}`,
    fields: {
      startDate: () => updatedData?.startDate,
      endDate: () => updatedData?.endDate,
      schedule: () => updatedData?.schedule,
      displayPublisherTarget: () => displayPublisherTarget,
    },
  });
};

/**
 * areFormInitialValuesAndValuesEqual checks initialValue and value in order to determine if those values are equal or not
 * @param initialValue refers to initial form values for a specific form fields (name, creative, schedule, etc.)
 * @param value refers to a current (changed) form values
 * @returns boolean
 */
export const areFormInitialValueAndValueEqual = <T>(initialValue: T, value: T): boolean =>
  _.isEqual(initialValue, value);

// Reusable alert helpers
export const showSuccess = (isAd = true): void => {
  const message = `${isAd ? 'Ad' : 'Line Item'} Saved Successfully`;

  openToastAlert({
    alertType: AlertType.SUCCESS,
    message,
  });
};

export const showFailure = ({ message, description }: Record<string, string>): void =>
  openToastAlert({
    alertType: AlertType.ERROR,
    message,
    description,
  });

export function generateErrorObject(message: string, description: string): { error: AdFormErrorType } {
  return {
    error: {
      message,
      description,
    },
  };
}

export const convertAdStartAndEndDateToRightFormat = (adsList: AdRotationInput[]): AdRotationInput[] =>
  adsList.map((ad) => ({
    ...ad,
    startDate: formatAdStartAndEndDateToCorrectFormat(ad.startDate, true),
    endDate: formatAdStartAndEndDateToCorrectFormat(ad.endDate, true),
  }));

export const convertToDateRangeInput = (dateRange: DateRangeV5[]): DateRangeInputV5[] => {
  return dateRange.map(({ startDate, endDate, pacingShare }) => ({ startDate, endDate, pacingShare }));
};

export const convertToDayPartInput = (dayPartList: DayPartV5[]): DayPartInputV5[] => {
  return dayPartList.map(({ firstActiveHour, lastActiveHour, activeDayList }) => ({
    firstActiveHour,
    lastActiveHour,
    activeDayList,
  }));
};

export const filterTermValues = (items: TargetingTermValue[] | null | undefined): TargetingTermValue[] => {
  if (!items || !items.length) {
    return [];
  }

  return items.filter((item) => item.category !== null && item.dimension !== null && item.value !== null);
};

export const validateAndPerformActionOnURL = (url: string, successCallback: () => void): void => {
  if (URL_REGEX.test(url)) {
    return successCallback();
  }

  openToastAlert({
    alertType: AlertType.ERROR,
    message: 'Tag Save Failed',
    description: TAG_VALIDATION_MESSAGE,
  });
};

export const findValueInArray = (keyToBeFind: string, array: string[] | undefined): string | undefined => {
  if (!array || array.length === 0) return undefined;

  const regex = new RegExp(keyToBeFind);

  return array.find((value) => regex.test(value));
};

export const getTireAndPriorityNamesComboErrorMessage = (errors: string[], loading: boolean): string => {
  const hasTierError = findValueInArray(ERROR_PATTERNS.TIER_NAME_ERROR, errors);
  const hasPriorityError = findValueInArray(ERROR_PATTERNS.PRIORITY_NAME_ERROR, errors);

  const isComboConditionInvalid = !loading && (hasTierError || hasPriorityError);

  return isComboConditionInvalid ? 'Please revise the invalid Tier and Priority combination.' : '';
};

export const onLineItemFailureUpdate = ({ message, description }: Record<string, string>): void =>
  showFailure({ message, description });

export const getGraphQLErrorExtensionsMessage = (error: ApolloError): string | undefined => {
  let result: string | undefined = undefined;

  if (!error.graphQLErrors) return;

  error.graphQLErrors.forEach((gqlError) => {
    const extensions = gqlError.extensions || {};
    const ownPropertyNames =
      extensions?.response?.body?.error && Object.getOwnPropertyNames(extensions?.response?.body?.error);
    if (findValueInArray('message', ownPropertyNames)) {
      const { message } = extensions.response?.body?.error;
      result = message;
    }
  });

  return result;
};
