import type { AdSelectorOption } from '../../../../../apis/graphql';
import type { DropdownOption } from '../../../../Dropdown';
import { AD_SELECTOR_VIDEO_CREATIVE_TOOLTIP } from '../constants';
import AdSelectorVideoCreativeOption from './AdSelectorVideoCreativeOption/AdSelectorVideoCreativeOption';
import { DEFAULT_TOOLTIP_ID } from './OptionAssetForm';

export const getIsOptionUsed = (
  adSelectorVideoCreativeId: string,
  creativeOptionId?: string,
  optionList?: AdSelectorOption[]
): boolean => {
  if (creativeOptionId === adSelectorVideoCreativeId) return false;

  return !!optionList?.find((option) => option.adSelectorVideoCreativeId === creativeOptionId);
};

export const transformCreativeOption = (
  creativeOption: DropdownOption<string>,
  isOptionUsed: boolean
): DropdownOption => {
  return {
    ...creativeOption,
    isDisabled: isOptionUsed,
    customOption: <AdSelectorVideoCreativeOption id={creativeOption.value} name={creativeOption.label} />,
    tooltip: isOptionUsed
      ? {
          id: DEFAULT_TOOLTIP_ID,
          message: AD_SELECTOR_VIDEO_CREATIVE_TOOLTIP,
          place: 'right',
        }
      : undefined,
  };
};

export const getSelectedCreativeOption = (
  adSelectorVideoCreativeOptions: DropdownOption[],
  index: number,
  optionList?: AdSelectorOption[]
): DropdownOption | null => {
  if (!optionList || !optionList[index]) return null;

  const selectedCreativeId = optionList[index].adSelectorVideoCreativeId;

  return adSelectorVideoCreativeOptions.find(({ id }) => selectedCreativeId === id) ?? null;
};
