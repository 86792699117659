import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import type { TargetingRuleDefinitionV4 } from '../../../../../../../../../apis/graphql';
import { removeTypeNamesFromObject } from '../../../../../../../../../apis/graphql';
import { filterTermValues } from '../../../../../../../../../common/utils';
import type { TargetingTermValue } from '../../../../../../../../../models';
import {
  GET_DRAWER_TARGETING_AD,
  GET_DRAWER_TARGETING_LINE_ITEM,
} from '../../../../../../../DrawerTargetingTable/hooks/queries';
import type { QueryResult } from '../../../../../../../DrawerTargetingTable/hooks/types';
import type {
  AdEntity,
  LineItemEntity,
  MultipleEditDrawerAdsFormValues,
  MultipleEditDrawerLineItemsFormValues,
} from '../../../../../types';

type Props = {
  isLineItem: boolean;
  values: MultipleEditDrawerLineItemsFormValues | MultipleEditDrawerAdsFormValues;
};

type ReduceResult = Record<string, TargetingTermValue[]>;

type Result = {
  loading: boolean;
  filterTargetingWithEntityId?: Record<string, TargetingTermValue[]>;
  targetingRule: TargetingRuleDefinitionV4 | null;
};

type NormalizedTargetingResult = {
  id: string;
  targetingTermValues: TargetingTermValue[];
  targetingRule: TargetingRuleDefinitionV4 | null;
};

export const useGetNormalizedTargeting = ({ values, isLineItem }: Props): Result | undefined => {
  const query = isLineItem ? GET_DRAWER_TARGETING_LINE_ITEM : GET_DRAWER_TARGETING_AD;

  const ids: string[] = values.entities.map(({ id }: LineItemEntity | AdEntity) => id);

  const isTargetingSectionSelected = useMemo(() => values.selectedSections.includes('Targeting'), [
    values.selectedSections,
  ]);

  const { data, loading } = useQuery<QueryResult>(query, {
    variables: { ids },
    fetchPolicy: 'no-cache',
    skip: !isTargetingSectionSelected,
  });

  const normalizedTargeting: NormalizedTargetingResult[] | undefined = isLineItem
    ? data?.lineItemsV5.edges.map(({ node }) => ({
        targetingTermValues: node?.targetingTermValues,
        id: node.id,
        targetingRule: node?.targetingRule,
      }))
    : data?.adsV5.edges.map(({ node }) => ({
        targetingTermValues: node?.targetingTermValues,
        id: node.id,
        targetingRule: node?.targetingRule,
      }));

  const filterTargetingWithEntityId = normalizedTargeting?.reduce<ReduceResult>((acc, { id, targetingTermValues }) => {
    acc[id] = [...filterTermValues(targetingTermValues)];

    return acc;
  }, {});

  let targetingRule = normalizedTargeting ? normalizedTargeting[0].targetingRule : null;
  targetingRule = targetingRule ? removeTypeNamesFromObject(targetingRule) : null;

  return {
    filterTargetingWithEntityId,
    loading,
    targetingRule,
  };
};
