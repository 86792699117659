// Z-Indices
export const zero = 0;
export const drawer = 300;
export const header = 400;
export const modal = 200;

export const dropdown = {
  container: 12,
  menu: 5,
};

export const editableDropdown = {
  container: 2,
  menu: 1,
};

/* 1 so that bottom border covers the TrekTabNavigation-root's bottom border */
export const taskTabSelected = 1;
export const alert = 1;
