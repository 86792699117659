import type { AdType, Config, CreativeType } from '.';
import { creativeTypes } from '.';

type VastEnabledAdType = 'VIDEO';

interface BasePermittedCreativeTypes {
  default: Config<CreativeType>;
}

interface VastEnabledCreativeTypes extends BasePermittedCreativeTypes {
  vast: Config<CreativeType>;
  magnite: Config<CreativeType>;
}

export const permittedCreativeTypes: Record<AdType, BasePermittedCreativeTypes> &
  Record<VastEnabledAdType, VastEnabledCreativeTypes> = {
  UNKNOWN: {
    default: creativeTypes.VIDEO,
  },
  AD_SELECTOR: {
    default: creativeTypes.BRIGHTLINE,
  },
  AD_SELECTOR_SLATE: {
    default: creativeTypes.AD_SELECTOR_SLATE,
  },
  AD_SELECTOR_VIDEO: {
    default: creativeTypes.AD_SELECTOR_VIDEO,
  },
  BE_SELECTOR_SLATE: {
    default: creativeTypes.SLATE,
  },
  BE_SELECTOR_VIDEO: {
    default: creativeTypes.VIDEO,
  },
  BRIGHTLINE: {
    default: creativeTypes.BRIGHTLINE,
  },
  COLLECTION_LOGO: {
    default: creativeTypes.DISPLAY,
  },
  COVER_STORY_LOGO: {
    default: creativeTypes.DISPLAY,
  },
  GATEWAY_GO: {
    default: creativeTypes.GATEWAY_GO,
  },
  GATEWAY_SHOP: {
    default: creativeTypes.BRIGHTLINE,
  },
  HUB_LOGO: {
    default: creativeTypes.DISPLAY,
  },
  INNOVID: {
    default: creativeTypes.INNOVID,
  },
  MARQUEE_PROMOTED_CONTENT: {
    default: creativeTypes.DISPLAY,
  },
  MAX_SELECTOR: {
    default: creativeTypes.BRIGHTLINE,
  },
  PAUSE: {
    default: creativeTypes.PAUSE,
  },
  PAUSE_QR: {
    default: creativeTypes.PAUSE,
  },
  SLATE: {
    default: creativeTypes.SLATE,
  },
  VIDEO: {
    default: creativeTypes.VIDEO,
    vast: creativeTypes.VAST_VIDEO,
    magnite: creativeTypes.MAGNITE,
  },
};

export function getPermittedCreativeTypes(adTypeKey: AdType): BasePermittedCreativeTypes | VastEnabledCreativeTypes {
  return permittedCreativeTypes[adTypeKey];
}

export const EXCLUDED_SEARCH_CREATIVE_TYPES = ['MAGNITE'];
