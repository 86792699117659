import { useMutation } from '@apollo/client';
import type { FormikHelpers } from 'formik';
import { useCallback } from 'react';

import type {
  BulkCreateSequencesV5Mutation,
  BulkDeleteSequencesV5Mutation,
  BulkUpdateSequencesV5Mutation,
} from '../../../apis/graphql';
import { campaignClient } from '../../../apis/graphql';
import { AlertType } from '../../../common/Alert';
import { openToastAlert } from '../../../common/ToastAlert/toastAlert';
import {
  CREATE_SEQUENCES_ERROR,
  DELETE_SEQUENCES_ERROR,
  SEQUENCES_SAVED_SUCCESSFULLY_MESSAGE,
  UPDATE_SEQUENCES_ERROR,
} from '../constants';
import { useSequenceContext } from '../context';
import { SequenceAction } from '../context/actions';
import { BULK_CREATE_SEQUENCES_V5 } from '../mutations/bulkCreateSequencesV5';
import { BULK_DELETE_SEQUENCES_V5 } from '../mutations/bulkDeleteSequencesV5';
import { BULK_UPDATE_SEQUENCES_V5 } from '../mutations/bulkUpdateSequencesV5';
import type { SequenceFormValues } from '../types';
import { applySequenceMutation, checkErrors, getSequenceDataToSave, showErrorToasts } from './utils';
import { validateSequences } from './validation';

export type UseSequencePageProps = {
  loadedData: SequenceFormValues;
};

export type UseSequencePage = {
  submitForm: (formValues: SequenceFormValues, formikHelpers: FormikHelpers<SequenceFormValues>) => Promise<void>;
};

export type SubmitError = {
  message: string;
  description: string;
};

export type SaveChangesResults<T> = {
  error: SubmitError | null;
  data: T | null;
};

type UpdateCacheDataProps = {
  createResults: SaveChangesResults<BulkCreateSequencesV5Mutation>;
  updateResults: SaveChangesResults<BulkUpdateSequencesV5Mutation>;
  deleteResults: SaveChangesResults<BulkDeleteSequencesV5Mutation>;
  formValues: SequenceFormValues;
  updateSequenceIds: Set<string>;
  deleteInputs: string[];
};

export type FormikStatus = Record<string, Set<string>>;

const useSequencePage = ({ loadedData }: UseSequencePageProps): UseSequencePage => {
  const { sequenceDispatch } = useSequenceContext();

  const [bulkCreateSequences] = useMutation<BulkCreateSequencesV5Mutation>(BULK_CREATE_SEQUENCES_V5);
  const [bulkUpdateSequences] = useMutation<BulkUpdateSequencesV5Mutation>(BULK_UPDATE_SEQUENCES_V5);
  const [bulkDeleteSequences] = useMutation<BulkDeleteSequencesV5Mutation>(BULK_DELETE_SEQUENCES_V5);

  // cache updates in this function have strict order
  // some of cache updates will be overwritten by others
  // WARNING: after cache is updated, form is re-initialized according to changes in cache
  const updateCacheData = useCallback(
    ({
      deleteResults,
      createResults,
      updateResults,
      formValues,
      updateSequenceIds,
      deleteInputs,
    }: UpdateCacheDataProps): void => {
      const { error: deleteError } = deleteResults;
      const { error: updateError, data: updateData } = updateResults;
      const { error: createError, data: createData } = createResults;

      if (!deleteError) {
        // all ads from sequences, that were deleted,
        // should be set to null
        loadedData.sequences.forEach((sequence) => {
          if (deleteInputs.includes(sequence.id)) {
            sequence.ads.forEach(({ id }) => {
              campaignClient.cache.modify({
                id: `AdV5:${id}`,
                fields: {
                  adSequence: () => null,
                },
              });
            });
          }
        });
      }

      if (!updateError && updateData) {
        // if ads were in sequences and now are move to unsequenced table,
        // change their adSequence to null
        formValues.unsequencedAds.forEach(({ id, adSequence }) => {
          if (adSequence && updateSequenceIds.has(adSequence.sequenceId)) {
            campaignClient.cache.modify({
              id: `AdV5:${id}`,
              fields: {
                adSequence: () => null,
              },
            });
          }
        });

        // update all ads, that were used in update sequence call
        updateData.bulkUpdateSequencesV5.forEach((sequence) => {
          sequence.adSequence?.forEach((adSeq) => {
            campaignClient.cache.modify({
              id: `AdV5:${adSeq?.adId}`,
              fields: {
                adSequence: () => adSeq,
              },
            });
          });
        });
      }

      if (!createError && createData) {
        // update all ads, that were used in sequence creation
        createData.bulkCreateSequencesV5.forEach((sequence) => {
          sequence.adSequence?.forEach((adSeq) => {
            campaignClient.cache.modify({
              id: `AdV5:${adSeq?.adId}`,
              fields: {
                adSequence: () => adSeq,
              },
            });
          });
        });
      }
    },
    [loadedData.sequences]
  );

  // used to handle form submit
  const submitForm = useCallback(
    async (formValues: SequenceFormValues, formikHelpers: FormikHelpers<SequenceFormValues>): Promise<void> => {
      // get validation results
      const validationResults = validateSequences(formValues.sequences);

      // set status with validation results
      formikHelpers.setStatus(validationResults);

      // check, if there are any errors
      if (checkErrors(validationResults)) {
        // prepare inputs for mutations
        const { createInputs, updateInputs, deleteInputs, updateSequenceIds } = getSequenceDataToSave(
          formValues.sequences,
          loadedData.sequences,
          formValues.unsequencedAds
        );

        const deleteResults = await applySequenceMutation<BulkDeleteSequencesV5Mutation>({
          mutation: bulkDeleteSequences,
          inputs: deleteInputs,
          errorMessage: DELETE_SEQUENCES_ERROR,
        });
        const updateResults = await applySequenceMutation<BulkUpdateSequencesV5Mutation>({
          mutation: bulkUpdateSequences,
          inputs: updateInputs,
          errorMessage: UPDATE_SEQUENCES_ERROR,
        });
        const createResults = await applySequenceMutation<BulkCreateSequencesV5Mutation>({
          mutation: bulkCreateSequences,
          inputs: createInputs,
          errorMessage: CREATE_SEQUENCES_ERROR,
        });

        // set order of sequenced ads, which will be used to correctly display order of sequences after save changes
        sequenceDispatch({
          type: SequenceAction.SET_SEQUENCED_ADS_ORDER,
          payload: [...formValues.sequences.map(({ ads }) => ads).flat()],
        });

        // update cache after all mutations are executed
        updateCacheData({ deleteResults, updateResults, createResults, updateSequenceIds, deleteInputs, formValues });

        // if there are any errors, show error messages and set form values to be able to discard failed changes
        if (deleteResults.error || updateResults.error || createResults.error) {
          showErrorToasts([deleteResults.error, updateResults.error, createResults.error]);
          formikHelpers.setValues({
            ...formValues,
          });
        } else {
          // if there are no errors, show success message
          openToastAlert({
            alertType: AlertType.SUCCESS,
            message: SEQUENCES_SAVED_SUCCESSFULLY_MESSAGE,
          });
        }
      }
    },
    [
      loadedData.sequences,
      bulkDeleteSequences,
      bulkUpdateSequences,
      bulkCreateSequences,
      updateCacheData,
      sequenceDispatch,
    ]
  );

  return {
    submitForm,
  };
};

export default useSequencePage;
