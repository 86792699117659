import './CreateReviewButton.scss';

import { User } from 'mission-control-common-components';
import React, { useCallback } from 'react';

import Button from '../../../common/Button';
import Tooltip from '../../../common/Tooltip';
import type { PermissionsNames } from '../../../constants';
import { TraffickingTableName } from '../../../constants';
import bem from '../../../utils/bem';
import { singularTitleCase } from '../../../utils/formatting';
import type { EntityModel } from '../TraffickingPage/modelConverters';
import CreateReviewLinkModal from './CreateReviewLinkModal/CreateReviewLinkModal';
import useCreateReviewButton from './hooks';
import type { FlatRowType, ReviewTableName } from './types';

const [block, element] = bem('create-review-button');

export interface CreateReviewButtonProps {
  tableName: ReviewTableName;
  selectedFlatRows: FlatRowType<EntityModel>;
}

function CreateReviewButton({ selectedFlatRows, tableName }: CreateReviewButtonProps): JSX.Element {
  const permissions = User.getPermissions<PermissionsNames>();

  const { reviewPath, disabledButton, showModal, setShowModal } = useCreateReviewButton(tableName);

  const handleClick = useCallback((): void => {
    setShowModal((showModal: boolean) => !showModal);
  }, [setShowModal]);

  const hasAccessToCreateReviewLink =
    tableName === TraffickingTableName.campaigns
      ? permissions?.createCampaignReviewLink
      : permissions?.createLineItemReviewLink;

  return (
    <>
      <Tooltip
        id={`create-${tableName}-link`}
        className={element('tooltip')}
        message={`Select ${singularTitleCase(tableName).toLowerCase()}s to create a review link.`}
        disable={!disabledButton}
      >
        <Button
          size="medium"
          icon="IconLink"
          variant="outlined"
          className={block()}
          onClick={handleClick}
          disabled={!hasAccessToCreateReviewLink || disabledButton}
          aria-label="Create Review Link"
          data-testid="create-review-button"
        >
          Create Review Link
        </Button>
      </Tooltip>
      {showModal && (
        <CreateReviewLinkModal
          reviewPath={reviewPath}
          closeModal={handleClick}
          tableName={tableName}
          selectedFlatRows={selectedFlatRows}
        />
      )}
    </>
  );
}

export default CreateReviewButton;
