import { Navigate, useLocation } from '@hulu/react-router-dom';
import React from 'react';

import { workWithLocalStorage } from '../../../utils/storage/localStorage';
import { LOCAL_STORAGE_LOCATION_STATE } from '../../Trafficking';
import type { AdsPageProps } from '../AdsPage';
import AdsPage from '../AdsPage';

export interface LocationState {
  lineItemState: AdsPageProps;
  editAdId: string;
}

const AdsPageController = (): React.ReactElement => {
  const location = useLocation();
  const state = location.state as LocationState;
  const localLocationState = workWithLocalStorage.getData<LocationState>(LOCAL_STORAGE_LOCATION_STATE);
  const lineItemState = state?.lineItemState || localLocationState?.lineItemState;

  if (!lineItemState) return <Navigate to="/" />;

  const editAdId = state ? state.editAdId : localLocationState?.editAdId;

  return <AdsPage {...lineItemState} editAdId={editAdId} />;
};

export default AdsPageController;
