// This is the prompt function for window event listener.
// Please keep in mind for chrome, which is the mainstream browser, return value must be specified.
// Reference: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
import { useCallback, useEffect } from 'react';

// This hook is used for windowPrompt. It will only handle the cases not handled by the react-router <Prompt />, when users try to navigate to another page or close the tab with unsaved content.
// If promptCondition is true (For example there are unsaved data), then we want to show a window alert with the text "Changes you made may not be saved."
// If promptCondition is false, event listener must be removed.
// Reference: https://github.com/streamich/react-use/blob/5ea486edf10c909f1985ca5aedfcd55f15304d13/src/useBeforeUnload.ts
export const useWindowPrompt = (promptCondition: boolean): void => {
  const handler = useCallback(
    (event: BeforeUnloadEvent) => {
      if (promptCondition) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    [promptCondition]
  );

  useEffect(() => {
    if (!promptCondition) {
      return;
    }
    window.addEventListener('beforeunload', handler);

    return (): void => window.removeEventListener('beforeunload', handler);
  }, [promptCondition, handler]);
};
