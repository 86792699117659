import React from 'react';

import type { StatusPillStatus } from '../StatusPill';
import { StatusPill } from '../StatusPill';
import { getBestLabel, getIngestLabel, getReviewLabel, getSPReviewLabel, getTranscodeLabel } from './util';

/**
 * Renders a pill component with the appropriate styling and messaging according
 * to the Ingest, Transcode, and Review status values for an asset.
 */
export const AssetStatusPill: React.FC<{
  className?: string;
  ingestionStatus?: string | null;
  reviewStatus?: string | null;
  spReviewStatus?: string | null;
  transcodeStatus?: string | null;
}> = ({ className, ingestionStatus, transcodeStatus, reviewStatus, spReviewStatus }) => {
  const ingest = getIngestLabel(ingestionStatus);
  const transcode = getTranscodeLabel(transcodeStatus);
  const review = getReviewLabel(reviewStatus);
  const spReview = getSPReviewLabel(spReviewStatus);

  const display = getBestLabel(ingest, transcode, review, spReview);

  // When no labels are available, we won't show a pill
  if (!display) return null;

  return (
    <StatusPill status={display.type as StatusPillStatus} className={className}>
      {display.stage === 'SP-REVIEW' ? display.label : `${display.stage}: ${display.label}`}
    </StatusPill>
  );
};
