import type { DisplayEnum, ViewabilityVendor } from '../../../configs';
import { UNRECOGNIZED, viewabilityVendors } from '../../../configs';
import type { AdTag } from '../../../models';
import type { MultiSelectValue } from '../../MultiSelectDropdown/MultiSelectDropdown';
import { getSortedAdTagList } from '../hooks/modelConverters';
import { getDefaultIASTags, getURLWithIASIds } from '../Tags/utils';

export const getViewabilityVendorValuesWithoutIAS = (
  newValue: MultiSelectValue<ViewabilityVendor | typeof UNRECOGNIZED>
): DisplayEnum<ViewabilityVendor>[] => {
  return newValue.reduce((acc, nv) => {
    if (nv.value !== viewabilityVendors.IAS.key) {
      acc.push(nv.value === UNRECOGNIZED ? { key: UNRECOGNIZED, displayName: nv.label } : viewabilityVendors[nv.value]);
    }

    return acc;
  }, new Array<DisplayEnum<ViewabilityVendor>>());
};

export const getIASCampaignLineItemIds = (
  iasCampaignLineItemIdValue: string = ''
): { campaignId?: string; lineItemId?: string } => {
  const splittedIds = iasCampaignLineItemIdValue.split('/');

  let campaignId: string = splittedIds[0];
  let lineItemId: string = splittedIds[1];

  if (splittedIds.length > 2) {
    campaignId = splittedIds[1];
    lineItemId = splittedIds[2];
  }

  return {
    campaignId,
    lineItemId,
  };
};

export const getAdTagsWithIASTags = (adTagList: AdTag[], iasCampaignLineItemIdValue?: string): AdTag[] => {
  const { campaignId, lineItemId } = getIASCampaignLineItemIds(iasCampaignLineItemIdValue);

  if (!adTagList.length) {
    return [...getDefaultIASTags(campaignId, lineItemId)];
  }

  const hasIASTags = adTagList.some(({ isIas }) => isIas);

  let newAdTagList = adTagList.reduce((acc, currentValue) => {
    if (currentValue.isIas) {
      acc.push({
        ...currentValue,
        url: getURLWithIASIds(currentValue.url, campaignId, lineItemId),
      });
    } else {
      acc.push(currentValue);
    }

    return acc;
  }, new Array<AdTag>());

  if (!hasIASTags) {
    newAdTagList = newAdTagList.concat(...getDefaultIASTags(campaignId, lineItemId));
  }

  return getSortedAdTagList(newAdTagList);
};
