import { isAfter } from 'date-fns';

import type { DateRange as Range } from '../../../models';

export const isStartDateAfterEndDate = (dateRange: Range): boolean => {
  const startDate = new Date(dateRange.startDate);

  if (!dateRange.startDate) return true;

  if (dateRange.endDate) {
    return isAfter(startDate, new Date(dateRange.endDate));
  }

  return isAfter(startDate, new Date());
};
