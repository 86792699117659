import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

export type CreativeType = keyof typeof creativeTypeDisplayNames;

const creativeTypeDisplayNames = {
  AD_SELECTOR_VIDEO: enumToTitleCase,
  AD_SELECTOR_SLATE: enumToTitleCase,
  BRIGHTLINE: enumToTitleCase,
  DRAX: enumToTitleCase,
  GATEWAY_GO: enumToTitleCase,
  INNOVID: enumToTitleCase,
  MAGNITE: enumToTitleCase,
  PAUSE: enumToTitleCase,
  SLATE: enumToTitleCase,
  VAST_VIDEO: 'VAST Video',
  VIDEO: enumToTitleCase,

  // @deprecated
  DISPLAY: enumToTitleCase,
  MARQUEE_PROMOTED_CONTENT: enumToTitleCase,
  BE_SELECTOR_VIDEO: enumToTitleCase,
  BE_SELECTOR_SLATE: enumToTitleCase,
};

export const creativeTypes = makeConfig<CreativeType>(creativeTypeDisplayNames);

export function convertCreative(apiValue: string): DisplayEnum<CreativeType> {
  return convertToDisplayEnum(apiValue, creativeTypes);
}
