import type { SequenceAdV5 } from '../types';

export enum SequenceAction {
  SET_SEARCH_UNSEQUENCED_ADS_TERM = 'SET_SEARCH_UNSEQUENCED_ADS_TERM',
  SET_SEQUENCED_ADS_ORDER = 'SET_SEQUENCED_ADS_ORDER',
}

export type SequenceActions =
  | { type: SequenceAction.SET_SEARCH_UNSEQUENCED_ADS_TERM; payload: string }
  | { type: SequenceAction.SET_SEQUENCED_ADS_ORDER; payload: SequenceAdV5[] };
