import { useLocation, useNavigate } from '@hulu/react-router-dom';
import type { FormikErrors, FormikHelpers } from 'formik';

import type { AssetExtended } from '../../../../common/_ForCommonLibrary/types/extended';
import type { AdFormValues, FormFormikErrors } from '../../../../common/AdForm/adFormik/types';
import { updateCreativeMetaDataByCreativeId } from '../../../../common/AdForm/CreativeBuilders/hooks/updateCreativeMetadataByCreativeId';
import { getSortedAdTagsFromFragment } from '../../../../common/AdForm/hooks/modelConverters';
import type { AdsPageLineItem } from '../../../../common/AdForm/types';
import { getAssetIds, typeIsVast } from '../../../../common/AdForm/utils';
import { validateAdForm } from '../../../../common/AdForm/validation/adForm';
import { showFailure, showSuccess } from '../../../../common/utils';
import { PathName } from '../../../../constants';
import type { LocationState } from '../../AdsPageController';
import type { FormSubmit, SaveAdReturnType } from '../types';
import { fetchAssetsByIds } from './utils/fetchAssetsByIds';
import { fetchCreativeMetadata } from './utils/fetchCreativeMetadata';
import { getParsedCountryCodeData } from './utils/getParsedCountryCodeData';
import { handleGraphQLErrors } from './utils/handleGraphQLErrors';
import { saveCreative } from './utils/saveCreative';

function useFormSubmit(
  saveAd: (values: AdFormValues, creativeId: string | null, assets?: AssetExtended[]) => Promise<SaveAdReturnType>,
  lineItem: AdsPageLineItem
): FormSubmit {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as LocationState;

  const submitForm = async (values: AdFormValues, helpers: FormikHelpers<AdFormValues>): Promise<void> => {
    const errors = validateAdForm(values, lineItem.schedule);

    if (errors.length) {
      const formErrors: FormFormikErrors = {};

      errors.forEach(({ fields, message, description }) => {
        fields.forEach((field) => (formErrors[field] = ' '));

        showFailure({ message, description });
      });

      helpers.setErrors(formErrors as FormikErrors<AdFormValues>);

      return;
    }

    try {
      // get country codes between lineItem and ad for save actions.
      const { combinedCountryCodes } = getParsedCountryCodeData(values, locationState);

      // Save the creative and return the creative ID, then save the ad
      const creativeId = await saveCreative(values);
      const creativeAssets = await fetchAssetsByIds(getAssetIds(values.creative));

      const resultData = await saveAd(values, creativeId, creativeAssets);

      let creative = values.creative;

      if (typeIsVast(creative.type) && creative.url) {
        creative = { ...values.creative, isSubmitted: true };
        // if creative type is VAST save country codes to Creative-Manager/Asset-Manager
        if (creativeId) {
          const creativeMetadata = await fetchCreativeMetadata(creativeId);

          updateCreativeMetaDataByCreativeId(
            creativeId,
            combinedCountryCodes,
            creativeMetadata?.isLegalApproved ?? false
          );
        }
      }

      setTimeout(() => {
        showSuccess();
      }, 0);

      const currentAdId = resultData?.createdAdId || values.id;
      const currentAdTagList = resultData?.createdAdTagList
        ? getSortedAdTagsFromFragment(resultData.createdAdTagList)
        : values.adTagList;

      helpers.resetForm({ values: { ...values, creative, id: currentAdId, adTagList: currentAdTagList } });

      if (resultData?.createdAdId) {
        navigate(
          { pathname: PathName.adsEdit, search: location.search },
          { replace: true, state: { ...location.state, editAdId: resultData?.createdAdId } }
        );
      }
    } catch (error) {
      const description = handleGraphQLErrors(error as Error);
      showFailure({ message: 'Ad Save Failed', description });
    }
  };

  return submitForm;
}

export default useFormSubmit;
