import { camelCase, forOwn, isPlainObject } from 'lodash';

import type { SingleValueChange } from '../../../../apis/graphql';
import type { SimpleChangeValue } from '../../../../apis/graphql';

type Block = {
  [key: string]: string | SimpleChangeValue | undefined;
};

function mapObjectKeys(list: SingleValueChange, callback: (v: string) => string): SingleValueChange {
  const block: Block | [] = Array.isArray(list) ? [] : {};

  forOwn<SingleValueChange>(list, (value, key) => {
    if (isPlainObject(value) || Array.isArray(value)) {
      // @ts-ignore
      value = mapObjectKeys(value, callback);
    }

    (block as Block)[callback(key)] = value;
  });

  return block as SingleValueChange;
}

export function camelizeObjectKeys(obj: SingleValueChange): SingleValueChange {
  return mapObjectKeys(obj, camelCase);
}
