import { useQuery } from '@apollo/client';

import type { Query, RoleV4 } from '../../apis/graphql';
import { GET_ROLES } from './queries';

export const useGetRoleDefinitions = (): { data: RoleV4[]; loading: boolean } => {
  const { data, loading } = useQuery<Query>(GET_ROLES);

  return { data: data?.getRoles || [], loading };
};
