import styled from 'styled-components';

import { theme } from '../../../../styles/styled';

export const Title = styled.p`
  margin-top: 0;
  font-weight: 500;
  font-size: ${theme.constant.fontSize.standard};
  line-height: ${theme.constant.gridPx(3)};
`;
