import { useQuery } from '@apollo/client';
import { get as _get } from 'lodash';
import { useMemo } from 'react';

import type { DropdownOption } from '../../Dropdown';
import type { DropdownOptions } from '../config';
import { DROPDOWN_OPTIONS_CONFIG } from '../config';
import { prepareDropdownOptions } from '../utils';

export type ReturnedHookType = {
  loading: boolean;
  options: DropdownOption<string>[];
  error?: string;
};

export const useFetchDropdownOptions = <T>(dropdownOption: DropdownOptions): ReturnedHookType => {
  const { queryName, schema, labelTransformationFunction } = DROPDOWN_OPTIONS_CONFIG[dropdownOption];

  const { data, error, loading } = useQuery<T>(schema);

  const options: DropdownOption[] = useMemo(() => {
    const queryNameData: string[] = _get(data, queryName, []) ?? [];

    const dropdownOptions = prepareDropdownOptions(queryNameData, dropdownOption, labelTransformationFunction);

    return dropdownOptions;
  }, [data, queryName, dropdownOption, labelTransformationFunction]);

  return {
    loading,
    error: error?.message,
    options,
  };
};
