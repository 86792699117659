import { NetworkStatus, useApolloClient, useQuery } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import { isEqual as _isEqual } from 'lodash';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Column, TableInstance } from 'react-table';
import { useBlockLayout, useColumnOrder, useResizeColumns, useRowSelect, useSortBy } from 'react-table';
import { usePrevious } from 'react-use';

import type {
  AdEdgeV5,
  CampaignEdgeV5,
  Edge,
  LineItemEdgeV5,
  NodeTransformFunction,
  Query,
} from '../../../../apis/graphql';
import type { SetDrawerProps } from '../../../../common/Drawer/useDrawerProps';
import { TraffickingTableName } from '../../../../constants';
import { useFiltersContext } from '../../../../contexts/FilterContext';
import type { ResultKeys } from '../../constants';
import { getSelectedRowIdsFromState } from '../../utils';
import type { EntityModel } from '../modelConverters';
import makeDeleteItemsPlugin from './makeDeleteItemsPlugin';
import type { TraffickingPageDrawerData } from './makePageDrawerPlugin';
import makePageDrawerPlugin from './makePageDrawerPlugin';
import useRowSelectCheckboxColumn from './useRowSelectCheckboxColumn';
import type { UseSequenceViewResult } from './useSequenceView';
import useSequenceView from './useSequenceView';
import type { QueryVariables, SelectedRowIds } from './useTraffickerState';
import useTraffickerTableInstance from './useTraffickerTableInstance';
import { getSelectedIdsMatchedSearchTerm, prepareSortOption, prepareSortOptionsForTable } from './utils';

export interface TraffickerTableState<Model extends EntityModel> extends UseSequenceViewResult {
  error: string | undefined;
  loading: boolean;
  initialLoading: boolean;
  tableInstance: TableInstance<Model>;
  hasMore: boolean;
  total: number;
  onNext: () => void;
  keyboardOnNext: (keyboardRowPosition: number) => void;
  defaultLimit: number;
  setOffset: Dispatch<SetStateAction<number>>;
  setTableSearchTerm: Dispatch<SetStateAction<string>>;
  resetSelectedIdOffset: Dispatch<SetStateAction<TraffickingTableName>>;
  handleDeleteItems(ids: string[], toggleSelectedOff: () => void): void;
  handleToggleAllSelectedOff: () => void;
}

type TraffickerTableOptions = {
  setDrawerProps: SetDrawerProps<TraffickingPageDrawerData>;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  offset: number;
  setLineItemOffset: Dispatch<SetStateAction<number>>;
  setAdOffset: Dispatch<SetStateAction<number>>;
  setOffset: Dispatch<SetStateAction<number>>;
  setIdsToQuery: Dispatch<SetStateAction<SelectedRowIds>>;
  skip?: boolean;
};

type Data = {
  [Property in ResultKeys]?: {
    edges: AdEdgeV5[] | LineItemEdgeV5[] | CampaignEdgeV5[];
    total: number;
    __typename?: AdEdgeV5['__typename'] | LineItemEdgeV5['__typename'] | CampaignEdgeV5['__typename'];
  };
};

export const DEFAULT_LIMIT = 50;
export const NO_FETCH_MORE_DELETE_LIMIT = 15;

function useTraffickerTable<NodeType, Model extends EntityModel>(
  query: DocumentNode,
  queryVariables: QueryVariables,
  nodesKey: ResultKeys,
  transformFn: NodeTransformFunction<NodeType, Model>,
  columns: Column<Model>[],
  tableName: TraffickingTableName,
  options: TraffickerTableOptions
): TraffickerTableState<Model> {
  const { cache } = useApolloClient();

  const offset = options.offset;
  const setOffset = options.setOffset;
  const setLineItemOffset = options.setLineItemOffset;
  const setAdOffset = options.setAdOffset;
  const skip = options.skip;
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [tableSearchTerm, setTableSearchTerm] = useState<string>(options.searchTerm);
  const prevTableSearchTerm = usePrevious(tableSearchTerm);

  // Keep in mind, that you need to keep in sync 'queryVariables'
  // and 'getLineItemsKeyFields', 'getAdsKeyFields', 'getCampaignsKeyFields'
  // which can be found in \mission-control-ui\app\src\constants.tsx.
  // Otherwise this query will NOT be executed when you are applying a new filter which is not in sync.
  const { loading, error, data, refetch, fetchMore, networkStatus } = useQuery(query, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip,
  });

  const [initialLoading, setInitialLoading] = useState(loading);
  const [isDataSetting, setIsDataSetting] = useState(loading);

  useEffect(() => {
    // isDataSetting is used to indicate that loading of data is not finished,
    // even if loading from useQuery is falsy.
    // We need that because we set our data in useEffect as loadedData.
    if (loading) {
      setIsDataSetting(true);

      // initialLoading indicates that query with these particular queryVariables
      // is going to be run for the first time
      if (networkStatus === NetworkStatus.setVariables) {
        setInitialLoading(true);
      }
    }

    if (!loading) {
      setInitialLoading(false);
    }
  }, [loading, networkStatus]);

  const cachedData = useMemo(
    () => cache.readQuery<Data>({ query, variables: queryVariables }),
    [cache, query, queryVariables]
  );

  const [loadedData, setLoadedData] = useState<Data>(data);

  const loadedDataTotal = loadedData && loadedData[nodesKey] && loadedData[nodesKey]?.total;
  const currentTotal = loadedDataTotal ? loadedDataTotal : 0;
  const [total, setTotal] = useState<number>(currentTotal);
  const [deletedCount, setDeletedCount] = useState<number>(0);
  const [isRefetching, setIsRefetching] = useState<boolean>(false);

  const onLoadMore = useCallback(
    (newOffset: number): void => {
      setOffset(newOffset);

      fetchMore({
        variables: {
          offset: newOffset,
        },
      });
    },
    [fetchMore, setOffset]
  );

  useEffect(() => {
    setTotal(currentTotal);
  }, [currentTotal]);

  const onNext = (): void => {
    if (hasMore) {
      const newOffset = offset - deletedCount + DEFAULT_LIMIT;
      onLoadMore(newOffset);
      setDeletedCount(0);
    }
  };

  const keyboardOnNext = (keyboardRowPosition: number): void => {
    if ((keyboardRowPosition + 1) % DEFAULT_LIMIT === 0) {
      onNext();
    }
  };

  useEffect((): void => {
    // we need a way to set hasMore back to true so we do not lose track of getting more data when filtering
    setHasMore(total > offset + DEFAULT_LIMIT);
  }, [offset, total]);

  useEffect(() => {
    // sets cached data, if query is cached
    // if there is no cached data, sets fetched data or an empty object
    if (cachedData && cachedData[nodesKey] && cachedData[nodesKey]?.edges?.length) {
      setLoadedData(cachedData);
    } else {
      setLoadedData(data && data[nodesKey]?.edges?.length ? data : {});
    }

    if (cachedData || data || error) setIsDataSetting(false);
  }, [skip, data, refetch, nodesKey, cachedData, error]);

  useEffect(() => {
    // is used to set offset according to the amount of loadedData
    // it's fixing the issue, that the offset is manually set to 0, when user applies a filter
    if (loadedData && loadedData[nodesKey] && loadedData[nodesKey]?.edges) {
      const loadedDataLength = loadedData[nodesKey]?.edges?.length;

      const initialOffset = loadedDataLength ? loadedDataLength - DEFAULT_LIMIT : 0;

      setOffset(initialOffset <= 0 ? 0 : initialOffset);
    }
  }, [loadedData, nodesKey, setOffset]);

  const handleDeleteItems = useCallback(
    (deleteIds: string[], toggleSelectedCallback: () => void) => {
      toggleSelectedCallback();

      let leftItemsCount: number = 0;

      setTotal((prevTotal) => prevTotal - deleteIds.length);

      // Here we set deleted count to reduce offset for loading correct more data
      setDeletedCount((prevState) => prevState + deleteIds.length);

      // Here we delete deleted items from cache
      const adsField: keyof Query = 'adsV5';
      cache.modify({
        fields: {
          [adsField](prev) {
            return {
              ...prev,
              edges: prev.edges.filter((edge: Edge) => !deleteIds.includes(cache.identify(edge.node)?.split(':')[1]!)),
              total: prev.total - deleteIds.length,
            };
          },
        },
      });
      cache.gc();

      // Here we delete deleted items from table state
      setLoadedData((prevState: Data) => {
        leftItemsCount = prevState && prevState[nodesKey]!.edges.length - deleteIds.length;
        return {
          [nodesKey]: {
            edges: (prevState[nodesKey]!.edges as []).filter(
              (edge: AdEdgeV5 | LineItemEdgeV5 | CampaignEdgeV5) => !deleteIds.includes(edge.node.id)
            ),
          },
        };
      });

      // If all items were deleted  , we do refetch
      if (deleteIds.length === DEFAULT_LIMIT) {
        setIsRefetching(true);
        refetch().then(() => {
          setIsRefetching(false);
        });
      }
      // If less than 15 items were left , we get more data, to prevent losing scroll
      else if (leftItemsCount <= NO_FETCH_MORE_DELETE_LIMIT && NO_FETCH_MORE_DELETE_LIMIT < DEFAULT_LIMIT && hasMore) {
        const newOffset = offset - deleteIds.length + DEFAULT_LIMIT;
        onLoadMore(newOffset);
      }
    },
    [refetch, onLoadMore, hasMore, nodesKey, offset, cache]
  );

  const usePageDrawerPlugin = useMemo(
    () => makePageDrawerPlugin<Model>(options.setDrawerProps),

    [options.setDrawerProps]
  );

  const useDeleteItemsPlugin = useMemo(() => makeDeleteItemsPlugin<Model>(handleDeleteItems), [handleDeleteItems]);
  const { applyTableFilter, filters, shareableId } = useFiltersContext();

  const tableOptions = { searchTerm: tableSearchTerm, setSearchTerm: setTableSearchTerm };
  const plugins = [
    useSortBy,
    useRowSelect,
    useRowSelectCheckboxColumn,
    usePageDrawerPlugin,
    useDeleteItemsPlugin,
    useResizeColumns,
    useColumnOrder,
    useBlockLayout,
  ];

  const tableInstance = useTraffickerTableInstance(
    loadedData,
    nodesKey,
    transformFn,
    columns,
    tableName,
    tableOptions,
    plugins
  );

  useEffect(() => {
    if (shareableId) {
      tableInstance.setSortBy(prepareSortOptionsForTable(tableName, filters[tableName]?.sortBy));
      tableInstance.setSearchTerm(filters[tableName]?.searchTerm);
      tableInstance.rows.forEach(({ id }) =>
        tableInstance.toggleRowSelected(id, filters[tableName]?.selectedRowIds?.includes(id))
      );
    }
  }, [filters, tableInstance, tableName, shareableId]);

  const {
    state: { selectedRowIds, sortBy },
    toggleAllRowsSelected,
    toggleRowSelected,
  } = tableInstance;

  useEffect(() => {
    if (prevTableSearchTerm !== undefined && tableSearchTerm !== prevTableSearchTerm) {
      setDeletedCount(0);
      options.setSearchTerm(tableSearchTerm);

      const matchedSelectedIds = getSelectedIdsMatchedSearchTerm<Model>(
        tableInstance.selectedFlatRows,
        tableSearchTerm
      );

      const matchedIds = Object.keys(matchedSelectedIds);
      const allSelectedIds = tableInstance.selectedFlatRows.map((el) => el.id);
      const mismatchedIds = allSelectedIds.filter((el) => !matchedIds.includes(el));

      mismatchedIds.forEach((el) => toggleRowSelected(el, false));

      options.setIdsToQuery(matchedSelectedIds);
    }
  }, [
    tableSearchTerm,
    prevTableSearchTerm,
    options,
    tableInstance.state,
    toggleAllRowsSelected,
    tableInstance.selectedFlatRows,
    toggleRowSelected,
  ]);

  const resetSelectedIdOffset = (): void => {
    if (tableName === TraffickingTableName.campaigns) {
      setLineItemOffset(0);
      setAdOffset(0);
    }
    if (tableName === TraffickingTableName.lineItems) {
      setAdOffset(0);
    }
  };

  // Each time react-table state selectedRowIds updates
  // This useEffect updates the filters state
  // The URL update should not trigger additional renders or graphQL requests
  // The GraphQL requests are made simultaneously to the URL update when react-table state updates
  useEffect(() => {
    const selectedTabParam = filters.selectedTab;
    const currentIds = filters[tableName]?.selectedRowIds;
    const newSelectedRowIds = Object.keys(selectedRowIds);

    if (_isEqual(currentIds, newSelectedRowIds)) {
      return;
    }

    if (selectedTabParam !== null && currentIds === null) {
      applyTableFilter(tableName, 'selectedRowIds', []);
      return;
    }

    if (tableName === TraffickingTableName.campaigns) {
      setLineItemOffset(0);
      setAdOffset(0);
    }

    if (tableName === TraffickingTableName.lineItems) {
      setAdOffset(0);
    }

    applyTableFilter(tableName, 'selectedRowIds', newSelectedRowIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds, setAdOffset, setLineItemOffset, tableName, toggleRowSelected]);

  useEffect(() => {
    const newSortOptions = prepareSortOption(tableName, sortBy[0]);

    if (_isEqual(filters[tableName]?.sortBy, newSortOptions)) {
      return;
    }
    // When sortBy changes via params, reset offset and deleted items count
    setDeletedCount(0);
    setOffset(0);

    applyTableFilter(tableName, 'sortBy', newSortOptions);

    options.setIdsToQuery(getSelectedRowIdsFromState(filters[tableName]?.selectedRowIds || []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, tableName, setOffset]);

  const handleToggleAllSelectedOff = useCallback(() => toggleAllRowsSelected(false), [toggleAllRowsSelected]);

  const sequenceView = useSequenceView(tableName);

  return {
    error: error && error.message,
    loading: loading || isRefetching || isDataSetting,
    initialLoading: initialLoading,
    tableInstance,
    hasMore,
    total,
    onNext,
    keyboardOnNext,
    defaultLimit: DEFAULT_LIMIT,
    setOffset,
    setTableSearchTerm,
    resetSelectedIdOffset,
    handleDeleteItems,
    handleToggleAllSelectedOff,
    ...sequenceView,
  };
}

export default useTraffickerTable;
