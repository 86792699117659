import type {
  GetLineItemsWithTargetingsQuery,
  Maybe,
  TargetingRule,
  TargetingTermValuesFragment,
} from '../../../../apis/graphql';
import type { LineItemModel } from './lineItemConverter';
import { lineItemNodeToModel } from './lineItemConverter';

export type LineItemWithTargetingsNode = NonNullable<
  GetLineItemsWithTargetingsQuery['lineItems']['edges'][number]
>['node'];

export type LineItemWithTargetingsModel = LineItemModel & {
  targetingTermValues: Maybe<Array<{ __typename?: 'TargetingTermValue' } & TargetingTermValuesFragment>>;
  targetingRule: Maybe<TargetingRule>;
};

export function lineItemWithTargetingsNodeToModel(node: LineItemWithTargetingsNode): LineItemWithTargetingsModel {
  return {
    ...lineItemNodeToModel(node),
    targetingTermValues: node.targetingTermValues,
    targetingRule: node.targetingRule as TargetingRule,
  };
}
