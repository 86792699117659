import './DropdownButton.scss';

import { IconClose } from '@hulu-react-style-components/icons';
import { DropDown as TrekDropDown, DropDownItem as TrekDropDownItem, Label } from '@hulu/react-style-components';
import React from 'react';

import bem from '../../../utils/bem';
import { makeLabelChild } from '../../Form/utils';
import type { PillColor } from '../../Pill';
import Pill from '../../Pill';

export interface DropdownButtonProps {
  label?: string;
  secondaryLabel?: string;
  errMsg?: string;
  displayValue: string;
  emptyDisplayText?: string;
  active: boolean;
  isRemovable?: boolean;
  isDisabled?: boolean;
  hideEmptyDisplayValue?: boolean;
  dataTestId?: string;
  setActive: (active: boolean) => void;
  handleRemove?: () => void;
  onClose?: () => void;
  highlightSelectedValue?: PillColor;
}

const [block, element] = bem('dropdown-button');

export const EmptyDisplayValue = ({
  emptyDisplayText = 'Select',
}: Pick<DropdownButtonProps, 'emptyDisplayText'>): JSX.Element => (
  <span className={element('empty-text')}>{emptyDisplayText}</span>
);

/**
 * A button that opens and closes the dropdown and displays its value
 */
function DropdownButton({
  label,
  secondaryLabel,
  errMsg,
  displayValue,
  emptyDisplayText,
  hideEmptyDisplayValue,
  active,
  isDisabled,
  dataTestId,
  isRemovable,
  setActive,
  handleRemove,
  onClose,
  highlightSelectedValue,
}: DropdownButtonProps): JSX.Element {
  const handleClick = (): void => {
    if (isDisabled) return;
    if (active && onClose) onClose();

    setActive(!active);
  };

  return (
    <div className={block()}>
      {(label || secondaryLabel) && (
        <Label id="dropdown-button-label" data-testid="dropdown-button-label" isErr={!!errMsg}>
          {makeLabelChild(label, secondaryLabel)}
        </Label>
      )}
      <div onClick={handleClick} aria-labelledby="dropdown-button-label" data-testid={dataTestId}>
        {/* using the Trek Dropdown purely for presentation */}
        <TrekDropDown
          value={displayValue}
          className={`trek-dropdown ${active ? 'TrekDropDown-formControlActive' : ''}`}
          disabled={isDisabled}
        >
          {/* placeholder DropdownItem for displaying the name */}
          <TrekDropDownItem value={displayValue} className={element('dropdown-child')}>
            {!highlightSelectedValue
              ? displayValue || (!hideEmptyDisplayValue && <EmptyDisplayValue emptyDisplayText={emptyDisplayText} />)
              : displayValue.length > 0 && <Pill text={displayValue} color={highlightSelectedValue} />}
          </TrekDropDownItem>
        </TrekDropDown>
        {isRemovable && (
          <IconClose
            width={17}
            height={17}
            className={element('remove-btn')}
            onClick={handleRemove}
            data-testid="remove-btn"
          />
        )}
      </div>
    </div>
  );
}

export default DropdownButton;
