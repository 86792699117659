import { useEffect, useState } from 'react';

import campaignGraphqlManager from '../../apis/campaignGraphqlManager';
import { TranscodeStatusV5 } from '../../apis/graphql';
import { openToastAlert } from '../../common/ToastAlert/toastAlert';

export type UseAsset = {
  assetUrl: string;
};

const useAsset = (url: string, transcodeStatus?: TranscodeStatusV5 | null): UseAsset => {
  const [assetUrl, setAssetUrl] = useState<string>('');

  useEffect(() => {
    const getAsset = async (): Promise<void> => {
      try {
        if (transcodeStatus !== TranscodeStatusV5.Pending) {
          const { data } = await campaignGraphqlManager.get(url, { responseType: 'blob' });
          setAssetUrl(URL.createObjectURL(data));
        }
      } catch {
        openToastAlert({ alertType: 'error', message: 'Failed to fetch asset' });
      }
    };

    getAsset();
  }, [url, transcodeStatus]);

  return {
    assetUrl,
  };
};

export default useAsset;
