import './IconWithTitle.scss';

import type { ReactElement } from 'react';
import React from 'react';

import bem from '../../utils/bem';

interface IconWithTitleProps {
  title: string;
  icon: ReactElement;
  className?: string;
}

const [block, element] = bem('icon-title');

function IconWithTitle({ title, icon, className }: IconWithTitleProps): JSX.Element {
  return (
    <div className={`${block()} ${className || ''}`}>
      {icon}
      <span className={element('text')}>{title}</span>
    </div>
  );
}

export default IconWithTitle;
