/**
 * Allows for dropdowns with a known list of options, but can default to an empty string
 */

import type {
  AccentRgbv5,
  AdSelectorOption,
  AssetTagV5,
  CreativeV5Type,
  DaapiFeatureInput,
  Maybe,
} from '../../../apis/graphql';
import type { Nullable } from '../../../models';

export type DaapiEntity = {
  id: string;
};

/**
 * Creative builder fields specific to Type.
 * Only one of the following should be used per form instance.
 */
export interface CreativeV5Types {
  logo?: { id?: Maybe<string> };
  offerImage?: { id?: Maybe<string> };
  poster?: { id?: Maybe<string> };
  image?: { id?: Maybe<string> };
  review?: Maybe<string>;
  slate?: { id?: Maybe<string> };
  video?: { id?: Maybe<string> };
  drax?: { id?: Maybe<string> };
  assetTagList?: Maybe<Array<Maybe<AssetTagV5>>>;
  videoAssetList?: Maybe<Array<DaapiEntity>>;
  featureList?: Maybe<Array<Maybe<DaapiFeatureInput>>>;
  template?: string;
  url?: string;
  vpaid?: boolean;
  adSourceId?: string;
  filesize?: number;
  importMethod?: 'search' | 'upload' | 'tag';
  innovid?: { template: string };
  brightline?: { template: string };
  isSubmitted?: boolean;
  altText?: string;
  accent?: Maybe<AccentRgbv5>;
  livingRoomCopy?: string;
  destinationUrl?: string;
  pushTitle?: string;
  pushBody?: string;
  emailSubject?: string;
  emailBody?: string;
  headline?: string;
  voiceoverUrl?: string;
  optionList?: AdSelectorOption[];
  randomOrder?: boolean;
}

type CreativesTypeNames =
  | 'AdSelectorSlateCreativeV5'
  | 'AdSelectorVideoCreativeV5'
  | 'BrightlineCreativeV5'
  | 'DisplayCreativeV5'
  | 'DraxCreativeV5'
  | 'GatewayGoCreativeV5'
  | 'InnovidCreativeV5'
  | 'MagniteCreativeV5'
  | 'MarqueePromotedContentCreativeV5'
  | 'PauseCreativeV5'
  | 'SlateCreativeV5'
  | 'VASTVidoeCreativeV5'
  | 'VideoCreativeV5';

export interface CreativesBuilderValues extends CreativeV5Types {
  mode: 'selectOption' | 'search' | 'build';
  __typename?: CreativesTypeNames;
  id: string;
  /** The type of the creative. */
  type: CreativeV5Type;
  /** The name of the creative in the CFS. */
  name: string;
  /** The list of asset tags for the creative */
  assetTagList?: Maybe<Array<Maybe<AssetTagV5>>>;
  /** The status of the creative. */
  duration?: number;
  /** The creative-library-id of the creative. */
  creativeLibraryId?: Nullable<string>;
  /** The creative-at date and time of the creative. */
  createdAt?: Date;
  /** The status of the creative. */
  status?: string;
}

/*
  readonly: all fields are non-editable
  partly: some fields are editable; this option is used for approved ads
  full: no restrictions on update
*/
export enum CreativeEditMode {
  readonly,
  partly,
  full,
}
