import './PriorityNameCell.scss';

import { User } from 'mission-control-common-components';
import React, { memo, useMemo } from 'react';
import type { Cell } from 'react-table';

import type { LineItemPriorityV5 } from '../../../../../apis/graphql';
import EditableDropdown from '../../../../../common/EditableTableCells/EditableDropdown';
import Tooltip from '../../../../../common/Tooltip';
import { getTireAndPriorityNamesComboErrorMessage, onLineItemFailureUpdate } from '../../../../../common/utils';
import type { PermissionsNames } from '../../../../../constants';
import bem from '../../../../../utils/bem';
import { enumToTitleCase } from '../../../../../utils/formatting';
import { getIdFromTableCell } from '../../columns';
import { useUpdateLineItem } from '../../hooks';
import { useLineItemPriorities } from '../../hooks/useLineItemPriorities';
import type { LineItemModel } from '../../modelConverters';

const [block] = bem('priority-name-cell');

function PriorityNameCell(cell: Cell<LineItemModel, LineItemPriorityV5>): JSX.Element {
  const permissions = User.getPermissions<PermissionsNames>();
  const id = getIdFromTableCell(cell);

  const {
    value: { name },
  } = cell;

  const { cellVariant, errors, loading, updateLineItemField } = useUpdateLineItem(
    id,
    'priority',
    onLineItemFailureUpdate
  );

  const tooltipMessage = useMemo(() => getTireAndPriorityNamesComboErrorMessage(errors, loading), [errors, loading]);

  const updatePriority = (priority: string): void => updateLineItemField({ name: priority });

  const { priorityNames } = useLineItemPriorities();

  const formattedOptions = priorityNames.map((priorityName) => ({ value: priorityName, label: priorityName }));

  const selectedValue = useMemo(() => ({ value: name, label: enumToTitleCase(name) }), [name]);

  const priorityCellDropdown = (
    <EditableDropdown<string>
      selectedValue={selectedValue}
      onSubmit={updatePriority}
      options={formattedOptions}
      variant={cellVariant}
      dataTestId="priority-name-cell"
      isDisabled={!permissions?.updateLineItem}
    />
  );

  return (
    <div className={block()}>
      {tooltipMessage ? (
        <Tooltip id="priority-name-cell" message={tooltipMessage} place="top">
          {priorityCellDropdown}
        </Tooltip>
      ) : (
        priorityCellDropdown
      )}
    </div>
  );
}

const comparison = (
  prevProps: Readonly<Cell<LineItemModel, LineItemPriorityV5>>,
  nextProps: Readonly<Cell<LineItemModel, LineItemPriorityV5>>
): boolean => prevProps.row.original.id === nextProps.row.original.id && prevProps.value.name === nextProps.value.name;

export default memo(PriorityNameCell, comparison);
