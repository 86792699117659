import './StatusTable.scss';

import React from 'react';

import Button from '../../../../../../../../../../common/Button/Button';
import bem from '../../../../../../../../../../utils/bem';
import { EntityTypeArray } from '../../../../../../../../TraffickingPage/hooks/makePageDrawerPlugin';
import type { MultipleEditDrawerFormValues } from '../../../../../../types';
import MultipleEditDrawerContentTable from '../../../../../MultipleEditDrawerContentTable';
import { useStatusTable } from './hooks/useStatusTable';

const [block, element] = bem('multiple-edit-status-table');

type StatusTableProps = {
  status: 'On' | 'Off';
  isExpanded: boolean;
  readonly?: boolean;
  entityType: EntityTypeArray;
  entitiesIdToIndexMap: Record<string, number>;
  entitiesValues: Pick<MultipleEditDrawerFormValues, 'entities'>['entities'];
};

export default function StatusTable({
  status,
  entitiesValues,
  entitiesIdToIndexMap,
  isExpanded,
  entityType,
  readonly,
}: StatusTableProps): JSX.Element {
  const buttonName = `Turn ${status}`;
  const headerName = `Review ${
    entityType === EntityTypeArray.LINE_ITEM_ARRAY ? 'line items' : 'ads'
  } to turn ${status.toLowerCase()}:`;
  const tableClassName = `${block()} ${isExpanded ? block('expended') : ''}`;
  const buttonClassName = `${element('button')} ${status === 'Off' ? element('button', 'last') : ''}`;

  const {
    changeEntitiesStatus,
    columns,
    data,
    isAnyEntitySelected,
    listenSelectedRows,
    initialSelectedRowIds,
  } = useStatusTable(status, element, entitiesValues, entitiesIdToIndexMap, entityType);

  return (
    <div className={tableClassName}>
      {!readonly && <div className={element('header')}>{headerName}</div>}

      <MultipleEditDrawerContentTable
        isSortable
        addCheckbox={!readonly}
        data={data}
        columns={columns}
        listenSelectedRows={listenSelectedRows}
        initialState={{ selectedRowIds: initialSelectedRowIds }}
      />

      {!readonly && (
        <div className={buttonClassName}>
          <Button size="medium" onClick={changeEntitiesStatus} disabled={!isAnyEntitySelected}>
            {buttonName}
          </Button>
        </div>
      )}
    </div>
  );
}
