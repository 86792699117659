export type SequenceState = {
  searchUnsequencedAdsTerm: string;
  uniqueParentLineOmsLinkIds: string[];
  sequencedAdsOrder: Map<string, number>;
};

export const SEQUENCE_INITIAL_STATE: SequenceState = {
  searchUnsequencedAdsTerm: '',
  sequencedAdsOrder: new Map(),
  uniqueParentLineOmsLinkIds: [],
};
