import { format } from 'date-fns';
import { isString as _isString } from 'lodash';

import type { DropdownOption, DropdownOptionGroup } from '../../../../common/Dropdown';
import { DEFAULT_DATE_FORMAT } from './CustomDateRange/constants';
import type { DateType } from './DateFilter';
import { CUSTOM_DATE } from './makeGroupedDateOptions';

type GroupedDateOption = DropdownOption<DateType>[];

export type SelectedDateValue = {
  label: string;
  value: DateType;
};

export type DateRange = {
  start: Date;
  end: Date;
};

export const getCustomDateDropdownOption = (dates: DateRange | null): DropdownOption<typeof CUSTOM_DATE> | null => {
  if (dates) {
    return {
      value: CUSTOM_DATE,
      label: `${format(dates.start, DEFAULT_DATE_FORMAT)} - ${format(dates.end, DEFAULT_DATE_FORMAT)}`,
    };
  }

  return null;
};

export const getSelectedDropdownOption = (
  groupedDateOptions: DropdownOptionGroup<DateType>[],
  selectedValue?: SelectedDateValue
): DropdownOption<DateType> => {
  const allOptions = groupedDateOptions.reduce<GroupedDateOption>((all, option) => {
    return [...all, ...option.options];
  }, []);
  const selectedOption = allOptions.filter((option) => {
    return option.label === selectedValue?.label;
  });

  return selectedOption[0];
};

export const getSavedDates = (selectedValue?: SelectedDateValue): DateRange | null => {
  if (_isString(selectedValue?.value)) return null;
  if (!selectedValue?.value) return { start: new Date(), end: new Date() };

  const start = new Date(
    selectedValue.value?.start.year,
    selectedValue.value?.start.month - 1,
    selectedValue.value?.start.day
  );
  const end = new Date(selectedValue.value?.end.year, selectedValue.value?.end.month - 1, selectedValue.value?.end.day);

  return { start, end };
};
