import type { AssetExtended } from '../../../_ForCommonLibrary/types/extended';
import type { PreIngestResult } from '../../../../apis/graphql';
import { campaignClient } from '../../../../apis/graphql';
import { AlertType } from '../../../../common/Alert';
import * as Toast from '../../../../common/ToastAlert/toastAlert';
import { SET_ASSET_PUBLISHING_ERROR } from './mutations/setAssetPublishingError';
import { stringifyGQLErrors } from './util';

export function showToast(success: boolean, description?: string): void {
  Toast.openToastAlert({
    alertType: success ? AlertType.SUCCESS : AlertType.ERROR,
    message: `Transcoding Status Update ${success ? 'Successful' : 'Failed'}`,
    description,
  });
}

export async function setAssetPublishingError(asset?: AssetExtended | null): Promise<boolean> {
  if (!asset) {
    showToast(false, 'Unable to update asset because no asset exists');
    return false;
  }

  const { id: assetId, review } = asset;

  if (review !== 'APPROVED' && review !== 'PREVIEW') {
    showToast(
      false,
      `Asset ID '${assetId}' has review state ${review}. Publishing status can ` +
        `only be updated on assets that are in APPROVED or PREVIEW review states.`
    );
    return false;
  }

  try {
    const result = await campaignClient.mutate<{ setAssetPublishingError: PreIngestResult }>({
      mutation: SET_ASSET_PUBLISHING_ERROR,
      variables: { assetId },
    });

    if (result.errors) throw new Error(stringifyGQLErrors(result.errors));

    showToast(true, `Asset ID '${assetId}'`);
  } catch (err) {
    const msg = err instanceof Error && err.message ? err.message : 'Unknown error encountered.';

    showToast(false, msg);
    return false;
  }

  return true;
}
