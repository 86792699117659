import './Button.scss';

import type { ButtonProps } from '@hulu/react-style-components';
import { Button as TrekButton } from '@hulu/react-style-components';
import React from 'react';

import bem from '../../utils/bem';
import Loader from '../Loader';

const [buttonBlock, buttonElement] = bem('button');

const [buttonTextBlock] = bem(buttonElement('text'));

export type Props = { loading?: boolean } & Partial<ButtonProps>;

const Button = ({ loading, disabled, className, ...props }: Props): JSX.Element => (
  <TrekButton
    as="button"
    type="button"
    className={`${className || ''} ${buttonBlock(loading ? 'loading' : '')}`}
    disabled={disabled || loading}
    {...props}
  >
    <>
      {loading && <Loader className={buttonElement('loading-spinner')} />}
      <span className={buttonTextBlock(loading ? 'loading' : '')}>{props.children}</span>
    </>
  </TrekButton>
);

export default Button;
