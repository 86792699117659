import { gql } from '@apollo/client';

// Data for Targeting Dropdowns
export const GET_TARGETING_DIMENSIONS_BY_CATEGORY = gql`
  query getTargetingDimensionsByCategory($categoryGuid: String!) {
    getTargetingDimensionsByCategory(categoryGuid: $categoryGuid) {
      id
      name
      displayName
      primarySource {
        id
        name
        source
        system
      }
      additionalSource {
        id
        name
        source
        system
      }
    }
  }
`;
