import { COLUMN_NAME_ID } from '../../../constants';

export const AdColumn = Object.freeze({
  Alert: 'Alert',
  Name: COLUMN_NAME_ID,
  EditAd: 'EditAd',
  TrackingId: 'TrackingId',
  DeleteAd: 'deleteAd',
  AdID: 'adID',
  Country: 'Country',
  Type: 'Type',
  Status: 'Status',
  Review: 'Review',
  ScheduleStart: 'ScheduleStart',
  ScheduleEnd: 'ScheduleEnd',
  LegalApprovedCreative: 'legalApprovedCreative',
  DisplayPublisherTarget: 'DisplayPublisherTarget',
  ScheduleTimezoneOffset: 'ScheduleTimezoneOffset',
  Rating: 'rating',
  Weight: 'Weight',
  ViewabilityVendorList: 'ViewabilityVendorList',
  FrequencyCapList: 'frequencyCapList',
  CreatedAt: 'CreatedAt',
  UpdatedAt: 'UpdatedAt',
  LineItemName: 'LineItemName',
  LineItemId: 'lineItemId',
  CampaignName: 'CampaignName',
  CampaignId: 'campaignId',
  RotationName: 'RotationName',
  SequenceId: 'SequenceId',
  SequenceName: 'SequenceName',
  SequenceOrder: 'SequenceOrder',
});
