import { useQuery } from '@apollo/client';
import { useState } from 'react';

import type {
  Get_Trafficker_EmailsQuery as GetTraffickerEmailsQuery,
  QueryGetTraffickerEmailsArgs,
  TraffickerEmail as TraffickerEmailV4,
} from '../../../../apis/graphql';
import type { DropdownOption } from '../../../../common/Dropdown';
import { fromEmailToName, normalizedSearchString } from '../../../../utils/common';
import { DEFAULT_LIMIT } from '../../constants';
import { GET_TRAFFICKER_EMAILS } from './queries';
import type { UseGetUsersResult } from './types';

export type TraffickerEmail = Pick<TraffickerEmailV4, 'id' | 'email'>;

const getEmailsFromQuery = (traffickerEmail?: GetTraffickerEmailsQuery['getTraffickerEmails']): TraffickerEmail[] => {
  return traffickerEmail?.edges.map(({ node }) => node) || [];
};

const mapUserDropdownOptions = (traffickerEmails?: TraffickerEmail[]): DropdownOption[] => {
  return (
    traffickerEmails?.map((traffickerEmail) => ({
      label: fromEmailToName(traffickerEmail.email),
      value: traffickerEmail.email,
    })) || []
  );
};

export const useGetTraffickerEmails = (): UseGetUsersResult => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { loading, error, data, fetchMore } = useQuery<GetTraffickerEmailsQuery, QueryGetTraffickerEmailsArgs>(
    GET_TRAFFICKER_EMAILS,
    {
      variables: {
        search: '',
        paginationOptions: { limit: DEFAULT_LIMIT, offset: 0 },
      },
    }
  );

  const searchUsers = async (search: string): Promise<void> => {
    setSearchTerm(search);

    try {
      await fetchMore({
        variables: {
          search: normalizedSearchString(search),
          paginationOptions: { limit: DEFAULT_LIMIT, offset: 0 },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onNext = async (): Promise<void> => {
    try {
      const offset = getEmailsFromQuery(data?.getTraffickerEmails).length;
      await fetchMore({
        variables: {
          paginationOptions: { limit: DEFAULT_LIMIT, offset },
          search: normalizedSearchString(searchTerm),
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const resetOptions = async (): Promise<void> => {
    try {
      await fetchMore({
        variables: {
          search: '',
          paginationOptions: { limit: DEFAULT_LIMIT, offset: 0 },
        },
      });
      setSearchTerm('');
    } catch (e) {
      console.error(e);
    }
  };

  return {
    loading,
    error: error?.message,
    userDropdownOptions: mapUserDropdownOptions(getEmailsFromQuery(data?.getTraffickerEmails)),
    onNext,
    searchUsers,
    resetOptions,
    hasMore: data?.getTraffickerEmails.pageInfo.hasNextPage ?? false,
    searchTerm,
  };
};
