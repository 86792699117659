import type { ApolloError, QueryLazyOptions } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';
import { useMemo } from 'react';

import type { GetRotationsListQuery, GetRotationsListQueryVariables, Rotation } from '../../../../apis/graphql';
import type { LineItem } from '../../../../models';
import { GET_ROTATIONS_LIST } from '../../../Ads/hooks/queries/getRotations';

interface LazyFetchRotationsResponse {
  rotations: Rotation[];
  error?: ApolloError;
  loading: boolean;
  loadRotations: (options?: QueryLazyOptions<GetRotationsListQueryVariables> | undefined) => void;
}

export default function useLazyFetchRotations(lineItemId: LineItem['id']): LazyFetchRotationsResponse {
  const [loadRotations, { loading, error, data }] = useLazyQuery<GetRotationsListQuery, GetRotationsListQueryVariables>(
    GET_ROTATIONS_LIST,
    {
      variables: {
        lineItemId,
      },
    }
  );

  const rotations = useMemo(() => (data ? data.getRotationsV5 : []), [data]);

  return { rotations, loading, error, loadRotations };
}
