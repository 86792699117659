import { useLocation, useNavigate } from '@hulu/react-router-dom';
import { isEqual as _isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useList, usePrevious } from 'react-use';

import type { AdsPageAd } from '../../../../../common/AdForm/hooks/types';
import type { PartialFormState } from '../../../../../common/hooks/useFormStateManager';
import { useFormStateManager } from '../../../../../common/hooks/useFormStateManager';
import { useTrackFormFieldChanges } from '../../../../../common/hooks/useTrackFormFieldChanges';
import { PathName } from '../../../../../constants';
import { workWithLocalStorage } from '../../../../../utils/storage/localStorage';
import { LOCAL_STORAGE_LOCATION_STATE } from '../../../../Trafficking';
import type { LocationState } from '../../../AdsPageController';
import { CHANGE_SELECTED_AD_CONFIRMATION_MSG, DELETE_CONFIRMATION_MSG } from '../../constants';
import type { AdListElement } from '../../types';
import { makeAdListElement, sortAdListByCreatedAtAndStatus } from '../../utils';

export interface AdListState {
  adList: AdListElement[];
  createAd: () => void;
  deleteAd: (adIndex: number) => void;
  selectAd: (adIndex: number, adId?: string) => void;
  selectedAdIndex: number;
}

export default function useAdList(ads: AdsPageAd[], editAdId?: string): AdListState {
  const { initialValues, setFormState, resetForm, currentFormState, prevFormState } = useFormStateManager();
  const { areFormFieldsDirty } = useTrackFormFieldChanges();

  const memoizedMakeAdListElement: (ad?: AdsPageAd) => AdListElement = useCallback(
    (ad?: AdsPageAd) => makeAdListElement(initialValues, ad),
    [initialValues]
  );

  const initialStateValues: { adList: AdListElement[]; selectedAdIndex: number } = useMemo(() => {
    const adList = ads.map(memoizedMakeAdListElement);
    let initialSortedAdList = sortAdListByCreatedAtAndStatus(adList);
    let initialSelectedAdIndex = 0;

    if (!editAdId) {
      const newDraftAdListItem: AdListElement = memoizedMakeAdListElement();
      initialSortedAdList = [newDraftAdListItem, ...initialSortedAdList];
    } else {
      const currentEditAdIndex = initialSortedAdList.findIndex((adListElement) => adListElement.ad.id === editAdId);

      if (currentEditAdIndex !== -1) {
        initialSelectedAdIndex = currentEditAdIndex;
      }
    }

    return { adList: initialSortedAdList, selectedAdIndex: initialSelectedAdIndex };
  }, [ads, memoizedMakeAdListElement, editAdId]);

  const [adList, { set, insertAt, removeAt, updateAt }] = useList<AdListElement>(initialStateValues.adList);
  const prevAds = usePrevious<AdsPageAd[]>(ads);
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedAdIndex, setSelectedAdIndex] = useState<number>(initialStateValues.selectedAdIndex);
  const [adId, setAdId] = useState<string | null>(null);
  const [isAdDeleting, setIsAdDeleting] = useState<boolean>(false);
  const [isAdSelecting, setIsAdSelecting] = useState<boolean>(false);
  const [isAdCreating, setIsAdCreating] = useState<boolean>(false);

  const setLocation = useCallback(
    (pathname: PathName, id?: string | null): void => {
      const localLocationState = workWithLocalStorage.getData<LocationState>(LOCAL_STORAGE_LOCATION_STATE);

      const state = {
        ...location.state,
        editAdId: id,
        lineItemState: { ...((location.state as LocationState)?.lineItemState || localLocationState?.lineItemState) },
      };

      navigate({ pathname, search: location.search }, { replace: true, state });
    },
    [location.search, location.state, navigate]
  );

  const createAd = useCallback(() => {
    if (areFormFieldsDirty && !window.confirm(CHANGE_SELECTED_AD_CONFIRMATION_MSG)) return;

    const newDraftAdListItem: AdListElement = memoizedMakeAdListElement();
    // Insert a new ad, select it, and update the form state
    insertAt(0, newDraftAdListItem);
    setSelectedAdIndex(0);
    setIsAdCreating(true);

    resetForm();
  }, [memoizedMakeAdListElement, insertAt, resetForm, areFormFieldsDirty]);

  const updateAd = useCallback(
    (formikState: PartialFormState, dirty: boolean = false) => {
      const { ad, formikState: storedState } = adList[selectedAdIndex];
      updateAt(selectedAdIndex, {
        ad: { ...ad, dirty },
        formikState: { ...storedState, ...formikState },
      });
    },
    [adList, selectedAdIndex, updateAt]
  );

  const deleteAd = useCallback(
    (adIndex: number) => {
      // if currently selected ad has a dirty form and user confirms deletion, delete the ad
      if (areFormFieldsDirty && !window.confirm(DELETE_CONFIRMATION_MSG)) return;

      removeAt(adIndex);
      setSelectedAdIndex(0);
      setIsAdDeleting(true);

      resetForm();
    },
    [areFormFieldsDirty, removeAt, resetForm]
  );

  const selectAd = useCallback(
    (adIndex: number, adId?: string | null) => {
      const locationState = location.state as LocationState;

      if (locationState?.editAdId === adId) return;
      if (areFormFieldsDirty && !window.confirm(CHANGE_SELECTED_AD_CONFIRMATION_MSG)) return;

      setAdId(adId ?? null);
      setSelectedAdIndex(adIndex);
      setIsAdSelecting(true);

      resetForm();
    },
    [resetForm, location.state, areFormFieldsDirty]
  );

  useEffect(() => {
    // takes incoming new 'ads' and update adList to reflect any creates or updates
    if (prevAds && !_isEqual(ads, prevAds)) {
      const newAdList = ads.map(memoizedMakeAdListElement);
      let sortedNewAdList = sortAdListByCreatedAtAndStatus(newAdList);

      if (ads.length === prevAds.length && adList[0].ad.id === '') {
        const newDraftAdListItem: AdListElement = memoizedMakeAdListElement();
        sortedNewAdList = [newDraftAdListItem, ...sortedNewAdList];
      }

      set(sortedNewAdList);
    }
  }, [ads, prevAds, memoizedMakeAdListElement, set, adList]);

  useEffect(() => {
    if (isAdDeleting) {
      setIsAdDeleting(false);

      if (adList.length) {
        setLocation(PathName.adsEdit, adList[0].ad.id);
      }
    }
  }, [isAdDeleting, setLocation, adList]);

  useEffect(() => {
    if (isAdSelecting) {
      setIsAdSelecting(false);
      setLocation(adId ? PathName.adsEdit : PathName.adsCreate, adId);
    }
  }, [isAdSelecting, adId, setIsAdSelecting, setLocation]);

  useEffect(() => {
    if (isAdCreating) {
      setIsAdCreating(false);
      setLocation(PathName.adsCreate);
    }
  }, [isAdCreating, setLocation]);

  // if the form has changed (and _not_ reset), update the AdListElement
  useEffect(() => {
    if (adList.length < 1) return;

    const { ad } = adList[selectedAdIndex];

    // for 'ad.dirty !== dirty' conditional: if the Ad List Ad's dirty state not equal the current form's dirty state,
    // update to form's dirty state only update when it changes to avoid an infinite loop
    if ((areFormFieldsDirty && currentFormState !== prevFormState) || ad.dirty !== areFormFieldsDirty) {
      updateAd(currentFormState, areFormFieldsDirty);
    }
  }, [adList, adList.length, currentFormState, areFormFieldsDirty, prevFormState, selectedAdIndex, updateAd]);

  // if the user selects an AdListElement, set the form's state to that AdListElement's stored state
  useEffect(() => {
    if (adList.length < 1) return;

    setFormState(adList[selectedAdIndex].formikState);
    // Updates to the ad list (including regular form updates) would cause way too many renders
    // so instead, we'll just run this effect when either selected ad switches or the ad list's shape changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAdIndex, adList.length]);

  return { adList, createAd, deleteAd, selectAd, selectedAdIndex };
}
