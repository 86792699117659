import { useQuery } from '@apollo/client';
import { User } from 'mission-control-common-components';
import React, { memo, useState } from 'react';
import type { Cell } from 'react-table';

import type {
  GetLineItemsWithTargetingsQuery,
  GetLineItemsWithTargetingsQueryVariables,
} from '../../../../../apis/graphql';
import { AlertType } from '../../../../../common/Alert';
import useNavigationWithLocalStorage from '../../../../../common/hooks/useNavigationWithLocalStorage';
import IconButton from '../../../../../common/IconButton';
import Loader from '../../../../../common/Loader';
import { openToastAlert } from '../../../../../common/ToastAlert/toastAlert';
import type { PermissionsNames } from '../../../../../constants';
import shallowEqual from '../../../../../utils/shallowEqual';
import { GET_LINE_ITEMS_WITH_TARGETINGS } from '../../hooks/queries/getLineItemsWithTargetings';
import type { AdModel, LineItemModel } from '../../modelConverters';
import { lineItemWithTargetingsNodeToModel } from '../../modelConverters/lineItemWithTargetingsConvertor';
import { CellType } from '../../types';
import { getLocation, isNavigateAllowed } from './utils';

type Props = {
  cell: Cell<LineItemModel> | Cell<AdModel>;
  cellType: CellType;
};

const CreateAndEditAdCell = ({ cell, cellType }: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const { refetch } = useQuery<GetLineItemsWithTargetingsQuery, GetLineItemsWithTargetingsQueryVariables>(
    GET_LINE_ITEMS_WITH_TARGETINGS,
    {
      skip: true,
    }
  );
  const permissions = User.getPermissions<PermissionsNames>();
  const isCreateMode = cellType === CellType.create;

  const { navigateWithLocalStorage } = useNavigationWithLocalStorage();

  const clickHandler = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
    e.stopPropagation();
    if (!isNavigateAllowed(cell.row)) return;
    try {
      const lineItemId = cellType === CellType.create ? cell.row.id : (cell as Cell<AdModel>).row.original.lineItem?.id;

      if (!lineItemId) return;
      setLoading(true);
      const { data: lineItems } = await refetch({
        ids: [lineItemId],
      });

      if (!lineItems.lineItems.edges[0]) return;
      const lineItem = lineItems.lineItems.edges[0].node;
      const lineItemModel = lineItemWithTargetingsNodeToModel(lineItem);
      const location = getLocation(lineItemModel, cellType);

      const locationState = {
        lineItemState: location.state,
        editAdId: isCreateMode ? undefined : cell.row.id,
      };

      navigateWithLocalStorage(e, location, locationState);
    } catch (e) {
      openToastAlert({
        alertType: AlertType.ERROR,
        message: 'Something went wrong. Please try later.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <span className="create-and-edit-cell" data-testid="create-and-edit-cell">
      {loading && <Loader />}
      {!loading && (
        <IconButton
          className={'create-and-edit-cell__button'}
          icon={isCreateMode ? 'IconCirclePlus' : 'IconEdit'}
          variant="outlined"
          aria-label={isCreateMode ? 'create ad' : 'edit ad'}
          disabled={isCreateMode ? !permissions?.createAd : !permissions?.updateAd}
          onClick={clickHandler}
          data-testid="create-and-edit-cell-button"
        />
      )}
    </span>
  );
};

const comparison = (prev: Props, next: Props): boolean => shallowEqual(prev.cell.row, next.cell.row);

export default memo(CreateAndEditAdCell, comparison);
