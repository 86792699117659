import styled from 'styled-components';

import { theme } from '../../../../styles/styled';

export const Preview = styled.div<{ imageUrl: string }>`
  position: relative;
  height: 7em;
  margin-bottom: 0.25em;

  background-color: ${theme.color.design.gray2};
  background-size: cover;
  background-image: url(${(props): string => props.imageUrl});
`;
