import { NetworkStatus, useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useList } from 'react-use';

import type { ChangeLogEntryEdge, GetChangeLogByAdObjectIdQuery, TargetingChangesMap } from '../../../apis/graphql';
import type { FilterVariables } from '../ChangeLogList/usePagination';
import { limitAudits, notifyOnNetworkStatusChange } from '../constants';
import type { ChangeLogEntry } from '../types';
import { transformTargetingToSameType } from '../utils';
import { GET_CHANGE_LOG } from './queries';

interface UseChangeLogProps {
  id?: string;
  entityType?: string;
}

export type TargetingEntry = TargetingChangesMap | null;
export type Toggles = boolean | boolean[];

interface UseChangeLogResult {
  changeLogEntries?: ChangeLogEntryNode[];
  targetingEntries?: TargetingEntry[];
  toggles: Toggles[];
  toggleAt: (index: number, value: boolean | boolean[]) => void;
  toggleAll: (isOpen: boolean) => void;
  loading: boolean;
  loadingFilter: boolean;
  setLoadingFilter: (isLoading: boolean) => void;
  refetch: (variables?: FilterVariables) => void;
  total?: number;
}

export type ChangeLogEntryNode = {
  __typename?: string;
  node: {
    __typename?: string;
    audits: ChangeLogEntry;
    creativeAudits: ChangeLogEntry[];
    targetingChanges?: TargetingChangesMap;
  };
};

export type Audits = {
  __typename?: string;
  audits: ChangeLogEntry;
  creativeAudits: ChangeLogEntry[];
};

type GetVariables = { id?: string; entityType: string | undefined };
type GetVariablesResult = {
  id?: string;
  entityType?: string;
  paginationOptions: {
    limit: number;
    offset: number;
  };
};

const getVariables = ({ id, entityType }: GetVariables): GetVariablesResult => {
  const defaultVariables = {
    id,
    paginationOptions: {
      limit: limitAudits,
      offset: 0,
    },
  };

  return entityType === 'Ad'
    ? {
        entityType: 'AD',
        ...defaultVariables,
      }
    : {
        ...defaultVariables,
      };
};

export default function useChangeLog({ entityType, id }: UseChangeLogProps): UseChangeLogResult {
  const [changeLogEntries, setChangeLogEntries] = useState<ChangeLogEntryNode[]>([]);
  const [loadingFilter, setLoadingFilter] = useState(false);

  const { data, loading, refetch, networkStatus } = useQuery<GetChangeLogByAdObjectIdQuery>(GET_CHANGE_LOG, {
    variables: getVariables({ id, entityType }),
    skip: !id,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: notifyOnNetworkStatusChange,
    onCompleted: () => {
      setLoadingFilter(false);
    },
  });

  useEffect(() => {
    if (networkStatus === NetworkStatus.ready) {
      setChangeLogEntries(data?.getChangeLogByAdObjectID?.edges as ChangeLogEntryNode[]);
    }
  }, [data, networkStatus]);

  const [toggles, { set, updateAt, update }] = useList<boolean | boolean[]>([]);

  useEffect(() => {
    const mixedTogglesWithCreatives = changeLogEntries?.map((change) => {
      if (change.node.creativeAudits.length) {
        const creativesArr = new Array(change.node.creativeAudits.length).fill(false);
        return [false, ...creativesArr];
      }

      return false;
    });

    if (changeLogEntries && !loading && changeLogEntries.length) {
      set(toggles.length ? [...toggles, ...mixedTogglesWithCreatives] : [...mixedTogglesWithCreatives]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLogEntries, loading, set]);

  const toggleAt = useCallback(
    (index: number, value: boolean | boolean[]) => {
      const newValue = value === undefined ? !toggles[index] : value;
      updateAt(index, Array.isArray(value) ? value.map((val) => !val) : !newValue);
    },
    [toggles, updateAt]
  );

  const toggleAll = useCallback(
    (isOpen: boolean | boolean[]) => {
      update((isOpen) => Array.isArray(isOpen), [isOpen, isOpen] as boolean[]);
      update((isOpen) => !Array.isArray(isOpen), isOpen);
    },

    [update]
  );

  const transformedChangeLogEntries = transformTargetingToSameType(changeLogEntries);

  let targetingEntries: TargetingEntry[] = [];

  try {
    targetingEntries = mapTargetingChanges(data);
  } catch (error) {
    console.error('processTargetingChanges failure', { error, changeLogEntries });
  }
  return {
    changeLogEntries: transformedChangeLogEntries,
    targetingEntries,
    toggles,
    toggleAt,
    toggleAll,
    loading,
    loadingFilter,
    setLoadingFilter,
    refetch,
    total: data?.getChangeLogByAdObjectID?.total,
  };
}

export function mapTargetingChanges(
  changeLogResponse: GetChangeLogByAdObjectIdQuery | undefined
): (TargetingChangesMap | null)[] {
  if (!changeLogResponse?.getChangeLogByAdObjectID?.edges.length) {
    return [];
  }
  const edges = changeLogResponse.getChangeLogByAdObjectID.edges as ChangeLogEntryEdge[];
  return edges.map((edge: ChangeLogEntryEdge) => {
    return edge?.node?.targetingChanges || null;
  });
}
