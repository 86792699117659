import '../CellItems.scss';

import React from 'react';

import { ChangeAction } from '../../../../../../apis/graphql';
import type { DateRange } from '../../../../../../models';
import bem from '../../../../../../utils/bem';
import { GreenPlus } from '../../../../../assets';
import type { CellItemsProps } from '../index';
import { formattedKey } from './utils';

const [block, element] = bem('multiple-change-log-cell');

type Change = Record<string, string | DateRange>;

function DateRangeCell({ changes }: CellItemsProps): JSX.Element {
  const dateRangePayload = (!Array.isArray(changes) && Array.isArray(changes.payload) && changes.payload) || [];

  const renderDateRangeChanges = dateRangePayload.map((change: Change, index) => {
    const isChangeObject = typeof change.value === 'object' && !Array.isArray(change.value) && change.value !== null;
    const removalClass = change.action === ChangeAction.Removal ? 'removal' : '';

    if (isChangeObject) {
      return (
        <>
          {change.action === ChangeAction.Addition && (
            <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />
          )}
          <div>
            {Object.entries(change.value).map(([key, value]) => (
              <div
                className={element('date-range', `${removalClass} ${key === 'timezone' ? 'timezone-value' : ''}`)}
                key={key}
              >
                <span>
                  <strong>{formattedKey(key)}: </strong>
                </span>
                <span>{value !== 'undefined' ? value : '-'}</span>
              </div>
            ))}
          </div>
        </>
      );
    }

    return (
      <span key={index} className={element('date-range-simple-value')}>
        {change.value}
      </span>
    );
  });

  return (
    <div className={block()}>
      <div>
        {renderDateRangeChanges.map((item, index) => (
          <div key={index} className={element('date-range-wrapper')}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DateRangeCell;
