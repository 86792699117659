import { PillColor } from '../../../../../../../../../../common/Pill';
import { lineItemDerivedState, lineItemStatuses } from '../../../../../../../../../../configs';
import type { MultipleEditDrawerFormValues } from '../../../../../../types';
import type { StatusTableData } from '../types';

export const getEntities = (
  entitiesValues: Array<Pick<MultipleEditDrawerFormValues, 'entities'>['entities'][0]>
): StatusTableData[] => entitiesValues.map(({ id, name, status }) => ({ id, name, status }));

export const colorPills = {
  [lineItemDerivedState.DID_NOT_RUN.displayName]: PillColor.YELLOW_DARK,
  [lineItemStatuses.ON.displayName]: PillColor.GREEN,
  [lineItemStatuses.OFF.displayName]: PillColor.GRAY,
  [lineItemDerivedState.ENDED.displayName]: PillColor.GRAY,
  [lineItemStatuses.CANCELLED.displayName]: PillColor.RED,
  [lineItemStatuses.PAUSED.displayName]: PillColor.ORANGE,
};
