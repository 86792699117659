function numToDoubleDigit(value: number): string {
  return Math.floor(value).toString().padStart(2, '0');
}

/**
 * Converts a milisecond time value into a human-readable time string
 */
export function msToTime(milliseconds?: number | null): string {
  const ms = milliseconds ?? 0;
  const seconds = numToDoubleDigit((ms / 1000) % 60);
  const minutes = numToDoubleDigit((ms / (1000 * 60)) % 60);
  const hours = numToDoubleDigit(ms / (1000 * 60 * 60));

  return `${hours}:${minutes}:${seconds}`;
}
