import type { CreativeReviewStatus, UNRECOGNIZED } from '../../../../configs';
import type { Nullable } from '../../../../models';
import { AlertType } from '../../../Alert';
import type { AlertProps } from '../../../Alert/Alert';

export const reviewAlertPropsMap: {
  [key in CreativeReviewStatus | typeof UNRECOGNIZED | 'PENDING' | 'PUBLISHED']: Nullable<AlertProps>;
} = {
  APPROVED: {
    type: AlertType.SUCCESS,
    bodyText: 'This creative has been approved!',
  },
  PUBLISHED: {
    type: AlertType.SUCCESS,
    bodyText: 'This creative has been published!',
  },
  PREVIEW: {
    type: AlertType.INFORMATION,
    bodyText: 'This creative is a preview.',
  },
  REVIEW: {
    type: AlertType.INFORMATION,
    bodyText: 'This creative is currently under review.',
  },
  REJECTED: {
    type: AlertType.ERROR,
    bodyText: 'This creative has been rejected!',
  },
  PENDING: {
    type: AlertType.INFORMATION,
    bodyText: 'This creative is currently under review.',
  },
  UNDER_REVIEW: {
    type: AlertType.INFORMATION,
    bodyText: 'This creative is currently under review.',
  },
  DRAFT: null,
  UNRECOGNIZED: null,
};
