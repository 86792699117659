import type { IStorage } from './types';

export class BrowserStorage implements IStorage {
  private readonly storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  getData = <T>(key: string): T | undefined => {
    try {
      const serializedState = this.storage.getItem(key);
      if (serializedState === null) {
        return undefined;
      }

      return JSON.parse(serializedState);
    } catch (error) {
      return undefined;
    }
  };

  setData = <T>(key: string, value: T): void => {
    try {
      this.storage.setItem(key, JSON.stringify(value));
    } catch (error) {
      // Ignore write errors
    }
  };

  removeData = (key: string): void => {
    try {
      this.storage.removeItem(key);
    } catch (error) {
      // Ignore write errors
    }
  };
}
