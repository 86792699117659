import type { Row } from 'react-table';

import type { TargetingTermValue } from '../../../models';
import type { TargetingTableRowGroup } from '../TargetingTable';

export const assignIdFromUnsortedData = (
  data: TargetingTableRowGroup[],
  groupType: string,
  termValueRow: Row<TargetingTermValue>
): number =>
  data
    .find((unsortedRowGroup) => unsortedRowGroup.groupType === groupType)!
    .targetingTermValues.findIndex(
      (unsortedTermValue) => unsortedTermValue?.value?.displayName === termValueRow?.values?.value?.displayName
    );
