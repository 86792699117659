import React from 'react';

import type { LineItemStatusError } from '../../../MultipleEditDrawerContentThirdStep';
import {
  LINE_ITEM_DOES_NOT_CONTAIN_ACTIVE_AD_MESSAGE,
  LINE_ITEMS_DO_NOT_CONTAIN_ACTIVE_ADS_MESSAGE,
} from '../../getRows/constants';
import type { GetLineItemConflictMessagesResult } from './useGetAllConflicts';

type GetLineItemConflictsMessagesParams = {
  statusErrors: LineItemStatusError;
};

type RenderErrorMessageForUnapprovedLineItemsParams = {
  isMultipleUnapprovedConflict: boolean;
  lineItemsWithoutApproved?: string[];
};

type RenderErrorMessageForLineItemsWithoutActiveAdParams = {
  isMultipleInactiveAdConflicts: boolean;
  lineItemsWithoutActiveAd?: string[];
};

export const renderErrorMessageForUnapprovedLineItems = ({
  isMultipleUnapprovedConflict,
  lineItemsWithoutApproved,
}: RenderErrorMessageForUnapprovedLineItemsParams): JSX.Element => {
  return (
    <>
      <div>
        Cannot activate all. Some line {isMultipleUnapprovedConflict ? 'items' : 'item'} not approved in Campaign
        Review. Line Item {isMultipleUnapprovedConflict ? 'IDs' : 'ID'} {lineItemsWithoutApproved?.join(`, `)}
      </div>
    </>
  );
};

export const renderErrorMessageForLineItemsWithoutActiveAd = ({
  isMultipleInactiveAdConflicts,
  lineItemsWithoutActiveAd,
}: RenderErrorMessageForLineItemsWithoutActiveAdParams): JSX.Element => {
  return (
    <>
      <div>
        Change not applied to Line Item {isMultipleInactiveAdConflicts ? 'IDs ' : 'ID '}
        {lineItemsWithoutActiveAd?.join(', ')}
      </div>
      <div>
        {isMultipleInactiveAdConflicts
          ? LINE_ITEMS_DO_NOT_CONTAIN_ACTIVE_ADS_MESSAGE
          : LINE_ITEM_DOES_NOT_CONTAIN_ACTIVE_AD_MESSAGE}
      </div>
    </>
  );
};

export const getLineItemConflictMessages = ({
  statusErrors,
}: GetLineItemConflictsMessagesParams): GetLineItemConflictMessagesResult => {
  const isMultipleInactiveAdConflicts = !!(
    statusErrors?.lineItemsWithoutActiveAd && statusErrors.lineItemsWithoutActiveAd.length > 1
  );

  const isMultipleUnapprovedConflict = !!(
    statusErrors?.lineItemsWithoutApproved && statusErrors?.lineItemsWithoutApproved.length > 1
  );

  const hasInactiveAd = statusErrors?.lineItemsWithoutActiveAd && statusErrors?.lineItemsWithoutActiveAd?.length > 0;
  const hasUnapprovedLineItemStatus =
    statusErrors?.lineItemsWithoutApproved && statusErrors?.lineItemsWithoutApproved?.length > 0;

  const errorInactiveAd = hasInactiveAd
    ? renderErrorMessageForLineItemsWithoutActiveAd({
        lineItemsWithoutActiveAd: statusErrors.lineItemsWithoutActiveAd,
        isMultipleInactiveAdConflicts,
      })
    : null;

  const errorUnapproved = hasUnapprovedLineItemStatus
    ? renderErrorMessageForUnapprovedLineItems({
        isMultipleUnapprovedConflict,
        lineItemsWithoutApproved: statusErrors.lineItemsWithoutApproved,
      })
    : null;

  return { errorUnapproved, errorInactiveAd };
};
