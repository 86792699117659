import React from 'react';

import SideNav from '../../../common/SideNav';

export interface SequencePageProps {
  className?: string;
  containerId?: string;
  links?: string[];
  error?: string;
}

export enum SequencePageFormSection {
  DETAILS = 'Details',
}

function SequencePageSideNav({
  className,
  containerId = '',
  links = Object.values(SequencePageFormSection),
}: SequencePageProps): React.JSX.Element {
  return <SideNav links={links} containerId={containerId} testid="sequence-page-side-nav" className={className} />;
}

export default SequencePageSideNav;
