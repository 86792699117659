import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

import React from 'react';
import SwiperCore, { Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';

import type { AssetExtended } from '../../../types/extended';
import { AssetPreviewCard } from '../../molecules/AssetPreviewCard';
import { SwiperWrapper, Title } from './styles';

export const AssetSlider: React.FC<{
  title: string;
  assets?: AssetExtended[];
  creativeId: string;
}> = (props) => {
  SwiperCore.use([Navigation]);

  return (
    <SwiperWrapper>
      <Title>{props.title}</Title>
      <Swiper navigation slidesPerView={3} slidesPerGroup={3}>
        {props.assets?.map((asset) => (
          <SwiperSlide key={asset.id}>
            <AssetPreviewCard asset={asset} creativeId={props.creativeId} />
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperWrapper>
  );
};
