import type { CampaignTagV5 } from '../../../apis/graphql';
import { CountryCode } from '../../../apis/graphql';
import type { BillableThirdParty, DisplayEnum } from '../../../configs';
import { adTagEvents, adTagTypes, billableThirdParties } from '../../../configs';
import type { AdTag, Nullable } from '../../../models';

export type NielsenTagProps = {
  lineItem_billableThirdParty: Nullable<DisplayEnum<BillableThirdParty>>;
  lineItem_isCoppaOrCaru: Nullable<boolean>;
  campaignTagList: Nullable<Pick<CampaignTagV5, 'type' | 'url'>[]>;
  lineItem_country?: string;
  isPausedAd?: boolean;
};

export const NIELSEN_TAG_HOST = 'https://secure-us.imrworldwide.com';
export const NIELSEN_DISNEY_ACCOUNT_ID = 'ci=nlsnapi11237';
export const NIELSEN_DISNEY_NEW_ACCOUNT_ID = 'ci=nlsnci767';
export const NIELSEN_DISNEY_AD_SERVER_ID = 'am=37';

export function isNielsenTag(adTagUrl: string): boolean {
  return (
    adTagUrl.startsWith(NIELSEN_TAG_HOST) &&
    adTagUrl.includes(NIELSEN_DISNEY_AD_SERVER_ID) &&
    (adTagUrl.includes(NIELSEN_DISNEY_ACCOUNT_ID) || adTagUrl.includes(NIELSEN_DISNEY_NEW_ACCOUNT_ID))
  );
}

export function getNielsenTag({
  lineItem_billableThirdParty,
  lineItem_isCoppaOrCaru,
  campaignTagList,
  lineItem_country,
  isPausedAd,
}: NielsenTagProps): Nullable<AdTag> {
  // Do not generate Nielsen tags on ads where Line Item IsCoppaOrCaru = TRUE or has pause status
  if (lineItem_isCoppaOrCaru || lineItem_country !== CountryCode.Us || isPausedAd) return null;

  // Do not generate Nielsen tags if campaign tag is not present
  const nielsenCampaignTag = campaignTagList?.find((tag) => tag.type?.toUpperCase() === 'NIELSEN');
  if (!nielsenCampaignTag) return null;
  // SPEAR-31: Generate Nielsen DAR tag as "billable" type and in complete position in Mission Control if LineItemBillableThirdParty =
  //        Nielsen_DTA_Demo
  //        Nielsen_DTA_P2
  // Generate Nielsen tag in "non-billable" type, in the complete position for all other LineItemBillableThirdParty values
  let nielsenTagType = adTagTypes.NON_BILLABLE;
  const nielsenTagEvent = adTagEvents.COMPLETE;
  if (
    lineItem_billableThirdParty?.key === billableThirdParties.NIELSEN_DTA_DEMO.key ||
    lineItem_billableThirdParty?.key === billableThirdParties.NIELSEN_DTA_P2.key
  ) {
    nielsenTagType = adTagTypes.BILLABLE;
  }

  const nielsenTag: AdTag = {
    id: '',
    event: nielsenTagEvent,
    isVerified: true,
    type: nielsenTagType,
    url: nielsenCampaignTag.url!,
    unDeletable: true,
    isIas: false,
  };
  return nielsenTag;
}

export const NIELSEN_CUTOFF_DAYS = 450;
export const NIELSEN_TAGS_COPPA_INFO = 'Nielsen DAR Tag does not generate on COPPA/CARU Line Items.';
export const NIELSEN_TAGS_FUTURE_CAMPAIGN_INFO = `Nielsen DAR Tag does not generate on campaigns that start in ${NIELSEN_CUTOFF_DAYS}+ days.`;
export const NIELSEN_TAGS_ERROR_MESSAGE =
  'Nielsen DAR Tag did not generate. DAR Tag is created hourly, please contact Mission Control Support team if it is not created in an hour.';
export const NIELSEN_TAGS_PAUSED_INFO = 'Nielsen DAR tag will not be generated on this ad type';

export type NielsenTagWarning = {
  message: string;
  isError: boolean;
};

export const generateNielsenErrorMessage = (
  adTagList: AdTag[],
  isCoppaOrCaru: Boolean,
  campaignStartDate: string,
  lineItemCountry?: string,
  isPausedAd?: boolean
): Nullable<NielsenTagWarning> => {
  const hasNielsenTag = adTagList?.some((tag) => isNielsenTag(tag.url));
  if (hasNielsenTag) {
    return null;
  }

  // No Nielsen Tag tracking for Campaign starting in 450+ days
  if (isCampaignStartingPastCutoff(campaignStartDate)) {
    return { message: NIELSEN_TAGS_FUTURE_CAMPAIGN_INFO, isError: true };
  }

  // No Nielsen Tags in CoppaOrCaru Line Item
  if (isCoppaOrCaru) {
    return { message: NIELSEN_TAGS_COPPA_INFO, isError: false };
  }

  if (lineItemCountry !== CountryCode.Us) {
    return null;
  }

  // No Nielsen Tags in Paused ads
  if (isPausedAd) {
    return { message: NIELSEN_TAGS_PAUSED_INFO, isError: false };
  }

  // Generic Error Message for missing Nielsen Tag
  return { message: NIELSEN_TAGS_ERROR_MESSAGE, isError: true };
};

const isCampaignStartingPastCutoff = (campaignStartDate: string): boolean => {
  if (campaignStartDate) {
    let nielsenCutoffDate = new Date();
    nielsenCutoffDate.setDate(nielsenCutoffDate.getDate() + NIELSEN_CUTOFF_DAYS);
    const startDate = new Date(campaignStartDate);

    return nielsenCutoffDate < startDate;
  } else {
    return false;
  }
};
