import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Loader from '../../Loader';

export const SearchResultsWrapper: React.FC<{
  children: JSX.Element;
  hasMore?: boolean;
  hasPagination?: boolean;
  loaderClass: string;
  numResults: number;
  onNext?: () => void;
}> = (props) => {
  if (!props.hasPagination) return props.children;

  return (
    <InfiniteScroll
      scrollableTarget="scrollableDiv"
      dataLength={props.numResults}
      loader={
        <div className={props.loaderClass}>
          <Loader />
        </div>
      }
      next={props.onNext ?? ((): void => {})}
      hasMore={!!props.hasMore}
    >
      {props.children}
    </InfiniteScroll>
  );
};
