import type { MultipleEditDrawerLineItemsFormValues } from '../../../../../../types';
import { getIsTierNameHasConflictByPacingType } from './utils';

type Props = {
  values: MultipleEditDrawerLineItemsFormValues;
};

export const checkPriorityConflicts = ({ values }: Props): string[] => {
  const isPrioritySectionSelected = values.selectedSections.includes('Priority');

  if (!isPrioritySectionSelected) {
    return [];
  }

  return values.initialStatuses
    .filter(
      (entity) =>
        entity.isSelected &&
        getIsTierNameHasConflictByPacingType({ pacingType: entity.pacingType, tier: values.priority.tier })
    )
    .map((entity) => entity.id);
};
