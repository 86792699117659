import './CreativeBreadcrumb.scss';

import { IconChevronRight } from '@hulu-react-style-components/icons';
import { Typography } from '@hulu/react-style-components';
import type { ModalProps } from 'antd';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import ModalPopup from '../../../common/ModalPopup';
import bem from '../../../utils/bem';
import type { AdFormValues } from '../adFormik';
import { isCreativeBuilderEmpty, resetCreativeBuilder } from '../adFormik';

const [block, element] = bem('creative-breadcrumb');

type CreativeBreadcrumbProps = {
  readonly?: boolean;
};

const modalProps: ModalProps = {
  cancelText: 'No, do not restart',
  okText: 'Yes, restart creative',
  title: 'Restart Creative',
};

const CreativeBreadcrumb = ({ readonly }: CreativeBreadcrumbProps): React.JSX.Element => {
  const {
    values: { creative, type: adType },
    setFieldValue,
  } = useFormikContext<AdFormValues>();

  const isExistingCreative = !!creative.id;
  const modalMessage = isExistingCreative
    ? 'Are you sure you want to remove this creative and start over?'
    : 'Are you sure you want to start over? All progress on the current creative will be lost.';

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  if (creative.mode === 'selectOption') {
    return <span className={block()}>Creative</span>;
  }

  const goBackToSelectOptions = (): void => {
    resetCreativeBuilder(adType, setFieldValue, creative.altText, creative.creativeLibraryId || '');
    setIsConfirmModalVisible(false);
  };

  // if we should show a warning, use the modal (which will itself call goBackToSelectOptions)
  const handleClick: () => void = !isCreativeBuilderEmpty(creative, adType)
    ? (): void => setIsConfirmModalVisible(true)
    : goBackToSelectOptions;

  const confirmationModal = (
    <ModalPopup
      visible={isConfirmModalVisible}
      onOk={goBackToSelectOptions}
      onCancel={(): void => setIsConfirmModalVisible(false)}
      modalMessage={modalMessage}
      modalProps={modalProps}
    />
  );

  return (
    <span className={block()}>
      <button
        className={element('selectOption-button')}
        onClick={handleClick}
        disabled={readonly}
        data-testid="clear-creative"
      >
        <Typography variant="link1">Creative</Typography>
      </button>
      <IconChevronRight />
      <Typography variant="p">{creative.mode === 'search' || isExistingCreative ? 'Library' : 'Builder'}</Typography>
      {confirmationModal}
    </span>
  );
};

export default CreativeBreadcrumb;
