import { useQuery } from '@apollo/client';

import type { GetCreativeByIdQuery } from '../../../../apis/graphql';
import type { Creative, Nullable } from '../../../../models';
import { GET_CREATIVE_BY_ID } from '../../CreativeSearcher/hooks/queries/getCreativeBuilderCreative';

interface UseCreativeIdProps {
  creativeId?: Nullable<Creative['id']>;
}
interface UseCreativeIDResult {
  data?: GetCreativeByIdQuery;
}

export default function useCreativeId({ creativeId }: UseCreativeIdProps): UseCreativeIDResult {
  const { data } = useQuery<GetCreativeByIdQuery>(GET_CREATIVE_BY_ID, {
    variables: { id: creativeId },
    skip: !creativeId,
  });

  return { data };
}
