import { User } from 'mission-control-common-components';
import React, { memo, useMemo } from 'react';
import type { Cell } from 'react-table';

import { commonCustomComponents } from '../../../../../common/Dropdown/Dropdown';
import EditableDropdown from '../../../../../common/EditableTableCells/EditableDropdown';
import type { PermissionsNames } from '../../../../../constants';
import { cellPropsComparison, fromEmailToName } from '../../../../../utils/common';
import { useGetTraffickerEmails } from '../../../TableFilterBar/hooks';
import MenuList from '../../../TableFilterBar/MenuList';
import { useUpdateCampaign } from '../../hooks';
import type { CampaignModel } from '../../modelConverters';

type Props = Pick<Cell<CampaignModel, string>, 'value' | 'row'>;

const CampaignLeadCell: React.FC<Props> = (cell) => {
  const permissions = User.getPermissions<PermissionsNames>();

  const { userDropdownOptions, loading, error, onNext, hasMore, searchUsers } = useGetTraffickerEmails();

  const handleSearch = (value: string): void => {
    searchUsers(value);
  };

  const { id } = cell.row.original;
  const { value: cellValue } = cell;
  const { cellVariant, updateCampaignField } = useUpdateCampaign(id, 'traffickerEmail');

  const selectedValue = userDropdownOptions.find((option) => option.value === cellValue);

  const components = useMemo(
    () => ({
      ...commonCustomComponents,
      MenuList,
    }),
    []
  );

  return (
    <EditableDropdown<string>
      classNameModifier="campaign-lead-cell"
      // If no selectedValue in userDropdownOptions, show the cellValue (traffickerEmail) instead
      // This handles scenarios like a removed user that still has campaigns w/o breaking the UI
      selectedValue={selectedValue ?? { value: cellValue, label: fromEmailToName(cellValue) }}
      onSubmit={updateCampaignField}
      options={userDropdownOptions}
      isSearchable
      error={error}
      isLoading={loading}
      onInputChange={handleSearch}
      onNext={onNext}
      hasMore={hasMore}
      components={components}
      variant={cellVariant}
      isDisabled={!permissions?.patchCampaign}
    />
  );
};

export default memo(CampaignLeadCell, cellPropsComparison);
