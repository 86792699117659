import { flatten as _flatten } from 'lodash';
import { useMemo } from 'react';
import type { ActionMeta, DeselectOptionActionMeta, SelectOptionActionMeta } from 'react-select';

import type { DropdownOption } from '../../Dropdown';
import type { DropdownOptionGroup } from '../../Dropdown';
import {
  CLEAR_SELECTED_ITEMS_VALUE,
  SELECT_ALL_OPTIONS_VALUE,
} from '../MultiSelectContainer/hooks/useMultiSelectContainer';
import type { MultiSelectDropdownProps, MultiSelectValue, OptionTypeWithExtras } from '../MultiSelectDropdown';
import { ActionTypes, isClearAllOption, isSelectAllOption } from '../utils';

interface UseMultiSelectDropdownResult<T = string> {
  isOptionSelected: (option: DropdownOption<OptionTypeWithExtras<T>>) => boolean;
  handleChange: (
    selectedValue: MultiSelectValue<OptionTypeWithExtras<T>>,
    actionMeta: ActionMeta<DropdownOption<OptionTypeWithExtras<T>>>
  ) => void;
  getDisplayValue: (selectedValue: MultiSelectValue<OptionTypeWithExtras<T>>) => string;
}

export default function useMultiSelectDropdown<T = string>({
  options,
  value,
  onChange,
}: Pick<MultiSelectDropdownProps<T>, 'options' | 'onChange' | 'value'>): UseMultiSelectDropdownResult<T> {
  const flatOptions: DropdownOption<T>[] = useMemo(
    () =>
      _flatten(
        options?.map((optionOrGroup: DropdownOption<T> | DropdownOptionGroup<T>) => {
          if ('value' in optionOrGroup) {
            // single option
            return optionOrGroup;
          } else {
            // group of options
            return optionOrGroup.options;
          }
        })
      ),
    [options]
  );

  const handleSelectAction = (
    selectedValue: MultiSelectValue<OptionTypeWithExtras<T>>,
    actionMeta: SelectOptionActionMeta<DropdownOption<OptionTypeWithExtras<T>>>
  ): void => {
    if (isSelectAllOption(actionMeta)) {
      onChange(flatOptions, actionMeta);
    } else if (isClearAllOption(actionMeta)) {
      onChange([], actionMeta);
    } else {
      onChange(selectedValue as MultiSelectValue<T>, actionMeta as SelectOptionActionMeta<DropdownOption>);
    }
  };

  const handleDeselectAction = (
    selectedValue: MultiSelectValue<OptionTypeWithExtras<T>>,
    actionMeta: DeselectOptionActionMeta<DropdownOption<OptionTypeWithExtras<T>>>
  ): void => {
    isSelectAllOption(actionMeta)
      ? onChange([], actionMeta)
      : onChange(selectedValue as MultiSelectValue<T>, actionMeta as DeselectOptionActionMeta<DropdownOption>);
  };

  const handleChange = (
    selectedValue: MultiSelectValue<OptionTypeWithExtras<T>>,
    actionMeta: ActionMeta<DropdownOption<OptionTypeWithExtras<T>>>
  ): void => {
    if (actionMeta.action === ActionTypes.select) {
      handleSelectAction(selectedValue, actionMeta);
    } else if (actionMeta.action === ActionTypes.deselect) {
      handleDeselectAction(selectedValue, actionMeta);
    }
  };

  const isOptionSelected = (option: DropdownOption<OptionTypeWithExtras<T>>): boolean => {
    if (option.value === SELECT_ALL_OPTIONS_VALUE) {
      return flatOptions.length === value.length;
    }
    if (option.value === CLEAR_SELECTED_ITEMS_VALUE) {
      return false;
    }
    return value.some(({ value, id }) => value === option.value && id === option.id);
  };

  function getDisplayValue<T>(selectedValue: MultiSelectValue<OptionTypeWithExtras<T>>): string {
    return selectedValue.map(({ label }) => label).join(', ');
  }

  return {
    isOptionSelected,
    handleChange,
    getDisplayValue,
  };
}
