import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { lowerCase as _lowerCase, startCase as _startCase } from 'lodash';

import type { AssetTagV5, Maybe } from '../apis/graphql/types';
import type { DayPartTimeRanges } from '../common/TimesList/types';
import { DEFAULT_TIMEZONE } from '../constants';
import type { AdDateHour, Nullable } from '../models';

export function isValidDate(date: Date): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}

// Intentionally unexported, this is a helper for formatting dates string based on common options
function dateFormatter(
  date: Date,
  timeStringOptions: Intl.DateTimeFormatOptions,
  includeTime: boolean,
  dateStringOptions?: Intl.DateTimeFormatOptions
): string {
  if (isNaN(date.getTime())) return 'Invalid Date';

  const dateString = date.toLocaleDateString('en-US', dateStringOptions);
  const timeString = date.toLocaleTimeString([], timeStringOptions);
  return `${dateString}${includeTime ? ` ${timeString}` : ''}`;
}

export const convertYearValueToCorrectFormat = (isYearFormatLong = false): 'numeric' | '2-digit' =>
  isYearFormatLong ? 'numeric' : '2-digit';

export const formatDate = (value: Date, includeTime = true, isYearFormatLong = false): string => {
  return dateFormatter(
    value,
    {
      hour: '2-digit',
      minute: '2-digit',
    },
    includeTime,
    {
      day: '2-digit',
      month: '2-digit',
      year: convertYearValueToCorrectFormat(isYearFormatLong),
    }
  );
};

export const formatDateTimeToZonedTime = (value: string, timezone: string = DEFAULT_TIMEZONE): string => {
  if (!isValidDate(new Date(value))) return 'Invalid Date';

  return format(utcToZonedTime(value, timezone), 'MM/dd/yy HH:mm aa');
};

export const formatTimeDetail = (value: string, timeZone?: string): string => {
  return dateFormatter(
    new Date(value),
    {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
      timeZone,
      timeZoneName: 'short',
    },
    true
  );
};

export const stringifyTagList = (assetTags: Maybe<Maybe<AssetTagV5>[]>): string => {
  if (!assetTags) {
    return '';
  }
  const validTags: string[] = [];
  assetTags.forEach((tag) => {
    if (tag && tag.displayName) {
      validTags.push(tag.displayName);
    }
  });
  return validTags.length !== 0 ? validTags.join(', ') : '';
};

export const formatAdDateObject = (value: AdDateHour): string => {
  return `${value.month}/${value.day}/${value.year}`;
};

export const formatStringDate = (value: Nullable<string>, shortYearFormat: boolean = false): string => {
  if (!value) return '';

  return format(parseISO(value), `MM/dd/yy${shortYearFormat ? '' : 'yy'}`);
};

export const formatDateRanges = (startDate: string, endDate: string): string =>
  `${formatStringDate(startDate)}${endDate && startDate ? '-' : ''}${formatStringDate(endDate)}`;

export const formatMilitaryTime = (time: number, rangeType?: 'first' | 'last'): string => {
  if (time === 0) {
    if (rangeType) {
      return rangeType === 'first' ? '12:00am' : '12:59am';
    }
    return '12am';
  }

  const suffix = time >= 12 && time <= 23 ? 'pm' : 'am';
  const minutes = rangeType ? (rangeType === 'first' ? ':00' : ':59') : '';

  return time > 12 ? `${time - 12}${minutes}${suffix}` : `${time}${minutes}${suffix}`;
};

type FormatTimeRangesProps = {
  ranges: DayPartTimeRanges;
  isShowMinutes?: boolean;
  noRangeMessage?: string;
  allDayMessage?: string;
};

export const formatTimeRanges = ({
  ranges,
  isShowMinutes,
  noRangeMessage = 'Dark',
  allDayMessage = 'All day',
}: FormatTimeRangesProps): string => {
  if (!ranges || ranges.length === 0) return noRangeMessage;

  const formattedTimeString = ranges.map((range: [number, number]) => {
    if (range[0] === 0 && range[1] === 23) return allDayMessage;

    if (isShowMinutes) {
      return `${formatMilitaryTime(range[0], 'first')} - ${formatMilitaryTime(range[1], 'last')}`;
    } else {
      return `${formatMilitaryTime(range[0])} - ${formatMilitaryTime(range[1])}`;
    }
  });

  return formattedTimeString.join(', ');
};

export function formatTruncatedDay(formatDay: string): string {
  return formatDay.charAt(0).toUpperCase() + formatDay.slice(1).slice(0, 2).toLowerCase();
}

export const formatVerified = (value: boolean): 'Verified' | 'Not Verified' => {
  return value ? 'Verified' : 'Not Verified';
};

const dollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
export const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const formatDollarAmount = (value: number): string => dollarFormatter.format(value);

export const formatPercentage = (value: number): string => `${percentFormatter.format(value)}%`;

// Convert to all lowercase, then split words and capitalize first letter of each word
export const enumToTitleCase = (value: string): string => _startCase(value.toLowerCase());

// Credit where due: https://stackoverflow.com/a/14919494
export const formatBytes = (bytes: number, metric = true, decimalPoints = 2): string => {
  const blockSize = metric ? 1000 : 1024;

  // If we have fewer than our blockSize of bytes, just return it as bytes. Easy!
  if (Math.abs(bytes) < blockSize) {
    return bytes + ' B';
  }

  // You never know how big a video file might get. In 1923, they thought videos might never exceed a Megabyte. Ha!
  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let unitIndex = -1;
  const remainder = 10 ** decimalPoints;

  do {
    bytes /= blockSize;
    ++unitIndex;
  } while (Math.round(Math.abs(bytes) * remainder) / remainder >= blockSize && unitIndex < units.length - 1);

  return bytes.toFixed(decimalPoints) + ' ' + units[unitIndex];
};

// Credit where due: https://stackoverflow.com/a/1322771
export const formatDuration = (milliseconds: number): string => {
  return new Date(milliseconds).toISOString().substr(11, 8);
};

// Durations of video assets under 1 hour
export const formatDurationShort = (milliseconds: number): string => {
  const dateString = new Date(milliseconds).toISOString();
  return dateString[14] === '0' ? dateString.substr(15, 4) : dateString.substr(14, 5);
};

// Converts a string to title case, i.e. 'MINUTE' to 'Minute',
export const titleCase = (string: string): string => {
  return _startCase(_lowerCase(string));
};

// Primarly useful to make lineItems into "Line Item" or campaigns into "Campaign"
// Cannot handle non-standard plurals where the last letter is not "s" (e.g. fiSH becomes Fis)
export const singularTitleCase = (pluralString: string): string => {
  return titleCase(pluralString).slice(0, -1);
};

export function formatArray(payload: string[] | number[]): string {
  return payload && payload.length > 0 ? payload.join(', ') : '-';
}

export function formatCurrency(payload: string | number | string[] | number[]): string {
  return formatDollarAmount(payload as number);
}

export function formatCellDate(payload: string | number | string[] | number[]): string {
  return formatTimeDetail(payload as string);
}

export function formatDefaultChangeLogCell(payload: string | number | string[] | number[]): string {
  if (Array.isArray(payload)) return formatArray(payload);

  return payload !== null && payload !== undefined ? payload.toString() : '-';
}

export function formatMicroCurrencyToDollarAmount(payload: string | number | string[] | number[] | null): string {
  const formattedString = (payload as number) / 1000000;
  return formatDollarAmount(formattedString);
}

export const TODAY = new Date();
const getFutureDate = (): Date => {
  const futureDate = new Date();
  futureDate.setMonth(TODAY.getMonth() + 1);
  return futureDate;
};
export const NEXT_MONTH = getFutureDate();

export const orderedDaysOfWeek: string[] = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
];

export const addTimeToStringDateIfNeeded = (date: string): string => {
  return date.includes('T') ? date : `${date}T00:00`;
};

export const formatDateOrCreateIfNotValid = (date: string | Date, includeTime = true): string => {
  const dateToFormat = date instanceof Date ? date : new Date(addTimeToStringDateIfNeeded(date));

  const formatTemplate = `yyyy-MM-dd${includeTime ? "'T'HH:mm" : ''}`;

  if (isValidDate(dateToFormat)) return format(dateToFormat, formatTemplate);

  return format(new Date(), formatTemplate);
};

export const formatWeightToPercentage = (value: number, addPercentageMark = true): string => {
  const formatter = new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 0 });

  return `${formatter.format(value * 100)}${addPercentageMark ? '%' : ''}`;
};

export const kebabToCamelCase = (string: string): string => {
  if (!string) return string;
  return string.replace(/-./g, (x) => x[1].toUpperCase());
};

export const formatAdStartAndEndDateToCorrectFormat = (
  value: Nullable<string>,
  isYearFormatLong?: boolean
): string | null => {
  if (!value) return null;

  return formatDate(new Date(value), undefined, isYearFormatLong);
};
