import type { FormikConfig, FormikValues } from 'formik';
import React from 'react';

import DuplicatedAdStepLayout from '../DuplicatedAdStepLayout';
import DuplicatedAdStepWrapper from '../DuplicatedAdStepWrapper';

type Props = {
  leftSide: JSX.Element;
  rightSide: JSX.Element;
} & Pick<FormikConfig<FormikValues>, 'validationSchema'>;

const DuplicateAdFormFirstStep: React.FC<Props> = ({ leftSide, rightSide }) => {
  return (
    <DuplicatedAdStepWrapper title="Duplicate ad">
      <DuplicatedAdStepLayout leftSide={leftSide} rightSide={rightSide} isWrapped />
    </DuplicatedAdStepWrapper>
  );
};

export default DuplicateAdFormFirstStep;
