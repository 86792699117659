import './Error.scss';

import React, { useMemo } from 'react';

import { ErrorIcon } from '../../../../../../../../../common/icons/ErrorIcon';
import bem from '../../../../../../../../../utils/bem';

const [block, element] = bem('multiple-edit-confirm-error');

type Props = {
  message?: string | React.JSX.Element | null;
  conflictIds?: string[];
  conflictMessage?: string;
};

export const Error = ({ message, conflictIds, conflictMessage }: Props): React.JSX.Element => {
  const messageComponent = useMemo(() => {
    if (message) {
      return message;
    }

    if (conflictIds && conflictIds?.length > 0) {
      return <span>{`${conflictMessage} ${conflictIds?.join(', ')}`}</span>;
    }

    return null;
  }, [conflictIds, conflictMessage, message]);

  return (
    <div className={block()}>
      <div data-testid="error-icon" className={element('error-icon')}>
        <ErrorIcon />
      </div>

      <div data-testid="error-text" className={element('wrapper-error-text')}>
        <div>{messageComponent}</div>
      </div>
    </div>
  );
};
