import { useEffect, useRef } from 'react';

import { FOCUSABLE_ELEMENT_SELECTOR } from '../constants';

// Based on https://hiddedevries.nl/en/blog/2017-01-29-using-javascript-to-trap-focus-in-an-element

/**
 * useFocusTrap creates a ref to trap the focus within that element (a good example is a modal), so that the user can only
 * navigate within that element with the keyboard (typically through tabbing)
 *
 * disallowedKeys is a list of keys that when pressed will not propagate outside the Focus Trap
 */
function useFocusTrap<T extends HTMLElement>(disallowedKeys?: string[]): React.RefObject<T> {
  const elRef = useRef<T>(null);

  useEffect(() => {
    const currentRef = elRef?.current;

    function handleFocus(e: KeyboardEvent): void {
      const focusableEls = currentRef?.querySelectorAll(FOCUSABLE_ELEMENT_SELECTOR);
      const firstFocusableEl = focusableEls?.[0] as HTMLElement;
      const lastFocusableEl = focusableEls?.[focusableEls.length - 1] as HTMLElement;

      if (disallowedKeys && disallowedKeys.includes(e.key)) {
        e.stopPropagation();
        return;
      }

      if (e.key !== 'Tab') {
        return;
      }
      if (e.shiftKey) {
        /* shift + tab */ if (document.activeElement === firstFocusableEl) {
          lastFocusableEl?.focus();
          e.preventDefault();
        }
      } /* tab */ else {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl?.focus();
          e.preventDefault();
        }
      }
    }
    currentRef?.addEventListener('keydown', handleFocus);

    return (): void => {
      currentRef?.removeEventListener('keydown', handleFocus);
    };
  }, [disallowedKeys]);

  return elRef;
}

export default useFocusTrap;
