import type { Dispatch, FC, SetStateAction } from 'react';
import React, { memo } from 'react';
import type { Row, TableBodyPropGetter, TableBodyProps } from 'react-table';

import type { EntityModel } from '../../../pages/Trafficking/TraffickingPage/modelConverters';
import shallowEqual from '../../../utils/shallowEqual';
import { TraffickingTableRows } from '../TraffickingTableRows';

const TraffickingTableBody: FC<{
  getTableBodyProps: (propGetter?: TableBodyPropGetter<EntityModel> | undefined) => TableBodyProps;
  loading: boolean;
  errorMessage?: string;
  prepareRow: (row: Row<EntityModel>) => void;
  rows: Row<EntityModel>[];
  selectedColIndex: number;
  selectedRowIndex: number;
  isColumnResizing: boolean;
  lastSelectedRowId: string;
  setLastSelectedRowId: Dispatch<SetStateAction<string>>;
}> = ({
  getTableBodyProps,
  loading,
  errorMessage,
  selectedColIndex,
  selectedRowIndex,
  prepareRow,
  rows,
  lastSelectedRowId,
  setLastSelectedRowId,
}) => {
  return (
    <tbody {...getTableBodyProps()}>
      {!loading && !errorMessage && (
        <TraffickingTableRows
          prepareRow={prepareRow}
          rows={rows}
          selectedColIndex={selectedColIndex}
          selectedRowIndex={selectedRowIndex}
          lastSelectedRowId={lastSelectedRowId}
          setLastSelectedRowId={setLastSelectedRowId}
        />
      )}
    </tbody>
  );
};

export default memo(
  TraffickingTableBody,
  (prevProps, nextProps) => nextProps.isColumnResizing || shallowEqual(prevProps, nextProps)
);
