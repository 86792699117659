import { gql } from '@apollo/client';

import { ASSET_IMPORTER_ASSET } from '../../../../common/AdForm/hooks/fragments';

// Data for Targeting Dropdowns
export const GET_ASSETS_BY_IDS = gql`
  query getAssetsByIDs(
    $assetIdList: [String!]!
    $filterOptions: ReviewTaskV5FilterOptions
    $paginationOptions: PaginationOptions
    $sortOptions: ReviewTaskV5SortOptionsInput
  ) {
    getAssetsByIDsV5(assetIdList: $assetIdList) {
      id
      review
      rejectionReasons
      name
      type
      s3Url
      assetTagList {
        value
      }
      createdAt
      updatedAt
      md5
      fileSize
      transcodeStatus
      transcodeFailureDetails {
        name
        message
      }
      ...assetImporterAssetV5
      ... on VideoAssetV5 {
        duration
      }
    }
    getReviewTasksV5(paginationOptions: $paginationOptions, sortOptions: $sortOptions, filterOptions: $filterOptions) {
      total
      edges {
        node {
          derivedSpReview
        }
      }
    }
  }
  ${ASSET_IMPORTER_ASSET}
`;
