import './ControlledCheckbox.scss';

import type { ChangeEvent, MutableRefObject } from 'react';
import React, { forwardRef, useEffect, useRef } from 'react';

import bem from '../../utils/bem';

type CheckboxRef = MutableRefObject<HTMLInputElement | null> | ((instance: HTMLInputElement | null) => void) | null;

// "checked" and "indeterminate" are HTMLInputElement properties for checkbox input states
// further reading: https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement
export interface CheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  labelContent?: string | JSX.Element;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  tabIndex?: number;
  className?: string;
  title?: string;
}

const [b, e] = bem('controlled-checkbox');

function CheckboxBase(props: CheckboxProps, ref: CheckboxRef): JSX.Element {
  const { checked, indeterminate, labelContent, ...rest } = props;

  // In order to actually update the underlying `<input />` element, we rely on refs.
  // In case we don't receive a `ref` from whatever is rendering this component,
  // we create a `defaultRef` that we may update instead.
  const defaultRef = useRef<HTMLInputElement>(null);
  const resolvedRef = ref || defaultRef;

  // If the indeterminate prop changes, update the indeterminate property of the ref'd
  // HTMLInputElement to equal the prop value.
  //
  // If the resolvedRef changes at all, force it to use the value of the indeterminate prop.
  useEffect(() => {
    if (typeof resolvedRef !== 'function' && resolvedRef && resolvedRef.current) {
      resolvedRef.current.indeterminate = !!indeterminate;
    }
  }, [resolvedRef, indeterminate]);

  // use this style class when selecting for this whole component by its checked state
  const isCheckedCls = checked ? 'is-checked' : null;

  return (
    <label data-testid={b()} className={b(isCheckedCls)}>
      <input checked={checked} type="checkbox" ref={resolvedRef} {...rest} />
      {labelContent && <span className={e('label-content')}>{labelContent}</span>}
    </label>
  );
}

const ControlledCheckbox = forwardRef<HTMLInputElement, CheckboxProps>(CheckboxBase);

export default ControlledCheckbox;
