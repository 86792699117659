import { gql } from '@apollo/client';

export const GET_TARGETING_PRESETS = gql`
  query GetTargetingPresets(
    $paginationOptions: PaginationOptions
    $filterOptions: TargetingPresetFilterOptions
    $sortOptions: TargetingPresetSortOptions
  ) {
    getTargetingPresets(
      paginationOptions: $paginationOptions
      filterOptions: $filterOptions
      sortOptions: $sortOptions
    ) {
      edges {
        node {
          archived
          name
          updatedAt
          id
          description
        }
      }
      total
    }
  }
`;

export const GET_TARGETING_PRESETS_WITH_TARGETING_TERMS = gql`
  query GetTargetingPresetsWithTargetingTerms(
    $paginationOptions: PaginationOptions
    $filterOptions: TargetingPresetFilterOptions
  ) {
    getTargetingPresets(paginationOptions: $paginationOptions, filterOptions: $filterOptions) {
      edges {
        node {
          archived
          name
          updatedAt
          id
          description
          definition {
            category {
              displayName
            }
            dimension {
              displayName
            }
            value {
              displayName
            }
          }
        }
      }
      total
    }
  }
`;
