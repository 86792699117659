import type { AdRotation as RawAdRotation, Rotation as RawRotation } from '../../../../apis/graphql';
import { removeTypeNamesFromObject } from '../../../../apis/graphql';
import type { AdFormValues } from '../../../../common/AdForm/adFormik';
import { convertAdsPageAdToFormValues } from '../../../../common/AdForm/adFormik';
import type { AdsPageAd } from '../../../../common/AdForm/hooks/types';
import { isNielsenTag } from '../../../../common/AdForm/utils';
import type { PillProps } from '../../../../common/Pill/Pill';
import { PillColor } from '../../../../common/Pill/Pill';
import type { AdListData, AdListSequence, AdRotation, Rotation } from '../../../../common/SequenceWeight/types';
import { getEarliestDateRange, getLatestDateRange } from '../../../../common/utils';
import type { AdV5, PartialBy } from '../../../../models';
import { formatWeightToPercentage } from '../../../../utils/formatting';
import { NEW_SEQUENCE_LABEL } from '../../../Sequence/constants';
import { blankAd } from '../constants';
import { adStatusPillMap } from '../constants';
import type { AdListElement, AdListOptionalFormState } from '../types';

export type AdRotationInput = Pick<
  AdV5,
  'adRotation' | 'id' | 'startDate' | 'endDate' | 'schedule' | 'name' | 'targetingTermValues' | 'adSequence'
>;
export const getAdListData = (adsList: AdRotationInput[], fetchedRotations: RawRotation[]): AdListData => {
  const rotations: Rotation[] = [];
  const adsWithoutWeight: AdRotation[] = [];

  const usedAdIds: string[] = [];

  fetchedRotations.forEach((fetchedRotation: RawRotation) => {
    const rotation: Rotation = {
      id: fetchedRotation.rotationId,
      name: fetchedRotation.name,
      order: fetchedRotation.order || 0,
      adsList: [],
    };

    fetchedRotation.adRotationList.forEach((fetchedAdRotation: RawAdRotation) => {
      const neededAd = adsList.find((ad: AdRotationInput) => ad.id === fetchedAdRotation.adId);

      if (!neededAd) return;

      const startDate = neededAd.startDate || neededAd.schedule?.dateRangeList[0]?.startDate || null;
      const endDate = neededAd.endDate || neededAd.schedule?.dateRangeList[0]?.endDate || null;

      rotation.adsList.push({
        adId: fetchedAdRotation.adId || '',
        order: fetchedAdRotation.order || 0,
        weight: parseInt(formatWeightToPercentage(fetchedAdRotation.weight)),
        name: neededAd.name,
        startDate,
        endDate,
        dayPartList: neededAd.schedule?.dayPartList,
        targetingTermValues: neededAd.targetingTermValues,
        adSequence: neededAd.adSequence ?? null,
      });

      usedAdIds.push(neededAd.id);
    });

    rotations.push(rotation);
  });

  adsList.forEach((ad: AdRotationInput) => {
    if (usedAdIds.includes(ad.id)) return;

    const earliestDateRange = getEarliestDateRange(ad.schedule);
    const latestDateRange = getLatestDateRange(ad.schedule);

    const startDate = ad.startDate || earliestDateRange?.startDate || null;
    const endDate = ad.endDate || latestDateRange?.endDate || null;

    const adRotation: AdRotation = {
      adId: ad.id,
      order: 0,
      weight: 0,
      name: ad.name,
      endDate,
      startDate,
      dayPartList: ad.schedule?.dayPartList,
      targetingTermValues: ad.targetingTermValues,
      adSequence: ad.adSequence ?? null,
    };

    if (rotations.length) {
      rotations[0].adsList.push(adRotation);
    } else {
      adsWithoutWeight.push(adRotation);
    }
  });

  const sequences: AdListSequence[] = [];
  const adsWithoutWeightOrSequence: AdRotation[] = [];

  adsWithoutWeight.forEach((ad) => {
    if (ad.adSequence) {
      const sequenceIndex = sequences.findIndex(({ id }) => id === ad.adSequence?.sequenceId);

      if (sequenceIndex !== -1) {
        sequences[sequenceIndex].adsList.push(ad);
      } else {
        sequences.push({
          id: ad.adSequence.sequenceId,
          name: ad.adSequence.name || NEW_SEQUENCE_LABEL + '1',
          adsList: [ad],
        });
      }
    } else {
      adsWithoutWeightOrSequence.push(ad);
    }
  });

  const sortedSequences = sequences.map((sequence) => {
    const sortedAdList = sequence.adsList.sort((a, b) => {
      if (a.adSequence && b.adSequence) {
        return a.adSequence.order > b.adSequence.order ? 1 : -1;
      }

      return 0;
    });

    return {
      ...sequence,
      adsList: sortedAdList,
    };
  });

  return { rotations, sequences: sortedSequences, adsWithoutWeightOrSequence };
};

// makeStatusPills determines which pills an ad list item should have
export function makeStatusPills({ ad, formikState }: AdListOptionalFormState): PillProps[] {
  const pills: PillProps[] = [];

  if (!ad.id) {
    pills.push({ text: 'draft', color: PillColor.GRAY });
  }

  const creativeStatus = ad?.creative?.review || formikState?.values?.creative?.review;

  if (creativeStatus === undefined || creativeStatus === null) {
    pills.push({ text: 'creative incomplete', color: PillColor.GRAY });
  } else if (creativeStatus === 'REVIEW' || creativeStatus === 'QC_REVIEW') {
    pills.push({ text: 'qc ' + [creativeStatus], color: PillColor.BLUE });
  } else if (creativeStatus === 'PUBLISHED' || creativeStatus === 'APPROVED') {
    pills.push({ text: 'qc ' + [creativeStatus], color: PillColor.GREEN });
  } else if (creativeStatus === 'REJECTED') {
    pills.push({ text: 'qc rejected', color: PillColor.RED });
  } else {
    pills.push({ text: 'qc ' + [creativeStatus], color: PillColor.GRAY });
  }

  if (ad.status.key) {
    pills.push(adStatusPillMap[ad.status.key]);
  }

  return pills;
}

export function isSavedAd(ad: PartialBy<AdsPageAd, 'id'>): boolean {
  return !!ad.id;
}

// makeAdListElement converts an ad to an ad list element
export function makeAdListElement(initialValues: AdFormValues, ad?: AdsPageAd): AdListElement {
  let values: AdFormValues;
  if (ad) {
    values = convertAdsPageAdToFormValues(initialValues, ad);
  } else {
    // New Ads filter out any existing Nielsen Tags with IDs | SPEAR-1332
    const newAdTagList = initialValues.adTagList
      ? initialValues.adTagList.filter((tag) => !isNielsenTag(tag.url) || !tag.id)
      : [];
    values = {
      ...initialValues,
      adTagList: newAdTagList,
    };
  }
  return removeTypeNamesFromObject({
    ad: ad ? { ...blankAd, ...ad } : { ...blankAd },
    formikState: { initialValues: values, values },
  });
}

const sortByCreatedAt = (
  { ad: { createdAt: a } }: Pick<AdListElement, 'ad'>,
  { ad: { createdAt: b } }: Pick<AdListElement, 'ad'>
): number => (a > b ? -1 : 0);

const sortByStatus = (
  {
    ad: {
      status: { key: a },
    },
  }: Pick<AdListElement, 'ad'>,
  {
    ad: {
      status: { key: b },
    },
  }: Pick<AdListElement, 'ad'>
): number => (a > b ? -1 : 0);

export const sortAdListByCreatedAtAndStatus = (adList: AdListElement[]): AdListElement[] => {
  const sortedByCreatedAt = [...adList].sort(sortByCreatedAt);
  const sortByCreatedAtAndStatus = [...sortedByCreatedAt].sort(sortByStatus);

  return sortByCreatedAtAndStatus;
};
