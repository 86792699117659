import './Priority.scss';

import { TextField } from '@hulu/react-style-components';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';

import Dropdown from '../../../../../../../../../common/Dropdown';
import type { DropdownOption, SingleSelectValue } from '../../../../../../../../../common/Dropdown/Dropdown';
import { generateErrorObject, showFailure } from '../../../../../../../../../common/utils';
import type { DeliveryTier } from '../../../../../../../../../configs';
import {
  convertConfigToDropdownOption,
  convertConfigToDropDownOptions,
  convertDeliveryTier,
  deliveryTiers,
} from '../../../../../../../../../configs';
import { invalidParameterErrorMessage } from '../../../../../../../../../constants';
import bem from '../../../../../../../../../utils/bem';
import { enumToTitleCase } from '../../../../../../../../../utils/formatting';
import { useLineItemPriorities } from '../../../../../../../TraffickingPage/hooks/useLineItemPriorities';
import type { MultipleEditDrawerLineItemsFormValues } from '../../../../../types';
import { getPriorityValue } from './utils';

const [block, element] = bem('multi-edit-priority');

export const sectionPriorityHeader =
  'Select a Tier Name and/or Priority Name to apply to the line items. Priority Value will be auto-calculated based on your selections.';

type IsPriorityValueSetParams = {
  tierName?: string;
  priorityName: string;
};

const Priority = (): JSX.Element => {
  const { priorityNames } = useLineItemPriorities();
  const {
    values: { priority },
    setFieldValue,
  } = useFormikContext<MultipleEditDrawerLineItemsFormValues>();

  const tierNameOptions = useMemo(() => {
    if (!priority.tier) {
      return { value: '' as DeliveryTier, label: '' };
    }

    return convertConfigToDropdownOption<DeliveryTier>(priority.tier.name) as DropdownOption<DeliveryTier> | null;
  }, [priority.tier]);

  const formedPriorityNameOptions = useMemo(
    () => priorityNames.map((priorityName) => ({ value: priorityName, label: priorityName })),
    [priorityNames]
  );

  const selectedPriorityName = useMemo(
    () => ({ value: priority.priority.name, label: enumToTitleCase(priority.priority.name) }),
    [priority.priority.name]
  );

  const changeTierName = (tierName: SingleSelectValue<DeliveryTier>): void => {
    if (tierName) {
      if (!priority.tier || isPriorityValueSet({ tierName: tierName.value, priorityName: priority.priority.name })) {
        const formattedTierName = convertDeliveryTier(tierName.value);
        setFieldValue(`priority.tier.name`, { ...formattedTierName });
      }
    }
  };

  const changePriorityName = (priorityName: SingleSelectValue): void => {
    if (priorityName) {
      if (isPriorityValueSet({ tierName: priority.tier?.name.key, priorityName: priorityName.value })) {
        setFieldValue(`priority.priority.name`, priorityName.value);
      }
    }
  };

  const isPriorityValueSet = ({ tierName, priorityName }: IsPriorityValueSetParams): boolean => {
    if (tierName && priorityName) {
      const priorityValue = getPriorityValue(tierName, priorityName);
      if (priorityValue) {
        setFieldValue(`priority.priorityValue`, priorityValue);
        return true;
      }
    }
    const alertError = `Tier ${tierName} cannot have ${priorityName ? priorityName : 'no'} priority.`;
    const { error } = generateErrorObject(invalidParameterErrorMessage, alertError);
    showFailure(error);
    return false;
  };

  return (
    <div className={block()}>
      <div className={element('header')}>{sectionPriorityHeader}</div>
      <div className={element('priority-dropdown-wrap')}>
        <Dropdown<DeliveryTier>
          id="multi-edit-tier-name"
          dataTestId="multi-edit-tier-name"
          label={'Tier name'}
          value={tierNameOptions}
          onChange={changeTierName}
          options={convertConfigToDropDownOptions(deliveryTiers)}
          isSearchable={false}
        />
      </div>
      <div className={element('priority-dropdown-wrap')}>
        <Dropdown<string>
          id="multi-edit-priority-name"
          dataTestId="multi-edit-priority-name"
          label={'Priority name'}
          value={selectedPriorityName}
          onChange={changePriorityName}
          options={formedPriorityNameOptions}
          isSearchable={false}
          isDisabled={!priority.tier}
        />
      </div>
      <div className={element('priority-input-wrap')}>
        <TextField
          id="multi-edit-priority-value"
          data-testid="multi-edit-priority-value"
          label="Priority value"
          value={priority.priorityValue?.toString()}
          disabled={true}
        />
      </div>
    </div>
  );
};

export default Priority;
