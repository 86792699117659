import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

export type AdProduct = keyof typeof adProductDisplayNames;

const adProductDisplayNames = {
  AD_SELECTOR: enumToTitleCase,
  AD_SELECTOR_SLATE: enumToTitleCase,
  AD_SELECTOR_VIDEO: enumToTitleCase,
  BRIGHTLINE: enumToTitleCase,
  GATEWAY_GO: enumToTitleCase,
  GATEWAY_SHOP: enumToTitleCase,
  INNOVID: enumToTitleCase,
  MAX_SELECTOR: enumToTitleCase,
  PAUSE: enumToTitleCase,
  SLATE: enumToTitleCase,
  VIDEO: enumToTitleCase,
  HOUSE_VIDEO: enumToTitleCase,

  // @deprecated
  BE_SELECTOR: enumToTitleCase,
  BINGE_AD_FREE: enumToTitleCase,
  BINGE_SLATE: enumToTitleCase,
  BINGE_TEASER: enumToTitleCase,
  BINGE_VIDEO: enumToTitleCase,
  BINGE_REWARD: enumToTitleCase,
  COLLECTION_LOGO: enumToTitleCase,
  COVER_STORY_LOGO: enumToTitleCase,
  HUB_LOGO: enumToTitleCase,
  MARQUEE: enumToTitleCase,
};

export const adProducts = makeConfig<AdProduct>(adProductDisplayNames);

export function convertAdProduct(apiValue: string): DisplayEnum<AdProduct> {
  return convertToDisplayEnum(apiValue, adProducts);
}
