import { enumToTitleCase } from '../utils/formatting';
import type { DisplayEnum } from '.';
import { convertToDisplayEnum, makeConfig } from './utils';

export type AdType = keyof typeof adTypeDisplayNames;

const adTypeDisplayNames = {
  UNKNOWN: enumToTitleCase,
  AD_SELECTOR: enumToTitleCase,
  AD_SELECTOR_SLATE: enumToTitleCase,
  AD_SELECTOR_VIDEO: enumToTitleCase,
  BRIGHTLINE: enumToTitleCase,
  COVER_STORY_LOGO: enumToTitleCase,
  GATEWAY_GO: enumToTitleCase,
  GATEWAY_SHOP: enumToTitleCase,
  INNOVID: enumToTitleCase,
  MAX_SELECTOR: enumToTitleCase,
  PAUSE: enumToTitleCase,
  SLATE: enumToTitleCase,
  VIDEO: enumToTitleCase,

  // @deprecated
  PAUSE_QR: 'PauseQR',
  MARQUEE_PROMOTED_CONTENT: enumToTitleCase,
  COLLECTION_LOGO: enumToTitleCase,
  HUB_LOGO: enumToTitleCase,
  BE_SELECTOR_SLATE: enumToTitleCase,
  BE_SELECTOR_VIDEO: enumToTitleCase,
};

export const adTypes = makeConfig<AdType>(adTypeDisplayNames);

export function convertAdType(apiValue: string): DisplayEnum<AdType> {
  return convertToDisplayEnum(apiValue, adTypes);
}
