import type { AuditLineItemV5, ChangeLogUnionObject, SingleValueChange } from '../../../apis/graphql';
import { modifyDefinition, modifySchedule, modifyTargetingRule } from './fields';
import { camelizeObjectKeys, getParentFieldName, prepareRestructuredChangeList } from './util';

export const restructureData = (
  previousObject: ChangeLogUnionObject | null,
  object: ChangeLogUnionObject,
  changeList: SingleValueChange[],
  entityType?: string
): SingleValueChange[] => {
  const restructuredChangeList = prepareRestructuredChangeList(changeList);

  const camelCasedRestructuredChangeList = restructuredChangeList.map((change) => camelizeObjectKeys(change));

  const scheduleBefore = (previousObject as AuditLineItemV5)?.schedule;
  const scheduleAfter = (object as AuditLineItemV5)?.schedule;

  camelCasedRestructuredChangeList.forEach((change, index) => {
    const [fieldName] = getParentFieldName(change.fieldName);
    switch (fieldName) {
      case 'schedule':
        modifySchedule({ change, restructuredChangeList, entityType, scheduleBefore, scheduleAfter });
        break;

      case 'definition':
        modifyDefinition(change, restructuredChangeList);
        break;

      case 'targeting-rule':
        modifyTargetingRule(restructuredChangeList[index], object, previousObject);
        break;
    }
  });

  return restructuredChangeList;
};
