import './Status.scss';

import React from 'react';

import type { EntityTypeArray } from '../../../../../../../TraffickingPage/hooks/makePageDrawerPlugin';
import { useGroupEntitiesByStatus } from './hooks/useGroupEntitiesByStatus';
import StatusTable from './StatusTable';

type StatusProps = {
  entityType: EntityTypeArray;
  isExpanded: boolean;
  readonly?: boolean;
};

export default function Status({ isExpanded, entityType, readonly }: StatusProps): JSX.Element {
  const { entitiesIdToIndexMap, selectedEntities } = useGroupEntitiesByStatus({ readonly });

  if (readonly) {
    return (
      <StatusTable
        status="Off"
        readonly={readonly}
        entitiesValues={selectedEntities.flat()}
        entitiesIdToIndexMap={entitiesIdToIndexMap}
        isExpanded={isExpanded}
        entityType={entityType}
      />
    );
  }

  return (
    <>
      {selectedEntities.map(
        (entitiesFilteredByStatuses, index) =>
          entitiesFilteredByStatuses.length > 0 && (
            <StatusTable
              status={index ? 'Off' : 'On'}
              entitiesValues={entitiesFilteredByStatuses}
              entitiesIdToIndexMap={entitiesIdToIndexMap}
              isExpanded={isExpanded}
              key={index}
              entityType={entityType}
            />
          )
      )}
    </>
  );
}
