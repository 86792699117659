import './BasicSelect.scss';

import type { ReactElement } from 'react';
import React, { useCallback, useMemo, useState } from 'react';

import bem from '../../utils/bem';
import { RcTooltip } from '../RcTooltip';
import { TooltipPlacement, TooltipTrigger } from '../RcTooltip/constants';
import type { TooltipProps } from '../RcTooltip/types';
import BasicSelectButton from './components/BasicSelectButton';
import type { BasicSelectOptionProps } from './components/BasicSelectOption';
import BasicSelectOptions from './components/BasicSelectOptions';

const tooltipProps: TooltipProps = {
  overlayClassName: 'basic-select__tooltip',
  visible: true,
  placement: TooltipPlacement.BottomLeft,
  trigger: [TooltipTrigger.Click],
};

const [block] = bem('basic-select');

export type BasicSelectProps = {
  optionsComponent?: (toggleOpen: () => void) => ReactElement;
  options?: BasicSelectOptionProps[];
  handleSelect?: Function;
  buttonText: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  isActive?: boolean;
  overlayClassName?: string;
};

const BasicSelect = ({
  optionsComponent,
  buttonText,
  isActive,
  leftIcon,
  rightIcon,
  options,
  handleSelect,
  overlayClassName,
}: BasicSelectProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleOpen = useCallback((): void => {
    setOpen((prevValue) => !prevValue);
  }, [setOpen]);

  const defaultHandleSelect = useCallback(
    (...args: unknown[]) => {
      if (handleSelect) {
        handleSelect.apply(null, args);
      }
      toggleOpen();
    },
    [handleSelect, toggleOpen]
  );

  const optionsElement = useMemo((): JSX.Element => {
    if (optionsComponent) {
      return optionsComponent(toggleOpen);
    }

    if (options && handleSelect) {
      return <BasicSelectOptions options={options} handleSelect={defaultHandleSelect} />;
    }

    return <div data-testid="default-select-options"></div>;
  }, [options, handleSelect, optionsComponent, toggleOpen, defaultHandleSelect]);

  return (
    <div className={`${block()}`}>
      {!open ? (
        <BasicSelectButton
          leftIcon={leftIcon}
          rightIcon={rightIcon}
          isOpen={open}
          isActive={isActive}
          text={buttonText}
          toggleOpen={toggleOpen}
        />
      ) : (
        <RcTooltip
          tooltipProps={{ ...tooltipProps, overlayClassName }}
          tooltipContent={optionsElement}
          onClickOutside={toggleOpen}
        >
          <BasicSelectButton
            isOpen={open}
            isActive={isActive}
            text={buttonText}
            toggleOpen={toggleOpen}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
          />
        </RcTooltip>
      )}
    </div>
  );
};

export default BasicSelect;
