import type { DropdownOption } from '../../Dropdown';
import type { PredefinedDropdownOptions } from '../config';
import { PREDEFINED_DROPDOWN_OPTIONS_CONFIG } from '../config';

export type UsePredefinedDropdownOptions = {
  options: DropdownOption[];
};

export const usePredefinedDropdownOptions = (key: PredefinedDropdownOptions): UsePredefinedDropdownOptions => {
  const options = PREDEFINED_DROPDOWN_OPTIONS_CONFIG[key];

  return {
    options,
  };
};
