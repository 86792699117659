import type { SingleValueChange } from '../../../../../apis/graphql';
import type { DayPartCellTimeRanges } from '../../../ChangeLogTable/Cell/CellItems';
import type { ChangedDayPartRange, NullableDayPart } from '../../../types';
import { compareDayValues } from './compareDayValues';
import { getChangeActionType } from './getChangeActionType';

export const getFormattedDayPartChangedDays = (
  dayPartListBefore: NullableDayPart[],
  dayPartListAfter: NullableDayPart[]
): SingleValueChange => {
  const action = getChangeActionType(dayPartListBefore, dayPartListAfter);
  const changedDays = compareDayValues(dayPartListBefore, dayPartListAfter, action);

  const getPayload = (rangeType: 'oldRange' | 'newRange'): DayPartCellTimeRanges[] =>
    changedDays.map((changed: ChangedDayPartRange) => ({ day: changed.day, ranges: changed[rangeType] }));

  return {
    fieldName: 'daypart',
    oldValue: {
      payload: dayPartListBefore.length ? getPayload('oldRange') : [],
      action,
    },
    newValue: {
      payload: dayPartListAfter.length ? getPayload('newRange') : [],
      action,
    },
  };
};
