import { resourcePaths } from '../../_ForCommonLibrary/constants';

export function getAcceptableUrl(
  loading: boolean,
  creativeId: string | undefined,
  id: string | undefined,
  hasReviewTaskId: boolean
): string {
  const acceptableUrl = !loading && creativeId && id && hasReviewTaskId;
  return acceptableUrl ? `${resourcePaths.ASSET_REVIEW_ASSET}?creative-id=${creativeId}&asset-id=${id}` : '';
}
