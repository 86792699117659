import './ToggleSection.scss';

import { IconChevronRight } from '@hulu-react-style-components/icons';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import bem from '../../utils/bem';

const toggleLabel = 'Expand and Collapse Toggle';

const [block, element] = bem('toggle-section');

export interface ToggleSectionProps {
  title: string | React.ReactNode;
  isExpanded: boolean;
  handleToggle(e: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>): void;
  children?: React.ReactNode;
  toggleClassName?: string;
  action?: React.ReactNode;
}

function ToggleSection({
  title,
  isExpanded,
  handleToggle,
  children,
  toggleClassName,
  action,
}: ToggleSectionProps): JSX.Element {
  const handleToggleClick = (e: React.MouseEvent<Element>): void => {
    if (e.defaultPrevented) return;
    handleToggle(e);
  };

  return (
    <div className={block()} data-testid="toggle-log-item">
      <div
        className={element('title-wrapper', isExpanded ? 'active' : '')}
        aria-label={toggleLabel}
        role="button"
        tabIndex={0}
        onKeyDown={(e: React.KeyboardEvent<Element>): void => {
          if (e.key === 'Escape') return;
          if (e.key === 'Space' || e.key === 'Enter' || e.key === ' ') handleToggle(e);
        }}
        onClick={handleToggleClick}
      >
        <div className={element('title-content-wrapper')}>
          <IconChevronRight className="chevron" />
          <div className={element('title')}>{title}</div>
          <div className={element('action')}>{action}</div>
        </div>
      </div>
      <CSSTransition
        classNames={`${toggleClassName} ${element('toggle')}`}
        in={isExpanded}
        timeout={150}
        unmountOnExit
        appear
      >
        <div>{children}</div>
      </CSSTransition>
    </div>
  );
}

export default ToggleSection;
