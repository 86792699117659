import './Switch.scss';

import { Label, Switch as TrekSwitch } from '@hulu/react-style-components';
import { useFormikContext } from 'formik';
import type { InputHTMLAttributes, ReactNode } from 'react';
import React from 'react';

import bem, { withOptionalClassName } from '../../../utils/bem';
import { useFieldFast } from '../hooks';
import PreviewWrapper from '../PreviewWrapper';
import { makeLabelChild } from '../utils';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  formFieldName: string;
  label?: string;
  secondaryLabel?: string;
  className?: string;
  info?: (value: boolean) => ReactNode;
  isPreview?: boolean;
  colorWhenOn?: string;
}
const [block, element] = bem('switch');

export const Switch = ({
  formFieldName,
  label,
  secondaryLabel,
  info,
  disabled,
  isPreview,
  ...props
}: Props): JSX.Element => {
  const [, { value }, { setValue }] = useFieldFast<boolean>(formFieldName);
  const { isSubmitting } = useFormikContext();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    e.key === 'Enter' && setValue(!value);
  };

  return (
    <PreviewWrapper isPreview={isPreview} label={label} value={value ? 'On' : 'Off'}>
      <div className={withOptionalClassName(block(), props.className)}>
        {(label || secondaryLabel) && <Label htmlFor={props.id}>{makeLabelChild(label, secondaryLabel)}</Label>}
        <div className={element('group')} tabIndex={0} onKeyDown={handleKeyDown}>
          <TrekSwitch
            disabled={disabled || isSubmitting}
            isOn={value}
            onChange={(): void => setValue(!value)}
            {...props}
          />
          {info && <span className={element('info')}>{info(value)}</span>}
        </div>
      </div>
    </PreviewWrapper>
  );
};

export default Switch;
