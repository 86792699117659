import './PacingTypeCell.scss';

import type { ModalProps } from 'antd';
import { User } from 'mission-control-common-components';
import type { JSX } from 'react';
import React, { memo, useMemo, useState } from 'react';

import EditableDropdown from '../../../../../common/EditableTableCells/EditableDropdown';
import ModalPopup from '../../../../../common/ModalPopup';
import type { PermittedPacingType } from '../../../../../configs';
import {
  convertConfigToDropdownOption,
  convertConfigToDropDownOptions,
  permittedPacingTypes,
} from '../../../../../configs';
import type { PermissionsNames } from '../../../../../constants';
import { cellPropsComparison } from '../../../../../utils/common';
import { CHANGE_PACING_TYPE_TO_AFAP_CONFIRM_MSG, CHANGE_PACING_TYPE_TO_EVEN_CONFIRM_MSG } from '../../../constants';
import { getIdFromTableCell } from '../../columns';
import useUpdatePacingType from '../../hooks/useUpdatePacingType';
import type { PacingTypeCellProps } from './types';

const PacingTypeCell = (props: PacingTypeCellProps): JSX.Element => {
  const id = getIdFromTableCell(props);
  const {
    value: { pacingType, tierName },
  } = props;

  const [confirmationModalVisible, setConfirmationModalVisible] = useState<boolean>(false);
  const [pacingTypeToSave, setPacingTypeToSave] = useState<string>('');

  const permissions = User.getPermissions<PermissionsNames>();

  const { cellVariant, updatePacingType, loading } = useUpdatePacingType();

  const handleOpenConfirmationModalSetPacingType = (pacingType: PermittedPacingType): void => {
    setPacingTypeToSave(pacingType);
    setConfirmationModalVisible(true);
  };

  const handleCloseConfirmationModal = (): void => {
    setConfirmationModalVisible(false);
    setPacingTypeToSave('');
  };

  const handleChangePacingType = (): void => {
    updatePacingType(id, pacingTypeToSave);
    handleCloseConfirmationModal();
  };

  const confirmationModalProps: ModalProps = useMemo(() => {
    return {
      title: `${
        pacingType.key === 'AFAP' ? CHANGE_PACING_TYPE_TO_EVEN_CONFIRM_MSG : CHANGE_PACING_TYPE_TO_AFAP_CONFIRM_MSG
      }`,
      okText: 'Yes',
      cancelText: 'No',
      className: 'free-btn',
      cancelButtonProps: { disabled: loading },
      okButtonProps: { loading },
    };
  }, [loading, pacingType.key]);

  const allowModifyPacingType = useMemo(() => {
    const permittedPacingType = ['EVEN', 'AFAP'];
    const permittedTierName = 'sponsorship';

    return (
      tierName === permittedTierName &&
      permittedPacingType.includes(pacingType.key) &&
      permissions?.updateLineItemPacingType
    );
  }, [pacingType.key, permissions?.updateLineItemPacingType, tierName]);

  return (
    <>
      {allowModifyPacingType ? (
        <EditableDropdown<PermittedPacingType>
          selectedValue={convertConfigToDropdownOption<PermittedPacingType>(pacingType)}
          onSubmit={handleOpenConfirmationModalSetPacingType}
          options={convertConfigToDropDownOptions(permittedPacingTypes)}
          variant={cellVariant}
          dataTestId="pacing-type-cell"
          isDisabled={!permissions?.updateLineItem}
        />
      ) : (
        <div className="pacing-type-wrapper">{pacingType.displayName}</div>
      )}
      {confirmationModalVisible && (
        <ModalPopup
          visible={confirmationModalVisible}
          onOk={handleChangePacingType}
          modalProps={confirmationModalProps}
          onCancel={handleCloseConfirmationModal}
        />
      )}
    </>
  );
};

export default memo(PacingTypeCell, cellPropsComparison);
