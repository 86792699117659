import './Drawer.scss';

import { IconClose, IconWindowCollapse, IconWindowExpand } from '@hulu-react-style-components/icons';
import React from 'react';

import bem from '../../utils/bem';
import type { DrawerProps } from './useDrawerProps';

type DrawerRenderProp<T extends {}> = ((props: { data?: T; isExpanded?: boolean }) => React.ReactNode) | string | null;

export interface DrawerPropsWithRenderProps<T extends {}> extends DrawerProps<T> {
  className?: string;
  content?: DrawerRenderProp<T>;
  title?: DrawerRenderProp<T>;
  isExpanded: boolean;
  size?: 'normal' | 'large';
  onToggleExpansion: () => void;
}

const [block, element] = bem('drawer');

function withRenderProp<T extends {}>(prop?: DrawerRenderProp<T>, data?: T, isExpanded?: boolean): React.ReactNode {
  if (typeof prop === 'function') return prop({ data, isExpanded });
  return prop ?? null;
}

function Drawer<T extends {}>({
  className,
  content,
  data,
  onClose,
  isOpen,
  title,
  isExpanded,
  size = 'normal',
  onToggleExpansion,
}: DrawerPropsWithRenderProps<T>): JSX.Element {
  const openClass = isOpen ? 'opened' : 'closed';
  const expandedClass = isOpen && isExpanded ? `expanded` : '';
  const sizeClass = size === 'large' ? 'large' : '';

  const mergedClassName = `${block(openClass)} ${block(sizeClass)} ${block(expandedClass)} ${className}`;

  return (
    <aside className={mergedClassName} aria-label="drawer" aria-hidden={!isOpen}>
      {isOpen && (
        <>
          <header>
            <div className={element('title')}>{withRenderProp<T>(title, data)}</div>
            <div className={element('header-btns')}>
              <button onClick={onToggleExpansion} aria-label="expand drawer">
                {isExpanded ? (
                  <IconWindowCollapse width="20px" height="20px" title="Collapse" />
                ) : (
                  <IconWindowExpand width="20px" height="20px" title="Expand" />
                )}
              </button>
              <button onClick={onClose} aria-label="close drawer">
                <IconClose width="20px" height="20px" title="Close" />
              </button>
            </div>
          </header>
          <main>{withRenderProp<T>(content, data, isExpanded)}</main>
        </>
      )}
    </aside>
  );
}

export default Drawer;
