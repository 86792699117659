import type { FormikHelpers } from 'formik';
import { useCallback } from 'react';

import { useUpdateAdSchedule } from '../../../../../../common/hooks/mutations/useUpdateAdSchedule';
import type { SchedulingFormInitialValues, UseAdSchedulingFormProps, UseAdSchedulingFormReturnedType } from '../types';

export const useAdSchedulingForm = ({
  row,
  isAdStartDate,
  onSuccessUpdate,
  onFailureUpdate,
}: UseAdSchedulingFormProps): UseAdSchedulingFormReturnedType => {
  const {
    original: { id, startDate, endDate, lineItem },
  } = row;

  const oldCellValue = isAdStartDate ? startDate : endDate;

  const { cellVariant, loading, updateAdSchedule } = useUpdateAdSchedule({
    adId: id,
    onSuccessUpdate,
    onFailureUpdate,
  });

  const onSchedulingFormSubmit = useCallback(
    async (values: SchedulingFormInitialValues, helpers: FormikHelpers<SchedulingFormInitialValues>) => {
      await updateAdSchedule({
        schedule: values.schedule,
        lineItemSchedule: lineItem?.schedule || null,
      });
      helpers.resetForm({ values });
    },
    [lineItem, updateAdSchedule]
  );

  return {
    oldCellValue,
    loading,
    cellVariant,
    onSchedulingFormSubmit,
  };
};
