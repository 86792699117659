import type { Maybe } from 'graphql/jsutils/Maybe';

import type {
  AuditAdV5,
  AuditLineItemV5,
  ChangeLogEntry as ChangeLogEntryGql,
  SingleValueChange,
} from '../../apis/graphql';
import type { ChangeLogUnionObject } from '../../apis/graphql';
import type { DayPartTimeRanges } from '../TimesList/types';
import type { DefaultAudit } from './ChangeLogListEntry/ChangeLogListEntry';
import type { TargetingEntry, Toggles } from './hooks/useChangeLog';

export type ChangeLogRecord = SingleValueChange;

export type ChangeLogRecordsList = ChangeLogRecord[];

export enum FieldNameTypes {
  id = 'id',
  name = 'name',
}

export type ChangeLogEntry = Omit<ChangeLogEntryGql, 'changeList' | 'object'> & {
  changeList: Array<SingleValueChange>;
  object: ChangeLogUnionObject;
};

export type NullableDayPart = {
  firstActiveHour: Maybe<number>;
  lastActiveHour: Maybe<number>;
  activeDayList: Array<string | null>;
};

export type ChangedDayPartRange = {
  day: string;
  oldRange: DayPartTimeRanges;
  newRange: DayPartTimeRanges;
};

export interface ChangeLogProps {
  id?: string;
  entityType?: string;
}

export interface ChangeLogTableProps {
  targetingEntry?: TargetingEntry;
  changes: Array<ChangeLogRecord>;
  subHeaderTitle: string;
  isLineThrough: boolean;
}

export interface NewChangeLogTablePropsWithCreatives {
  targetingEntry?: TargetingEntry;
  changes: Array<ChangeLogRecord>;
  creativeTablesProps?: DefaultAudit;
  creativeDateFormat?: string;
  creativeModifiedBy?: string;
  subHeaderTitle: string;
  isLineThrough: boolean;
  parentIndex?: number;
  toggles?: Toggles[];
  toggleAt?: (index: number, value: boolean | boolean[]) => void;
}

export interface NewChangeLogTableProps {
  auditProps: ChangeLogTableProps[];
  creativeProps: ChangeLogTableProps[];
}

export type ArrayGroupedChanges = {
  [key: string]: ChangeLogRecordsList;
};

export type ReduceGroupChangesByIndex = {
  notArrayChanges: ChangeLogRecordsList;
  arrayGroupedChanges: ArrayGroupedChanges;
};

export type GroupChangesByIndex = {
  audits: {
    notArrayChanges: ChangeLogRecordsList;
    arrayGroupedChanges: ArrayGroupedChanges;
  };
  creativeAudits: {
    notArrayChanges: ChangeLogRecordsList;
    arrayGroupedChanges: ArrayGroupedChanges;
  }[];
};

export type AdChangeInLineItem = {
  name: string;
  id: string;
};

export type JoinedTermsArray = { dimensionId: string; valuesIds: string[]; not: boolean }[];

export type ChangeLogUnionObjectWithTerms<
  Object extends 'AuditLineItemV5' | 'AuditAdV5'
> = Object extends 'AuditLineItemV5'
  ? AuditLineItemV5 & { lineItemTargetingTermValues: AuditLineItemV5['targetingTermValues'] }
  : AuditAdV5 & { adTargetingTermValues: AuditAdV5['targetingTermValues'] };
