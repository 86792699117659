import './DuplicateAdFormThirdStep.scss';

import type { ApolloError } from '@apollo/client';
import { Typography } from '@hulu/react-style-components';
import type { FormikConfig, FormikValues } from 'formik';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { convertAdsPageAdToFormValues } from '../../../../../common/AdForm/adFormik';
import AdSideNav from '../../../../../common/AdForm/AdSideNav';
import Loader from '../../../../../common/Loader';
import { openToastAlert } from '../../../../../common/ToastAlert/toastAlert';
import bem from '../../../../../utils/bem';
import type { AdModel } from '../../../TraffickingPage/modelConverters';
import { DUPLICATE_INFO } from '../../constants';
import DuplicatedAdStepWrapper from '../DuplicatedAdStepWrapper';
import { FieldName } from '../enums';
import { useFetchDuplicateAd } from '../hooks';
import type { DuplicateAdFormValues } from '../types';
import { AdPreviewForm } from './AdPreviewForm';

const [block, element] = bem('duplicate-ad-form');

type Props = Pick<FormikConfig<FormikValues>, 'validationSchema'> & {
  adId: string;
  lineItem: AdModel['lineItem'];
};

const onError = (e: ApolloError): void => {
  openToastAlert({
    alertType: 'error',
    message: e.message,
    description: e.extraInfo,
  });
};

const DuplicateAdFormThirdStep = ({ adId, lineItem }: Props): JSX.Element => {
  const { ad, lineItemTargetingTermValues, loading } = useFetchDuplicateAd(adId, lineItem?.id || '', onError);
  const { initialValues, values, setValues } = useFormikContext<DuplicateAdFormValues>();

  useEffect(() => {
    if (ad) {
      setValues({ ...initialValues, ...convertAdsPageAdToFormValues(initialValues, ad) });
    }
  }, [initialValues, ad, setValues]);

  return (
    <DuplicatedAdStepWrapper title="Select Criteria for Duplicate(s)">
      <div className={block()}>
        <Typography variant="p" className={element('duplicate-info')}>
          {DUPLICATE_INFO}
        </Typography>
        <div className={element('form-content')}>
          <AdSideNav containerId="ad-preview-form-id" />
          {loading ? (
            <Loader className={element('loader')} />
          ) : (
            <AdPreviewForm
              lineItem={lineItem}
              lineItemTargetingTermValues={lineItemTargetingTermValues}
              duplicateLineItems={values[FieldName.lineItems] || []}
            />
          )}
        </div>
      </div>
    </DuplicatedAdStepWrapper>
  );
};

export default DuplicateAdFormThirdStep;
