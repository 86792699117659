import '../style.scss';

import { AccountMenu, ProfileMenu } from '@hulu/react-style-components';
import { User } from 'mission-control-common-components';
import type { Dispatch, SetStateAction } from 'react';
import React, { useEffect, useRef } from 'react';

import bem from '../../../utils/bem';
import { UserNavLogoutButton } from './UserNavLogoutButton';

const [block] = bem('account-menu');

/**
 * User nav will get user information based on id_token in the Cookie.
 * As for now(04/26/2021) the only property which can identify the user from id_token is email. Thus we put email as the first name and skipped the last name.
 * TODO: https://jira.hulu.com/browse/AX-2059 Add user first name and last name back. This would include either:
 * 1. Ask ad infra to add those into id_token
 * or 2. Make a call to AMS(campaign-gql as the bridge) to get the user data.
 */

export interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setWidthOfModal: Dispatch<SetStateAction<number | null>>;
}

const UserNav = ({ isOpen, setIsOpen, setWidthOfModal }: Props): React.ReactElement => {
  const userAuthInfo = User.getUserInfo();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (setWidthOfModal) {
      setWidthOfModal(ref.current ? ref?.current?.offsetWidth : 0);
    }
  }, [setWidthOfModal, isOpen]);

  if (userAuthInfo?.userInfo) {
    const {
      userInfo: { email },
    } = userAuthInfo;

    return (
      <div className={block()} onClick={(): void => setIsOpen(!isOpen)} ref={ref}>
        <AccountMenu firstName={email} lastName="" isOpen={isOpen}>
          <ProfileMenu isOpen={isOpen}>
            <UserNavLogoutButton />
          </ProfileMenu>
        </AccountMenu>
      </div>
    );
  } else {
    return <span className={block('not-found')}>User Not Found</span>;
  }
};

export default UserNav;
