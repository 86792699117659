import './TierNameCell.scss';

import { User } from 'mission-control-common-components';
import React, { memo, useMemo } from 'react';
import type { Cell } from 'react-table';

import EditableDropdown from '../../../../../common/EditableTableCells/EditableDropdown';
import Tooltip from '../../../../../common/Tooltip';
import { getTireAndPriorityNamesComboErrorMessage, onLineItemFailureUpdate } from '../../../../../common/utils';
import type { DeliveryTier } from '../../../../../configs';
import { convertConfigToDropdownOption, convertConfigToDropDownOptions, deliveryTiers } from '../../../../../configs';
import type { PermissionsNames } from '../../../../../constants';
import type { Tier } from '../../../../../models/tier';
import bem from '../../../../../utils/bem';
import { cellPropsComparison } from '../../../../../utils/common';
import { getIdFromTableCell } from '../../columns';
import { useUpdateLineItem } from '../../hooks';
import type { LineItemModel } from '../../modelConverters';

type Props = Cell<LineItemModel, Tier>;

const [block] = bem('tier-name-cell');

function TierNameCell(props: Props): JSX.Element {
  const permissions = User.getPermissions<PermissionsNames>();
  const id = getIdFromTableCell(props);
  const {
    value: { name: deliveryTier },
  } = props;

  const { cellVariant, errors, loading, updateLineItemField } = useUpdateLineItem(id, 'tier', onLineItemFailureUpdate);

  const tooltipMessage = useMemo(() => getTireAndPriorityNamesComboErrorMessage(errors, loading), [errors, loading]);

  const updateLineItemTier = (deliveryTier: DeliveryTier): void => {
    updateLineItemField({
      name: deliveryTier,
    });
  };

  const tireNameDropdown = (
    <EditableDropdown<DeliveryTier>
      selectedValue={convertConfigToDropdownOption<DeliveryTier>(deliveryTier)}
      onSubmit={updateLineItemTier}
      options={convertConfigToDropDownOptions(deliveryTiers)}
      variant={cellVariant}
      dataTestId="tier-name-cell"
      isDisabled={!permissions?.updateLineItem}
    />
  );

  return (
    <div className={block()}>
      {tooltipMessage ? (
        <Tooltip id="tier-name-cell" message={tooltipMessage} place="top">
          {tireNameDropdown}
        </Tooltip>
      ) : (
        tireNameDropdown
      )}
    </div>
  );
}

export default memo(TierNameCell, cellPropsComparison);
