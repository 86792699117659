import type { AdSelectorOption, CreativeV5Type } from '../../../apis/graphql';
import type { Nullable } from '../../../models';
import type { CreativesBuilderValues, CreativeV5Types, DaapiEntity } from '../CreativeBuilders/types';

export function creativeTypeToAssetKey(creativeType: CreativeV5Type): Array<keyof CreativeV5Types> | null {
  switch (creativeType) {
    case 'BRIGHTLINE':
    case 'INNOVID':
    case 'VAST_VIDEO':
    case 'DRAX':
    case 'MAGNITE':
      return ['videoAssetList'];
    case 'DISPLAY':
    case 'PAUSE':
      return ['image'];
    case 'SLATE':
      return ['slate'];
    case 'AD_SELECTOR_VIDEO':
    case 'VIDEO':
      return ['video'];
    case 'GATEWAY_GO':
      return ['offerImage', 'video'];
    case 'AD_SELECTOR_SLATE':
      return ['logo', 'optionList'];
    default:
      return null;
  }
}

const getAssetIdsByKey = (creative: CreativesBuilderValues, assetKey: keyof CreativeV5Types): string[] => {
  if (assetKey === 'optionList') {
    return (
      creative?.optionList?.reduce<string[]>((ids: string[], option: AdSelectorOption) => {
        if (option?.thumbnail?.id) {
          ids.push(option.thumbnail.id);
        }

        return ids;
      }, []) || []
    );
  }

  if (Array.isArray(creative[assetKey]) || (!!creative.videoAssetList && !!creative.videoAssetList.length)) {
    return creative.videoAssetList && !!creative.videoAssetList.length
      ? creative.videoAssetList.reduce((acc: string[], video: Nullable<DaapiEntity>) => {
          if (!!video && !!video?.id) {
            acc.push(video.id);
          }
          return acc;
        }, [])
      : [];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const assetObject: any = !!creative[assetKey] && creative[assetKey]?.hasOwnProperty('id') ? creative[assetKey] : null;
  const assetId = !!assetObject && typeof assetObject !== 'string' ? assetObject.id : null;
  return !!assetId ? [assetId] : [];
};

export function getAssetIds(creative: CreativesBuilderValues | null): string[] {
  if (!creative) {
    return [];
  }
  const assetKeys = creativeTypeToAssetKey(creative.type);

  if (!assetKeys) return [];

  return assetKeys.map((key) => getAssetIdsByKey(creative, key)).flat();
}
