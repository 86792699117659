import './Alert.scss';

import {
  IconAlertErrorRed,
  IconAlertInfoBlue,
  IconAlertSuccessGreen,
  IconAlertWarningYellow,
  IconClose,
} from '@hulu-react-style-components/icons';
import React, { useState } from 'react';

import bem, { withOptionalClassName } from '../../utils/bem';

export enum AlertType {
  SUCCESS = 'success',
  WARNING = 'warning',
  INFORMATION = 'information',
  ERROR = 'error',
}

export interface AlertProps {
  type: AlertType;
  bodyText: string | React.ReactNode;
  headerText?: string;
  className?: string;
  onDismiss?: () => void;
}

const [block, element] = bem('alert');

const getIcon = (type: AlertType): React.ReactNode => {
  switch (type) {
    case AlertType.SUCCESS:
      return <IconAlertSuccessGreen />;
    case AlertType.WARNING:
      return <IconAlertWarningYellow />;
    case AlertType.INFORMATION:
      return <IconAlertInfoBlue />;
    case AlertType.ERROR:
      return <IconAlertErrorRed />;
  }
};

// Todo AX-1492: Update or replace with Trek Alert when available
const Alert = ({ type, bodyText, headerText, className, onDismiss }: AlertProps): JSX.Element => {
  const [hidden, setHidden] = useState(false);
  const dismissHandler = (): void => {
    if (onDismiss) {
      onDismiss();
    }
    setHidden(true);
  };

  return (
    <div className={withOptionalClassName(block(), className)} hidden={hidden} role="alert">
      <div className={element('content', [type])}>
        <div className={element('icon')}>{getIcon(type)}</div>
        <div className={element('text')}>
          <span className={element('type')}>{type}!</span>
          {headerText && <span className={element('header-text')}>{headerText}</span>}
          <p className={element('body-text')}>{bodyText}</p>
        </div>
        <div className={element('close-btn')} aria-label="close alert" onClick={dismissHandler}>
          <IconClose />
        </div>
      </div>
    </div>
  );
};

export default Alert;
