import { findIndex, set } from 'lodash';

import type { SingleValueChange } from '../../../../apis/graphql';
import { AdFieldName } from '../../../../configs/adFieldNames';
import { getParentFieldName, removeEmptyArrayElements, setPayloadToNull } from '.';

const blackListSubFieldName: Record<string, string[]> = {
  [AdFieldName.FrequencyCapList]: ['type', 'enabled'],
};

const FIELD_NAME_ALIAS = 'fieldName';
const SUB_FIELD_NAME_ALIAS = 'subFieldName';

const FIELD_NAMES_WITH_FORMATS: Record<string, string> = {
  [AdFieldName.FrequencyCapList]: `${FIELD_NAME_ALIAS}.${SUB_FIELD_NAME_ALIAS}`,
  [AdFieldName.Tier]: `${FIELD_NAME_ALIAS}-${SUB_FIELD_NAME_ALIAS}`,
  [AdFieldName.Priority]: `${FIELD_NAME_ALIAS}-${SUB_FIELD_NAME_ALIAS}`,
};

export const getFieldName = (fieldName: string, subFieldName?: string): string => {
  if (subFieldName && Object.keys(FIELD_NAMES_WITH_FORMATS).includes(fieldName)) {
    return FIELD_NAMES_WITH_FORMATS[fieldName]
      .replace(FIELD_NAME_ALIAS, fieldName)
      .replace(SUB_FIELD_NAME_ALIAS, subFieldName);
  }

  return fieldName;
};

export const prepareRestructuredChangeList = (changeList: Array<SingleValueChange>): SingleValueChange[] => {
  const restructuredChangeList: SingleValueChange[] = [];

  const oldPayloadObject: Record<string, string> = {};
  const newPayloadObject: Record<string, string> = {};

  changeList.forEach((change) => {
    const [fieldName, subFieldName] = getParentFieldName(change.fieldName);

    // usage of fieldName will break targeting values display. use change.fieldName if allowed
    const allowedUsageChangeFieldName: string[] = [
      AdFieldName.TargetingRule,
      AdFieldName.AdTagList,
      AdFieldName.NonBillableAdTrackingList,
      AdFieldName.ClickThrough,
      AdFieldName.BillableAdTracking,
    ];
    const field = allowedUsageChangeFieldName.includes(fieldName) ? change.fieldName : fieldName;

    set(oldPayloadObject, field, change.oldValue.payload);
    set(newPayloadObject, field, change.newValue.payload);

    const newChangeObject = {
      fieldName: getFieldName(fieldName, subFieldName),
      oldValue: {
        action: change.oldValue.action,
        payload: setPayloadToNull(removeEmptyArrayElements(oldPayloadObject[fieldName])),
        __typename: change.oldValue.__typename,
      },
      newValue: {
        action: change.newValue.action,
        payload: setPayloadToNull(removeEmptyArrayElements(newPayloadObject[fieldName])),
        __typename: change.newValue.__typename,
      },
    };

    const index = findIndex(restructuredChangeList, { fieldName });

    if (!blackListSubFieldName[fieldName]?.includes(subFieldName)) {
      if (index === -1) {
        restructuredChangeList.push(newChangeObject);
      } else {
        restructuredChangeList.splice(index, 1, newChangeObject);
      }
    }
  });

  return restructuredChangeList;
};
