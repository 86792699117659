import './MultipleEditDrawerContentFirstStep.scss';

import { useFormikContext } from 'formik';
import React, { useCallback, useMemo } from 'react';

import Button from '../../../../../../../common/Button';
import bem from '../../../../../../../utils/bem';
import type { EntityTypeArray } from '../../../../../TraffickingPage/hooks/makePageDrawerPlugin';
import type { MultipleEditDrawerLineItemsFormValues } from '../../../types';
import MultipleEditDrawerContentHeader from '../../MultipleEditDrawerContentHeader';
import MultipleEditDrawerContentTable from '../../MultipleEditDrawerContentTable';
import { getColumns } from './getColumns';
import type { SelectedRows } from './types';
import { getData, getHeaderText, getTotalText } from './utils';

const [block, element] = bem('multiple-edit-drawer-content-first-step');

type MultipleEditDrawerContentFirstStepProps = {
  entityType: EntityTypeArray;
  onNextStep: () => void;
};

export default function MultipleEditDrawerContentFirstStep({
  entityType,
  onNextStep,
}: MultipleEditDrawerContentFirstStepProps): JSX.Element {
  const {
    values: { entities },
    setFieldValue,
  } = useFormikContext<MultipleEditDrawerLineItemsFormValues>();

  const columns = useMemo(() => getColumns(element('cell')), []);

  const data = useMemo(() => getData(entities), [entities]);

  const initialSelectedRowIds = entities.reduce(
    (prevRowsIds, { isSelected }, index) => ({ ...prevRowsIds, [index]: isSelected }),
    {}
  );

  const listenSelectedRows = useCallback(
    (selectedRows: SelectedRows): void => {
      entities.forEach((_, index) => {
        setFieldValue(`entities[${index}].isSelected`, !!selectedRows[index]);
        setFieldValue(`initialStatuses[${index}].isSelected`, !!selectedRows[index]);
      });
    },
    [entities, setFieldValue]
  );

  const hasSelectedEntity = entities.some((entity) => entity.isSelected);

  return (
    <div className={block()}>
      <MultipleEditDrawerContentHeader>
        <div>{getHeaderText(entityType)}</div>
        <Button size="medium" onClick={onNextStep} disabled={!hasSelectedEntity}>
          Next
        </Button>
      </MultipleEditDrawerContentHeader>
      <div className={element('body')}>
        <MultipleEditDrawerContentTable
          isSortable
          data={data}
          addCheckbox
          columns={columns}
          listenSelectedRows={listenSelectedRows}
          initialState={{ selectedRowIds: initialSelectedRowIds }}
        />
        <div className={element('footer')}>
          {getTotalText(entityType)}
          <span className={element('footer__total')}>{entities.filter(({ isSelected }) => isSelected).length}</span>
        </div>
      </div>
    </div>
  );
}
