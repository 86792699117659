import type { Get_Ads_By_Line_Item_IdsQuery } from '../../../../../../../../../../apis/graphql';
import { reviews } from '../../../../../../../../../../configs';
import type { LineItemModel } from '../../../../../../../../TraffickingPage/modelConverters';
import type { UseCheckConflictsParams } from '../../../types';
import { getAds, getLineItemsToBeActivated } from './utils';

type LineItemWithValidation = {
  lineItemsWithoutActiveAd: Array<LineItemModel['id']>;
  lineItemsWithoutApproved: Array<LineItemModel['id']>;
};

type CheckStatusLineItemConflictsParams = {
  values: UseCheckConflictsParams['values'];
  adsByLineItemsIds?: Get_Ads_By_Line_Item_IdsQuery;
  isStatusSectionSelected?: boolean;
};

const initialConflictsState = {
  lineItemsWithoutApproved: [],
  lineItemsWithoutActiveAd: [],
};

export const checkStatusLineItemConflicts = ({
  values,
  adsByLineItemsIds,
  isStatusSectionSelected,
}: CheckStatusLineItemConflictsParams): LineItemWithValidation => {
  if (!isStatusSectionSelected) {
    return initialConflictsState;
  }

  const allNormalizedAds = getAds(adsByLineItemsIds);

  const lineItemsToBeActivated = getLineItemsToBeActivated(values);

  return lineItemsToBeActivated.reduce<LineItemWithValidation>((accumulatorConflicts, currentLineItem) => {
    const isLineItemApproved = currentLineItem.review === reviews.APPROVED;

    const lineItemAds = allNormalizedAds?.filter((ad) => ad.lineItemId === currentLineItem.id);
    const isLineItemHasActiveAd = lineItemAds?.some((lineItemAd) => lineItemAd.hasActiveAd);

    if (!isLineItemApproved || !isLineItemHasActiveAd) {
      accumulatorConflicts = {
        lineItemsWithoutApproved: isLineItemApproved
          ? [...accumulatorConflicts.lineItemsWithoutApproved]
          : [...accumulatorConflicts.lineItemsWithoutApproved, currentLineItem.id],
        lineItemsWithoutActiveAd: isLineItemHasActiveAd
          ? [...accumulatorConflicts.lineItemsWithoutActiveAd]
          : [...accumulatorConflicts.lineItemsWithoutActiveAd, currentLineItem.id],
      };
    }

    return accumulatorConflicts;
  }, initialConflictsState);
};
