import { useMemo } from 'react';
import type { DropResult } from 'react-beautiful-dnd';

import { UNSEQUENCED_TABLE_ID } from '../constants';
import { useSequenceContext } from '../context';
import type { SequenceAdV5 } from '../types';
import useSequenceForm from './useSequenceForm';

export type UseSequenceContent = {
  filteredUnsequencedAds: SequenceAdV5[];
  handleDragEnd: (dropResult: DropResult) => void;
};

const useSequenceContent = (): UseSequenceContent => {
  const { sequenceState } = useSequenceContext();
  const { searchUnsequencedAdsTerm } = sequenceState;

  const { values, changeAdSequenceOrder } = useSequenceForm();
  const { sequences, unsequencedAds } = values;

  // filtered unseqeunced ads according to search term
  const filteredUnsequencedAds = useMemo((): SequenceAdV5[] => {
    if (!searchUnsequencedAdsTerm) return unsequencedAds;

    const loweredSearchTerm = searchUnsequencedAdsTerm.toLowerCase();

    return unsequencedAds.reduce((acc, ad) => {
      const { name, trackingId } = ad;

      const loweredName = name?.toLowerCase();
      const loweredTrackingId = trackingId?.toLowerCase();

      if (
        (loweredTrackingId && loweredTrackingId.includes(loweredSearchTerm)) ||
        loweredName.includes(loweredSearchTerm)
      ) {
        acc.push(ad);
      }

      return acc;
    }, new Array<SequenceAdV5>());
  }, [searchUnsequencedAdsTerm, unsequencedAds]);

  // handle drag and drop on sequence tables
  const handleDragEnd = (dropResult: DropResult): void => {
    const { destination, source } = dropResult;

    if (destination) {
      const { droppableId: sourceDroppableId, index: sourceIndex } = source;
      const isUnsequencedSource = sourceDroppableId === UNSEQUENCED_TABLE_ID;

      // find ad, that was dragged from a table
      const draggedAd = isUnsequencedSource
        ? filteredUnsequencedAds[sourceIndex]
        : sequences[Number(sourceDroppableId)].ads[sourceIndex];

      // handle situations, when indexes from filtered unsequenced array are not the same as in the original array
      if (isUnsequencedSource) {
        source.index = unsequencedAds.findIndex(({ id }) => id === draggedAd.id);
      }

      changeAdSequenceOrder({ destination, source, draggedAd });
    }
  };

  return {
    filteredUnsequencedAds,
    handleDragEnd,
  };
};

export default useSequenceContent;
