import { useState } from 'react';

import { sequenceData } from '../../../../common/TraffickingTable/utils';
import type { TraffickingTableName } from '../../../../constants';

export type UseSequenceViewResult = {
  isSequenceViewEnabled: boolean;
  toggleIsSequenceViewEnabled: (value: boolean) => void;
};

const useSequenceView = (tableName: TraffickingTableName): UseSequenceViewResult => {
  const localStorageData = sequenceData.getStorageData(tableName);

  const [isSequenceViewEnabled, setIsSequenceViewEnabled] = useState<boolean>(
    !!localStorageData && !!localStorageData[0] && localStorageData[0] === 'true'
  );

  const toggleIsSequenceViewEnabled = (value: boolean): void => {
    sequenceData.setStorageData(tableName, [String(value)]);

    setIsSequenceViewEnabled(value);
  };

  return {
    isSequenceViewEnabled,
    toggleIsSequenceViewEnabled,
  };
};

export default useSequenceView;
