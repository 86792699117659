import type { AdType } from '../../../configs';
import type { AdDimensionsType } from './CreativeBuilder/CreativeBuilder';

const video = {
  sizes: [{ width: 1920, height: 1080 }],
};

const siteServedVideo = {
  sizes: [
    { width: 1920, height: 1080 },
    { width: 1280, height: 720 },
  ],
};

export const adProductDimensionSpec: Record<AdType, AdDimensionsType> = {
  UNKNOWN: { video: siteServedVideo },
  AD_SELECTOR: {
    logo: { sizes: [{ width: 254, height: 79 }] },
    option: {
      display: { sizes: [{ width: 546, height: 307 }] },
      video,
    },
  },
  AD_SELECTOR_SLATE: {
    logo: { sizes: [{ width: 254, height: 79 }], isJPGTypeAllowed: true },
    option: {
      display: { sizes: [{ width: 546, height: 307 }], isJPGTypeAllowed: true },
    },
  },
  AD_SELECTOR_VIDEO: {
    video: siteServedVideo,
  },
  BE_SELECTOR_SLATE: { video: siteServedVideo },
  BE_SELECTOR_VIDEO: { video: siteServedVideo },
  BRIGHTLINE: { video },
  COLLECTION_LOGO: {
    display: { sizes: [{ width: 750, height: 180 }] },
  },
  COVER_STORY_LOGO: {
    display: { sizes: [{ width: 750, height: 180 }] },
  },
  GATEWAY_GO: {
    display: { sizes: [{ width: 1185, height: 350 }] },
    video: {
      fileSize: 250 * 1024 * 1024,
      sizes: siteServedVideo.sizes,
    },
  },
  GATEWAY_SHOP: {
    display: { sizes: [{ width: 1185, height: 350 }] },
    video,
  },
  HUB_LOGO: {
    display: { sizes: [{ width: 750, height: 180 }] },
  },
  INNOVID: { video },
  MARQUEE_PROMOTED_CONTENT: {
    logo: { sizes: [{ width: 750, height: 750 }], isJPGTypeAllowed: true },
    orientation: {
      landscape: { sizes: [{ width: 3840, height: 2160 }], isJPGTypeAllowed: true },
      portrait: { sizes: [{ width: 1440, height: 2160 }], isJPGTypeAllowed: true },
    },
  },
  MAX_SELECTOR: {
    display: { sizes: [{ width: 1185, height: 350 }] },
  },
  PAUSE: {
    display: { sizes: [{ width: 1085, height: 770 }] },
  },
  PAUSE_QR: {
    display: { sizes: [{ width: 1085, height: 770 }] },
  },
  SLATE: { video: siteServedVideo },
  VIDEO: { video: siteServedVideo },
};
