import React from 'react';
import type { Cell, Column, Row, TableBodyPropGetter } from 'react-table';

import { FOCUSABLE_ELEMENT_SELECTOR } from '../constants';

type CommonTableBodyRow<RawObject extends {} = {}> = Row<RawObject> & {
  /** A boolean to indicate if the row is highlighted via the checkbox or not. */
  isSelected?: boolean;
};

type ColumnExtender<T extends Object = {}> = Column<T> & {
  /** A boolean to indicate if there is something editable on the row. */
  isEditable?: boolean | ((row: T) => boolean);
};

type CommonTableBodyRowConfig<T extends Object = {}> = Cell<T> & {
  /** A boolean to indicate if there is something editable on the row. */
  isEditable?: boolean | ((row: T) => boolean);
  column: ColumnExtender<T>;
};

export type CommonTableBody<RawObject extends {} = {}> = {
  /** A function to get table props provided by useTable in react-table. */
  getTableBodyProps: () => TableBodyPropGetter<object>;
  /** An array of objects provided by useTable in react-table. */
  rows: CommonTableBodyRow<RawObject>[];
  /** A function provided by useTable in react-table. */
  prepareRow: (row: CommonTableBodyRow<RawObject>) => void;
  /** A number representing what column is selected. */
  selectedColIndex: number;
  /** A number representing what row index is selected. */
  selectedRowIndex: number;
  /** A boolean that keeps the checkbox to the left. */
  checkboxSticky: boolean;
};

const handleFocus = (event: React.ChangeEvent): void => {
  const focusable = event.target.querySelector(FOCUSABLE_ELEMENT_SELECTOR) as HTMLElement;

  focusable?.focus();
};

const cellToElement = <RawObject extends {} = {}>(
  cell: CommonTableBodyRowConfig<RawObject>,
  selectedColIndex: number,
  selectedRowIndex: number,
  colIndex: number,
  checkboxSticky: boolean
): JSX.Element => {
  const rowIndex = cell.row.index;
  const cellKey = cell.getCellProps().key.toString();
  let hasEditableClass = false;

  if (typeof cell.column.isEditable === 'boolean') {
    hasEditableClass = cell.column.isEditable;
  } else if (typeof cell.column.isEditable === 'function') {
    hasEditableClass = cell.column.isEditable(cell.row.original);
  }

  const isCellSelected = colIndex === selectedColIndex && rowIndex === selectedRowIndex;

  return (
    <td
      {...cell.getCellProps()}
      className={`${hasEditableClass ? 'editable' : 'not-editable'}${isCellSelected ? ' selected' : ''}${
        checkboxSticky && cellKey.includes('checkbox') ? ' sticky' : ''
      }`}
      tabIndex={isCellSelected ? 0 : -1}
      data-row={rowIndex}
      data-col={colIndex}
      onFocus={handleFocus}
    >
      {cell.render('Cell')}
    </td>
  );
};

const renderRows = <RawObject extends {} = {}>(
  prepareRow: (row: CommonTableBodyRow<RawObject>) => void,
  rows: CommonTableBodyRow<RawObject>[],
  selectedColIndex: number,
  selectedRowIndex: number,
  checkboxSticky: boolean
): JSX.Element[] => {
  return rows.map((row) => {
    prepareRow(row);

    const rowCheckedCls = row.isSelected ? 'checked' : '';

    return (
      <tr {...row.getRowProps()} className={`row${rowCheckedCls ? ' checked' : ''}`}>
        {row.cells.map((cell: CommonTableBodyRowConfig<RawObject>, colIndex) =>
          cellToElement(cell, selectedColIndex, selectedRowIndex, colIndex, checkboxSticky)
        )}
      </tr>
    );
  });
};

const TableBody = <RawObject extends {} = {}>({
  getTableBodyProps,
  prepareRow,
  rows,
  selectedColIndex,
  selectedRowIndex,
  checkboxSticky,
}: CommonTableBody<RawObject>): JSX.Element => {
  return (
    <tbody {...getTableBodyProps()}>
      {renderRows(prepareRow, rows, selectedColIndex, selectedRowIndex, checkboxSticky)}
    </tbody>
  );
};

export default TableBody;
