import { gql } from '@apollo/client';

import { AD_TRAFFICKING_COLUMN, LINE_ITEM_TRAFFICKING_COLUMNS } from '../../../../../TraffickingPage/hooks/fragments';

export const GET_ADS_BY_LINE_ITEM_IDS = gql`
  query GET_ADS_BY_LINE_ITEM_IDS($lineItemIds: [ID!]!) {
    ads: adsV5(filterOptions: { lineItemIdList: $lineItemIds }) {
      edges {
        node {
          status
          lineItem {
            id
          }
        }
      }
    }
  }
`;

export const UPDATE_LINE_ITEMS = gql`
  mutation MultiEditLineItem($input: MultiEditLineItemsInputV5!, $targetingRule: TargetingRuleInput) {
    multiEditLineItem: multiEditLineItemsV5(input: $input, targetingRule: $targetingRule) {
      ...lineItemTraffickingColumns
      __typename
    }
  }
  ${LINE_ITEM_TRAFFICKING_COLUMNS}
`;

export const UPDATE_ADS = gql`
  mutation MultiEditAds($input: MultiEditAdsInputV5!, $targetingRule: TargetingRuleInput) {
    multiEditAds: multiEditAdsV5(input: $input, targetingRule: $targetingRule) {
      ...adTraffickingColumn
      __typename
    }
  }
  ${AD_TRAFFICKING_COLUMN}
`;
