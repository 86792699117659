import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';

import type {
  CountryCode,
  DisplayAssetV5,
  SearchAssetsQuery as GQLQueryResult,
  SearchAssetsQueryVariables as GQLQueryVariables,
  VideoAssetV5,
} from '../../../../apis/graphql';
import { getDataFromNodes } from '../../../../apis/graphql';
import type { AssetType } from '../../../../configs';
import type { AssetV5, BaseAssetV5 } from '../../../../models';
import { SEARCH_ASSETS } from './queries/searchAssets';

export interface UseSearchAssetsResult {
  loading: boolean;
  error?: ApolloError;
  results: AssetV5[]; // return empty array if no results are returned by API
}

export function paramsToQueryVariables(query: string, assetTypes?: AssetType[], limit?: number): GQLQueryVariables {
  return {
    name: query,
    type: assetTypes,
    limit,
  };
}

export function searchAssetsResultToModel(gqlResult?: GQLQueryResult): AssetV5[] {
  const nodeToModel = (node: DisplayAssetV5 | VideoAssetV5): AssetV5 => {
    const baseAsset: BaseAssetV5 = {
      ...node,
      assetTagList: node.assetTagList || [],
      filename: node.filename ?? undefined,
      publisher: node.publisher ?? undefined,
      review: node?.review || 'PENDING',
      createdAt: node.createdAt,
      countryCodes: node?.countryCodes?.filter((code) => !!code) as CountryCode[],
    };

    if (node.type === 'DISPLAY') {
      const displayNode = node as DisplayAssetV5;

      return {
        ...baseAsset,
        height: (displayNode.height ?? 0).toString(),
        type: node.type,
      };
    }

    return {
      ...baseAsset,
      type: node.type,
    };
  };

  return getDataFromNodes(gqlResult, 'searchAssetsV5', nodeToModel)();
}

export function useSearchAssets(query: string, assetTypes?: AssetType[], limit?: number): UseSearchAssetsResult {
  const trimmedQuery = query.trim();

  const { error, loading, data } = useQuery<GQLQueryResult, GQLQueryVariables>(SEARCH_ASSETS, {
    skip: !trimmedQuery, // skip query if no valid name is provided
    variables: paramsToQueryVariables(trimmedQuery, assetTypes, limit),
  });

  return {
    loading,
    error,
    results: searchAssetsResultToModel(data),
  };
}
