import { Typography } from '@hulu/react-style-components';
import styled from 'styled-components';

import { theme } from '../../../styles/styled';

export const StyledLabel = styled(Typography).attrs({ variant: 'eyebrow' })`
  line-height: 12px;
  font-size: 12px;
  padding-bottom: 6px;
  display: block;
  color: ${theme.color.design.fauxBlack};
  text-align: left;
`;
