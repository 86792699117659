import React from 'react';
import type { HeaderGroup } from 'react-table';

import type { DuplicateLineItem } from '../../../../hooks/useDuplicateLineItemsState';
import { pickerElement } from '../../constants';

export type HeaderProps = {
  headerGroups: HeaderGroup<DuplicateLineItem>[];
};

const DuplicateLineItemsTableHeader = ({ headerGroups }: HeaderProps): JSX.Element => {
  return (
    <thead className={pickerElement('header')} data-testid="duplicate-line-items-table-header">
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th tabIndex={-1} {...column.getHeaderProps()} className={pickerElement('header-cell')}>
              <div className={pickerElement('header-cell-container')}>{column.render('Header')}</div>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default DuplicateLineItemsTableHeader;
