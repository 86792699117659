import { debounce } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import type { CellProps } from 'react-table';
import { useFlexLayout, useRowSelect, useTable } from 'react-table';

import Button from '../../../../../../../common/Button';
import ControlledCheckbox from '../../../../../../../common/ControlledCheckbox';
import { useFieldArray } from '../../../../../../../common/Form/hooks';
import type { AdType, DisplayEnum } from '../../../../../../../configs';
import { TraffickingTableName } from '../../../../../../../constants';
import { useFiltersContext } from '../../../../../../../contexts/FilterContext';
import { generateQueryVariables, getSelectedRowIdsFromState } from '../../../../../utils';
import { FieldName } from '../../../enums';
import type { DuplicateLineItem, UseDuplicateLineItemsState } from '../../../hooks';
import { useFetchDuplicateLineItems } from '../../../hooks';
import { pickerElement, sharedTableColumns } from '../constants';
import DuplicateLineItemsTable from '../DuplicateLineItemsTable';
import {
  getInitiallySelectedIds,
  isDifferentSelectionFromSaved,
  transformDuplicateLineItemsToFormValue,
} from '../utils';

export type Props = {
  adName: string;
  adType: DisplayEnum<AdType>;
  handleClearSearch: () => void;
  searchValue: string;
} & Pick<UseDuplicateLineItemsState, 'handleAddSavedItem' | 'handleRemoveSavedItem'>;

const DuplicateLineItemsSearchTable = ({
  adName,
  adType,
  handleClearSearch,
  handleAddSavedItem,
  handleRemoveSavedItem,
  searchValue,
}: Props): JSX.Element => {
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<DuplicateLineItem[]>([]);

  const [, { value: fieldValue }, { setValue: setFieldValue }] = useFieldArray<DuplicateLineItem>(FieldName.lineItems);

  const { filters } = useFiltersContext();

  const lineItemQueryVariables = generateQueryVariables({
    ...filters,
    searchTerm: '',
    sortOption: undefined,
    tableName: TraffickingTableName.lineItems,
    selectedRowIds: getSelectedRowIdsFromState(filters.lineItems?.selectedRowIds || []),
    campaignRowsIds: getSelectedRowIdsFromState(filters.campaigns?.selectedRowIds || []),
  });

  const { handleSearch: handleSearchDuplicateLineItems, hasMore } = useFetchDuplicateLineItems(
    {
      ...lineItemQueryVariables,
      adPotentialTypeList: [adType.key],
    },
    searchValue,
    true
  );

  const selectedSavedRowIds = useMemo(() => getInitiallySelectedIds(adType, fieldValue, undefined, undefined), [
    fieldValue,
    adType,
  ]);

  const handleSearch = useCallback(
    async (newDebounceSearchValue: string): Promise<void | null> => {
      try {
        const transformedData = await handleSearchDuplicateLineItems(newDebounceSearchValue);
        setSearchResults(transformedData);
      } catch {
        return null;
      } finally {
        setIsSearching(false);
      }
    },
    [handleSearchDuplicateLineItems]
  );

  const onNext = async (): Promise<void> => {
    if (hasMore) {
      const transformedData = await handleSearchDuplicateLineItems(searchValue);
      setSearchResults((prevData) => [...prevData, ...transformedData]);
    }
  };

  useEffect(() => {
    setIsSearching(true);
    const debounceSearch = debounce(handleSearch, 500);
    debounceSearch(searchValue);

    return debounceSearch.cancel;
    // This hook should run only when searchValue is changed
    // handleSearch in dependency array leads to more fetches than it needs to have
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const {
    rows,
    headerGroups,
    prepareRow,
    getTableBodyProps,
    getTableProps,
    state: { selectedRowIds },
    selectedFlatRows,
  } = useTable<DuplicateLineItem>(
    {
      columns: sharedTableColumns,
      data: searchResults,
      getRowId: (originalRow) => originalRow.id,
      autoResetSelectedRows: false,
      initialState: {
        selectedRowIds: selectedSavedRowIds,
      },
    },
    useRowSelect,
    useFlexLayout,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }): JSX.Element => (
            <ControlledCheckbox
              {...getToggleAllRowsSelectedProps()}
              className={pickerElement('checkbox')}
              data-testid="controlled-checkbox"
            />
          ),
          Cell: ({ row }: CellProps<DuplicateLineItem>): JSX.Element => (
            <ControlledCheckbox
              {...row.getToggleRowSelectedProps()}
              className={pickerElement('checkbox')}
              data-testid="controlled-checkbox"
            />
          ),
          width: 30,
        },
        ...columns,
      ]);
    }
  );

  const selectedRowIdsKeys = Object.keys(selectedRowIds);
  const selectedSavedRowIdsKeys = Object.keys(selectedSavedRowIds || {});

  const isDifferentSelection = useMemo(
    () => isDifferentSelectionFromSaved(selectedRowIdsKeys, selectedSavedRowIdsKeys),
    [selectedRowIdsKeys, selectedSavedRowIdsKeys]
  );

  const handleConfirm = (): void => {
    const newSelectedSavedItems: DuplicateLineItem[] = [];

    selectedFlatRows.forEach(
      ({
        original: { id, name, schedule, startDate, endDate, adProduct, billableThirdParty, isCoppaOrCaru, campaign },
      }) => {
        if (!selectedSavedRowIdsKeys.includes(id)) {
          const savedItem = {
            id,
            name,
            schedule,
            startDate,
            endDate,
            adProduct,
            billableThirdParty,
            isCoppaOrCaru,
            campaign,
          };
          newSelectedSavedItems.push(savedItem);
          handleAddSavedItem(savedItem);
        }
      }
    );

    const newDeSelectedSavedItems: string[] = [];

    selectedSavedRowIdsKeys.forEach((selectedSavedRowId) => {
      if (!selectedRowIdsKeys.includes(selectedSavedRowId)) {
        handleRemoveSavedItem(selectedSavedRowId);
        newDeSelectedSavedItems.push(selectedSavedRowId);
      }
    });

    const oldFieldValue = fieldValue || [];

    const oldFieldValueWithoutDeleted = oldFieldValue.filter((item) => !newDeSelectedSavedItems.includes(item.id));

    const newFormValue = transformDuplicateLineItemsToFormValue(
      [...oldFieldValueWithoutDeleted, ...newSelectedSavedItems],
      adName
    );

    setFieldValue(newFormValue);

    handleClearSearch();
  };

  return (
    <>
      <DuplicateLineItemsTable
        tableProps={getTableProps()}
        headerGroups={headerGroups}
        tableBodyProps={getTableBodyProps()}
        rows={rows}
        prepareRow={prepareRow}
        isSearching={isSearching}
        emptyMessage="No Line Items found."
        hasMore={hasMore}
        onNext={onNext}
      />
      <div className={pickerElement('actions')} data-testid="duplicate-line-items-actions">
        <Button onClick={handleClearSearch} data-testid="duplicate-line-items-cancel-button">
          Decline
        </Button>
        <Button
          onClick={handleConfirm}
          disabled={!isDifferentSelection}
          data-testid="duplicate-line-items-confirm-button"
        >
          Confirm
        </Button>
      </div>
    </>
  );
};

export default memo(DuplicateLineItemsSearchTable);
