import { useLocation } from '@hulu/react-router-dom';
import { bem } from 'mission-control-common-components';

import { workWithLocalStorage } from '../../utils/storage/localStorage';
import { LOCAL_STORAGE_LOCATION_STATE } from '../Trafficking';
import { SequenceProvider } from './context';
import { SEQUENCE_INITIAL_STATE } from './context/state';
import type { SequenceLocationState } from './SequencePage';
import SequencePage from './SequencePage';

const [block] = bem('sequence-page');

const SequencePageWrapper = (): React.JSX.Element => {
  const location = useLocation();
  const locationState = location.state as SequenceLocationState;
  const localLocationState = workWithLocalStorage.getData<SequenceLocationState>(LOCAL_STORAGE_LOCATION_STATE);
  const state = locationState || localLocationState;

  return (
    <div className={block()}>
      <SequenceProvider
        initialState={{ ...SEQUENCE_INITIAL_STATE, uniqueParentLineOmsLinkIds: state.uniqueParentLineOmsLinkIds }}
      >
        <SequencePage />
      </SequenceProvider>
    </div>
  );
};

export default SequencePageWrapper;
