import './DragDropItem.scss';

import { IconDrag } from '@hulu-react-style-components/icons';
import React from 'react';

import bem from '../../../utils/bem';

const [block, bemElement] = bem('drag-drop-item');

type Props = {
  element: JSX.Element;
};

const DragDropItem: React.FC<Props> = ({ element }) => (
  <div className={block()}>
    <IconDrag className={bemElement('icon')} />
    <div data-testid="drag-drop-element" className={bemElement('element')}>
      {element}
    </div>
  </div>
);

export default DragDropItem;
