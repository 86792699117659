import '../CellItems.scss';

import { camelCase as _camelCase, mapKeys as _mapKeys } from 'lodash';
import React from 'react';

import type { FrequencyCap } from '../../../../../../models';
import bem from '../../../../../../utils/bem';
import { enumToTitleCase, titleCase } from '../../../../../../utils/formatting';
import { GreenPlus } from '../../../../../assets';
import type { Action } from '../../CellItem';
import type { CellItemsProps } from '../index';

const [, element] = bem('multiple-change-log-cell');

const computeRenderValue = (rawValue: FrequencyCap): string | React.ReactNode => {
  if (Object.keys(rawValue).length === 1) {
    const valueKey: string = Object.keys(rawValue)[0];
    return `${enumToTitleCase(valueKey)}: ${titleCase(String(rawValue[valueKey as keyof FrequencyCap]))}`;
  }

  const value = _mapKeys(rawValue, (_val, _key) => _camelCase(_key));

  const hasAllItems = value?.durationUnit && value?.duration && value?.limit;

  const durationUnit = value?.durationUnit ? titleCase(`${value.durationUnit}`) : '';
  const plural = value.duration && value?.durationUnit && value.duration > 1 && durationUnit !== 'Lifetime' ? 's' : '';

  if (hasAllItems) return `${value.limit} / ${value.duration} ${durationUnit}${plural}`;

  if (!value?.limit && !value?.duration && !durationUnit) return null;

  return (
    <>
      {value?.limit && <div>Limit: {value.limit}</div>}
      {value?.duration && <div>Duration: {value.duration}</div>}
      {durationUnit && <div>Unit: {durationUnit}</div>}
    </>
  );
};

interface FrequencyCapListProps {
  item: string | React.ReactNode;
  action: Action;
  index: number;
  hasBottomBorder: boolean;
  noList?: boolean;
}

function FrequencyCapList({ item, action, index, hasBottomBorder, noList }: FrequencyCapListProps): JSX.Element {
  const removalClass = action.removal ? 'removal' : '';

  const capToRender = noList ? (
    <span className={action.addition ? 'addition' : ''}>{item}</span>
  ) : (
    <ul>
      <li>{item || '-'}</li>
    </ul>
  );

  return (
    <div className={element(`freq-cap${hasBottomBorder ? '' : '-border'}`, removalClass)} key={index}>
      {action.addition && <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />}
      {capToRender}
    </div>
  );
}

function FrequencyCapCell({ changes, action }: CellItemsProps): JSX.Element {
  if (changes && Array.isArray(changes)) {
    return (
      <>
        {changes.map(({ payload }, index) => {
          const allPayloadEmpty = Object.values(payload).every((value: unknown): boolean => value === null);
          const hasBottomBorder = changes.length !== 1 && index !== changes.length - 1;
          return !allPayloadEmpty && payload ? (
            <FrequencyCapList
              item={computeRenderValue(payload as FrequencyCap)}
              action={action}
              index={index}
              key={`frequency-cap-${index}`}
              hasBottomBorder={hasBottomBorder}
              noList={changes.length < 2}
            />
          ) : (
            <span>-</span>
          );
        })}
      </>
    );
  }

  if (changes.payload && Array.isArray(changes.payload)) {
    const hasPayloadItems = changes.payload.length > 0;
    return (
      <div>
        {hasPayloadItems ? (
          changes.payload.map((item: FrequencyCap, index: number) => {
            const hasBottomBorder = changes.payload.length !== 1 && index !== changes.payload.length - 1;
            return item ? (
              <FrequencyCapList
                item={computeRenderValue(item)}
                index={index}
                action={action}
                key={`frequency-cap-${index}`}
                hasBottomBorder={hasBottomBorder}
                noList={changes.payload.length < 2}
              />
            ) : (
              <span>-</span>
            );
          })
        ) : (
          <span>-</span>
        )}
      </div>
    );
  }

  return (
    <FrequencyCapList
      item={computeRenderValue(changes.payload as FrequencyCap)}
      hasBottomBorder={false}
      index={0}
      action={action}
    />
  );
}

export default FrequencyCapCell;
