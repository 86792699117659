import { useMemo } from 'react';
import type { Row } from 'react-table';

import { TraffickingTableName } from '../../../../constants';
import type { AdModel, EntityModel, LineItemModel } from '../modelConverters';
import { DEFAULT_UNGROUPED_ID, getGroupedAdsBySequences, getGroupedLineItemsBySequences } from './utils';

export type GroupedRows = {
  id: string;
  title?: string;
  rows: Row<AdModel>[] & Row<LineItemModel>[];
};

export type GroupedData = {
  title?: string;
  data: GroupedRows[];
};

type UseGroupedTraffickerDataProps = {
  tableName: TraffickingTableName;
  isSequenceViewEnabled?: boolean;
  rows: Row<EntityModel>[];
};

export type UseGroupedTraffickerDataResult = {
  groupedData: GroupedData[];
  ungroupedData: GroupedRows;
  isGrouped: boolean;
};

const SEQUENCE_VIEW_TRANSFORM_FUNCTIONS = {
  [TraffickingTableName.ads]: getGroupedAdsBySequences,
  [TraffickingTableName.lineItems]: getGroupedLineItemsBySequences,
} as const;

const useGroupedTraffickerData = ({
  tableName,
  isSequenceViewEnabled,
  rows,
}: UseGroupedTraffickerDataProps): UseGroupedTraffickerDataResult => {
  const processedData = useMemo((): Pick<UseGroupedTraffickerDataResult, 'groupedData' | 'ungroupedData'> => {
    if (isSequenceViewEnabled && tableName !== TraffickingTableName.campaigns) {
      return SEQUENCE_VIEW_TRANSFORM_FUNCTIONS[tableName](rows as Row<AdModel>[] & Row<LineItemModel>[]);
    }

    return {
      groupedData: [],
      ungroupedData: {
        id: DEFAULT_UNGROUPED_ID + tableName,
        rows: [...rows] as Row<AdModel>[] & Row<LineItemModel>[],
      },
    };
  }, [isSequenceViewEnabled, rows, tableName]);

  const isGrouped = !!isSequenceViewEnabled;

  return {
    ...processedData,
    isGrouped,
  };
};

export default useGroupedTraffickerData;
