import { lineItemDerivedState, lineItemStatuses } from '../../../../configs';
import { TraffickingTableName } from '../../../../constants';
import type { Nullable } from '../../../../models';
import type { FlatRowType } from '../../CreateReviewButton/types';
import type { EntityModel, LineItemModel } from '../../TraffickingPage/modelConverters';

export const OVERRIDE_ERROR_MSGS = {
  CANCELLED_ENDED_LINE_ITEMS: 'Cannot override cancelled or ended line(s)',
};

export function validateLineItemsProposedForApproval(lineItems: FlatRowType<LineItemModel>): Nullable<string[]> {
  const errors = [];
  // don't override if all selected items are cancelled/ended
  const statusesNotApprovable = lineItems?.every(({ original }) => {
    const { status, subStatus } = original as LineItemModel;

    return status.key === lineItemStatuses.CANCELLED.key || subStatus?.key === lineItemDerivedState.ENDED.key;
  });

  if (statusesNotApprovable) {
    errors.push(OVERRIDE_ERROR_MSGS.CANCELLED_ENDED_LINE_ITEMS);
  }

  return errors?.length ? errors : null;
}

export function validateValuesProposedForApproval({
  selectedFlatRows,
  tableName,
}: {
  selectedFlatRows: FlatRowType<EntityModel>;
  tableName: TraffickingTableName;
}): Nullable<string[]> {
  if (tableName === TraffickingTableName.lineItems) {
    return validateLineItemsProposedForApproval(selectedFlatRows as FlatRowType<LineItemModel>);
  }

  return null;
}
