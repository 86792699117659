import type { AdSequence } from '../../../apis/graphql';
import { adTypes } from '../../../configs';
import type { SequenceAdV5, TransformedSequence } from '../types';

export const AD_SEQUENCE_MOCK: AdSequence = {
  adId: 'some-guid',
  order: 1,
  sequenceId: 'sequence-id',
  name: 'Test 1',
  updatedAt: '2023-10-04T01:33:13',
};

export const MOCK_SEQUENCE_AD_V5: SequenceAdV5 = {
  id: 'some-guid',
  name: 'Ad Name 1',
  type: adTypes.VIDEO.key,
  startDate: '2023-10-04T01:33:13',
  endDate: '2023-11-04T12:33:13',
  trackingId: '000000',
  lineItem: {
    id: 'test id',
    name: 'test name',
    parentLineOmsLink: {
      id: 'parent-line-oms-link',
    },
  },
  adRotation: null,
  adSequence: {
    ...AD_SEQUENCE_MOCK,
  },
};

export const SEQUENCES_MOCK: TransformedSequence[] = [
  {
    id: '8147dc1d-0dd1-4ba6-bd4d-054bcec98dfc',
    name: 'Test 1',
    ads: [
      {
        ...MOCK_SEQUENCE_AD_V5,
        id: 'f0b9ce1d-fd24-440c-ab82-c24bbc6d9189',
        name: 'Ad Name 2',
        trackingId: '000001',
        type: adTypes.SLATE.key,
        adSequence: {
          ...AD_SEQUENCE_MOCK,
          adId: 'f0b9ce1d-fd24-440c-ab82-c24bbc6d9189',
          sequenceId: '8147dc1d-0dd1-4ba6-bd4d-054bcec98dfc',
        },
      },
      {
        ...MOCK_SEQUENCE_AD_V5,
        name: 'Ad Name 3',
        trackingId: '000002',
        adSequence: {
          ...AD_SEQUENCE_MOCK,
          adId: 'e36813d6-0fe7-4d4c-bc98-e3d0d1edbae2',
          sequenceId: '8147dc1d-0dd1-4ba6-bd4d-054bcec98dfc',
          order: 2,
        },
      },
    ],
  },
  {
    id: '2a5df2f8-7ef3-428c-b6b3-7635b5a56ff0',
    name: 'Test 2',
    ads: [
      {
        ...MOCK_SEQUENCE_AD_V5,
        name: 'Ad Name 4',
        trackingId: '000003',
        type: adTypes.SLATE.key,
        adSequence: {
          ...AD_SEQUENCE_MOCK,
          adId: '9a1c3089-a0d0-43c6-b846-c1dacc527375',
          sequenceId: '2a5df2f8-7ef3-428c-b6b3-7635b5a56ff0',
        },
      },
      {
        ...MOCK_SEQUENCE_AD_V5,
        name: 'Ad Name 5',
        trackingId: '000004',
        adSequence: {
          ...AD_SEQUENCE_MOCK,
          adId: '752895a5-61f0-4f30-826c-df18a0e2399a',
          sequenceId: '2a5df2f8-7ef3-428c-b6b3-7635b5a56ff0',
          order: 2,
        },
      },
    ],
  },
];

export const SEQUENCES_INITIAL_VALUES = {
  sequences: SEQUENCES_MOCK,
  unsequencedAds: [MOCK_SEQUENCE_AD_V5],
};
