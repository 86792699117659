import { COLUMN_NAME_ID } from '../../../constants';

export const CampaignColumn = Object.freeze({
  Alert: 'Alert',
  Name: COLUMN_NAME_ID,
  TrackingId: 'TrackingId',
  Brand: 'brand',
  Country: 'Country',
  OrderType: 'OrderType',
  OrderOmsLinkId: 'OrderOmsLinkId',
  TraffickerEmail: 'TraffickerEmail',
  OrderSalespersonEmail: 'OrderSalespersonEmail',
  Start: 'Start',
  End: 'End',
  Advertiser: 'advertiser',
  IndustryName: 'industryName',
  Agency: 'agency',
  CampaignID: 'campaignID',
  CreatedAt: 'CreatedAt',
  UpdatedAt: 'UpdatedAt',
  AdAccount: 'adAccount',
  AdAccountId: 'AdAccountId',
  BrandId: 'BrandId',
  CampaignBudget: 'campaignBudget',
});
