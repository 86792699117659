import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useRef, useState } from 'react';

import type { Get_Ads_By_Parent_Line_Oms_Link_Id_ListQuery } from '../../../apis/graphql/types';
import type { SequenceState } from '../context/state';
import { GET_ADS_BY_PARENT_LINE_OMS_LINK_ID_LIST } from '../queries/getAdsByParentLineOmsLinkIdList';
import type { SequenceFormValues } from '../types';
import { useSequenceContext } from './../context';
import { getSortedAdsByOrder, getSortedAdsEdgesBySavedAdsOrder, getSortedUnsequencedAds } from './utils';

type UseSequenceData = {
  sequenceValues: SequenceFormValues;
  isLoading: boolean;
  error?: string;
};

type UseSequenceDataProps = Pick<SequenceState, 'uniqueParentLineOmsLinkIds'>;

const DEFAULT_OFFSET = 0;
const DEFAULT_LIMIT = 75;

const useSequenceData = ({ uniqueParentLineOmsLinkIds }: UseSequenceDataProps): UseSequenceData => {
  const { sequenceState } = useSequenceContext();
  const savedAdsOrder = useRef<Map<string, number>>(sequenceState.sequencedAdsOrder);

  const { data, fetchMore, loading, error } = useQuery<Get_Ads_By_Parent_Line_Oms_Link_Id_ListQuery>(
    GET_ADS_BY_PARENT_LINE_OMS_LINK_ID_LIST,
    {
      variables: {
        lineItemParentLineOmsLinkList: [...uniqueParentLineOmsLinkIds],
        offset: DEFAULT_OFFSET,
        limit: DEFAULT_LIMIT,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  // isLoading regulates loading state  to avoid showing an hiding with fetchMore
  const [isLoading, setIsLoading] = useState(true);

  // used to fetch all related ads, that were not included on the first try
  useEffect(() => {
    if (data && data?.ads.total > data?.ads.edges.length) {
      setIsLoading(true);
      fetchMore({
        variables: {
          lineItemParentLineOmsLinkList: [...uniqueParentLineOmsLinkIds],
          offset: DEFAULT_OFFSET + DEFAULT_LIMIT,
          limit: data?.ads.total,
        },
      });
    } else if (!loading) {
      setIsLoading(false);
    }
  }, [data, fetchMore, loading, uniqueParentLineOmsLinkIds]);

  // used to save ads order without re-sorting sequenceValue
  useEffect(() => {
    savedAdsOrder.current = sequenceState.sequencedAdsOrder;
  }, [sequenceState.sequencedAdsOrder]);

  // formatted fetched values
  const sequenceValues: SequenceFormValues = useMemo(() => {
    const initialValues: SequenceFormValues = {
      sequences: [],
      unsequencedAds: [],
    };

    if (data) {
      // sort edges to persist current sequence order
      const sortedEdges = getSortedAdsEdgesBySavedAdsOrder(data, savedAdsOrder.current);

      const values = sortedEdges.reduce((acc, { node }) => {
        // if ad have a sequence, than put it into the sequenced category
        if (node.adSequence) {
          const sequenceIndex = acc.sequences.findIndex(({ id }) => node.adSequence?.sequenceId === id);

          // if there is an ad's sequence, add the ad into the ads array of this sequence
          if (sequenceIndex > -1) {
            acc.sequences[sequenceIndex].ads.push(node);
          }
          // if not, create a new sequence object
          else {
            acc.sequences.push({
              id: node.adSequence.sequenceId,
              name: node.adSequence.name,
              ads: [node],
            });
          }
        }
        // if an ad does not have a sequence, put it into the unsequenced category
        else {
          acc.unsequencedAds.push(node);
        }

        return acc;
      }, initialValues);

      // sort ads by ad type and order
      // SLATE should be on top, than VIDEO, ads with rotation should be on the bottom in unsequenced section
      // sorted by order in sequences
      return {
        sequences: values.sequences.map((sequence) => ({
          ...sequence,
          ads: getSortedAdsByOrder(sequence.ads),
        })),
        unsequencedAds: getSortedUnsequencedAds(values.unsequencedAds),
      };
    }

    return initialValues;
  }, [data]);

  return {
    sequenceValues,
    isLoading,
    error: error?.message,
  };
};

export default useSequenceData;
