export const TOOLTIP_MESSAGE = 'Select a single ad to copy this ad to line items';
export const DUPLICATE_INFO =
  'Select ad properties to keep in the duplicated ad. Deselected properties will not be duplicated.';

export const DEFAULT_SELECTED_SECTIONS = ['Creative', 'Targeting', 'Tags', 'Metadata'];

export const TYPE_DIVERGENCE_HOVER_MESSAGE = 'Cannot copy ad to line item with different ad type.';

export const EMPTY_LINE_ITEM_SELECTION_MESSAGE =
  'No Line Items selected. Search for line items you want to copy the ad into.';

export const DUPLICATE_LINE_ITEM_HEADER = 'Confirm the line item(s) where you want a copy of the ad to be placed.';

export const DUPLICATE_REVIEW_AD_HEADER = 'Review the duplicated ad names and modify as needed.';
