import './ChangeLogTablesList.scss';

import React, { memo } from 'react';

import bem from '../../../utils/bem';
import type { DefaultAudit } from '../ChangeLogListEntry/ChangeLogListEntry';
import { ChangeLogTable } from '../ChangeLogTable';
import type { TargetingEntry, Toggles } from '../hooks/useChangeLog';
import type { ChangeLogTableProps } from '../types';
import { getChangesLengthWithoutDuplicates } from '../utils';

const [block] = bem('change-log-tables-list');

type Props = {
  tablesProps?: ChangeLogTableProps[];
  creativeTablesProps?: DefaultAudit;
  targetingEntry?: TargetingEntry;
  creativeDateFormat?: string;
  creativeModifiedBy?: string;
  toggles?: Toggles[];
  index?: number;
  toggleAt?: (index: number, value: boolean | boolean[]) => void;
};

const ChangeLogTablesList = ({
  tablesProps,
  targetingEntry,
  creativeTablesProps,
  creativeDateFormat,
  creativeModifiedBy,
  toggles,
  index: parentIndex,
  toggleAt,
}: Props): JSX.Element => {
  return (
    <div className={block()} data-testid="change-log-tables-list">
      {tablesProps?.map((props, index) => {
        const changesLength = getChangesLengthWithoutDuplicates(props.changes);
        return changesLength ? (
          <ChangeLogTable
            key={index}
            {...props}
            targetingEntry={targetingEntry}
            creativeTablesProps={creativeTablesProps}
            creativeDateFormat={creativeDateFormat}
            creativeModifiedBy={creativeModifiedBy}
            parentIndex={parentIndex}
            toggles={toggles}
            toggleAt={toggleAt}
          />
        ) : null;
      })}
    </div>
  );
};

export default memo(ChangeLogTablesList);
