import { useFormikContext } from 'formik';
import { useMemo } from 'react';

import type { AssetExtended } from '../../../../_ForCommonLibrary/types/extended';
import type { Nullable } from '../../../../../models';
import type { AdFormValues } from '../../../adFormik';
import { useCreativeData } from '../../hooks/useCreativeData';

export type UseAdSelectorSlateAssets = {
  loadingCreativeData: boolean;
  assets: Nullable<AssetExtended>[];
  selectedAsset?: AssetExtended | null;
};

export type UseAdSelectorSlateAssetsProps = {
  selectedTabIndex: number;
};

export const useAdSelectorSlateAssets = ({
  selectedTabIndex,
}: UseAdSelectorSlateAssetsProps): UseAdSelectorSlateAssets => {
  const {
    values: { creative },
  } = useFormikContext<AdFormValues>();

  const {
    data: { assets },
    loading: loadingCreativeData,
  } = useCreativeData(creative);

  // merge assets fetched in useCreativeData and assets from option list
  const sortedAssets = useMemo((): Nullable<AssetExtended>[] => {
    const resultAssetList: Nullable<AssetExtended>[] = [];

    const logoAsset = assets.find(({ id }) => id === creative.logo?.id);
    resultAssetList.push(logoAsset ?? null);

    if (creative.optionList) {
      creative.optionList.forEach((option) => {
        const foundFetchedAsset = assets.find(({ id }) => option.thumbnailId === id);

        if (foundFetchedAsset) {
          resultAssetList.push(foundFetchedAsset);
        } else if (option.thumbnail?.id) {
          resultAssetList.push(option.thumbnail);
        } else {
          resultAssetList.push(null);
        }
      });
    }

    return resultAssetList;
  }, [assets, creative.logo?.id, creative.optionList]);

  return {
    loadingCreativeData,
    assets: sortedAssets,
    selectedAsset: sortedAssets[selectedTabIndex],
  };
};
