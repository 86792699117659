import { isEqual } from 'lodash';

import type {
  ChangeLogUnionObject,
  SimpleChangeValue,
  SingleValueChange,
  TargetingTermValue,
} from '../../../apis/graphql';
import { ChangeAction } from '../../../apis/graphql';
import type { MultiValueChange } from '../ChangeLogTable/ChangeLogTable';
import { NestedArrayChangesProperties, ROWS_TO_IGNORE } from '../constants';
import type { Audits, ChangeLogEntryNode } from '../hooks/useChangeLog';
import type {
  AdChangeInLineItem,
  ArrayGroupedChanges,
  ChangeLogRecord,
  ChangeLogRecordsList,
  ChangeLogUnionObjectWithTerms,
  GroupChangesByIndex,
  JoinedTermsArray,
} from '../types';
import type { ReduceGroupChangesByIndex } from '../types';
import { getArrayPropertyWithIndex } from './formatters';

export const createSingleChange = (
  fieldName: string,
  action: ChangeAction,
  oldPayload: null | string | number | object,
  newPayload: null | string | number | object,
  valueTypename: boolean = false
): SingleValueChange =>
  valueTypename
    ? {
        __typename: 'SingleValueChange',
        fieldName,
        oldValue: {
          __typename: 'SimpleChangeValue',
          action,
          payload: oldPayload,
        },
        newValue: {
          __typename: 'SimpleChangeValue',
          action,
          payload: newPayload,
        },
      }
    : {
        __typename: 'SingleValueChange',
        fieldName,
        oldValue: {
          action,
          payload: oldPayload,
        },
        newValue: {
          action,
          payload: newPayload,
        },
      };

export const createArrayGroupedChangesFromAdList = (payload: AdChangeInLineItem[]): ArrayGroupedChanges =>
  payload.reduce((acc: ArrayGroupedChanges, next, index) => {
    const propertiesAndValues = Object.entries(next);

    const newChanges: ChangeLogRecord[] = propertiesAndValues.map(([property, value]) =>
      createSingleChange(property, ChangeAction.Addition, null, value, true)
    );

    const arrayPropertyNameAndIndex = `ad-list[${index}]`;

    return {
      ...acc,
      [arrayPropertyNameAndIndex]: newChanges,
    };
  }, {});

export const hasDuplicates = (newValue: SimpleChangeValue, oldValue: SimpleChangeValue): boolean => {
  // old and new values not both null, the same, or empty arrays
  const oldAndNewAreSame = isEqual(newValue.payload, oldValue.payload);

  const newValueIsDirty =
    newValue.payload === null ||
    typeof newValue.payload === 'undefined' ||
    (Array.isArray(newValue.payload) && !newValue.payload.length);

  const oldValueIsDirty =
    oldValue.payload === null ||
    typeof oldValue.payload === 'undefined' ||
    (Array.isArray(oldValue.payload) && !oldValue.payload.length);

  return oldAndNewAreSame || (newValueIsDirty && oldValueIsDirty);
};

export const getChangesLengthWithoutDuplicates = (changeList: ChangeLogRecordsList): number =>
  changeList.length
    ? changeList.filter(
        (value: ChangeLogRecord) =>
          !ROWS_TO_IGNORE.includes(value.fieldName) &&
          !hasDuplicates(value.newValue as SimpleChangeValue, value.oldValue as SimpleChangeValue)
      ).length
    : 0;

export const isLineThrough = (action: ChangeAction, lineThroughActions: string[]): boolean => {
  return lineThroughActions.includes(action);
};

export const isActionSameForAllChanges = (
  changeAction: ChangeAction,
  changes: (SingleValueChange | MultiValueChange)[]
): boolean => {
  return changes.every((change) => {
    const newValue = change.newValue;

    if (Array.isArray(newValue)) {
      return newValue.every((value) => value.action === changeAction);
    } else {
      return newValue.action === changeAction;
    }
  });
};

export const getChangesAction = (changes: (SingleValueChange | MultiValueChange)[]): ChangeAction => {
  if (isActionSameForAllChanges(ChangeAction.Addition, changes)) return ChangeAction.Addition;
  if (isActionSameForAllChanges(ChangeAction.Removal, changes)) return ChangeAction.Removal;

  return ChangeAction.Update;
};

const getGroupedChangesByIndex = (acc: ReduceGroupChangesByIndex, next: ChangeLogRecord): ReduceGroupChangesByIndex => {
  if (isFieldArrayChange(next.fieldName)) {
    const { index, property } = getArrayPropertyWithIndex(next.fieldName);

    // Initial creation of ad list in line item
    // e.g. { fieldName: 'ad-list' }
    if (index === undefined && Array.isArray(next.newValue?.payload)) {
      const payload = (next.newValue as SimpleChangeValue).payload;
      const newChanges = createArrayGroupedChangesFromAdList(payload);

      return {
        ...acc,
        arrayGroupedChanges: {
          ...acc.arrayGroupedChanges,
          ...newChanges,
        },
      };
    }

    const arrayPropertyNameAndIndex = `${property}[${index}]`;
    const oldArrayChanges = acc.arrayGroupedChanges[arrayPropertyNameAndIndex] || [];

    return {
      ...acc,
      arrayGroupedChanges: {
        ...acc.arrayGroupedChanges,
        [arrayPropertyNameAndIndex]: [...oldArrayChanges, next],
      },
    };
  }

  return { ...acc, notArrayChanges: [...acc.notArrayChanges, next] };
};

// Here we divide changes on array changes and others
// We group array changes by index
// e.g. ad-list[0]: { ...changes }
export const divideChangesByType = (node: Audits): GroupChangesByIndex => {
  const audits = node.audits.changeList.reduce(getGroupedChangesByIndex, {
    notArrayChanges: [],
    arrayGroupedChanges: {},
  });

  const creativeAudits = node.creativeAudits.map((creative) => {
    return creative.changeList.reduce(getGroupedChangesByIndex, { notArrayChanges: [], arrayGroupedChanges: {} });
  });

  return {
    audits,
    creativeAudits,
  };
};

export const isFieldArrayChange = (fieldName: string): boolean =>
  Object.values(NestedArrayChangesProperties).some((property) => fieldName.includes(property));

export const hasArrayChanges = (changeList: (SingleValueChange | MultiValueChange)[]): boolean =>
  Boolean(changeList?.some((change) => isFieldArrayChange(change.fieldName)));

export const hasNestedChanges = (
  nestedChangesPrefix?: string,
  changeList?: (SingleValueChange | MultiValueChange)[]
): boolean => {
  return Boolean(
    nestedChangesPrefix &&
      changeList?.length &&
      changeList.some((change) => change.fieldName.includes(nestedChangesPrefix))
  );
};

// this function is needed to make indexations of terms in changeList array and object.targetingTermValues array from ChangeLogEntry the same
export const joinTermsByDimensionIdFromObject = (object: ChangeLogUnionObject): JoinedTermsArray => {
  let targetingTermValues: TargetingTermValue[] | null;
  /**
   * In order to avoid dublications in type names, we rename targetingTermValues field for AuditLineItemV5 and AuditAdV5 in GQL schema
   * dublications arises because we fetch AuditLineItemV5 and AuditAdV5 objects by condition in the same GQL query for audit
   * renames are: lineItemTargetingTermValues for AuditLineItemV5 and adTargetingTermValues for AuditAdV5
   */
  if (object.__typename === 'AuditLineItemV5') {
    targetingTermValues = (object as ChangeLogUnionObjectWithTerms<'AuditLineItemV5'>).lineItemTargetingTermValues;
  } else if (object.__typename === 'AuditAdV5') {
    targetingTermValues = (object as ChangeLogUnionObjectWithTerms<'AuditAdV5'>).adTargetingTermValues;
  } else {
    targetingTermValues = [];
  }

  return (
    targetingTermValues?.reduce((accumulator: JoinedTermsArray, currentValue) => {
      const joinedObject = accumulator.find((value) => value.dimensionId === currentValue.dimension?.id);
      if (joinedObject) {
        joinedObject.valuesIds.push(currentValue.value?.id ?? '');
      } else {
        accumulator.push({
          dimensionId: currentValue.dimension?.id ?? '',
          valuesIds: [currentValue.value?.id ?? ''],
          not: !currentValue.include,
        });
      }
      return accumulator;
    }, []) ?? []
  );
};

/**
 * API provide us with targetingRule in the next structure:
 *    targeting-rule.definition.term-list...
 * But there is one exception - when only one targeting was added to the ad, API returns data without term-list field:
 *    targeting-rule.definition...
 * This function make all targeting rules have the same structure:
 *    targeting-rule.definition.term-list...
 */
export const transformTargetingToSameType = (changeLogEntries: ChangeLogEntryNode[]): ChangeLogEntryNode[] => {
  const possibleFieldNames = [
    'targeting-rule.definition.dimension',
    'targeting-rule.definition.not',
    'targeting-rule.definition.value-set',
    'targeting-rule.definition.range-list',
  ];

  return changeLogEntries
    ? changeLogEntries.map((changeLogEntryNode) => {
        if (
          changeLogEntryNode.node.audits.changeList?.some((change) =>
            possibleFieldNames.some((fieldName) => change.fieldName.includes(fieldName))
          )
        ) {
          const newChangeList: SingleValueChange[] = [];

          changeLogEntryNode.node.audits.changeList.forEach((change) => {
            if (change.fieldName.includes('targeting') && !change.fieldName.includes('term-list')) {
              const newFieldName = change.fieldName.replace('definition', 'definition.term-list[0]');
              newChangeList.push({ ...change, fieldName: newFieldName });
            } else {
              newChangeList.push(change);
            }
          });

          const newChangeListWithoutDublications = newChangeList.reduce((list: SingleValueChange[], currentValue) => {
            const dubplicatedChangeIndex = list.findIndex((change) => change.fieldName === currentValue.fieldName);
            if (dubplicatedChangeIndex !== -1) {
              const dubplicatedChange = list[dubplicatedChangeIndex];
              const finalChange: SingleValueChange = {
                fieldName: currentValue.fieldName,
                newValue: currentValue.newValue.payload === null ? dubplicatedChange.newValue : currentValue.newValue,
                oldValue: currentValue.oldValue.payload === null ? dubplicatedChange.oldValue : currentValue.oldValue,
              };
              list.splice(dubplicatedChangeIndex, 1, finalChange);
            } else {
              list.push(currentValue);
            }

            return list;
          }, []);

          return {
            ...changeLogEntryNode,
            node: {
              ...changeLogEntryNode.node,
              audits: {
                ...changeLogEntryNode.node.audits,
                changeList: newChangeListWithoutDublications,
              },
              creativeAudits: changeLogEntryNode.node.creativeAudits,
            },
          };
        } else {
          return changeLogEntryNode;
        }
      })
    : [];
};
