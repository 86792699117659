import { bem } from 'mission-control-common-components';
import React from 'react';

import type { AssetExtended } from '../../../../_ForCommonLibrary/types/extended';
import type { CreativeReviewStatus } from '../../../../../configs';
import type { Nullable } from '../../../../../models';
import Tooltip from '../../../../Tooltip';
import CreativeReviewBadge from '../../../CreativeReviewBadge';
import { ingestButton } from '../../configs';
import { reviewAlertPropsMap } from '../../utils';
import { CreativeFooterStyling } from '../CreativeFooterStyling';
import { SetAssetErrorButton } from '../SetAssetErrorButton';

const element = bem('creative-footer')[1];

export const SiteServedFooter: React.FC<{
  assets?: Nullable<AssetExtended>[] | null;
  isLoading?: boolean;
  readonly?: boolean;
  hrLine?: boolean | undefined;
  creativeStatus?: string | undefined;
  selectedAsset?: Nullable<AssetExtended>;
  creativeReview?: CreativeReviewStatus;
}> = ({ assets, isLoading, readonly, creativeStatus, hrLine = true, creativeReview, selectedAsset }) => {
  const review = creativeReview ? reviewAlertPropsMap?.[creativeReview] : null;
  const spReviewLocked = assets?.some((asset) => asset && asset.reviewTask?.spReview === 'LOCKED');

  if (!review || spReviewLocked) return null;

  const hasTranscodeAbort = assets?.some((asset) => asset && asset.transcodeStatus === 'PUBLISHING_ABORT');

  const { tooltipConfig, tooltipText } = ingestButton;

  return (
    <CreativeFooterStyling>
      {hrLine && <hr />}
      {hasTranscodeAbort ? (
        <div className={element('content', 'interaction')}>
          <Tooltip id="vast-footer-tooltip" className={element('tooltip')} message={tooltipText} {...tooltipConfig}>
            <SetAssetErrorButton readonly={readonly} assets={assets} />
          </Tooltip>
        </div>
      ) : (
        <div className={element('content', 'information')}>
          <CreativeReviewBadge
            loading={isLoading}
            asset={selectedAsset}
            alertProps={review}
            creativeStatus={creativeStatus}
            creativeReview={creativeReview}
          />
        </div>
      )}
    </CreativeFooterStyling>
  );
};
