import './FiltersDropdown.scss';

import React, { useEffect, useState } from 'react';

import { RcTooltip } from '../../../../../common/RcTooltip';
import { TooltipPlacement, TooltipTrigger } from '../../../../../common/RcTooltip/constants';
import type { TooltipProps } from '../../../../../common/RcTooltip/types';
import type { SearchParamsValuesType } from '../../../../../constants';
import bem from '../../../../../utils/bem';
import type { AdditionalFilterDropdown, SelectedFiltersValues } from '../constants';
import { FilterButton } from './FilterButton';
import FilterDropdown from './FilterDropdown';

const [block, element] = bem('table-filters');

type FiltersDropdownProps = {
  filters: AdditionalFilterDropdown[];
  selectedDropdownsLength: number;
  selectedFiltersValues: SelectedFiltersValues;
  handleApplyFilter: (selectedSearchParam: SearchParamsValuesType, value: string[]) => void;
  handleRemoveFilter: (selectedSearchParam: SearchParamsValuesType) => void;
  setAdditionalFilter(filter: AdditionalFilterDropdown): void;
};

const tooltipProps: TooltipProps = {
  placement: TooltipPlacement.BottomLeft,
  trigger: [TooltipTrigger.Click],
  overlayClassName: 'filter-tooltip',
  visible: true,
};

const FiltersDropdown: React.FC<FiltersDropdownProps> = ({
  filters,
  selectedDropdownsLength,
  selectedFiltersValues,
  handleApplyFilter,
  handleRemoveFilter,
  setAdditionalFilter,
}) => {
  const [filtersIsOpened, setFiltersIsOpened] = useState<boolean>(false);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  useEffect(() => {
    if (selectedDropdownsLength < 1) {
      setExpandedItems([]);
    }
  }, [selectedDropdownsLength]);

  const toggleItem = (itemLabel: string): void => {
    if (expandedItems.includes(itemLabel)) {
      setExpandedItems(expandedItems.filter((label) => label !== itemLabel));
    } else {
      setExpandedItems([...expandedItems, itemLabel]);
    }
  };
  const expandAll = (): void => {
    setExpandedItems(filters.map((item) => item.label));
  };

  const collapseAll = (): void => {
    setExpandedItems([]);
  };

  const handleFilterClick = (): void => setFiltersIsOpened(!filtersIsOpened);

  const isExpandAllVisible = expandedItems.length < filters.length;
  const isCollapseAllVisible = expandedItems.length > 0;

  const FiltersDropdownAccordion = (): JSX.Element => (
    <div data-testid="filters-modal-container" className={element('modal')}>
      <div className={element('controls-container')}>
        {isExpandAllVisible && (
          <span className={element('controls')} onClick={expandAll}>
            Expand All
          </span>
        )}
        {isExpandAllVisible && isCollapseAllVisible && ' / '}
        {isCollapseAllVisible && (
          <span className={element('controls')} onClick={collapseAll}>
            Collapse All
          </span>
        )}
      </div>
      {filters.map((filter) => {
        const selectedValues = selectedFiltersValues[filter.searchParam] || [];

        const handleSetAdditionalFilter = (): void => setAdditionalFilter(filter);

        return (
          <FilterDropdown
            isExpanded={expandedItems.includes(filter.label)}
            key={filter.searchParam}
            filter={filter}
            selectedValues={selectedValues}
            handleApplyFilter={handleApplyFilter}
            handleRemoveFilter={handleRemoveFilter}
            onToggle={toggleItem}
            setSelectedFilter={handleSetAdditionalFilter}
          />
        );
      })}
    </div>
  );

  return (
    <div className={block()} data-testid="filters-dropdown">
      {!filtersIsOpened ? (
        <FilterButton toggleFilterOpen={handleFilterClick} quantity={selectedDropdownsLength} />
      ) : (
        <RcTooltip
          tooltipProps={tooltipProps}
          tooltipContent={<FiltersDropdownAccordion />}
          onClickOutside={handleFilterClick}
        >
          <FilterButton toggleFilterOpen={handleFilterClick} quantity={selectedDropdownsLength} />
        </RcTooltip>
      )}
    </div>
  );
};

export default FiltersDropdown;
