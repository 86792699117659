import type { AlertProps } from '../../../../common/Alert/Alert';
import type { CreativeReviewStatus } from '../../../../configs';
import type { Nullable } from '../../../../models';
import type { CreativesBuilderValues } from '../types';
import { reviewAlertPropsMap } from './reviewAlertPropsMap';

/** Returns the appropriate Review Alert props for the given review status */
export function getCreativeReviewAlertProps(review?: CreativesBuilderValues['review']): Nullable<AlertProps> {
  if (!review || !reviewAlertPropsMap.hasOwnProperty(review)) return null;

  return reviewAlertPropsMap[review as CreativeReviewStatus];
}
