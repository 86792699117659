import './SequenceForm.scss';

import { useFormikContext } from 'formik';
import { bem } from 'mission-control-common-components';
import React, { useMemo } from 'react';

import DefaultFormContainer from '../../../common/DefaultFormContainer/DefaultFormContainer';
import DefaultFormSectionWrapper from '../../../common/DefaultFormSectionWrapper';
import Loader from '../../../common/Loader';
import {
  DISCARD_CHANGES_ARIA_LABEL,
  DISCARD_CHANGES_TITLE,
  SAVE_SEQUENCE_ARIA_LABEL,
  SAVE_SEQUENCE_TITLE,
  SEQUENCE_FORM_TITLE,
} from '../constants';
import { useSequenceForm } from '../hooks';
import SequenceContent from '../SequenceContent';
import SequencePageSideNav from '../SequencePageSideNav';
import { SequencePageFormSection } from '../SequencePageSideNav/SequencePageSideNav';

const [block, element] = bem('sequence-form');

export type SequenceFormProps = {
  submitForm: (e: Event) => void;
  isLoading: boolean;
};

const SequenceForm = ({ submitForm, isLoading }: SequenceFormProps): React.JSX.Element => {
  const { handleReset, isControlButtonsDisabled } = useSequenceForm();
  const { isSubmitting } = useFormikContext();

  const submitTitle = useMemo(() => {
    return (
      <div className={element('submit-title')}>
        <span>{SAVE_SEQUENCE_TITLE}</span>
        {isSubmitting && <Loader />}
      </div>
    );
  }, [isSubmitting]);

  return (
    <DefaultFormContainer
      title={SEQUENCE_FORM_TITLE}
      handleResetTitle={DISCARD_CHANGES_TITLE}
      submitTitle={submitTitle}
      handleReset={handleReset}
      submitForm={submitForm}
      isHandleResetDisabled={isControlButtonsDisabled || isSubmitting}
      isSubmitFormDisabled={isControlButtonsDisabled || isSubmitting}
      handleResetAriaLabel={DISCARD_CHANGES_ARIA_LABEL}
      submitFormAriaLabel={SAVE_SEQUENCE_ARIA_LABEL}
    >
      <>
        <SequencePageSideNav />
        <form className={block()} onSubmit={(e): void => e.preventDefault()}>
          <div className={element('content')} id="formContainer">
            <DefaultFormSectionWrapper
              title={SequencePageFormSection.DETAILS}
              sectionName={SequencePageFormSection.DETAILS}
              isLoading={isLoading}
            >
              <SequenceContent />
            </DefaultFormSectionWrapper>
          </div>
        </form>
      </>
    </DefaultFormContainer>
  );
};

export default SequenceForm;
